<template>
  <div class="ProjectSetup">
    <el-container>
      <el-header>
        <div class="header-warp">
          <i class="el-icon-arrow-left" @click="goBack"></i>
          <span>{{$t('m.项目设置')}}</span>
          <span></span>
        </div>
      </el-header>
      <el-container>
        <!-- aside -->
        <el-aside width="200px">
          <el-menu
            :default-active="index"
            class="el-menu-vertical-demo"
            background-color="#282933"
            text-color="#ffffff"
            active-text-color="#007aff"
          >
            <!-- <el-menu-item index="1" @click="menuClick(1)"> -->
            <!-- <i class="el-icon-setting"></i> -->
            <!-- <span slot="title">项目信息</span> -->
            <!-- </el-menu-item> -->
            <el-menu-item index="1" @click="menuClick(2)">
              <!-- <i class="el-icon-tickets"></i> -->
              <span slot="title">{{$t('m.高级设置')}}</span>
            </el-menu-item>
            <!-- <el-menu-item index="5" @click="menuClick(5)"> -->
            <!-- <i class="el-icon-tickets"></i> -->
            <!-- <span slot="title">交易对设置</span> -->
            <!-- </el-menu-item> -->
            <el-menu-item index="2" @click="menuClick(3)">
              <!-- <i class="el-icon-coin"></i> -->
              <span slot="title">{{$t('m.项目成员')}}</span>
            </el-menu-item>
            <!-- <el-menu-item index="4" @click="menuClick(4)">
              <span slot="title">高级设置</span>
            </el-menu-item> -->
          </el-menu>
        </el-aside>
        <!-- main -->
        <el-main>
          <div class="main-content">
            <!-- 项目信息 -->
            <!-- <ProjectInformation v-if="active == 1" @getProject="getProject" /> -->
            <!-- 高级设置 -->
            <AdvancedSetting v-if="active == 2" @getProject="getProject" />
            <!-- 交易对限制 -->
            <!-- <PairLimit v-if="active == 5" @getProject="getProject" /> -->
            <!-- 项目成员 -->
            <ProjectMember v-if="active == 3" />
            <!-- 其他 -->
            <!-- <div class="content-Project-other" v-if="active == 4">
              <p>操作</p>
              <div @click="deleteProject">退出项目</div>
            </div> -->
          </div>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import ProjectInformation from "./projectSetup/projectInformation";
import AdvancedSetting from "./projectSetup/advancedSetting";
import ProjectMember from "./projectSetup/projectMember";
import PairLimit from "./projectSetup/pairLimit";
import { getProject, deleteProject } from "../request/api";
export default {
  components: {
    ProjectInformation,
    AdvancedSetting,
    ProjectMember,
    PairLimit,
  },
  data() {
    return {
      active: 2,
      projectInfo: null, //项目信息
      index: "1",
    };
  },
  computed: {},
  watch: {},
  methods: {
    // 返回上一页
    goBack() {
      this.$router.back(-1);
    },
    // 侧页切换
    menuClick(v) {
      this.active = v;
    },

    // 查询项目信息
    getProject() {
      let payload = {
        teamProjectId: this.projectInfo.teamProjectId,
        token: sessionStorage.getItem("token"),
      };
      getProject(payload).then((res) => {
        if (res.status == 200) {
          sessionStorage.setItem(
            "projectInfo",
            JSON.stringify(res.data.project)
          );
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 删除项目
    deleteProject() {
      let payload = {
        teamProjectId: this.projectInfo.teamProjectId,
        token: sessionStorage.getItem("token"),
      };
      deleteProject(payload).then((res) => {
        if (res.status == 200) {
          this.$message.success(this.$t('m.操作成功'));
          this.$router.back(-1);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
  created() {},
  mounted() {},
  beforeCreate() {},
  beforeMount() {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {},
  activated() {
    let projectInfo = sessionStorage.getItem("project");
    this.projectInfo = JSON.parse(projectInfo);
    console.log(this.projectInfo);
    this.getProject();
    if (this.$route.query.active === "3") {
      this.active = 3;
      this.index = "2";
    }
  },
  deactivated() {
    sessionStorage.removeItem("projectInfo");
    this.$destroy();
  },
};
</script>
<style lang='scss' scoped>
.ProjectSetup {
  width: 100%;
  height: 100%;
  .el-container {
    height: 100%;
    background: #eff2f5;
  }
  .el-header {
    background-color: #282933;
    color: #ffffff;
    text-align: center;
    height: 70px !important;
    line-height: 70px;
    .header-warp {
      display: flex;
      justify-content: space-between;
      align-items: center;
      i {
        cursor: pointer;
      }
    }
  }

  .el-aside {
    background-color: #282933;
    color: #ffffff;
    // text-align: center;
    line-height: 200px;
    .el-menu {
      border-right: 0px;
    }
  }

  .el-main {
    background: #eef0f5;
    color: #333;
    margin: 0 20px;
    padding: 0;
    .main-content {
      //   其
      .content-Project-other {
        font-size: 14px;
        color: #333;
        div {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 90px;
          height: 30px;
          border-radius: 5px;
          border: 1px solid #007aff;
          color: #007aff;
          margin-top: 20px;
          cursor: pointer;
        }
      }
    }
  }
}
</style>