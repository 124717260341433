<template>
  <div class="oldDetails">
    <el-container>
      <el-header>
        <div class="header-warp">
          <i class="el-icon-arrow-left" @click="goBack"></i>
          <span>{{$t('m.历史详情')}}</span>
          <span></span>
        </div>
      </el-header>
      <el-container>
        <!-- main -->
        <el-main>
          <div class="content">
            <!--历史策略  -->
            <div class="current">
              <div class="current-title">{{$t('m.历史策略')}}</div>
              <el-table
                ref="multipleTable"
                :data="historyExample"
                tooltip-effect="dark"
                style="width: 100%"
                border>

                <el-table-column
                  prop="createTime"
                  :label="$t('m.启动时间')"
                  width="200"
                  sortable>
                  <template slot-scope="scope">{{
                    moment(scope.row.createTime).format("YYYY-MM-DD HH:mm:ss")
                  }}</template>
                </el-table-column>

                <el-table-column
                  prop="endTime"
                  :label="$t('m.终止时间')"
                  width="200"
                  sortable>
                  <template slot-scope="scope">{{
                    moment(scope.row.endTime).format("YYYY-MM-DD HH:mm:ss")
                  }}</template>
                </el-table-column>

                <el-table-column prop="oneId" :label="$t('m.实例')" width="170">
                  <template slot-scope="scope">{{
                    $t('m.'+scope.row.strategyName) +
                    "( " +
                    scope.row.oneId +
                    "-" +
                    scope.row.threeId +
                    " )"
                  }}</template>
                </el-table-column>

                <el-table-column
                  prop="investCoinnNum"
                  :label="$t('m.总投入')"
                  width="100"
                  sortable>
                  <template slot-scope="scope">{{
                    Math.floor(Number(scope.row.investCoinnNum) * 10000) / 10000
                  }}</template>
                </el-table-column>

                <el-table-column prop="realEarnings" :label="$t('m.总收益')" width="100">
                  <template slot-scope="scope">{{
                    Math.floor(Number(scope.row.realEarnings) * 10000) / 10000
                  }}</template>
                </el-table-column>

                <el-table-column
                  prop="realEarningsRate"
                  :label="$t('m.总收益率')"
                  width="100"
                ></el-table-column>

                <el-table-column
                  prop="yearIncome"
                  :label="$t('m.总年化') + ' / ' + $t('m.实现年化')"
                  width="200"
                >
                  <template slot-scope="scope">{{
                    scope.row.yearIncome + "/" + scope.row.realYearIncome
                  }}</template>
                </el-table-column>

                <el-table-column
                  prop="name"
                  :label="$t('m.交易数（买入）（卖出）')"
                  width="200"
                >
                  <template slot-scope="scope">{{
                    scope.row.finishBuyCount + "/" + scope.row.finishSellCount
                  }}</template>
                </el-table-column>

                <el-table-column :label="$t('m.操作')" min-width="200">
                  <template slot-scope="scope">
                    <div class="deposit-operation">
                      <span @click="toMessage(scope.row.executeId)">{{$t('m.消息')}}</span>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import { fundHistoryDetails } from "../../request/api";
import moment from "moment";
export default {
  components: {},
  data() {
    return {
      moment,
      id: null,
      historyExample: [],
    };
  },
  computed: {},
  watch: {},
  methods: {

    // 返回上一页
    goBack() {
      this.$router.back(-1);
    },

    // 获取历史详情
    fundHistoryDetails(id) {
      let payload = {
        token: sessionStorage.getItem("token"),
        id: id,
      };
      fundHistoryDetails(payload).then((res) => {
        if (res.status == 200) {
          this.historyExample = res.data.historyExample;
          console.log(res.data)
        }
      });
    },

    // 消息
    toMessage(id) {
      this.$router.push({ path: "strategyMessage", query: { executeId: id } });
    },
  },
  created() {},
  mounted() {},
  beforeCreate() {},
  beforeMount() {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {},
  activated() {
    let id = this.$route.query.id;
    this.fundHistoryDetails(id);
  },
};
</script>
<style lang='scss' scoped>
.oldDetails {
  width: 100%;
  height: 100%;
  .el-container {
    height: 100%;
    background: #eff2f5;
  }
  .el-header {
    background-color: #fff;
    color: #333;
    text-align: center;
    line-height: 60px;
    // margin-bottom: 20px;
    .header-warp {
      display: flex;
      justify-content: space-between;
      align-items: center;
      i {
        cursor: pointer;
      }
    }
  }

  .el-main {
    // background-color: #fff;
    color: #333;
    margin: 0px 20px 20px 20px;
    padding: 20px 10%;
    font-size: 14px;
    .content {
      padding: 10px 20px;
      background: #fff;

      .strategyDetails {
        margin: 20px 0;
      }

      //   当前策略
      .current {
        margin-top: 20px;
        background: #fff;
        padding-top: 20px;
        .current-title {
          color: #333;
          font-size: 14px;
          font-weight: 600;
          padding-left: 10px;
          margin-bottom: 10px;
        }
      }

      .deposit-operation {
        display: flex;
        // justify-content: space-between;
        flex-wrap: wrap;
        padding: 0 5px;
        span {
          font-size: 12px;
          color: #007aff;
          cursor: pointer;
          margin-right: 10px;
        }
      }
      // 分页
      .pagination-warp {
        display: flex;
        justify-content: flex-end;
        margin: 20px 0;
      }
    }
  }
}
</style>