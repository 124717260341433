<!-- 持仓组合 -->
<template>
  <div class="Project">
    <el-container>
      <el-header>
        <div class="header-warp">
          <i class="el-icon-arrow-left" @click="goBack">
            <span class="projectName">{{ project.projectName }}</span>
          </i>
          <span></span>
          <span
            @click="batchSynchSpotApi()"
            v-if="
              active !== '3' &&
              active !== '6' &&
              active !== '7' &&
              active !== '8' &&
              active !== '9'
            "
            class="synchronize-assets"
            >{{$t('m.同步全部资产')}}</span>
        </div>
      </el-header>
      <el-container>
        <!-- aside -->
        <el-aside width="200px" class="asideBox" style="margin-top: 60px">
          <el-menu
            :default-active="active"
            class="el-menu-vertical-demo"
            background-color="#282933"
            text-color="#fff">
            <el-submenu index="1">
              <template slot="title">
                <i class="el-icon-s-data"></i>
                <span>{{$t('m.策略交易')}}</span>
              </template>
              <el-menu-item-group>
                <el-menu-item index="1-1" @click="activeClick('1-1')">
                  <span class="radiusBigBox">
                    <span class="radiusBox"></span>{{$t('m.创建模板')}}</span>
                  </el-menu-item>
              </el-menu-item-group>
              <el-menu-item-group>
                <el-menu-item index="1-2" @click="activeClick('1-2')">
                  <span class="radiusBigBox">
                    <span class="radiusBox"></span>{{$t('m.策略')}}</span>
                  </el-menu-item>
              </el-menu-item-group>
            </el-submenu>

            <el-submenu index="2">
              <template slot="title">
                <i class="el-icon-tickets"></i>
                <span>{{$t('m.智能订单')}}</span>
              </template>
              <el-menu-item-group>
                <el-menu-item index="2-3" @click="activeClick('2-3')">
                  <span class="radiusBigBox">
                    <span class="radiusBox"></span>
                    {{$t('m.快捷交易')}}
                  </span>
                </el-menu-item>

                <el-menu-item index="2-1" @click="activeClick('2-1')">
                  <span class="radiusBigBox">
                    <span class="radiusBox"></span>
                    {{$t('m.创建订单链')}}
                  </span>
                </el-menu-item>

                <el-menu-item index="2-2" @click="activeClick('2-2')">
                  <span class="radiusBigBox">
                    <span class="radiusBox"></span>
                    {{$t('m.订单链')}}
                  </span>
                  </el-menu-item>
              </el-menu-item-group>
            </el-submenu>

            <el-menu-item index="3" @click="activeClick('3')">
              <i class="el-icon-coin"></i>
              <span slot="title">{{$t('m.持仓组合')}}</span>
            </el-menu-item>

            <el-menu-item index="4" @click="activeClick('4')">
              <i class="el-icon-picture-outline"></i>
              <span slot="title">{{$t('m.我管理的账户')}}</span>
            </el-menu-item>

            <el-menu-item index="5" @click="activeClick('5')" v-if="isSuper">
              <i class="el-icon-picture"></i>
              <span slot="title">{{$t('m.项目管理的账户')}}</span>
            </el-menu-item>

            <el-menu-item index="6" @click="activeClick('6')">
              <i class="el-icon-s-promotion"></i>
              <span slot="title">{{$t('m.信号中心')}}</span>
            </el-menu-item>

            <el-menu-item index="7" v-if="isSuper" @click="activeClick('7')">
              <i class="el-icon-s-data"></i>
              <span slot="title">{{$t('m.交易数据监测')}}</span>
            </el-menu-item>

            <el-menu-item index="8" @click="activeClick('8')">
              <i class="el-icon-user-solid"></i>
              <span slot="title">{{$t('m.项目成员')}}</span>
            </el-menu-item>

            <el-menu-item index="10" v-if="isSuper" @click="activeClick('10')">
              <i class="el-icon-chat-dot-round"></i>
              <span slot="title">{{$t('m.消息中心')}}</span>
            </el-menu-item>

            <el-menu-item index="9" v-if="isSuper" @click="activeClick('9')">
              <i class="el-icon-setting"></i>
              <span slot="title">{{$t('m.项目设置')}}</span>
            </el-menu-item>
          </el-menu>
        </el-aside>
        <!-- main -->
        <el-main style="margin-top: 80px">
          <!-- 创建策略 -->
          <CreateStrategy
            v-if="active == '1-1'"
            @childFn="parentFn"
            @batchSynchSpotApi="batchSynchSpotApi"
          />

          <!-- 模板策略详情 -->
          <NewTemplate
            @backBtn="backBtn"
            @childFn="parentFn"
            v-if="active == '1-2'"
          />

          <!-- 创建订单链 -->
          <CreateOrderChain
            v-if="active == '2-1'"
            @childFn="parentFn"
            @batchSynchSpotApi="batchSynchSpotApi"
          />

          <!-- 订单链 -->
          <ActiveOrderChain v-if="active == '2-2'" />

          <!-- 快捷交易 -->
          <ExpressTransaction v-if="active == '2-3'" />

          <!-- 持仓组合 -->
          <PositionOrderChain v-if="active == '3'" @childFn="parentFn" />

          <!-- 我管理的账户 -->
          <AdministerAccount v-if="active == '4'" @childFn="parentFn" />

          <!-- 项目管理的账户 -->
          <ProjectAccount v-if="active == '5'" />

          <!-- 信号中心 -->
          <Signal v-if="active == '6'" />
          <!-- 交易数据监测 -->
          <DataOverview
            v-if="active == '7'"
            ref="DataOverview"
            @childFn="parentFn"
          />

          <!-- 项目成员 -->
          <ProjectMember :isSuper="isSuper" v-if="active == '8'" />

          <!-- 项目设置 -->
          <ProjectSettings v-if="active == '9'" />

          <!-- 消息中心 -->
          <AllMessage v-if="active == '10'" />
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import Vuex from "vuex";
import DataOverview from "./projectManagement/dataOverview";
import CreateOrderChain from "./projectManagement/createOrderChain";
import ActiveOrderChain from "./projectManagement/activeOrderChain";
import PositionOrderChain from "./projectManagement/positionOrderChain";
import CreateStrategy from "./projectManagement/createStrategy";
import ProjectSettings from "./projectManagement/projectSettings";
import AdministerAccount from "./projectManagement/administerAccount";
import ProjectAccount from "./projectManagement/projectAccount";
import NewTemplate from "./projectManagement/newTemplate";
import ProjectMember from "./projectManagement/projectMember";
import Signal from "./projectManagement/signal";
import UserManagement from "./projectManagement/userManagement";

import ExpressTransaction from "./projectManagement/expressTransaction.vue";

//////////////******************************** ////////////////

import MyUserManagement from "./projectManagement/userManagement";
import OperationManagement from "./projectManagement/operationManagement";
import TradingOperation from "./projectManagement/tradingOperation";
import ActiveOrder from "./projectManagement/activeOrder";
import NewStrategy from "./projectManagement/newStrategy";

import AllMessage from "./projectManagement/allMessage.vue";

import {
  getRole,
  batchSynchSpotApi,
  batchSynchContractApi,
  batchSynchAssetApi,
} from "../request/api";
export default {
  components: {
    DataOverview,
    UserManagement,
    OperationManagement,
    TradingOperation,
    MyUserManagement,
    CreateOrderChain,
    ActiveOrderChain,
    PositionOrderChain,
    CreateStrategy,
    ActiveOrder,

    NewStrategy,
    ProjectSettings,

    AdministerAccount,
    ProjectAccount,
    NewTemplate,
    ProjectMember,
    Signal,
    AllMessage,
    ExpressTransaction,
  },
  data() {
    return {
      active: "3",
      isSuper: false,
      project: null,
      defaultTeam: null,
    };
  },
  computed: {},
  watch: {
    "$store.state.NoviceActive"(New, old) {
      console.log(New);
      if (New == 6) {
        this.activeClick("9");
      }
    },
  },
  methods: {
    activeClick(v) {
      this.active = v;
      console.log(this.active);

      // if (v == 1) {
      //   sessionStorage.setItem("userId", this.account.userId);
      // } else if (v == 3) {
      //   sessionStorage.removeItem("userId");
      // } else if (v == 2) {
      //   console.log(this.account);
      //   sessionStorage.setItem("userId", this.account.userId);
      // }
      if (v == 1) {
        sessionStorage.setItem("userId", this.account.userId);
      } else if (v == 5) {
        // sessionStorage.removeItem("userId");
      } else if (v == 4) {
        console.log(this.account);
        sessionStorage.setItem("userId", this.account.userId);
      }

      sessionStorage.setItem("projectActive", v);
      sessionStorage.removeItem("searchData");
      sessionStorage.removeItem("currentTemplate");
      sessionStorage.removeItem("Now");
      sessionStorage.removeItem("active");
      sessionStorage.removeItem("tableActive");
      sessionStorage.removeItem("pageNo");
      if (v == 3) {
        sessionStorage.setItem("Active", 3);
      }
    },
    // 返回上一页
    goBack() {
      this.$store.commit("setNoviceActive", "2");
      sessionStorage.removeItem("projectActive");
      this.$router.push("/");
    },
    parentFn(v) {
      console.log(v);
      this.active = v;
    },
    backBtn(e) {
      console.log(e);
      this.active = e;
    },
    // 查询角色权限
    getRole(id) {
      let payload = {
        teamProjectId: id,
        token: sessionStorage.getItem("token"),
      };
      getRole(payload).then((res) => {
        
        if (res.status == 200) {
          let role = res.data.role;
          sessionStorage.setItem("role", JSON.stringify(role));
          if (role.roleName == "ADMIN" || role.roleName == "MANAGE") {
            this.isSuper = true;
          } else {
            this.isSuper = false;
            sessionStorage.setItem("detailsOperationAuthority", true);
          }
        } else {
          this.$message.error(res.msg);
        }
      });
    },

    //同步资产
    batchSynchSpotApi(e) {
      let payload = {
        token: sessionStorage.getItem("token"),
      };
      batchSynchSpotApi(payload).then((res) => {
        if (res.status == 200) {
          console.log(this.$t('m.同步成功'));
          if (e) {
          } else {
            this.$message.success(this.$t('m.同步成功'));
          }
        } else {
          this.$message.error(res.msg);
        }
      });
      batchSynchContractApi(payload).then((res) => {
        if (res.status == 200) {
          console.log(this.$t('m.同步成功'));
        }
      });
      batchSynchAssetApi(payload).then((res) => {
        if (res.status == 200) {
          console.log(this.$t('m.同步成功'));
        }
      });
    },
  },
  created() {
    let project = JSON.parse(sessionStorage.getItem("project"));
    this.project = project;
    this.getRole(this.project.teamProjectId);
    this.isSuper = JSON.parse(sessionStorage.getItem("isSuper"));
    if (sessionStorage.getItem("Active")) {
      let active = sessionStorage.getItem("Active");
      this.active = active;
    }
    sessionStorage.setItem("detailsOperationAuthority", true);
    let projectActive = sessionStorage.getItem("projectActive");
    if (!projectActive) {
      sessionStorage.setItem("projectActive", 3);
    }
    this.active = projectActive ? projectActive : "3";
  },
  mounted() {
    let account = sessionStorage.getItem("account");
    this.account = JSON.parse(account);
    sessionStorage.setItem("isOplistShow", true);
    sessionStorage.setItem("userId", this.account.userId);

    let project = JSON.parse(sessionStorage.getItem("project"));
    this.project = project;

    let defaultTeam = JSON.parse(sessionStorage.getItem("defaultTeam"));
    this.defaultTeam = defaultTeam;

    if (this.project.creator == this.account.userId) {
      this.$store.commit("setIsProject", true);
    }

    let pay = {
      teamId: this.defaultTeam.teamId,
      teamProjectId: this.project.teamProjectId,
    };
    this.$store.dispatch("getType", pay);
  },
  beforeCreate() {},
  beforeMount() {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {},
  activated() {},
  deactivated() {
    sessionStorage.removeItem("isOplistShow");
    // sessionStorage.removeItem("projectActive");
    this.$store.commit("setIsProject", false);

    this.$destroy();
  },
};
</script>
<style lang='scss' scoped>
.Project {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: absolute;
  .el-container {
    // min-height: 975px;
    height: 100vh;
    background: #eff2f5;
  }
  .el-header {
    background-color: #282933;
    color: #fff;
    text-align: center;
    line-height: 60px;
    position: fixed;
    z-index: 2;
    width: 100%;
    // margin-bottom: 20px;
    .header-warp {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 60px;
      i {
        cursor: pointer;
      }
      .projectName {
        margin-left: 10px;
      }
      .synchronize-assets {
        min-width: 100px;
        height: 30px;
        font-size: 14px;
        padding: 0 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        background: #2174ff;
        cursor: pointer;
      }
    }
  }

  .el-aside {
    background-color: #282933;
    color: #333;
    .el-menu {
      border: 0px;
    }
  }

  .el-main {
    // background-color: #fff;
    color: #333;
    margin: 20px 20px 10px 20px;
    font-size: 14px;
    padding: 0;
    // overflow: visible;
  }
}
.asideBox {
  /deep/.is-active {
    background: #2174ff !important;
    color: #fff !important;
  }
  .radiusBigBox {
    display: flex;
    align-items: center;
    .radiusBox {
      display: flex;
      width: 4px;
      height: 4px;
      background: #ffffff;
      border-radius: 4px;
      margin-right: 4px;
    }
  }
}
</style>