<template>
  <!-- 数据概览 -->
  <div class="dataOverview">
    <div class="content">
      <!-- 数据概览 -->
      <div>
        <div class="data-box">
          <div class="data-box-title">
            <el-tooltip
              class="headerStyle"
              effect="dark"
              :content="$t('m.根据用户建议，APP端展示时自动剔除不足7日结算退出的数据。')"
              placement="right">
              <div class="brdTipsStyle">{{$t('m.数据概览图（项目）')}}</div>
            </el-tooltip>
          </div>

          <el-table :data="projectData" style="width: 100%" border>
            <el-table-column prop="currentUserCount" :label="$t('m.当前/累计账户数量')">
              <template slot-scope="scope">
                <span>{{
                  (scope.row.currentUserCount != null
                    ? scope.row.currentUserCount
                    : "--") +
                  " / " +
                  (scope.row.accumulatedUserCount != null
                    ? scope.row.accumulatedUserCount
                    : "--")
                }}</span>
              </template>
            </el-table-column>

            <el-table-column
              prop="accumulatedExecuteCount"
              :label="$t('m.当前/累计执行策略')">
              <template slot-scope="scope">
                <span>{{
                  (scope.row.currentExecuteCount != null
                    ? scope.row.currentExecuteCount
                    : "--") +
                  " / " +
                  (scope.row.accumulatedExecuteCount != null
                    ? scope.row.accumulatedExecuteCount
                    : "--")
                }}</span>
              </template>
            </el-table-column>

            <el-table-column prop="currentAssets" :label="$t('m.当前/累计资产(USDT)')">
              <template slot-scope="scope">
                <span>{{
                  (scope.row.currentAssets != null
                    ? Math.floor(Number(scope.row.currentAssets) * 10000) /
                      10000
                    : "--") +
                  " / " +
                  (scope.row.accumulatedAssets != null
                    ? Math.floor(Number(scope.row.accumulatedAssets) * 10000) /
                      10000
                    : "--")
                }}</span>
              </template>
            </el-table-column>

            <el-table-column
              prop="currentProfitLoss"
              :label="$t('m.当前/累计盈亏(USDT)')"
              width="200">
              <template slot-scope="scope">
                <span>{{
                  (scope.row.currentProfitLoss != null
                    ? Math.floor(Number(scope.row.currentProfitLoss) * 10000) /
                      10000
                    : "--") +
                  " / " +
                  (scope.row.cumulatedProfitLoss != null
                    ? Math.floor(
                        Number(scope.row.cumulatedProfitLoss) * 10000
                      ) / 10000
                    : "--")
                }}</span>
              </template>
            </el-table-column>

            <el-table-column prop="maxReturn" :label="$t('m.最大盈利/回撤率')">
              <template slot-scope="scope">
                <span>{{
                  (scope.row.maxReturn != null ? scope.row.maxReturn : "--") +
                  " / " +
                  (scope.row.minReturn != null
                    ? scope.row.minReturn + "%"
                    : "--")
                }}</span>
              </template>
            </el-table-column>

            <el-table-column
              prop="currentAverageAnnualized"
              :label="$t('m.当前平均年化')">
              <template slot-scope="scope">
                <span>{{
                  scope.row.currentAverageAnnualized != null
                    ? Math.floor(
                        Number(scope.row.currentAverageAnnualized) * 100
                      ) /
                        100 +
                      "%"
                    : "--"
                }}</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>

      <!-- 走势图 -->
      <div class="trendChart">
        <div class="trendChart-box">
          <div class="trendChart-title">
            <span>{{$t('m.资产走势图')}}</span>
            <span style="font-size: 12px"> </span>
            <span></span>
          </div>
          <div class="trendChart-assets" style="flex: 1; height: 350px"></div>
        </div>
        
        <div class="trendChart-box">
          <div class="trendChart-title">
            <span>{{$t('m.每日利润')}}</span>
            <span></span>
            <span class="trendChart-title-box">
              <span
                class="trendChart-title-rightBox"
                style="margin-right: 10px">
                <span style="background: #ff3a3a"></span>
                <span>{{$t('m.资金盈利')}}</span>
              </span>
              <span class="trendChart-title-rightBox">
                <span style="background: #26b34b"></span>
                <span>{{$t('m.资产亏损')}}</span>
              </span>
            </span>
          </div>
          <div class="trendChart-profit" style="flex: 1; height: 350px"></div>
        </div>
      </div>

      <!-- 操作员列表 -->
      <div>
        <div v-for="(item, index) in managerList" :key="index" class="data-box">
          <div class="boxTitle">
            <div class="titleData">
              <span>{{ item.userData.nickName }}</span>
              <span>{{ item.userData.mark }}</span>
              <span v-if="item.userData.isDefault == 1">({{$t('m.默认')}})</span>
              <span
                class="title-me"
                v-if="account.userId == item.userData.userId">
                {{$t('m.我')}}</span>
            </div>

            <div class="titleBtn">
              <span
                v-if="item.userData.isDefault !== 1"
                @click="settingOperator(item.userData.userId)">
                {{$t('m.设置默认操作员')}}</span>
              <span @click="migratingUsers(item.userData.userId)">
                {{$t('m.整体迁移用户')}}</span>
              <span @click="toDetails(item)">
                {{$t('m.详情')}}</span>
              <span
                style="color: #f56c6c"
                @click="deleteProjectMember(item.userData)"
                v-if="item.userData.mark !== '超级管理员'">
                {{$t('m.移出项目')}}
              </span>
            </div>
          </div>

          <div class="nav">
            <div class="navItem">
              <span class="navBrd">{{$t('m.当前/累计账户数量')}}</span>
              <span>{{
                (item.currentUserCount != null ? item.currentUserCount : "--") +
                " / " +
                (item.accumulatedUserCount != null
                  ? item.accumulatedUserCount
                  : "--")
              }}</span>
            </div>

            <div class="navItem">
              <span class="navBrd">{{$t('m.当前/累计执行策略')}}</span>
              <span>{{
                (item.currentExecuteCount != null
                  ? item.currentExecuteCount
                  : "--") +
                " / " +
                (item.accumulatedExecuteCount != null
                  ? item.accumulatedExecuteCount
                  : "--")
              }}</span>
            </div>
            <div class="navItem">
              <span class="navBrd">{{$t('m.当前/累计资产(USDT)')}}</span>
              <span>
                {{
                  (item.currentAssets != null
                    ? Math.floor(Number(item.currentAssets) * 10000) / 10000
                    : "--") +
                  " / " +
                  (item.accumulatedAssets != null
                    ? Math.floor(Number(item.accumulatedAssets) * 10000) / 10000
                    : "--")
                }}
              </span>
            </div>
            <div class="navItem">
              <span class="navBrd">{{$t('m.当前/累计盈亏(USDT)')}}</span>
              <span>{{
                (item.currentProfitLoss != null
                  ? Math.floor(Number(item.currentProfitLoss) * 10000) / 10000
                  : "--") +
                " / " +
                (item.cumulatedProfitLoss != null
                  ? Math.floor(Number(item.cumulatedProfitLoss) * 10000) / 10000
                  : "--")
              }}</span>
            </div>
            <div class="navItem">
              <span class="navBrd">{{$t('m.最大盈利/回撤率')}}</span>
              <span>{{
                (item.maxReturn != null ? item.maxReturn : "--") +
                " / " +
                (item.minReturn != null ? item.minReturn + "%" : "--")
              }}</span>
            </div>
            <div class="navItem" style="border: 0">
              <span class="navBrd">{{$t('m.当前平均年化')}}</span>
              <span>{{
                item.currentAverageAnnualized != null
                  ? Math.floor(Number(item.currentAverageAnnualized) * 100) /
                      100 +
                    "%"
                  : "--"
              }}</span>
            </div>
          </div>
        </div>
      </div>

      <!-- 迁移用户弹窗 -->
      <el-dialog
        :title="$t('m.迁移用户')"
        :visible.sync="dialogVisible"
        width="30%"
        :before-close="handleClose">
        <el-select v-model="operatorLater" :placeholder="$t('m.请选择操作员')">
          <el-option
            v-for="(item, idx) in OperatorList"
            :key="idx"
            :label="item.nickName"
            :value="item.userId"
          >
          </el-option>
        </el-select>
        <span slot="footer" class="dialog-footer">
          <el-button @click="cancel">{{$t('m.取 消')}}</el-button>
          <el-button type="primary" @click="migrationUser">{{$t('m.确 定')}}</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import {
  dataSummarizing,
  operationManagement,
  settingOperator,
  migrationUser,
  operatorList,
  deleteProjectMember,
  prjectPictureData,
} from "../../request/api";
export default {
  components: {},
  data() {
    return {
      projectData: [],
      project: null, //项目信息
      roleUserId: null,

      role: null,
      account: null,
      managerList: [], //操作员带数据列表
      OperatorList: [], //操作员列表
      dialogVisible: false,
      operatorLater: null, //被迁移操作员ID
      isManagerListShow: false,
      detailsOperationAuthority: null,
    };
  },
  computed: {},
  watch: {},
  methods: {
    // 获取项目数据
    dataSummarizing() {
      let payload = {
        token: sessionStorage.getItem("token"),
        teamProjectId: this.project.teamProjectId,
        roleUserId: this.roleUserId,
      };
      dataSummarizing(payload).then((res) => {
        if (res.status == 200) {
          let list = [];
          list.push(res.data);
          list.forEach((e) => {
            if (e.minReturn.toString().indexOf(".") > -1) {
              e.minReturn =
                e.minReturn
                  .toString()
                  .slice(0, e.minReturn.toString().indexOf(".") + 5) * 100;
            }
          });

          this.projectData = list;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    handleClose(done) {
      this.operatorFront = null;
      this.operatorLater = null;
      done();
    },
    // 操作详情页
    toDetails(item) {
      console.log(item);
      if (!this.detailsOperationAuthority) {
        // this.$message.error("不是模板创建人，无权操作");
        this.$alert(
          this.$t('m.您没有权限使用该功能，敬请谅解！'), 
          this.$t('m.额，好像没有权限'), {
          confirmButtonText: this.$t('m.知道啦'),
        });
        return;
      }
      if (item.userData.nickName == this.role.nickName) {
        // sessionStorage.setItem("detailsOperationAuthority", true);
        this.$emit("childFn", "3");
        return;
      } else {
        sessionStorage.setItem(
          "isSuper",
          item.userData.mark == "管理员" ? true : false
        );
        sessionStorage.setItem("detailsOperationAuthority", false);
      }
      this.$router.push({
        path: "ProjectNewPerator",
        query: {
          userId: item.userData.userId,
          mark: item.userData.mark,
          nickName: item.userData.nickName,
          other: "other",
          projectActive: "7",
        },
      });
    },

    // 查询操作员列表
    operationManagement() {
      let payload = {
        token: sessionStorage.getItem("token"),
        teamProjectId: this.project.teamProjectId,
      };

      operationManagement(payload).then((res) => {
        if (res.status == 200) {
          let userId = this.roleUserId;
          let list = res.data;
          this.managerList = list;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 设置默认操作员
    settingOperator(id) {
      if (!this.detailsOperationAuthority) {
        // this.$message.error("不是模板创建人，无权操作");
        this.$alert(
          this.$t('m.您没有权限使用该功能，敬请谅解！'), 
          this.$t('m.额，好像没有权限'), {
          confirmButtonText: this.$t('m.知道啦'),
        });
        return;
      }
      let payload = {
        teamProjectId: this.project.teamProjectId,
        token: sessionStorage.getItem("token"),
        userId: id,
      };
      console.log(payload);
      settingOperator(payload).then((res) => {
        if (res.status == 200) {
          this.$message.success(this.$t('m.设置成功'));
          this.operationManagement();
          console.log(res.data);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 查询操作员列表
    operatorList() {
      let payload = {
        teamProjectId: this.project.teamProjectId,
        token: sessionStorage.getItem("token"),
      };
      console.log(payload);
      operatorList(payload).then((res) => {
        if (res.status == 200) {
          this.OperatorList = res.data.operatorList;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 迁移用户弹窗
    migratingUsers(id) {
      if (!this.detailsOperationAuthority) {
        // this.$message.error("不是模板创建人，无权操作");
        this.$alert(
          this.$t('m.您没有权限使用该功能，敬请谅解！'), 
          this.$t('m.额，好像没有权限'), {
          confirmButtonText: this.$t('m.知道啦'),
        });
        return;
      }
      console.log(id);
      this.operatorFront = id;
      this.operatorList();
      this.dialogVisible = true;
    },
    // 迁移用户
    migrationUser() {
      if (!this.operatorLater) {
        this.$message.error(this.$t('m.请先选择操作员'));
        return;
      }
      let payload = {
        teamProjectId: this.project.teamProjectId,
        token: sessionStorage.getItem("token"),
        operatorFront: this.operatorFront,
        operatorLater: this.operatorLater,
      };
      console.log(payload);
      migrationUser(payload).then((res) => {
        if (res.status == 200) {
          this.$message.success(this.$t('m.迁移成功'));
          this.dialogVisible = false;
          console.log(res.data);
          this.operationManagement();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 取消弹窗
    cancel() {
      this.operatorFront = null;
      this.operatorLater = null;
      this.dialogVisible = false;
    },
    // 移除项目成员
    deleteProjectMember(item) {
      if (!this.detailsOperationAuthority) {
        // this.$message.error("不是模板创建人，无权操作");
        this.$alert(
          this.$t('m.您没有权限使用该功能，敬请谅解！'), 
          this.$t('m.额，好像没有权限'), {
          confirmButtonText: this.$t('m.知道啦'),
        });
        return;
      }
      this.$confirm(
        this.$t('m.退出项目后，该成员所属的用户将被分配给项目管理员'),
        this.$t('m.退出项目'),
        {
          confirmButtonText: this.$t('m.确定'),
          cancelButtonText: this.$t('m.取消'),
          closeOnClickModal: false,
        }
      )
        .then(() => {
          let payload = {
            teamProjectId: this.project.teamProjectId,
            token: sessionStorage.getItem("token"),
            userId: item.userId,
          };
          deleteProjectMember(payload).then((res) => {
            if (res.status == 200) {
              this.$message.success(this.$t('m.移出成功'));
              if (item.mark == "超级管理员") {
                this.$router.push("/");
              } else {
                this.operationManagement();
              }
              console.log(res.data);
            } else {
              this.$message.error(res.msg);
            }
          });
        })
        .catch(() => {});
    },
    prjectPictureData() {
      let payload = {
        token: sessionStorage.getItem("token"),
        teamProjectId: this.project.teamProjectId,
      };
      prjectPictureData(payload).then((res) => {
        console.log(res.data);
        let assetsData = res.data.assetsData;
        let profitData = res.data.profitData;
        var chartAssetsDom = $(".trendChart-assets")[0];
        var myChartAssets = echarts.init(chartAssetsDom);
        var optionAssets;
        let assetsTime = [];
        let assetsInvestNum = [];
        if (assetsData.length == 0) {
          let number = Date.parse(new Date()) - 24 * 60 * 60 * 1000;
          number = this.timestampToTime(number);
          console.log(number);
          assetsTime = [number];
          assetsInvestNum = [0];
        } else {
          assetsData.forEach((e, index) => {
            if (index == assetsData.length - 1) {
            } else {
              assetsTime.unshift(this.timestampToTime(e.time));
              assetsInvestNum.unshift(e.investNum);
            }
          });
        }
        console.log(assetsTime, assetsInvestNum);
        let intervalAssets = assetsTime.length - 2;

        optionAssets = {
          tooltip: {
            trigger: "item",
            confine: true,
            formatter: `{b}<br />`+this.$t('m.总资产')+`: {c} USDT`,
          },
          legend: {
            top: "5%",
            left: "center",
            textStyle: {
              color: "#E6E9EE",
            },
          },
          xAxis: {
            type: "category",
            data: assetsTime,
            axisLabel: {
              interval: intervalAssets,
            },
          },
          yAxis: {
            type: "value",
          },
          series: [
            {
              data: assetsInvestNum,
              type: "line",
            },
          ],
        };
        myChartAssets.setOption(optionAssets);
        var chartProfitDom = $(".trendChart-profit")[0];
        var myChartProfit = echarts.init(chartProfitDom);
        var optionProfit;
        let profitTime = [];
        let profitInvestNum = [];
        if (profitData.length == 0) {
          let number = Date.parse(new Date()) - 24 * 60 * 60 * 1000;
          number = this.timestampToTime(number);
          console.log(number);
          profitTime = [number];
          profitInvestNum = [0];
        } else {
          profitData.forEach((e, index) => {
            if (index !== profitData.length - 1) {
              profitTime.unshift(this.timestampToTime(e.time));
              if (e.incomeNum < 0) {
                profitInvestNum.unshift({
                  value: e.incomeNum,
                  itemStyle: {
                    color: "#26B34B",
                  },
                });
              } else {
                profitInvestNum.unshift({
                  value: e.incomeNum,
                  itemStyle: {
                    color: "#FF3A3A",
                  },
                });
              }
            }
          });
        }
        let intervalProfit = profitTime.length - 2;

        optionProfit = {
          tooltip: {
            trigger: "item",
            confine: true,
            formatter: `{b}<br />`+this.$t('m.当前收益')+`: {c} USDT`,
          },
          legend: {
            top: "5%",
            left: "center",
            textStyle: {
              color: "#E6E9EE",
            },
          },
          xAxis: {
            type: "category",
            data: profitTime,
            axisLabel: {
              interval: intervalProfit,
            },
          },
          yAxis: {
            type: "value",
          },
          series: [
            {
              data: profitInvestNum,
              type: "bar",
              // barWidth: 12,
            },
          ],
        };
        myChartProfit.setOption(optionProfit);
      });
    },
    //日期换算
    timestampToTime(timestamp) {
      var date = new Date(timestamp); 
      var Y = date.getFullYear() + "-";
      var M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      var D =
        date.getDate() < 10
          ? "0" + date.getDate() + " "
          : +date.getDate() + " ";
      var h =
        date.getHours() < 10
          ? "0" + date.getHours() + ":"
          : date.getHours() + ":";
      var m =
        date.getMinutes() < 10
          ? "0" + date.getMinutes() + ":"
          : date.getMinutes() + ":";
      var s =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      return Y + M + D;
    },
  },
  created() {
    let project = sessionStorage.getItem("project");
    this.project = JSON.parse(project);
    this.prjectPictureData();
  },
  mounted() {
    let roleUserId = this.$route.query.userId;
    this.roleUserId = roleUserId ? roleUserId : null;
    this.dataSummarizing();

    let account = sessionStorage.getItem("account");
    this.account = JSON.parse(account);
    let role = sessionStorage.getItem("role");
    this.role = JSON.parse(role);
    this.operationManagement();

    let isManagerListShow = sessionStorage.getItem("isOplistShow");
    if (isManagerListShow) {
      this.isManagerListShow = true;
    }
    let detailsOperationAuthority = sessionStorage.getItem(
      "detailsOperationAuthority"
    );
    this.detailsOperationAuthority = JSON.parse(detailsOperationAuthority);
  },
  beforeCreate() {},
  beforeMount() {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {},
  activated() {},
};
</script>
<style lang='scss' scoped>
.dataOverview {
  font-size: 16px;
  color: #333;
  height: 100%;
  .content {
    // background: #fff;
    .data-box {
      padding: 20px;
      background: #fff;
      margin-bottom: 20px;
      border-radius: 8px;
      .boxTitle {
        margin-bottom: 20px;
        border-bottom: 1px solid #e6e9ee;
        padding-bottom: 20px;
        display: flex;
        justify-content: space-between;
        .titleData {
          span {
            margin-right: 10px;
          }
          .title-me {
            font-size: 12px;
            color: #007aff;
          }
        }
        .titleBtn {
          display: flex;
          font-size: 14px;
          color: #2174ff;
          span {
            margin-left: 10px;
            cursor: pointer;
          }
        }
      }
    }
    .trendChart {
      display: flex;
      margin-bottom: 20px;
      :first-child {
        margin-right: 10px;
      }
      .trendChart-box {
        flex: 1;
        height: 370px;
        background: #fff;
        padding: 20px 20px 0;
        border-radius: 8px;
        .trendChart-title {
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-bottom: 1px solid #e6e9ee;
          padding-bottom: 5px;
          .trendChart-title-box {
            display: flex;
            color: #54698a;
            font-size: 12px;
            .trendChart-title-rightBox {
              display: flex;
              align-items: center;
              :first-child {
                display: flex;
                width: 8px;
                height: 8px;
              }
            }
          }
        }
        /deep/.trendChart-assets {
          flex: 1;
        }
        /deep/.trendChart-profit {
          flex: 1;
        }
      }
    }
    .content-box-warp {
      display: flex;
      flex-wrap: wrap;
      .content-box {
        width: 300px;
        padding: 24px;
        margin: 24px 24px 24px 0;
        background: #fff;
        border-radius: 8px;

        .content-box-title {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 40px;
          div {
            display: flex;
            align-items: center;
            :nth-child(2) {
              font-size: 12px;
              color: #999;
              margin: 0 5px;
            }
          }
        }
        .content-box-text {
          display: flex;
          justify-content: space-between;
          align-items: center;
          color: #999;
          font-size: 14px;
          margin-bottom: 10px;
        }
        .el-select {
          width: 100% !important;
        }
      }
    }
  }
}
.nav {
  display: flex;
  border: 1px solid #ebeef5;
  .navItem {
    display: flex;
    flex: 1;
    flex-direction: column;
    border-right: 1px solid #ebeef5;
    color: #909399;
    font-size: 14px;
    span {
      padding: 15px 10px;
    }
    .navBrd {
      border-bottom: 1px solid #ebeef5;
    }
  }
}
.headerStyle {
  display: flex;
  align-items: center;
  cursor: pointer;
  .headerIcon {
    margin-left: 3px;
  }
}
.brdTipsStyle {
  width: 145px;
  border-bottom: 1px dashed #333;
}
.data-box-title {
  padding-bottom: 20px;
  display: flex;
  justify-content: flex-start;
}
</style>