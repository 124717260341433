<template>
  <div>
    <div class="nav">
      <el-table
        :cell-style="rowStyle"
        :data="data"
        style="width: 100%"
        @selection-change="selectRow">

        <template style="color: black">
          <template v-for="(item, index) in label">
            <el-table-column
              v-if="item.title == 'API状态'"
              :prop="item.nav"
              :label="$t('m.'+item.title)"
              :key="index"
              width="100">
              <template slot="header">
                <el-tooltip
                  class="headerStyle"
                  effect="dark"
                  :content="$t('m.异常指下单时出现交易所报错信息、数据异常等；无效指无法同步，可能是结算中、失去权限、交易所断开链接等。')"
                  placement="top">
                  <div class="brdTipsStyle">{{$t('m.API状态')}}</div>
                </el-tooltip>
              </template>
              <template slot-scope="scope">
                <div>{{ scope.row.isExpire }}</div>
              </template>
            </el-table-column>

            <el-table-column
              v-else-if="item.title == '订单链'"
              :prop="item.nav"
              :label="$t('m.'+item.title)"
              :key="index">
              <template slot-scope="scope">
                <div v-html="scope.row.order"></div>
              </template>
            </el-table-column>

            <el-table-column
              v-else-if="item.title == '账户'"
              :prop="item.nav"
              :label="$t('m.'+item.title)"
              :key="index">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.remarkCode
                      ? scope.row.remarkCode
                      : scope.row.publicCode
                      ? scope.row.publicCode
                      : scope.row.publicPeopleName
                  }}
                </div>
              </template>
            </el-table-column>

            <el-table-column
              v-else
              :prop="item.nav"
              :label="$t('m.'+item.title)"
              :key="index"
              :width="item.width ? item.width : ''">
            </el-table-column>
          </template>

          <el-table-column
            v-if="modify"
            :width="details ? 150 : 120"
            :label="$t('m.操作')"
            header-align="right"
            label-class-name="rightBox">
            <template slot-scope="scope">
              <div class="modifyStyle">
                <div
                  v-if="!details"
                  class="modifyBtn"
                  @click="modifyDetailBtn(scope.row)">
                  {{$t('m.详情')}}
                </div>
                <div
                  class="modifyItem"
                  v-if="details"
                  @click="toMessage(scope.row)">
                  {{$t('m.消息')}}
                </div>
                <div
                  class="modifyItem"
                  v-if="details"
                  @click="parameter(scope.row)">
                  {{$t('m.参数')}}
                </div>
                <div
                  class="modifyBtn"
                  v-if="details && detailBtn"
                  @click="toStrategy(scope.row)">
                  {{$t('m.详情')}}
                </div>

                <div
                  v-if="
                    !details &&
                    (scope.row.orderList
                      ? scope.row.orderList[scope.row.orderList.length - 1]
                          .orderType !== 3
                      : '')"
                  class="modifyBtn"
                  @click="modifyBtn(scope.row)">
                  {{ scope.row.states == "执行中" ? "修改" : "查看" }}
                </div>
              </div>
            </template>
          </el-table-column>

          <el-table-column
            v-if="!modify"
            :width="!news ? 260 : 70"
            :label="$t('m.操作')"
            header-align="right"
            label-class-name="rightBox">
            <template slot-scope="scope">
              <div class="modifyStyle" v-if="scope.row.state !== '结算中'">
                <div
                  class="modifyItem"
                  v-if="
                    scope.row.coinType == 'USDT' ||
                    (scope.row.exchangeName == 'OkexV5' && detailsDelete)"
                  @click="transferDetermine(scope.row)">
                  {{$t('m.划转')}}
                </div>
                <div
                  class="modifyItem"
                  v-if="newsRemarks"
                  @click="updateMark(scope.row)">
                  {{$t('m.备注')}}
                </div>
                <div
                  class="modifyItem"
                  v-if="!news && !scope.row.news"
                  @click="modifyShowBtn(scope.row, '1-1')">
                  {{$t('m.启动策略')}}
                </div>
                <div
                  class="modifyItem"
                  v-if="!news && !scope.row.news"
                  @click="createOrderChain(scope.row)">
                  {{$t('m.创建订单链')}}
                </div>
                <div
                  class="modifyItem"
                  v-if="!detailsDelete"
                  @click="toDetails(scope.row)">
                  {{$t('m.详情')}}
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column :selectable="selectable" type="selection" width="20">
          </el-table-column>
        </template>
      </el-table>
    </div>
  </div>
</template>
<script>
export default {
  props: [
    "data",
    "label",
    "rowStyleData",
    "modify",
    "details",
    "news",
    "detailsDelete",
    "detailBtn",
    "disabledShow",
    "statesShow",
    "newsRemarks",
  ],
  watch: {
    mark() {
      if (this.mark.length >= 6) {
        this.mark = this.mark.slice(0, 6);
        console.log(this.mark);
      }
    },
  },
  data() {
    return {
      options: ["1", "2"],
      strategyType: 123,
      rowIndexNowOne: 0,
      rowIndexNowTwo: 0,
      dialogData: [3, 4],
      detailsOperationAuthority: null,
      dialogVisible: false,
      mark: null,
      scopedRow: null,
    };
  },
  methods: {
    //列的字体样式
    rowStyle({ row, column, rowIndex, columnIndex }) {
      if (this.rowStyleData) {
        for (let i = this.rowIndexNowOne; i < this.rowStyleData.length; i++) {
          if (columnIndex == this.rowStyleData[i].columnIndex) {
            if (typeof this.rowStyleData[i].style == "string") {
              this.rowIndexNowOne = 0;
              return this.rowStyleData[i].style;
            } else {
              for (
                let item = this.rowIndexNowTwo;
                item < this.rowStyleData[i].style.length;
                item++
              ) {
                for (let e = 0; e < row.state.length; e++) {
                  if (row.state[e] == this.rowStyleData[i].style[item].state) {
                    return this.rowStyleData[i].style[item].style;
                  }
                }
              }
            }
          }
        }
      }
    },
    //备注按钮
    updateMark(row) {
      this.$emit("updateMark", row);
    },

    //选中的数据
    selectRow(selection) {
      console.log(selection);
      this.$emit("selectRow", selection);
    },

    //启动策略按钮
    modifyShowBtn(e, index) {
      if (!this.detailsOperationAuthority) {
        // this.$message.error("不是模板创建人，无权操作");
        this.$alert(
          $t('m.您没有权限使用该功能，敬请谅解！'), 
          $t('m.额，好像没有权限'), {
          confirmButtonText: $t('m.知道啦'),
        });
        return;
      }
      console.log(e);
      let list = [e];
      sessionStorage.setItem("startAcc", JSON.stringify(list));

      this.$emit("setActive", "1-1");
    },
    // 创建订单链
    createOrderChain(e) {
      if (!this.detailsOperationAuthority) {
        // this.$message.error("不是模板创建人，无权操作");
        this.$alert(
          $t('m.您没有权限使用该功能，敬请谅解！'), 
          $t('m.额，好像没有权限'), {
          confirmButtonText: $t('m.知道啦'),
        });
        return;
      }
      console.log(e);
      let list = [e];
      sessionStorage.setItem("startAcc", JSON.stringify(list));
      this.$emit("setActive", "2-1");
    },
    //跳订单链详情
    modifyDetailBtn(v) {
      if (!this.detailsOperationAuthority) {
        // this.$message.error("不是模板创建人，无权操作");
        this.$alert(
          $t('m.您没有权限使用该功能，敬请谅解！'), 
          $t('m.额，好像没有权限'), {
          confirmButtonText: $t('m.知道啦'),
        });
        return;
      }
      if (sessionStorage.getItem("Now")) {
        sessionStorage.setItem("orderDetail", JSON.stringify(v));
        console.log(location);
        if (location.origin == "http://159.138.87.203") {
          window.open(location.origin + "/TrustFundTest/#/orderDetail");
        } else {
          window.open(location.origin + "/#/orderDetail");
        }
      } else {
        this.$router.push({
          path: "/orderDetail",
          query: {
            val: JSON.stringify(v),
          },
        });
      }
    },
    // 跳账户详情页
    toDetails(v) {
      if (!this.detailsOperationAuthority) {
        // this.$message.error("不是模板创建人，无权操作");
        this.$alert(
          $t('m.您没有权限使用该功能，敬请谅解！'), 
          $t('m.额，好像没有权限'), {
          confirmButtonText: $t('m.知道啦'),
        });
        return;
      }
      console.log(v);
      this.$router.push({
        path: "/activeOrder",
        query: {
          val: JSON.stringify(v),
        },
      });
    },

    //跳消息
    toMessage(v) {
      if (!this.detailsOperationAuthority) {
        // this.$message.error("不是模板创建人，无权操作");
        this.$alert(
          $t('m.您没有权限使用该功能，敬请谅解！'), 
          $t('m.额，好像没有权限'), {
          confirmButtonText: $t('m.知道啦'),
        });
        return;
      }
      console.log(v);
      if (sessionStorage.getItem("Now")) {
        sessionStorage.setItem("strategyMessage", JSON.stringify(v));
        if (location.origin == "http://159.138.87.203") {
          window.open(location.origin + "/TrustFundTest/#/strategyMessage");
        } else {
          window.open(location.origin + "/#/strategyMessage");
        }
      } else {
        this.$router.push({
          path: "strategyMessage",
          query: { executeId: v.executeId },
        });
      }
    },

    // 参数
    parameter(v) {
      if (!this.detailsOperationAuthority) {
        // this.$message.error("不是模板创建人，无权操作");
        this.$alert(
          $t('m.您没有权限使用该功能，敬请谅解！'), 
          $t('m.额，好像没有权限'), {
          confirmButtonText: $t('m.知道啦'),
        });
        return;
      }
      console.log(v);
      let selectStrategy = [];
      selectStrategy.push(v);
      console.log(selectStrategy, v);

      sessionStorage.setItem("selectStrategy", JSON.stringify(selectStrategy));

      this.$emit("getTemplateMessage");
      this.$emit("updateParams");
    },

    // 跳策略详情页
    toStrategy(v) {
      if (!this.detailsOperationAuthority) {
        // this.$message.error("不是模板创建人，无权操作");
        this.$alert(
          $t('m.您没有权限使用该功能，敬请谅解！'), 
          $t('m.额，好像没有权限'), {
          confirmButtonText: $t('m.知道啦'),
        });
        return;
      }
      console.log(v);
      sessionStorage.setItem("accInfo", JSON.stringify(v));
      this.$router.push({ path: "strategyDetail" });
    },
    //修改按钮
    modifyBtn(e) {
      console.log(e);
      if (!this.detailsOperationAuthority) {
        // this.$message.error("不是模板创建人，无权操作");
        this.$alert(
          $t('m.您没有权限使用该功能，敬请谅解！'), 
          $t('m.额，好像没有权限'), {
          confirmButtonText: $t('m.知道啦'),
        });
        return;
      }
      this.$emit("modifyBtn", e);
      // 修改订单链
      // let payload={
      //   token: sessionStorage.getItem("token"),
      //   orderChainId: e.orderChainId,
      // }
      this.$emit("findByIdOrderChain", e);
    },
    //划转按钮
    transferDetermine(e) {
      if (!this.detailsOperationAuthority) {
        // this.$message.error("不是模板创建人，无权操作");
         this.$alert(
          $t('m.您没有权限使用该功能，敬请谅解！'), 
          $t('m.额，好像没有权限'), {
          confirmButtonText: $t('m.知道啦'),
        });
        return;
      }
      this.$emit("transferDetermineBtn", e);
    },
    handleClose(done) {
      this.mark = "";
      done();
    },
    selectable(row, index) {
      if (this.statesShow) {
        return false;
      } else {
        return true;
      }
    },
  },
  created() {
    let detailsOperationAuthority = sessionStorage.getItem(
      "detailsOperationAuthority"
    );
    this.detailsOperationAuthority = JSON.parse(detailsOperationAuthority);
  },
  mounted() {},
};
</script>

<style lang='scss' scoped>
.nav {
  padding: 0 30px;
  background-color: white;
}
.modifyBtn {
  width: 40px;
  height: 20px;
  border: 1px solid #2174ff;
  display: flex;
  color: #2174ff;
  justify-content: center;
  line-height: 20px;
  font-size: 12px;
  margin-right: 10px;
  border-radius: 2px;
  cursor: pointer;
}
.modifyBtn:hover {
  background-color: #2174ff;
  color: white;
}
.modifyStyle {
  display: flex;
  justify-content: flex-end;
  color: #2174ff;
}
.modifyItem {
  height: 20px;
  border: 1px solid #2174ff;
  line-height: 20px;
  font-size: 12px;
  padding: 0 5px;
  border-radius: 2px;
  cursor: pointer;
  margin-right: 10px;
}
.dialog-nav {
  display: flex;
  flex-direction: column;
  .dialog-top {
    border-bottom: 1px solid #f0f0f0;
  }
  .dialog-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
}
/deep/.el-dialog__body {
  border-top: 1px solid #f0f0f0;
  border-bottom: 1px solid #f0f0f0;
  padding-bottom: 0;
}
/deep/.el-dialog__header {
  padding: 12px;
  .el-dialog__title {
    font-size: 16px;
  }
}
/deep/.el-dialog__footer {
  display: flex;
  justify-content: flex-end;
  .el-button--primary {
    padding: 0;
    height: 34px;
    width: 72px;
    background-color: #fff;
    color: #2174ff;
  }
}
/deep/.cell {
  padding: 0 !important;
}
.headerStyle {
  display: flex;
  align-items: center;
  cursor: pointer;
  .headerIcon {
    margin-left: 3px;
  }
}
.brdTipsStyle {
  width: 55px;
  border-bottom: 1px dashed #333;
}

::v-deep.el-dialog__body {
  border-top: 0px solid #fff !important;
  border-bottom: 0px solid #fff !important;
}
/deep/.rightBox {
  padding-right: 10px;
}
</style>