<template>
  <div class="projectMember">

    <div class="projectMember-title">
      <div class="projectMember-num">
        <span>{{$t('m.共&(1)名项目成员').replace('&(1)', memberNum)}}</span>
        <span class="member-permission">{{$t('m.成员权限')}}</span>
        <img
          src="../../assets/icon/jieshi.png"
          alt=""
          @click="jurisdictionTips = true"/>
      </div>
      <div class="add-member">
        <img src="../../assets/icon/xinzeng-bai.png" alt="" />
        <span @click="getUnSelectTeamMember()">{{$t('m.添加成员')}}</span>
      </div>
    </div>

    <div class="projectMember-cont">
      <el-table :data="memberList">

        <el-table-column prop="nickName" :label="$t('m.项目成员')">
          <template slot-scope="scope">
            <div class="iconMy">
              <span>{{ scope.row.nickName }}</span>
              <span class="my" v-if="account.userId == scope.row.userId">({{$t('m.我')}})</span>
              <el-tooltip
                v-if="scope.row.isDefault == 1"
                class="item"
                effect="dark"
                :content="tipsOne"
                placement="top">
                <span class="myIcon">({{$t('m.默认')}})</span>
              </el-tooltip>
            </div>
          </template>
        </el-table-column>

        <el-table-column prop="phoneUnique" :label="$t('m.账号')"></el-table-column>
        <el-table-column :label="$t('m.权限')">
          <template slot-scope="scope">
            <span v-if="scope.row.mark == '超级管理员'">
              <span>{{ scope.row.mark }}</span>
            </span>
            <el-dropdown trigger="click" v-else placement="bottom">
              <span style="cursor: pointer" class="el-dropdown-link">
                <span>{{ scope.row.mark }}</span>
                <i class="el-icon-arrow-down el-icon--right cur"></i>
              </span>
              <el-dropdown-menu slot="dropdown">

                <el-dropdown-item
                  v-if="scope.row.mark !== '管理员'"
                  @click.native="updateMemberRole(scope.row.userId, 'MANAGE')">
                  {{$t('m.管理员')}}</el-dropdown-item>

                <el-dropdown-item
                  v-if="scope.row.mark !== '操作员'"
                  @click.native="updateMemberRole(scope.row.userId, 'OPERATOR')">
                  {{$t('m.操作员')}}</el-dropdown-item>

                <el-dropdown-item>
                  <span
                    class="red"
                    @click="deleteProjectMember(scope.row.userId)">
                    {{$t('m.移出项目')}}</span >
                </el-dropdown-item>

              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>

        <el-table-column :label="$t('m.默认操作员')" width="150px">
          <template slot-scope="scope">
            <span
              style="color: #2174ff; cursor: pointer"
              @click="settingOperator(scope.row.userId)"
              v-if="scope.row.isDefault !== 1">
            {{$t('m.设置默认操作员')}}</span>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <!-- 邀请成员 -->
    <template v-if="dialogVisible">
      <el-dialog
        :title="$t('m.添加成员')"
        :visible.sync="dialogVisible"
        width="26%"
        :before-close="handleClose">
        <div class="dialog-content">

          <el-table
            ref="multipleTable"
            :data="notMemberList"
            tooltip-effect="dark"
            style="width: 100%"
            @selection-change="handleSelectionChange">
            <el-table-column prop="counterCoin" :label="$t('m.成员')" width="180">
              <template slot-scope="scope">
                <span>{{ scope.row.nickName }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="last" :label="$t('m.账号')" width="180">
              <template slot-scope="scope">
                <span>{{ scope.row.phoneUnique }}</span>
              </template>
            </el-table-column>
            <el-table-column type="selection" width="90"> </el-table-column>
          </el-table>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">
            {{$t('m.取 消')}}</el-button>
          <el-button type="primary" @click="addProjectMember()">
            {{$t('m.添 加')}}</el-button>
        </span>
      </el-dialog>
    </template>

    <!-- 权限解释 -->
    <el-dialog
      :title="$t('m.成员权限')"
      center
      :visible.sync="jurisdictionTips"
      width="570px"
      top="260px">
      <div class="jurisdiction">
        <div>
          <span class="title">{{$t('m.超级管理员')}}</span>
          <span>({{$t('m.团队唯一')}})</span>
        </div>
        <div style="border-bottom: 1px solid #e6e7ed" class="box">
          <span>{{$t('m.创建/删除项目')}}</span>
          <span>{{$t('m.管理团队成员')}}</span>
          <span>{{$t('m.兼容管理员')}}</span>
        </div>
        <div>
          <span class="title">{{$t('m.管理员')}}</span>
          <span>({{$t('m.项目唯一')}})</span>
        </div>
        <div style="border-bottom: 1px solid #e6e7ed" class="box">
          <span>{{$t('m.管理项目')}}</span>
          <span>{{$t('m.分流用户')}}</span>
          <span>{{$t('m.兼容操作员')}}</span>
        </div>
        <div>
          <span class="title">{{$t('m.操作员')}}</span>
        </div>
        <div class="box" style="margin: 0">
          <span>{{$t('m.配置自动交易')}}</span>
          <span>{{$t('m.批量操作')}}</span>
        </div>
      </div>
      <div class="btn" @click="jurisdictionTips = false">
        {{$t('m.知道啦')}}</div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getProjectMember,
  settingOperator,
  updateMemberRole,
  deleteProjectMember,
  getUnSelectTeamMember,
  addProjectMember,
} from "../../request/api";
export default {
  props: ["isSuper"],
  components: {},
  data() {
    return {
      account: null,
      dialogVisible: false,
      memberList: [],
      notMemberList: [],
      projectInfo: null,
      defaultTeam: null,
      jurisdictionTips: false, //权限解释
      memberNum: 0,
      projectName: JSON.parse(sessionStorage.getItem("project")).projectName || "",
      tipsOne: this.$t('m.入金用户将自动分配给默认操作员'),
      defaultTips: false, //默认操作员解释
      detailsOperationAuthority: null,

      selectMemberList: null,
    };
  },
  computed: {},
  watch: {},
  methods: {
    handleClose(done) {
      done();
    },
    // 查询项目成员
    getProjectMember() {
      let payload = {
        token: sessionStorage.getItem("token"),
        teamProjectId: JSON.parse(sessionStorage.getItem("project"))
          .teamProjectId,
      };
      getProjectMember(payload).then((res) => {
        if (res.status == 200) {
          this.memberList = res.data.memberList;
          this.memberNum = this.memberList.length;
          console.log(res.data.memberList);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 设置默认操作员
    settingOperator(id) {
      if (!this.detailsOperationAuthority) {
        // this.$message.error("不是模板创建人，无权操作");
        this.$alert(this.$t('m.您没有权限使用该功能，敬请谅解！'), this.$t('m.额，好像没有权限'), {
          confirmButtonText: this.$t('m.知道啦'),
        });
        return;
      }
      if (!this.isSuper) {
        return this.$alert(
          this.$t('m.您没有权限使用该功能，敬请谅解！'),
          this.$t('m.额，好像没有权限'),
          {
            confirmButtonText: this.$t('m.知道啦'),
          }
        );
      } else {
        let payload = {
          teamProjectId: JSON.parse(sessionStorage.getItem("project"))
            .teamProjectId,
          token: sessionStorage.getItem("token"),
          userId: id,
        };
        settingOperator(payload).then((res) => {
          if (res.status == 200) {
            this.$message.success(this.$t('m.设置成功'));
            this.getProjectMember();
            console.log(res.data);
          } else {
            this.$message.error(res.msg);
          }
        });
      }
    },
    // 修改成员角色
    updateMemberRole(id, type) {
      console.log(this.isSuper);
      // return;
      if (!this.detailsOperationAuthority) {
        // this.$message.error("不是模板创建人，无权操作");
        this.$alert(this.$t('m.您没有权限使用该功能，敬请谅解！'), this.$t('m.额，好像没有权限'), {
          confirmButtonText: this.$t('m.知道啦'),
        });
        return;
      }
      if (!this.isSuper) {
        return this.$alert(
          this.$t('m.您没有权限使用该功能，敬请谅解！'),
          this.$t('m.额，好像没有权限'),
          {
            confirmButtonText: this.$t('m.知道啦'),
          }
        );
      } else {
        console.log(id, type);

        let payload = {
          token: sessionStorage.getItem("token"),
          roleName: type,
          byUserId: id,
          teamProjectId: JSON.parse(sessionStorage.getItem("project"))
            .teamProjectId,
        };
        updateMemberRole(payload).then((res) => {
          if (res.status == 200) {
            this.$message.success(this.$t('m.设置成功'));
            this.getProjectMember();
            console.log(res.data);
          } else {
            this.$message.error(res.msg);
          }
        });
      }
    },
    // 移除项目成员
    deleteProjectMember(id) {
      if (!this.detailsOperationAuthority) {
        // this.$message.error("不是模板创建人，无权操作");
        this.$alert(this.$t('m.您没有权限使用该功能，敬请谅解！'), this.$t('m.额，好像没有权限'), {
          confirmButtonText: this.$t('m.知道啦'),
        });
        return;
      }
      if (!this.isSuper) {
        return this.$alert(
          this.$t('m.您没有权限使用该功能，敬请谅解！'),
          this.$t('m.额，好像没有权限'),
          {
            confirmButtonText: this.$t('m.知道啦'),
          }
        );
      } else {
        this.$confirm(
          this.$t('m.移出项目后，该成员所属的用户将被分配给项目管理员'),
          this.$t('m.移出项目'),
          {
            confirmButtonText: this.$t('m.确定'),
            cancelButtonText: this.$t('m.取消'),
            closeOnClickModal: false,
          }
        )
          .then(() => {
            console.log(id);
            let payload = {
              teamProjectId: JSON.parse(sessionStorage.getItem("project"))
                .teamProjectId,
              token: sessionStorage.getItem("token"),
              userId: id,
            };
            deleteProjectMember(payload).then((res) => {
              if (res.status == 200) {
                this.$message.success(this.$t('m.移出成功'));
                this.getProjectMember();
                console.log(res.data);
              } else {
                this.$message.error(res.msg);
              }
            });
          })
          .catch(() => {});
      }
    },
    // 查询未选成员
    getUnSelectTeamMember() {
      if (!this.detailsOperationAuthority) {
        // this.$message.error("不是模板创建人，无权操作");
        this.$alert(this.$t('m.您没有权限使用该功能，敬请谅解！'), this.$t('m.额，好像没有权限'), {
          confirmButtonText: this.$t('m.知道啦'),
        });
        return;
      }
      if (!this.isSuper) {
        return this.$alert(
          this.$t('m.您没有权限使用该功能，敬请谅解！'),
          this.$t('m.额，好像没有权限'),
          {
            confirmButtonText: this.$t('m.知道啦'),
          }
        );
      }
      this.dialogVisible = true;
      let payload = {
        teamId: this.defaultTeam.teamId,
        token: sessionStorage.getItem("token"),
        teamProjectId: this.projectInfo.teamProjectId,
      };
      getUnSelectTeamMember(payload).then((res) => {
        if (res.status == 200) {
          this.notMemberList = res.data.memberList;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 选中
    change(item) {
      item.type = !item.type ? true : false;
      console.log(item);
    },

    // 勾选
    handleSelectionChange(val) {
      this.selectMemberList = val;
      console.log(val, this.selectMemberList);
    },
    // 添加成员
    addProjectMember() {
      // let list = this.notMemberList;
      // let newList = list.filter(function (item) {
      //   return item.type;
      // });
      // let userIds = [];
      // for (let i = 0; i < newList.length; i++) {
      //   userIds.push(newList[i].userId);
      // }
      // console.log(newList, userIds);

      let list = this.selectMemberList;
      let userIds = [];
      for (let val of list) {
        userIds.push(val.userId);
      }

      let payload = {
        teamProjectId: this.projectInfo.teamProjectId,
        token: sessionStorage.getItem("token"),
        userIds: userIds,
      };
      addProjectMember(payload).then((res) => {
        if (res.status == 200) {
          console.log(res.data);
          this.$message.success(this.$t('m.添加成功'));
          this.getProjectMember();
          this.selectMemberList = [];
          this.dialogVisible = false;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
  created() {
    let detailsOperationAuthority = sessionStorage.getItem(
      "detailsOperationAuthority"
    );
    this.detailsOperationAuthority = JSON.parse(detailsOperationAuthority);
  },
  mounted() {
    let projectInfo = JSON.parse(sessionStorage.getItem("project"));
    this.projectInfo = projectInfo;
    let defaultTeam = sessionStorage.getItem("defaultTeam");
    this.defaultTeam = JSON.parse(defaultTeam);
    this.account = JSON.parse(sessionStorage.getItem("account"));
    this.getProjectMember();
  },
  beforeCreate() {},
  beforeMount() {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {},
  activated() {},
};
</script>
<style lang='scss' scoped>
//@import url(); 引入公共css类
.projectMember {
  font-size: 16px;
  color: #55698a;
  height: calc(100% - 40px);
  min-height: 550px;
  background: #fff;
  padding: 20px;
  .projectMember-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 40px 30px 40px;
    .member-permission {
      margin: 0 4px 0 10px;
      font-size: 14px;
    }

    img {
      width: 12px;
      height: 12px;
      cursor: pointer;
    }
  }
  .add-member {
    width: 148px;
    height: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #2174ff;
    border-radius: 4px;
    color: #fff;
    font-size: 14px;
    cursor: pointer;
    img {
      width: 14px;
      height: 14px;
      margin-right: 6px;
    }
  }
  .projectMember-cont {
    padding: 10px 30px;
    border: 1px solid #e6e9ee;
  }
  .cur {
    cursor: pointer;
  }
  .red {
    color: #e12525 !important;
  }
  .iconMy {
    display: flex;
  }
  .my {
    color: #2174ff;
    margin-left: 4px;
  }
  .myIcon {
    display: flex;
    align-items: center;
    margin-left: 5px;
    cursor: pointer;
    border-bottom: 1px dashed #333;
  }
  .dialog-content {
    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 10px;
      margin-bottom: 10px;
      border-bottom: 1px solid #f5f5f5;
      :nth-child(1) {
        width: 40%;
      }
      span {
        width: 25%;
      }
      :last-child {
        text-align: right;
      }
    }
    .content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 14px;
      color: #333;
      margin-bottom: 20px;
      div {
        width: 40%;
        display: flex;
        align-items: center;
        img {
          width: 40px;
          height: 40px;
          border-radius: 50%;
        }
        .members-name {
          margin: 0 8px;
        }
        :last-child {
          color: #999999;
          font-size: 12px;
        }
      }
      .members-phone,
      .members-check {
        width: 25%;
      }
      .members-check {
        text-align: right;
      }
    }
  }
  .jurisdiction {
    color: #999;
    font-size: 12px;
    line-height: 20px;
    .title {
      color: #333;
      margin-right: 5px;
    }
    .box {
      padding: 3px 0 18px;
      margin: 0 0 20px;
    }
    span {
      margin-right: 10px;
    }
  }
  .btn {
    color: #007aff;
    display: flex;
    font-size: 14px;
    justify-content: flex-end;
    margin-right: 10px;
    cursor: pointer;
  }
}
</style>