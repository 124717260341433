<template>
  <div class="box">

    <div class="top">
      <div style="display: flex; align-items: center">
        <img
          style="margin-right: 20px; cursor: pointer"
          @click="backBtn"
          src="../../assets/icon/icon_back@3x.png"
          alt=""
          v-if="!showName"/>
        <span style="margin-top: 1px">{{
          intelligencOrderChain ? intelligencOrderChain.label : "--"
        }}</span>
      </div>
    </div>

    <div class="nav">
      <div class="nav-top">
        <div class="nav-name">
          <div>
            {{$t('m.智能订单')+'-'}}
            {{
              intelligencOrderChain
              ? intelligencOrderChain.transactionType == "spot" ? $t('m.币币') : $t('m.合约')
              : "--"
            }}
          </div>
          <div>
            {{
              intelligencOrderChain
                ? intelligencOrderChain.remarkCode
                  ? intelligencOrderChain.remarkCode
                  : intelligencOrderChain.publicPeopleName
                : "--"
            }}
          </div>
          <div v-html="order"></div>
        </div>
        <div class="nav-btn">

          <div v-if="intelligencOrderChain.states !== 'COMPLETED'"
            @click="stopSubmit">
            {{$t('m.终止订单链')}}</div>

          <div @click="messageBtn">
            {{$t('m.消息')}}</div>

          <template v-if="orderType == 1">
            <div @click="modifyBtn" v-if="this.intelligencOrderChain.states !== 'EXECUTE'">
              {{$t('m.查看订单链')}}
            </div>
            <div @click="modifyBtn" v-else>
              {{$t('m.修改订单链')}}
            </div>
          </template>
        </div>
      </div>
      <div class="nav-box">
        <div class="title">
          {{$t('m.智能订单信息')}}：</div>
        <div class="specialBox">
          <div>
            <span class="specialTitle">
              {{$t('m.启动时间')}}</span>
            <span>{{intelligencOrderChain ? intelligencOrderChain.createTime : "--"}}</span>
          </div>
          <div>
            <span class="specialTitle">
              {{$t('m.执行时长')}}</span>
            <span>{{
              intelligencOrderChain ? intelligencOrderChain.endTime : "--"
            }}</span>
          </div>
          <div>
            <span class="specialTitle">
              {{$t('m.账户')}}</span>
            <span>{{
              intelligencOrderChain
                ? intelligencOrderChain.remarkCode ? intelligencOrderChain.remarkCode : intelligencOrderChain.publicPeopleName
                : "--"
            }}</span>
          </div>
          <div>
            <span class="specialTitle">
              {{$t('m.初始投入')}}
            </span>
            <span>{{intelligencOrderChain ? intelligencOrderChain.investNum : "--"}}</span>
          </div>
          <div>
            <span class="specialTitle">
              {{$t('m.当前订单')}}</span>
            <span>
              {{intelligencOrderChain ? intelligencOrderChain.executeStatesName : "--"}}</span>
          </div>
          <div>
            <span class="specialTitle">
              {{$t('m.订单方向')}}</span>
            <span :style="intelligencOrderChain.orderDirectionStyle">
              {{intelligencOrderChain ? intelligencOrderChain.orderDirection : "--"}}</span>
          </div>
          <div>
            <span class="specialTitle">
              {{$t('m.订单状态')}}</span>
            <span>
              {{intelligencOrderChain ? intelligencOrderChain.orderStates : "--"}}</span>
          </div>
          <div>
            <span class="specialTitle">
              {{$t('m.订单链状态')}}</span>
            <span>{{
              intelligencOrderChain
                ? intelligencOrderChain.states == "COMPLETED" ? $t('m.已完成') : $t('m.执行中')
                : "--"}}</span>
          </div>
        </div>
      </div>
      <div class="nav-box">
        <div class="title">
          {{$t('m.持仓')}}:</div>
        <div>
          <div class="specialRow" style="color: #55698a">
            <div>{{$t('m.币种')}}</div>
            <div>{{$t('m.数量')}}</div>
          </div>
          <template v-for="(item, index) in positionList">
            <div class="specialRow" :key="index">
              <div class="specialRowFlex">
                <span>{{ item.name }}</span>
                <span :style="item.style">{{
                  item.tradeType ? item.tradeType : ""
                }}</span>
              </div>
              <div>{{ item.price }}</div>
            </div>
          </template>
        </div>
      </div>
      <div class="nav-box" v-if="entrustShow">
        <div class="title">{{$t('m.当前委托')}}:</div>
        <div>
          <div class="specialRow" style="color: #55698a">
            <div>{{$t('m.委托时间')}}</div>
            <div>{{$t('m.交易对')}}</div>
            <div>{{$t('m.方向')}}</div>
            <div>{{$t('m.委托数量')}}</div>
            <div>{{$t('m.委托价格')}}</div>
          </div>
          <template v-for="item in entrustList">
            <div class="specialRow" :key="item.id">
              <div>{{ item.orderTime }}</div>
              <div>{{ item.baseCoin + "/" + item.cointerCoin }}</div>
              <div>{{ item.type }}</div>
              <div>{{ item.amount }}</div>
              <div>{{ item.price + " " + item.baseCoin }}</div>
            </div>
          </template>
        </div>
      </div>
      <div class="nav-box">
        <div class="title">{{$t('m.历史记录')}}:</div>
        <div>
          <div class="specialRow" style="color: #55698a">
            <div>{{$t('m.订单')}}</div>
            <div>{{$t('m.执行动作')}}</div>
            <div>{{$t('m.交易对')}}</div>
            <div>{{$t('m.方向')}}</div>
            <div>{{$t('m.成交数量')}} | {{$t('m.成交金额')}}</div>
            <div>{{$t('m.成交均价')}}</div>
            <div>{{$t('m.操作')}}</div>
          </div>
          <template v-for="item in historyList">
            <div class="specialRow" :key="item.id">
              <div>{{ item.displayConfig.name }}</div>
              <div>{{ item.label }}</div>
              <div>{{ item.transactionPair }}</div>
              <div :style="item.orderDirectionStyle">
                {{ item.orderDirection }}
              </div>
              <div>{{ item.investNow + " " + item.investUnit }}</div>
              <div>{{ item.price }}</div>
              <div class="recordBtn" @click="tradeClick(item)">{{$t('m.成交记录')}}</div>
            </div>
          </template>
        </div>
      </div>
    </div>
    <el-dialog
      :title="$t('m.成交记录')"
      :visible.sync="dialogRecord"
      center
      width="997px"
      :before-close="handleClose">
      <div>
        <div class="specialRow" style="color: #55698a; margin: 0">
          <div>{{$t('m.委托时间')}}</div>
          <div>{{$t('m.交易对')}}</div>
          <div>{{$t('m.方向')}}</div>
          <div>{{$t('m.成交价格')}}</div>
          <div>{{$t('m.成交数量')}}</div>
          <div>{{$t('m.成交金额')}}</div>
        </div>
        <template v-for="(item, index) in queryOrderList">
          <div class="specialRow" :key="'queryOrderList'+index">
            <div>{{ item.finishTime }}</div>
            <div>
              {{ item.baseCoin + "/" + item.counterCoin }}
            </div>
            <div :style="item.tradeTypeStyle">
              {{ item.tradeType }}
            </div>
            <div>
              {{ Number(item.dealPrice) + " " + item.baseCoin }}
            </div>
            <div>{{ item.dealNumber }}</div>
            <div>
              {{ item.number }}
            </div>
          </div>
        </template>
      </div>
    </el-dialog>
    <el-dialog
      :title="$t('m.终止订单')"
      :visible.sync="activeShow"
      width="400px"
      center
      :before-close="handleClose"
      :close-on-click-modal="false">
      <div class="dialog-nav">
        <div class="dialog-top">
          {{$t('m.确认终止后订单将自动撤消未成交的委托单。')}}</div>
      </div>
      <div slot="footer">
        <div class="dialog-footer">
          <div class="footerBack" @click="activeShow = false">
            {{$t('m.取消')}}</div>
          <div class="footerBtn" @click="activeBtn">
            {{$t('m.确定')}}</div>
        </div>
      </div>
    </el-dialog>

    <el-dialog
      :title="$t('m.订单链')"
      :visible.sync="dialogVisible"
      width="70%"
      :before-close="handleClose">
      <div>
        <OrderChain
          ref="child"
          :transactionType="transactionType"
          :controlsPrice="controlsPrice"/>
      </div>
      <span v-if="!btnSubmitShow" slot="footer" class="dialog-footer">
        <el-button
          @click="
            dialogVisible = false;
            batchModify = false;">
          {{$t('m.取 消')}}</el-button>
        <el-button type="primary" @click="updateOrderChain()">
          {{$t('m.确 定')}}</el-button>
      </span>
      <span v-else slot="footer" class="dialog-footer">
        <el-button
          @click="dialogVisible = false; batchModify = false;">
          {{$t('m.关 闭')}}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  orderChainDetails,
  findByIdOrderChain,
  userManagementExchangelist,
  queryFinishOrderList,
  batchOrderChainEnd,
  updateOrderChain,
  batchUpdateOrderChain,
} from "../../request/api";

import OrderChain from "../../components/orderChain.vue";
export default {
  components: { OrderChain },
  data() {
    return {
      dialogRecord: false, //成交记录
      dialogVisible: false, //订单链
      activeShow: false, //终止订单链
      btnSubmitShow: false,
      intelligencOrderChain: "", //页面数据
      entrustShow: false, //当前委托
      entrustList: [], //委托数据
      positionList: [], //持仓数据
      historyList: [], //历史记录
      orderChainId: null,
      controlsPrice: null,
      transactionType: null,
      exchangeLimit: null, //交易所
      exchangeName: null, //当前交易所
      queryOrderList: [],
      orderType: null,
      showName: false,
    };
  },
  methods: {
    backBtn() {
      this.$router.back(-1);
    },
    handleClose() {
      this.dialogRecord = false;
      this.dialogVisible = false;
      this.activeShow = false;
    },
    //页面数据
    orderChainDetails(payload) {
      orderChainDetails(payload).then((res) => {
        if (res.status == 200) {
          console.log(res);
          //页面数据
          let intelligencOrderChain = res.data.intelligencOrderChain;
          this.orderType =
            res.data.intelligencOrderChain.orderList[0].orderType;
          console.log(res.data.intelligencOrderChain.orderList[0].orderType);
          if (intelligencOrderChain.states == "COMPLETED") {
            intelligencOrderChain.endTime = this.date_format(
              intelligencOrderChain.endTime - intelligencOrderChain.createTime
            );
          } else {
            intelligencOrderChain.endTime = this.date_format(
              Date.parse(new Date()) - intelligencOrderChain.createTime
            );
          }
          intelligencOrderChain.createTime = this.timestampToTime(
            intelligencOrderChain.createTime
          );
          if (intelligencOrderChain.transactionType == "contract_usdt") {
            intelligencOrderChain.investNum =
              intelligencOrderChain.orderList[
                intelligencOrderChain.orderList.length - 1
              ].investNum + " " + this.$t('m.张');
          } else {
            intelligencOrderChain.investNum =
              intelligencOrderChain.orderList[
                intelligencOrderChain.orderList.length - 1
              ].investNum +
              " " +
              intelligencOrderChain.orderList[
                intelligencOrderChain.orderList.length - 1
              ].investUnit;
          }

          intelligencOrderChain.totalIncomeNum =
            intelligencOrderChain.totalIncomeNum
              ? intelligencOrderChain.totalIncomeNum
              : 0;
          let orderList = "";
          if (intelligencOrderChain.states == "COMPLETED") {
            orderList =
              intelligencOrderChain.orderList[
                intelligencOrderChain.orderList.length - 1
              ];
            this.entrustShow = false;
          } else {
            for (
              let i = intelligencOrderChain.orderList.length - 1;
              i >= 0;
              i--
            ) {
              if (
                intelligencOrderChain.orderList[i].executeStates == "EXECUTE"
              ) {
                orderList = intelligencOrderChain.orderList[i];
              }
            }
            this.entrustShow = true;
            //当前委托
            let entrust = res.data.entrust;

            let entrustList = [];
            if (
              res.data.intelligencOrderChain.transactionType == "contract_usdt"
            ) {
              if (entrust.unfinish_orders) {
                entrust.unfinish_orders = JSON.parse(
                  entrust.unfinish_orders
                ).orders;
                if (entrust.unfinish_orders.length > 0) {
                  entrust.unfinish_orders.forEach((e) => {
                    console.log(e);
                    if (e.tradeOffset == 1) {
                      if (e.tradeType == 1) {
                        e.type = this.$t('m.做多');
                      } else {
                        e.type = this.$t('m.做空');
                      }
                    } else {
                      if (e.tradeType == 1) {
                        e.type = this.$t('m.平多');
                      } else {
                        e.type = this.$t('m.平空');
                      }
                    }
                  });
                }

                entrustList.push(...entrust.unfinish_orders);
              } else {
                this.entrustShow = false;
              }
            } else {
              if (entrust.unfinish_buy_orders) {
                entrust.unfinish_buy_orders = JSON.parse(
                  entrust.unfinish_buy_orders
                ).orders;
                entrust.unfinish_buy_orders.forEach((e) => {
                  e.type = this.$t('m.买入');
                });
              }
              if (entrust.unfinish_sell_orders) {
                entrust.unfinish_sell_orders = JSON.parse(
                  entrust.unfinish_sell_orders
                ).orders;
                entrust.unfinish_sell_orders.forEach((e) => {
                  e.type = this.$t('m.卖出');
                });
              }

              entrustList.push(
                ...entrust.unfinish_buy_orders,
                ...entrust.unfinish_sell_orders
              );
            }
            entrustList.forEach((e) => {
              e.orderTime = this.timestampToTime(e.orderTime);
            });
            this.entrustList = entrustList;
          }
          intelligencOrderChain.executeStatesName = JSON.parse(
            orderList.displayConfig
          ).name;
          if (
            res.data.intelligencOrderChain.transactionType == "contract_usdt"
          ) {
            intelligencOrderChain.orderDirection =
              JSON.parse(orderList.displayConfig).singleDirection +
              " " +
              JSON.parse(orderList.displayConfig).leverRate +
              "x";
          } else {
            intelligencOrderChain.orderDirection =
              orderList.orderDirection == "buy" ? this.$t('m.买入') : this.$t('m.卖出');
          }
          console.log(orderList.states);
          intelligencOrderChain.orderStates =
            orderList.states == "WAIT_TRIGGER"
              ? this.$t('m.等待触发')
              : orderList.states == "WAIT_DEAL"
              ? this.$t('m.等待成交')
              : orderList.states == "ALREADY_DEAL"
              ? this.$t('m.成交成功')
              : orderList.states == "ERROR"
              ? this.$t('m.订单异常')
              : "--";
          // 区分开订单链状态和订单状态
          // if (orderList.executeStates == "STOP") {
          //   intelligencOrderChain.orderStates = "已完成";
          // }
          let keepInfo = [];
          let nameList = [];
          let historyList = [];
          for (
            let i = intelligencOrderChain.orderList.length - 1;
            i >= 0;
            i--
          ) {
            intelligencOrderChain.orderList[i].orderDeal = JSON.parse(
              intelligencOrderChain.orderList[i].orderDeal
            );
            intelligencOrderChain.orderList[i].orderDeal.forEach((e) => {
              e.finishTime = this.timestampToTime(e.finishTime);
              if (e.baseCoin == "btc") {
                e.usdtVolume = e.usdtVolume.slice(
                  0,
                  e.usdtVolume.indexOf(".") + 7
                );
              } else {
                e.usdtVolume = e.usdtVolume.slice(
                  0,
                  e.usdtVolume.indexOf(".") + 5
                );
              }
              if (e.tradeType == 0) {
                if (e.counterCoin == "btc") {
                  e.dealAmount = e.dealAmount.slice(
                    0,
                    e.dealAmount.indexOf(".") + 7
                  );
                  e.usdtVolume = e.usdtVolume.slice(
                    0,
                    e.usdtVolume.indexOf(".") + 5
                  );
                } else {
                  e.dealAmount = e.dealAmount.slice(
                    0,
                    e.dealAmount.indexOf(".") + 5
                  );
                  e.usdtVolume = e.usdtVolume.slice(
                    0,
                    e.usdtVolume.indexOf(".") + 7
                  );
                }
              } else {
                if (e.baseCoin == "btc") {
                  e.usdtVolume = e.usdtVolume.slice(
                    0,
                    e.usdtVolume.indexOf(".") + 7
                  );
                  e.dealAmount = e.dealAmount.slice(
                    0,
                    e.dealAmount.indexOf(".") + 5
                  );
                } else {
                  e.usdtVolume = e.usdtVolume.slice(
                    0,
                    e.usdtVolume.indexOf(".") + 5
                  );
                  e.dealAmount = e.dealAmount.slice(
                    0,
                    e.dealAmount.indexOf(".") + 7
                  );
                }
              }
              e.number = e.usdtVolume + " USDT";
              e.dealNumber = e.dealAmount + " " + e.counterCoin;
              console.log(e.dealNumber);
            });

            if (
              intelligencOrderChain.orderList[i].states == "ALREADY_DEAL" &&
              intelligencOrderChain.orderList[i].executeStates !== "EXECUTE"
            ) {
              intelligencOrderChain.orderList[i].displayConfig = JSON.parse(
                intelligencOrderChain.orderList[i].displayConfig
              );
              let keepInfo = JSON.parse(
                intelligencOrderChain.orderList[i].keepInfo
              );
              intelligencOrderChain.orderList[i].price = keepInfo.price;
              intelligencOrderChain.orderList[i].invest = keepInfo.invest;
              intelligencOrderChain.orderList[
                i
              ].displayConfig.actionList.forEach((item, index) => {
                if (item.isCheck == true) {
                  if (intelligencOrderChain.orderList[i].actionIndex == index) {
                    intelligencOrderChain.orderList[i].label = item.label;
                  }
                } else {
                  intelligencOrderChain.orderList[i].actionIndex++;
                }
              });

              if (intelligencOrderChain.orderList[i].investNum !== "") {
                for (const key in keepInfo) {
                  if (key == intelligencOrderChain.orderList[i].investUnit) {
                    intelligencOrderChain.orderList[i].investNow =
                      intelligencOrderChain.orderList[i].investNum -
                      keepInfo[key];
                  }
                }
              } else {
                for (const key in keepInfo) {
                  if (key == intelligencOrderChain.orderList[i].investUnit) {
                    intelligencOrderChain.orderList[i].investNow =
                      historyList[historyList.length - 1].investNow /
                        JSON.parse(historyList[historyList.length - 1].keepInfo)
                          .price -
                      keepInfo[key];
                  }
                }
              }
              historyList.push(intelligencOrderChain.orderList[i]);
            }
            if (intelligencOrderChain.transactionType == "contract_usdt") {
              let orderDeal =
                intelligencOrderChain.orderList[
                  intelligencOrderChain.orderList.length - 1
                ].orderDeal[0];

              console.log(orderDeal);

              if (orderDeal && orderDeal.tradeOffset == 1) {
                orderDeal.tradeType =
                  orderDeal.tradeType == 0 ? this.$t('m.做空') : this.$t('m.做多');
                this.positionList = [
                  {
                    price: orderDeal.dealAmount + ' ' + this.$t('m.张'),
                    name: orderDeal.counterCoin,
                    tradeType: orderDeal.tradeType,
                    style:
                      orderDeal.tradeType == 0 ? "color:red" : "color:green",
                  },
                ];
              } else {
                this.positionList = [];
              }
            } else {
              for (const key in JSON.parse(
                intelligencOrderChain.orderList[i].keepInfo
              )) {
                if (key !== "price" && key !== "invest") {
                  let priceNumber;
                  if (key == "BTC") {
                    priceNumber = Number(
                      JSON.parse(intelligencOrderChain.orderList[i].keepInfo)[
                        key
                      ].slice(
                        0,
                        JSON.parse(intelligencOrderChain.orderList[i].keepInfo)[
                          key
                        ].indexOf(".") + 7
                      )
                    );
                  } else {
                    priceNumber = Number(
                      JSON.parse(intelligencOrderChain.orderList[i].keepInfo)[
                        key
                      ].slice(
                        0,
                        JSON.parse(intelligencOrderChain.orderList[i].keepInfo)[
                          key
                        ].indexOf(".") + 5
                      )
                    );
                  }
                  keepInfo.push({
                    name: key,
                    price: priceNumber,
                  });
                  nameList.push(key);
                }
              }

              nameList = [...new Set(nameList)];
              let nowList = [];
              for (const key in nameList) {
                nowList.push({ name: nameList[key], price: 0 });
              }

              for (let i = 0; i < nowList.length; i++) {
                for (let item = 0; item < keepInfo.length; item++) {
                  if (nowList[i].name == keepInfo[item].name) {
                    nowList[i].price += keepInfo[item].price;
                  }
                }
              }
              this.positionList = nowList;
            }
          }
          historyList.forEach((e) => {
            if (e.transactionType == "contract_usdt") {
              e.orderDirection =
                e.orderDirection == "open_many"
                  ? this.$t('m.开多')+" " + e.displayConfig.leverRate + "x"
                  : //买
                  e.orderDirection == "open_empty"
                  ? this.$t('m.开空')+" " + e.displayConfig.leverRate + "x"
                  : e.orderDirection == "flat_many"
                  ? this.$t('m.平多')+" " + e.displayConfig.leverRate + "x"
                  : e.orderDirection == "flat_empty"
                  ? this.$t('m.平空')+" " + e.displayConfig.leverRate + "x"
                  : "";
              e.investNow = 0;
              if (e.transactionPair.indexOf("SWAP") > 0) {
                e.transactionPair = e.transactionPair.split("-SWAP")[0];
              } else if (e.transactionPair.indexOf("/") > 0) {
                e.transactionPair = e.transactionPair.split("/")[0];
              }
              e.orderDeal.forEach((item) => {
                e.investNow += +Number(item.dealAmount);
              });
              console.log(e.investNow);
              e.investUnit = this.$t('m.张');
            } else {
              e.orderDirection = e.orderDirection == "buy" ? this.$t('m.买入') : this.$t('m.卖出');
            }
            if (e.investNow) {
              e.investNow = e.investNow.toString();
              if (e.investUnit == "BTC") {
                e.investNow = e.investNow.slice(
                  0,
                  e.investNow.indexOf(".") + 7
                );
              } else {
                e.investNow = e.investNow.slice(
                  0,
                  e.investNow.indexOf(".") + 5
                );
              }
            }
            e.orderDirectionStyle =
              e.orderDirection.indexOf(this.$t('m.开多')) > -1
                ? "color:#E12525"
                : e.orderDirection.indexOf(this.$t('m.平空')) > -1
                ? "color:#E12525"
                : e.orderDirection.indexOf(this.$t('m.开空')) > -1
                ? "color:#26B34B"
                : e.orderDirection.indexOf(this.$t('m.平多')) > -1
                ? "color:#26B34B"
                : e.orderDirection == this.$t('m.买入')
                ? "color:#26B34B"
                : "color:#E12525";
          });
          console.log(historyList);

          this.historyList = historyList;
          intelligencOrderChain.orderDirectionStyle =
            intelligencOrderChain.orderDirection.indexOf(this.$t('m.开多')) > -1
              ? "color:#E12525"
              : intelligencOrderChain.orderDirection.indexOf(this.$t('m.平空')) > -1
              ? "color:#E12525"
              : intelligencOrderChain.orderDirection.indexOf(this.$t('m.开空')) > -1
              ? "color:#26B34B"
              : intelligencOrderChain.orderDirection.indexOf(this.$t('m.平多')) > -1
              ? "color:#26B34B"
              : intelligencOrderChain.orderDirection.indexOf(this.$t('m.买入')) > -1
              ? "color:#26B34B"
              : "color:#E12525";

          this.intelligencOrderChain = intelligencOrderChain;
        }
      });
    },

    // 查询成交记录
    tradeClick(item) {
      console.log(item);

      item.orderDeal.forEach((e) => {
        if (item.transactionType == "contract_usdt") {
          e.dealNumber = e.dealAmount + ' ' + this.$t('m.张');
          e.tradeTypeStyle =
            e.tradeType == this.$t('m.开多')
              ? "color:#E12525"
              : e.tradeType == this.$t('m.平空')
              ? "color:#E12525"
              : e.tradeType == this.$t('m.开空')
              ? "color:#26B34B"
              : e.tradeType == this.$t('m.平多')
              ? "color:#26B34B"
              : e.tradeType == this.$t('m.买入')
              ? "color:#26B34B"
              : "color:#E12525";
          e.tradeType = item.orderDirection;
        } else {
          e.tradeType = item.orderDirection;
          e.tradeTypeStyle =
            e.tradeType == this.$t('m.买入') ? "color:#26B34B" : "color:#E12525";
        }
      });
      this.queryOrderList = item.orderDeal;

      this.dialogRecord = true;
    },

    //终止按钮
    stopSubmit() {
      this.activeShow = true;
    },
    //消息按钮
    messageBtn() {
      this.$router.push({
        path: "/orderMessage",
        query: {
          orderChainId: this.orderChainId,
          exchangeName: this.intelligencOrderChain.remarkCode
            ? this.intelligencOrderChain.remarkCode
            : this.intelligencOrderChain.publicPeopleName,
        },
      });
    },
    //终止确认按钮
    activeBtn() {
      let payload = {
        token: sessionStorage.getItem("token"),
        orderChainStr: this.orderChainId,
        transactionType: this.intelligencOrderChain.transactionType,
      };
      batchOrderChainEnd(payload).then((res) => {
        if (res.status == 200) {
          this.activeShow = false;
          this.$router.back(-1);
        }
      });
    },
    //查看订单链
    modifyBtn() {
      this.dialogVisible = true;
      let payload = {
        token: sessionStorage.getItem("token"),
        orderChainId: this.orderChainId,
      };
      if (this.intelligencOrderChain.states !== "EXECUTE") {
        this.btnSubmitShow = true;
      } else {
        this.btnSubmitShow = false;
      }
      if (this.$refs.child) {
        this.$refs.child.orderIndex = 0;
      }
      this.findByIdOrderChain(payload);
    },

    // 查询订单链详情
    findByIdOrderChain(payload) {
      findByIdOrderChain(payload).then((res) => {
        if (res.status == 200) {

          let orderType = res.data.intelligencOrderChain.transactionType;

          if (orderType == "spot") {
            this.transactionType = "spot";
            this.orderList = JSON.parse(
              JSON.stringify(res.data.intelligencOrderChain)
            );

            let list = res.data.intelligencOrderChain.orderList;

            console.log(list);
            let newList = [];
            for (let key of list) {
              console.log(key);
              let item = JSON.parse(key.displayConfig);
              console.log(key.executeStates, item);
              item.executeStates = key.executeStates;

              if (key.actionIndex) {
                item.actionIndex = key.actionIndex;
                // 判断是否有关闭的动作
                for (let i = 0; i < item.actionList.length; i++) {
                  console.log(item.actionList[i]);
                  if (!item.actionList[i].isCheck) {
                    // console.log('111111111111111',i)
                    item.actionIndex = Number(item.actionIndex) + 1 + "";
                  }
                  // console.log(item.actionList[i].isCheck)
                }
              }

              newList.push(item);
            }

            newList.reverse();

            this.$refs.child.orderList = newList;
            this.$refs.child.singleDirection = newList[0].singleDirection;
            this.$refs.child.investmentType = newList[0].investmentType;
            this.$refs.child.actionIdx = 0;
            this.$refs.child.showPair =
              newList[0].pair.counterCoin + "/" + newList[0].pair.baseCoin;
            this.$refs.child.investment = newList[0].investment;
            this.$refs.child.createdActionList = newList[0].actionList;
            this.$refs.child.inputCurrency = newList[0].inputCurrency;
            this.$refs.child.actionParams = JSON.parse(
              newList[0].actionList[0].params
            );

            console.log(newList);

            if (this.batchModify) {
              this.$refs.child.executeStates = true;
            } else {
              this.$refs.child.executeStates = false;
            }
            setTimeout(() => {
              this.$refs.child.$refs.child.percentage =
                newList[0].actionList[0].percentage;
              // this.$refs.child.$refs.child.executeStates=list[0].executeStates
            }, 300);
            this.$refs.child.isUpdate = true;
            this.$refs.child.detailIsFalse = false;

            console.log(newList);
            console.log(1, this.$refs.child.orderList);
          } else {
            this.transactionType = "contract_usdt";
            this.orderList = JSON.parse(
              JSON.stringify(res.data.intelligencOrderChain)
            );

            let list = res.data.intelligencOrderChain.orderList;

            this.controlsPrice = Number(list[list.length - 1].price);

            console.log(list);
            let newList = [];

            for (let key of list) {
              let item = JSON.parse(key.displayConfig);
              console.log(key.executeStates, item, key);
              item.executeStates = key.executeStates;
              item.actionIndex = key.actionIndex;
              newList.push(item);
            }

            newList.reverse();

            console.log(newList);

            // // 查询合约交易对
            let payload = {
              teamProjectId: JSON.parse(sessionStorage.getItem("project"))
                .teamProjectId,
              exchangeId: res.data.intelligencOrderChain.exchangeId,
              token: sessionStorage.getItem("token"),
            };
            this.$refs.child.queryOrderChainContractPair(payload, "update");

            // // 查询合约杠杆
            let params = {
              exchangeId: res.data.intelligencOrderChain.exchangeId,
              token: sessionStorage.getItem("token"),
            };

            this.$refs.child.findByIdLeverMultiple(params);

            console.log();

            this.$refs.child.orderList = newList;
            this.$refs.child.contractPair = newList[0].contractPair;
            this.$refs.child.lever = newList[0].leverRate;
            this.$refs.child.investmentType = newList[0].investmentType;
            this.$refs.child.proportionMax = newList[0].proportionMax;
            this.$refs.child.proportionMin = newList[0].proportionMin;

            this.$refs.child.investment = newList[0].investment;
            this.$refs.child.createdActionList = newList[0].actionList;

            this.$refs.child.actionParams = JSON.parse(
              newList[0].actionList[0].params
            );
            this.$refs.child.singleDirection = newList[0].singleDirection;
            console.log(newList);

            if (this.batchModify) {
              this.$refs.child.executeStates = true;
            } else {
              this.$refs.child.executeStates = false;
            }
            setTimeout(() => {
              this.$refs.child.$refs.child.percentage =
                newList[0].actionList[0].percentage;
              // this.$refs.child.$refs.child.executeStates=list[0].executeStates
            }, 300);
            this.$refs.child.isUpdate = true;
            this.$refs.child.detailIsFalse = false;

            console.log(newList);
            console.log(1, this.$refs.child.orderList);
          }
        }
      });
    },
    // 修改订单链
    updateOrderChain() {
      this.$refs.child.firstOrderAction();
      let orderIndex = this.$refs.child.orderIndex; //当前订单索引
      let actionIdx = this.$refs.child.actionIdx; //当前动作索引

      let orderList = this.$refs.child.orderList; //当前订单链

      console.log(orderList);

      let orderChainList = JSON.parse(JSON.stringify(this.orderList)); //原订单链

      let controls = this.$refs.child.$refs.child.childParams; //当前订单动作参数
      let links = this.$refs.child.$refs.child.linksParams;
      let percentage = this.$refs.child.$refs.child.percentage; //当前订单动作下单资金比例
      let payload = {
        controls,
        links,
      };
      orderList[orderIndex].actionList[actionIdx].params =
        JSON.stringify(payload);

      orderList[orderIndex].actionList[actionIdx].percentage = percentage;

      let newOrderList = JSON.parse(
        JSON.stringify(orderChainList.orderList.reverse())
      );

      let num = newOrderList.length - orderList.length;
      newOrderList.splice(orderList.length, num);
      console.log(newOrderList);

      let params = {
        token: sessionStorage.getItem("token"),
        transactionType: this.intelligencOrderChain.transactionType,
        orderChainId: this.orderChainId,
        orderChain: [],
      };

      for (let i = 0; i < newOrderList.length; i++) {
        let item = null; //新的订单集
        item = JSON.parse(JSON.stringify(newOrderList[i]));
        item.displayConfig = JSON.parse(item.displayConfig);
        item.executeConfig = JSON.parse(item.executeConfig);
        item.executeConfig.actions = [];

        // console.log(JSON.parse(item.displayConfig))
        // console.log(JSON.parse(item.executeConfig))
        console.log(orderList[i]);
        console.log(item);

        let actionList = orderList[i].actionList;
        for (let z = 0; z < actionList.length; z++) {
          item.displayConfig.actionList[z] = actionList[z];

          for (let info of actionList) {
            console.log(info);

            // 动作是否开启
            if (info.isCheck) {
              let actionsItem = {
                name: info.name,
                investRate: info.percentage,
              };

              // 添加动作参数
              let list = JSON.parse(info.params).controls;
              let params = [];
              for (let cont of list) {
                console.log(cont);

                if (
                  cont.build != false &&
                  cont.controltype != "multi-switch" &&
                  cont.controltype != "signal-input"
                ) {
                  let val = {};
                  val.name = cont.name;
                  val.value = cont.value ? cont.value : null;
                  val.valueType = cont.valueType;
                  params.push(val);
                } else if (cont.controltype == "multi-switch") {
                  let val = {};
                  val.name = cont.name;
                  val.value = cont.proposed;
                  val.valueType = cont.valueType;
                  params.push(val);
                  console.log(cont);
                } else if (cont.controltype == "signal-input") {
                  console.log(cont);
                  let val = {};
                  val.name = cont.name;
                  val.valueType = cont.valueType;
                  val.value = {
                    signalId: cont.signal.signalId,
                    attrName: cont.signalAttribute.name,
                    opt: cont.signalOpt,
                    value: cont.value,
                  };
                  params.push(val);
                }
              }
              if (params.length > 0) {
                actionsItem.params = params;
              }
              console.log(actionsItem);
              item.executeConfig.actions.push(actionsItem);
            }
          }
        }

        console.log(item);
        params.orderChain.push(item);
      }

      for (let key of params.orderChain) {
        key.displayConfig = JSON.stringify(key.displayConfig);
        key.executeConfig = JSON.stringify(key.executeConfig);
      }

      params.orderChain = params.orderChain.reverse();

      if (this.batchModify) {
        console.log(this.selectRowList);
        let orderChain = "";
        this.selectRowList.forEach((e, index) => {
          if (index == 0) {
            orderChain = e.orderChainId;
          } else {
            orderChain += "," + e.orderChainId;
          }
        });

        let payload = {
          token: sessionStorage.getItem("token"),
          orderChainIdStr: orderChain,
          orderChain: params.orderChain,
          transactionType: this.intelligencOrderChain.transactionType,
        };

        batchUpdateOrderChain(payload).then((res) => {
          if (res.status == 200) {
            this.batchModify = false;
            this.dialogVisible = false;
            this.$refs.child.executeStates = false;
            this.orderChainId = null;
            this.$message.success(this.$t('m.操作成功'));
          } else {
            this.$message.error("res.msg");
          }
        });
      } else {
        updateOrderChain(params).then((res) => {
          if (res.status == 200) {
            this.dialogVisible = false;
            this.$refs.child.executeStates = false;
            this.orderChainId = null;
            this.batchModify = false;
            this.$message.success(this.$t('m.操作成功'));
          } else {
            this.$message.error("res.msg");
          }
        });
      }
    },

    //获取交易所列表
    userManagementExchangelist(payload) {
      userManagementExchangelist(payload).then((res) => {
        if (res.status == 200) {
          console.log(res.data.exchangeLimit);
          this.exchangeLimit = res.data.exchangeLimit;
        }
      });
    },

    //日期换算
    timestampToTime(timestamp) {
      var date = new Date(timestamp); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
      var Y = date.getFullYear() + "-";
      var M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      var D =
        date.getDate() < 10
          ? "0" + date.getDate() + " "
          : +date.getDate() + " ";
      var h =
        date.getHours() < 10
          ? "0" + date.getHours() + ":"
          : date.getHours() + ":";
      var m =
        date.getMinutes() < 10
          ? "0" + date.getMinutes() + ":"
          : date.getMinutes() + ":";
      var s =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      return Y + M + D + h + m + s;
    },
    //时分秒换算
    date_format(micro_second) {
      var second = Math.floor(micro_second / 1000);
      var day = Math.floor(second / 3600 / 24);
      var hr = Math.floor((second / 3600) % 24);
      var min = Math.floor((second / 60) % 60);
      return day + "d " + hr + "h " + min + "min";
    },
  },
  created() {
    if (sessionStorage.getItem("Now")) {
      let orderDetail = JSON.parse(sessionStorage.getItem("orderDetail"));
      this.orderChainId = orderDetail.orderChainId;
      this.order = orderDetail.order;
      this.showName = true;
    } else {
      this.orderChainId = JSON.parse(this.$route.query.val).orderChainId;
      this.order = JSON.parse(this.$route.query.val).order;
    }
    let payload = {
      token: sessionStorage.getItem("token"),
      orderChainId: this.orderChainId,
    };
    this.orderChainDetails(payload);
    let payloadData = {
      token: sessionStorage.getItem("token"),
      teamProjectId: JSON.parse(sessionStorage.getItem("project"))
        .teamProjectId,
    };
    this.userManagementExchangelist(payloadData);
  },
  deactivated() {
    sessionStorage.removeItem("orderDetail");
    this.$destroy();
  },
};
</script>

<style scoped lang='scss'>
.box {
  background-color: #fbfbfb;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  .top {
    height: 70px;
    background: #32333d;
    color: #fff;
    align-items: center;
    display: flex;
    padding-left: 30px;
    top: 0;
    width: calc(100% - 30px);
    z-index: 2;
  }
  .nav {
    flex: 1;
    width: calc(100% - 100px);
    margin: 20px;
    padding: 0 30px 30px;
    background-color: #ffffff;
    .nav-top {
      display: flex;
      justify-content: space-between;
      padding: 20px 0;
      .nav-name {
        display: flex;
        align-items: center;
        div {
          margin-right: 10px;
        }
      }
      .nav-btn {
        display: flex;
        color: #2174ff;
        font-size: 14px;
        div {
          margin-left: 10px;
          cursor: pointer;
        }
      }
    }
    .nav-box {
      display: flex;
      flex-direction: column;
      border-top: 1px solid #f3f4f6;
      padding: 20px 0;
      .specialBox {
        display: flex;
        div {
          display: flex;
          flex-direction: column;
          flex: 1;
          color: #102859;
          .specialTitle {
            color: #55698a;
            padding: 20px 0;
            font-size: 14px;
          }
        }
      }
      .specialRow {
        display: flex;
        margin-top: 20px;
        font-size: 14px;
        div {
          width: 200px;
        }
        .recordBtn {
          color: #2174ff;
          cursor: pointer;
        }
        .specialRowFlex {
          display: flex;
          align-items: center;
        }
      }
    }
  }
}
/deep/.el-dialog__header {
  border-bottom: 1px solid #e6e9ee;
}
.specialRow {
  display: flex;
  margin-top: 20px;
  font-size: 14px;
  div {
    width: 135px;
  }
  :last-child {
    display: flex;
    justify-content: flex-end;
  }
  .recordBtn {
    color: #2174ff;
    cursor: pointer;
  }
}

/deep/.el-dialog__body {
  padding: 30px 30px;
  border-top: 1px solid #f0f0f0;
  border-bottom: 1px solid #f0f0f0;
  .dialog-nav {
    color: #55698a;
    padding: 20px 0 0;
    font-size: 12px;
    .dialog-top {
      font-size: 16px;
      color: #102859;
      margin-bottom: 10px;
    }
  }
}
.dialog-footer {
  display: flex;
  justify-content: flex-end;
  div {
    width: 72px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    cursor: pointer;
  }
  .footerBack {
    border: 1px solid #c2d9ff;
    color: #2174ff;
  }
  .footerBtn {
    color: #ffffff;
    background: #2174ff;
    border: 1px solid #c2d9ff;
    margin-left: 12px;
  }
}
</style>