<template>
  <!-- 团队设置 -->
  <div class="teamIntroduction">
    <div class="content">
      <div class="content-title">
        <span>{{$t('m.团队状态')}}</span>
        <span class="type" v-if="teamInfo.status == 'notActivate'">
          {{$t('m.未激活')}}</span>
        <span class="type" v-if="teamInfo.status == 'review'">
          {{$t('m.审核中')}}</span>
        <span class="type" v-if="teamInfo.status == 'activate'">
          {{$t('m.已激活')}}</span>
        
        <span
          class="type gold-color"
          v-if="teamInfo.contentStatus == 'review' && teamInfo.status == 'activate'">
          {{$t('m.审核中')}}</span>
        <span
          class="suspend-color"
          v-if="
            teamInfo.contentStatus == 'reviewFail' &&
            teamInfo.status == 'activate'">
            {{$t('m.审核失败')}}</span>
        <span class="feedback" v-if="teamInfo.reviewFail">{{
          $t('m.审核反馈：') + teamInfo.des
        }}</span>
      </div>
      <div class="content-box">
        <!-- <div>
          <span>团队名称</span>
          <span>限6个字符</span>
        </div>
        <el-input v-model="teamName" placeholder="请输入内容"></el-input> -->
        <div>
          <span>{{$t('m.团队介绍')}}</span>
          <span>{{$t('m.限500字符')}}</span>
        </div>
        <el-input
          type="textarea"
          :rows="12"
          :placeholder="$t('m.请输入内容')"
          v-model="teamIntroduct"
          :maxlength="500"
          resize="none"
        >
        </el-input>
        <div class="btn">
          <!-- <span>取消</span> -->
          <span @click="ModifySubmit()" v-if="teamInfo.contentStatus != 'review'">
            {{$t('m.提 交')}}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  findTemaMessage,
  updateTeamMessage,
  updateTeamName,
} from "../../request/api";
export default {
  components: {},
  data() {
    return {
      teamIntroduct: "", //团队介绍
      teamInfo: {},
      teamName: null, //团队名称
    };
  },
  computed: {},
  watch: {},
  methods: {
    // 获取团队信息
    findTemaMessage() {
      let payload = {
        token: sessionStorage.getItem("token"),
        teamId: JSON.parse(sessionStorage.getItem("defaultTeam")).teamId,
      };
      findTemaMessage(payload).then((res) => {
        if (res.status == 200) {
          this.teamInfo = res.data.TeamMessage;
          this.teamIntroduct = res.data.TeamMessage.teamIntroduct;
          this.teamName = res.data.TeamMessage.teamName;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // // 修改团队名称
    // updateTeamName() {
    //   let payload = {
    //     token: sessionStorage.getItem('token'),
    //     teamId: this.teamInfo.teamId,
    //     teamName: this.teamName,
    //   };
    //   updateTeamName(payload).then((rees) => {
    //     if (res.status == 200) {
    //       console.log(res.data);
    //     } else {
    //       this.$message.error(res.msg);
    //     }
    //   });
    // },
    // 修改团队介绍
    ModifySubmit() {
      if(this.teamIntroduct===''){
        this.$message.error(this.$t('m.团队介绍不能为空！'));
        return
      }
      let payload = {
        token: sessionStorage.getItem("token"),
        teamId: this.teamInfo.teamId,
        teamIntroduct: this.teamIntroduct,
      };
      updateTeamMessage(payload).then((res) => {
        if (res.status == 200) {
          console.log(res.data);
          this.$message.success(this.$t('m.修改成功'));
          this.findTemaMessage()
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
  created() {},
  mounted() {
    this.findTemaMessage();
  },
  beforeCreate() {},
  beforeMount() {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {},
  activated() {
    this.findTemaMessage();
  },
};
</script>
<style lang='scss' scoped>
.teamIntroduction {
  .content {
    .content-title {
      font-size: 14px;
      margin-bottom: 20px;
      .type {
        margin: 0 15px;
        padding: 2px 18px;
        color: #fff;
        background: #007aff;
        border-radius: 11px;
      }
      .gold-color {
        background: #ffa600;
      }
      .suspend-color {
        background: #b3b3b3;
      }
      .feedback {
        color: #999;
        font-size: 12px;
      }
    }
    .content-box {
      div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
        font-size: 14px;
        color: #333;
        :last-child {
          font-size: 12px;
          color: #666;
        }
      }
      .btn {
        display: flex;
        justify-content: flex-end;
        margin-top: 30px;
        :first-child {
          padding: 8px 40px;
          border: 1px solid #c4c6cf;
          border-radius: 4px;
          margin-right: 20px;
          cursor: pointer;
        }
        :last-child {
          padding: 8px 40px;
          color: #fff;
          background: #007aff;
          border-radius: 4px;
          cursor: pointer;
        }
      }
    }
  }
}
</style>