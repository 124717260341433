<template>
  <!-- 单个策略详情 -->
  <div class="details">
    <el-container>
      <el-header>
        <div class="header-warp">
          <i class="el-icon-arrow-left" @click="goBack"></i>
          <span>{{$t('m.策略详情')}}</span>
          <span></span>
        </div>
      </el-header>

      <el-container>
        <!-- main -->
        <el-main>
          <div class="content">
            <!--  -->
            <div class="strategyDetails">
              <el-table
                ref="multipleTable"
                :data="topData"
                tooltip-effect="dark"
                style="width: 100%"
                border>
                <el-table-column prop="startTime" 
                  :label="$t('m.时间')" width="200">
                  <template slot-scope="scope">{{
                    moment(scope.row.startTime).format("YYYY-MM-DD HH:mm:ss")
                  }}</template>
                </el-table-column>
                <el-table-column
                  :label="$t('m.总投入(USDT)')"
                  prop="investCoinNum"
                  width="200"
                  sortable>
                </el-table-column>
                <el-table-column prop="realEarnings" 
                  :label="$t('m.总收益')" width="200">
                  <template slot-scope="scope">
                    {{
                      scope.row.realEarnings
                        ? Math.floor(Number(scope.row.realEarnings) * 10000) /
                          10000
                        : 0
                    }}
                  </template>
                </el-table-column>
                <el-table-column prop="earnings" 
                  :label="$t('m.实现收益')" width="200">
                  <template slot-scope="scope">
                    {{
                      scope.row.earnings
                        ? Math.floor(Number(scope.row.earnings) * 10000) / 10000
                        : 0
                    }}
                  </template>
                </el-table-column>
                <el-table-column
                  prop="floatEarnings"
                  :label="$t('m.浮动收益(USDT)')"
                  width="200">
                  <template slot-scope="scope">
                    {{
                      scope.row.floatEarnings
                        ? Math.floor(Number(scope.row.floatEarnings) * 10000) /
                          10000
                        : 0
                    }}
                  </template>
                </el-table-column>
                <el-table-column
                  prop="realYearIncome"
                  :label="$t('m.总年化')">
                </el-table-column>
                <el-table-column :label="$t('m.操作')" v-if="singleExecute == 0">
                  <template
                    slot-scope="scope"
                    v-if="scope.row.symbol != 'usdt'">
                    <div class="deposit-operation">
                      <span v-if="scope.row.states == 'STOPING'">
                        {{$t('m.终止中')}}</span>
                      <template v-else>
                        <span @click="getPairPage()">
                          {{$t('m.增加币对')}}</span>
                      </template>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </div>

            <!-- 单币对持仓 -->
            <div class="Position" v-if="singleExecute.length != 0">
              <div class="Position-title">
                {{$t('m.持仓')}}</div>
              <el-table
                ref="multipleTable"
                :data="singleExecute"
                tooltip-effect="dark"
                style="width: 100%"
                border>
                <el-table-column
                  :label="$t('m.币种')"
                  prop="symbol"
                  width="200"
                  sortable>
                  <template slot-scope="scope">{{
                    scope.row.symbol.toUpperCase()
                  }}</template>
                </el-table-column>
                <el-table-column prop="amount" :label="$t('m.数量')" width="200">
                  <template slot-scope="scope">{{
                    Math.floor(Number(scope.row.amount) * 10000) / 10000
                  }}</template>
                </el-table-column>
                <el-table-column prop="freez" :label="$t('m.委托冻结')" width="200">
                  <template slot-scope="scope">{{
                    Number(scope.row.amount) == 0
                      ? 0
                      : Math.floor(Number(scope.row.freez) * 10000) / 10000
                  }}</template>
                </el-table-column>
                <el-table-column :label="$t('m.操作')">
                  <template
                    slot-scope="scope"
                    v-if="scope.row.symbol != 'usdt'">
                    <div class="deposit-operation">

                      <span @click="strategyEnd(executeId)">
                        {{$t('m.终止')}}</span>

                      <span
                        v-if="executeDetail.states == 'EXECUTE'"
                        @click="suspend(executeId)">
                        {{$t('m.暂停')}}</span>

                      <span
                        v-if="executeDetail.states == 'SUSPEND'"
                        @click="restart(executeId)">
                        {{$t('m.重启')}}</span>

                      <span @click="modifyParametersShow = true">
                        {{$t('m.参数')}}</span>

                      <span @click="toMessage(executeId)">
                        {{$t('m.消息')}}</span>

                      <span @click="getEntrustOrders(executeId)">
                        {{$t('m.委托订单')}}</span>

                      <span @click="queryFinishOrderList(executeId)">
                        {{$t('m.成交记录')}}</span>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </div>

            <!-- 多币对持仓 -->
            <div class="Position" v-if="sonExecuteList.length != 0">
              <div class="Position-title">
                {{$t('m.持仓')}}</div>
              <el-table
                ref="multipleTable"
                :data="sonExecuteList"
                tooltip-effect="dark"
                style="width: 100%"
                border>

                <el-table-column
                  :label="$t('m.币种')"
                  prop="counterCoin"
                  width="200"
                  sortable>
                  <template slot-scope="scope">{{
                    scope.row.counterCoin.toUpperCase()
                  }}</template>
                </el-table-column>

                <el-table-column
                  prop="counterCoinTotal"
                  :label="$t('m.数量')"
                  width="200">
                  <template slot-scope="scope">{{
                    scope.row.counterCoinTotal
                      ? Math.floor(Number(scope.row.counterCoinTotal) * 10000) /
                        10000
                      : "--"
                  }}</template>
                </el-table-column>

                <el-table-column prop="freez" 
                  :label="$t('m.委托冻结')" width="200">
                  <template slot-scope="scope">{{
                    !scope.row.counterCoinTotal ||
                    Number(scope.row.counterCoinTotal) == 0
                      ? 0
                      : Math.floor(Number(scope.row.counterCoinFreez) * 10000) /
                        10000
                  }}</template>
                </el-table-column>

                <el-table-column :label="$t('m.操作')">
                  <template
                    slot-scope="scope"
                    v-if="scope.row.symbol != 'usdt'">
                    <div class="deposit-operation">

                      <span v-if="scope.row.states == 'STOPING'">
                        {{$t('m.终止中')}}</span>

                      <template v-else>

                        <span @click="strategyEnd(scope.row.executeId)">
                          {{$t('m.终止')}}</span>

                        <span
                          v-if="scope.row.states == 'EXECUTE'"
                          @click="suspend(scope.row.executeId)">
                          {{$t('m.暂停')}}</span>

                        <span
                          v-if="scope.row.states == 'SUSPEND'"
                          @click="restart(scope.row.executeId)">
                          {{$t('m.重启')}}</span>

                        <span @click="modify(scope.row)">
                          {{$t('m.参数')}}</span>

                        <span @click="toMessage(scope.row.executeId)">
                          {{$t('m.消息')}}</span>

                        <span @click="getEntrustOrders(scope.row.executeId)">
                          {{$t('m.委托订单')}}</span>

                        <span @click="queryFinishOrderList(scope.row.executeId)">
                          {{$t('m.成交记录')}}</span>
                      </template>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            
            <!-- 历史 -->
            <div class="History" v-if="singleExecute.length == 0">
              <div class="History-title">
                {{$t('m.历史记录')}}</div>
              <el-table
                ref="multipleTable"
                :data="historyList"
                tooltip-effect="dark"
                style="width: 100%"
                border>
                <el-table-column
                  :label="$t('m.币种')"
                  prop="counterCoin"
                  width="200"
                  sortable>
                </el-table-column>
                <el-table-column
                  prop="realEarnings"
                  :label="$t('m.实现收益')"
                  width="200">
                </el-table-column>
                <el-table-column
                  prop="floatEarnings"
                  :label="$t('m.浮动收益')"
                  width="200">
                </el-table-column>
                <el-table-column
                  prop="finishOpenCount"
                  :label="$t('m.交易数（买入）（卖出）')"
                  width="200">
                  <template slot-scope="scope">
                    {{
                      (scope.row.finishOpenCount
                        ? scope.row.finishOpenCount
                        : 0) +
                      " / " +
                      (scope.row.finishCloseCount
                        ? scope.row.finishCloseCount
                        : 0)
                    }}
                  </template>
                </el-table-column>
                <el-table-column :label="$t('m.操作')">
                  <template slot-scope="scope">
                    <div class="deposit-operation">
                      <span @click="HistoricalParameters(scope.row)">
                        {{$t('m.参数')}}</span>
                      <span @click="toMessage(scope.row.executeId)">
                        {{$t('m.消息')}}</span>
                      <span @click="queryFinishOrderList(scope.row.executeId)">
                        {{$t('m.成交记录')}}</span>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </el-main>
      </el-container>
    </el-container>

    <!-- 修改参数 -->
    <template v-if="modifyParametersShow">
      <el-dialog
        :title="$t('m.修改参数')"
        :visible.sync="modifyParametersShow"
        width="20%"
        :before-close="handleClose"
        :close-on-click-modal="false">
        <div class="modifyParameters">
          <template v-for="(item, idx) in reserves">
            <div
              class="box"
              :key="idx"
              v-if="
                item.visiable != '0' &&
                item.name != '定投类型' &&
                item.name != '定投周期'
              ">
              <span class="box-title">
                <span>{{ item.name }}</span>
                <span v-if="item.valueMin || item.start">{{
                  $t('m.范围：') +
                  (item.valueMin && item.valueMin != "0"
                    ? item.valueMin
                    : item.start) +
                  "~" +
                  (item.valueMax && item.valueMax != "0"
                    ? item.valueMax
                    : item.end)
                }}</span>
              </span>
              <el-input
                v-model="item.value"
                :placeholder="$t('m.请输入内容')"
                @blur="inpCheck($event, item, idx)">
              </el-input>
            </div>

            <div class="box" v-if="item.name == '定投周期'" :key="'dingtouzq'+idx">
              <span class="box-title">
                <span>{{ item.name }}</span>
                <span v-if="item.valueMin || item.start">{{
                  $t('m.范围：') +
                  (item.valueMin != "" && item.valueMin
                    ? item.valueMin
                    : item.start) +
                  "~" +
                  (item.valueMax != "" && item.valueMax
                    ? item.valueMax
                    : item.end)
                }}</span>
              </span>
              <div class="parameter-box-text">
                <div class="block">
                  <el-cascader
                    v-model="fixedTime"
                    :options="times"
                    @change="handleChange">
                  </el-cascader>
                </div>
              </div>
            </div>
          </template>
        </div>
        <span slot="footer" class="dialog-footer">
          <div class="btn" @click="modifyParam">
            {{$t('m.修改参数')}}</div>
        </span>
      </el-dialog>
    </template>

    <!-- 成交记录 -->
    <el-dialog
      :title="$t('m.成交记录')"
      :visible.sync="transactionRecordShow"
      width="30%"
      :before-close="handleClose">
      <div class="transaction-record" v-if="orderList.length != 0">
        <div class="content">
          <div class="title">
            <span>{{$t('m.操作')}}</span>
            <span>{{$t('m.成交价')+ "( " + orderList[0].baseCoin + " )" }}</span>
            <span>{{$t('m.数量')+" (" + orderList[0].counterCoin + " )" }}</span>
            <span>{{$t('m.全部')+"( " + orderList[0].baseCoin + " )" }}</span>
          </div>
          <template v-for="(item, idx) in orderList">
            <div class="box" :key="idx">
              <div>
                <span :class="item.tradeType == '1' ? 'red' : 'green'">
                  {{item.tradeType == "1" ? "买入" : "卖出"}}</span>
                <!-- <span v-if="item.tradeType == '0'" :class="item.tradeType == '1'?'red':'green'">卖出</span> -->
                <span>{{moment(item.finishTime).format("MM/DD HH:mm:ss")}}</span>
              </div>
              <div :class="item.tradeType == '1' ? 'red' : 'green'">
                {{ item.dealPrice }}
              </div>
              <div>
                {{ Math.floor(Number(item.dealAmount) * 10000) / 10000 }}
              </div>
              <div>
                {{ Math.floor(Number(item.usdtVolume) * 10000) / 10000 }}
              </div>
            </div>
          </template>
        </div>
      </div>
      <div v-else class="default">
        <img src="../../assets/image/img_01@2x (1).png" alt="" />
        <span>{{$t('m.暂无成交记录')}}</span>
      </div>
    </el-dialog>

    <!-- 委托订单 -->
    <el-dialog
      :title="$t('m.委托订单')"
      :visible.sync="commissionOrderShow"
      width="30%"
      :before-close="handleClose">
      <div class="commissionOrder">
        <div class="content">
          <div class="sell">
            <div class="title">
              <span>{{ $t('m.买入')+" (" + buy.length + " )" }}</span>
              <span>{{$t('m.价格')}}</span>
            </div>
            <template v-for="(item, idx) in buy">
              <div class="box" :key="idx">
                <span>{{ item.amount }}</span>
                <span class="red">{{ item.price }}</span>
              </div>
            </template>
          </div>
          <div class="buy">
            <div class="title">
              <span>{{$t('m.卖出') +" (" + sell.length + " )" }}</span>
              <span>{{$t('m.价格')}}</span>
            </div>
            <template v-for="(item, idx) in sell">
              <div class="box" :key="idx">
                <span>{{ item.amount }}</span>
                <span class="green">{{ item.price }}</span>
              </div>
            </template>
          </div>
        </div>
      </div>
      <!-- <div v-else class="default">
        <img src="../../assets/image/img_01@2x (1).png" alt="" />
        <span>暂无委托订单</span>
      </div> -->
    </el-dialog>

    <!-- 历史参数 -->
    <el-dialog
      :title="$t('m.历史参数')"
      :visible.sync="historyParametersShow"
      width="20%"
      :before-close="handleClose">
      <div class="modifyParameters">
        <template v-for="(item, idx) in historyReserves">
          <div class="box" :key="idx" v-if="item.visiable == '1'">
            <span class="box-title">
              <span>{{ item.name }}</span>
              <span v-if="item.valueMin || item.start">{{
                $t('m.范围：') +
                (item.valueMin && item.valueMin != "0"
                  ? item.valueMin
                  : item.start) +
                "~" +
                (item.valueMax && item.valueMax != "0"
                  ? item.valueMax
                  : item.end)
              }}</span>
            </span>
            <el-input
              v-model="item.value"
              :placeholder="$t('m.请输入内容')"
              :readonly="true">
              <!-- <template slot="append">USDT</template> -->
            </el-input>
          </div>
        </template>
      </div>
    </el-dialog>

    <!-- 增加币对 -->
    <template v-if="addCurrencyPairShow">
      <el-dialog
        :title="$t('m.增加子币对')"
        :visible.sync="addCurrencyPairShow"
        width="20%"
        :before-close="handleClose"
        :close-on-click-modal="false">
        <div class="addCurrencyPair">
          <div class="title">
            {{$t('m.请谨慎增加币对，避免操作失败致使实例，被大量移至未分组')}}
          </div>
          <div class="box" v-for="(item, idx) in pairList" :key="'pairList1'+idx">
            <div class="checkbox-warp">
              <el-checkbox
                :checked="item.type"
                :key="idx"
                @change="pairChange(item)">
                <div class="box-text">
                  <span>{{ item.counterCoin }}</span>
                  <span>{{ "/" + item.baseCoin }}</span>
                </div>
              </el-checkbox>
            </div>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="addCurrencyPairShow = false">
            {{$t('m.取 消')}}</el-button>
          <el-button type="primary" @click="addPair">
            {{$t('m.确 定')}}</el-button>
        </span>
      </el-dialog>
    </template>
  </div>
</template>

<script>
import {
  executeDetail,
  suspend,
  restart,
  strategyEnd,
  updateParam,
  getEntrustOrders,
  queryFinishOrderList,
  getPairPage,
  manyAddSymbol,
  getPairPageById,
} from "../../request/api";
import moment from "moment";
import { times } from "../../assets/js/time";
export default {
  components: {},
  data() {
    return {
      moment,
      executeDetail: [], //策略数据
      topData: [],
      singleExecute: [], // 单币对持仓
      sonExecuteList: [], //多币对持仓
      historyList: [], //历史列表
      item: [],
      reserves: [],
      reservesCopy: [],
      historyReserves: [], //历史参数
      modifyParametersShow: false, //修改参数
      historyParametersShow: false, //历史参数
      exchangeName: null,
      transactionRecordShow: false, //成交记录
      orderList: [], //成交记录列表
      commissionOrderShow: false, //委托订单
      sell: [],
      buy: [],
      sonExecuteId: null, //多币对子策略ID
      paramsType: true, //参数校验
      addCurrencyPairShow: false,
      pairList: [],
      project: null,
      times: times,
      fixedTime: [1, 1],
      copyFixedTime: [1, 1],
    };
  },
  computed: {},
  watch: {},
  methods: {
    // 返回上一页
    goBack() {
      this.$router.back(-1);
    },
    // 获取策略详情
    ExecuteDetail() {
      this.topData = [];
      let payload = {
        token: sessionStorage.getItem("token"),
        executeId: this.executeId,
      };
      executeDetail(payload).then((res) => {
        if (res.status == 200) {
          // debugger
          
          this.executeDetail = res.data.executeDetail;
          let topData = {};
          topData.startTime = this.executeDetail.startTime;
          topData.investCoinNum =
            this.executeDetail.config.tradePairs[0].investCoinnNum;
          topData.realEarnings = this.executeDetail.realEarnings;
          topData.earnings = this.executeDetail.earnings;
          topData.floatEarnings = this.executeDetail.floatEarnings;
          topData.realYearIncome = this.executeDetail.realYearIncome;
          this.topData.push(topData);
          if (res.data.singleExecute) {
            this.singleExecute = res.data.singleExecute;
            this.reserves = JSON.parse(
              JSON.stringify(this.executeDetail.config.reserves)
            );
            this.reservesCopy = JSON.parse(
              JSON.stringify(this.executeDetail.config.reserves)
            );
            if (res.data.executeDetail.strategyName == "恒量定投") {
              for (let key of this.reserves) {
                if (key.name == "定投类型") {
                  this.fixedTime[0] = key.value;
                  this.copyFixedTime[0] = key.value;
                } else if (key.name == "定投周期") {
                  this.fixedTime[1] = key.value;
                  this.copyFixedTime[1] = key.value;
                }
              }
            }

            console.log(this.reserves, this.reservesCopy);
          }
          if (res.data.sonExecuteList) {
            this.sonExecuteList = res.data.sonExecuteList;
            this.historyList = res.data.historyList;
          }
          // console.log(JSON.parse(this.executeDetail.config))
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 暂停
    suspend(id) {
      this.$confirm(this.$t('m.确认暂停该策略？'))
        .then((_) => {
          let payload = {
            executeId: id,
            token: sessionStorage.getItem("token"),
          };
          suspend(payload).then((res) => {
            if (res.status == 200) {
              this.$message.success(this.$t('m.暂停成功'));
              this.ExecuteDetail();
            } else {
              this.$message.error(res.msg);
            }
          });
        })
        .catch((_) => {});
    },
    // 重启
    restart(id) {
      this.$confirm(this.$t('m.确认重启该策略？'))
        .then((_) => {
          let payload = {
            executeId: id,
            token: sessionStorage.getItem("token"),
          };
          restart(payload).then((res) => {
            if (res.status == 200) {
              this.$message.success(this.$t('m.重启成功'));
              this.ExecuteDetail();
            } else {
              this.$message.error(res.msg);
            }
          });
        })
        .catch((_) => {});
    },
    // 终止
    strategyEnd(id) {
      this.$confirm(this.$t('m.确认终止该策略？'))
        .then((_) => {
          let payload = {
            executeId: id,
            token: sessionStorage.getItem("token"),
          };
          strategyEnd(payload).then((res) => {
            if (res.status == 200) {
              this.$message.success(this.$t('m.终止成功'));
              if (this.singleExecute.length != 0) {
                this.$router.back(-1);
              } else {
                this.ExecuteDetail();
              }
            } else {
              this.$message.error(res.msg);
            }
          });
        })
        .catch((_) => {});
    },
    // 重置参数
    handleClose(done) {
      this.reserves = JSON.parse(JSON.stringify(this.reservesCopy));
      this.fixedTime = JSON.parse(JSON.stringify(this.copyFixedTime));
      done();
    },
    // 多币对修改参数弹窗
    modify(row) {
      this.sonExecuteId = row.executeId;
      let reserves = JSON.parse(row.config).reserves;
      this.reserves = JSON.parse(JSON.stringify(reserves));
      this.reservesCopy = JSON.parse(JSON.stringify(reserves));
      console.log(reserves);
      this.modifyParametersShow = true;
    },
    // 参数校验
    inpCheck(e, item, index) {
      console.log(item, index);
      let value = item.value;
      let min =
        item.valueMin && (item.valueMin != 0 || item.valueMax != 0)
          ? item.valueMin
          : item.start;
      let max =
        item.valueMax && (item.valueMin != 0 || item.valueMax != 0)
          ? item.valueMax
          : item.end;
      console.log(item, item.value, max, min);
      console.log(value === "");
      if (value === "") {
        this.$message.error(this.$t('m.参数不能为空'));
        e.currentTarget.parentElement.style.border = "1px solid #F23A4C";
        e.currentTarget.parentElement.style.borderRadius = "4px";
        this.paramsType = false;
        return;
      } else if (min != "" && Number(value) < min&&item.name!='价格上限'&&item.name!='价格下限') {
        this.$message.error(this.$t('m.参数不能小于最低范围'));
        e.currentTarget.parentElement.style.border = "1px solid #F23A4C";
        e.currentTarget.parentElement.style.borderRadius = "4px";
        console.log(4);
        this.paramsType = false;
        return;
      } else if (max != "" && Number(value) > max&&item.name!='价格上限'&&item.name!='价格下限') {
        this.$message.error(this.$t('m.参数不能大于最大范围'));
        e.currentTarget.parentElement.style.border = "1px solid #F23A4C";
        e.currentTarget.parentElement.style.borderRadius = "4px";
        console.log(5);
        this.paramsType = false;
        return;
      } else if (
        (item.name == "价格上限" || item.name == "价格下限") &&
        Number(value) < 0
      ) {
        this.$message.error(this.$t('m.价格上限/价格下限不能小于0'));
        e.target.style.border = "1px solid #F23A4C";
        console.log(5);
        this.paramsType = false;
        return;
      } else {
        e.target.style.border = "1px solid #DCDFE6";
        console.log(6);
        this.paramsType = true;
      }
    },
    // 参数二次校验
    secondaryCheck(item) {
      for (let key in item) {
        let min =
          item[key].valueMin &&
          (item[key].valueMax != 0 || item[key].valueMin != 0)
            ? item[key].valueMin
            : item[key].start;
        let max =
          item[key].valueMax &&
          (item[key].valueMax != 0 || item[key].valueMin != 0)
            ? item[key].valueMax
            : item[key].end;
        let value = item[key].value;

        if (item[key].visiable == "1" && value === "") {
          console.log(item[key], value);
          this.paramsType = false;
          return;
        } else if (
          item[key].visiable == "1" &&
          min != "" &&
          Number(value) < min
        ) {
          console.log(item[key]);
          this.paramsType = false;
          return;
        } else if (
          item[key].visiable == "1" &&
          max != "" &&
          Number(value) > max
        ) {
          console.log(item[key]);
          this.paramsType = false;
          return;
        } else if (
          (item[key].name == "价格上限" || item[key].name == "价格下限") &&
          Number(value) < 0
        ) {
          console.log(item[key]);
          this.paramsType = false;
          return;
        } else {
          this.paramsType = true;
        }
      }
    },
    // 修改参数
    modifyParam() {
      this.secondaryCheck(this.reserves);
      if (!this.paramsType) {
        this.$message.error(this.$t('m.参数配置不正确，请重新修改'));
        return;
      }

      let item = this.reserves;
      var min = null;
      var max = null;
      for (let key of item) {
        if (key.name == "价格上限") {
          max = key.value;
        } else if (key.name == "价格下限") {
          min = key.value;
        }
      }
      if (Number(max) < min) {
        this.$message.error(this.$t('m.价格上限不能低于价格下限'));
        // this.paramsType = false;
        return;
      }
      if (this.executeDetail.strategyName == "恒量定投") {
        for (let key of this.reserves) {
          if (key.name == "定投类型") {
            key.value = this.fixedTime[0];
          } else if (key.name == "定投周期") {
            key.value = this.fixedTime[1];
          }
        }
        console.log(this.reserves);
      }
      let payload = {
        token: sessionStorage.getItem("token"),
        executeId: this.sonExecuteId ? this.sonExecuteId : this.executeId,
        reserves: JSON.stringify(this.reserves),
      };
      updateParam(payload).then((res) => {
        if (res.status == 200) {
          this.$message.success(this.$t('m.修改成功'));
          this.modifyParametersShow = false;
          this.ExecuteDetail();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 查询成交记录
    queryFinishOrderList(id) {
      let payload = {
        token: sessionStorage.getItem("token"),
        executeId: id,
        // executeId: "c214fbecd5054f0f94b25c3cfbb55fb1",
        exchangeName: this.executeDetail.config.tradePairs[0].exchangeName,
      };
      queryFinishOrderList(payload).then((res) => {
        if (res.status == 200) {
          
          this.orderList = res.data.orderList;
          this.transactionRecordShow = true;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 查询委托订单
    getEntrustOrders(id) {
      let payload = {
        token: sessionStorage.getItem("token"),
        executeId: id,
        // token: "04004cf04ab2de001f5b796ab41fe3ab",
        // executeId: "5f1738753ad44df98817a38f7ce27daa",
      };
      getEntrustOrders(payload).then((res) => {
        if (res.status == 200) {
          
          this.commissionOrderShow = true;
          this.sell = JSON.parse(res.data.order.unfinish_sell_orders).orders;
          this.buy = JSON.parse(res.data.order.unfinish_buy_orders).orders;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 消息
    toMessage(id) {
      this.$router.push({ path: "strategyMessage", query: { executeId: id } });
    },
    // 历史参数
    HistoricalParameters(val) {
      console.log(JSON.parse(val.config).reserves);
      this.historyReserves = JSON.parse(val.config).reserves;
      this.historyParametersShow = true;
    },
    // 查询交易对
    getPairPage() {
      let payload = {
        token: sessionStorage.getItem("token"),
        teamProjectId: this.project.teamProjectId,
        exchangeId: this.executeDetail.config.tradePairs[0].exchangeId,
      };
      console.log(payload);
      getPairPageById(payload).then((res) => {
        if (res.status == 200) {
          console.log(res.data);
          this.addCurrencyPairShow = true;

          let list = res.data.pairList;
          let pair = this.sonExecuteList;
          if (pair.length != 0) {
            for (let i = 0; i < list.length; i++) {
              for (let j = 0; j < pair.length; j++) {
                if (
                  list[i].baseCoin == pair[j].baseCoin.toUpperCase() &&
                  list[i].counterCoin == pair[j].counterCoin.toUpperCase()
                ) {
                  list[i].type = true;
                }
              }
            }
            let newList = list.filter(function (item) {
              return item.type != true;
            });
            this.pairList = newList;
          } else {
            this.pairList = list;
          }
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 币对选中
    pairChange(item) {
      if (!item.type) {
        item.type = true;
      } else {
        item.type = false;
      }
      console.log(item);
    },
    // 增加币对
    addPair() {
      let list = this.pairList;
      let pairs = [];
      for (let i = 0; i < list.length; i++) {
        if (list[i].type == true) {
          let pair = {};
          pair.baseCoin = list[i].baseCoin;
          pair.counterCoin = list[i].counterCoin;
          pairs.push(pair);
        }
      }
      if (pairs.length == 0) {
        this.$message.error(this.$t('m.没有选择币对'));
        return;
      }
      let payload = {
        token: sessionStorage.getItem("token"),
        executeId: this.executeId,
        pairs: JSON.stringify(pairs),
      };
      console.log(payload);
      manyAddSymbol(payload).then((res) => {
        if (res.status == 200) {
          console.log(res.data);
          this.$message.success(this.$t('m.增加币对成功'));
          this.ExecuteDetail();
          this.addCurrencyPairShow = false;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 定投参数
    handleChange(value) {
      this.fixedTime = value;
    },
  },
  created() {},
  mounted() {},
  beforeCreate() {},
  beforeMount() {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {},
  activated() {
    let project = JSON.parse(sessionStorage.getItem("project"));
    this.project = project;
    this.executeId = this.$route.query.executeId;
    this.ExecuteDetail();
  },
  deactivated() {
    this.$destroy();
  },
};
</script>
<style lang='scss' scoped>
.details {
  width: 100%;
  height: 100%;
  .el-container {
    height: 100%;
    background: #eff2f5;
  }
  .el-header {
    background-color: #fff;
    color: #333;
    text-align: center;
    line-height: 60px;
    // margin-bottom: 20px;
    .header-warp {
      display: flex;
      justify-content: space-between;
      align-items: center;
      i {
        cursor: pointer;
      }
    }
  }

  .el-main {
    // background-color: #fff;
    color: #333;
    margin: 0px 20px 20px 20px;
    padding: 20px 10%;
    font-size: 14px;
    .content {
      padding: 10px 20px;
      //   background: #fff;

      .strategyDetails {
        background: #fff;
        margin: 20px 0;
        padding: 10px;
        border-radius: 8px;
      }
      .Position {
        background: #fff;
        margin-top: 20px;
        padding: 10px;
        border-radius: 8px;
        .Position-title {
          color: #333;
          font-size: 16px;
          padding: 10px 0 0 10px;
          margin-bottom: 20px;
        }
      }
      .History {
        background: #fff;
        margin-top: 20px;
        padding: 10px;
        border-radius: 8px;
        .History-title {
          color: #333;
          font-size: 16px;
          padding: 10px 0 0 10px;
          margin-bottom: 20px;
        }
      }
      .deposit-operation {
        display: flex;
        // justify-content: space-between;
        flex-wrap: wrap;
        padding: 0 5px;
        span {
          font-size: 12px;
          color: #007aff;
          cursor: pointer;
          margin-right: 10px;
        }
      }
    }
  }
  // 修改参数
  .modifyParameters {
    .box {
      margin-bottom: 10px;
      .box-title {
        display: inline-block;
        font-size: 14px;
        color: #333;
        margin-bottom: 4px;
        :last-child {
          font-size: 12px;
          color: #666;
          margin-left: 4px;
        }
      }
      ::v-deep.el-cascader {
        width: 100%;
      }
    }
  }
  // 修改参数确认
  .btn {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #007aff;
    color: #fff;
    font-size: 16px;
    border-radius: 4px;
    cursor: pointer;
  }
  // 成交记录
  .transaction-record {
    height: 450px;
    overflow: scroll;
    font-size: 14px;
    color: #999;
    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      span {
        width: 25%;
      }
    }
    .box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 10px 0;
      font-size: 12px;
      div {
        display: flex;
        flex-direction: column;
        width: 25%;
      }
      .red {
        color: #ff4141;
      }
      .green {
        color: #00ad88;
      }
    }
  }
  .default {
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      width: 120px;
      height: 80px;
      margin-bottom: 10px;
    }
  }
  // 委托订单
  .commissionOrder {
    .content {
      display: flex;
      justify-content: space-around;
      .sell,
      .buy {
        display: flex;
        flex-direction: column;
        width: 40%;
        .title,
        .box {
          width: 100%;
          display: flex;
          justify-content: space-between;
        }
        .box {
          margin: 10px 0;
        }
        .red {
          color: #ff4141;
        }
        .green {
          color: #00ad88;
        }
      }
    }
  }
  // 增加币对
  .addCurrencyPair {
    height: 400px;
    overflow: scroll;
    .title {
      font-size: 12px;
      color: #999;
      margin-bottom: 10px;
    }
    .checkbox-warp {
      margin-bottom: 10px;
      padding: 5px;
      .box {
        .box-text {
          margin-left: 30px;
          font-size: 14px;
          color: #666;
          :last-child {
            font-size: 12px;
            color: #999;
          }
        }
      }
    }

    .checkbox-warp:hover {
      background: #d5d5d5;
      border-radius: 4px;
    }
  }
}
</style>