export const cycleTimes  = [
    {
        value: 1,
        label: "固定间隔",
        children: [
            {
                value: 1,
                label: "1日",
            },
            {
                value: 2,
                label: "2日",
            },
            {
                value: 3,
                label: "3日",
            },
            {
                value: 4,
                label: "4日",
            },
            {
                value: 5,
                label: "5日",
            },
            {
                value: 6,
                label: "6日",
            },
            {
                value: 7,
                label: "7日",
            },
            {
                value: 8,
                label: "8日",
            },
            {
                value: 9,
                label: "9日",
            },
            {
                value: 10,
                label: "10日",
            },

            {
                value: 11,
                label: "11日",
            },
            {
                value: 12,
                label: "12日",
            },
            {
                value: 13,
                label: "13日",
            },
            {
                value: 14,
                label: "14日",
            },
            {
                value: 15,
                label: "15日",
            },
            {
                value: 16,
                label: "16日",
            },
            {
                value: 17,
                label: "17日",
            },
            {
                value: 18,
                label: "18日",
            },
            {
                value: 19,
                label: "19日",
            },
            {
                value: 20,
                label: "20日",
            },

            {
                value: 21,
                label: "21日",
            },
            {
                value: 22,
                label: "22日",
            },
            {
                value: 23,
                label: "23日",
            },
            {
                value: 24,
                label: "24日",
            },
            {
                value: 25,
                label: "25日",
            },
            {
                value: 26,
                label: "26日",
            },
            {
                value: 27,
                label: "27日",
            },
            {
                value: 28,
                label: "28日",
            },
            {
                value: 29,
                label: "29日",
            },
            {
                value: 30,
                label: "30日",
            },
            {
                value: 31,
                label: "31日",
            },
            {
                value: 32,
                label: "32日",
            },
            {
                value: 33,
                label: "33日",
            },
            {
                value: 34,
                label: "34日",
            },
            {
                value: 35,
                label: "35日",
            },
            {
                value: 36,
                label: "36日",
            },
            {
                value: 37,
                label: "37日",
            },
            {
                value: 38,
                label: "38日",
            },
            {
                value: 39,
                label: "39日",
            },
            {
                value: 40,
                label: "40日",
            },

            {
                value: 41,
                label: "41日",
            },
            {
                value: 42,
                label: "42日",
            },
            {
                value: 43,
                label: "43日",
            },
            {
                value: 44,
                label: "44日",
            },
            {
                value: 45,
                label: "45日",
            },
            {
                value:46,
                label: "46日",
            },
            {
                value: 47,
                label: "47日",
            },
            {
                value: 48,
                label: "48日",
            },
            {
                value: 49,
                label: "49日",
            },
            {
                value: 50,
                label: "50日",
            },

            {
                value: 51,
                label: "51日",
            },
            {
                value: 52,
                label: "52日",
            },
            {
                value: 53,
                label: "53日",
            },
            {
                value: 54,
                label: "54日",
            },
            {
                value: 55,
                label: "55日",
            },
            {
                value: 56,
                label: "56日",
            },
            {
                value: 57,
                label: "57日",
            },
            {
                value: 58,
                label: "58日",
            },
            {
                value: 59,
                label: "59日",
            },
            {
                value: 60,
                label: "60日",
            },

            {
                value: 61,
                label: "61日",
            },
            {
                value: 62,
                label: "62日",
            },
            {
                value: 63,
                label: "63日",
            },
            {
                value: 64,
                label: "64日",
            },
            {
                value: 65,
                label: "65日",
            },
            {
                value: 66,
                label: "66日",
            },
            {
                value: 67,
                label: "67日",
            },
            {
                value: 68,
                label: "68日",
            },
            {
                value: 69,
                label: "69日",
            },
            {
                value: 70,
                label: "70日",
            },

            {
                value: 71,
                label: "71日",
            },
            {
                value: 72,
                label: "72日",
            },
            {
                value: 73,
                label: "73日",
            },
            {
                value: 74,
                label: "74日",
            },
            {
                value: 75,
                label: "75日",
            },
            {
                value: 76,
                label: "76日",
            },
            {
                value: 77,
                label: "77日",
            },
            {
                value: 78,
                label: "78日",
            },
            {
                value: 79,
                label: "79日",
            },
            {
                value: 80,
                label: "80日",
            },

            {
                value: 81,
                label: "81日",
            },
            {
                value: 82,
                label: "82日",
            },
            {
                value: 83,
                label: "83日",
            },
            {
                value: 84,
                label: "84日",
            },
            {
                value: 85,
                label: "85日",
            },
            {
                value: 86,
                label: "86日",
            },
            {
                value: 87,
                label: "87日",
            },
            {
                value: 88,
                label: "88日",
            },
            {
                value: 89,
                label: "89日",
            },
            {
                value: 90,
                label: "90日",
            },

            {
                value: 91,
                label: "91日",
            },
            {
                value: 92,
                label: "92日",
            },
            {
                value: 93,
                label: "93日",
            },
            {
                value: 94,
                label: "94日",
            },
            {
                value: 95,
                label: "95日",
            },
            {
                value: 96,
                label: "96日",
            },
            {
                value: 97,
                label: "97日",
            },
            {
                value: 98,
                label: "98日",
            },
            {
                value: 99,
                label: "99日",
            },
            {
                value: 100,
                label: "100日",
            },
        ],
    },
    {
        value: 2,
        label: '每周',
        children: [
            {
                value: 1,
                label: "周一",
            },
            {
                value: 2,
                label: "周二",
            },
            {
                value: 3,
                label: "周三",
            },
            {
                value: 4,
                label: "周四",
            },
            {
                value: 5,
                label: "周五",
            },
            {
                value: 6,
                label: "周六",
            },
            {
                value: 7,
                label: "周日",
            },
        ]
    },
    {
        value: 3,
        label: "每月",
        children: [
            {
                value: 1,
                label: "1日",
            },
            {
                value: 2,
                label: "2日",
            },
            {
                value: 3,
                label: "3日",
            },
            {
                value: 4,
                label: "4日",
            },
            {
                value: 5,
                label: "5日",
            },
            {
                value: 6,
                label: "6日",
            },
            {
                value: 7,
                label: "7日",
            },
            {
                value: 8,
                label: "8日",
            },
            {
                value: 9,
                label: "9日",
            },
            {
                value: 10,
                label: "10日",
            },

            {
                value: 11,
                label: "11日",
            },
            {
                value: 12,
                label: "12日",
            },
            {
                value: 13,
                label: "13日",
            },
            {
                value: 14,
                label: "14日",
            },
            {
                value: 15,
                label: "15日",
            },
            {
                value: 16,
                label: "16日",
            },
            {
                value: 17,
                label: "17日",
            },
            {
                value: 18,
                label: "18日",
            },
            {
                value: 19,
                label: "19日",
            },
            {
                value: 20,
                label: "20日",
            },

            {
                value: 21,
                label: "21日",
            },
            {
                value: 22,
                label: "22日",
            },
            {
                value: 23,
                label: "23日",
            },
            {
                value: 24,
                label: "24日",
            },
            {
                value: 25,
                label: "25日",
            },
            {
                value: 26,
                label: "26日",
            },
            {
                value: 27,
                label: "27日",
            },
            {
                value: 28,
                label: "28日",
            },
            {
                value: 29,
                label: "29日",
            },
            {
                value: 30,
                label: "30日",
            }
        ]
    }
]
