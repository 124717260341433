import { render, staticRenderFns } from "./createStrategy.vue?vue&type=template&id=953b1364&scoped=true&"
import script from "./createStrategy.vue?vue&type=script&lang=js&"
export * from "./createStrategy.vue?vue&type=script&lang=js&"
import style0 from "./createStrategy.vue?vue&type=style&index=0&id=953b1364&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "953b1364",
  null
  
)

export default component.exports