<template>
  <!-- 选择交易对 -->
  <div class="settlementDetails">
    <el-container>
      <el-header>
        <div class="header-warp">
          <i class="el-icon-arrow-left" @click="goBack"></i>
          <span>{{$t('m.结算明细')}}</span>
          <span></span>
        </div>
      </el-header>
      <el-container>
        <!-- main -->
        <el-main>
          <div class="content">
            <div class="table-warp">
              <el-table
                ref="multipleTable"
                :data="list"
                tooltip-effect="dark"
                style="width: 100%"
              >
                <el-table-column prop="endTime" :label="$t('m.退出时间')" width="160" sortable>
                  <template slot-scope="scope">
                    {{
                      moment(scope.row.endTime).format("YYYY/MM/DD HH:mm:ss")
                    }}
                  </template>
                </el-table-column>
                <el-table-column prop="startTime" :label="$t('m.入金时间')" width="160" sortable>
                  <template slot-scope="scope">
                    {{
                      moment(scope.row.startTime).format("YYYY/MM/DD HH:mm:ss")
                    }}
                  </template>
                </el-table-column>
                <el-table-column prop="publicCode" :label="$t('m.用户')" width="140">
                </el-table-column>
                <el-table-column
                  prop="investCoinNum"
                  :label="$t('m.总投入(USDT)')"
                  width="120"
                >
                </el-table-column>
                <el-table-column
                  prop="realEarnings"
                  :label="$t('m.总收益(USDT)')"
                  width="120"
                >
                  <template slot-scope="scope">
                    <span>{{
                      Math.floor(Number(scope.row.realEarnings) * 10000) / 10000
                    }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="realEarningRate"
                  :label="$t('m.总收益率')"
                  width="120"
                >
                  <template slot-scope="scope">
                    <span>{{
                      Math.floor(Number(scope.row.realEarningRate) * 100) / 100+'%'
                    }}</span>
                  </template>
                </el-table-column>

                <el-table-column
                  prop="amount"
                  :label="$t('m.实际结算金额(VTN)')"
                  width="150"
                >
                  <template slot-scope="scope">
                    <span>{{
                      scope.row.amount ? scope.row.amount : "--"
                    }}</span>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <!-- 分页 -->
            <div class="pagination-warp">
              <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="pageNo"
                :page-sizes="[10, 20, 30, 40]"
                :page-size="20"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total"
              >
              </el-pagination>
            </div>
          </div>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import moment from "moment";
import { settleDetail } from "../../request/api";
export default {
  components: {},
  data() {
    return {
      moment,
      list: null,
      total: 0,
      teamProjectId: null,
      pageNo: 1,
      pageSize: 20,
    };
  },
  computed: {},
  watch: {},
  methods: {
    // 返回上一页
    goBack() {
      this.$router.back(-1);
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.settleDetail();
    },
    handleCurrentChange(val) {
      this.pageNo = val;
      this.settleDetail();
    },
    // 结算明细
    settleDetail() {
      let payload = {
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        teamProjectId: this.teamProjectId,
        token: sessionStorage.getItem("token"),
      };
      settleDetail(payload).then((res) => {
        if (res.status == 200) {
          this.list = res.data.detailList;
          this.total = res.data.total;
          console.log(res.data);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
  created() {},
  mounted() {},
  beforeCreate() {},
  beforeMount() {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {},
  activated() {
    this.teamProjectId = this.$route.query.id;
    this.settleDetail();
  },
  deactivated() {
    this.$destroy();
  },
};
</script>
<style lang='scss' scoped>
.settlementDetails {
  width: 100%;
  height: 100%;
  .el-container {
    height: 100%;
    background: #eff2f5;
  }
  .el-header {
    background-color: #fff;
    color: #333;
    text-align: center;
    line-height: 60px;
    margin-bottom: 20px;
    .header-warp {
      display: flex;
      justify-content: space-between;
      align-items: center;
      i {
        cursor: pointer;
      }
    }
  }
  .el-main {
    color: #333;
    padding: 0 10%;
    font-size: 14px;
    .content {
      // height: 100%;
      background: #fff;
      border-radius: 8px;
      padding: 20px;
      ::v-deep.el-input__inner {
        height: 30px;
        line-height: 30px;
      }
      ::v-deep.el-input__icon {
        line-height: 30px;
      }
      .table-warp {
        height: 550px;
        overflow: scroll;
      }
      .pagination-warp {
        display: flex;
        justify-content: flex-end;
        margin: 20px 0;
      }
    }
  }
}
</style>