<template>
  <div class="orderChain">
    <div class="create">
      <!-- 左 -->
      <div class="create-chain">
        <div class="create-chain-title">
          <span>{{$t('m.订单链')}}</span>
          <img
            class="icon-jieshi"
            src="../assets/icon/jieshi.png"
            alt=""
            @click="OrderChainInterpretation()"
          />
        </div>

        <div class="create-chain-cont" v-if="orderList.length > 0">
          <el-timeline>
            <template v-for="(item, idx) in orderList">
              <el-timeline-item :key="idx">
                <div
                  class="chain-box"
                  :class="{ 'chain-box-on': orderIndex == idx }"
                  @click="selectOrder(idx)">
                  <div class="title">
                    <span>{{ item.name }}</span>
                    <span
                      class="type"
                      v-if="item.executeStates == 'WAIT_EXECUTE'">
                      {{$t('m.等待执行')}}</span>

                    <span class="type" v-if="item.executeStates == 'EXECUTE'">
                      {{$t('m.执行中')}}</span>

                    <span class="type" v-if="item.executeStates == 'STOPING'">
                      {{$t('m.终止中')}}</span>

                    <span class="type" v-if="item.executeStates == 'STOP'">
                      {{$t('m.已完成')}}</span>
                  </div>
                  <div>{{ item.value }}</div>

                  <img
                    class="icon-detail"
                    src="../assets/icon/shanchu.png"
                    alt=""
                    v-if="orderIndex != idx && idx != 0 && !isUpdate"
                    @click.stop="deteleOrder(idx)"
                  />

                  <img
                    v-if="orderIndex == idx && idx != 0 && detailIsFalse"
                    class="icon-detail"
                    src="../assets/icon/shanchu-bai.png"
                    alt=""
                    @click.stop="deteleOrder(idx)"
                  />
                </div>
              </el-timeline-item>
            </template>
          </el-timeline>

          <div
            class="btn-warp"
            :class="{
              disable: singleDirection == '平空' || singleDirection == '平多',
            }"
            v-if="!isUpdate"
            @click="addOrder()">
            <img class="icon-add" src="../assets/icon/xinzeng-bai.png" alt="" />
            <span>{{$t('m.新建订单')}}</span>
          </div>
        </div>
      </div>

      <!-- 参数 -->
      <div class="parameter">
        <!-- 条件选择 -->
        <template v-if="orderList.length > 0">
          <!-- title -->
          <div class="parameter-title">
            <span>{{$t('m.订单&(1)参数设置').replace('&(1)', (orderIndex + 1))}}</span>
            <span class="red">{{$t('m.带*符号，为必填项')}} </span>
          </div>
          
          <!-- 参数 -->
          <div class="parameter-select">
            <!-- 现货 -->
            <template v-if="transactionType == 'spot'">
              <div class="select-warp">
                
                <div>
                  <span>{{$t('m.交易对')}}：</span>
                  <el-select
                    v-model="showPair"
                    :placeholder="$t('m.请选择')"
                    :filterable="!isUpdate"
                    @change="exchangePair">
                    <template v-if="!isUpdate">
                      <el-option
                        v-for="(item, idx) in orderList[orderIndex].pairList"
                        :key="idx"
                        :label="item.counterCoin + '/' + item.baseCoin"
                        :value="item">
                      </el-option>
                    </template>
                  </el-select>
                </div>

                <div>
                  <span>{{$t('m.下单方向')}}：</span>
                  <el-select
                    v-model="singleDirection"
                    :placeholder="$t('m.请选择')"
                    @change="changeInvestmentType">
                    <template v-if="orderIndex == 0 && !isUpdate">
                      <el-option
                        v-for="item in soptType"
                        :key="item.value"
                        :label="$t('m.'+item.label)"
                        :value="item.value">
                      </el-option>
                    </template>
                  </el-select>
                </div>
              </div>
              
              <div class="select-warp" v-if="orderIndex == 0">
                <div>
                  <span class="invest">
                    <span>{{$t('m.总投入')}}：</span>
                    <img
                      class="icon-jieshi"
                      src="../assets/icon/jieshi.png"
                      alt=""
                      @click="inputInterpretation()"
                    />
                  </span>
                  <el-select
                    v-model="investmentType"
                    :placeholder="$t('m.请选择')"
                    @change="selecTinvestmentType"
                    :disabled="isUpdate">
                    <el-option
                      v-for="item in investmentList"
                      :key="item.value"
                      :label="$t('m.'+item.label)"
                      :value="item.value">
                    </el-option>
                  </el-select>
                </div>
                <div v-if="investmentType == '固定投入'">
                  <span>
                    <span class="red">*</span>
                    <span>{{$t('m.数量')}}：</span>
                  </span>
                  <el-input
                    v-model="investment"
                    :placeholder="$t('m.请输入数量')"
                    type="number"
                    @blur="verification($event)"
                    :disabled="isUpdate">
                    <i class="iconStyle" slot="suffix">{{ inputCurrency }}</i>
                  </el-input>
                </div>
                <div v-else>
                  <span class="proportion">
                    <span class="red">*</span>
                    <span>{{$t('m.资金比例')}}：</span>
                  </span>
                  <el-input
                    v-model="investment"
                    :placeholder="$t('m.请输入数量')"
                    type="number"
                    @blur="verification($event)">
                    <i class="iconStyle" slot="suffix">%</i>
                  </el-input>
                </div>
              </div>
            </template>

          </div>

          <!-- 动作 -->
          <div class="action">
            <p class="action-title">
              {{$t('m.动作')}}</p>
            <div class="action-cont">
              <template v-if="createdActionList.length > 0">
                <div class="cont-left">
                  <div
                    class="left-box"
                    :class="{ on: actionIdx == idx }"
                    v-for="(item, idx) in createdActionList"
                    :key="idx"
                    @click="curSelectAction(idx)">
                    <div class="box-cont">
                      <span>{{ item.label }}</span>
                      <span
                        class="trigger"
                        v-if="orderList[orderIndex].actionIndex == idx">
                      </span>
                      <span></span>
                    </div>
                    <div class="box-cont">
                      <el-switch
                        v-model="item.isCheck"
                        active-color="#2174ff"
                        inactive-color="#758DB5"
                        :width="36"
                        :disabled="!executeStates">
                      </el-switch>
                      <img
                        class="icon-detail"
                        src="../assets/icon/lajitong.png"
                        alt=""
                        @click.stop="detele(idx)"
                        v-if="!isUpdate"
                      />
                    </div>
                  </div>
                  <div
                    class="btn-warp"
                    v-if="!isUpdate"
                    @click="queryOrderActionList()">
                    <img
                      class="icon-add"
                      src="../assets/icon/xinjian.png"
                      alt=""
                    />
                    <span>{{$t('m.新建动作')}}</span>
                  </div>
                </div>

                <div class="cont-right">
                  <ParameterComponent
                    :childData="actionDetailParams"
                    :links="links"
                    :current="inputCurrency"
                    :executeStates="executeStates"
                    :actionParams="actionParams"
                    ref="child"
                  />
                </div>
              </template>

              <!-- 缺省 -->
              <div class="default" v-else>
                <img src="../assets/image/zanwudongzuo.png" alt="" />
                <span class="text">{{$t('m.暂无动作、点击下方按钮创建动作')}}</span>
                <div
                  class="btn-warp"
                  v-if="!isUpdate"
                  @click="queryOrderActionList()">
                  <img src="../assets/icon/xinjian.png" alt="" />
                  <span>{{$t('m.新建动作')}}</span>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>

    <!-- 动作弹窗 -->
    <el-dialog :title="$t('m.动作')" :visible.sync="actionIsShow" width="26%">
      <div class="action-warp">
        <div v-for="(item, idx) in actionList" :key="'actionList'+idx">
          <span
            :class="{ on: actionIndex == idx }"
            @click="selectAction(idx, item)"
            >{{ item.label }}</span
          >
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose()">
          {{$t('m.取 消')}}</el-button>
        <el-button type="primary" @click="handleConfirm()">
          {{$t('m.确 定')}}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import ParameterComponent from "./parameterComponent.vue";
import {
  queryOrderActionList,
  queryOrderActionDetails,
  getPairPageById,
  queryOrderChainContractPair,
  findByIdLeverMultiple,
} from "../request/api";
export default {
  components: {
    ParameterComponent,
  },
  props: {
    transactionType: String,
    exchangeName: String,
    controlsPrice: Number,
  },
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 24 * 60 * 60 * 1000;
        },
      },

      value: "",

      //////////////////////
      soptType: [
        //现货
        { label: "买入", value: "买入" },
        { label: "卖出", value: "卖出" },
      ],
      contractType: [
        //合约
        { label: "开多", value: "开多" },
        { label: "平多", value: "平多" },
        { label: "开空", value: "开空" },
        { label: "平空", value: "平空" },
      ],

      investmentList: [
        //投入类型
        { label: "固定投入", value: "固定投入" },
        { label: "比例投入", value: "比例投入" },
      ],

      orderList: [], //订单链列表
      orderIndex: 0, //订单列表高亮
      singleDirection: "买入", //下单方向
      investmentType: "固定投入", //投入类型

      investment: null, //投入数量

      pair: null, //交易对

      actionIsShow: false, //动作列表弹窗
      actionList: null, //动作列表

      actionIndex: 0, //选中动作

      actionIdx: 0, //当前显示动作

      actionDetail: null, //动作详情

      actionDetailParams: null, // 传给子组件的动作参数
      links: null, // 传给子组件的动作校验参数

      createdActionList: [], //创建的动作列表

      copyPairList: [], //交易对备份数据
      pairList: [],
      allPairList: null, //所有交易对
      showPair: null, // 显示的交易对
      getCurrency: null, //得到的币种
      inputCurrency: null, // 投入币种

      inputVerification: true, //投入校验
      isUpdate: false,
      detailIsFalse: true,
      executeStates: true, //执行状态

      actionParams: null, //传递的动作参数

      startAcc: [], //

      // *************合约数据***********//
      contractPairList: [], //合约交易对

      contractPair: "", //选中的合约交易对

      leverList: [], //杠杆倍数列表
      lever: 3, //选中的杠杆倍数
      contractSize: null, //合约size

      proportionMin: null,
      proportionMax: null,
      contractPairInfo: null,
      project: null,
      contractCoin: null, //面值

      contPair: null, //
    };
  },
  computed: {},
  watch: {
    transactionType(New, old) {

    },

    startAcc(New, old) {

      if (New[0].contract) {
        // 查询合约杠杆
        let params = {
          exchangeId: New[0].exchangeId,
          token: sessionStorage.getItem("token"),
        };
        this.findByIdLeverMultiple(params);
        if (New[0].exchangeName == "Binance") {
          // 查询合约交易对
          let payload = {
            teamProjectId: this.project.teamProjectId,
            exchangeId: New[0].exchangeId,
            token: sessionStorage.getItem("token"),
          };
          this.queryOrderChainContractPair(payload);
        }
      }
      this.startAcc = New;
    },
  },
  methods: {
    // 查询交易对
    queryPair(id) {
      // 重置默认数据
      if (this.startAcc && this.startAcc.length != 0) {
        id =
          this.startAcc[0].exchangeName == "Huobi"
            ? 3
            : this.startAcc[0].exchangeName == "OkexV5"
            ? 29
            : 4;
      }
      this.createdActionList = [];
      this.actionParams = null;

      this.orderList = [];
      this.actionDetail = null;
      this.orderIndex = 0;
      let payload = {
        token: sessionStorage.getItem("token"),
        exchangeId: id,
        teamProjectId: this.project.teamProjectId,
      };
      getPairPageById(payload).then((res) => {
        if (res.status == 200) {
          let projectPairList = res.data.coinList;
          if (!projectPairList) {
            projectPairList = ["USDT", "BTC"];
          }
          let allPairList = res.data.pairList;

          let combinationPairList = [];
          let newPairList = [];

          for (var i = 0; i < projectPairList.length; i++) {
            for (var j = 0; j < projectPairList.length; j++) {
              let item = {};
              item.baseCoin = projectPairList[i];
              item.counterCoin = projectPairList[j];
              combinationPairList.push(item);
            }
          }

          for (var i = 0; i < allPairList.length; i++) {
            for (var j = 0; j < combinationPairList.length; j++) {
              if (
                allPairList[i].baseCoin == combinationPairList[j].baseCoin &&
                allPairList[i].counterCoin == combinationPairList[j].counterCoin
              ) {
                newPairList.push(allPairList[i]);
              }
            }
          }

          this.allPairList = JSON.parse(JSON.stringify(newPairList));
          this.pair = newPairList[0];
          for (let key of newPairList) {
            if (
              key.counterCoin == "BTC" &&
              key.baseCoin == "USDT" &&
              id == key.exchangeId
            ) {
              this.pair = key;
            }
          }
          this.showPair = this.pair.counterCoin + "/" + this.pair.baseCoin;

          this.copyPairList = JSON.parse(JSON.stringify(newPairList));
          this.pairList = JSON.parse(JSON.stringify(newPairList));

          for (let pair of newPairList) {
            if (
              this.startAcc &&
              this.startAcc.length != 0 &&
              this.startAcc[0].coinType &&
              pair.counterCoin == this.startAcc[0].coinType &&
              pair.baseCoin == this.startAcc[0].investCoin
            ) {
              this.showPair = pair.counterCoin + "/" + pair.baseCoin;
              this.pair = pair;
              this.copyPairList = [];
              this.pairList = [];
              this.copyPairList.push(pair);
              this.pairList.push(pair);
              //  order.pairList = JSON.parse(JSON.stringify(pair));
            }
          }

          let order = {
            name: this.$t('m.订单') + " 1",
            value:
              this.$t('m.'+this.singleDirection) +
              "("+this.$t('m.投') + ' ' +
              this.pair.baseCoin +
              ")" +
              this.pair.counterCoin +
              "/" +
              this.pair.baseCoin,
          };
          this.inputCurrency = this.pair.baseCoin;
          this.getCurrency = this.pair.counterCoin;
          order.pairList = JSON.parse(JSON.stringify(this.pairList));

          order.inputCurrency = this.pair.baseCoin;
          order.getCurrency = this.pair.counterCoin;
          order.singleDirection = this.singleDirection;
          order.investmentType = this.investmentType;
          order.investment = this.investment;
          order.pair = this.pair;
          order.sort = 1;
          // this.orderList.push(order);
          this.orderList = [order];

          if (
            this.startAcc.length != 0 &&
            this.startAcc[0].exchangeName == "Binance" &&
            this.startAcc[0].contract
          ) {
            this.$emit("queryOperatorUserList", "BTCUSDT");
          } else if (
            this.startAcc.length != 0 &&
            this.startAcc[0].exchangeName == "Huobi" &&
            this.startAcc[0].contract
          ) {
            this.$emit("queryOperatorUserList", this.startAcc[0].contractId);
            this.contractPair = this.startAcc[0].contractId;
          } else {
            this.$emit("queryOperatorUserList", order.inputCurrency);
          }
          this.$forceUpdate();
        }
      });
    },

    // 新增订单
    addOrder() {
      if (this.transactionType !== "spot") {
        if (this.orderList.length >= 2) {
          this.$message({
            message: this.$t('m.合约最多支持2个'),
            type: "warning",
          });
          return;
        }
      } else {
        if (this.orderList.length >= 5) {
          this.$message({
            message: this.$t('m.订单数最多支持5个'),
            type: "warning",
          });
          return;
        }
      }

      if (
        this.transactionType == "contract_usdt" &&
        (this.singleDirection == "平空" || this.singleDirection == "平多")
      ) {
        return;
      }

      // 保存第一单动作参数  、、、、、、、、、、、、、、、、、、、、、、
      this.firstOrderAction();

      if (this.createdActionList.length == 0) {
        return;
      }

      ///////////////////
      // 现货
      if (this.transactionType == "spot") {
        let list = this.allPairList;
        let newList = [];
        for (let key of list) {
          if (
            this.getCurrency == key.baseCoin ||
            this.getCurrency == key.counterCoin
          ) {
            newList.push(key);
          }
        }

        this.pairList = newList;
        this.pair = newList[0];

        if (this.getCurrency == this.pair.baseCoin) {
          this.singleDirection = "买入";
        } else {
          this.singleDirection = "卖出";
        }

        // 创建订单添加到订单数组
        let num = this.orderList.length;
        let order = {
          name: this.$t('m.订单') + (num + 1),
          value:
            this.$t('m.'+this.singleDirection) +
            "("+this.$t('m.投') + ' ' +
            this.orderList[num - 1].getCurrency +
            ")" +
            this.pair.counterCoin +
            "/" +
            this.pair.baseCoin,
        };

        order.pairList = this.pairList;
        order.inputCurrency = this.orderList[num - 1].getCurrency; //新订单投入币既上一单得到币
        order.getCurrency =
          order.inputCurrency == this.pair.baseCoin
            ? this.pair.counterCoin
            : this.pair.baseCoin; //新订单得到币
        order.singleDirection = this.singleDirection; //新订单方向
        order.pair = this.pair;
        order.sort = num + 1;
        this.orderList.push(order); //添加新订单

        this.getCurrency = order.getCurrency;
        this.inputCurrency = order.inputCurrency;
        this.showPair = this.pair.counterCoin + "/" + this.pair.baseCoin;
        this.orderIndex = this.orderList.length - 1;

        this.createdActionList = []; //新订单置空动作列表
      } else {
        // U本位合约
        let singleDirection = JSON.parse(JSON.stringify(this.singleDirection));
        let pair = JSON.parse(JSON.stringify(this.contractPair)).split("-");
        let inputCurrency = null;
        switch (singleDirection) {
          case "开多":
            this.singleDirection = "平多";
            inputCurrency = pair[0];
            break;
          case "开空":
            this.singleDirection = "平空";
            inputCurrency = pair[0];
            break;
          case "平多":
            // this.singleDirection = "开多";
            break;
          case "平空":
            // this.singleDirection = "开空";
            break;
          default:
            break;
        }
        if (this.exchangeName == "Binance") {
          inputCurrency = pair[0].substring(0, pair[0].length - 4);
        }
        let num = this.orderList.length;

        let order = {
          name: this.$t('m.订单') + (num + 1),
          value:
            this.$t('m.'+this.singleDirection) +
            "("+this.$t('m.投') + ' ' +
            inputCurrency +
            ")" +
            this.contractPair,
        };
        order.singleDirection = this.singleDirection; //新订单方向
        order.inputCurrency = inputCurrency;
        order.sort = num + 1;
        order.contractPair = this.contractPair;
        order.leverRate = this.lever;
        this.orderList.push(order); //添加新订单
        this.orderIndex = this.orderList.length - 1;

        this.createdActionList = []; //新订单置空动作列表
      }
    },

    // 保存第一单动作参数
    firstOrderAction() {

      if (this.createdActionList.length > 0) {
        let payload = {
          controls: this.$refs.child.childParams,
          links: this.$refs.child.linksParams,
        };

        this.createdActionList[this.actionIdx].params = JSON.stringify(payload);

        this.createdActionList[this.actionIdx].percentage = this.$refs.child
          ? this.$refs.child.percentage
          : 100;
      } else {
        this.$message({
          message: this.$t('m.请为订单添加至少一个动作'),
          type: "warning",
        });
        return;
      }

      this.orderList[this.orderIndex].actionList = JSON.parse(
        JSON.stringify(this.createdActionList)
      );
    },

    // 交易对切换
    exchangePair(v) {

      this.contractPairList.forEach((e) => {
        if (e.contractId == v) {
          this.contractCoin = e.counterCoin;
        }
      });

      if (this.transactionType == "spot") {
        this.pair = v;
        this.showPair = v.counterCoin + "/" + v.baseCoin;

        // 判断后续订单币对是否符合

        if (this.orderList.length > this.orderIndex + 1) {
          let cutNum = this.orderList.length - this.orderIndex - 1;
          if (
            v.baseCoin != this.orderList[this.orderIndex + 1].inputCurrency &&
            v.counterCoin != this.orderList[this.orderIndex + 1].inputCurrency
          ) {
            this.orderList.splice(this.orderIndex + 1, cutNum);
          }
        }

        if (this.orderIndex == 0) {
          // 买入/卖出覆盖订单链数据
          if (this.singleDirection == "买入") {
            this.inputCurrency = v.baseCoin;
            this.getCurrency = v.counterCoin;

            this.orderList[this.orderIndex].inputCurrency = v.baseCoin;
            this.orderList[this.orderIndex].getCurrency = v.counterCoin;
            this.orderList[this.orderIndex].value =
              this.$t('m.'+this.singleDirection) +
              "("+this.$t('m.投') + ' ' +
              this.inputCurrency +
              ")" +
              v.counterCoin +
              "/" +
              v.baseCoin;
          } else {
            this.inputCurrency = v.counterCoin;
            this.getCurrency = v.baseCoin;
            this.orderList[this.orderIndex].inputCurrency = v.counterCoin;
            this.orderList[this.orderIndex].getCurrency = v.baseCoin;
            this.orderList[this.orderIndex].value =
              this.$t('m.'+this.singleDirection) +
              "("+this.$t('m.投') + ' ' +
              this.inputCurrency +
              ")" +
              v.counterCoin +
              "/" +
              v.baseCoin;
          }
        } else {

          this.inputCurrency = JSON.parse(
            JSON.stringify(this.orderList[this.orderIndex].inputCurrency)
          );

          if (this.inputCurrency == v.baseCoin) {
            this.singleDirection = "买入";
            this.orderList[this.orderIndex].singleDirection = "买入";
            this.getCurrency = v.counterCoin;
            this.orderList[this.orderIndex].getCurrency = v.counterCoin;
          } else {
            this.singleDirection = "卖出";
            this.orderList[this.orderIndex].singleDirection = "卖出";
            this.getCurrency = v.baseCoin;
            this.orderList[this.orderIndex].getCurrency = v.baseCoin;
          }

          this.orderList[this.orderIndex].value =
            this.$t('m.'+this.singleDirection) +
            "("+this.$t('m.投') + ' ' +
            this.inputCurrency +
            ")" +
            v.counterCoin +
            "/" +
            v.baseCoin;
        }

        this.orderList[this.orderIndex].pair = this.pair;

        if (this.orderIndex == 0) {
          this.$emit("queryOperatorUserList", this.orderList[0].inputCurrency);
        }
      } else {
        for (let key of this.contractPairList) {
          if (key.contractId == v) {
            this.contPair = key;
          }
        }

        let pair = JSON.parse(JSON.stringify(this.contractPair)).split("-");
        let inputCurrency = null;
        let value = null;

        if (this.singleDirection == "开多" || this.singleDirection == "开空") {
          inputCurrency = pair[1];
          if (this.exchangeName == "Binance") {
            inputCurrency = pair[0].substring(
              pair[0].length - 4,
              pair[0].length
            );
          }

          value =
            this.$t('m.'+this.singleDirection) +
            "("+this.$t('m.投') + ' ' +
            inputCurrency +
            ")" +
            this.contPair.counterCoin +
            "-" +
            this.contPair.baseCoin;
        } else {
          inputCurrency = pair[0];
          if (this.exchangeName == "Binance") {
            inputCurrency = pair[0].substring(0, pair[0].length - 4);
          }

          value =
            this.$t('m.'+this.singleDirection) +
            "("+this.$t('m.投') + ' ' +
            inputCurrency +
            ")" +
            this.contPair.counterCoin +
            "-" +
            this.contPair.baseCoin;
        }
        if (value.indexOf("-SWAP") > 0) {
          value = value.split("-SWAP")[0];
        }

        this.orderList[0].value = value;
        this.orderList[0].contractPair = this.contractPair;

        for (let key of this.contractPairList) {
          if (key.contractId == v) {
            this.orderList[0].baseCoin = key.baseCoin;
            this.orderList[0].counterCoin = key.counterCoin;
          }
        }

        if (this.orderList[1]) {
          this.orderList[1].contractPair = this.contractPair;
          this.orderList[1].inputCurrency = this.orderList[0].counterCoin;

          this.orderList[1].value =
            this.orderList[1].singleDirection +
            "("+this.$t('m.投') + ' ' +
            this.orderList[1].inputCurrency +
            ")" +
            this.contPair.counterCoin +
            "-" +
            this.contPair.baseCoin;
        }

        for (let key of this.contractPairList) {
          if (key.contractId == this.contractPair) {
            this.contractSize = key.contractSize;
          }
        }

        if (this.orderIndex == 0) {
          this.$emit("queryOperatorUserList", this.contractPair);
        }
        this.leverChange();
      }
    },

    // 下单方向切换
    changeInvestmentType(v) {

      if (this.transactionType == "spot") {
        // 现货
        if (v == "买入") {
          this.inputCurrency = this.pair.baseCoin;
          this.orderList[this.orderIndex].inputCurrency = this.pair.baseCoin;
          this.orderList[this.orderIndex].getCurrency = this.pair.counterCoin;
          this.orderList[this.orderIndex].value =
            this.$t('m.'+this.singleDirection) +
            "("+this.$t('m.投') + ' ' +
            this.pair.baseCoin +
            ")" +
            this.pair.counterCoin +
            "/" +
            this.pair.baseCoin;
        } else if (v == "卖出") {
          this.inputCurrency = this.pair.counterCoin;
          this.getCurrency = this.pair.baseCoin;
          this.orderList[this.orderIndex].inputCurrency = this.pair.counterCoin;
          this.orderList[this.orderIndex].getCurrency = this.pair.baseCoin;
          this.orderList[this.orderIndex].value =
            this.$t('m.'+this.singleDirection) +
            "("+this.$t('m.投') + ' ' +
            this.pair.counterCoin +
            ")" +
            this.pair.counterCoin +
            "/" +
            this.pair.baseCoin;
        }
        if (this.orderIndex == 0) {
          this.orderList[0].singleDirection = v;
          this.$emit("queryOperatorUserList", this.orderList[0].inputCurrency);
        }

        if (this.orderList.length > 1) {
          let list = JSON.parse(JSON.stringify(this.orderList));
          if (list[0].getCurrency != list[1].inputCurrency) {
            list.splice(1, list.length - 1);
          }

          this.orderList = JSON.parse(JSON.stringify(list));
        }
      } else {
        this.orderList[this.orderIndex].singleDirection = v;

        let pair = JSON.parse(JSON.stringify(this.contractPair)).split("-");
        let inputCurrency = pair[1];

        if (this.exchangeName == "Binance") {
          inputCurrency = pair[0].substring(pair[0].length - 4, pair[0].length);
        }
        let value =
          this.$t('m.'+this.singleDirection) +
          "("+this.$t('m.投') + ' ' +
          inputCurrency +
          ")" +
          this.contPair.counterCoin +
          "-" +
          this.contPair.baseCoin;
        // this.contractPair;

        if (v == "平空" || v == "平多") {
          inputCurrency = pair[0];
          if (this.exchangeName == "Binance") {
            inputCurrency = pair[0].substring(0, pair[0].length - 4);
          }
          value =
            this.$t('m.'+this.singleDirection) +
            "("+this.$t('m.投') + ' ' +
            inputCurrency +
            ")" +
            this.contPair.counterCoin +
            "-" +
            this.contPair.baseCoin;

          // this.contractPair;
        }
        this.orderList[0].value = value;
        this.orderList[0].inputCurrency = inputCurrency;

        this.orderList.splice(1);
        this.leverChange();
      }
    },

    // 当前选中订单
    selectOrder(v) {
      this.inputCurrency = this.orderList[v].inputCurrency;
      this.pair = this.orderList[v].pair;
      this.updateParams();

      this.orderIndex = v;

      let str = this.orderList[this.orderIndex].value;

      this.showPair = str.substring(str.indexOf(")") + 1, str.length);

      this.singleDirection = this.orderList[this.orderIndex].singleDirection;
      if (v == 0) {
        this.investmentType = this.orderList[this.orderIndex].investmentType;
        this.investment = this.orderList[this.orderIndex].investment;
      }

      this.createdActionList = JSON.parse(
        JSON.stringify(this.orderList[this.orderIndex].actionList)
      );

      this.actionParams =
        this.createdActionList.length > 0
          ? JSON.parse(this.createdActionList[0].params)
          : [];

      let orderType = this.orderList[this.orderIndex].executeStates;

      switch (orderType) {
        case "EXECUTE":
          this.executeStates = false;
          break;
        case "STOPING":
          this.executeStates = false;
          break;
        case "STOP":
          this.executeStates = false;
          break;
        case "WAIT_EXECUTE":
          this.executeStates = true;
          break;
        default:
          this.executeStates = true;
          break;
      }

      // this.executeStates =
      //   this.orderList[this.orderIndex].executeStates != "EXECUTE";
      // this.executeStates =
      //   this.orderList[this.orderIndex].executeStates == "STOPING";
      // this.executeStates =
      //   this.orderList[this.orderIndex].executeStates == "STOP";

      if (this.createdActionList.length > 0 && this.$refs.child) {
        this.$refs.child.percentage = this.createdActionList[0].percentage;
      }

      this.actionIdx = 0; //切换订单后复位默认显示动作
    },

    // 更新参数
    updateParams() {
      if (this.$refs.child && this.createdActionList.length > 0) {
        let payload = {
          controls: this.$refs.child.childParams
            ? this.$refs.child.childParams
            : null,
          links: this.$refs.child.linksParams
            ? this.$refs.child.linksParams
            : null,
        };

        this.createdActionList[this.actionIdx].params = JSON.stringify(payload);

        this.createdActionList[this.actionIdx].percentage = this.$refs.child
          ? this.$refs.child.percentage
          : 100;
      }

      this.orderList[this.orderIndex].actionList = JSON.parse(
        JSON.stringify(this.createdActionList)
      );
    },

    // 删除订单
    deteleOrder(idx) {
      let allNum = this.orderList.length;
      this.orderList.splice(idx, allNum - idx);
      if (this.orderList.length > 1) {
        this.orderIndex = this.orderList.length - 1;
      } else {
        this.orderIndex = 0;
      }

      if (this.orderList.length != 0) {
        this.createdActionList = this.orderList[this.orderIndex].actionList;

        this.actionParams = JSON.parse(this.createdActionList[0].params);

        if (this.$refs.child) {
          this.$refs.child.percentage = this.createdActionList[0].percentage;
        }
      }
      this.singleDirection = this.orderList[0].singleDirection;
      this.actionIdx = 0;
    },

    // 订单链解释文案
    OrderChainInterpretation() {
      this.$alert(
        "<div style='color:#102859'><p>"+
        this.$t('m.订单链由顺序执行的多个订单构成，可充分扩展，使您的下单计划不再受限，资金使用更高效。')+
        "</p><p>"+
        this.$t('m.1、在上一个订单成交后，所获得的持仓投入下一个订单。')+
        "</p><div>"+
        this.$t('m.例：开启两个订单，前一订单设置的交易对为BTC/USDT，投入USDT买入BTC，成交后获得BTC，则后一个订单的投入币种为BTC，如后一订单设置的交易对也为BTC/USDT，则委托方向为卖出；如后一订单设置的交易对为ETH/BTC，则委托方向为买入。')+
        "</div><p>"+
        this.$t('m.2、订单按顺序执行，删除其中一个，后续订单都将被删除。')+
        "</p></div>",
        this.$t('m.订单链'),
        {
          confirmButtonText: this.$t('m.确定'),
          dangerouslyUseHTMLString: true,
        }
      );
    },

    // 投入解释
    inputInterpretation() {
      this.$alert(
        "<div style='color:#102859'><p>"+
        this.$t('m.1、设置固定金额时，不满足金额或数量的账户不下单；')+
        "</p><p>"+
        this.$t('m.2、设置比例投入，账户满足比例时，低于下限的不下单；高于上限的按上限下单。')+
        "</p></div>",
        this.$t('m.投入'),
        {
          confirmButtonText: this.$t('m.确定'),
          dangerouslyUseHTMLString: true,
        }
      );
    },

    // 投入方式切换
    selecTinvestmentType(v) {
      this.orderList[0].investmentType = v;

      this.investment = null;
      this.orderList[0].investment = null;

      this.$emit("childTrigger", v, this.investment);
    },

    // 投入校验
    verification(e) {

      let num = Number(this.investment);
      if (this.investmentType == "固定投入") {
        if (num < 0) {
          this.$message.error(this.$t('m.投入不能小于0'));
          e.target.style.border = "1px solid red";
          this.inputVerification = false;
        } else {
          e.target.style.border = "1px solid #DCDFE6";
          this.inputVerification = true;
        }
      } else {
        if (num < 0) {
          this.$message.error(this.$t('m.投入比例不能小于0'));
          e.target.style.border = "1px solid red";
          this.inputVerification = false;
          return;
        } else if (num > 100) {
          this.$message.error(this.$t('m.投入比例不能大于100'));
          e.target.style.border = "1px solid red";
          this.inputVerification = false;
          return;
        } else {
          e.target.style.border = "1px solid #DCDFE6";
          this.inputVerification = true;
        }
      }

      this.orderList[0].investment = num;
      this.$emit("childTrigger", this.investmentType, num);
      this.leverChange();
    },

    // *********************动作事件系列*****************************

    // 查询动作列表
    async queryOrderActionList() {
      if (this.createdActionList.length >= 5) {
        this.$message({
          message: this.$t('m.动作数最多支持5个'),
          type: "warning",
        });
        return;
      }
      let payload = {
        token: sessionStorage.getItem("token"),
      };
      queryOrderActionList(payload).then((res) => {
        if (res.status == 200) {
          this.actionList = res.data.orderActionList;
          this.actionName = this.actionList[0].name;
          this.actionIsShow = true;
        }
      });
    },

    // 动作弹窗取消按钮
    handleClose(done) {
      this.actionIndex = 0;
      this.actionName = this.actionList[0].name;
      this.actionIsShow = false;
    },

    // 动作选中切换
    selectAction(idx, v) {
      this.actionName = v.name;
      this.actionIndex = idx;
    },

    // 创建动作
    handleConfirm() {
      let payload = {
        name: this.actionName,
        token: sessionStorage.getItem("token"),
      };
      queryOrderActionDetails(payload).then((res) => {
        if (res.status == 200) {

          if (this.createdActionList.length != 0) {
            // 获取上单的动作/上单的资金占比
            let params = {
              controls: this.$refs.child.childParams,
              links: this.$refs.child.linksParams,
            };

            // 保存上单的动作
            this.createdActionList[this.actionIdx].params =
              JSON.stringify(params);
            // 保存上单的资金占比
            this.createdActionList[this.actionIdx].percentage =
              this.$refs.child.percentage;
          }


          this.actionDetail = res.data.orderActionDetails;

          this.actionDetail.isCheck = true;

          this.createdActionList.push(this.actionDetail);

          // 传递的动作参数/links
          this.actionParams = JSON.parse(res.data.orderActionDetails.params);
          this.actionIdx = this.createdActionList.length - 1;


          if (this.$refs.child) {
            this.$refs.child.percentage = 100;
          }
        }
      });

      this.actionIndex = 0;
      this.actionName = this.actionList[0].name;
      this.actionIsShow = false;
    },

    // 当前选中动作参数切换
    curSelectAction(v) {
      // 切换前保存上个动作参数

      let params = {
        controls: this.$refs.child.childParams,
        links: this.$refs.child.linksParams,
      };

      this.createdActionList[this.actionIdx].percentage =
        this.$refs.child.percentage;

      this.createdActionList[this.actionIdx].params = JSON.stringify(params);

      this.actionIdx = v;

      // 传递新选动作参数
      this.actionParams = JSON.parse(this.createdActionList[v].params);
      this.$refs.child.percentage = this.createdActionList[v].percentage;

      this.actionDetailParams = JSON.parse(
        this.createdActionList[v].params
      ).controls;
      this.links = JSON.parse(this.createdActionList[v].params).links;
    },

    // 删除动作
    detele(v) {
      // 保存上个动作参数
      let params = {
        controls: this.$refs.child.childParams,
        links: this.$refs.child.linksParams,
      };

      this.createdActionList[this.actionIdx].percentage =
        this.$refs.child.percentage;
      this.createdActionList[this.actionIdx].params = JSON.stringify(params);

      this.createdActionList.splice(v, 1);

      let num = this.createdActionList.length;

      if (num > 1) {
        this.actionIdx = num - 1;
      } else {
        this.actionIdx = 0;
      }

      if (num != 0) {
        this.actionParams = JSON.parse(
          this.createdActionList[this.actionIdx].params
        );
        this.$refs.child.percentage = this.createdActionList[v].percentage;
      }
    },

    // 查询合约杠杆倍数

    findByIdLeverMultiple(payload) {
      findByIdLeverMultiple(payload).then((res) => {
        if (res.status == 200) {
          this.leverList = JSON.parse(res.data.exchangeMap.leverMultiple);
          // this.lever = 3;
        }
      });
    },

    //查询合约交易对
    queryOrderChainContractPair(payload, update) {
      queryOrderChainContractPair(payload).then((res) => {
        if (res.status == 200) {
          this.contractPairList = res.data.resultMap;
          if (!update && this.startAcc.length == 0) {
            this.contractPair = this.contractPairList[0].contractId;
            this.contractCoin = this.contractPairList[0].counterCoin;
            this.contractSize = this.contractPairList[0].contractSize;
            this.contractPairInfo = this.contractPairList[0];
            this.contPair = this.contractPairList[0];
            this.contractOrder();
          } else {
            let list = res.data.resultMap;
            for (let key of list) {
              if (this.contractPair == key.contractId) {
                this.contractSize = Number(key.contractSize);
                this.contractPairInfo = key;
                this.contPair = key;
              }
            }
          }

          if (this.startAcc.length != 0) {
            for (let key of this.contractPairList) {
              if (key.contractId.indexOf(this.startAcc[0].coinType) != -1) {
                this.contractPair = key.counterCoin + "-" + key.baseCoin;
                this.contractSize = key.contractSize;
                this.contractPairInfo = key;
                this.contPair = key;
              }
            }
          }
        }
      });
    },

    // 初始化合约第一单
    contractOrder() {
      this.createdActionList = [];
      this.actionParams = null;
      this.orderList = [];
      this.actionDetail = null;
      let pair = JSON.parse(JSON.stringify(this.contractPair)).split("-");
      let inputCurrency = pair[1];
      let contractPairNow;
      if (this.exchangeName == "Binance") {
        inputCurrency = pair[0].substring(pair[0].length - 4, pair[0].length);
      } else if (this.exchangeName == "OKexV5") {
        contractPairNow = pair[0] + "-" + pair[1];
      }
      let order = {
        name: this.$t('m.订单') + " 1",
        value:
          this.$t('m.'+this.singleDirection) +
          "("+this.$t('m.投') + ' ' +
          inputCurrency +
          ")" +
          this.contractPair,
      };

      order.singleDirection = this.singleDirection;
      order.investmentType = this.investmentType;
      order.investment = this.investment;
      order.inputCurrency = inputCurrency;
      order.sort = 1;
      order.contractPair = this.contractPair;
      order.leverRate = this.lever;
      order.baseCoin = this.contractPairInfo.baseCoin;
      order.counterCoin = this.contractPairInfo.counterCoin;
      if (order.value.indexOf("-SWAP") > 0) {
        order.value = order.value.split("-SWAP")[0];
      }

      // 新改动

      order.value =
        this.$t('m.'+this.singleDirection) +
        "("+this.$t('m.投') + ' ' +
        inputCurrency +
        ")" +
        this.contPair.counterCoin +
        "-" +
        this.contPair.baseCoin;

      this.orderList.push(order);

      this.$emit("queryOperatorUserList", this.contractPair);
    },

    //传递/杠杆/size/
    leverChange(v) {

      for (let key of this.orderList) {
        key.leverRate = this.lever;
      }
      this.orderList[0].proportionMax = this.proportionMax;
      this.orderList[0].proportionMin = this.proportionMin;
      this.orderList[0].investmentType = this.investmentType;
      if (this.singleDirection == "平多" || this.singleDirection == "平空") {
        this.investmentType = "固定投入";
        this.proportionMin = null;
        this.proportionMax = null;
      }
      this.$emit(
        "leverTypeChange",
        this.lever,
        this.singleDirection,
        this.investmentType,
        this.investment,
        this.proportionMin,
        this.proportionMax
      );
    },
  },
  created() {},
  mounted() {
    let project = JSON.parse(sessionStorage.getItem("project"));
    this.project = project;
  },
  beforeCreate() {},
  beforeMount() {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {},
  activated() {},
  deactivated() {},
};
</script>
<style lang='scss' scoped>
//@import url(); 引入公共css类
.orderChain {
  width: 100%;
  ::v-deep.el-input__suffix {
    line-height: 40px;
  }
  .create {
    width: 100%;
    display: flex;
    flex: 1;
    // 左侧
    .create-chain {
      // width: 400px;
      width: 30%;
      //   max-width: 370px;
      min-height: 698px;
      background: #fff;
      box-shadow: 20px 10px 22px 0px rgba(109, 147, 176, 0.08);
      border-radius: 8px 0 0 8px;
      padding: 20px;
      .create-chain-title {
        display: flex;
        align-items: center;
        padding-bottom: 20px;
        border-bottom: 1px solid #e6e9ee;
        span {
          color: #102859;
          font-size: 16px;
          margin-right: 5px;
        }
        .icon-jieshi {
          width: 14px;
          height: 14px;
          cursor: pointer;
        }
      }
      .create-chain-cont {
        padding: 20px 0;
        ::v-deep.el-timeline-item__node--normal {
          top: 26px;
        }
        ::v-deep.el-timeline-item__tail {
          top: 26px;
        }
        ::v-deep.el-card__body {
          height: 94px;
          //   border: 1px solid #e6e9ee;
          padding: 0;
          box-shadow: none !important;
        }
        .chain-box {
          position: relative;
          padding: 20px;
          background: #f7f7f7;
          border-radius: 4px;
          border: 1px solid #e6e9ee;
          color: #14263d;
          font-size: 16px;
          cursor: pointer;
          .title {
            display: flex;
            align-items: center;
            margin-bottom: 10px;
            .type {
              font-size: 14px;
              margin-left: 6px;
            }
          }
          .icon-detail {
            width: 8px;
            height: 8px;
            position: absolute;
            top: 10px;
            right: 10px;
            cursor: pointer;
          }
        }
        .chain-box-on {
          background: #2174ff;
          color: #fff;
        }
        .btn-warp {
          width: 120px;
          height: 34px;
          background: #2174ff;
          border-radius: 4px;
          font-size: 14px;
          color: #fff;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-left: 30px;
          cursor: pointer;
          .icon-add {
            width: 14px;
            height: 14px;
            cursor: pointer;
            margin-right: 4px;
            margin-top: 2px;
          }
        }

        .disable {
          background: #909399;
        }
      }
    }

    // 中间
    .parameter {
      // width: 760px;
      // width: calc(60% - 40px);

      flex: 1;
      background: #ffffff;
      box-shadow: 0px 11px 22px 0px rgba(109, 147, 176, 0.08);
      border-radius: 0px 8px 8px 0px;
      padding: 20px;
      .parameter-title {
        display: flex;
        align-items: center;
        font-size: 12px;
        padding-bottom: 20px;
        border-bottom: 1px solid #e6e9ee;
        :first-child {
          font-size: 16px;
          color: #102859;
          margin-right: 10px;
        }
        .red {
          font-size: 14px;
          color: #e12525;
        }
      }
      .parameter-select {
        padding: 20px 0;
        ::v-deep.el-input-group__append {
          height: 38px;
          line-height: 38px;
        }

        // 现货
        .select-warp {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding-bottom: 20px;
          div {
            // width: 350px;
            width: 49%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .proportion {
              display: flex;
              justify-content: flex-start;
              align-items: center;
            }
            .red {
              color: #e12525;
            }
            .el-select,
            .el-input {
              width: 70%;
            }

            .invest {
              display: flex;
              align-items: center;
              .icon-jieshi {
                width: 14px;
                height: 14px;
                cursor: pointer;
              }
            }
          }
        }
        //合约
        .cont-select-warp {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding-bottom: 20px;

          div {
            width: 32%;
            display: flex;
            align-items: center;
            .el-select {
              width: 60%;
            }
            .first-el-select {
              width: 60%;
            }
            .invest,
            .proportion {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              .icon-jieshi {
                width: 14px;
                height: 14px;
                cursor: pointer;
              }
              .red {
                color: #e12525;
              }
            }
            .el-input {
              width: 60%;
            }
          }
          .first-warp {
            width: 30%;
          }
          .proportion-warp,
          .inves {
            width: 48%;
            .el-input,
            .el-select {
              width: 80%;
            }
          }
        }
        // 范围
        .invest-rang {
          .invest-rang-title {
            margin: 10px 0;
          }
          .invest-rang-cont {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .one {
              width: 25%;
            }
            .two {
              width: 35%;
              text-align: right;
              .el-input {
                width: 80%;
              }
            }
            .face-value {
              width: 35%;
              display: flex;
              flex-direction: column;
            }
          }
        }
      }

      //   动作
      .action {
        .action-title {
          color: #102859;
          font-size: 16px;
          padding-bottom: 20px;
        }

        .action-cont {
          display: flex;
          .cont-left {
            width: 142px;
            .left-box {
              display: flex;
              flex-direction: column;
              justify-content: space-around;
              height: 56px;
              width: 142px;
              margin-bottom: 10px;
              border-radius: 4px 0px 0px 4px;
              border: 1px solid #e6e9ee;
              padding: 8px 0;
              border-right: none;
              cursor: pointer;
              .box-cont {
                padding: 0 10px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                .trigger {
                  width: 12px;
                  height: 12px;
                  border-radius: 50%;
                  background: #13ce66;
                  margin-left: -65px;
                  margin-top: 2px;
                }
                .icon-xiala {
                  width: 12px;
                  height: 12px;
                  cursor: pointer;
                }
                .icon-detail {
                  width: 11px;
                  height: 13px;
                  cursor: pointer;
                }
              }
            }
            .on {
              background: #e6e9ee;
            }
            .btn-warp {
              width: 120px;
              height: 34px;
              display: flex;
              align-items: center;
              justify-content: center;
              color: #2174ff;
              border: 1px solid #2174ff;
              border-radius: 4px;
              margin-top: 20px;
              cursor: pointer;
              .icon-add {
                width: 14px;
                height: 14px;
                cursor: pointer;
                margin-top: 2px;
                margin-right: 4px;
              }
            }
          }

          .cont-right {
            border-radius: 0px 8px 8px 8px;
            border: 1px solid #e6e9ee;
            padding: 30px 20px;
            // width: 620px;
            width: 100%;
            min-height: 400px;
            // 数字输入框
            // .params-warp {
            //   .params {
            //     .title {
            //       margin-bottom: 10px;
            //       font-size: 16px;
            //       color: #102859;
            //       display: flex;
            //       justify-content: space-between;
            //       align-items: center;
            //       .title-left {
            //         display: flex;
            //         align-items: center;
            //         .red {
            //           color: #e12525;
            //         }
            //         .icon-jieshi {
            //           width: 14px;
            //           height: 14px;
            //           cursor: pointer;
            //           margin-left: 6px;
            //         }
            //         .rang {
            //           color: #55698a;
            //           font-size: 12px;
            //           margin-left: 10px;
            //         }
            //       }
            //       .title-right {
            //         font-size: 12px;
            //         display: flex;
            //         align-items: center;
            //       }
            //     }
            //     .params-box {
            //       margin-bottom: 20px;
            //       .red {
            //         display: inline-block;
            //         color: #e12525;
            //         font-size: 12px;
            //         margin-top: 6px;
            //       }
            //       .el-select,
            //       .el-date-editor,
            //       .el-cascader {
            //         width: 100%;
            //       }

            //       .radio-warp {
            //         display: flex;
            //         align-items: center;
            //         span {
            //           flex: 1;
            //           color: #102859;
            //           height: 40px;
            //           display: flex;
            //           justify-content: center;
            //           align-items: center;
            //           border-radius: 4px;
            //           cursor: pointer;
            //           background: #f7f7f7;
            //         }
            //         :last-child {
            //           background: #2174ff;
            //           color: #fff;
            //         }
            //       }

            //       //   时长选择
            //       .duration {
            //         display: flex;
            //         justify-content: space-between;
            //         align-items: center;
            //         .el-input,
            //         .el-date-editor {
            //           width: 40%;
            //         }
            //       }
            //     }
            //   }
            // }
          }
          .default {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            color: #758db5;
            margin-top: 60px;
            img {
              width: 184px;
              height: 143px;
            }
            .text {
              margin: 20px 0 20px 0;
            }
            .btn-warp {
              width: 100px;
              height: 34px;
              display: flex;
              justify-content: center;
              align-items: center;
              cursor: pointer;
              color: #2174ff;
              border-radius: 4px;
              border: 1px solid #2174ff;
              img {
                width: 14px;
                height: 14px;
                margin-right: 4px;
              }
            }
          }
        }
      }
    }
  }

  ::v-deep.el-dialog__body {
    border-top: 1px solid #e6e9ee;
  }
  .action-warp {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0 30px;
    span {
      width: 104px;
      height: 34px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      border: 1px solid #e6e9ee;
      color: #55698a;
      cursor: pointer;
      margin-bottom: 10px;
    }
    .on {
      background: #2174ff;
      color: #fff;
    }
  }
}
</style>