<template>
  <div class="pairLimit">
    <!-- 币对限制 -->
    <div class="pair-restrictions">
      <span>{{$t('m.交易对限制')}}</span>
      <span>（{{$t('m.最多支持20个币对，项目上线后不可修改，请谨慎选择')}}）</span>
    </div>
    <div class="pair-restrictions-box">
      <div class="add-box">
        <el-select v-model="pair" :placeholder="$t('m.请选择')" filterable>
          <el-option
            v-for="(item, idx) in pairList"
            :key="idx"
            :label="item.counterCoin + '/' + item.baseCoin"
            :value="item.counterCoin"
          >
          </el-option>
        </el-select>
        <span class="pair-restrictions-btn" @click="pairAdd">{{$t('m.添加')}}</span>
      </div>
      <div class="pairList">
        <el-tag
          class="tag"
          v-for="(tag, idx) in tags"
          :key="idx"
          closable
          @close="tagClose(tag)">
          {{ tag.counterCoin + "/" + tag.baseCoin }}
        </el-tag>
      </div>
    </div>
    <div
      class="btn"
      @click="confirm()"
      v-if="projectInfo && projectInfo.showStatus == 'notOnline'" >{{$t('m.提 交')}}</div>
  </div>
</template>

<script>
import {
  getProjectPairById,
  addProjectPair,
  getTradePair,
} from "../../request/api";
export default {
  components: {},
  data() {
    return {
      project: null,
      projectInfo: null,
      pair: null, //交易对
      tags: [],
      exchangeList: [],

      pairList: null,
      historyList: null,
    };
  },
  computed: {},
  watch: {},
  methods: {
    // 查询交易对
    getProjectPairById() {
      let payload = {
        token: sessionStorage.getItem("token"),
        teamProjectId: this.project.teamProjectId,
      };
      getProjectPairById(payload).then((res) => {
        if (res.status == 200) {
          this.pairList = res.data.pairList;
        } else {
          this.$message.error(res.msg);
        }
      });
    },

    // 交易对添加
    pairAdd() {
      let name = this.pair;
      let list = this.pairList;
      let newlist = this.tags;
      for (let i = 0; i < newlist.length; i++) {
        if (name == newlist[i].counterCoin) {
          this.$message.warning(this.$t('m.已经存在所选交易对'));
          return;
        }
      }
      if (this.tags.length >= 20) {
        this.$message.warning(this.$t('m.最多支持20个交易对'));
        return;
      }

      for (let i = 0; i < list.length; i++) {
        if (name == list[i].counterCoin) {
          this.tags.push(list[i]);
          console.log(this.pairId, this.tags);
          return;
        }
      }
    },
    // tag删除
    tagClose(v) {
      console.log(v);
      let list = this.tags;
      if (list.length == 1) {
        this.$message.warning(this.$t('m.最少选择一个交易对'));
        return;
      }
      for (let i = 0; i < list.length; i++) {
        if (v.counterCoin == list[i].counterCoin) {
          list.splice(i, 1);
          return;
        }
      }
    },
    // 保存
    confirm() {
      let list = this.tags;
      let pairs = [];
      for (let i = 0; i < list.length; i++) {
        let item = {};
        item.baseCoin = list[i].baseCoin;
        item.counterCoin = list[i].counterCoin;
        item.pairId = list[i].pairId;
        pairs.push(item);
      }
      console.log(pairs, this.tags);
      let payload = {
        token: sessionStorage.getItem("token"),
        teamProjectId: this.project.teamProjectId,
        pairs: pairs,
      };
      console.log(payload);
      addProjectPair(payload).then((res) => {
        if (res.status == 200) {
          this.$emit("getProject");
          this.$message.success(this.$t('m.保存成功'));
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 查询历史选择交易对
    getTradePair() {
      let payload = {
        token: sessionStorage.getItem("token"),
        teamProjectId: this.project.teamProjectId,
      };
      getTradePair(payload).then((res) => {
        if (res.status == 200) {
          this.historyList = res.data.history;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
  created() {},
  mounted() {
    let project = sessionStorage.getItem("project");
    let projectInfo = JSON.parse(sessionStorage.getItem("projectInfo"));
    this.projectInfo = projectInfo;
    if (JSON.parse(this.projectInfo.tradePair)) {
      this.tags = JSON.parse(this.projectInfo.tradePair);
    }
    console.log(this.projectInfo, this.tags);
    this.project = JSON.parse(project);
    this.getProjectPairById();
    // this.getTradePair()
  },
  beforeCreate() {},
  beforeMount() {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {},
  activated() {},
};
</script>
<style lang='scss' scoped>
.pairLimit {
  ::v-deep.el-input__inner {
    height: 30px;
    line-height: 30px;
  }
  ::v-deep.el-input__icon {
    line-height: 30px;
  }
  .pair-restrictions {
    color: #333;
    font-size: 14px;
    margin-bottom: 8px;
    margin-top: 10px;
    :last-child {
      color: #999;
      font-size: 12px;
    }
  }
  .pair-restrictions-box {
    font-size: 14px;
    .add-box {
      display: flex;
      margin-bottom: 12px;
      .pair-restrictions-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50px;
        height: 30px;
        color: #fff;
        background: #007aff;
        border-radius: 4px;
        margin-left: 16px;
        cursor: pointer;
      }
    }
    .pairList {
      margin-bottom: 15px;
    }
    .historyPair {
      margin-top: 20px;
      font-size: 14px;
      .title {
        margin-bottom: 20px;
        :last-child {
          font-size: 12px;
          color: #999;
          margin-left: 8px;
        }
      }
    }
    .tag {
      margin: 0 38px 10px 0;
    }
  }
  .btn {
    margin-top: 50px;
    width: 60px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    color: #fff;
    background: #007aff;
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;
  }
}
</style>