<template>
  <div class="box">
    <div class="nav">
      <div class="contractSelect">

        <div class="contractData">
          <template v-for="(item, index) in exchangeList">
            <div
              :class="index == contractActive ? 'bcgStyle' : ''"
              class="contractDataItem"
              @click="contractTableBtn(index)"
              :key="index">
              {{ item.exchangeName }}
            </div>
          </template>
        </div>

        <div class="contractShow">
          <el-tooltip
            class="item"
            effect="dark"
            :content="$t('m.开启后，价值小于10 USDT的币种将会被隐藏。')"
            placement="bottom-end">
            <span class="brdTipsStyle">
              {{$t('m.隐藏小额币种')}}</span>
          </el-tooltip>
          <el-switch
            v-model="showSmallCoin"
            inactive-color="#eaecf0"
            active-color="#2174FF">
          </el-switch>
        </div>
      </div>
      <div class="topData">
        <div class="topDataList">
          <template v-for="(item, index) in Tab">
            <div
              :class="index == tableActive ? 'brdStyle' : ''"
              class="topDataItem"
              @click="tableDataBtn(index)"
              :key="index">
              {{ $t('m.'+item) }}
            </div>
          </template>
        </div>
        <div class="contractType">
          {{$t('m.账户类型')}}：{{
            this.exchangeName == "OkexV5"
              ? $t('m.统一账户')
              : warehouse
              ? $t('m.逐仓')
              : $t('m.全仓')
          }}：</div>
      </div>
      <div style="border: 1px solid #e6e9ee" v-if="tableActive == 0">
        <div class="title">
          <div class="topColumn">
            <SearchBox
              @btnSubmit="searchBtn"
              @btnBack="btnBack"
              :searchData="searchData"
            />
          </div>
          <div class="columnRight">
            <span @click="batchSpotApiBtn">
              <!-- <img src="../../assets/icon/suiji-bai.png" alt="" /> -->
              <div>{{$t('m.同步资产')}}</div>
            </span>
            <span @click="exchangeBtn()">
              {{$t('m.快速兑换')}}【{{ startCoin ? startCoin : "--" }}】</span>
          </div>
        </div>
        <DetailsData
          :rowStyleData="rowStyleData"
          :data="tableData"
          @selectRow="selectRow"
          @transferDetermineBtn="transferDetermineBtn"
          :label="orderData"
          :detailsDelete="detailsDelete"
          class="detailsData"
          @setActive="setActive"
        />
      </div>
      <div class="contract" v-if="tableActive == 1">
        <div class="title">
          <SearchBox
            class="topColumn"
            @btnSubmit="searchBtn"
            @btnBack="btnBack"
            :searchData="contractSearch"
          />
          <div class="columnRight">
            <span @click="synchronization">
              {{$t('m.同步资产')}}</span>
            <span @click="quickFlatPosition">
              {{$t('m.快速平仓')}}</span>
          </div>
        </div>
        <div v-if="contractTable.length !== 0" class="tableStyle">
          <div class="tableTitle">
            <div>{{$t('m.账户')}}</div>
            <div>{{$t('m.合约')}}</div>
            <div>{{$t('m.权益')}}(USDT)</div>
            <div>{{$t('m.可用担保资产')}}(USDT)</div>
            <div style="flex: 1.5"></div>
            <el-checkbox
              @change="allContractSelect"
              v-model="contractSelectBtn"
            ></el-checkbox>
          </div>
          <div class="tableNav">
            <div class="tableItem" v-for="(item, index) in contractTable" :key="'contractTable'+index">
              <div class="tableFirst">
                <div>
                  {{ item.remarkCode ? item.remarkCode : item.publicCode }}
                </div>
                <div>{{ item.contractId }}</div>
                <div>{{ item.equity }}</div>
                <div>{{ item.available }}</div>
                <div class="contractBtn" style="flex: 1.5">
                  <span
                    @click="transferBtn(item)"
                    v-if="exchangeName !== 'OkexV5'">
                    {{$t('m.划转')}}</span>
                  <span
                    @click="templateBtn(item)"
                    v-if="!item.strategyStart && !news">
                    {{$t('m.启动策略')}}</span>
                  <span
                    @click="orderBtn(item)"
                    v-if="!item.orderChainStart && !news">
                    {{$t('m.创建订单链')}}</span>
                  <span
                    @click="item.show = !item.show"
                    v-if="item.positions !== null">
                    {{$t('m.详情')}}<img
                      v-if="item.show"
                      style="width: 12px; background: white"
                      src="../../assets/icon/zxxq_btn-lan.png"
                      alt=""
                    />
                    <img
                      v-else
                      style="width: 12px; background: white"
                      src="../../assets/icon/xiala-lan.png"
                      alt=""
                    />
                  </span>
                </div>
                <el-checkbox
                  @change="FirstSelect(item)"
                  v-model="item.select"
                ></el-checkbox>
              </div>
              <div class="tableTwo" v-if="item.show && item.positions">
                <div class="tableTwoTitle">
                  <span>{{$t('m.合约')}}</span>
                  <span>{{$t('m.方向')}}</span>
                  <span>{{$t('m.持仓量(张)')}}</span>
                  <span>{{$t('m.可平量(张)')}}</span>
                  <span>{{$t('m.开仓均价')}}({{ item.investCoin }})</span>
                  <span>{{$t('m.担保资产率')}}</span>
                  <span>{{$t('m.预估强平价')}}({{ item.investCoin }})</span>
                  <span>{{$t('m.收益')}}({{ item.investCoin }})</span>
                  <span>{{$t('m.收益率')}}</span>
                  <div style="width: 14px"></div>
                </div>
                <div class="tableTwoNav" v-for="(i, index) in item.positions" :key="'positions'+index">
                  <span>
                    {{ i.contractIdType + "·永续" }}
                  </span>
                  <span
                    :style="{ color: i.direction == 'buy' ? 'red' : 'green' }">
                    {{ i.direction == "buy" ? "做多" : "做空" }}·{{
                      i.leverRate
                    }}x {{ i.marginMode == "isolated" ? "逐仓" : "全仓" }}</span>
                  <span>{{ i.volumeNum }}</span>
                  <span>{{ i.availableNum }}</span>
                  <span>{{ i.costOpen }}</span>
                  <span>{{ i.riskRate }}%</span>
                  <span>{{
                    i.liquidationPrice == 0 ? "--" : i.liquidationPrice
                  }}</span>
                  <span
                    :style="{
                      color: i.profit < 0 ? 'green' : i.profit > 0 ? 'red' : '',
                    }"
                    >{{ i.profit }}</span>
                  <span
                    :style="{
                      color:
                        i.profitRate < 0
                          ? 'green'
                          : i.profitRate > 0
                          ? 'red'
                          : ''}">
                    {{ i.profitRate }}%</span>
                  <el-checkbox
                    v-model="i.select"
                    @change="TwoSelect(item)"
                  ></el-checkbox>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="noTableStyle">
          {{$t('m.暂无数据')}}</div>
      </div>
      <div class="contract" v-if="tableActive == 2">
        <div class="title">
          <SearchBox
            class="topColumn"
            @btnSubmit="searchBtn"
            @btnBack="btnBack"
            :searchData="contractSearch"
          />
          <div class="columnRight">
            <span @click="synchronizationV5">
              {{$t('m.同步资产')}}</span>
          </div>
        </div>
        <div v-if="contractTable.length !== 0" class="tableStyle">
          <div class="tableTitle">
            <div>{{$t('m.账户')}}</div>
            <div>{{$t('m.币种')}}</div>
            <div style="width: 500px">
              {{$t('m.可用')}}</div>
            <div></div>
            <el-checkbox
              @change="allContractSelect"
              v-model="contractSelectBtn"
            ></el-checkbox>
          </div>
          <div class="tableNav">
            <div class="tableItem" v-for="(item, index) in contractTable" :key="'contractTable'+index">
              <div class="tableFirst">
                <div>
                  {{ item.remarkCode ? item.remarkCode : item.publicCode }}
                </div>
                <div>{{ item.coinType }}</div>
                <div style="width: 500px">{{ item.available }}</div>
                <div class="contractBtn">
                  <span @click="transferDetermineBtn(item)">
                    {{$t('m.划转')}}</span>
                </div>
                <el-checkbox
                  @change="FirstSelect(item)"
                  v-model="item.select"
                ></el-checkbox>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="noTableStyle">
          {{$t('m.暂无数据')}}</div>
      </div>
    </div>
    <div class="paginStyle">
      <div></div>
      <div v-if="listNumber >= 10" class="paginCenter">
        <span>{{$t('m.共计持仓&(1)条').replace('&(1)', listNumber)}}</span>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="currentPage"
          :page-size="10"
          background
          layout="prev, pager, next, jumper"
          :total="listNumber">
        </el-pagination>
      </div>
      <div v-if="tableActive !== 1">
        {{$t('m.已选择&(1)条').replace('&(1)', (selectRowList ? selectRowList.length : 0))}}
      </div>
      <div v-else></div>
    </div>
    <el-dialog
      title="快速兑换USDT"
      :visible.sync="activeShow"
      width="400px"
      center
      :before-close="handleClose"
      :close-on-click-modal="false">
      <div class="dialog-nav">
        <div>{{$t('m.确认快速兑换USDT后所选持仓将以taker单快速兑换为USDT。')}}</div>
      </div>
      <div slot="footer">
        <div class="dialog-footer">
          <div class="footerBack" @click="activeShow = false">
            {{$t('m.取消')}}</div>
          <div class="footerBtn" @click="activeBtn">
            {{$t('m.确定')}}</div>
        </div>
      </div>
    </el-dialog>
    <!-- 划转 -->
    <el-dialog
      title="划转"
      :visible.sync="transfer"
      width="400px"
      center
      :before-close="handleClose"
      :close-on-click-modal="false">
      <div class="dialog-nav">
        <div class="dialog-box">
          <div>
            <div class="dialog-top">
              {{$t('m.从:')}}
              <el-select
                size="medium"
                v-model="topValue"
                @change="topValueChange"
                :placeholder="$t('m.请选择')">
                <el-option
                  v-for="(item, index) in topOptions"
                  :key="index"
                  :value="item.name">
                </el-option>
              </el-select>
            </div>

            <div class="dialog-top">
              {{$t('m.到:')}}
              <el-select
                size="medium"
                v-model="twoValue"
                @change="twoValueChange"
                :placeholder="$t('m.请选择')">
                <el-option
                  v-for="(item, index) in twoOptions"
                  :key="index"
                  :value="item.name">
                </el-option>
              </el-select>
            </div>
          </div>
          <img
            @click="transformationBtn()"
            class="dialog-img"
            src="../../assets/icon/zhuanhuan-big.png"
            alt=""
          />
        </div>
        <div class="dialog-currency" style="margin-bottom: 15px">
          <span>{{$t('m.币种')}}</span>
          <el-select size="medium" v-model="currencyValue" :placeholder="$t('m.请选择')">
            <el-option
              v-for="(item, index) in currencyList"
              :key="index"
              :label="item"
              :value="item">
            </el-option>
          </el-select>
        </div>
        <div class="dialog-currency">
          <span>{{$t('m.划转数量')}}</span>
          <el-input
            v-model="inputOptions"
            type="number"
            :placeholder="$t('m.请输入内容')"
            :class="inputStyle ? 'inputStyleItem' : ''"
            @focus="inputNumberFocus($event)"
            @blur="inputNumber($event)">
            <template slot="append"
              >USDT |
              <span
                style="cursor: pointer; color: #2174ff"
                @click="inputOptions = usdtAvailable">
                {{$t('m.全部')}}</span>
            </template>
          </el-input>
        </div>
        <span style="margin-top: 10px">
          {{$t('m.可用划转')}} {{ usdtAvailable + " " + currencyValue }}
        </span>
      </div>
      <div slot="footer">
        <div class="dialog-footer">
          <div
            class="footerBack"
            @click="
              transfer = false;
              inputOptions = '';
              currencyValue = 'USDT';
            ">
            {{$t('m.取消')}}
          </div>
          <div class="footerBtn" @click="transferDetermine()">
            {{$t('m.确定')}}</div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { number } from "echarts";
import DetailsData from "../../components/detailsData.vue";
import SearchBox from "../../components/searchBox.vue";
import {
  getSpotApiList,
  batchSynchSpotApi,
  quickSwap,
  getExchangeList,
  queryPair,
  userManagementExchangelist,
  getContractApiList,
  batchSynchContractApi,
  synchSingelContractApi,
  quickFlatPosition,
  findFundSpot,
  findContract,
  transfer,
  getProject,
  synchSingleSpotApi,
  queryProjectData,
  getAssetApiList,
  findFundAssets,
  batchSynchAssetApi,
  synchSingelAssetApi,
  findContractId,
} from "../../request/api";
export default {
  components: { DetailsData, SearchBox },
  watch: {
    currencyValue() {
      if (this.transformation == false) {
        let payload = {
          token: sessionStorage.getItem("token"),
          apiId: this.spotByApp.apiId,
          coin: this.currencyValue,
        };
        this.findFundSpot(payload);
      } else {
        let payload = {
          token: sessionStorage.getItem("token"),
          apiId: this.spotByApp.apiId,
          coin: this.currencyValue,
        };
        this.findFundAssets(payload);
      }
    },
    //小额币种显隐
    showSmallCoin() {
      sessionStorage.setItem("showSmallCoin", this.showSmallCoin);
      if (this.tableActive == 0) {
        let payload = {
          token: sessionStorage.getItem("token"),
          teamProjectId: this.project.teamProjectId,
          exchangeName: this.exchangeName,
          userId: this.userId,
          pageSize: 10,
          pageNo: 1,
        };
        this.getSpotApiList(payload);
      } else if (this.tableActive == 1) {
        let payload = {
          token: sessionStorage.getItem("token"),
          exchangeName: this.exchangeName,
          teamProjectId: this.project.teamProjectId,
          accountMode: this.exchangeList[this.contractActive].warehouse
            ? "isolated"
            : "cross",
          userId: this.userId,
          pageSize: 10,
          pageNo: 1,
        };
        this.getContractApiList(payload);
      } else {
        let payload = {
          token: sessionStorage.getItem("token"),
          apiId: this.apiId,
          pageSize: 10,
          pageNo: 1,
        };
        this.getAssetApiList(payload);
      }
    },
  },
  data() {
    return {
      detailsDelete: true,
      strategyType: [1, 2],
      options: [1, 2],
      Tab: ["币币", "U本位永续合约"],
      //合约交易所
      exchangeList: [],
      //币币交易所
      exchangeLimit: [],
      tableActive: 0, //top切换
      contractActive: 0, //合约
      // 表格数据
      tableData: [],
      // 合约数据
      contractTable: [],
      //合约
      contractSelectBtn: false, //全选
      // 搜索的数据
      searchTable: [],
      // 币币搜索框
      searchData: [
        {
          name: "账户",
          type: "input",
        },
        {
          name: "币种",
          type: "input",
        },
      ],
      // 合约搜索框
      contractSearch: [
        {
          name: "账户",
          type: "input",
        },
        {
          name: "币种",
          type: "input",
        },
      ],
      // 表头数据
      orderData: [
        {
          title: "账户",
          nav: "apiName",
          // width: 120,
        },
        {
          title: "币种",
          nav: "coinType",
          // width: 70,
        },
        {
          title: "可用",
          nav: "available",
          // width: 100,
        },
        {
          title: "冻结",
          nav: "lock",
          // width: 100,
        },
        {
          title: "折合投入币种估值",
          nav: "coinValue",
        },
        // {
        //   title: "折合【投入币种】成本",
        //   nav: "coinCost",
        // },
        // {
        //   title: "折合【投入币种】收益",
        //   nav: "coinIncome",
        // },
        {
          title: "上一次同步时间",
          nav: "updateTime",
        },
      ],
      // 具体样式
      rowStyleData: [
        {
          columnIndex: 4,
          style: [
            {
              state: "true4",
              style: "color:#E12525",
            },
            {
              state: "zero4",
              style: "color:#102859",
            },
            {
              state: "false4",
              style: "color:#26B34B",
            },
          ],
        },
      ],
      activeShow: false, //快速兑换
      startCoin: null, //初始投入币种
      selectRowList: [], // 币币选中数据
      contractRowList: [], // 合约选中数据
      listNumber: null, //总数据条数
      currentPage: 1, //当前页数

      transfer: false, //划转
      warehouse: null,
      userId: null, //操作员id
      spotByApp: null, //当前划转用户
      topOptions: [{ name: "币币账户" }],
      topValue: "USDT 币币账户",
      twoOptions: ["USDT 永续合约"],
      twoValue: "",
      inputOptions: null,
      currencyList: ["USDT"],
      currencyValue: "USDT",
      usdtAvailable: 0,
      contractData: null,
      inputStyle: false,
      tradePair: [], //项目支持的币种
      contractList: null,
      inputVerification: null,
      transformation: false, //转换
      exchangeName: null, //当前交易所
      news: true,
      batchShow: false,
      showSmallCoin: sessionStorage.getItem("showSmallCoin")
        ? JSON.parse(sessionStorage.getItem("showSmallCoin"))
        : true, //小额币种
    };
  },
  methods: {
    handleSizeChange() {
      console.log(2);
    },
    //翻页
    handleCurrentChange(e) {
      console.log(e);
      if (this.tableActive == 0) {
        let payload = {
          token: sessionStorage.getItem("token"),
          teamProjectId: this.project.teamProjectId,
          exchangeName: this.exchangeName,
          userId: this.userId,
          pageSize: 10,
          pageNo: e,
          ...this.searchTable,
        };
        this.getSpotApiList(payload);
      } else {
        let payload = {
          token: sessionStorage.getItem("token"),
          exchangeName: this.exchangeName,
          teamProjectId: this.project.teamProjectId,
          accountMode: this.exchangeList[this.contractActive].warehouse
            ? "isolated"
            : "cross",
          userId: this.userId,
          pageSize: 10,
          pageNo: e,
          ...this.searchTable,
        };
        this.getContractApiList(payload);
      }
    },
    //获取币币的选中数据
    selectRow(e) {
      console.log(e);
      this.selectRowList = e;
    },
    //币币合约按钮
    tableDataBtn(e) {
      this.tableActive = e;
      this.currentPage = 1;
      this.searchTable = [];

      // this.contractActive = 0;
      if (e == 0) {
        let payloadData = {
          token: sessionStorage.getItem("token"),
        };
        batchSynchSpotApi(payloadData).then((res) => {
          let payload = {
            token: sessionStorage.getItem("token"),
            teamProjectId: this.project.teamProjectId,
            userId: this.userId,
            exchangeName: this.exchangeName,
            pageSize: 10,
            pageNo: 1,
          };
          //页面数据
          this.getSpotApiList(payload);
        });
      } else if (e == 1) {
        let payloadData = {
          token: sessionStorage.getItem("token"),
        };
        batchSynchContractApi(payloadData).then((res) => {
          let payload = {
            token: sessionStorage.getItem("token"),
            exchangeName: this.exchangeName,
            teamProjectId: this.project.teamProjectId,
            accountMode: this.exchangeList[this.contractActive].warehouse
              ? "isolated"
              : "cross",
            userId: this.userId,
            pageSize: 10,
            pageNo: 1,
          };
          this.getContractApiList(payload);
        });
      } else {
        let payloadData = {
          token: sessionStorage.getItem("token"),
        };
        batchSynchAssetApi(payloadData).then((res) => {
          let payload = {
            token: sessionStorage.getItem("token"),
            apiId: this.apiId,
            pageSize: 10,
            pageNo: 1,
          };
          this.getAssetApiList(payload);
        });
      }
    },
    //v5资产账户
    getAssetApiList(payload) {
      payload.coinValue = this.showSmallCoin == true ? 10 : "";
      getAssetApiList(payload).then((res) => {
        if (res.status == 200) {
          console.log(res);
          let assetApiList = res.data.assetApiList;
          assetApiList.forEach((e) => {
            //如果不是项目支持的币种 不给按钮显示
            if (this.tradePair.indexOf(e.coinType) == -1) {
              e.news = true;
            }
          });
          this.contractTable = assetApiList;
        } else {
          this.contractTable = [];
        }
      });
    },

    typeSelest() {
      console.log(1111);
    },
    //查询按钮
    searchBtn(e) {
      
      if (this.tableActive == 0) {
        if (e[1] !== "" || e[2] !== this.$t('m.全部')) {
          this.searchTable = {
            apiName: e[0] !== "" ? e[0] : "",
            coinType: e[1] !== this.$t('m.全部') ? e[1] : "",
          };
        } else {
          this.searchTable = [];
        }
        let payload = {
          token: sessionStorage.getItem("token"),
          teamProjectId: this.project.teamProjectId,
          exchangeName: this.exchangeName,
          userId: this.userId,
          pageSize: 10,
          pageNo: 1,
          ...this.searchTable,
        };
        this.getSpotApiList(payload);
      } else if (this.tableActive == 1) {
        if (e[0] !== "" || e[1] !== "") {
          this.searchTable = {
            apiName: e[0],
            counterCoin: e[1],
          };
        } else {
          this.searchTable = [];
        }
        let payload = {
          token: sessionStorage.getItem("token"),
          exchangeName: this.exchangeName,
          teamProjectId: this.project.teamProjectId,
          accountMode: this.exchangeList[this.contractActive].warehouse
            ? "isolated"
            : "cross",
          userId: this.userId,
          ...this.searchTable,
        };
        this.getContractApiList(payload);
      } else {
        if (e[0] !== "" || e[1] !== "") {
          this.searchTable = {
            apiName: e[0],
            coinType: e[1],
          };
        } else {
          this.searchTable = [];
        }
        let payload = {
          token: sessionStorage.getItem("token"),
          apiId: this.apiId,
          pageSize: 10,
          pageNo: 1,
          ...this.searchTable,
        };
        this.getAssetApiList(payload);
      }

      this.currentPage = 1;
    },
    //重置按钮
    btnBack() {
      this.searchTable = [];
      if (this.tableActive == 0) {
        let payload = {
          token: sessionStorage.getItem("token"),
          teamProjectId: this.project.teamProjectId,
          exchangeName: this.exchangeName,
          userId: this.userId,
          pageSize: 10,
          pageNo: 1,
        };
        this.getSpotApiList(payload);
      } else if (this.tableActive == 1) {
        let payload = {
          token: sessionStorage.getItem("token"),
          exchangeName: this.exchangeName,
          teamProjectId: this.project.teamProjectId,
          accountMode: this.exchangeList[this.contractActive].warehouse
            ? "isolated"
            : "cross",
          userId: this.userId,
          pageSize: 10,
          pageNo: 1,
        };
        this.getContractApiList(payload);
      } else {
        let payload = {
          token: sessionStorage.getItem("token"),
          apiId: this.apiId,
          pageSize: 10,
          pageNo: 1,
        };
        this.getAssetApiList(payload);
      }
      this.currentPage = 1;
    },
    //快速兑换
    exchangeBtn() {
      if (!this.detailsOperationAuthority) {
        // this.$message.error("不是模板创建人，无权操作");
        this.$alert(
          this.$t('m.您没有权限使用该功能，敬请谅解！'), 
          this.$t('m.额，好像没有权限'), {
          confirmButtonText: this.$t('m.知道啦'),
        });
        return;
      }
      let list = this.selectRowList;
      if (list.length == 0) {
        this.$message.warning("请先选择持仓");
        return;
      } else if (list.length == 1 && list[0].coinType == "USDT") {
        this.$message.warning("不可兑换");
        return;
      }
      this.activeShow = true;
    },


    // 确定兑换
    async activeBtn() {

      console.log(this.exchangeList);
      let exchangeList = this.exchangeList;
      let list = this.selectRowList;
      let swapStr = [];
      let swapStrErr = [];

      for (let key of list) {
        let item = {};
        item.apiId = key.apiId + "";
        item.counterCoinAmount = key.available + "";
        item.userId = key.userId;
        item.swapCoin = key.coinType;
        for (let val of exchangeList) {
          if (key.exchangeName == val.exchangeName) {
            item.exchangeId = val.exchangeId + "";
            let params = {
              token: sessionStorage.getItem("token"),
              exchangeId: item.exchangeId + "",
            };
            let pairList = null;
            await queryPair(params).then((res) => {
              if (res.status == 200) {
                pairList = res.data.pairList;
              }
            });
            console.log("交易对", pairList);
            for (let pair of pairList) {
              if (
                key.investCoin == pair.baseCoin &&
                key.coinType == pair.counterCoin
              ) {
                item.pairId = pair.pairId + "";
                console.log("交易对1111", pair);
              }
            }
          }
        }
        if (item.pairId) {
          swapStr.push(item);
        } else {
          swapStrErr.push(item);
        }
      }
      if (swapStrErr.length > 0) {
        let coin = "";
        swapStrErr.forEach((e, index) => {
          if (index == 0) {
            coin = e.swapCoin;
          } else {
            coin = coin + "," + e.swapCoin;
          }
        });
        this.$message.error("平台不支持 " + coin + " 快速兑换");
      }
      if (swapStrErr.length == list.length) {
        this.activeShow = false;
        return;
      }

      console.log(swapStr);
      let payload = {
        token: sessionStorage.getItem("token"),
        swapStr: JSON.stringify(swapStr),
      };

      quickSwap(payload).then((res) => {
        if (res.status == 200) {
          this.$message.success("操作成功");
          console.log(res.data);
          let payload = {
            token: sessionStorage.getItem("token"),
            teamProjectId: this.project.teamProjectId,
            exchangeName: this.exchangeName,
            userId: this.userId,
            pageSize: 10,
            pageNo: 1,
            ...this.searchTable,
          };
          this.getSpotApiList(payload);
          this.currentPage = 1;
        }
      });
      this.activeShow = false;
    },

    handleClose(done) {
      done();
    },
    toNonExponential(num) {
      var m = num.toExponential().match(/\d(?:\.(\d*))?e([+-]\d+)/);
      return num.toFixed(Math.max(0, (m[1] || "").length - m[2]));
    },

    //获取币币页面数据
    getSpotApiList(payload) {
      payload.coinValue = this.showSmallCoin == true ? 10 : "";
      getSpotApiList(payload).then((res) => {
        console.log(res, "币币页面数据");
        if (res.status == 200) {
          if (res.data.totalNum !== 0) {
            let spotApiList = res.data.spotApiList;
            this.listNumber = res.data.totalNum;
            this.startCoin = res.data.spotApiList[0].investCoin;
            spotApiList.forEach((e) => {
              e.updateTime = this.timestampToTime(e.updateTime);
              //如果不是项目支持的币种 不给按钮显示
              if (this.tradePair.indexOf(e.coinType) == -1) {
                e.news = true;
              }
              if (e.coinType == e.investCoin) {
                console.log(e);
                this.apiId = e.apiId;
                e.coinValue = "--";
                e.coinCost = "--";
                e.coinIncome = "--";
              }
              e.coinType += e.states == "SWAPING" ? " (快速兑换中)" : "";
              e.lock = this.toNonExponential(e.lock);
              if (e.coinType == "BTC") {
                e.available = e.available
                  .toString()
                  .slice(0, e.available.toString().indexOf(".") + 7);
                e.lock = e.lock.slice(0, e.lock.indexOf(".") + 7);
              } else {
                e.available = e.available
                  .toString()
                  .slice(0, e.available.toString().indexOf(".") + 5);
                e.lock = e.lock.slice(0, e.lock.indexOf(".") + 5);
              }
              e.lock = e.lock == 0 ? 0 : e.lock;
              e.available = Number(e.available);
              e.state = [];
              //注释了 收益和成本
              e.state.push();
            });
            this.orderData.forEach((e) => {
              if (e.title.indexOf("投入币种") != -1) {
                e.title = e.title.replace(
                  /投入币种/,
                  spotApiList[0].investCoin
                );
              }
            });
            this.tableData = spotApiList;
          } else {
            this.startCoin = "--";
            this.tableData = [];
            this.listNumber = 0;
          }
        }
      });
    },
    //同步资产按钮
    batchSpotApiBtn() {
      let api = [];
      let coin = [];
      this.selectRowList.forEach((e) => {
        api.push(e.apiId);
        coin.push(e.coinType);
      });
      api = Array.from(new Set(api));

      if (api.length == 1 && coin.length == 1) {
        let payload = {
          token: sessionStorage.getItem("token"),
          apiId: api[0],
          coinType: coin,
        };
        this.synchSingleSpotApi(payload);
      } else {
        this.batchSynchSpotApi();
      }
    },
    //币币同步单个资产
    synchSingleSpotApi(payload) {
      synchSingleSpotApi(payload).then((e) => {
        if (res.status == 200) {
          this.$message.success("操作成功");
          let payload = {
            token: sessionStorage.getItem("token"),
            teamProjectId: this.project.teamProjectId,
            exchangeName: this.exchangeName,
            userId: this.userId,
            pageSize: 10,
            pageNo: 1,
            ...this.searchTable,
          };
          this.getSpotApiList(payload);
        }
      });
    },
    //同步币币全部资产
    batchSynchSpotApi() {
      let payload = {
        token: sessionStorage.getItem("token"),
      };
      batchSynchSpotApi(payload).then((res) => {
        if (res.status == 200) {
          if (this.batchShow == true) {
            if (this.tableActive == 0) {
              let payload = {
                token: sessionStorage.getItem("token"),
                teamProjectId: this.project.teamProjectId,
                exchangeName: this.exchangeName,
                userId: this.userId,
                pageSize: 10,
                pageNo: 1,
                ...this.searchTable,
              };
              this.getSpotApiList(payload);
            }
          } else {
            this.$message.success("操作成功");
            let payload = {
              token: sessionStorage.getItem("token"),
              teamProjectId: this.project.teamProjectId,
              exchangeName: this.exchangeName,
              userId: this.userId,
              pageSize: 10,
              pageNo: 1,
              ...this.searchTable,
            };
            this.getSpotApiList(payload);
          }
        }
      });
    },

    //获取交易所列表
    userManagementExchangelist(payload) {
      userManagementExchangelist(payload).then((res) => {
        if (res.status == 200) {
          console.log(res.data);
          this.exchangeLimit = res.data.exchangeLimit;
          this.exchangeList = res.data.exchangeLimit;
          this.exchangeList.forEach((e) => {
            if (e.exchangeName == "Huobi" || e.exchangeName == "Okex") {
              e.warehouse = true;
              this.warehouse = true;
            } else {
              e.warehouse = false;
              this.warehouse = false;
            }
          });
          this.exchangeName = this.exchangeList[0].exchangeName;
          this.warehouse = this.exchangeList[0].warehouse;
          if (this.exchangeName == "OkexV5") {
            this.Tab = ["币币(统一账户)", "U本位永续(统一账户)", "资金账户"];
          }
          let payload = {
            token: sessionStorage.getItem("token"),
            userId: this.userId,
            teamProjectId: this.project.teamProjectId,
            exchangeName: this.exchangeName,
            pageSize: 10,
            pageNo: 1,
          };

          //页面数据
          this.getSpotApiList(payload);
        }
      });
    },
    //日期换算
    timestampToTime(timestamp) {
      var date = new Date(timestamp); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
      var Y = date.getFullYear() + "-";
      var M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      var D =
        date.getDate() < 10
          ? "0" + date.getDate() + " "
          : +date.getDate() + " ";
      var h =
        date.getHours() < 10
          ? "0" + date.getHours() + ":"
          : date.getHours() + ":";
      var m =
        date.getMinutes() < 10
          ? "0" + date.getMinutes() + ":"
          : date.getMinutes() + ":";
      var s =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      return Y + M + D + h + m + s;
    },
    // 传递页面active
    setActive(v) {
      this.$emit("childFn", v);
    },

    //币币划转
    transferDetermineBtn(e) {
      this.transformation = true;
      this.transfer = true;
      this.spotByApp = e;
      this.currencyValue = e.coinType;
      console.log(this.spotByApp);
      this.exchangeList.forEach((e) => {
        if (e.exchangeName == this.spotByApp.exchangeName) {
          this.warehouse = e.warehouse;
        }
      });
      console.log(this.warehouse);
      if (this.exchangeName == "OkexV5") {
        this.topValue = "统一账户";
        this.topOptions = [{ name: "统一账户" }];
        this.twoValue = "资金账户";
        this.twoOptions = [{ name: "资金账户" }];
        this.currencyList = this.tradePair;
        this.transformation = false;

        setTimeout(() => {
          let payload = {
            token: sessionStorage.getItem("token"),
            apiId: this.spotByApp.apiId,
            coin: this.currencyValue,
          };
          this.findFundSpot(payload);
        }, 300);
      } else {
        if (this.exchangeName == "Huobi") {
          let payload = {
            token: sessionStorage.getItem("token"),
            teamProjectId: this.project.teamProjectId,
            exchangeName: this.exchangeName,
          };
          this.findContractId(payload);
        } else {
          this.twoValue = "USDT 合约全仓";
          this.twoOptions = [{ name: "USDT 合约全仓" }];
        }

        setTimeout(() => {
          let payload = {
            token: sessionStorage.getItem("token"),
            apiId: this.spotByApp.apiId,
            coin: "USDT",
          };
          this.findFundSpot(payload);
        }, 300);
      }
    },
    findFundAssets(payload) {
      findFundAssets(payload).then((res) => {
        if (res.status == 200) {
          console.log(res, 1111111111);
          this.usdtAvailable = res.data.available;
        }
      });
    },
    topValueChange(e) {
      console.log(e);
      if (!this.transformation) {
        let payload = {
          token: sessionStorage.getItem("token"),
          apiId: this.spotByApp.apiId,
          exchangeName: this.spotByApp.exchangeName,
          contractId: e.split(" ")[2],
        };
        this.findContract(payload);
      }
    },
    twoValueChange(e) {},

    //获取币币同步全部资产
    findContract(payload) {
      findContract(payload).then((res) => {
        if (res.status == 200) {
          if (res.data) {
            let data = res.data;
            if (Number(data.available) == 0) {
              data.available = 0;
            } else {
              data.available = Number(data.available)
                .toString()
                .slice(0, Number(data.available).toString().indexOf(".") + 5);
            }
            this.usdtAvailable = data.available;
            this.contractData = data;
          } else {
            this.usdtAvailable = 0;
          }
        }
      });
    },

    //输入框校验
    inputNumber(e) {
      console.log(this.inputOptions, this.usdtAvailable);
      if (Number(this.inputOptions) <= 0) {
        this.$message.error("划转不能小于0");
        e.target.style.border = "1px solid red";
        this.inputVerification = false;
      } else if (Number(this.inputOptions) > this.usdtAvailable) {
        this.$message.error("划转不能大于可用划转金额");
        e.target.style.border = "1px solid red";
        this.inputVerification = false;
      } else {
        e.target.style.border = "1px solid #DCDFE6";
        this.inputVerification = true;
      }
    },
    inputNumberFocus(e) {
      e.target.style.border = "1px solid #DCDFE6";
    },

    /////////////////////////////合约
    //创建策略
    templateBtn(e) {
      if (!this.detailsOperationAuthority) {
        // this.$message.error("不是模板创建人，无权操作");
        this.$alert(
          this.$t('m.您没有权限使用该功能，敬请谅解！'), 
          this.$t('m.额，好像没有权限'), {
          confirmButtonText: this.$t('m.知道啦'),
        });
        return;
      }
      console.log(this.exchangeList);
      this.exchangeList.forEach((item) => {
        if (item.exchangeName == e.exchangeName) {
          e.exchangeId = item.exchangeId;
        }
      });
      e.contract = true;
      let list = [e];
      sessionStorage.setItem("startAcc", JSON.stringify(list));
      this.$emit("childFn", "1-1");
    },
    //创建订单链
    orderBtn(e) {
      if (!this.detailsOperationAuthority) {
        // this.$message.error("不是模板创建人，无权操作");
        this.$alert(
          this.$t('m.您没有权限使用该功能，敬请谅解！'), 
          this.$t('m.额，好像没有权限'), {
          confirmButtonText: this.$t('m.知道啦'),
        });
        return;
      }
      console.log(this.exchangeList);
      this.exchangeList.forEach((item) => {
        if (item.exchangeName == e.exchangeName) {
          e.exchangeId = item.exchangeId;
        }
      });
      e.contract = true;
      let list = [e];
      sessionStorage.setItem("startAcc", JSON.stringify(list));
      this.$emit("childFn", "2-1");
    },

    //合约划转按钮
    transferBtn(e) {
      this.transformation = true;
      this.spotByApp = e;
      this.spotCoin = e.contractId;
      this.transfer = true;
      this.warehouse = this.exchangeList[this.contractActive].warehouse;

      let payload = {
        token: sessionStorage.getItem("token"),
        apiId: e.apiId,
        coin: "USDT",
      };
      this.findFundSpot(payload);
      this.currencyList = ["USDT"];
      let payloadData = {
        token: sessionStorage.getItem("token"),
        teamProjectId: this.project.teamProjectId,
        exchangeName: this.exchangeName,
      };
      this.findContractId(payloadData);
    },

    //获取合约可划转账户
    findFundSpot(payload) {
      findFundSpot(payload).then((res) => {
        console.log(res);
        let data = res.data;
        console.log(data);
        let available = data.available;
        if (Number(available) == 0) {
          available = 0;
        } else {
          available = available.slice(0, available.indexOf(".") + 5);
        }
        this.usdtAvailable = available;
      });
    },
    //划转
    transferDetermine() {
      if (Number(this.inputOptions) <= 0) {
        return this.$message.warning("划转不能小于0");
      } else if (Number(this.inputOptions) > this.usdtAvailable) {
        return this.$message.warning("划转不能大于可用划转金额");
      }
      let payload = {};

      if (this.transformation) {
        payload = {
          token: sessionStorage.getItem("token"),
          paramStr: {
            from: "spot",
            to: "linear-swap",
            currency: this.currencyValue,
            amount: this.inputOptions,
            "margin-account": this.warehouse
              ? this.twoValue.split(" ")[2]
              : this.twoValue.split(" ")[0],
          },
          apiId: this.spotByApp.apiId,
        };

        if (this.exchangeName == "OkexV5") {
          payload.paramStr = {
            ...payload.paramStr,
            "margin-account": this.currencyValue,
          };
        }
      } else {
        payload = {
          token: sessionStorage.getItem("token"),
          paramStr: {
            from: "linear-swap",
            to: "spot",
            currency: this.currencyValue,
            amount: this.inputOptions,
            "margin-account": this.warehouse
              ? this.topValue.split(" ")[2]
              : this.topValue.split(" ")[0],
          },
          apiId: this.spotByApp.apiId,
        };
        if (this.exchangeName == "OkexV5") {
          payload.paramStr = {
            ...payload.paramStr,
            "margin-account": this.currencyValue,
          };
        }
      }

      console.log(payload);
      transfer(payload).then((res) => {
        if (res.status == 200) {
          this.transfer = false;
          this.inputOptions = "";
          this.pageNo = 1;
          this.batchShow = true;

          this.$message.success("操作成功");
          this.batchSynchSpotApi();
          this.batchSynchContractApi();
          this.batchSynchAssetApi();
          setTimeout(() => {
            this.batchShow = false;
          }, 3000);
        } else {
          this.$message.error(res.msg);
        }
      });
    },

    //交易所select
    contractTableBtn(e) {
      this.contractActive = e;
      this.pageNo = 1;
      this.currentPage = 1;
      this.warehouse = this.exchangeList[e].warehouse;
      console.log(this.warehouse);
      this.exchangeName = this.exchangeList[e].exchangeName;
      console.log(e);
      if (this.exchangeName == "OkexV5") {
        this.Tab = ["币币(统一账户)", "U本位永续(统一账户)", "资金账户"];
      } else {
        this.Tab = ["币币", "U本位永续合约"];
      }
      this.tableActive = 0;
      if (this.tableActive == 0) {
        let payloadData = {
          token: sessionStorage.getItem("token"),
        };
        batchSynchSpotApi(payloadData).then((res) => {
          let payload = {
            token: sessionStorage.getItem("token"),
            teamProjectId: this.project.teamProjectId,
            userId: this.userId,
            exchangeName: this.exchangeName,
            pageSize: 10,
            pageNo: 1,
          };
          //页面数据

          this.getSpotApiList(payload);
        });
      } else {
        let payload = {
          token: sessionStorage.getItem("token"),
          exchangeName: this.exchangeName,
          teamProjectId: this.project.teamProjectId,
          accountMode: this.warehouse ? "isolated" : "cross",
          userId: this.userId,
          pageSize: 10,
          pageNo: 1,
        };
        this.getContractApiList(payload);
      }
    },
    //全选
    allContractSelect() {
      console.log(this.contractTable);
      console.log(this.contractSelectBtn);
      this.contractTable.forEach((e) => {
        e.select = this.contractSelectBtn;
        if (e.positions == null) {
          return;
        } else if (e.positions.length == 0) {
          return;
        } else {
          e.positions.forEach((item) => {
            item.select = this.contractSelectBtn;
          });
        }
      });
      if (this.contractSelectBtn) {
        this.selectRowList = this.contractTable;
      } else {
        this.selectRowList = [];
      }
      console.log(this.listNumber);
    },

    //外层的选择框
    FirstSelect(data) {
      console.log(data);
      if (data.select == false) {
        this.contractSelectBtn = false;
        if (data.positions == null) {
          return;
        } else if (data.positions.length == 0) {
          return;
        } else {
          data.positions.forEach((e) => {
            e.select = false;
          });
        }
      } else {
        let number = 0;
        for (let i = 0; i < this.contractTable.length; i++) {
          if (this.contractTable[i].select == true) {
            number++;
          }
        }
        if (number == this.contractTable.length) {
          this.contractSelectBtn = true;
        }
        if (data.positions == null) {
          return;
        } else if (data.positions.length == 0) {
          return;
        } else {
          data.positions.forEach((e) => {
            e.select = true;
          });
        }
      }
    },
    //里面的选择框
    TwoSelect(data) {
      let number = 0;
      data.positions.forEach((e) => {
        if (e.select == false) {
          this.contractSelectBtn = false;
          data.select = false;
        } else {
          number++;
          if (number == data.positions.length) {
            data.select = true;
            let numberAll = 0;
            for (let i = 0; i < this.contractTable.length; i++) {
              if (this.contractTable[i].select == true) {
                numberAll++;
              }
            }
            console.log(numberAll);
            if (numberAll == this.contractTable.length) {
              this.contractSelectBtn = true;
            }
          }
        }
      });
      console.log(data);
    },

    //获取合约页面信息
    getContractApiList(payload) {
      payload.coinValue = this.showSmallCoin == true ? 10 : "";
      getContractApiList(payload).then((res) => {
        if (res.status == 200) {
          console.log(res.data, "合约页面信息");
          let contractList = res.data.contractList;
          this.listNumber = res.data.totalNum;

          contractList.forEach((e) => {
            e.select = false;
            e.show = true;
            //如果不是项目支持的币种 不给按钮显示
            if (this.tradePair.indexOf(e.coinType) == -1) {
              e.news = true;
            }
            if (!e.contractId) {
              e.contractId = "USDT 合约全仓";
            } else {
              let number = 0;
              this.tradePair.forEach((item) => {
                if (item !== e.contractId.split("-")[0]) {
                  number++;
                } else {
                  e.state = true;
                }
              });
              if (number == this.tradePair.length) {
                e.state = false;
              }
            }
            if (Number(e.available) == 0) {
              e.available = 0;
            } else {
              e.available = Number(e.available)
                .toString()
                .slice(0, Number(e.available).toString().indexOf(".") + 5);
            }
            if (Number(e.equity) == 0) {
              e.equity = 0;
            } else {
              e.equity = Number(e.equity)
                .toString()
                .slice(0, Number(e.equity).toString().indexOf(".") + 5);
            }
            if (e.positions == null) {
              return;
            } else {
              if (JSON.parse(e.positions).length == 0) {
                e.positions = null;
              } else {
                let positions = JSON.parse(e.positions);
                console.log(positions);
                positions.forEach((item) => {
                  item.select = false;
                  item.volumeNum = item.volumeNum.toString().split(".")[0];
                  console.log(item.volumeNum);
                  item.availableNum = Number(item.availableNum)
                    .toString()
                    .split(".")[0];
                  item.costOpen = item.costOpen
                    .toString()
                    .slice(0, item.costOpen.toString().indexOf(".") + 5);
                  if (item.contractId.indexOf("SWAP") > -1) {
                    item.contractIdType = item.contractId.split("-SWAP")[0];
                  } else {
                    item.contractIdType = item.contractId;
                  }
                  console.log(item);
                  item.riskRate = item.riskRate * 100;
                  item.riskRate = item.riskRate
                    .toString()
                    .slice(0, item.riskRate.toString().indexOf(".") + 5);
                  if (item.liquidationPrice !== 0) {
                    item.liquidationPrice = item.liquidationPrice.slice(
                      0,
                      item.liquidationPrice.indexOf(".") + 3
                    );
                  }
                  if (item.investCoin !== "BTC") {
                    item.profit = item.profit.slice(
                      0,
                      item.profit.indexOf(".") + 5
                    );
                  } else {
                    item.profit = item.profit.slice(
                      0,
                      item.profit.indexOf(".") + 7
                    );
                  }
                  item.profitRate = (Number(item.profitRate) * 100).toString();
                  item.profitRate = item.profitRate.slice(
                    0,
                    item.profit.indexOf(".") + 3
                  );
                });
                e.positions = positions;
              }
            }
          });
          if (!this.transfer) {
            this.contractTable = contractList;
            this.warehouse = this.exchangeList[this.contractActive].warehouse;
            console.log(this.contractTable);
          } else {
            if (!this.transformation) {
              console.log(this.spotByApp);
              if (contractList.length == 0) {
                this.tradePair.forEach((e) => {
                  if (e !== this.spotByApp.investCoin) {
                    contractList.push({
                      contractId: e + "-" + this.spotByApp.investCoin,
                    });
                  }
                });
              }

              contractList.forEach((e) => {
                console.log(this.warehouse);
                if (this.spotByApp.contractId) {
                  if (e.contractId == this.spotByApp.contractId) {
                    if (this.warehouse) {
                      this.topValue = "USDT 永续合约 " + e.contractId;
                    } else {
                      this.topValue = e.contractId;
                    }
                  }
                } else {
                  if (this.warehouse) {
                    this.topValue = "USDT 永续合约 BTC-USDT";
                  } else {
                    this.topValue = "USDT 合约全仓";
                  }
                }

                if (this.warehouse) {
                  e.name = "USDT 永续合约 " + e.contractId;
                } else {
                  e.name = e.contractId;
                }
              });

              this.topOptions = contractList;
              this.contractTable = contractList;
              this.contractList = contractList;

              this.twoOptions = [{ name: "USDT 币币账户" }];
              this.twoValue = "USDT 币币账户";
              console.log(this.topValue);
            } else {
              console.log(this.tradePair, this.spotByApp);
              if (contractList.length == 0) {
                this.tradePair.forEach((e) => {
                  if (e !== this.spotByApp.investCoin) {
                    contractList.push({
                      contractId: e + "-" + this.spotByApp.investCoin,
                    });
                  }
                });
              }
              contractList.forEach((e) => {
                console.log(this.warehouse);
                if (this.warehouse) {
                  e.name = "USDT 永续合约 " + e.contractId;
                  if (e.contractId == this.spotByApp.contractId) {
                    this.twoValue = "USDT 永续合约 " + e.contractId;
                  }
                } else {
                  e.name = e.contractId;
                  if (e.contractId == this.spotByApp.contractId) {
                    this.twoValue = e.contractId;
                  }
                }
              });
              console.log(this.spotByApp);
              if (!this.spotByApp.contractId) {
                if (this.spotByApp.exchangeName == "Huobi") {
                  this.twoValue = "USDT 永续合约 " + e.contractId;
                } else {
                  this.twoValue = "USDT 合约全仓";
                }
              }
              this.topOptions = [{ name: "USDT 币币账户" }];
              this.topValue = "USDT 币币账户";

              console.log(contractList);
              this.twoOptions = contractList;
            }
          }
        }
      });
    },

    //同步资产按钮
    synchronization() {
      if (!this.detailsOperationAuthority) {
        // this.$message.error("不是模板创建人，无权操作");
        this.$alert(
          this.$t('m.您没有权限使用该功能，敬请谅解！'), 
          this.$t('m.额，好像没有权限'), {
          confirmButtonText: this.$t('m.知道啦'),
        });
        return;
      }
      let payload = {
        token: sessionStorage.getItem("token"),
        // api: "9634",
      };
      let number = [];
      let posititonsNumber = [];
      this.contractTable.forEach((e) => {
        if (e.select == true) {
          number.push(e);
          if (number.length > 1) {
            return this.batchSynchContractApi();
          }
        }
      });

      if (number.length !== 0) {
        payload.apiId = number[0].apiId;
        let contractNumber = 0;
        console.log(number);
        if (number[0].positions !== null) {
          number[0].positions.forEach((e) => {
            if (e.select == true) {
              contractNumber++;
            }
            if (contractNumber > 1) {
              return this.synchSingelContractApi(payload);
            }
          });
          if (contractNumber !== 0) {
            payload.contractId = number[0].positions[0].contractId;
            return this.synchSingelContractApi(payload);
          }
        } else {
          payload.contractId = number[0].contractId;
          return this.synchSingelContractApi(payload);
        }
      } else {
        this.contractTable.forEach((e) => {
          if (e.positions !== null) {
            e.positions.forEach((item) => {
              if (item.select == true) {
                posititonsNumber.push(item);
              }
              if (posititonsNumber.length > 1) {
                payload.apiId = e.apiId;
                return this.synchSingelContractApi(payload);
              }
            });
          }
        });
        if (posititonsNumber.length !== 0) {
          payload.apiId = e.apiId;
          payload.contractId = posititonsNumber[0].contractId;
          return this.synchSingelContractApi(payload);
        } else {
          return this.batchSynchContractApi();
        }
      }
    },

    // v5同步资产按钮
    synchronizationV5() {
      if (!this.detailsOperationAuthority) {
        // this.$message.error("不是模板创建人，无权操作");
        this.$alert(
          this.$t('m.您没有权限使用该功能，敬请谅解！'), 
          this.$t('m.额，好像没有权限'), {
          confirmButtonText: this.$t('m.知道啦'),
        });
        return;
      }
      let payload = {
        token: sessionStorage.getItem("token"),
      };
      let number = [];
      this.contractTable.forEach((e) => {
        if (e.select == true) {
          number.push(e);
        }
      });
      console.log(number);
      if (number.length == 0) {
        return this.batchSynchAssetApi();
      } else if (number.length > 1) {
        return this.batchSynchAssetApi();
      } else {
        payload.contractId = number[0].coinType;
        payload.apiId = this.apiId;
        this.synchSingelAssetApi(payload);
      }
    },
    //同步v5资产
    batchSynchAssetApi() {
      let payload = {
        token: sessionStorage.getItem("token"),
      };
      batchSynchAssetApi(payload).then((res) => {
        if (res.status == 200) {
          console.log(this.batchShow);
          if (this.batchShow == true) {
            if (this.tableActive == 2) {
              let payload = {
                token: sessionStorage.getItem("token"),
                apiId: this.apiId,
                pageSize: 10,
                pageNo: 1,
              };
              this.getAssetApiList(payload);
              this.contractSelectBtn = false;
            }
          } else {
            this.$message.success("操作成功");
            let payload = {
              token: sessionStorage.getItem("token"),
              apiId: this.apiId,
              pageSize: 10,
              pageNo: 1,
            };
            this.getAssetApiList(payload);
            this.contractSelectBtn = false;
          }
        }
      });
    },
    //同步单个v5资产
    synchSingelAssetApi(payload) {
      synchSingelAssetApi(payload).then((res) => {
        if (res.status == 200) {
          this.$message.success("操作成功");
          let payload = {
            token: sessionStorage.getItem("token"),
            apiId: this.apiId,
            pageSize: 10,
            pageNo: 1,
          };
          this.getAssetApiList(payload);
          this.contractSelectBtn = false;
        }
      });
    },

    //合约同步资产
    batchSynchContractApi() {
      let payload = {
        token: sessionStorage.getItem("token"),
      };
      batchSynchContractApi(payload).then((res) => {
        if (res.status == 200) {
          console.log(this.warehouse);
          if (this.batchShow == true) {
            if (this.tableActive == 1) {
              let payload = {
                token: sessionStorage.getItem("token"),
                exchangeName: this.exchangeName,
                teamProjectId: this.project.teamProjectId,
                accountMode: this.warehouse ? "isolated" : "cross",
                userId: this.userId,
                pageSize: 10,
                pageNo: 1,
              };
              this.getContractApiList(payload);
            }
          } else {
            this.$message.success("操作成功");
            let payload = {
              token: sessionStorage.getItem("token"),
              exchangeName: this.exchangeName,
              teamProjectId: this.project.teamProjectId,
              accountMode: this.warehouse ? "isolated" : "cross",
              userId: this.userId,
              pageSize: 10,
              pageNo: 1,
            };
            this.getContractApiList(payload);
          }
        }
      });
    },
    //合约同步单个资产
    synchSingelContractApi(payload) {
      synchSingelContractApi(payload).then((res) => {
        if (res.status == 200) {
          this.$message.success("操作成功");
          let payload = {
            token: sessionStorage.getItem("token"),
            exchangeName: this.exchangeName,
            teamProjectId: this.project.teamProjectId,
            accountMode: this.warehouse ? "isolated" : "cross",
            userId: this.userId,
            pageSize: 10,
            pageNo: 1,
          };
          this.getContractApiList(payload);
        }
      });
    },
    //合约快速平仓
    quickFlatPosition() {
      if (!this.detailsOperationAuthority) {
        // this.$message.error("不是模板创建人，无权操作");
        this.$alert(
          this.$t('m.您没有权限使用该功能，敬请谅解！'), 
          this.$t('m.额，好像没有权限'), {
          confirmButtonText: this.$t('m.知道啦'),
        });
        return;
      }
      let number = 0;
      this.contractTable.forEach((e) => {
        if (e.select == true) {
          number++;
        }
        if (e.positions !== null) {
          e.positions.forEach((item) => {
            if (item.select == true) {
              number++;
            }
          });
        }
      });
      if (number !== 0) {
        let flatPositionStr = [];
        this.contractTable.forEach((e) => {
          if (e.positions !== null) {
            e.positions.forEach((item) => {
              console.log(e);
              if (item.select == true) {
                flatPositionStr.push({
                  apiId: e.apiId,
                  exchangeName: e.exchangeName,
                  investCoin: item.counterCoin,
                  contractId: item.contractId,
                  direction: item.direction,
                  userId: e.userId,
                });
              }
            });
          }
        });
        let payload = {
          token: sessionStorage.getItem("token"),
          flatPositionStr: flatPositionStr,
        };
        quickFlatPosition(payload).then((res) => {
          if (res.status == 200) {
            let payloadData = {
              token: sessionStorage.getItem("token"),
            };
            batchSynchContractApi(payloadData).then((res) => {
              if (res.status == 200) {
                this.contractSelectBtn = false;
                this.$message.success("操作成功");
                let payload = {
                  token: sessionStorage.getItem("token"),
                  exchangeName: this.exchangeName,
                  teamProjectId: this.project.teamProjectId,
                  accountMode: this.exchangeList[this.contractActive].warehouse
                    ? "isolated"
                    : "cross",
                  userId: this.userId,
                  pageSize: 10,
                  pageNo: this.pageNo,
                };
                this.getContractApiList(payload);
              }
            });
          } else {
            this.$message.error("未选中可平的持仓");
          }
        });
      } else {
        this.$message.warning("请选择实例");
      }
    },
    //获取项目可用币种
    getProject() {
      let payload = {
        teamProjectId: this.project.teamProjectId,
        token: sessionStorage.getItem("token"),
      };
      getProject(payload).then((res) => {
        console.log("项目信息", res.data.project);
        let tradePair = JSON.parse(res.data.project.tradePair);
        this.tradePair = tradePair;
      });
    },
    //转换按钮
    transformationBtn() {
      this.transformation = !this.transformation;
      if (this.exchangeName == "OkexV5") {
        if (this.transformation == false) {
          this.topValue = "统一账户";
          this.topOptions = [{ name: "统一账户" }];
          this.twoValue = "资金账户";
          this.twoOptions = [{ name: "资金账户" }];
          this.currencyList = this.tradePair;

          setTimeout(() => {
            let payload = {
              token: sessionStorage.getItem("token"),
              apiId: this.spotByApp.apiId,
              coin: this.currencyValue,
            };
            this.findFundSpot(payload);
          }, 300);
        } else {
          this.twoValue = "统一账户";
          this.twoOptions = [{ name: "统一账户" }];
          this.topValue = "资金账户";
          this.topOptions = [{ name: "资金账户" }];
          let payload = {
            token: sessionStorage.getItem("token"),
            apiId: this.spotByApp.apiId,
            coin: this.currencyValue,
          };
          this.findFundAssets(payload);
        }
      } else {
        if (this.exchangeName == "Huobi") {
          let payload = {
            token: sessionStorage.getItem("token"),
            teamProjectId: this.project.teamProjectId,
            exchangeName: this.exchangeName,
          };
          this.findContractId(payload);
        } else {
          let payload = {
            token: sessionStorage.getItem("token"),
            teamProjectId: this.project.teamProjectId,
            accountMode: this.warehouse ? "isolated" : "cross",
            exchangeName: this.spotByApp.exchangeName,
            apiName: this.spotByApp.apiName,
          };
          this.getContractApiList(payload);
        }
        setTimeout(() => {
          if (!this.transformation) {
            let payload = {
              token: sessionStorage.getItem("token"),
              apiId: this.spotByApp.apiId,
              exchangeName: this.spotByApp.exchangeName,
            };

            console.log(this.topValue);
            if (this.spotByApp.exchangeName == "Huobi") {
              payload.contractId = this.topValue.split(" ")[2];
            } else {
              payload.contractId = this.topValue.split(" ")[0];
            }
            console.log(payload);
            this.findContract(payload);
          } else {
            let payload = {
              token: sessionStorage.getItem("token"),
              apiId: this.spotByApp.apiId,
              coin: "USDT",
            };
            this.findFundSpot(payload);
          }
        }, 300);
      }
    },
    //获取项目的交易类型
    queryProjectData() {
      let payload = {
        token: sessionStorage.getItem("token"),
        teamProjectId: this.project.teamProjectId,
      };
      queryProjectData(payload).then((res) => {
        console.log(res);
        let spotOrContract = JSON.parse(res.data.resultMap.spotOrContract);
        if (spotOrContract.length == 1) {
          this.news = true;
        } else {
          this.news = false;
        }
      });
    },
    //获取可用币对合约
    findContractId(payload) {
      findContractId(payload).then((res) => {
        let data = res.data;
        let contractIdList = [];
        data.forEach((e) => {
          if (this.warehouse) {
            contractIdList.push({ name: "USDT 永续合约 " + e.contractId });
          } else {
            contractIdList.push({ name: "USDT 合约全仓" });
          }
        });
        let value1 = this.twoValue;
        let value2 = this.topValue;
        console.log(this.transformation);
        if (this.transformation) {
          this.twoOptions = contractIdList;
          console.log(this.spotCoin);
          if (this.spotCoin) {
            contractIdList.forEach((e) => {
              if (e.name.indexOf(this.spotCoin) > -1) {
                this.twoValue = e.name;
                this.spotCoin = null;
              }
            });
          } else {
            if (value2 == "USDT 币币账户" || value2 == "") {
              this.twoValue = contractIdList[0].name;
            } else {
              contractIdList.forEach((e) => {
                if (e.name == value2) {
                  this.twoValue = e.name;
                }
              });
            }
          }
          this.topOptions = [{ name: "USDT 币币账户" }];
          this.topValue = "USDT 币币账户";
        } else {
          this.topOptions = contractIdList;
          contractIdList.forEach((e) => {
            if (e.name == value1) {
              this.topValue = e.name;
            }
          });
          // this.spotByApp.show = false;
          this.twoOptions = [{ name: "USDT 币币账户" }];
          this.twoValue = "USDT 币币账户";
        }
      });
    },
  },
  created() {},
  mounted() {
    // this.getExchangeList();
    let active = sessionStorage.getItem("projectActive");
    if (active == 3) {
      let project = JSON.parse(sessionStorage.getItem("project"));
      this.project = project;
      if (this.$route.query.userId) {
        this.userId = this.$route.query.userId;
      } else {
        this.userId = null;
      }
      this.queryProjectData();

      let payloadData = {
        token: sessionStorage.getItem("token"),
        teamProjectId: this.project.teamProjectId,
      };
      //交易所
      this.userManagementExchangelist(payloadData);
      let detailsOperationAuthority = sessionStorage.getItem(
        "detailsOperationAuthority"
      );
      this.detailsOperationAuthority = JSON.parse(detailsOperationAuthority);
      this.getProject();
    }
  },
};
</script>

<style scoped lang='scss'>
.box {
  padding: 20px 20px;
  background: #ffffff;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  min-width: 1165px;
  height: calc(100% - 40px);
  overflow-x: hidden;
  .nav {
    .contractSelect {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #e6e9ee;
      margin-bottom: 20px;
      .contractData {
        display: flex;
        height: 40px;
        font-size: 16px;
        align-items: center;
        color: #55698a;
        .contractDataItem {
          height: 100%;
          line-height: 40px;
          padding: 0 20px;
          cursor: pointer;
          color: #55698a;
          border: 1px solid #e6e9ee;
          border-bottom: 0;
          margin-right: 20px;
        }
        .bcgStyle {
          background-color: #2174ff;
          color: #fff;
          border-radius: 3px 3px 0px 0px;
        }
      }
      .contractShow {
        display: flex;

        .brdTipsStyle {
          border-bottom: 1px dashed #333;
          color: #55698a;
          margin-right: 10px;
        }
      }
    }
    .topData {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #e6e9ee;
      height: 40px;
      font-size: 16px;
      align-items: center;
      margin-bottom: 20px;
      color: #55698a;
      .topDataList {
        display: flex;
        .topDataItem {
          height: 100%;
          line-height: 40px;
          padding: 0 20px;
          cursor: pointer;
          color: #55698a;
        }
        .brdStyle {
          border-bottom: 2px solid #2174ff;
          color: #2174ff;
        }
      }
    }
    .title {
      display: flex;
      color: #ffffff;
      justify-content: space-between;
      align-items: center;
      background-color: #32333d;
      font-size: 12px;
      padding: 0 30px;
      .topColumn {
        flex: 1;
        display: flex;
        height: 36px;
        align-items: center;
        justify-content: space-between;
        color: #ffffff;
        /deep/.el-input__inner {
          background-color: #1c1d28 !important;
          border: 1px solid #1c1d28 !important;
        }
        .one {
          display: flex;
          align-items: center;
          margin-right: 30px;
          span {
            white-space: nowrap;
          }
        }
        .btnData {
          display: flex;
          height: 100%;
          span {
            width: 60px;
            margin: 5px 0;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            border-radius: 2px;
          }
        }
      }
      .columnRight {
        display: flex;
        span {
          margin-left: 20px;
          display: flex;
          align-items: center;
          cursor: pointer;
          img {
            margin-right: 3px;
          }
        }
      }
    }
  }
  .contract {
    display: flex;
    flex-direction: column;
    background: #ffffff;

    .title {
      display: flex;
      color: #ffffff;
      justify-content: space-between;
      align-items: center;
      background-color: #32333d;
      font-size: 12px;
      padding: 0 30px;
      .topColumn {
        flex: 1;
        display: flex;
        height: 36px;
        align-items: center;
        justify-content: space-between;
        color: #ffffff;
        /deep/.el-input__inner {
          background-color: #1c1d28 !important;
          border: 1px solid #1c1d28 !important;
        }
        .one {
          display: flex;
          align-items: center;
          margin-right: 30px;
          span {
            white-space: nowrap;
          }
        }
        .btnData {
          display: flex;
          height: 100%;
          span {
            width: 60px;
            margin: 5px 0;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            border-radius: 2px;
          }
        }
      }
      .columnRight {
        display: flex;
        span {
          margin-left: 20px;
          display: flex;
          align-items: center;
          cursor: pointer;
          img {
            margin-right: 3px;
          }
        }
      }
    }
    .tableStyle {
      display: flex;
      flex-direction: column;
      border: 1px solid #e6e9ee;
      padding-bottom: 20px;
      .tableTitle {
        display: flex;
        padding: 5px 30px;
        background: #f6f9fd;
        color: #54698a;
        div {
          flex: 1;
        }
      }
      .tableNav {
        display: flex;
        flex-direction: column;
        padding: 0 10px;
        .tableItem {
          display: flex;
          flex-direction: column;
          .tableFirst {
            display: flex;
            padding: 20px;
            color: #102859;
            div {
              flex: 1;
            }
            .contractBtn {
              display: flex;
              span {
                cursor: pointer;
                margin-left: 10px;
                color: #2174ff;
                border: 1px solid rgba(33, 116, 255, 0.3);
                padding: 0 5px;
              }
            }
          }
          .tableTwo {
            display: flex;
            flex-direction: column;
            background: rgba(230, 233, 238, 0.1);
            border-radius: 8px;
            border: 1px solid #e6e9ee;
            padding: 20px;
            .tableTwoTitle {
              display: flex;
              color: #54698a;
              span {
                flex: 1;
              }
            }
            .tableTwoNav {
              display: flex;
              margin-top: 20px;
              color: #102859;
              span {
                flex: 1;
              }
            }
          }
        }
      }
    }
    .noTableStyle {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 50px;
      color: #909399;
      border: 1px solid #e6e9ee;
      border-top: 0;
    }
  }
  .paginStyle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #55698a;
    padding: 20px 100px 20px;
    .paginCenter {
      display: flex;
      align-items: center;
    }
  }
}
.inputStyleItem {
  border: 1px solid red;
  border-radius: 5px;
}
/deep/.el-dialog__body {
  padding: 0 30px;
  border-top: 1px solid #f0f0f0;
  border-bottom: 1px solid #f0f0f0;

  .dialog-nav {
    color: #55698a;
    padding: 20px 0 30px;
    font-size: 12px;
    .dialog-box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .dialog-img {
        width: 36px;
        height: 36px;
        cursor: pointer;
        margin: 0 20px 20px 0;
      }
      .dialog-top {
        font-size: 16px;
        margin-bottom: 20px;
        color: #102859;
      }
    }
    .dialog-currency {
      display: flex;
      flex-direction: column;
      font-size: 16px;
      span {
        margin-bottom: 10px;
      }
    }
  }
}
.dialog-footer {
  display: flex;
  justify-content: flex-end;
  div {
    width: 72px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    cursor: pointer;
  }
  .footerBack {
    border: 1px solid #c2d9ff;
    color: #2174ff;
  }
  .footerBtn {
    color: #ffffff;
    background: #2174ff;
    border: 1px solid #c2d9ff;
    margin-left: 12px;
  }
}
.dialog-nav {
  display: flex;
  flex-direction: column;
  .dialog-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
}
/deep/.cell {
  padding: 0 !important;
}
</style>