<template>
  <!-- 创建策略/模板 -->
  <div class="createPolicyTemplate">

    <el-container>
      <el-main>
        <div class="content">
          <div class="content-tamplate">
            <div class="content-tamplate-top">

              <div class="top"> 

                  <div class="warp">
                      <span class="title">
                        {{$t('m.模版标签')}}：</span>
                      <el-input v-model="labelData.label" class="input-with-select" maxlength="6">
                        <i slot="suffix">
                          <img
                            class="icon-suiji"
                            src="../../assets/icon/suiji.png"
                            alt=""
                            @click="queryStrategyTemplateLabel()"/>
                        </i>
                    </el-input>
                  </div>

                  <div class="warp">
                    <span class="title">
                      {{$t('m.策略')}}：</span>
                    <el-select v-model="strategyId" :placeholder="$t('m.请选择')" @change="selectStrategy">
                      <el-option
                        v-for="item in strategyList"
                        :key="item.strategyId"
                        :label="$t('m.'+item.strategyName)"
                        :value="item.strategyId">
                      </el-option>
                    </el-select>
                  </div>

                  <div class="warp">
                    <span class="title">
                      {{$t('m.交易所')}}：</span>
                    <el-select v-model="exchangeId" :placeholder="$t('m.请选择')" @change="selectExchange" :disabled="startAcc!=null">
                      <el-option
                        v-for="item in exchangeList"
                        :key="item.exchangeId"
                        :label="item.exchangeName"
                        :value="item.exchangeId">
                      </el-option>
                    </el-select>
                  </div>
              </div>

              <div class="bottom">
                <div class="warp" v-if="modeIsShow">
                  <span class="title">
                    {{$t('m.选择模式')}}：</span>
                  <!-- 现货 -->
                  <div class="pattern" v-if="!contractTypeShow">
                      <span :class="{'on':modeSelection}" @click="selectModeSelection(true)">
                        {{$t('m.自定义模式')}}</span>
                      <span :class="{'on':!modeSelection}" @click="selectModeSelection(false)">
                        {{$t('m.多币对模式')}}</span>
                  </div>

                  <!-- 合约 -->
                  <div class="pattern" v-else>
                      <span :class="{'contrOn':contractType==1}" @click="contractModeSelect(1)">
                        {{$t('m.做多')}}</span>
                      <span :class="{'contrOn':contractType==0}" @click="contractModeSelect(0)">
                        {{$t('m.做空')}}</span>
                      <span :class="{'contrOn':contractType==2}" @click="contractModeSelect(2)">
                        {{$t('m.双向')}}</span>
                  </div>
                </div>
                
                <div class="warp" v-if="strategicDirectionIsShow&&modeSelection">
                    <span class="title">
                      {{$t('m.策略方向')}}：</span>
                    <div class="pattern">
                        <span :class="{'on':!strategicDirection}" @click="selectStrategicDirection(false)">
                          {{$t('m.赚本币（看多）')}}</span>
                        <span :class="{'on':strategicDirection}" @click="selectStrategicDirection(true)">
                          {{$t('m.赚交易币（看空）')}}</span>
                    </div>
                </div>

                <div class="warp">
                  <span class="title">
                    {{$t('m.选择交易对')}}：</span>
                  <el-select
                    v-model="tagList"
                    value-key="name"
                    :placeholder="$t('m.请选择')"
                    multiple
                    style="width: 100%"
                    @click.native="pairShow()"
                    :multiple-limit="tagNum"
                    :closable='false'
                    @remove-tag='removeTag'>
                  </el-select>
                </div>

                <div class="warp" v-if="contractTypeShow">
                  <span class="title">
                    {{$t('m.杠杆倍数')}}：</span>
                  <el-select v-model="lever" :placeholder="$t('m.请选择')">
                    <el-option
                      v-for="item in leverList"
                      :key="item"
                      :label="item"
                      :value="item">
                    </el-option>
                  </el-select>
                </div>
              </div>
            </div>


            <div class="content-tamplate-main">
              <div class="left-warp">
                <!-- 参数 -->
                <div class="parameter">
                  <p class="title">
                    {{$t('m.参数配置')}}</p>
                  <div class="parameter-box-warp">

                    <!-- 其他策略  modeSelection -->
                    <template v-if="strategyId!='WL20210624182847743' 
                                    &&strategyId!='WL20210512112808681'
                                    &&strategyId!='WL202X0512112808681'
                                    &&strategyId!='WL20190402171936974x'
                                    &&strategyId!='WL202007311426363425x' 
                                    &&strategyId!= 'WL202007311426363424x'">
                      <template v-for="(item, idx) in reserves">

                        <template v-if="item.name!='价格上限'&&item.name!='价格下限' ">
                          <div :key="'reserves1'+idx"
                            class="parameter-box" v-if="item.visiable=='1'||strategyId=='CL20181115155643599x'">
                              <div class="left">
                                  <span class="reserves-name">
                                    <span>{{$t('m.'+item.name)}}</span>
                                    <img src="../../assets/icon/jieshi.png" alt="" 
                                      @click="reservesTips(item.comment)" 
                                      v-if="item.name!='单格投入' && strategyId=='CL20181115155003478x'">
                                    <span style="margin-left:6px" 
                                      v-if="item.name=='单格投入' && strategyId=='CL20181115155003478x'"></span>
                                  </span>

                                  <!-- 不计算范围 -->
                                  <span v-if="(item.valueMin=='' && item.valueMax=='') 
                                              || (item.valueMin=='0' && item.valueMax=='0')">{{
                                    $t('m.范围') +
                                    " " +
                                    (item.valueMin &&
                                    (item.valueMin != 0 || item.valueMax != 0)
                                      ? item.valueMin
                                      : item.start) +
                                    "~" +
                                    (item.valueMax &&
                                    (item.valueMin != 0 || item.valueMax != 0)
                                      ? item.valueMax
                                      : item.end)
                                  }}</span>

                                  <!-- 计算范围 -->
                                  <span  v-if="item.valueMin!='' 
                                                && item.valueMax!='' 
                                                && item.valueMin!='0' && item.valueMax!='0'">{{
                                    $t('m.范围') +
                                      calculation(
                                        currency,
                                        pairMap,
                                        item.valueMin &&
                                          (item.valueMin != '' || item.valueMax != '')
                                          ? item.valueMin
                                          : item.start
                                      ) +
                                      "~" +
                                      calculation(
                                        currency,
                                        pairMap,
                                        item.valueMax &&
                                          (item.valueMin != 0 || item.valueMax != 0)
                                          ? item.valueMax
                                          : item.end
                                      )
                                  }}</span>                                    

                              </div>
                              <div class="right">
                                  <el-input type='number' :placeholder="$t('m.请输入内容')" v-model="item.value" @blur="parameterVerification($event,item)">
                                    <i class="iconStyle" slot="suffix" v-if="(item.valueMin != 0 || item.valueMax != 0)">{{currency}}</i>
                                    <i class="iconStyle" slot="suffix" v-if="item.valueType=='percent'">%</i>
                                  </el-input>
                              </div>
                          </div>
                        </template>

                        <template v-if="modeSelection&&(item.name=='价格上限'||item.name=='价格下限')">
                          <div :key="'reserves1'+idx" class="parameter-box">
                              <div class="left">
                                  <span class="reserves-name">
                                    <span>{{$t('m.'+item.name)}}</span>
                                    <img src="../../assets/icon/jieshi.png" alt="" @click="reservesTips(item.comment)">
                                  </span>
                              </div>
                              <div class="right">
                                  <el-input  type='number' :placeholder="$t('m.请输入内容')" v-model="item.value" @blur="parameterVerification($event,item)">
                                    <i class="iconStyle" slot="suffix">
                                      {{$t('m.选填')}}</i>
                                  </el-input>
                              </div>
                          </div>
                        </template>
                      </template>
                    </template>

                    <!-- 恒量定投 -->
                    <template v-if="strategyId=='WL20190402171936974x'">
                      <template v-for="(item,idx) in reserves">
                        <!-- 定投周期 -->
                        <div :key="'reserves2'+idx" class="parameter-box" v-if="item.name=='定投周期'">
                          <div class="left">
                            <span class="reserves-name">
                              <span>{{$t('m.'+item.name)}}</span>
                              <img src="../../assets/icon/jieshi.png" alt="">
                            </span>
                          </div>

                          <div class="right">
                            <el-cascader
                              v-model="fixedTime"
                              :options="times"
                              @change="handleChange"></el-cascader>
                          </div>
                        </div>

                        <!-- 单笔金额 -->
                        <div :key="'reserves2'+idx" class="parameter-box" v-if="item.name=='单笔金额'">
                          <div class="left">
                            <span class="reserves-name">
                              <span>{{$t('m.'+item.name)}}</span>
                              <img src="../../assets/icon/jieshi.png" alt="" @click="reservesTips(item.comment)">
                            </span>
                            <!-- 不计算范围 -->
                            <span v-if="(item.valueMin=='' && item.valueMax=='') ||(item.valueMin=='0' && item.valueMax=='0')">{{
                              $t('m.范围') +
                              " " +
                              (item.valueMin &&
                              (item.valueMin != 0 || item.valueMax != 0)
                                ? item.valueMin
                                : item.start) +
                              "~" +
                              (item.valueMax &&
                              (item.valueMin != 0 || item.valueMax != 0)
                                ? item.valueMax
                                : item.end)
                            }}</span>

                            <!-- 计算范围 -->
                            <span  v-if="item.valueMin!='' && item.valueMax!='' &&(item.valueMin!='0' || item.valueMax!='0')">{{
                              $t('m.范围') +
                                calculation(
                                  currency,
                                  pairMap,
                                  item.valueMin &&
                                    (item.valueMin != '' || item.valueMax != '')
                                    ? item.valueMin
                                    : item.start
                                ) +
                                "~" +
                                calculation(
                                  currency,
                                  pairMap,
                                  item.valueMax &&
                                    (item.valueMin != 0 || item.valueMax != 0)
                                    ? item.valueMax
                                    : item.end
                                )
                            }}</span> 
                          </div>
                          <div class="right">
                            <el-input  type='number' :placeholder="$t('m.请输入内容')" v-model="item.value" @blur="parameterVerification($event,item)">
                              <i class="iconStyle" slot="suffix">{{currency}}</i>
                            </el-input>
                          </div>
                        </div>
                      </template>
                    </template>

                    <!-- 海风马丁  WL20210512112808681 -->
                    <template v-if="strategyId=='WL20210512112808681'||strategyId=='WL202X0512112808681'">
                      <template v-for="(item,idx) in reserves">
                        <!-- 补仓金额模式 -->
                        <template>
                          <div :key="'reserves3'+idx" class="parameter-box" 
                          v-if="item.visiable=='1'&&item.name == '补仓金额模式'">
                            <div class="special-box">
                              <span class="special-box-title">{{$t('m.'+item.name)}}</span>
                              <div class="special-box-cont">
                                <span :class="{'on':replenishment}" @click="replenishment=true">
                                  {{$t('m.倍数补仓')}}</span>
                                <span :class="{'on':!replenishment}" @click="replenishment=false">
                                  {{$t('m.增量补仓')}}</span>
                              </div>
                            </div>
                          </div>

                          <div :key="'reserves3'+idx"
                          class="parameter-box" v-if="(replenishment
                          &&(item.name == '补仓金额倍数' 
                          || item.name == '倍投起始单' 
                          || item.name == '最大做单数')) 
                          || (!replenishment &&(item.name == '补仓金额增量' || item.name == '最大做单数'))">
                            <div class="left">
                              <span class="reserves-name">
                                <span>{{$t('m.'+item.name)}}</span>
                                <img src="../../assets/icon/jieshi.png" alt="" @click="reservesTips(item.comment)">
                              </span>
                              <!-- 不计算范围 -->
                              <span v-if="(item.valueMin=='' && item.valueMax=='') ||(item.valueMin=='0' && item.valueMax=='0')">{{
                                $t('m.范围') +
                                " " +
                                (item.valueMin &&
                                (item.valueMin != 0 || item.valueMax != 0)
                                  ? item.valueMin
                                  : item.start) +
                                "~" +
                                (item.valueMax &&
                                (item.valueMin != 0 || item.valueMax != 0)
                                  ? item.valueMax
                                  : item.end)
                              }}</span>

                              <!-- 计算范围 -->
                              <span  v-if="item.valueMin!='' && item.valueMax!='' &&(item.valueMin!='0' || item.valueMax!='0')">{{
                                $t('m.范围') +
                                  calculation(
                                    currency,
                                    pairMap,
                                    item.valueMin &&
                                      (item.valueMin != '' || item.valueMax != '')
                                      ? item.valueMin
                                      : item.start
                                  ) +
                                  "~" +
                                  calculation(
                                    currency,
                                    pairMap,
                                    item.valueMax &&
                                      (item.valueMin != 0 || item.valueMax != 0)
                                      ? item.valueMax
                                      : item.end
                                  )
                              }}</span> 
                            </div>
                            
                            <div class="right">
                                <el-input  type='number' :placeholder="$t('m.请输入内容')" v-model="item.value" @blur="parameterVerification($event,item)">
                                  <i class="iconStyle" slot="suffix" v-if="(item.valueMin != 0 || item.valueMax != 0)">{{currency}}</i>
                                </el-input>
                            </div>
                          </div>                              
                        </template>

                        <!-- 补仓间隔模式 -->
                        <template>
                          <div :key="'reserves3'+idx" class="parameter-box" v-if="item.visiable=='1'&&item.name == '补仓间隔模式'">
                            <div class="special-box">
                              <span class="special-box-title">{{$t('m.'+item.name)}}</span>
                              <div class="special-box-cont">
                                <span :class="{'on':intervalMode}" @click="intervalMode=true">
                                  {{$t('m.固定间隔')}}</span>
                                <span :class="{'on':!intervalMode}" @click="intervalMode=false">
                                  {{$t('m.自定义间隔')}}</span>
                              </div>
                            </div>
                          </div>   

                          <div :key="'reserves3'+idx" class="parameter-box" 
                            v-if="(intervalMode&&(item.id == 'reserve_38' ||item.id == 'reserve_39')) ||
                            (!intervalMode&&(item.id == 'reserve_40' 
                            || item.name.search('replenishment') != -1
                            || item.name.search('次补仓比例') != -1))">
                            <div class="left">
                              <span class="reserves-name">
                                <span>{{$t('m.'+item.name)}}</span>
                                <img src="../../assets/icon/jieshi.png" alt="" @click="reservesTips(item.comment)">
                              </span>

                              <span v-if="item.valueMin || item.start">{{
                                $t('m.范围') +
                                " " +
                                (item.valueMin &&
                                (item.valueMin != 0 || item.valueMax != 0)
                                  ? item.valueMin
                                  : item.start) +
                                "~" +
                                (item.valueMax &&
                                (item.valueMin != 0 || item.valueMax != 0)
                                  ? item.valueMax
                                  : item.end)
                              }}</span>
                            </div>

                            <div class="right">
                              <el-input  type='number' :placeholder="$t('m.请输入内容')" v-model="item.value" @blur="parameterVerification($event,item)">
                                <i class="iconStyle" slot="suffix" v-if="(item.valueMin != 0 || item.valueMax != 0)">{{currency}}</i>
                                <i class="iconStyle" slot="suffix" v-if="item.valueType=='percent'">%</i>
                              </el-input>
                            </div>
                          </div>   
                        </template>  

                        <!-- 其他参数 -->
                        <template>
                          <div :key="'reserves3'+idx"
                            class="parameter-box"
                            v-if="
                              item.visiable!='0'&&
                              item.id != 'reserve_38' &&
                              item.id!='reserve_37'&&
                              item.id != 'reserve_39' &&
                              item.id != 'reserve_7' &&item.id != 'reserve_6'&&
                              item.id != 'reserve_9' &&
                              item.id != 'reserve_10' &&
                              item.id != 'reserve_40' &&
                              item.id != 'reserve_35' &&
                              item.id != 'reserve_52' &&
                              item.id != 'reserve_53' &&
                              item.id != 'reserve_54' &&
                              item.name.search('replenishment') == -1&&item.name.search('次补仓比例') == -1&&item.name!='价格上限'&&item.name!='价格下限'
                            ">
                            <div class="left">
                              <span class="reserves-name">
                                <span>{{$t('m.'+item.name)}}</span>
                                <img src="../../assets/icon/jieshi.png" alt="" @click="reservesTips(item.comment)">
                              </span>

                              <!-- 不计算范围 -->
                              <span v-if="(item.valueMin=='' && item.valueMax=='') ||(item.valueMin=='0' && item.valueMax=='0')">{{
                                $t('m.范围') +
                                " " +
                                (item.valueMin &&
                                (item.valueMin != 0 || item.valueMax != 0)
                                  ? item.valueMin
                                  : item.start) +
                                "~" +
                                (item.valueMax &&
                                (item.valueMin != 0 || item.valueMax != 0)
                                  ? item.valueMax
                                  : item.end)
                              }}</span>

                              <!-- 计算范围 -->
                              <span  v-if="item.valueMin!='' && item.valueMax!='' &&(item.valueMin!='0' || item.valueMax!='0')">{{
                                $t('m.范围') +
                                calculation(
                                  currency,
                                  pairMap,
                                  item.valueMin &&
                                    (item.valueMin != '' || item.valueMax != '')
                                    ? item.valueMin
                                    : item.start
                                ) +
                                "~" +
                                calculation(
                                  currency,
                                  pairMap,
                                  item.valueMax &&
                                    (item.valueMin != 0 || item.valueMax != 0)
                                    ? item.valueMax
                                    : item.end
                              )}}</span> 
                            </div>

                            <div class="right">
                              <el-input  type='number' :placeholder="$t('m.请输入内容')" v-model="item.value" @blur="parameterVerification($event,item)">
                                <i class="iconStyle" slot="suffix" v-if="(item.valueMin != 0 || item.valueMax != 0)">{{currency}}</i>
                                <i class="iconStyle" slot="suffix" v-if="item.valueType=='percent'">%</i>
                              </el-input>
                            </div>                                  
                          </div>
                        </template>

                        <template v-if="item.name=='价格上限'||item.name=='价格下限'">
                          <div :key="'reserves3'+idx" class="parameter-box">
                            <div class="left">
                                <span class="reserves-name">
                                  <span>{{$t('m.'+item.name)}}</span>
                                  <img src="../../assets/icon/jieshi.png" alt="" @click="reservesTips(item.comment)">
                                </span>
                            </div>
                            <div class="right">
                                <el-input  type='number' :placeholder="$t('m.请输入内容')" v-model="item.value" @blur="parameterVerification($event,item)">
                                  <i class="iconStyle" slot="suffix">
                                    {{$t('m.选填')}}</i>
                                </el-input>
                            </div>
                          </div>
                        </template>
                      
                        <!-- 信号参数 -->
                        <template v-if="strategyId=='WL202X0512112808681'">
                          <div :key="'reserves3'+idx"
                            class="parameter-box"
                            v-if="                                    
                              item.id == 'reserve_52' ||
                              item.id == 'reserve_53' ||
                              item.id == 'reserve_54' ">
                            <div class="left">
                              <span class="reserves-name">
                                <span>{{$t('m.'+item.name)}}</span>
                              </span>
                            </div>

                            <div class="right" v-if="signalList">
                              <div>
                                <el-select v-model="item.signalName" :placeholder="$t('m.请选择')" @change="signalChange($event,item)">
                                  <el-option
                                    v-for="(val,idx) in signalList"
                                    :key="idx"
                                    :label="val.signalName"
                                    :value="val">
                                  </el-option>
                                </el-select>
                              </div>

                              <div class="signal-params">
                                <div class="one">
                                  <el-select v-model="item.paramBodyLabel" :placeholder="$t('m.请选择')" @change="paramBodyValue($event,item)">
                                    <template v-if="item.paramBody">
                                      <el-option
                                        v-for="(e,idx) in item.paramBody"
                                        :key="idx"
                                        :label="e.label"
                                        :value="e">
                                      </el-option>
                                    </template>
                                  </el-select>
                                </div>

                                <div class="two">
                                  <el-select v-model="item.ops" :placeholder="$t('m.请选择')" @change="opsChange($event,item)">
                                    <el-option
                                      v-for="(ops,idx) in SymbolList"
                                      :key="idx"
                                      :label="ops.value"
                                      :value="ops.value">
                                    </el-option>
                                  </el-select>
                                </div>

                                <div class="three">
                                  <el-input v-model="item.signalValue" placeholder="阈值" @change="signalValueChange($event,item)"></el-input>
                                </div>
                              </div>
                            </div> 
                          </div>
                        </template>
                      </template>
                    </template>

                    <!-- 合约策略 -->
                    <template v-if="strategyId=='WL202007311426363425x' ||strategyId== 'WL202007311426363424x'||strategyId=='WL20210624182847743'">
                      
                      <template v-for="(item,idx) in reserves">

                        <!-- 合约网格 等比/等差 -->
                        <template v-if="(gridDistribution&&item.name=='网格间隔(等比)')||(!gridDistribution&&item.name=='网格间隔(等差)')">
                          <div :key="'reserves4' + idx" class="parameter-box">
                            <div class="special-box">
                              <span class="special-box-title">
                                {{$t('m.网格分布')}}</span>
                              <div class="special-box-cont">
                                <span :class="{'on':gridDistribution}" @click="gridDistributionSelect(true)">
                                  {{$t('m.等比网格')}}</span>
                                <span :class="{'on':!gridDistribution}" @click="gridDistributionSelect(false)">
                                  {{$t('m.等差网格')}}</span>
                              </div>
                            </div>
                          </div>

                          <div :key="'reserves4' + idx" class="parameter-box" v-if="item.visiable=='1'">
                            <div class="left">
                              <span class="reserves-name">
                                <span>{{$t('m.'+item.name)}}</span>
                                <img src="../../assets/icon/jieshi.png" alt="" @click="reservesTips(item.comment)">
                              </span>

                              <template v-if="gridDistribution">
                                <!-- 不计算范围 -->
                                <span v-if="(item.valueMin=='' && item.valueMax=='') ||(item.valueMin=='0' && item.valueMax=='0')">{{
                                  $t('m.范围') +
                                  " " +
                                  (item.valueMin &&
                                  (item.valueMin != 0 || item.valueMax != 0)
                                    ? item.valueMin
                                    : item.start) +
                                  "~" +
                                  (item.valueMax &&
                                  (item.valueMin != 0 || item.valueMax != 0)
                                    ? item.valueMax
                                    : item.end)
                                }}</span>

                                <!-- 计算范围 -->
                                <span  v-if="item.valueMin!='' && item.valueMax!='' &&item.valueMin!='0' && item.valueMax!='0'">{{
                                  $t('m.范围') +
                                  calculation(
                                    currency,
                                    pairMap,
                                    item.valueMin &&
                                      (item.valueMin != '' || item.valueMax != '')
                                      ? item.valueMin
                                      : item.start
                                  ) +
                                  "~" +
                                  calculation(
                                    currency,
                                    pairMap,
                                    item.valueMax &&
                                      (item.valueMin != 0 || item.valueMax != 0)
                                      ? item.valueMax
                                      : item.end
                                  )
                                }}</span>                                    
                              </template>
                            </div>

                            <div class="right">
                              <el-input :placeholder="$t('m.请输入内容')"  type='number' v-model="item.value" @blur="parameterVerification($event,item)">
                                <i class="iconStyle" slot="suffix" v-if="item.valueType=='percent'">%</i>
                              </el-input>
                            </div>
                          </div>
                        </template>

                        <template v-if="item.name!='价格上限'&&item.name!='价格下限'&&item.name!='最大做多单数'&&item.name!='最大做空单数'&&item.name!='网格间隔(等差)'&&item.name!='网格间隔(等比)'&&item.name!=='补仓金额模式'&&item.name!=='补仓下单模式'&&item.name!=='止盈平仓模式'&&item.name!=='止盈下单模式'">
                          <div :key="'reserves4' + idx" class="parameter-box" v-if="item.visiable=='1'">
                            <div class="left">
                              <span class="reserves-name">
                                <span>{{$t('m.'+item.name)}}</span>
                                <img v-if="item.name!='首单投入（张）'&&item.name!='单格投入（张）'" src="../../assets/icon/jieshi.png" alt="" @click="reservesTips(item.comment)">
                                <img v-if="item.name=='单格投入（张）'" src="../../assets/icon/jieshi.png" alt="" @click="reservesTips(item.comment+'当前合约面值1张='+Number(multipleSelection[0].contractSize)+multipleSelection[0].counterCoin)">
                                <img v-if="item.name=='首单投入（张）'"  src="../../assets/icon/jieshi.png" alt="" @click="reservesTips(item.comment+'当前合约面值1张='+Number(multipleSelection[0].contractSize)+multipleSelection[0].counterCoin)">
                              </span>

                              <!-- 不计算范围 -->
                              <span v-if="(item.valueMin=='' && item.valueMax=='') ||(item.valueMin=='0' && item.valueMax=='0')">
                                {{
                              $t('m.范围') +
                                " " +
                                (item.valueMin &&
                                (item.valueMin != 0 || item.valueMax != 0)
                                  ? item.valueMin
                                  : item.start) +
                                "~" +
                                (item.valueMax &&
                                (item.valueMin != 0 || item.valueMax != 0)
                                  ? item.valueMax
                                  : item.end)
                              }}</span>

                              <!-- 计算范围 -->
                              <span  v-if="item.valueMin!='' && item.valueMax!='' &&item.valueMin!='0' && item.valueMax!='0'">{{
                                $t('m.范围') +
                                calculation(
                                  currency,
                                  pairMap,
                                  item.valueMin &&
                                    (item.valueMin != '' || item.valueMax != '')
                                    ? item.valueMin
                                    : item.start
                                ) +
                                "~" +
                                calculation(
                                  currency,
                                  pairMap,
                                  item.valueMax &&
                                    (item.valueMin != 0 || item.valueMax != 0)
                                    ? item.valueMax
                                    : item.end
                                )
                              }}</span>
                            </div>

                            <div class="right">
                              <el-input :placeholder="$t('m.请输入内容')"  type='number' v-model="item.value" @blur="parameterVerification($event,item)">
                                <i class="iconStyle" slot="suffix" v-if="item.valueType=='percent'">%</i>
                              </el-input>
                            </div>
                          </div>
                        </template>

                        <!-- SMT合约 -->
                        <template v-if="strategyId=='WL20210624182847743'">
                          <div :key="'reserves4' + idx" class="parameter-box" v-if="item.name=='补仓金额模式' ">
                            <div class="left">
                                <span class="reserves-name">
                                  <span>{{$t('m.'+item.name)}}</span>
                                  <img src="../../assets/icon/jieshi.png" alt="" @click="reservesTips(item.comment)">
                                </span>
                            </div>
                            <div class="right">
                              <div class="special-box-cont">
                                <span :class="{'on':replenishmentbutionOne}" @click="replenishmentbutionSelect(true,'倍数补仓')">
                                  {{$t('m.倍数补仓')}}</span>
                                <span :class="{'on':!replenishmentbutionOne}" @click="replenishmentbutionSelect(false,'增量补仓')">
                                  {{$t('m.增量补仓')}}</span>
                              </div>
                            </div>
                          </div>

                          <div :key="'reserves4' + idx" class="parameter-box" v-if="item.name=='补仓下单模式' ">
                            <div class="left">
                              <span class="reserves-name">
                                <span>{{$t('m.'+item.name)}}</span>
                                <img src="../../assets/icon/jieshi.png" alt="" @click="reservesTips(item.comment)">
                              </span>
                            </div>
                            <div class="right">
                              <div class="special-box-cont">
                                <span :class="{'on':replenishmentbutionTwo}" @click="replenishmentbutionSelect(true,'追踪补仓')">追踪补仓</span>
                                <span :class="{'on':!replenishmentbutionTwo}" @click="replenishmentbutionSelect(false,'预埋单补仓')">预埋单补仓</span>
                              </div>
                            </div>
                          </div>

                          <div :key="'reserves4' + idx" class="parameter-box" v-if="item.name=='止盈平仓模式' ">
                            <div class="left">
                              <span class="reserves-name">
                                <span>{{$t('m.'+item.name)}}</span>
                                <img src="../../assets/icon/jieshi.png" alt="" @click="reservesTips(item.comment)">
                              </span>
                            </div>
                            <div class="right">
                              <div class="special-box-cont">
                                <span :class="{'on':replenishmentbutionThree}" @click="replenishmentbutionSelect(true,'一次性止盈')">
                                  {{$t('m.一次性止盈')}}</span>
                                <span :class="{'on':!replenishmentbutionThree}" @click="replenishmentbutionSelect(false,'逐单止盈')">
                                  {{$t('m.逐单止盈')}}</span>
                              </div>
                            </div>
                          </div>

                          <div :key="'reserves4' + idx" class="parameter-box" v-if="item.name=='止盈下单模式' ">
                            <div class="left">
                                <span class="reserves-name">
                                  <span>{{$t('m.'+item.name)}}</span>
                                  <img src="../../assets/icon/jieshi.png" alt="" @click="reservesTips(item.comment)">
                                </span>
                            </div>

                            <div class="right">
                              <div class="special-box-cont">
                                <span :class="{'on':replenishmentbutionFour}" @click="replenishmentbutionSelect(true,'追踪止盈')">
                                  {{$t('m.追踪止盈')}}</span>
                                <span :class="{'on':!replenishmentbutionFour}" @click="replenishmentbutionSelect(false,'预埋单止盈')">
                                  {{$t('m.预埋单止盈')}}</span>
                              </div>
                            </div>
                          </div>
                        </template>
                        
                        <template v-if="(item.name=='最大做多单数'&&contractType==1)||(item.name=='最大做空单数'&&contractType==0)||(contractType==2&&(item.name=='最大做空单数'||item.name=='最大做多单数')) ">
                          <div :key="'reserves5' + idx" class="parameter-box" v-if="item.visiable=='1'">
                            <div class="left">
                              <span class="reserves-name">
                                <span>{{$t('m.'+item.name)}}</span>
                                <img src="../../assets/icon/jieshi.png" alt="" @click="reservesTips(item.comment)">
                              </span>

                              <!-- 不计算范围 -->
                              <span v-if="(item.valueMin=='' && item.valueMax=='') ||(item.valueMin=='0' && item.valueMax=='0')">{{
                                $t('m.范围') +
                                " " +
                                (item.valueMin &&
                                (item.valueMin != 0 || item.valueMax != 0)
                                  ? item.valueMin
                                  : item.start) +
                                "~" +
                                (item.valueMax &&
                                (item.valueMin != 0 || item.valueMax != 0)
                                  ? item.valueMax
                                  : item.end)
                              }}</span>

                              <!-- 计算范围 -->
                              <span  v-if="item.valueMin!='' && item.valueMax!='' &&item.valueMin!='0' && item.valueMax!='0'">{{
                                $t('m.范围') +
                                  calculation(
                                    currency,
                                    pairMap,
                                    item.valueMin &&
                                      (item.valueMin != '' || item.valueMax != '')
                                      ? item.valueMin
                                      : item.start
                                  ) +
                                  "~" +
                                  calculation(
                                    currency,
                                    pairMap,
                                    item.valueMax &&
                                      (item.valueMin != 0 || item.valueMax != 0)
                                      ? item.valueMax
                                      : item.end
                                  )
                              }}</span>
                            </div>
                            <div class="right">
                              <el-input :placeholder="$t('m.请输入内容')"  type='number' v-model="item.value" @blur="parameterVerification($event,item)">
                                <i class="iconStyle" slot="suffix" v-if="item.valueType=='percent'">%</i>
                              </el-input>
                            </div>
                          </div>
                        </template>

                        <template v-if="item.name=='价格上限'||item.name=='价格下限'">
                          <div :key="'reserves5' + idx" class="parameter-box">
                            <div class="left">
                              <span class="reserves-name">
                                <span>{{$t('m.'+item.name)}}</span>
                                <img src="../../assets/icon/jieshi.png" alt="" @click="reservesTips(item.comment)">
                              </span>
                            </div>
                            <div class="right">
                              <el-input :placeholder="$t('m.请输入内容')"  type='number' v-model="item.value" @blur="parameterVerification($event,item)">
                                <i class="iconStyle" slot="suffix">
                                  {{$t('m.选填')}}</i>
                              </el-input>
                            </div>
                          </div>
                        </template>
                      </template>

                      <!-- 开仓阈值 -->
                      <div class="parameter-box" v-if="reserves">
                        <div class="left">
                          <span class="reserves-name">
                            <span style="font-size:16px;color:#102859">
                              {{$t('m.策略开仓阈值')}}</span>
                            <img src="../../assets/icon/jieshi.png" alt="" @click="reservesTips(threshold)">
                          </span>
                        </div>
                        <div class="right">
                          <el-input :placeholder="$t('m.请输入内容')"  type='number' v-model="openingThreshold"  @change="openingThresholdChange">
                            <i class="iconStyle" slot="suffix">%</i>
                          </el-input>
                        </div>
                      </div>
                    </template>
                  </div>
                </div>

                <!-- 配置 -->
                <div class="configure">

                  <div class="title">
                    <span class="config">{{$t('m.配置投入带*符号为必填项')}}</span>
                  </div>

                  <div class="configure-cont" v-if="limitMap">

                    <!-- 固定投入 -->
                    <div class="fixedInvestment" v-if="investmentType=='固定投入'">
                      <div class="investment-left">
                        <div class="label investment">
                          <div>
                            <span>{{$t('m.总投入')}}：</span>
                            <img src="../../assets/icon/jieshi.png" alt="" @click="investmentTips()">
                          </div>
                          <span class="rang" v-if="limitMap">
                            {{$t('m.范围')+'：'+this.calculation(currency, pairMap, Number(limitMap.minInvest))+'~'+this.calculation(currency, pairMap, Number(limitMap.maxInvest))}}</span>
                        </div>
                        <el-select v-model="investmentType" :placeholder="$t('m.请选择')">
                          <el-option
                            v-for="item in investmentTypeList"
                            :key="item.value"
                            :label="$t('m.'+item.label)"
                            :value="item.value">
                          </el-option>
                        </el-select>
                      </div>

                      <div class="investment-right" >
                        <div class="label">
                          <span class="red">*</span>
                          <span class="text">{{$t('m.数量')}}：</span>
                        </div>
                        <el-input :placeholder="$t('m.请输入内容')"  type='number' v-model="investment" @blur="investmentCheck($event)">
                          <i class="iconStyle" slot="suffix">{{currency}}</i>
                        </el-input>
                      </div>
                    </div>

                    <!-- 比例投入 -->
                    <div class="proportionInvestment" v-if="investmentType=='比例投入'">

                      <div class="proportionInvestment-top">
                        <div class="warp">
                          <div class="label">
                            <div>
                              <span>{{$t('m.投入')}}：</span>
                              <img src="../../assets/icon/jieshi.png" alt=""  @click="investmentTips()">
                            </div>
                            <span class="rang" v-if="limitMap">
                              {{$t('m.范围')+'：'+this.calculation(currency, pairMap, Number(limitMap.minInvest))+'~'+this.calculation(currency, pairMap, Number(limitMap.maxInvest))}}</span>
                          </div>
                          <el-select v-model="investmentType" :placeholder="$t('m.请选择')">
                            <el-option
                              v-for="item in investmentTypeList"
                              :key="item.value"
                              :label="$t('m.'+item.label)" 
                              :value="item.value">
                            </el-option>
                          </el-select>
                        </div>

                        <div class="warp ratio">
                          <div class="label">
                            <span class="red">*</span>
                            <span>{{$t('m.资金比例')}}：</span>
                          </div>
                          <el-input placeholder="" v-model="investment" @blur="investmentCheck($event)">
                            <i class="iconStyle" slot="suffix">%</i>
                          </el-input>                             
                        </div>
                      </div>

                      <div class="proportionInvestment-bottom">
                        <div class="warp">
                          <div class="label">
                            <span>{{$t('m.资金上下限')}}：</span>
                          </div>
                          <el-input :placeholder="$t('m.选填')"  type='number' v-model="min" @blur="proportionalCheck($event,min,'min')">
                            <i class="iconStyle" slot="suffix">{{currency}}</i>
                          </el-input>                             
                        </div>
                        <el-input :placeholder="$t('m.选填')"  type='number' v-model="max" @blur="proportionalCheck($event,max,'max')">
                          <i class="iconStyle" slot="suffix">{{currency}}</i>
                        </el-input>                             
                      </div>
                    </div>

                    <div class="otherSettings">
                      <div class="title">{{$t('m.其他设置')}}</div>
                      <div class="Setting-warp">
                        
                        <div class="Setting-parameter">
                          <div class="Setting-parameter-title">
                            <span>{{$t('m.总收益止盈')}}</span>
                            <img src="../../assets/icon/jieshi.png" alt="" @click="SP()">
                          </div>
                          <el-input  type='number'  :placeholder="$t('m.选填')" v-model="stopSurplus">
                            <i class="iconStyle" slot="suffix">%</i>
                          </el-input>
                        </div>

                        <div class="Setting-parameter">
                          <div class="Setting-parameter-title">
                            <span>{{$t('m.总亏损止损')}}</span>
                            <img src="../../assets/icon/jieshi.png" alt="" @click="SL()">
                          </div>
                          <el-input  type='number' :placeholder="$t('m.选填')" v-model="stopLoss">
                            <i class="iconStyle" slot="suffix">%</i>
                          </el-input>
                        </div>

                        <div class="Setting-parameter">
                          <div class="Setting-parameter-title">
                            <span>{{$t('m.定时自动终止')}}</span>
                            <img src="../../assets/icon/jieshi.png" alt="" @click="timeTips()">
                          </div>
                          <el-date-picker
                          v-model="timedTermination"
                            class="date-picker"
                            type="datetime"
                            :placeholder="$t('m.选填')"
                            :picker-options="pickerOptions"
                            :clearable='false'>
                          </el-date-picker>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>  

              <!-- 账户 -->
              <div class="account">
                <div class="title">
                  <span>{{$t('m.账户')}}</span>
                </div>
                
                <div class="account-cont"  :class="[accList.length > 5 ? 'overflowBox' : '']">

                  <template v-if="accList.length>0&&strategyId">
                    <div class="acc-warp">
                      <div class="acc-box" v-for="(item,idx) in accList" :key="new Date().getTime()+idx">
                        <div class="acc-boxLeft">
                          <div class="acc-box-name">
                          <span>{{item.remarkCode?item.remarkCode:item.publicCode}}</span>
                          <el-checkbox
                            v-model="item.isCheck"
                            @change="accChange"
                            :checked='item.isCheck'
                            :disabled='item.show'>
                          </el-checkbox>
                        </div>
                        <span class="available">{{'可用：'+ (Math.floor(Number(item.available)*10000)/10000)  +' '+currency}}</span>
                        </div>
                        <div class="acc-boxBtn" v-if="transferShow" @click="transferBtn(item)">
                          {{$t('m.划转')}}</div>
                      </div>
                    </div>                        
                  </template>

                  <template v-else>
                    <div class="account-cont-tips">
                      {{$t('m.选定的账户自动以此配置为模板启动策略实例，您也可以先创建模板，然后再选择账户添加到创建好的模板中以启动实例。')}}</div>
                    <div class="no-acc">
                      <img src="../../assets/image/no-acc.png" alt="">
                      <p>{{$t('m.暂无可用账户')}}</p>
                    </div>
                  </template>

                  <div class="btn-warp" :class="{'on':startOn}" @click="strategyStart()" >
                    <span>{{$t('m.创建策略')}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-main>
    </el-container>

    <!-- 选择交易对 -->
    <el-dialog
      :title="$t('m.选择交易对')"
      :visible.sync="selectPairShow"
      width="600px"
      height='500px'
      :show-close='false'>
      <div class="pair-warp" style="height:500px;overflow: scroll;">

        <template v-if="transactionType!='spot'">
          <el-table
            ref="multipleTable"
            :data="pairList"
            tooltip-effect="dark"
            style="width: 100%"
            @selection-change="handleSelectionChange">

            <el-table-column prop="counterCoin" :label="$t('m.交易对')" width="160">
              <template slot-scope="scope">
                <span>{{ scope.row.counterCoin+'/'+scope.row.baseCoin }}</span>
              </template>
            </el-table-column>

            <el-table-column prop="last" :label="$t('m.价格')" width="160" sortable>
              <template slot-scope="scope">
                <span>{{
                  Math.floor(Number(scope.row.last) * 10000) / 10000
                }}</span>
              </template>
            </el-table-column>

            <el-table-column
              prop="increase"
              :label="$t('m.涨跌幅')"
              width="160"
              sortable>
              <template slot-scope="scope">
                <span>{{ scope.row.increase }}</span>
              </template>
            </el-table-column>

            <el-table-column type="selection" width="60">
            </el-table-column>
          </el-table>
        </template>
        
        <template v-if="transactionType=='spot'">

          <el-tabs v-model="pairActiveName" @tab-click="handleClick">
            <el-tab-pane v-if="exchangeName == 'Bithumb'" label="KRW" name="KRW"></el-tab-pane>
            <el-tab-pane  v-else label="USDT" name="USDT"></el-tab-pane> 
          </el-tabs>

          <template>
            <el-table
              ref="multipleTable"
              :data="pairList"
              tooltip-effect="dark"
              style="width: 100%"
              @selection-change="handleSelectionChange">

              <el-table-column prop="counterCoin" :label="$t('m.币种')" width="160">
                <template slot-scope="scope">
                  <span>{{
                    scope.row.counterCoin + "/" + scope.row.baseCoin
                  }}</span>
                </template>
              </el-table-column>
              
              <el-table-column prop="last" :label="$t('m.价格')" width="160" sortable>
                <template slot-scope="scope">
                  <span>{{
                    Math.floor(Number(scope.row.last) * 10000) / 10000
                  }}</span>
                </template>
              </el-table-column>

              <el-table-column
                prop="increase"
                :label="$t('m.涨跌幅')"
                width="160"
                sortable>
                <template slot-scope="scope">
                  <span :class="{'red':Number(scope.row.increase)>0,'green':Number(scope.row.increase)<0}">{{Number(scope.row.increase)>0?('+'+scope.row.increase):scope.row.increase }}</span>
                </template>
              </el-table-column>
              
              <el-table-column type="selection" width="60">
              </el-table-column>
            </el-table>
          </template>
        </template>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="selectPairCancel()">
          {{$t('m.取 消')}}</el-button>
        <el-button type="primary" @click="selectPairConfirm()">
          {{$t('m.确 定')}}</el-button>
      </span>
    </el-dialog>

    <!-- 划转 -->
    <el-dialog
      :title="$t('m.划转')"
      :visible.sync="transfer"
      width="400px"
      center
      :before-close="handleClose"
      :close-on-click-modal="false">
      <div class="dialog-nav">
        <div class="dialog-box">
          <div>
            <div class="dialog-top">
              {{$t('m.从')}}：
              <el-select
                size="medium"
                v-model="topValue"
                @change="topValueChange"
                :placeholder="$t('m.请选择')">
                <el-option
                  v-for="(item, index) in topOptions"
                  :key="index"
                  :value="item.name">
                </el-option>
              </el-select>
            </div>

            <div class="dialog-top">
              {{$t('m.到')}}：
              <el-select
                size="medium"
                v-model="twoValue"
                @change="twoValueChange"
                :placeholder="$t('m.请选择')">
                <el-option
                  v-for="(item, index) in twoOptions"
                  :key="index"
                  :value="item.name">
                </el-option>
              </el-select>
            </div>
          </div>

          <img
            @click="transformationBtn()"
            class="dialog-img"
            src="../../assets/icon/zhuanhuan-big.png"
            alt=""/>
        </div>

        <div class="dialog-currency" style="margin-bottom: 15px">
          <span>{{$t('m.币种')}}</span>
          <el-select size="medium" v-model="currencyValue" :placeholder="$t('m.请选择')">
            <el-option
              v-for="(item, index) in currencyList"
              :key="index"
              :label="item"
              :value="item">
            </el-option>
          </el-select>
        </div>

        <div class="dialog-currency">
          <span>{{$t('m.划转数量')}}</span>
         
          <el-input
            v-model="inputOptions"
            type="number"
            :placeholder="$t('m.请输入内容')"
            :class="inputStyle ? 'inputStyleItem' : ''"
            @focus="inputNumberFocus($event)"
            @blur="inputNumber($event)">
            <template slot="append">USDT |
              <span
                style="cursor: pointer; color: #2174ff"
                @click="inputOptions = usdtAvailable">
                {{$t('m.全部')}}</span>
            </template>
          </el-input>
        </div>

        <span style="margin-top: 10px">
          {{$t('m.可用划转') + ' ' +  usdtAvailable + " " + currencyValue }}
        </span>
      </div>

      <div slot="footer">
        <div class="dialog-footer">
          <div
            class="footerBack"
            @click="
              transfer = false;
              inputOptions = '';
              currencyValue = 'USDT';
            ">
            {{$t('m.取消')}}</div>

          <div class="footerBtn" @click="transferDetermine()">
            {{$t('m.确定')}}</div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>





<script>
import {
  strategyLibrary,
  getExchangeListByType,
  getStartPage,
  addStrategyTemplate,
  getPairPageById,
  queryStrategyTemplateLabel,
  getAvailableList,
  findByapiIdCoin,
  queryOrderChainContractPair,
  findByIdLeverMultiple,
  queryOperatorUserList,
  findFundSpot,
  findFundAssets,
  getProject,
  transfer,
  findContractId,
  findContract,
  querySignalList,
  signalDetail,
  batchSynchContractApi,
} from "../../request/api";
import { times } from "../../assets/js/time";
export default {
  components: {},
  data() {
    return {
      input3: "",
      pattern: true,
      investmentTypeList: [
        { value: "固定投入", label: "固定投入" },
        { value: "比例投入", label: "比例投入" },
      ],
      capitalProportionTypeList: [
        { value: "账户可用资金", label: "账户可用资金" },
        { value: "账户总资金", label: "账户总资金" },
      ],

      capitalProportionType: "账户可用资金", //比例锚定对象

      labelData: { label: null }, //随机标签
      investmentType: "固定投入", //投入类型
      investment: null, //投入数量/比例
      max: null, //比例上限
      min: null, //比例下限

      currency: "USDT", //投入币种

      project: null, //项目信息
      defaultTeam: null, //团队信息
      exchangeList: [], //交易所列表
      exchangeId: null,
      exchangeName: null,
      strategyList: [], //策略列表
      strategyId: null, //策略ID
      strategyName: null,

      modeSelection: true, //模式  true  自定义
      modeIsShow: true, // 模式显示隐藏
      strategicDirection: false, //策略方向  false  本币
      strategicDirectionIsShow: true, //策略方向显示隐藏

      selectPairShow: false, //选择交易对弹窗
      pairList: null,
      copyPairList: null,
      multipleSelection: [], //交易对列表
      copyMultipleSelection: [], //交易对备份列表
      tagList: [], //tag显示列表
      copyTagList: [], //备份显示
      tagNum: 3,
      accList: [], //账户列表
      copyAccList: [], //备份账户列表
      selectAccList: [], //选中账户

      //********策略数据********//

      configMap: null, //策略配置
      limitMap: null, //策略范围
      pairMap: null, //策略交易对
      reserves: null, //策略参数
      copyReserves: null, //备份参数
      signalList: null, //信号列表
      SymbolList: [
        { value: "=" },
        { value: ">" },
        { value: "<" },
        { value: "≥" },
        { value: "≦" },
      ],

      // ******* 其他设置参数 *****//

      stopSurplus: "", //止盈
      stopLoss: "", //止损
      timedTermination: "", //定时终止

      replenishment: true, //海风补仓模式
      intervalMode: true, //海风间隔模式

      // ******** 恒量定投特殊数据******//
      times: times,
      fixedTime: [1, 7],

      // ******自动终止时间日期禁用*****//
      pickerOptions: {
        disabledDate: (time) => {
          return time.getTime() < new Date().getTime();
        },
      },

      startOn: false, // 启动按钮颜色切换

      parameterCheckList: [], //参数校验
      investmentIsTrue: true, //投入校验
      proportionalIsOk: true, //比例范围校验

      startAcc: null, //传递的账户

      pairActiveName: "USDT",

      transactionType: "spot", //策略类型

      // *********合约*************//
      contractTypeShow: false, //合约模式显示
      contractType: 1, //当前合约做多/空

      gridDistribution: true, //网格分布

      leverList: [], //杠杆列表
      lever: 3,

      openingThreshold: 600, //开仓阈值
      threshold:
        "当担保资产率下降到此值之下时,策略将暂停开仓动作,平仓逻辑不受影响,您可根据风险承受能力自主调节,担保资产率 =(账户权益/(持仓担保资产 + 冻结担保资产))*100%－交易所调整系数。",

      contractId: null,
      contractSize: null,

      //*** */    划转按钮
      transferShow: false, //划转按钮
      transfer: false, //划转显隐
      inputOptions: null,
      currencyValue: null,
      usdtAvailable: null,
      inputStyle: null,
      twoOptions: [],
      twoValue: null,
      currencyList: [],
      topValue: null,
      topOptions: [],
      replenishmentbutionOne: true,
      replenishmentbutionTwo: true,
      replenishmentbutionThree: true,
      replenishmentbutionFour: true,

      copyStrategy: null, // 复制的策略模板

      exchangeCh: false, //
      pairCh: false, //
      strategyCh: false, //
      reservesCh: false,
      signalCh: false,
    };
  },
  computed: {},
  watch: {
    // 策略方向切换
    strategicDirection(New, old) {
      if (this.multipleSelection.length != 0) {
        if (New == false) {
          this.currency = this.multipleSelection[0].baseCoin;
        } else {
          this.currency = this.multipleSelection[0].counterCoin;
        }
        // this.getAvailableList();
        this.queryOperatorUserList();
        console.log(this.currency);
      }
    },
    // 投入类型切换
    investmentType(New, old) {
      // this.investment = null;
      // this.max = null;
      // this.min = null;
      this.proportionalIsOk = true;

      this.accList = JSON.parse(JSON.stringify(this.copyAccList));
      console.log(this.accList);
      console.log(this.investment);
    },

    // 比例锚定对象切换
    capitalProportionType(New, old) {
      // this.investment = null;
      console.log(this.investment);
    },

    // 监听投入
    investment(New, old) {
      console.log(this.multipleSelection);
      if (
        New &&
        this.strategyId &&
        this.exchangeId &&
        this.multipleSelection.length != 0
      ) {
        this.startOn = true;
      } else {
        this.startOn = false;
      }
      console.log(this.selectAccList);
    },
    currencyValue() {
      if (this.transformation == false) {
        let payload = {
          token: sessionStorage.getItem("token"),
          apiId: this.spotByApp.apiId,
          coin: this.currencyValue,
        };
        this.findFundSpot(payload);
      } else {
        let payload = {
          token: sessionStorage.getItem("token"),
          apiId: this.spotByApp.apiId,
          coin: this.currencyValue,
        };
        this.findFundAssets(payload);
      }
    },
  },
  methods: {

    // 多币对tab切换
    handleClick(tab, event) {
      
      let list = JSON.parse(JSON.stringify(this.copyPairList));
      let newList = [];
      for (let key of list) {
        if (this.pairActiveName == key.baseCoin) {
          newList.push(key);
        }
      }
      this.pairList = newList;
    },

    transferBtn(e) {
      this.transfer = true;
      this.transformation = true;
      this.spotByApp = e;
      this.currencyValue = "USDT";
      if (e.exchangeName == "OkexV5") {
        this.topValue = "统一账户";
        this.topOptions = [{ name: "统一账户" }];
        this.twoValue = "资金账户";
        this.twoOptions = [{ name: "资金账户" }];
        this.currencyList = this.tradePair;
        this.transformation = false;
        setTimeout(() => {
          let payload = {
            token: sessionStorage.getItem("token"),
            apiId: this.spotByApp.apiId,
            coin: this.currencyValue,
          };
          this.findFundSpot(payload);
        }, 300);
      } else {
        if (this.exchangeName == "Huobi") {
          let payload = {
            token: sessionStorage.getItem("token"),
            teamProjectId: this.project.teamProjectId,
            exchangeName: this.exchangeName,
          };
          this.findContractId(payload);
        } else {
          this.topOptions = [{ name: "USDT 币币账户" }];
          this.topValue = "USDT 币币账户";
          this.twoOptions = [{ name: "USDT 合约全仓" }];
          this.twoValue = "USDT 合约全仓";
        }
        this.currencyList = ["USDT"];
        setTimeout(() => {
          let payload = {
            token: sessionStorage.getItem("token"),
            apiId: this.spotByApp.apiId,
            coin: "USDT",
          };
          this.findFundSpot(payload);
        }, 300);
      }
    },
    //获取合约可划转账户
    findFundSpot(payload) {
      findFundSpot(payload).then((res) => {
        console.log(res);
        let data = res.data;
        console.log(data);
        let available = data.available;
        if (Number(available) == 0) {
          available = 0;
        } else {
          available = available.slice(0, available.indexOf(".") + 5);
        }
        this.usdtAvailable = available;
      });
    },
    //获取可用币对合约
    findContractId(payload) {
      findContractId(payload).then((res) => {
        let data = res.data;
        let contractIdList = [];
        data.forEach((e) => {
          contractIdList.push({ name: "USDT 永续合约 " + e.contractId });
        });
        let value1 = this.twoValue;
        let value2 = this.topValue;
        if (this.transformation) {
          this.twoOptions = contractIdList;
          contractIdList.forEach((e) => {
            if (e.name.indexOf(this.tagList[0].split("/")[0]) > 0) {
              this.twoValue = e.name;
            }
          });
          this.topOptions = [{ name: "USDT 币币账户" }];
          this.topValue = "USDT 币币账户";
        } else {
          this.topOptions = contractIdList;
          contractIdList.forEach((e) => {
            if (e.name == value1) {
              this.topValue = e.name;
            }
          });
          // this.spotByApp.show = false;
          this.twoOptions = [{ name: "USDT 币币账户" }];
          this.twoValue = "USDT 币币账户";
        }
      });
    },
    twoValueChange() {
      console.log(111111);
    },
    topValueChange(e) {
      console.log(e);
      if (!this.transformation) {
        let payload = {
          token: sessionStorage.getItem("token"),
          apiId: this.spotByApp.apiId,
          exchangeName: this.spotByApp.exchangeName,
          contractId: e.split(" ")[2],
        };
        this.findContract(payload);
      }
    },
    //获取币币同步全部资产
    findContract(payload) {
      findContract(payload).then((res) => {
        if (res.status == 200) {
          if (res.data) {
            let data = res.data;
            if (Number(data.available) == 0) {
              data.available = 0;
            } else {
              data.available = Number(data.available)
                .toString()
                .slice(0, Number(data.available).toString().indexOf(".") + 5);
            }
            this.usdtAvailable = data.available;
            this.contractData = data;
          } else {
            this.usdtAvailable = 0;
          }
        }
      });
    },
    handleClose(done) {
      done();
    },
    //转换按钮
    transformationBtn() {
      this.transformation = !this.transformation;
      if (this.exchangeName == "OkexV5") {
        if (this.transformation == false) {
          this.topValue = "统一账户";
          this.topOptions = [{ name: "统一账户" }];
          this.twoValue = "资金账户";
          this.twoOptions = [{ name: "资金账户" }];
          this.currencyList = this.tradePair;
          setTimeout(() => {
            let payload = {
              token: sessionStorage.getItem("token"),
              apiId: this.spotByApp.apiId,
              coin: this.currencyValue,
            };
            this.findFundSpot(payload);
          }, 300);
        } else {
          this.twoValue = "统一账户";
          this.twoOptions = [{ name: "统一账户" }];
          this.topValue = "资金账户";
          this.topOptions = [{ name: "资金账户" }];
          let payload = {
            token: sessionStorage.getItem("token"),
            apiId: this.spotByApp.apiId,
            coin: this.currencyValue,
          };
          this.findFundAssets(payload);
        }
      } else {
        if (this.exchangeName == "Huobi") {
          let payload = {
            token: sessionStorage.getItem("token"),
            teamProjectId: this.project.teamProjectId,
            exchangeName: this.exchangeName,
          };
          this.findContractId(payload);
        } else {
          if (this.transformation) {
            this.twoOptions = [{ name: "USDT 合约全仓" }];
            this.twoValue = "USDT 合约全仓";
            this.topOptions = [{ name: "USDT 币币账户" }];
            this.topValue = "USDT 币币账户";
          } else {
            this.topOptions = [{ name: "USDT 合约全仓" }];
            this.topValue = "USDT 合约全仓";
            this.twoOptions = [{ name: "USDT 币币账户" }];
            this.twoValue = "USDT 币币账户";
          }
        }
        setTimeout(() => {
          if (!this.transformation) {
            let payload = {
              token: sessionStorage.getItem("token"),
              apiId: this.spotByApp.apiId,
              exchangeName: this.spotByApp.exchangeName,
            };
            if (this.exchangeName == "Huobi") {
              payload.contractId = this.topValue.split(" ")[2];
            } else {
              payload.contractId = this.topValue.split(" ")[0];
            }
            this.findContract(payload);
          } else {
            let payload = {
              token: sessionStorage.getItem("token"),
              apiId: this.spotByApp.apiId,
              coin: "USDT",
            };
            this.findFundSpot(payload);
          }
        }, 300);
      }
    },
    //获取项目可用币种
    getProject() {
      let payload = {
        teamProjectId: this.project.teamProjectId,
        token: sessionStorage.getItem("token"),
      };
      getProject(payload).then((res) => {
        
        let tradePair = JSON.parse(res.data.project.tradePair);
        this.tradePair = tradePair;


      });
    },

    findFundAssets(payload) {
      findFundAssets(payload).then((res) => {
        if (res.status == 200) {
          console.log(res, 1111111111);
          this.usdtAvailable = res.data.available;
        }
      });
    },

    //划转接口
    transferDetermine() {
      if (Number(this.inputOptions) <= 0) {
        return this.$message.warning("划转不能小于0");
      } else if (Number(this.inputOptions) > this.usdtAvailable) {
        return this.$message.warning("划转不能大于可用划转金额");
      }
      let payload = {};
      if (this.transformation) {
        payload = {
          token: sessionStorage.getItem("token"),
          paramStr: {
            from: "spot",
            to: "linear-swap",
            "margin-account":
              this.exchangeName == "Huobi"
                ? this.twoValue.split(" ")[2]
                : this.twoValue.split(" ")[0],
          },
        };
      } else {
        payload = {
          token: sessionStorage.getItem("token"),
          paramStr: {
            from: "linear-swap",
            to: "spot",
            "margin-account":
              this.exchangeName == "Huobi"
                ? this.topValue.split(" ")[2]
                : this.topValue.split(" ")[0],
          },
        };
      }
      console.log(this.exchangeName);
      if (this.exchangeName == "OkexV5") {
        payload.paramStr = {
          ...payload.paramStr,
          "margin-account": this.currencyValue,
        };
      }
      payload.paramStr.currency = this.currencyValue;
      payload.paramStr.amount = this.inputOptions;
      payload.apiId = this.spotByApp.apiId;
      console.log(payload);
      transfer(payload).then((res) => {
        if (res.status == 200) {
          this.transfer = false;
          this.inputOptions = "";
          this.pageNo = 1;
          this.batchShow = true;
          this.$message.success(this.$t('m.操作成功'));
          batchSynchContractApi(payload).then((res) => {
            this.queryOperatorUserList();
          });
          this.batchShow = false;
        } else {
          this.$message.error(res.msg);
        }
      });
    },

    //输入框校验
    inputNumber(e) {
      console.log(this.inputOptions, this.usdtAvailable);
      if (Number(this.inputOptions) <= 0) {
        this.$message.error(this.$t('m.划转不能小于0'));
        e.target.style.border = "1px solid red";
        this.inputVerification = false;
      } else if (Number(this.inputOptions) > this.usdtAvailable) {
        this.$message.error(this.$t('m.划转不能大于可用划转金额'));
        e.target.style.border = "1px solid red";
        this.inputVerification = false;
      } else {
        e.target.style.border = "1px solid #DCDFE6";
        this.inputVerification = true;
      }
    },

    inputNumberFocus(e) {
      e.target.style.border = "1px solid #DCDFE6";
    },

    ////***********划转按钮结束 */

    // 投入解释
    investmentTips() {
      this.$alert(
        "<div style='color:#102859'><p>"+
        this.$t('m.1、设置固定金额时，不满足金额或数量的账户不下单；')+
        "</p><p>"+
        this.$t('m.2、设置比例投入，账户满足比例时，低于下限的不下单；高于上限的按上限下单。')+
        "</p></div>",
        this.$t('m.投入'),
        {
          confirmButtonText: this.$t('m.确定'),
          dangerouslyUseHTMLString: true,
        }
      );
    },

    // 止盈
    SP() {
      if (this.transactionType == "spot") {
        this.$alert(
          "<div style='color:#102859'>"+
          this.$t('m.止盈根据总收益率计算，策略暂停操作优先级高于止盈止损及定时终止，即暂停状态下此类操作不生效。触发止盈后自动终止策略，系统根据你的选择决定是否执行撤单兑换操作。')+
          "</div>",
          this.$t('m.总收益止盈'),
          {
            confirmButtonText: this.$t('m.确定'),
            dangerouslyUseHTMLString: true,
          }
        );
      } else {
        this.$alert(
          "<div style='color:#102859'>"+
          this.$t('m.止盈根据总收益率计算，触发止盈后系统自动撤单平仓后终止策略。')+
          "</div>",
          this.$t('m.总收益止盈'),
          {
            confirmButtonText: this.$t('m.确定'),
            dangerouslyUseHTMLString: true,
          }
        );
      }
    },
    // 止损
    SL() {
      if (this.transactionType == "spot") {
        this.$alert(
          "<div style='color:#102859'>"+
          this.$t('m.止损根据总收益率计算，策略暂停操作优先级高于止盈止损及定时终止，即暂停状态下此类操作不生效。触发止损后自动终止策略，系统根据你的选择决定是否执行撤单兑换操作。')+
          "</div>",
          this.$t('m.总收益止损'),
          {
            confirmButtonText: this.$t('m.确定'),
            dangerouslyUseHTMLString: true,
          }
        );
      } else {
        this.$alert(
          "<div style='color:#102859'>"+
          this.$t('m.止损根据总收益率计算，触发止损后系统自动撤单平仓后终止策略。')+
          "</div>",
          this.$t('m.总收益止损'),
          {
            confirmButtonText: this.$t('m.确定'),
            dangerouslyUseHTMLString: true,
          }
        );
      }
    },

    // 定时终止
    timeTips() {
      if (this.transactionType == "spot") {
        this.$alert(
          "<div style='color:#102859'>"+
          this.$t('m.策略暂停操作优先级高于止盈止损及定时终止，即暂停状态下此类操作不生效。定时终止可能会有几分钟的偏差，触发后自动终止策略，系统根据你的选择决定是否执行撤单兑换操作。')+
          "</div>",
          this.$t('m.定时自动终止'),
          {
            confirmButtonText: this.$t('m.确定'),
            dangerouslyUseHTMLString: true,
          }
        );
      } else {
        this.$alert(
          "<div style='color:#102859'>"+
          this.$t('m.定时终止可能会有几分钟的偏差，触发后系统自动撤单平仓后终止策略。')+
          "</div>",
          this.$t('m.定时自动终止'),
          {
            confirmButtonText: this.$t('m.确定'),
            dangerouslyUseHTMLString: true,
          }
        );
      }
    },

    // 参数解释
    reservesTips(val) {
      console.log(val);
      this.$alert("<div style='color:#102859'>" + val + "</div>", "", {
        confirmButtonText: this.$t('m.确定'),
        dangerouslyUseHTMLString: true,
      });
    },

    // 返回上一页
    goBack() {
      this.clear();
      this.$router.back(-1);
      // this.$destroy();
    },

    // 定投参数
    handleChange(value) {
      this.fixedTime = value;
    },

    // 获取随机标签
    queryStrategyTemplateLabel() {
      let payload = {
        token: sessionStorage.getItem("token"),
      };
      queryStrategyTemplateLabel(payload).then((res) => {
        if (res.status == 200) {
          console.log("随机标签", res.data.strategyTemplateLabel);
          if (res.data.strategyTemplateLabel) {
            this.labelData = res.data.strategyTemplateLabel;
          }
        }
      });
    },

    // 查询交易所
    getExchangeList() {
      let payload = {
        token: sessionStorage.getItem("token"),
        teamProjectId: this.project.teamProjectId,
      };
      console.log(this.project.teamProjectId);
      getExchangeListByType(payload).then((res) => {
        if (res.status == 200) {
          let list = res.data.exchangeList;
          this.exchangeList = list;

          if (this.startAcc) {
            for (let key of list) {
              if (key.exchangeName == this.startAcc[0].exchangeName) {
                this.exchangeId = key.exchangeId;
                this.exchangeName = key.exchangeName;
              }
            }
          } else if (this.exchangeCh) {
            this.exchangeName = this.copyStrategy.tradePairs.exchangeName;
            this.exchangeId = this.copyStrategy.tradePairs.exchangeId;
            this.exchangeCh = false;
          } else {
            console.log(2);
            this.exchangeName = this.exchangeList[0].exchangeName;
            this.exchangeId = this.exchangeList[0].exchangeId;
          }


          if (this.transactionType == "spot") {
            this.selectPair();
          } else {
            this.queryOrderChainContractPair();
          }
          this.pairActiveName = this.exchangeName == 'Bithumb' ? 'KRW' : "USDT";
          this.currency = this.pairActiveName;
          this.handleClick()


          console.log("交易所列表", res.data);
        } else {
          this.$message.error(res.msg);
        }
      });
    },

    // 选择交易所
    selectExchange(v) {
      let list = this.exchangeList;
      for (let key of list) {
        if (key.exchangeId == v) {
          this.exchangeName = key.exchangeName;
        }
      }

      console.log(v, this.exchangeName);
      this.pairList = null;
      this.copyPairList = null;
      this.tagList = [];
      this.copyTagList = [];
      this.multipleSelection = [];
      this.copyMultipleSelection = [];
      this.selectAccList = [];
      this.accList = [];

      console.log(this.selectAccList);

      this.copyStrategyName = this.strategyName;
      this.copyreserves = this.reserves;

      if (this.transactionType == "spot") {
        this.selectPair();
      } else {
        this.queryOrderChainContractPair();
        this.findByIdLeverMultiple();
      }
    },

    // 策略方向切换
    selectStrategicDirection(v) {
      this.strategicDirection = v;
      if (this.strategyId == "WL202007311426363421x") {
        this.payloadCheck();
      }
    },

    // 现货策略模式切换
    selectModeSelection(v) {
      console.log(this.modeSelection, v);
      this.modeSelection = v;
      this.tagList = [];
      this.copyTagList = [];
      this.multipleSelection = [];
      this.copyMultipleSelection = [];
      this.pairList = JSON.parse(JSON.stringify(this.copyPairList));
      this.strategicDirection = false;
      let list = JSON.parse(JSON.stringify(this.copyPairList));
      for (let key of list) {
        if (key.baseCoin == "USDT" && key.counterCoin == "BTC") {
          let item = key.counterCoin + "/" + key.baseCoin;
          this.tagList.push(item);
          this.copyTagList.push(item);
          this.multipleSelection.push(key);
        }
      }

      if (v == true) {
      } else {
        let list = JSON.parse(JSON.stringify(this.copyPairList));
        let newList = [];
        for (let key of list) {
          if (this.pairActiveName == key.baseCoin) {
            newList.push(key);
          }
        }
        this.pairList = newList;
      }
      this.pairActiveName = this.exchangeName == 'Bithumb' ? 'KRW' : "USDT";
      this.handleClick();
    },

    // 合约模式切换
    contractModeSelect(v) {
      this.contractType = v;
    },

    // 查询策略
    strategyLibrary() {
      let payload = {
        token: sessionStorage.getItem("token"),
        teamProjectId: this.project.teamProjectId,
      };
      strategyLibrary(payload).then((res) => {
        if (res.status == 200) {
          let publicStrategys = res.data.publicStrategys;
          console.log(publicStrategys);

          // let publicStrategysList = [];
          // publicStrategys.forEach((e) => {
          //   if (e.strategyId !== "WL20210624182847743") {
          //   publicStrategysList.push(e);
          //   }
          // });

          this.strategyList = publicStrategys;
          for (let key of this.strategyList) {
            if (this.startAcc) {
              if (this.startAcc[0].contract == true) {
                if (key.strategyName == "合约网格") {
                  this.strategyId = key.strategyId;
                  this.strategyName = key.strategyName;
                }
              } else {
                if (key.strategyName == "网格交易") {
                  this.strategyId = key.strategyId;
                  this.strategyName = key.strategyName;
                }
              }
            } else {
              if (key.strategyName == "网格交易") {
                this.strategyId = key.strategyId;
                this.strategyName = key.strategyName;
              }
            }
          }

          if (this.strategyCh) {
            this.strategyId = this.copyStrategy.strategyId;
            this.strategyName = this.copyStrategy.strategyName;

            this.strategyCh = false;
          }

          console.log("策略列表", res.data);
        } else {
          this.$message.error(res.msg);
        }
      });
    },

    // 策略选择
    selectStrategy(v) {
      console.log(v, this.strategyId);
      this.openingThreshold = 600;
      this.replenishmentbutionOne = true;
      this.replenishmentbutionTwo = true;
      this.replenishmentbutionThree = true;
      this.replenishmentbutionFour = true;
      let list = this.strategyList;
      for (let key of list) {
        if (key.strategyId == v) {
          this.strategyName = key.strategyName;
          this.transactionType = key.type;
        }
      }
      console.log(v, this.strategyName, this.transactionType);
      this.modeSelection = true;
      this.strategicDirection = false;
      if (
        v == "WL202007311426363424x" ||
        v == "WL202007311426363425x" ||
        v == "WL20210624182847743"
      ) {
        this.transferShow = true;
      } else {
        this.transferShow = false;
      }

      switch (v) {
        // 信号马丁
        case "WL202X0512112808681":
          this.modeIsShow = false;
          this.strategicDirection = false;
          this.strategicDirectionIsShow = false;
          this.contractTypeShow = false;
          this.lever = 3;
          this.selectPair();
          break;
        // 海风马丁
        case "WL20210512112808681":
          this.modeIsShow = true;
          this.strategicDirection = false;
          this.strategicDirectionIsShow = false;
          this.contractTypeShow = false;
          this.lever = 3;
          this.selectPair();
          break;
        // 马丁追踪
        case "WL20200717202249188x":
          this.modeIsShow = true;
          this.strategicDirection = false;
          this.strategicDirectionIsShow = false;
          this.contractTypeShow = false;
          this.lever = 3;
          this.selectPair();
          break;
        // 区间套利
        case "CL20181115155643599x":
          this.modeIsShow = true;
          this.modeIsShow = false;
          this.strategicDirection = false;
          this.strategicDirectionIsShow = false;
          this.contractTypeShow = false;
          this.lever = 3;
          this.selectPair();
          break;
        // 恒量定投
        case "WL20190402171936974x":
          this.modeIsShow = true;
          this.strategicDirection = false;
          this.strategicDirectionIsShow = false;
          this.contractTypeShow = false;
          this.lever = 3;
          this.selectPair();
          break;
        //合约网格
        case "WL202007311426363424x":
          this.modeIsShow = true;
          this.strategicDirection = false;
          this.strategicDirectionIsShow = false;
          this.contractTypeShow = true;
          this.contractType = 1;
          this.lever = 3;
          this.queryOrderChainContractPair();
          this.findByIdLeverMultiple();
          break;
        //合约马丁
        case "WL202007311426363425x":
          this.modeIsShow = true;
          this.strategicDirection = false;
          this.strategicDirectionIsShow = false;
          this.contractTypeShow = true;
          this.contractType = 1;
          this.lever = 3;
          this.queryOrderChainContractPair();
          this.findByIdLeverMultiple();
          break;
        //SMT合约
        case "WL20210624182847743":
          this.modeIsShow = true;
          this.strategicDirection = false;
          this.strategicDirectionIsShow = false;
          this.contractTypeShow = true;
          this.contractType = 1;
          this.lever = 3;
          this.queryOrderChainContractPair();
          this.findByIdLeverMultiple();
          break;
        default:
          this.modeIsShow = true;
          this.strategicDirection = false;
          this.strategicDirectionIsShow = true;
          this.contractTypeShow = false;
          this.contractType = 1;
          this.lever = 3;
          this.selectPair();
          break;
      }
      this.payloadCheck();
    },

    // 现货交易对列表查询
    selectPair() {
      if (!this.exchangeId) {
        this.$message.warning("请先选择交易所");
        return;
      }
      // if (!this.copyPairList) {
      let payload = {
        exchangeId: this.exchangeId,
        teamProjectId: this.project.teamProjectId,
        token: sessionStorage.getItem("token"),
      };
      getPairPageById(payload).then((res) => {

        if (res.status == 200) {
          

          let projectPairList = res.data.coinList;
          let allPairList = res.data.pairList;

          let combinationPairList = [];
          let newPairList = [];
          if (!projectPairList) {
            projectPairList = ["USDT", "BTC"];
          }

          // /////////USDT BTC ETH  ////////////
          for (var i = 0; i < projectPairList.length; i++) {
            for (var j = 0; j < projectPairList.length; j++) {
              let item = {};
              item.baseCoin = projectPairList[i];
              item.counterCoin = projectPairList[j];
              combinationPairList.push(item);
            }
          }

          for (var i = 0; i < allPairList.length; i++) {
            for (var j = 0; j < combinationPairList.length; j++) {
              if (
                allPairList[i].baseCoin == combinationPairList[j].baseCoin &&
                allPairList[i].counterCoin == combinationPairList[j].counterCoin
              ) {
                allPairList[i].increase = Number(allPairList[i].increase);
                allPairList[i].last = Number(allPairList[i].last);
                newPairList.push(allPairList[i]);
              }
            }
          }

          if (
            this.startAcc &&
            this.startAcc.length != 0 &&
            this.startAcc[0].coinType == "USDT"
          ) {
            let item =
              newPairList[0].counterCoin + "/" + newPairList[0].baseCoin;

            this.tagList = [];
            this.copyTagList = [];
            this.multipleSelection = [];
            this.copyMultipleSelection = [];

            this.tagList.push(item);
            this.copyTagList.push(item);
            this.multipleSelection.push(newPairList[0]);
            this.copyMultipleSelection.push(newPairList[0]);
          }

          for (let key of newPairList) {
            if (
              key.baseCoin == "USDT" &&
              key.counterCoin == "BTC" &&
              !this.startAcc
            ) {
              this.tagList = [];
              let item = key.counterCoin + "/" + key.baseCoin;
              this.tagList.push(item);
              this.copyTagList.push(item);
              this.multipleSelection.push(key);
              this.copyMultipleSelection.push(key);
              console.log(111111111111111);
            }

            if (this.startAcc && this.startAcc.length != 0) {
              if (
                this.startAcc[0].coinType &&
                key.counterCoin == this.startAcc[0].coinType &&
                key.baseCoin == this.startAcc[0].investCoin
              ) {
                let item = key.counterCoin + "/" + key.baseCoin;

                this.tagList = [];
                this.copyTagList = [];
                this.multipleSelection = [];
                this.copyMultipleSelection = [];

                this.tagList.push(item);
                this.copyTagList.push(item);
                this.multipleSelection.push(key);
                this.copyMultipleSelection.push(key);
              } else if (
                !this.startAcc[0].coinType &&
                key.counterCoin == "BTC" &&
                key.baseCoin == "USDT"
              ) {
                let item = key.counterCoin + "/" + key.baseCoin;

                this.tagList = [];
                this.copyTagList = [];
                this.multipleSelection = [];
                this.copyMultipleSelection = [];

                this.tagList.push(item);
                this.copyTagList.push(item);
                this.multipleSelection.push(key);
                this.copyMultipleSelection.push(key);
              } else if (
                this.startAcc &&
                this.startAcc.length != 0 &&
                this.startAcc[0].coinType == "USDT" &&
                key.counterCoin == "BTC" &&
                key.baseCoin == "USDT"
              ) {
                let item = key.counterCoin + "/" + key.baseCoin;

                this.tagList = [];
                this.copyTagList = [];
                this.multipleSelection = [];
                this.copyMultipleSelection = [];

                this.tagList.push(item);
                this.copyTagList.push(item);
                this.multipleSelection.push(key);
                this.copyMultipleSelection.push(key);
                console.log(1111111 + "111111111111");
              }
            }
          }
          
          if (this.tagList.length == 0) {
            this.tagList.push(
              newPairList[0].counterCoin + "/" + newPairList[0].baseCoin
            );
            this.copyTagList.push(
              newPairList[0].counterCoin + "/" + newPairList[0].baseCoin
            );
            this.multipleSelection.push(newPairList[0]);
            this.copyMultipleSelection.push(newPairList[0]);
          }

          let newPair = [];
          for (let info of newPairList) {
            if (info.baseCoin == "USDT") {
              newPair.push(info);
            }
          }
          this.pairList = JSON.parse(JSON.stringify(newPair));
          this.copyPairList = JSON.parse(JSON.stringify(newPairList));
          this.handleClick();


          if (this.pairCh) {
            if (this.copyStrategy.templatePattern == 0) {
              // 自定义
              for (let key of newPairList) {
                if (this.copyStrategy.tradePairs.pairId == key.id) {
                  this.tagList = [];
                  this.multipleSelection = [];
                  let item = key.counterCoin + "/" + key.baseCoin;
                  this.tagList.push(item);
                  this.copyTagList.push(item);
                  this.multipleSelection.push(key);
                  this.copyMultipleSelection.push(key);
                }
              }
            } else {
              // 多币对
              this.tagList = [];
              this.copyTagList = [];
              this.multipleSelection = [];
              this.copyMultipleSelection = [];

              for (let key of this.copyStrategy.pairs) {
                let item = key.counterCoin + "/" + key.baseCoin;
                this.tagList.push(item);
                this.copyTagList.push(item);
                this.multipleSelection.push(key);
                this.copyMultipleSelection.push(key);
              }
            }

            this.pairCh = false;
          }
          console.log(
            this.multipleSelection,
            this.copyMultipleSelection,
            this.copyTagList,
            this.tagList
          );

          this.payloadCheck();
          // this.getAvailableList();
          this.queryOperatorUserList();
          // this.selectPairShow = true;

          console.log(this.multipleSelection);
        } else {
          this.$message.error(res.msg);
        }
      });
      // }
      //  else {
      //   // this.pairList = JSON.parse(JSON.stringify(this.copyPairList));
      //   console.log(this.pairList);
      //   this.selectPairShow = true;
      // }
    },

    // 合约交易对查询
    queryOrderChainContractPair() {
      let payload = {
        teamProjectId: this.project.teamProjectId,
        exchangeId: this.exchangeId,
        token: sessionStorage.getItem("token"),
      };

      queryOrderChainContractPair(payload).then((res) => {
        if (res.status == 200) {
          console.log(res.data);
          let list = res.data.resultMap;

          this.tagList = [];
          this.copyTagList = [];
          this.multipleSelection = [];
          this.copyMultipleSelection = [];
          this.contractId = list[0].contractId;
          this.contractSize = list[0].contractSize;
          this.tagList.push(
            list[0].counterCoin + "/" + list[0].baseCoin + " 永续"
          );
          this.copyTagList.push(list[0].contractId);
          this.multipleSelection.push(list[0]);
          this.copyMultipleSelection.push(list[0]);

          this.pairList = JSON.parse(JSON.stringify(list));
          this.copyPairList = JSON.parse(JSON.stringify(list));
          this.handleClick();
          this.queryOperatorUserList();

          console.log(this.pairList);
          this.payloadCheck();
        } else {
          this.$message.error(res.msg);
        }
      });
    },

    // 查询合约杠杆
    findByIdLeverMultiple(v) {
      let params = {
        exchangeId: v ? v : this.exchangeId,
        token: sessionStorage.getItem("token"),
      };
      findByIdLeverMultiple(params).then((res) => {
        if (res.status == 200) {
          console.log(res.data);
          let list = res.data.exchangeMap.leverMultiple;
          this.leverList = JSON.parse(list);
        } else {
          this.$message.error(res.msg);
        }
      });
    },

    // 显示交易对选择弹窗
    pairShow() {
      if (
        this.startAcc &&
        this.startAcc.length != 0 &&
        this.startAcc[0].coinType
      ) {
        return;
      }
      this.selectPairShow = true;
    },

    // 勾选
    handleSelectionChange(val) {
      console.log(val);
      console.log(this.transactionType);
      this.multipleSelection = val;
    },

    // 取消选择交易对
    selectPairCancel() {
      this.copyTagList = JSON.parse(JSON.stringify(this.copyTagList));
      console.log(this.multipleSelection);
      console.log(this.tagList, this.copyTagList);
      this.selectPairShow = false;
      if (this.$refs.multipleTable) {
        this.$refs.multipleTable.clearSelection();
      }

      if (this.multipleSelection.length == 0) {
        this.multipleSelection = JSON.parse(
          JSON.stringify(this.copyMultipleSelection)
        );
      }
      console.log(this.multipleSelection, this.copyMultipleSelection);
    },

    // 确定选择交易对
    selectPairConfirm() {

      if (this.transactionType == "spot") {
        if (this.modeSelection && this.multipleSelection.length > 1) {
          this.$message.warning("自定义模式只支持选择一个交易对");
          this.multipleSelection = JSON.parse(
            JSON.stringify(this.copyMultipleSelection)
          );
          return;
        } else if (!this.modeSelection && this.multipleSelection.length > 20) {
          this.$message.warning("多币对模式最多支持20个交易对");
          return;
        } else {
          console.log(this.multipleSelection);
          this.copyMultipleSelection = JSON.parse(
            JSON.stringify(this.multipleSelection)
          );
        }

        if (this.multipleSelection.length == 0) {
          let list = JSON.parse(JSON.stringify(this.copyPairList));
          for (let key of list) {
            if (key.baseCoin == "USDT" && key.counterCoin == "BTC") {
              let item = key.counterCoin + "/" + key.baseCoin;
              console.log(item);
              this.tagList = [];
              this.copyTagList = [];
              this.tagList.push(item);
              this.copyTagList.push(item);
              this.multipleSelection.push(key);
              this.copyMultipleSelection = JSON.parse(
                JSON.stringify(this.multipleSelection)
              );
            }
          }
        }

        console.log(22222222);

        let list = this.multipleSelection;
        let tagList = [];
        for (let key of list) {
          let item = null;
          item = key.counterCoin + "/" + key.baseCoin;
          tagList.push(item);
          if (tagList.length > 3) {
            break;
          }
        }

        if (list.length > 4) {
          let num = "+" + (list.length - 4);
          tagList.push(num);
        }

        this.copyTagList = JSON.parse(JSON.stringify(tagList));

        this.tagList = JSON.parse(JSON.stringify(tagList));
        this.selectPairShow = false;

        if (this.strategicDirection) {
          this.currency = this.multipleSelection[0].counterCoin;
        } else {
          this.currency = this.multipleSelection[0].baseCoin;
        }
      } else {
        if (this.multipleSelection.length > 1) {
          this.$message.warning("合约只支持选择一个交易对");
          this.multipleSelection = JSON.parse(
            JSON.stringify(this.copyMultipleSelection)
          );
          return;
        } else {
          this.copyMultipleSelection = JSON.parse(
            JSON.stringify(this.multipleSelection)
          );
        }

        if (this.multipleSelection.length == 0) {
          let list = JSON.parse(JSON.stringify(this.copyPairList));

          this.tagList = [];
          this.copyTagList = [];
          this.tagList.push(list[0].contractId);
          this.copyTagList.push(list[0].contractId);
          this.multipleSelection.push(list[0]);
          this.copyMultipleSelection = JSON.parse(
            JSON.stringify(this.multipleSelection)
          );
        } else {
          this.tagList = [];
          this.copyTagList = [];
          this.contractSize = this.multipleSelection[0].contractSize;
          this.contractId = this.multipleSelection[0].contractId;
          this.tagList.push(
            this.multipleSelection[0].counterCoin +
              "/" +
              this.multipleSelection[0].baseCoin +
              " 永续"
          );
          this.copyTagList.push(this.multipleSelection[0].contractId);
          this.copyMultipleSelection = JSON.parse(
            JSON.stringify(this.multipleSelection)
          );
        }

        this.selectPairShow = false;
        console.log(this.multipleSelection, this.copyMultipleSelection);
      }

      this.copyStrategyName = this.strategyName;
      this.copyreserves = this.reserves;

      this.payloadCheck();
      // this.getAvailableList();
      this.queryOperatorUserList();
    },

    //tag 不可点击删除
    removeTag(v) {
      // if(this.modeSelection){
      //   this.tagList = JSON.parse(JSON.stringify(this.copyTagList));
      //   this.$message.warning('自定义模式至少选择1个交易对')
      // }else{
      // }
      // console.log(v);
    },

    // 查询策略配置校验
    payloadCheck() {
      console.log(this.strategyId, this.exchangeName, this.multipleSelection);
      if (!this.strategyId) {
        return;
      } else if (!this.exchangeName) {
        return;
      } else if (this.multipleSelection.length == 0) {
        return;
      }
      this.getStartPage();
    },

    // 查询策略配置
    getStartPage() {
      let payload = {
        token: sessionStorage.getItem("token"),
        strategyId: this.strategyId,
        model: this.modeSelection ? "define" : "manyPair",
        investType: this.strategicDirection,
        exchangeName: this.exchangeName,
        counterCoin: this.multipleSelection[0].counterCoin,
        baseCoin: this.multipleSelection[0].baseCoin,
      };
      if (this.strategyId == "CL20181115155643599x") {
        payload.investType = false;
      }
      console.log(payload);

      getStartPage(payload).then((res) => {
        if (res.status == 200) {
          // this.exchangeList = res.data.exchangeList;
          let configMap = JSON.parse(res.data.configMap);

          // 复制模板
          if (this.reservesCh) {
            configMap.reserves = JSON.parse(
              JSON.stringify(this.copyStrategy.reserves)
            );

            // 恒量定投
            if (this.copyStrategy.strategyId == "WL20190402171936974x") {
              this.fixedTime = [
                configMap.reserves[1].value,
                configMap.reserves[0].value,
              ];
            }

            // 海风马丁

            if (this.copyStrategy.strategyId == "WL20210512112808681") {
              for (let key of configMap.reserves) {
                if (key.name == "补仓金额模式") {
                  this.replenishment = key.value == 0 ? true : false; //海风补仓模式
                } else if (key.name == "补仓间隔模式") {
                  this.intervalMode = key.value == 0 ? true : false; //海风补仓模式
                }
              }
            }

            // 信号马丁
            if (this.copyStrategy.strategyId == "WL202X0512112808681") {
              for (let key of configMap.reserves) {
                if (key.name == "补仓金额模式") {
                  this.replenishment = key.value == 0 ? true : false; //海风补仓模式
                } else if (key.name == "补仓间隔模式") {
                  this.intervalMode = key.value == 0 ? true : false; //海风补仓模式
                }
              }
            }

            // 合约网格

            if (this.copyStrategy.strategyId == "WL202007311426363424x") {
              for (let key of configMap.reserves) {
                if (key.name == "网格间隔(等比)") {
                  this.gridDistribution = key.value == 0 ? false : true;
                }
              }
            }

            // SMT 合约
            if (this.copyStrategy.strategyId == "WL20210624182847743") {
              for (let key of configMap.reserves) {
                if (key.name == "补仓金额模式") {
                  this.replenishmentbutionOne = key.value == 0 ? true : false;
                } else if (key.name == "补仓下单模式") {
                  this.replenishmentbutionTwo = key.value == 0 ? true : false;
                } else if (key.name == "止盈平仓模式") {
                  this.replenishmentbutionThree = key.value == 0 ? true : false;
                } else if (key.name == "止盈下单模式") {
                  this.replenishmentbutionFour = key.value == 0 ? true : false;
                }
              }
            }

            this.reservesCh = false;
            this.startOn = true;
          }

          if (this.strategyId == "WL20210624182847743") {
            configMap.reserves.forEach((e) => {
              if (e.name == "补仓金额增量") {
                e.visiable = 0;
              }
            });
          }

          this.configMap = configMap; //策略配置
          this.limitMap = res.data.limitMap; //投入范围
          this.pairMap = res.data.pairMap; //策略交易对信息
          this.reserves = JSON.parse(JSON.stringify(this.configMap.reserves)); //策略参数
          this.copyReserves = JSON.parse(
            JSON.stringify(this.configMap.reserves)
          ); //备份参数
          // this.transactionType=this.configMap.basic.type

          // let copyStrategyName=this.strategyName
          // let copyreserves=this.reserves

          if (this.copyStrategyName == this.strategyName) {
            this.reserves = JSON.parse(JSON.stringify(this.copyreserves));
            console.log("22222222222222222", this.copyreserves, this.reserves);
          }

          if (this.strategyId == "WL20210512112808681") {
            for (let key of this.reserves) {
              if (key.name == "首单投入") {
                key.value = this.calculation(
                  this.currency,
                  this.pairMap,
                  key.value
                );
              }
            }
          } else if (this.strategyId == "CL20181115155003478x") {
            for (let key of this.reserves) {
              if (key.name == "单格投入") {
                key.value = this.calculation(
                  this.currency,
                  this.pairMap,
                  key.value
                );
              }
            }
          }
          
          if (this.strategyId == "WL202X0512112808681") {
            this.querySignalList();
          }
        } else {
          this.$message.error(res.msg);
        }
      });
    },

    // 兑U计算
    calculation(currency, pairMap, val) {
      if (!pairMap) {
        return;
      }
      var number = null;
      if (currency == pairMap.baseCoin) {
        number = val / (Number(pairMap.usdPrice) / Number(pairMap.last));
      } else {
        number = Number(val) / Number(pairMap.usdPrice);
      }
      return Math.floor(number * 1000000) / 1000000;
    },

    // 查询资金账户
    getAvailableList() {
      if (!this.startAcc) {
        let payload = {
          token: sessionStorage.getItem("token"),
          teamProjectId: this.project.teamProjectId,
          errorInfo: false,
          exchangeName: this.exchangeName,
          investCoin: this.currency,
        };

        getAvailableList(payload).then((res) => {
          if (res.status == 200) {
            console.log("账户列表", res.data.availableList);

            let list = JSON.parse(JSON.stringify(res.data.availableList));
            this.copyAccList = JSON.parse(
              JSON.stringify(res.data.availableList)
            );

            this.accList = list;

            console.log(this.accList);
          } else {
            this.$message.error(res.msg);
          }
        });
      } else {
        let payload = {
          token: sessionStorage.getItem("token"),
          teamProjectId: this.project.teamProjectId,
          errorInfo: false,
          exchangeName: this.exchangeName,
          investCoin: this.currency,
        };

        console.log(payload);
        getAvailableList(payload).then((res) => {
          if (res.status == 200) {
            console.log("账户列表", res.data.availableList);

            let allList = JSON.parse(JSON.stringify(res.data.availableList));
            // this.copyAccList = JSON.parse(
            //   JSON.stringify(res.data.availableList)
            // );
            // this.accList = list;
            // console.log(this.accList);

            this.accList = [];
            let list = this.startAcc;
            let apiId = [];
            for (let key of list) {
              apiId.push(key.apiId);
            }
            let newApiList = [];
            for (let i = 0; i < allList.length; i++) {
              for (let key of apiId) {
                if (allList[i].apiId == key) {
                  newApiList.push(allList[i]);
                }
              }
            }
            this.copyAccList = JSON.parse(JSON.stringify(newApiList));
            this.accList = JSON.parse(JSON.stringify(newApiList));
            console.log(apiId);
          } else {
            this.$message.error(res.msg);
          }
        });
      }

      // else {
      //   this.accList = [];
      //   let list = this.startAcc;
      //   let apiId = [];
      //   for (let key of list) {
      //     apiId.push(key.apiId);
      //   }
      //   let params = {
      //     token: sessionStorage.getItem("token"),
      //     investCoin: this.currency,
      //     apiId: apiId.join(","),
      //   };
      //   console.log(params);

      //   findByapiIdCoin(params).then((res) => {
      //     if (res.status == 200) {
      //       console.log(res.data);
      //       this.copyAccList = JSON.parse(JSON.stringify(res.data.map));
      //       this.accList = JSON.parse(JSON.stringify(res.data.map));
      //     }
      //   });
      //   console.log(this.accList);
      // }
    },

    // 查询账户
    queryOperatorUserList() {
      if (!this.startAcc) {
        let payload = {
          token: sessionStorage.getItem("token"),
          exchangeName: this.exchangeName,
          teamProjectId: this.project.teamProjectId,
          transactionType:
            this.transactionType == "spot" ? "spot" : "contract_usdt",
        };

        if (this.transactionType == "spot") {
          payload.investCoin = this.currency;
        } else {
          payload.contractId = this.contractId;
        }

        queryOperatorUserList(payload).then((res) => {
          if (res.status == 200) {

            let list = JSON.parse(JSON.stringify(res.data.operatorUserList));
            list.forEach((e) => {
              if (Number(e.available) >= this.investment) {
              } else {
                e.show = true;
              }
            });
            if (list) {
              this.copyAccList = JSON.parse(
                JSON.stringify(res.data.operatorUserList)
              );
            } else {
              this.copyAccList = [];
            }
            this.accList = list ? list : [];
            console.log(this.accList);
          } else {
            this.$message.error(res.msg);
          }
        });
      } else {
        let payload = {
          token: sessionStorage.getItem("token"),
          exchangeName: this.exchangeName,
          teamProjectId: this.project.teamProjectId,
          transactionType:
            this.transactionType == "spot" ? "spot" : "contract_usdt",
        };

        if (this.transactionType == "spot") {
          payload.investCoin = this.currency;
        } else {
          payload.contractId = this.contractId;
        }

        console.log(payload);
        queryOperatorUserList(payload).then((res) => {
          if (res.status == 200) {
            console.log("账户列表", res.data.operatorUserList);

            let allList = JSON.parse(JSON.stringify(res.data.operatorUserList));

            this.accList = [];
            let list = this.startAcc;
            let apiId = [];
            for (let key of list) {
              apiId.push(key.apiId);
            }
            let newApiList = [];
            for (let i = 0; i < allList.length; i++) {
              for (let key of apiId) {
                if (allList[i].apiId == key) {
                  newApiList.push(allList[i]);
                }
              }
            }
            this.copyAccList = JSON.parse(JSON.stringify(newApiList));
            this.accList = JSON.parse(JSON.stringify(newApiList));
            console.log(apiId);
          } else {
            this.$message.error(res.msg);
          }
        });
      }
    },

    // 选择账户触发
    accChange(v) {
      console.log(v);
      let list = this.accList;
      let newList = [];
      for (let key of list) {
        if (key.isCheck) {
          newList.push(key);
        }
      }

      this.selectAccList = newList;
      console.log(this.selectAccList);
    },

    // 合约等比等差切换
    gridDistributionSelect(v) {
      this.gridDistribution = v;
      if (v) {
        for (let key of this.reserves) {
          if (key.name == "网格间隔(等差)") {
            key.value = 0;
          }
        }
      } else {
        for (let key of this.reserves) {
          if (key.name == "网格间隔(等比)") {
            key.value = 0;
          }
        }
      }
    },

    replenishmentbutionSelect(v, item) {
      if (item == "一次性止盈" || item == "逐单止盈") {
        this.replenishmentbutionThree = !this.replenishmentbutionThree;
      }
      if (v) {
        for (let e of this.reserves) {
          if (item == "倍数补仓") {
            if (e.name == "补仓金额增量") {
              e.visiable = 0;
            }
            if (e.name == "补仓金额倍数" || e.name == "倍投起始单") {
              e.visiable = 1;
            }
            this.replenishmentbutionOne = true;
          } else if (item == "追踪补仓") {
            if (e.name == "补仓反弹比例") {
              e.visiable = 1;
            }
            this.replenishmentbutionTwo = true;
          } else if (item == "追踪止盈") {
            if (e.name == "止盈回撤比例") {
              e.visiable = 1;
            }
            this.replenishmentbutionFour = true;
          }
        }
      } else {
        for (let e of this.reserves) {
          if (item == "增量补仓") {
            if (e.name == "补仓金额增量") {
              e.visiable = 1;
            }
            if (e.name == "补仓金额倍数" || e.name == "倍投起始单") {
              e.visiable = 0;
            }

            this.replenishmentbutionOne = false;
          } else if (item == "预埋单补仓") {
            if (e.name == "补仓反弹比例") {
              e.visiable = 0;
            }
            this.replenishmentbutionTwo = false;
          } else if (item == "预埋单止盈") {
            if (e.name == "止盈回撤比例") {
              e.visiable = 0;
            }
            this.replenishmentbutionFour = false;
          }
        }
      }
    },

    // 参数校验
    parameterVerification(e, item) {
      let value = Number(item.value);
      let currency = this.currency;
      let pairMap = this.pairMap;
      let min = null;
      let max = null;

      if (item.valueMin && item.valueMin != "0") {
        min = this.calculation(currency, pairMap, Number(item.valueMin));
      } else {
        min = Number(item.start);
      }

      if (item.valueMax && item.valueMax != "0") {
        max = this.calculation(currency, pairMap, Number(item.valueMax));
      } else {
        max = Number(item.end);
      }

      if (!value && value != 0) {
        this.$message.error(this.$t('m.参数不能为空'));
        e.target.style.border = "1px solid #F23A4C";
        if (this.parameterCheckList.indexOf(item.name) > -1) {
          return;
        } else {
          this.parameterCheckList.push(item.name);
        }
        return;
      } else if (
        min > value &&
        item.name != "价格上限" &&
        item.name != "价格下限" &&
        item.name != "网格间隔(等差)"
      ) {
        console.log(value, min, item);
        this.$message.error(this.$t('m.参数不能小于最小范围'));
        e.target.style.border = "1px solid #F23A4C";
        if (this.parameterCheckList.indexOf(item.name) > -1) {
          return;
        } else {
          this.parameterCheckList.push(item.name);
        }
        return;
      } else if (
        max < value &&
        item.name != "价格上限" &&
        item.name != "价格下限" &&
        item.name != "网格间隔(等差)"
      ) {
        console.log(value, max);
        this.$message.error(this.$t('m.参数不能大于最大范围'));
        e.target.style.border = "1px solid #F23A4C";
        if (this.parameterCheckList.indexOf(item.name) > -1) {
          return;
        } else {
          this.parameterCheckList.push(item.name);
        }
        return;
      } else if (item.name == "网格间隔(等差)" && item.value == 0) {
        this.$message.error(this.$t('m.网格间隔(等差)不能为0'));
        e.target.style.border = "1px solid #F23A4C";
        if (this.parameterCheckList.indexOf(item.name) > -1) {
          return;
        } else {
          this.parameterCheckList.push(item.name);
        }
      } else {
        if (this.parameterCheckList.indexOf(item.name) > -1) {
          this.parameterCheckList.splice(
            this.parameterCheckList.indexOf(item.name),
            1
          );
        }
        e.target.style.border = "1px solid #C0C4CC";
      }
      console.log(item.value);
      console.log(item);
    },

    // 参数二次校验
    secondaryVerification() {
      let reserves = this.reserves;
      for (let key of reserves) {
        if (key.name == "单格投入" && key.value == 0) {
          if (this.parameterCheckList.indexOf(key.name) > -1) {
            return;
          } else {
            this.parameterCheckList.push(key.name);
          }
          return;
        } else if (
          key.name == "首单投入" &&
          (key.value == "" || key.value == 0)
        ) {
          if (this.parameterCheckList.indexOf(key.name) > -1) {
            return;
          } else {
            this.parameterCheckList.push(key.name);
          }
          return;
        } else if (
          key.name == "首单金额" &&
          (key.value == "" || key.value == 0)
        ) {
          if (this.parameterCheckList.indexOf(key.name) > -1) {
            return;
          } else {
            this.parameterCheckList.push(key.name);
          }
          return;
        } else if (
          key.name == "单笔金额" &&
          (key.value == "" || key.value == 0)
        ) {
          if (this.parameterCheckList.indexOf(key.name) > -1) {
            return;
          } else {
            this.parameterCheckList.push(key.name);
          }
          return;
        } else if (
          key.name == "买入价格" &&
          (key.value == "" || key.value == 0)
        ) {
          if (this.parameterCheckList.indexOf(key.name) > -1) {
            return;
          } else {
            this.parameterCheckList.push(key.name);
          }
          return;
        } else if (
          key.name == "卖出价格" &&
          (key.value == "" || key.value == 0)
        ) {
          if (this.parameterCheckList.indexOf(key.name) > -1) {
            return;
          } else {
            this.parameterCheckList.push(key.name);
          }
          return;
        } else {
          if (this.parameterCheckList.indexOf(key.name) > -1) {
            console.log(key.name);
            this.parameterCheckList.splice(
              this.parameterCheckList.indexOf(key.name),
              1
            );
          }
        }
      }
    },
    // 投入校验
    investmentCheck(e) {
      this.selectAccList = [];
      let min = null;
      let max = null;
      if (this.investmentType == "固定投入") {
        min = this.calculation(
          this.currency,
          this.pairMap,
          Number(this.limitMap.minInvest)
        );
        max = this.calculation(
          this.currency,
          this.pairMap,
          Number(this.limitMap.maxInvest)
        );

        let list = this.copyAccList;
        list.forEach((e) => {
          if (Number(e.available) >= this.investment) {
          } else {
            e.show = true;
          }
        });
        console.log(list);
        this.accList = list;
      } else {
        min = 0;
        max = 100;

        if (this.investment) {
          this.checkAcc();
        }
      }

      console.log(this.selectAccList);
      let investment = Number(this.investment);
      if (investment < min) {
        this.$message.error(this.$t('m.投入不能小于最小投入范围'));
        e.target.style.border = "1px solid #F23A4C";
        this.investmentIsTrue = false;
        return;
      } else if (investment > max) {
        this.$message.error(this.$t('m.投入不能大于最大投入范围'));
        e.target.style.border = "1px solid #F23A4C";
        this.investmentIsTrue = false;
        return;
      } else {
        this.investmentIsTrue = true;
        e.target.style.border = "1px solid #C0C4CC";
      }
    },

    // 策略阈值校验
    openingThresholdChange(v) {
      console.log(v);
      if (v < 0 || !v) {
        if (!v) {
          this.$message.error(this.$t('m.策略开仓阈值不能为空'));
        } else {
          this.$message.error(this.$t('m.策略开仓阈值不能小于0'));
        }
        if (this.parameterCheckList.indexOf("开仓阈值") > -1) {
          return;
        } else {
          this.parameterCheckList.push("开仓阈值");
        }
        return;
      } else {
        if (this.parameterCheckList.indexOf("开仓阈值") > -1) {
          this.parameterCheckList.splice(
            this.parameterCheckList.indexOf("开仓阈值"),
            1
          );
        }
      }
    },

    // 比例范围校验
    proportionalCheck(e, v, type) {
      let min = this.calculation(
        this.currency,
        this.pairMap,
        Number(this.limitMap.minInvest)
      );
      let max = this.calculation(
        this.currency,
        this.pairMap,
        Number(this.limitMap.maxInvest)
      );

      if (type == "max" && v && Number(v) > max) {
        this.$message.error(this.$t('m.资金上限不能大于投入最大范围'));
        e.target.style.border = "1px solid #F23A4C";
        this.proportionalIsOk = false;
        console.log(type, v, max);
        return;
      } else if (type == "max" && v && Number(v) < min) {
        this.$message.error(this.$t('m.资金上限不能小于投入最小范围'));
        e.target.style.border = "1px solid #F23A4C";
        this.proportionalIsOk = false;
        console.log(type, v, max);
        return;
      } else if (type == "min" && v && Number(v) < min) {
        this.$message.error(this.$t('m.资金下限不能小于投入最小范围'));
        e.target.style.border = "1px solid #F23A4C";
        this.proportionalIsOk = false;
        console.log(type, v, max);
        return;
      } else if (type == "min" && v && Number(v) > max) {
        this.$message.error(this.$t('m.资金下限不能大于投入最大范围'));
        e.target.style.border = "1px solid #F23A4C";
        this.proportionalIsOk = false;
        console.log(type, v, max);
        return;
      } else if (type == "min" && v && this.max && Number(v) > this.max) {
        this.$message.error(this.$t('m.资金下限不能大于资金上限'));
        this.proportionalIsOk = false;
        return;
      } else if (type == "max" && v && this.min && Number(v) < this.min) {
        this.$message.error(this.$t('m.资金上限不能小于资金下限'));
        this.proportionalIsOk = false;
        return;
      } else {
        e.target.style.border = "1px solid #C0C4CC";
        this.proportionalIsOk = true;
      }

      if (this.investment && this.min) {
        this.checkAcc();
      }
    },

    // 计算不同比例可用账户
    checkAcc() {
      console.log(1, this.capitalProportionType);

      let list = this.copyAccList;
      let newList = [];
      let min = this.min ? this.min : 0;
      let max = this.max
        ? this.max
        : this.calculation(
            this.currency,
            this.pairMap,
            Number(this.limitMap.maxInvest)
          );

      if (this.capitalProportionType == "账户可用资金") {
        for (let key of list) {
          if (
            (Number(key.available) * this.investment) / 100 >= min &&
            (Number(key.available) * this.investment) / 100 <= max &&
            (Number(key.available) * this.investment) / 100 >
              this.calculation(
                this.currency,
                this.pairMap,
                Number(this.limitMap.minInvest)
              )
          ) {
            newList.push(key);
            console.log(newList);
          }
        }
      }

      // else {
      //   console.log(2);
      //   for (let key of list) {
      //     console.log(
      //       (Number(key.investCoinNum) * this.investment) / 100,
      //       (Number(key.investCoinNum) * this.investment) / 100,
      //       min,
      //       max
      //     );
      //     if (
      //       Number(key.availableNum) >=
      //         (Number(key.investCoinNum) * this.investment) / 100 &&
      //       (Number(key.investCoinNum) * this.investment) / 100 >= min &&
      //       (Number(key.investCoinNum) * this.investment) / 100 <= max
      //     ) {
      //       newList.push(key);
      //     }
      //   }
      // }
      this.accList = newList;
    },

    // 创建策略
    strategyStart() {
      this.secondaryVerification();
      if (!this.strategyId) {
        return;
      } else if (!this.exchangeId) {
        return;
      } else if (this.multipleSelection.length == 0) {
        return;
      } else if (!this.investment) {
        this.$message.error(this.$t('m.请先输入策略投入'));
        return;
      } else if (this.parameterCheckList.length !== 0) {
        this.$message.error(this.$t('m.参数不正确，请检查修改'));
        return;
      } else if (!this.investmentIsTrue) {
        this.$message.error(this.$t('m.投入不正确，请检查修改'));
        return;
      } else if (this.stopLoss != "" && Number(this.stopLoss) < 0) {
        this.$message.error(this.$t('m.止损参数不能小于0%'));
        return;
      } else if (this.stopLoss != "" && Number(this.stopLoss) > 100) {
        this.$message.error(this.$t('m.止损参数不能大于100%'));
        return;
      } else if (this.stopSurplus != "" && Number(this.stopSurplus) < 0) {
        this.$message.error(this.$t('m.止赢参数不能小于0%'));
        return;
      } else if (!this.labelData.label) {
        this.$message.error(this.$t('m.策略模板名称不能为空'));
        return;
      }

      if (this.strategyId == "WL202X0512112808681") {
        for (let key of this.reserves) {
          if (
            (key.id == "reserve_52" ||
              key.id == "reserve_53" ||
              key.id == "reserve_54") &&
            !key.signalValue
          ) {
            this.$message.error(key.name + "阈值不能为空");
            return;
          }
          // else if (
          //   (key.id == "reserve_52" ||
          //     key.id == "reserve_53" ||
          //     key.id == "reserve_54") &&
          //   Number(key.signalValue) <= 0
          // ) {
          //   this.$message.error(key.name + "阈值不能小于0");
          //   return;
          // }
          if (
            key.id == "reserve_52" ||
            key.id == "reserve_53" ||
            key.id == "reserve_54"
          ) {
            let item = {};
            item.signalId = key.signal.signalId;
            item.value = Number(key.signalValue);
            item.attrName = key.paramBodyValue;
            switch (key.ops) {
              case "=":
                item.opt = "eq";
                break;
              case ">":
                item.opt = "gt";
                break;
              case "<":
                item.opt = "lt";
                break;
              case "≦":
                item.opt = "le";
                break;
              case "≥":
                item.opt = "ge";
                break;
              default:
                break;
            }

            key.value = JSON.stringify(item);

            // "value": "{\"opt\":\"eq\",\"signalId\":\"d8aa6c8b8b874e31bd201e9f5510ac43\",\"value\":\"2\",\"attrName\":\"long\"}"
          }
        }
        console.log(this.reserves);
      }

      console.log(this.selectAccList);

      let list = this.selectAccList;
      let ids = [];
      for (let key of list) {
        ids.push(key.id + "");
      }

      let pairMap = this.pairMap;
      pairMap.id = this.configMap.tradePairs[0].id;
      pairMap.exchange = pairMap.exchangeName;
      pairMap.investCoin = this.currency;

      if (this.transactionType != "spot") {
        pairMap.investType = this.contractType;
        pairMap.invest = this.lever;
        pairMap.type = "contract_usdt";
        pairMap.leverRate = this.lever;
        pairMap.contractId = this.contractId;
        pairMap.name = "合约账户";
        pairMap.contractSize = this.contractSize;
        pairMap.isMimic = false;
        pairMap.openThreshold = this.openingThreshold / 100;
      } else {
        pairMap.transactionPair =
          this.pairMap.counterCoin + "/" + this.pairMap.baseCoin;
        pairMap.type = "spot";
        pairMap.investType = this.strategicDirection ? 1 : 0;
      }

      if (this.strategyId == "WL20190402171936974x") {
        this.reserves[1].value = this.fixedTime[0];
        this.reserves[0].value = this.fixedTime[1];
      } else if (
        this.strategyId == "WL20210512112808681" ||
        this.strategyId == "WL202X0512112808681"
      ) {
        let list = JSON.parse(JSON.stringify(this.reserves));
        for (let key of list) {
          if (key.name == "补仓金额模式") {
            key.value = this.replenishment ? 0 : 1;
          } else if (key.name == "补仓间隔模式") {
            key.value = this.intervalMode ? 0 : 1;
          }
        }
        this.reserves = list;
      } else if (this.strategyId == "WL202007311426363424x") {
        let list = JSON.parse(JSON.stringify(this.reserves));
        for (let key of list) {
          if (key.id == "reserve_12" && this.gridDistribution) {
            key.value = 0;
          } else if (key.id == "reserve_11" && !this.gridDistribution) {
            key.value = 0;
          } else if (key.id == "reserve_17") {
            key.value = this.contractType;
          }
        }
        this.reserves = list;
      } else if (this.strategyId == "WL202007311426363425x") {
        let list = JSON.parse(JSON.stringify(this.reserves));
        for (let key of list) {
          if (key.id == "reserve_16") {
            key.value = this.contractType;
          }
        }
        this.reserves = list;
      } else if (this.strategyId == "WL20210624182847743") {
        let list = JSON.parse(JSON.stringify(this.reserves));
        for (let key of list) {
          if (key.name == "补仓下单模式") {
            console.log(key);
            key.value = this.replenishmentbutionTwo ? 0 : 1;
          } else if (key.name == "止盈平仓模式") {
            key.value = this.replenishmentbutionThree ? 0 : 1;
          } else if (key.name == "止盈下单模式") {
            key.value = this.replenishmentbutionFour ? 0 : 1;
          } else if (key.name == "补仓金额模式") {
            key.value = this.replenishmentbutionOne ? 0 : 1;
          } else if (key.id == "reserve_33") {
            key.value = this.contractType;
          }
        }
        this.reserves = list;
      }

      // if(this.strategyId=='WL202007311426363424x')

      console.log(this.timedTermination, this.stopLoss, this.stopSurplus);

      let payload = {
        token: sessionStorage.getItem("token"),
        templateName: this.labelData.label,
        teamProjectId: this.project.teamProjectId,
        strategyId: this.strategyId,
        strategyName: this.strategyName,
        ids: ids.length != 0 ? ids : null,
        transactionType: this.transactionType,
        contractId: this.contractId,
        config: {
          isConvert: true,
          cancelOrder: true,
          stopLoss:
            this.stopLoss != ""
              ? (Number(this.stopLoss) / 100) * -1 + ""
              : this.stopLoss, //止损
          stopTime:
            this.timedTermination != ""
              ? new Date(this.timedTermination).getTime() + ""
              : this.timedTermination, //定时终止
          stopProfit:
            this.stopSurplus != ""
              ? Number(this.stopSurplus) / 100 + ""
              : this.stopSurplus, //止盈
          basic: this.configMap.basic,
          tradePairs: [pairMap], //数组格式
          reserves: this.reserves, //策略参数
          // pairs:pairs
        },

        investConfig: {
          investCoin: this.currency,
          fixInvest: this.investmentType == "固定投入" ? this.investment : null, //固定投入数量
          proportionInvest: {
            proportion:
              this.investmentType == "比例投入" ? this.investment : null, //比例投入数量
            maxInvest: this.max ? this.max : this.limitMap.maxInvest,
            minInvest: this.min ? this.min : this.limitMap.minInvest,
          },
        },
      };
      let pairs = [];
      if (!this.modeSelection) {
        let list = this.multipleSelection;
        for (let key of list) {
          let item = {};
          item.baseCoin = key.baseCoin;
          item.counterCoin = key.counterCoin;
          pairs.push(item);
        }

        payload.config.pairs = pairs;
        // console.log(this.multipleSelection)
      }
      console.log(payload);

      // console.log(this.fixedTime);
      addStrategyTemplate(payload).then((res) => {
        if (res.status == 200) {
          this.$message.success(this.$t('m.操作成功'));
          sessionStorage.setItem("projectActive", "1-2");
          this.$emit("childFn", "1-2");
          console.log(res.data);
        } else {
          this.$message.error(res.msg);
        }
      });
    },

    // 是否传递账户
    isAcc() {
      let startAcc = JSON.parse(sessionStorage.getItem("startAcc"));
      if (startAcc) {
        this.startAcc = startAcc;
        // this.exchangeName = startAcc.exchangeName;
        if (startAcc[0].contract) {
          this.exchangeId = startAcc[0].exchangeId;
          this.transactionType = "contract_usdt";
          // this.strategyLibrary();
          this.selectStrategy("WL202007311426363424x");
        }
      }
      console.log(this.startAcc);
    },

    //查询信号列表
    querySignalList() {
      let payload = {
        token: sessionStorage.getItem("token"),
        teamId: JSON.parse(sessionStorage.getItem("defaultTeam")).teamId,
        isTotal: true,
        // this.project.teamProjectId
      };
      querySignalList(payload).then((res) => {
        if (res.status == 200) {
          console.log("信号列表", res.data.signalList);
          this.signalList = res.data.signalList;
          console.log(this.reserves);

          if (!this.signalCh) {
            for (let key of this.reserves) {
              if (key.valueType == "signal") {
                key.signal = this.signalList[0];
                key.signalName = key.signal.signalName;
                key.paramBody = JSON.parse(key.signal.paramBody);
                key.paramBodyValue = key.paramBody[0].name;
                key.paramBodyLabel = key.paramBody[0].label;
                key.ops = "=";
              }
            }
          }

          this.signalCh = false;
        }
      });
    },

    // 信号选择
    signalChange(v, item) {
      console.log(v, item);
      item.signalName = v.signalName;
      item.signal = v;
      item.paramBody = JSON.parse(v.paramBody);
      console.log(item);
      item.paramBodyValue = item.paramBody[0].name;
      item.paramBodyLabel = item.paramBody[0].label;
      item.ops = "=";
      console.log(item);
      this.$forceUpdate();
    },

    // 信号参数选择
    paramBodyValue(v, item) {
      // item.paramBody = v;
      item.paramBodyValue = v.name;
      item.paramBodyLabel = v.label;
      this.$forceUpdate();
      console.log(item);
    },

    // 符号选择
    opsChange(v, item) {
      item.ops = v;
      console.log(v, item);
      this.$forceUpdate();
    },
    // 信号阈值
    signalValueChange(v, item) {
      item.signalValue = v.trim();
      this.$forceUpdate();
      console.log(item);
    },

    // 存在复制模板
    isCopyStrategy() {
      console.log(
        "**********copyStrategy**************copyStrategy",
        this.copyStrategy
      );

      this.exchangeCh = true; //
      this.pairCh = true; //
      this.strategyCh = true; //
      this.reservesCh = true;
      this.signalCh = true;

      this.stopLoss =
        this.copyStrategy.stopLoss == ""
          ? ""
          : Number(this.copyStrategy.stopLoss) * -100;
      this.stopSurplus =
        this.copyStrategy.stopProfit == ""
          ? ""
          : Number(this.copyStrategy.stopProfit) * 100;
      this.timedTermination =
        this.copyStrategy.stopTime == ""
          ? ""
          : Number(this.copyStrategy.stopTime);

      this.strategicDirection =
        this.copyStrategy.tradePairs.investType == 1 ? true : false; //   策略方向  false 本币
      this.modeSelection =
        this.copyStrategy.templatePattern == 1 ? false : true; // 0自定义  ，1多币对

      let investConfig = this.copyStrategy.investConfig;
      console.log("-----------------------", investConfig);

      this.currency = investConfig.investCoin; //投入币种
      if (investConfig.fixInvest) {
        // 固定投入
        this.investmentType = "固定投入";
        this.investment = investConfig.fixInvest;
      } else {
        this.investmentType = "比例投入";
        console.log(investConfig);

        this.investment = investConfig.proportionInvest.proportion;
        if (investConfig.proportionInvest.maxInvest) {
          this.max = investConfig.proportionInvest.maxInvest;
        }
        if (investConfig.proportionInvest.minInvest) {
          this.min = investConfig.proportionInvest.minInvest;
        }
      }

      if (
        this.copyStrategy.strategyName == "合约网格" ||
        this.copyStrategy.strategyName == "合约马丁" ||
        this.copyStrategy.strategyId == "WL20210624182847743"
      ) {
        this.modeIsShow = true;
        this.strategicDirection = false;
        this.strategicDirectionIsShow = false;
        this.contractTypeShow = true;
        this.contractType = this.copyStrategy.tradePairs.investType;
        this.lever = this.copyStrategy.tradePairs.invest;
        this.openingThreshold =
          this.copyStrategy.tradePairs.openThreshold * 100;

        this.transactionType = "contract";
        this.findByIdLeverMultiple(this.copyStrategy.tradePairs.exchangeId);
        this.transferShow = true;
      }
    },
  },
  created() {},
  mounted() {
    let project = sessionStorage.getItem("project");
    this.project = JSON.parse(project);

    this.getProject();
    let defaultTeam = sessionStorage.getItem("defaultTeam");
    this.defaultTeam = JSON.parse(defaultTeam);
    this.getExchangeList();
    this.strategyLibrary();
    this.queryStrategyTemplateLabel();
    // console.log(this.defaultTeam, this.project);

    this.isAcc();

    let copyStrategy = JSON.parse(sessionStorage.getItem("copyStrategy"));
    if (copyStrategy) {
      this.copyStrategy = copyStrategy;
      console.log(
        "**********copyStrategy**************copyStrategy",
        this.copyStrategy
      );
      this.isCopyStrategy();
    }

    console.log("copyStrategy", copyStrategy);

    console.log("***********mounted***************");
  },
  beforeCreate() {},
  beforeMount() {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {
    sessionStorage.removeItem("startAcc");
    sessionStorage.removeItem("copyStrategy");
    console.log("***************destroyed****************");
  },
  activated() {
    // let defaultTeam = sessionStorage.getItem("defaultTeam");
    // this.defaultTeam = JSON.parse(defaultTeam);
    // let project = sessionStorage.getItem("project");
    // this.project = JSON.parse(project);
    // console.log(this.defaultTeam,this.project)
    // this.strategyLibrary();
    // this.getExchangeList();

    let pair = JSON.parse(sessionStorage.getItem("pair"));
    if (pair && pair.length != 0) {
      for (let i = 0; i < pair.length; i++) {
        this.pair.push(pair[i]);
      }
      sessionStorage.removeItem("pair");
      // this.payloadCheck();
    }
    console.log(this.pair);
  },
  deactivated() {},
};
</script>




<style lang='scss' scoped>
.createPolicyTemplate {
  width: 100%;
  // height: 100%;
  .el-input__suffix {
    line-height: 40px;
    i {
      font-style: normal;
    }
  }
  .el-container {
    height: 100%;
    background: #eff2f5;
  }
  .el-header {
    background-color: #fff;
    color: #333;
    text-align: center;
    line-height: 60px;
    margin-bottom: 20px;
    .header-warp {
      display: flex;
      justify-content: space-between;
      align-items: center;
      i {
        cursor: pointer;
        margin-left: 5px;
      }
    }
  }
  .el-main {
    color: #333;
    padding: 0;
    font-size: 14px;
    .content {
      min-height: 100%;
      //   background: #fff;
      border-radius: 8px;
      //   padding: 0 20px;
      // overflow: scroll;
      ::v-deep.el-input__inner {
        height: 30px;
        line-height: 30px;
      }
      ::v-deep.el-input__icon {
        line-height: 30px;
      }
      .content-tamplate {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .content-tamplate-top {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 0 20px 20px 20px;
          background: #fff;
          border-radius: 8px;
          margin-bottom: 20px;
          .top,
          .bottom {
            width: 100%;
            display: flex;
            align-items: center;
            .warp {
              box-sizing: border-box;
              width: 33%;
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-top: 20px;
              margin-right: 20px;
              .title {
                display: inline-block;
                width: 20%;
                margin-right: 10px;
                // text-align: right;
              }
              .el-input {
                width: 78%;
                height: 40px;
              }
              .el-select {
                width: 100%;
              }
              ::v-deep.el-input__inner {
                height: 40px;
                line-height: 40px;
              }
              .el-input {
                ::v-deep.el-input__suffix {
                  top: 12px;
                  right: 10px;
                  cursor: pointer;
                }
              }
              .pattern {
                display: flex;
                flex-direction: row;
                width: 80%;
                span {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  width: 50%;
                  height: 40px;
                  background: #f7f7f7;
                  border-radius: 4px;
                  color: #102859;
                  cursor: pointer;
                }
                .on {
                  background: #2174ff;
                  border-radius: 4px;
                  color: #fff;
                }
                .contrOn {
                  background: #2174ff;
                  border-radius: 4px;
                  color: #fff;
                }
              }
            }
          }
        }

        .content-tamplate-main {
          display: flex;
          justify-content: space-between;
          width: 100%;

          .left-warp {
            flex: 1;
            display: flex;
            min-height: 676px;
            .parameter,
            .configure {
              background: #fff;
              border-radius: 8px;
              font-size: 16px;
              color: #102859;
              padding: 0 10px;
              .title {
                padding: 20px 0;
                display: flex;
                align-items: center;
                border-bottom: 1px solid #e6e9ee;
                .config {
                  margin-right: 10px;
                }
                .red {
                  font-size: 12px;
                  color: #e12525;
                }
                .mark {
                  font-size: 12px;
                }
              }

              //参数
              .parameter-box-warp {
                padding: 20px 0 0 0;
                .parameter-box {
                  display: flex;
                  // align-items: center;
                  padding: 0 0 20px 0;
                  .left {
                    display: flex;
                    flex-direction: column;
                    width: 120px;
                    .reserves-name {
                      display: flex;
                      align-items: center;
                      color: #102859 !important;
                      font-size: 14px !important;
                      margin-bottom: 4px;
                      img {
                        width: 12px;
                        height: 12px;
                        cursor: pointer;
                        margin-left: 10px;
                      }
                    }
                    :last-child {
                      color: #55698a;
                      font-size: 12px;
                    }
                  }
                  .right {
                    flex: 1;
                    margin-left: 15px;
                    .el-input {
                      height: 40px;
                    }
                    .el-cascader {
                      width: 100%;
                    }
                    ::v-deep.el-input__inner {
                      height: 40px;
                      line-height: 40px;
                    }
                    ::v-deep.el-input__suffix {
                      line-height: 40px;
                    }
                    .el-select {
                      width: 100%;
                    }
                    .signal-params {
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                      margin-top: 20px;

                      .one {
                        width: 45%;
                      }
                      .two {
                        width: 20%;
                        /deep/.el-icon-arrow-up:before {
                          content: "";
                        }
                        /deep/.el-input__inner {
                          display: flex;
                          align-items: center;
                          padding-right: 10px;
                        }
                      }
                      .three {
                        width: 35%;
                        /deep/.el-input__inner {
                          padding-right: 10px;
                        }
                      }
                    }
                  }
                  // 海风特殊参数样式
                  .special-box {
                    width: 100%;
                    .special-box-title {
                      color: #102859;
                      font-size: 16px;
                    }
                    .special-box-cont {
                      width: 100%;
                      height: 40px;
                      display: flex;
                      justify-content: center;
                      align-items: center;

                      margin-top: 10px;
                      span {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 50%;
                        height: 40px;
                        background: #f7f7f7;
                        color: #102859;
                        cursor: pointer;
                        border-radius: 4px;
                      }
                      .on {
                        background: #2174ff;
                        color: #fff;
                      }
                    }
                  }
                  .special-box-cont {
                    width: 100%;
                    height: 40px;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    margin-top: 10px;
                    span {
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      width: 50%;
                      height: 40px;
                      background: #f7f7f7;
                      color: #102859;
                      cursor: pointer;
                      border-radius: 4px;
                    }
                    .on {
                      background: #2174ff;
                      color: #fff;
                    }
                  }
                }
              }
              // 配置
              .configure-cont {
                // 固定投入
                .fixedInvestment {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  padding: 20px 0 0 0;
                  .investment-left,
                  .investment-right {
                    display: flex;
                    align-items: center;
                    width: 49%;
                    margin-bottom: 20px;
                    .label {
                      width: 22%;
                      img {
                        width: 12px;
                        height: 12px;
                        cursor: pointer;
                      }
                      .red {
                        color: #e12525;
                      }
                      .text {
                        font-size: 14px;
                      }
                      .rang {
                        font-size: 12px;
                        word-wrap: break-word;
                      }
                    }
                    .investment {
                      width: 34%;
                      margin-right: 4px;
                    }
                    .el-input,
                    .el-select {
                      width: 78%;
                      height: 40px;
                    }
                    ::v-deep.el-input__inner {
                      height: 40px;
                      line-height: 40px;
                    }
                    ::v-deep.el-input__suffix {
                      line-height: 40px;
                    }
                  }
                }
                // 比例投入
                .proportionInvestment {
                  padding: 20px 0 0 0;
                  .proportionInvestment-top {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .warp {
                      width: 55%;
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                      .label {
                        width: 40%;
                        font-size: 14px;
                        img {
                          width: 12px;
                          height: 12px;
                          cursor: pointer;
                        }
                        .rang {
                          font-size: 12px;
                        }
                      }
                      .el-select {
                        width: 60%;
                        height: 40px;
                      }
                      ::v-deep.el-input__inner {
                        height: 40px;
                        line-height: 40px;
                      }
                      ::v-deep.el-input__suffix {
                        line-height: 40px;
                      }
                    }
                    .Anchor {
                      width: 33%;
                      .label {
                        width: 50%;
                        font-size: 14px;
                      }
                      .el-select {
                        width: 66%;
                        height: 40px;
                      }
                    }
                    .ratio {
                      width: 40%;
                      .label {
                        width: 40%;
                        font-size: 14px;
                        .red {
                          color: #e12525;
                        }
                        img {
                          width: 12px;
                          height: 12px;
                          cursor: pointer;
                        }
                      }
                      .el-input {
                        width: 60%;
                        height: 40px;
                      }
                    }
                  }
                  .proportionInvestment-bottom {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-top: 20px;
                    .warp {
                      width: 55%;
                      display: flex;

                      align-items: center;
                      .label {
                        width: 28%;
                        font-size: 14px;
                        margin-right: 6px;

                        img {
                          width: 12px;
                          height: 12px;
                          cursor: pointer;
                        }
                        .red {
                          color: #e12525;
                        }
                        .text {
                          font-size: 14px;
                        }
                      }
                      .el-input {
                        width: 72%;
                        height: 40px;
                      }
                    }
                    .el-input {
                      width: 40%;
                      height: 40px;
                    }
                    ::v-deep.el-input__inner {
                      height: 40px;
                      line-height: 40px;
                    }
                    ::v-deep.el-input__suffix {
                      line-height: 40px;
                    }
                  }
                }
                .otherSettings {
                  // padding: 0 20px 0 20px;
                  .title {
                    border-bottom: 1px solid #e6e9ee;
                  }
                  .Setting-warp {
                    display: flex;
                    justify-content: space-between;
                    flex-wrap: wrap;
                    padding: 20px 0 0 0;
                    .Setting-parameter {
                      width: 48%;
                      margin-bottom: 20px;
                      .Setting-parameter-title {
                        margin-bottom: 10px;
                        span {
                          color: #102859;
                        }
                        img {
                          width: 12px;
                          height: 12px;
                          margin-left: 6px;
                          cursor: pointer;
                        }
                      }
                      .el-input {
                        height: 40px;
                      }
                      ::v-deep.el-input__inner {
                        height: 40px;
                        line-height: 40px;
                      }
                      ::v-deep.el-input__suffix {
                        line-height: 40px;
                      }
                      .el-date-editor {
                        width: 100%;
                      }
                    }
                  }
                }
              }
            }
            .parameter {
              width: 45%;
              height: 676px;
              overflow: scroll;
            }
            .configure {
              width: 62%;
              margin-left: 2px;
              height: 676px;
              // overflow: scroll;
            }
          }

          // 账户
          .account {
            // width: 390px;
            position: relative;
            width: 20%;
            height: 676px;
            margin-left: 10px;
            background: #fff;
            padding: 0 20px;
            border-radius: 8px;
            .title {
              padding: 20px 0;
              display: flex;
              align-items: center;
              border-bottom: 1px solid #e6e9ee;
              .config {
                margin-right: 10px;
              }
              .red {
                font-size: 12px;
                color: #e12525;
              }
              .mark {
                font-size: 12px;
              }
            }
            // 账户
            .account-cont {
              height: 510px;
              padding: 20px 0 0 0;
              // display: flex;
              // justify-content: space-between;
              // align-items: center;

              .acc-warp {
                .acc-box {
                  display: flex;
                  margin-bottom: 20px;
                  height: 82px;
                  .acc-boxLeft {
                    border-radius: 4px;
                    border: 1px solid #e6e9ee;
                    padding: 20px;
                    // width: 164px;
                    flex: 1;
                    .acc-box-name {
                      display: flex;
                      justify-content: space-between;
                      color: #102859;
                      font-size: 14px;
                      margin-bottom: 10px;
                    }
                    .available {
                      font-size: 12px;
                    }
                  }
                  .acc-boxBtn {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 0 10px;
                    color: #2174ff;
                    cursor: pointer;
                  }
                }
              }
              .account-cont-tips {
                color: #55698a;
                font-size: 14px;
              }
              .no-acc {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-top: 120px;
                margin-bottom: 210px;
                img {
                  width: 185px;
                  height: 165px;
                }
                p {
                  color: #758db5;
                  font-size: 14px;
                  margin-top: 20px;
                }
              }

              .btn-warp {
                width: 90%;
                border-radius: 4px;
                color: #55698a;
                background: #f7f7f7;
                height: 34px;
                margin-bottom: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                position: absolute;
                left: 20px;
                bottom: 20px;
              }
              .on {
                color: #fff;
                background: #2174ff;
              }
            }
          }
        }
      }
      .btn {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 60px;
        padding-bottom: 30px;
        span {
          border-radius: 4px;
          color: #fff;
          font-size: 14px;
          padding: 5px 30px;
          background: #007aff;
          cursor: pointer;
        }
      }
    }
  }
  ::v-deep.el-tag__close {
    display: none;
  }
}
.tips {
  display: inline-block;
  margin: 5px 0 0 5px;
  color: #999;
  font-size: 12px;
}

.red {
  color: #e12525;
}
.green {
  color: #26b34b;
}
/deep/.el-checkbox__inner {
  border-radius: 100%;
}

.dialog-nav {
  color: #55698a;
  padding: 20px 0 30px;
  font-size: 12px;
  .dialog-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .dialog-img {
      width: 36px;
      height: 36px;
      cursor: pointer;
      margin: 0 20px 20px 0;
    }
    .dialog-top {
      font-size: 16px;
      margin-bottom: 20px;
      color: #102859;
    }
  }
  .dialog-currency {
    display: flex;
    flex-direction: column;
    font-size: 16px;
    span {
      margin-bottom: 10px;
    }
  }
}
.dialog-footer {
  display: flex;
  justify-content: flex-end;
  div {
    width: 72px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    cursor: pointer;
  }
  .footerBack {
    border: 1px solid #c2d9ff;
    color: #2174ff;
  }
  .footerBtn {
    color: #ffffff;
    background: #2174ff;
    border: 1px solid #c2d9ff;
    margin-left: 12px;
  }
}
.overflowBox {
  overflow-y: scroll;
}
</style>