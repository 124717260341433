<template>
  <div class="Project">
    <el-container>
      <el-header>
        <div class="header-warp">
          <i class="el-icon-arrow-left" @click="goBack"></i>
          <span>{{$t('m.项目详情')}}</span>
          <span></span>
        </div>
      </el-header>
      <el-container>
        <!-- aside -->
        <el-aside width="200px">
          <el-menu
            :default-active="active"
            class="el-menu-vertical-demo"
            background-color="#282933"
            text-color="#fff"
          >
            <!-- <el-menu-item index="1" @click="activeClick('1')">
              <i class="el-icon-coin"></i>
              <span slot="title">策略交易</span>
            </el-menu-item> -->

            <el-submenu index="1">
              <template slot="title">
                <i class="el-icon-coin"></i>
                <span>{{$t('m.策略交易')}}</span>
              </template>
              <el-menu-item-group>
                <el-menu-item index="1-2" @click="activeClick('1-2')"
                  >{{$t('m.策略')}}</el-menu-item
                >
              </el-menu-item-group>
            </el-submenu>

            <el-submenu index="2">
              <template slot="title">
                <i class="el-icon-location"></i>
                <span>{{$t('m.智能订单')}}</span>
              </template>
              <el-menu-item-group>
                <el-menu-item index="2-2" @click="activeClick('2-2')"
                  >{{$t('m.订单链')}}</el-menu-item
                >
              </el-menu-item-group>
            </el-submenu>

            <el-menu-item index="3" @click="activeClick('3')"
              >{{$t('m.持仓组合')}}</el-menu-item
            >

            <el-menu-item index="4" @click="activeClick('4')">
              <i class="el-icon-tickets"></i>
              <span slot="title">{{$t('m.我管理的账户')}}</span>
            </el-menu-item>

            <el-menu-item index="5" @click="activeClick('5')" v-if="isSuper">
              <i class="el-icon-tickets"></i>
              <span slot="title">{{$t('m.项目管理的账户')}}</span>
            </el-menu-item>

            <el-menu-item index="6" @click="activeClick('6')"
              >{{$t('m.信号中心')}}</el-menu-item
            >

            <el-menu-item index="7" v-if="isSuper" @click="activeClick('7')">
              <i class="el-icon-tickets"></i>
              <span slot="title">{{$t('m.交易数据监测')}}</span>
            </el-menu-item>

            <el-menu-item index="8" @click="activeClick('8')">
              <i class="el-icon-tickets"></i>
              <span slot="title">{{$t('m.项目成员')}}</span>
            </el-menu-item>

            <el-menu-item index="9" v-if="isSuper" @click="activeClick('9')">
              <i class="el-icon-tickets"></i>
              <span slot="title">{{$t('m.项目设置')}}</span>
            </el-menu-item>

            <!-- <el-menu-item index="2" @click="activeClick('4')">
              <i class="el-icon-tickets"></i>
              <span slot="title">我的用户</span>
            </el-menu-item>

            <el-menu-item index="3" @click="activeClick('3')">
              <i class="el-icon-tickets"></i>
              <span slot="title">项目用户</span>
            </el-menu-item>

            <el-menu-item index="7" @click="activeClick('7')">new</el-menu-item>

            <el-menu-item index="4" @click="activeClick('4')">
              <i class="el-icon-setting"></i>
              <span slot="title">项目数据</span>
            </el-menu-item>

            <el-menu-item index="8" @click="activeClick('8')">
              <i class="el-icon-tickets"></i>
              <span slot="title">项目成员</span>
            </el-menu-item>

            <el-menu-item index="1-2" @click="activeClick('1-2')"
              >策略</el-menu-item
            >
            <el-menu-item index="1-3" @click="activeClick('1-3')"
              >策略</el-menu-item
            >

            <el-menu-item index="1-4" @click="activeClick('1-4')"
              >设置</el-menu-item
            >

            <el-menu-item index="2-3" @click="activeClick('2-3')"
              >持仓资金</el-menu-item
            >
            <el-menu-item index="2-4" @click="$router.push('/activeOrder')"
              >持仓资金</el-menu-item
            > -->
            <!-- <el-menu-item index="3" @click="activeClick(3)">
              <i class="el-icon-coin"></i>
              <span slot="title">操作管理</span>
            </el-menu-item> -->
          </el-menu>
        </el-aside>
        <!-- main -->
        <el-main>
          <!-- 模板策略详情 -->
          <NewTemplate @backBtn="backBtn" v-if="active == '1-2'" />

          <!-- 订单链 -->
          <ActiveOrderChain v-if="active == '2-2'" />

          <!-- 持仓组合 -->
          <PositionOrderChain v-if="active == '3'" @childFn="parentFn" />

          <!-- 我管理的账户 -->
          <AdministerAccount v-if="active == '4'" @childFn="parentFn" />

          <!-- 项目管理的账户 -->
          <ProjectAccount v-if="active == '5'" />

          <!-- 信号中心 -->
          <Signal v-if="active == '6'" />
          <!-- 交易数据监测 -->
          <DataOverview
            v-if="active == '7'"
            ref="DataOverview"
            @childFn="parentFn"
          />

          <!-- 项目成员 -->
          <ProjectMember v-if="active == '8'" />

          <!-- 项目设置 -->
          <ProjectSettings v-if="active == '9'" />

          <!-- *******分割线********* -->

          <!-- <CreateStrategy v-if="active == '1-1'" />
          
          <NewStrategy v-if="active == '1-3'" ref="NewStrategy" />
          <MyUserManagement v-if="active == 2" ref="MyUserManagement" />
          <UserManagement v-if="active == 3" ref="UserManagement" />
          <DataOverview
            v-if="active == 4"
            ref="DataOverview"
            @childFn="parentFn"
          />
          <OperationManagement v-if="active == 3" ref="OperationManagement" />



          

          
          <ActiveOrder />

           -->
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import DataOverview from "./projectManagement/dataOverview";
import UserManagement from "./projectManagement/userManagement";
import MyUserManagement from "./projectManagement/userManagement";
import OperationManagement from "./projectManagement/operationManagement";
import TradingOperation from "./projectManagement/tradingOperation";
import CreateOrderChain from "./projectManagement/createOrderChain";
import ActiveOrderChain from "./projectManagement/activeOrderChain";
import PositionOrderChain from "./projectManagement/positionOrderChain";
import ActiveOrder from "./projectManagement/activeOrder";
import CreateStrategy from "./projectManagement/createStrategy";

import NewStrategy from "./projectManagement/newStrategy";
import ProjectSettings from "./projectManagement/projectSettings";

import AdministerAccount from "./projectManagement/administerAccount";
import ProjectAccount from "./projectManagement/projectAccount";
import NewTemplate from "./projectManagement/newTemplate";

import Signal from "./projectManagement/signal";
import ProjectMember from "./projectManagement/projectMember";

export default {
  components: {
    DataOverview,
    UserManagement,
    OperationManagement,
    TradingOperation,
    MyUserManagement,
    CreateOrderChain,
    ActiveOrderChain,
    PositionOrderChain,
    CreateStrategy,
    ActiveOrder,

    NewStrategy,
    ProjectSettings,

    AdministerAccount,
    ProjectAccount,
    NewTemplate,
    ProjectMember,
    Signal,
  },
  data() {
    return {
      active: "3",
      detailsOperationAuthority: false,
      isSuper: false,
    };
  },
  computed: {},
  watch: {},
  methods: {
    activeClick(v) {
      this.active = v;
      if (v == 1) {
        sessionStorage.setItem("userId", this.account.userId);
      } else if (v == 5) {
        sessionStorage.removeItem("userId");
      } else if (v == 4) {
        console.log(this.account);
        sessionStorage.setItem("userId", this.account.userId);
      }
    },
    // 返回上一页
    goBack() {
      // sessionStorage.setItem("projectActive", this.$route.query.projectActive);
      sessionStorage.removeItem("detailsOperationAuthority");
      this.$router.back(-1);
    },
    parentFn(v) {
      console.log(v);
      this.active = v;
    },
    backBtn(e) {
      this.active = e;
    },
  },
  created() {
    this.isSuper = JSON.parse(sessionStorage.getItem("isSuper"));
  },
  mounted() {
    let account = sessionStorage.getItem("account");
    this.account = JSON.parse(account);
    sessionStorage.setItem("isOplistShow", true);
    sessionStorage.setItem("userId", this.account.userId);
  },
  beforeCreate() {},
  beforeMount() {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {},
  activated() {},
  deactivated() {
    sessionStorage.removeItem("userId");
    sessionStorage.removeItem("isOplistShow");
    this.$destroy();
  },
};
</script>
<style lang='scss' scoped>
.Project {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: absolute;
  .el-container {
    height: 100%;
    background: #eff2f5;
  }
  .el-header {
    background-color: #282933;
    color: #fff;
    text-align: center;
    line-height: 60px;
    // margin-bottom: 20px;
    .header-warp {
      display: flex;
      justify-content: space-between;
      align-items: center;
      i {
        cursor: pointer;
      }
    }
  }

  .el-aside {
    background-color: #282933;
    color: #333;
    .el-menu {
      border: 0px;
    }
  }

  .el-main {
    // background-color: #fff;
    color: #333;
    padding: 0;
    font-size: 14px;
    margin: 20px 20px 20px 20px;
  }
}
</style>