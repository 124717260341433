<template>
  <!-- 操作日志 -->
  <div class="operationLog">
    <el-container>
      <el-header>
        <div class="header-warp">
          <i class="el-icon-arrow-left" @click="goBack"></i>
          <span>{{$t('m.操作日志')}}</span>
          <span></span>
        </div>
      </el-header>
      <el-container>
        <!-- main -->
        <el-main>
          <div class="content">
            <div v-if="logList.length == 0">{{$t('m.暂无记录')}}</div>
            <div
              v-else
              class="log-box"
              v-for="(item, idx) in logList"
              :key="idx"
            >
              <span>{{
                moment(item.createTime).format("YYYY-MM-DD HH:mm:ss")
              }}</span>
              <span>{{ "[" + item.publicCode + "]" }}</span>
              <span>{{ "[" + item.templateName + "]" }}</span>
              <span>{{ "[" + $t('m.'+item.strategyName) + "]" }}</span>
              <span>{{ "[" + item.content + "]" }}</span>
              <span v-if="item.msg">{{ "[" + item.msg + "]" }}</span>
              <span v-if="!item.msg">[{{$t('m.操作成功')}}]</span>
            </div>
          </div>
          <div class="pagination-warp">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              background
              :page-sizes="[10, 20, 30, 40, 50]"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination>
          </div>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import moment from "moment";
import { getOperateLog } from "../../request/api";
export default {
  components: {},
  data() {
    return {
      moment,
      availableList: [], //列表数据
      multipleSelection: [],
      templateId: null, //模板ID
      project: null,
      logList: [],
      pageNo: 1,
      pageSize: 10,
      total: 0,
    };
  },
  computed: {},
  watch: {},
  methods: {
    // 返回上一页
    goBack() {
      this.$router.back(-1);
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
      console.log(this.multipleSelection);
    },
    // 查询操作日志
    getOperateLog() {
      let payload = {
        token: sessionStorage.getItem("token"),
        templateId: this.templateId,
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        update: true,
      };
      getOperateLog(payload).then((res) => {
        if (res.status == 200) {
          this.logList = res.data.logList;
          this.total = res.data.logCount;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getOperateLog();
    },
    handleCurrentChange(val) {
      this.pageNo = val;
      this.getOperateLog();
    },
  },
  created() {},
  mounted() {
    // this.templateId = this.$route.query.templateId;
    // console.log(this.$route.query.templateId);
    // let project = sessionStorage.getItem("project");
    // this.project = JSON.parse(project);
    // this.getOperateLog();
    // console.log(this.templateId);
    sessionStorage.setItem("projectActive", this.$route.query.projectActive);
  },
  beforeCreate() {},
  beforeMount() {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {},
  activated() {
    this.templateId = this.$route.query.templateId;
    console.log(this.$route.query.templateId);
    let project = sessionStorage.getItem("project");
    this.project = JSON.parse(project);
    this.getOperateLog();
  },
};
</script>
<style lang='scss' scoped>
.operationLog {
  width: 100%;
  height: 100%;
  .el-container {
    height: 100%;
    background: #eff2f5;
  }
  .el-header {
    background-color: #fff;
    color: #333;
    text-align: center;
    line-height: 60px;
    margin-bottom: 20px;
    .header-warp {
      display: flex;
      justify-content: space-between;
      align-items: center;
      i {
        cursor: pointer;
      }
    }
  }
  .el-main {
    color: #333;
    font-size: 14px;
    height: 800px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: #fff;
    margin: 0 100px;
    .content {
      border-radius: 8px;
      padding: 20px;
      display: flex;
      flex-direction: column;
      .log-box {
        color: #666;
        font-size: 14px;
        line-height: 30px;
        border-bottom: 1px solid #eaeaec;
        span {
          display: inline-block;
          margin-right: 10px;
          padding: 10px 0;
        }
      }
    }
    .pagination-warp {
      display: flex;
      justify-content: center;
      margin: 20px 0;
    }
  }
}
</style>