<template>
  <!-- 选择交易对 -->
  <div class="selectTradingPairs">
    <el-container>

      <el-header>
        <div class="header-warp">
          <i class="el-icon-arrow-left" @click="goBack"></i>
          <span>{{$t('m.选择交易对')}}</span>
          <span></span>
        </div>
      </el-header>

      <el-container>
        <!-- main -->
        <el-main>
          <div class="content">
            <p class="title">{{$t('m.交易对列表')}}</p>
            
            <div class="table-warp">
              <el-table
                ref="multipleTable"
                :data="pairList"
                tooltip-effect="dark"
                style="width: 100%"
                @selection-change="handleSelectionChange">

                <el-table-column prop="counterCoin" :label="$t('m.币种')" width="200">
                  <template slot-scope="scope">
                    <span>{{
                      scope.row.counterCoin + "/" + scope.row.baseCoin
                    }}</span>
                  </template>
                </el-table-column>

                <el-table-column prop="last" :label="$t('m.价格')" width="200" sortable>
                  <template slot-scope="scope">
                    <span>{{
                      Math.floor(Number(scope.row.last) * 10000) / 10000
                    }}</span>
                  </template>
                </el-table-column>

                <el-table-column
                  prop="increase"
                  :label="$t('m.涨跌幅')"
                  width="200"
                  sortable>
                  <template slot-scope="scope">
                    <span>{{ scope.row.increase }}</span>
                  </template>
                </el-table-column>

                <el-table-column prop="name" :label="$t('m.选择')" width="50">
                </el-table-column>

                <el-table-column type="selection" width="200">
                </el-table-column>
              </el-table>
            </div>
            <div class="btn-warp">
              <span @click="cancel">{{$t('m.取消')}}</span>
              <span @click="confirm">{{$t('m.确定')}}</span>
            </div>
          </div>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import { getPairPage, getPairPageById } from "../../request/api";
export default {
  components: {},
  data() {
    return {
      exchangeId: null,
      project: null,
      baseCoinList: [], //本币列表
      pairList: [], //交易币列表
      index: 0,
      search: null,
      multipleSelection: [],
      pair: [],
      modeSelection: null,
    };
  },
  computed: {},
  watch: {},
  methods: {
    // 返回上一页
    goBack() {
      this.$router.back(-1);
    },

    // 查询交易对
    getPairPage() {

      let payload = {
        token: sessionStorage.getItem("token"),
        teamProjectId: this.project.teamProjectId,
        exchangeId: this.exchangeId,
      };
      getPairPageById(payload).then((res) => {
        if (res.status == 200) {
          console.log(this.pair);
          let list = res.data.pairList;
          let pair = this.pair;
          console.log(this.pair);
          if (pair.length != 0) {
            for (let i = 0; i < list.length; i++) {
              for (let j = 0; j < pair.length; j++) {
                console.log(Number(list[i].last));
                if (list[i].pairId == pair[j].pairId) {
                  console.log(list[i]);
                  list.splice(i, 1);
                }
              }
            }
          }
          for (let k = 0; k < list.length; k++) {
            list[k].last = Number(list[k].last);
            list[k].increase = Number(list[k].increase);
          }
          this.pairList = list;
        } else {
          this.$message.error(res.msg);
        }
      });
    },

    // 根据本币查询交易对
    baseCoinSelect(v) {
      this.index = v;
    },

    // 勾选
    handleSelectionChange(val) {
      this.multipleSelection = val;
      console.log(this.multipleSelection);
    },

    // 搜索交易对
    searchPair() {
      console.log(this.search);
      let payload = {
        token: sessionStorage.getItem("token"),
        exchangeId: this.exchangeId,
        keyword: this.search,
      };
      getPairPage(payload).then((res) => {
        if (res.status == 200) {
          this.baseCoinList = res.data.baseCoinList;
          let list = res.data.pairList;
          let pair = this.pair;
          if (pair.length != 0) {
            for (let i = 0; i < list.length; i++) {
              Number(list[i].last);
              Number(list[i].increase);
              for (let j = 0; j < pair.length; j++) {
                if (list[i].pairId == pair[j].pairId) {
                  console.log(list[i]);
                  list.splice(i, 1);
                }
              }
            }
          }

          console.log(list);
          this.pairList = list;
        } else {
          this.$message.error(res.msg);
        }
      });
    },

    // 取消
    cancel() {
      this.$router.back(-1);
    },

    // 确定
    confirm() {
      
      let list = this.multipleSelection;
      if (list.length == 0) {
        this.$message.error(this.$t('m.请选择交易对'));
        return;
      } else if(list.length>1&&this.modeSelection=='true'){
        this.$message.error(this.$t('m.自定义模式只能选择1个交易对'));
        return
      } else if (list.length > 10) {
        this.$message.error(this.$t('m.多币对模式最多勾选10个交易对'));
        return
      }
      sessionStorage.setItem("pair", JSON.stringify(this.multipleSelection));
      console.log(this.multipleSelection);
      this.$router.back(-1);
    },
  },
  created() {},
  mounted() {},
  beforeCreate() {},
  beforeMount() {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {},
  activated() {
    if (this.$route.query.pair) {
      this.pair = this.$route.query.pair;
    }
    this.exchangeId = this.$route.query.exchangeId;
    this.modeSelection = this.$route.query.modeSelection;
    let project = JSON.parse(sessionStorage.getItem("project"));
    this.project = project;
    this.getPairPage();
    console.log(this.modeSelection);
  },
  deactivated() {
    this.$destroy();
  },
};
</script>
<style lang='scss' scoped>
.selectTradingPairs {
  width: 100%;
  height: 100%;
  .el-container {
    height: 100%;
    background: #eff2f5;
  }
  .el-header {
    background-color: #fff;
    color: #333;
    text-align: center;
    line-height: 60px;
    margin-bottom: 20px;
    .header-warp {
      display: flex;
      justify-content: space-between;
      align-items: center;
      i {
        cursor: pointer;
      }
    }
  }
  .el-main {
    color: #333;
    padding: 0 10%;
    font-size: 14px;
    .content {
      // height: 100%;
      background: #fff;
      border-radius: 8px;
      padding: 20px;
      ::v-deep.el-input__inner {
        height: 30px;
        line-height: 30px;
      }
      ::v-deep.el-input__icon {
        line-height: 30px;
      }
      .title {
        margin-bottom: 20px;
      }
      .baseCoin-warp {
        display: flex;
        align-items: center;
        height: 30px;
        line-height: 30px;
        margin-bottom: 30px;
        .baseCoin-box {
          border: 1px solid #c4c6cf;
          border-radius: 4px;
          .baseCoin {
            display: inline-block;
            height: 30px;
            line-height: 30px;
            padding: 0 20px;
            cursor: pointer;
          }
          .bck {
            background: #007aff;
            color: #fff;
            border-radius: 4px;
          }
        }
        .el-input {
          width: 300px;
          margin-left: 60px;
        }
      }
      .table-warp {
        height: 500px;
        overflow: scroll;
      }
      .btn-warp {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-top: 30px;
        span {
          color: #333;
          border-radius: 3px;
          border: 1px solid #c4c6cf;
          padding: 5px 20px;
          cursor: pointer;
        }
        :last-child {
          color: #fff;
          border: none;
          background: #007aff;
          margin-left: 20px;
        }
      }
    }
  }
}
</style>