<template>
  <div class="navs">
    <div class="top">
      <div style="display: flex; align-items: center">
        <img
          style="margin-right: 20px; cursor: pointer"
          @click="backBtn"
          src="../../assets/icon/icon_back@3x.png"
          alt=""/>
        <span style="margin-top: 1px">{{ name ? name : "--" }}</span>
      </div>
    </div>
    <div class="nav">
      <div class="left">
        <div class="title">
          {{$t('m.账户数据')}}</div>
        <div class="navItem">
          <div>
            <span>
              {{$t('m.总资金')}} ({{ publicPeopleMap ? publicPeopleMap.investCoin : "--"}})</span>
            <span>{{publicPeopleMap ? publicPeopleMap.investCoinNum : "--"}}</span>
          </div>
          <div>
            <span>{{$t('m.入金时间')}}</span>
            <span>{{
              publicPeopleMap ? publicPeopleMap.createTime : "--"
            }}</span>
          </div>
          <div>
            <span>{{$t('m.已投入')}} ({{publicPeopleMap ? publicPeopleMap.investCoin : "--"}})</span>
            <span>{{publicPeopleMap ? publicPeopleMap.investment : "--"}}</span>
          </div>
          <div>
            <span>{{$t('m.剩余可用')}} ({{publicPeopleMap ? publicPeopleMap.investCoin : "--"}})</span>
            <span>{{ publicPeopleMap ? publicPeopleMap.availableNum : "--"}}</span>
          </div>
          <div>
            <span>{{$t('m.当前策略数')}}</span>
            <span>{{publicPeopleMap ? publicPeopleMap.strategyNumber : "--"}}</span>
          </div>
          <div>
            <span>{{$t('m.总收益')}} ({{publicPeopleMap ? publicPeopleMap.investCoin : "--"}})</span>
            <span>{{publicPeopleMap ? publicPeopleMap.realEarnings : "--"}}</span>
          </div>
          <div>
            <span>{{$t('m.总收益率')}} ({{publicPeopleMap ? publicPeopleMap.investCoin : "--"}})</span>
            <span>{{publicPeopleMap ? publicPeopleMap.realEarningRate : "--"}}%</span>
          </div>
          <div>
            <span>{{$t('m.总年化')}} ({{publicPeopleMap ? publicPeopleMap.investCoin : "--"}})</span>
            <span>{{publicPeopleMap ? publicPeopleMap.realYearIncome : "--"}}%</span>
          </div>
        </div>
        <div class="chartData">
          <div class="chartData-title">{{$t('m.资金分布')}}</div>
          <div class="chartImg">
            <div
              id="main"
              style="width: 360px; height: 420px; color: white"
              textStyle="white"
            ></div>
          </div>
        </div>
      </div>
      <div class="box">
        <div class="right">

          <div class="topTab">
            <template v-for="(item, index) in topTab">
              <span
                @click="tableTopBtn(index)"
                :class="index == tableTopActive ? 'brdTopStyle' : ''"
                :key="'topTab'+index">
                {{ $t('m.'+item) }}
              </span>
            </template>
          </div>

          <div class="topData">
            <template v-for="(item, index) in Tab">
              <div
                class="topDataItem"
                @click="tableDataBtn(index)"
                :key="'Tab'+index"
                :class="index == tableActive ? 'brdStyle' : ''">
                {{ $t('m.'+item) }}
              </div>
            </template>
          </div>

          <div class="topData" v-if="tableTopActive == 0">
            <template v-for="(item, index) in TabTwo">
              <div
                class="topDataItem"
                @click="tableDataTwoBtn(index)"
                :key="'TabTwo'+index"
                :class="index == tableActiveTwo ? 'brdStyle' : ''">
                {{ $t('m.'+item) }}
              </div>
            </template>
          </div>

          <div
            v-if="tableActive == 0 || (tableActive == 1 && tableTopActive !== 2)"
            class="bottomColumn">
            <div class="topColumn">
              <SearchBox
                @btnSubmit="searchBtn"
                :searchData="searchData"
                @btnBack="btnBack"
                ref="child" />
              <div
                class="stopBtn"
                style="width: 50px"
                v-if="
                  tableTopActive == 0 &&
                  tableActiveTwo == 0 &&
                  nowData &&
                  tableActive !== 1
                "
                @click="confirmSuspend()">
                {{$t('m.暂停')}}</div>
              <div
                class="stopBtn"
                style="width: 50px"
                v-if="
                  tableTopActive == 0 &&
                  tableActiveTwo == 0 &&
                  nowData &&
                  tableActive !== 1"
                @click="operationBtn()">
                {{$t('m.重启')}}</div>
              <div
                class="stopBtn"
                style="width: 50px"
                v-if="
                  tableTopActive == 0 &&
                  tableActiveTwo == 0 &&
                  nowData &&
                  tableActive !== 1
                "
                @click="stopSubmit()">
                {{$t('m.终止')}}</div>
              <div
                class="stopBtn"
                v-if="tableTopActive == 1"
                @click="stopChainSubmit">
                {{$t('m.终止订单链')}}</div>
              <div
                class="stopBtn"
                v-if="tableTopActive == 2"
                @click="batchSynchSpotApi()">
                <!-- <img
                  class="headerIcon"
                  src="../../assets/icon/suiji-bai.png"
                  alt=""
                /> -->
                <div>{{$t('m.同步资产')}}</div>
              </div>
              <div
                class="stopBtn"
                style="width: 130px"
                v-if="tableTopActive == 2"
                @click="exchangeBtn">
                {{$t('m.快速兑换')}}【{{ startCoin ? startCoin : "USDT" }}】
              </div>
            </div>

            <DetailsData
              :rowStyleData="rowStyleData"
              :data="tableData"
              class="detailsData"
              :label="orderData"
              :modify="modify"
              :detailsDelete="detailsDelete"
              :detailBtn="detailBtn"
              :details="details"
              @selectRow="selectRow"
              @setActive="setActive"
              @transferDetermineBtn="transferDetermineBtn"
              @updateParams="updateParams"
              @findByIdOrderChain="findByIdOrderChain"
            />
          </div>
          <div class="contract" v-if="tableActive == 1 && tableTopActive == 2">
            <div class="title">
              <SearchBox
                class="topColumn"
                @btnSubmit="searchBtn"
                @btnBack="btnBack"
                :searchData="contractSearch"
                ref="child"
              />
              <div class="columnRight">
                <span @click="synchronization">
                  {{$t('m.同步资产')}}</span>
                <span @click="quickFlatPosition">
                  {{$t('m.快速平仓')}}</span>
              </div>
            </div>
            <div v-if="contractTable.length !== 0" class="tableStyle">
              <div class="tableTitle">
                <span>{{$t('m.账户')}}</span>
                <span>{{$t('m.合约')}}</span>
                <span>{{$t('m.权益')}}(USDT)</span>
                <span>{{$t('m.可用担保资产')}}(USDT)</span>
                <span></span>
                <el-checkbox
                  @change="allContractSelect"
                  v-model="contractSelectBtn"
                ></el-checkbox>
              </div>
              <div class="tableNav">
                <div class="tableItem" v-for="(item, index) in contractTable" :key="'tableItem' + index">
                  <div class="tableFirst">
                    <div>
                      {{ item.remarkCode ? item.remarkCode : item.apiName }}
                    </div>
                    <div>{{ item.contractId }}</div>
                    <div>{{ item.equity }}</div>
                    <div>{{ item.available }}</div>
                    <div class="contractBtn">
                      <span @click="transferBtn(item)">{{$t('m.划转')}}</span>
                      <span @click="templateBtn(item)">{{$t('m.启动策略')}}</span>
                      <span @click="orderBtn(item)">{{$t('m.创建订单链')}}</span>
                      <span
                        @click="item.show = !item.show"
                        v-if="item.positions !== null">
                        {{$t('m.详情')}}<img
                          v-if="item.show"
                          style="width: 12px; background: white"
                          src="../../assets/icon/zxxq_btn-lan.png"
                          alt="" />
                        <img
                          v-else
                          style="width: 12px; background: white"
                          src="../../assets/icon/xiala-lan.png"
                          alt="" />
                      </span>
                    </div>
                    <el-checkbox
                      @change="FirstSelect(item)"
                      v-model="item.select"
                    ></el-checkbox>
                  </div>
                  <div
                    class="tableTwo"
                    v-if="item.show && item.positions && item.positions.length > 0">
                    <div class="tableTwoTitle">
                      <span>{{$t('m.合约')}}</span>
                      <span>{{$t('m.方向')}}</span>
                      <span>{{$t('m.持仓量(张)')}}</span>
                      <span>{{$t('m.可平量(张)')}}</span>
                      <span>{{$t('m.开仓均价')}}({{ item.investCoin }})</span>
                      <span>{{$t('m.担保资产率')}}</span>
                      <span>{{$t('m.预估强平价')}}({{ item.investCoin }})</span>
                      <span>{{$t('m.收益')}}({{ item.investCoin }})</span>
                      <span>{{$t('m.收益率')}}</span>
                      <div style="width: 14px"></div>
                    </div>
                    <div class="tableTwoNav" v-for="(item, index) in item.positions" :key="'tableTwoNav' + index">
                      <span>{{ item.contractIdType + "·" + $t('m.永续')}}</span>
                      <span :style="{color: item.direction == 'buy' ? 'red' : 'green'}">
                        {{item.direction == "buy" ? $t('m.做多') : $t('m.做空')}}·
                        {{item.leverRate}}x
                        {{item.marginMode == "isolated" ? $t('m.逐仓') :$t('m.全仓')}}</span>
                      <span>{{ item.volumeNum }}</span>
                      <span>{{ item.availableNum }}</span>
                      <span>{{ item.costOpen }}</span>
                      <span>{{ item.riskRate }}%</span>
                      <span>{{
                        item.liquidationPrice == 0 ? "--" : item.liquidationPrice
                      }}</span>

                      <span :style="{color: 
                      item.profit < 0 
                      ? 'green' 
                      : (item.profit > 0 ? 'red' : '')}">{{ item.profit }}</span>

                      <span :style="{color:
                      item.profitRate < 0
                      ? 'green'
                      : (item.profitRate > 0 ? 'red' : '')}" >{{ item.profitRate }}%</span>

                      <el-checkbox
                        v-model="item.select"
                        @change="TwoSelect(item)"
                      ></el-checkbox>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-else class="noTableStyle">{{$t('m.暂无数据')}}</div>
          </div>
          <div class="contract" v-if="tableActive == 2">
            <div class="title">
              <SearchBox
                class="topColumn"
                @btnSubmit="searchBtn"
                @btnBack="btnBack"
                :searchData="contractSearch"/>
              <div class="columnRight">
                <span @click="synchronizationV5">{{$t('m.同步资产')}}</span>
              </div>
            </div>
            <div v-if="contractTable.length !== 0" class="tableStyle">
              <div class="tableTitle">
                <div>{{$t('m.账户')}}</div>
                <div>{{$t('m.币种')}}</div>
                <div style="width: 500px">{{$t('m.可用')}}</div>
                <div></div>
                <el-checkbox
                  @change="allContractSelect"
                  v-model="contractSelectBtn"
                ></el-checkbox>
              </div>
              <div class="tableNav">
                <div class="tableItem" v-for="(item, index) in contractTable" :key="'tableItem' + index">
                  <div class="tableFirst">
                    <div>
                      {{ item.remarkCode ? item.remarkCode : item.apiName }}
                    </div>
                    <div>{{ item.coinType }}</div>
                    <div style="width: 500px">{{ item.available }}</div>
                    <div class="contractBtn">
                      <span @click="transferDetermineBtn(item)">{{$t('m.划转')}}</span>
                    </div>
                    <el-checkbox
                      @change="FirstSelect(item)"
                      v-model="item.select"
                    ></el-checkbox>
                  </div>
                </div>
              </div>
            </div>
            <div v-else class="noTableStyle">{{$t('m.暂无数据')}}</div>
          </div>
        </div>
        <div class="paginStyle">
          <div></div>
          <div v-if="listNumber >= 10" class="paginCenter">
            <span>{{$t('m.共计订单&{1}条').replace('&(1)', listNumber)}}</span>
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page.sync="currentPage"
              :page-size="10"
              background
              layout="prev, pager, next, jumper"
              :total="listNumber"
            >
            </el-pagination>
          </div>
          <div v-if="tableActiveData == 0">
            {{$t('m.已选择订单&(1)条').replace('&(1)', (selectRowList ? selectRowList.length : 0))}}
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      :title="$t('m.快速兑换USDT')"
      :visible.sync="activeShow"
      width="400px"
      center
      :before-close="handleClose"
      :close-on-click-modal="false">
      <div class="dialog-nav">
        <div class="dialog-top">{{$t('m.确认选中的持仓立即兑换成投入币种？')}}</div>
        <div>{{$t('m.已冻结的持仓，需要先撤销委托订单可兑换哦')}}</div>
      </div>
      <div slot="footer">
        <div class="dialog-footer">
          <div class="footerBack" @click="activeShow = false">{{$t('m.取消')}}</div>
          <div class="footerBtn" @click="activeBtn">{{$t('m.确定')}}</div>
        </div>
      </div>
    </el-dialog>

    <!-- 终止订单链 -->
    <el-dialog
      title="终止订单"
      :visible.sync="chainShow"
      width="400px"
      center
      :before-close="handleClose"
      :close-on-click-modal="false">
      <div class="dialog-nav">
        <div class="dialog-top">{{$t('m.确认终止后订单将自动撤消未成交的委托单。')}}</div>
      </div>
      <div slot="footer">
        <div class="dialog-footer">
          <div class="footerBack" @click="chainShow = false">{{$t('m.取消')}}</div>
          <div class="footerBtn" @click="chainBtn">{{$t('m.确定')}}</div>
        </div>
      </div>
    </el-dialog>

    <!-- 终止按钮 -->
    <el-dialog
      title="终止"
      :visible.sync="terminationShow"
      width="20%"
      :before-close="handleClose"
      :close-on-click-modal="false">
      <div class="termination">
        <div class="title">{{$t('m.终止确认')}}</div>
        
        <div class="text">
          {{$t('m.请确认终止现有策略实例&(1)个，确认终止后，策略将自动撤销未成交的委托单，并兑换成投入币种。').replace('&(1)', selectRowList.length)}}</div>

        <div class="tips">
          {{$t('m.此过程将持续几分钟，请耐心等待！您可以查看其他页面，但不可进行操作，以免数据出错。')}}</div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="terminationShow = false">{{$t('m.取 消')}}</el-button>
        <el-button type="primary" @click="batchStrategyEnd">{{$t('m.确 定')}}</el-button>
      </span>
    </el-dialog>

    <!-- 修改参数 -->
    <template v-if="modifyParametersShow">
      <el-dialog
        :title="$t('m.修改参数')"
        :visible.sync="modifyParametersShow"
        width="40%"
        :before-close="handleClose"
        :close-on-click-modal="false">
        <div class="modifyParameters">
          <p class="otherSetting-title">{{$t('m.参数设置')}}</p>

          <!-- 其他策略 -->
          <template
            v-if="
              strategyId != 'WL20210512112808681' &&
              strategyId != 'WL20190402171936974x' &&
              strategyId != 'WL202007311426363425x' &&
              strategyId != 'WL202007311426363424x' &&
              strategyId != 'WL202X0512112808681' &&
              strategyName != '信号马丁' &&
              strategyId != 'WL20210624182847743'
            ">
            <template v-for="(item, index) in reserves">

              <div class="parameter-box" :key="'reserves1' + index"
                v-if="item.visiable == '1' || strategyId == 'CL20181115155643599x'">
                <div class="left">
                  <span class="reserves-name">{{ item.name }}</span>
                  <img
                    class="jieshi-tips"
                    src="../../assets/icon/jieshi.png"
                    alt=""
                    @click="reservesTips(item.comment)"
                    v-if="item.name != '单格投入' && strategyId == 'CL20181115155003478x'" />

                  <span
                    style="margin-left: 6px"
                    v-if=" item.name == '单格投入' && strategyId == 'CL20181115155003478x'"></span>
                  
                  <!-- 不计算范围 -->
                  <span
                    v-if="(
                      (item.valueMin == '' && item.valueMax == '') ||
                      (item.valueMin == '0' && item.valueMax == '0')) &&
                      item.name != '价格上限' &&
                      item.name != '价格下限'">
                      {{
                      $t('m.范围') 
                      + " " +
                      (
                        item.valueMin && (item.valueMin != 0 || item.valueMax != 0)
                        ? item.valueMin
                        : item.start
                      )
                      + "~" +
                      (
                        item.valueMax && (item.valueMin != 0 || item.valueMax != 0)
                        ? item.valueMax
                        : item.end
                      )
                    }}</span>

                  <!-- 计算范围 -->
                  <span
                    v-if="
                      item.valueMin != '' &&
                      item.valueMax != '' &&
                      item.valueMin != '0' &&
                      item.valueMax != '0' &&
                      item.name != '价格上限' &&
                      item.name != '价格下限' ">
                      {{$t('m.范围') 
                      + " " +
                      calculation(
                        tradePairs.investCoin,
                        tradePairs,
                        item.valueMin && (item.valueMin != "" || item.valueMax != "")
                        ? item.valueMin
                        : item.start) 
                      +  "~" +
                      calculation(
                        tradePairs.investCoin,
                        tradePairs,
                        item.valueMax && (item.valueMin != 0 || item.valueMax != 0)
                        ? item.valueMax
                        : item.end)
                    }}</span
                  >
                </div>

                <div class="right">
                  <el-input
                    :placeholder="$t('m.请输入内容')"
                    v-model="item.value"
                    :disabled="tableActiveTwo == 1"
                    @blur="parameterVerification($event, item)"
                  >
                    <!-- <template
                      slot="append"
                      v-if="item.valueMin != 0 || item.valueMax != 0 item.name=='价格上限'||item.name=='价格下限'"
                      >{{ tradePairs.investCoin }}</template
                    > -->
                    <i
                      class="iconStyle"
                      slot="suffix"
                      v-if="
                        item.valueMin != 0 ||
                        item.valueMax != 0 ||
                        item.name == '价格上限' ||
                        item.name == '价格下限'
                      "
                      >{{ tradePairs.investCoin }}</i
                    >
                    <i
                      class="iconStyle"
                      slot="suffix"
                      v-if="item.valueType == 'percent'"
                      >%</i>
                  </el-input>
                </div>
              </div>
            </template>
          </template>

          <!-- 恒量定投 -->
          <template v-if="strategyId == 'WL20190402171936974x'">
            <template v-for="(item, index) in reserves">
              <!-- 定投周期 -->
              <div class="parameter-box" :key="'reserves2' + index" 
              v-if="item.name == '定投周期'">
                <div class="left">
                  <span class="reserves-name">{{ item.name }}</span>
                  <img
                    class="jieshi-tips"
                    src="../../assets/icon/jieshi.png"
                    alt=""
                    @click="reservesTips(item.comment)"
                  />
                </div>
                <div class="right">
                  <el-cascader
                    v-model="fixedTime"
                    :options="times"
                    @change="handleChange"
                    :disabled="tableActiveTwo == 1"
                  ></el-cascader>
                </div>
              </div>
              <!-- 单笔金额 -->
              <div class="parameter-box" :key="'reserves2' + index"
              v-if="item.name == '单笔金额'">
                <div class="left">
                  <span class="reserves-name">{{ item.name }}</span>
                  <img
                    class="jieshi-tips"
                    src="../../assets/icon/jieshi.png"
                    alt=""
                    @click="reservesTips(item.comment)"
                  />
                  <span v-if="item.valueMin || item.start">
                    {{$t('m.范围') 
                    + " " +
                    (item.valueMin && (item.valueMin != 0 || item.valueMax != 0)
                    ? item.valueMin
                    : item.start) 
                    + "~" +
                    (item.valueMax && (item.valueMin != 0 || item.valueMax != 0)
                      ? item.valueMax
                      : item.end)
                  }}</span>
                </div>
                <div class="right">
                  <el-input
                    :disabled="tableActiveTwo == 1"
                    :placeholder="$t('m.请输入内容')"
                    v-model="item.value"
                    @blur="parameterVerification($event, item)"
                  >
                    <template slot="append">{{
                      tradePairs.investCoin
                    }}</template>
                  </el-input>
                </div>
              </div>
            </template>
          </template>

          <!-- 海风马丁  WL20210512112808681 -->
          <template
            v-if="
              strategyId == 'WL20210512112808681' ||
              strategyId == 'WL202X0512112808681' ||
              strategyName == '信号马丁'
            ">
            <template v-for="(item, index) in reserves">
              <!-- 补仓金额模式 -->
              <template>
                <div
                  class="parameter-box" :key="'reserves3' + index"
                  v-if="item.visiable == '1' && item.name == 'index补仓金额模式'">
                  
                  <div class="special-box">
                    <span class="special-box-title">{{ item.name }}</span>
                    
                    <div class="special-box-cont">
                      <span
                        :class="{ on: replenishment }"
                        @click="replenishment = true"
                        >{{$t('m.倍数补仓')}}</span>
                        <span
                        :class="{ on: !replenishment }"
                        @click="replenishment = false"
                        >{{$t('m.增量补仓')}}</span
                      >
                    </div>
                  </div>
                </div>
                <div
                  class="parameter-box" :key="'reserves3' + index"
                  v-if="
                    (replenishment &&
                      (item.name == '补仓金额倍数' ||
                        item.name == '倍投起始单' ||
                        item.name == '最大做单数')) ||
                    (!replenishment &&
                      (item.name == '补仓金额增量' ||
                        item.name == '最大做单数'))
                  ">
                  <div class="left">
                    <span class="reserves-name">{{ item.name }}</span>
                    <img
                      class="jieshi-tips"
                      src="../../assets/icon/jieshi.png"
                      alt=""
                      @click="reservesTips(item.comment)"
                    />
                    <span v-if="item.valueMin || item.start">{{
                      $t('m.范围')
                      + " " +
                      (item.valueMin && (item.valueMin != 0 || item.valueMax != 0)
                        ? item.valueMin
                        : item.start) 
                      + "~" +
                      (item.valueMax && (item.valueMin != 0 || item.valueMax != 0)
                        ? item.valueMax
                        : item.end)
                    }}</span>
                  </div>
                  <div class="right">
                    <el-input
                      :disabled="tableActiveTwo == 1"
                      :placeholder="$t('m.请输入内容')"
                      v-model="item.value"
                      @blur="parameterVerification($event, item)">
                      <template
                        slot="append"
                        v-if="item.valueMin != 0 || item.valueMax != 0">{{ tradePairs.investCoin }}</template>
                    </el-input>
                  </div>
                </div>
              </template>

              <!-- 补仓间隔模式 -->
              <template>
                <div
                  class="parameter-box"  :key="'reserves3' + index"
                  v-if="item.visiable == '1' && item.name == '补仓间隔模式'">
                  <div class="special-box">
                    <span class="special-box-title">{{ item.name }}</span>
                    <div class="special-box-cont">
                      <span
                        :class="{ on: intervalMode }"
                        @click="intervalMode = true">
                        {{$t('m.固定间隔')}}</span>
                       <span
                        :class="{ on: !intervalMode }"
                        @click="intervalMode = false">
                        {{$t('m.自定义间隔')}}</span>
                    </div>
                  </div>
                </div>
                <div
                  class="parameter-box" :key="'reserves3' + index"
                  v-if="
                    (intervalMode &&
                      (item.id == 'reserve_38' || item.id == 'reserve_39')) ||
                    (!intervalMode &&
                      (item.id == 'reserve_40' ||
                        item.name.search('replenishment') != -1 ||
                        item.name.search('次补仓比例') != -1))">
                  <div class="left">
                    <span class="reserves-name">{{ item.name }}</span>
                    <img
                      class="jieshi-tips"
                      src="../../assets/icon/jieshi.png"
                      alt=""
                      @click="reservesTips(item.comment)"
                    />
                    <span v-if="item.valueMin || item.start">{{
                      $t('m.范围')
                      + " " +
                      (item.valueMin && (item.valueMin != 0 || item.valueMax != 0)
                        ? item.valueMin
                        : item.start) 
                      + "~" +
                      (item.valueMax && (item.valueMin != 0 || item.valueMax != 0)
                        ? item.valueMax
                        : item.end)
                    }}</span>
                  </div>
                  <div class="right">
                    <el-input
                      :disabled="tableActiveTwo == 1"
                      :placeholder="$t('m.请输入内容')"
                      v-model="item.value"
                      @blur="parameterVerification($event, item)">
                      <template
                        slot="append"
                        v-if="item.valueMin != 0 || item.valueMax != 0">{{ tradePairs.investCoin }}</template>
                      <template slot="append" v-if="item.valueType == 'percent'">%</template>
                    </el-input>
                  </div>
                </div>
              </template>

              <!-- 其他参数 -->
              <template>
                <div
                  class="parameter-box" :key="'reserves3' + index"
                  v-if="
                    item.id != 'reserve_38' &&
                    item.id != 'reserve_37' &&
                    item.id != 'reserve_39' &&
                    item.id != 'reserve_7' &&
                    item.id != 'reserve_6' &&
                    item.id != 'reserve_9' &&
                    item.id != 'reserve_10' &&
                    item.id != 'reserve_40' &&
                    item.id != 'reserve_35' &&
                    item.id != 'reserve_52' &&
                    item.id != 'reserve_53' &&
                    item.id != 'reserve_54' &&
                    item.name.search('replenishment') == -1 &&
                    item.name.search('次补仓比例') == -1
                  ">
                  <div class="left">
                    <span class="reserves-name">{{ item.name }}</span>
                    <img
                      class="jieshi-tips"
                      src="../../assets/icon/jieshi.png"
                      alt=""
                      @click="reservesTips(item.comment)"
                    />
                    <span
                      v-if="
                        (item.valueMin || item.start) &&
                        item.name != '价格上限' &&
                        item.name != '价格下限'">{{
                        $t('m.范围') 
                        + " " +
                        (item.valueMin && (item.valueMin != 0 || item.valueMax != 0)
                          ? item.valueMin
                          : item.start) 
                        + "~" +
                        (item.valueMax && (item.valueMin != 0 || item.valueMax != 0)
                          ? item.valueMax
                          : item.end)
                      }}</span
                    >
                  </div>
                  <div class="right">
                    <el-input
                      :disabled="tableActiveTwo == 1"
                      :placeholder="$t('m.请输入内容')"
                      v-model="item.value"
                      @blur="parameterVerification($event, item)">
                      <template
                        slot="append"
                        v-if="item.valueMin != 0 || item.valueMax != 0">{{ tradePairs.investCoin }}</template>
                      <template slot="append" v-if="item.valueType == 'percent'">%</template>
                    </el-input>
                  </div>
                </div>
              </template>

              <!-- 信号参数 -->
              <template
                v-if="
                  strategyId == 'WL202X0512112808681' ||
                  strategyName == '信号马丁'">
                <div
                  class="parameter-box" :key="'reserves3' + index"
                  v-if="
                    item.id == 'reserve_52' ||
                    item.id == 'reserve_53' ||
                    item.id == 'reserve_54'">
                  <div class="left">
                    <span class="reserves-name">{{ item.name }}</span>
                    <img
                      class="jieshi-tips"
                      src="../../assets/icon/jieshi.png"
                      alt=""
                      @click="reservesTips(item.comment)"
                    />
                  </div>
                  <div class="right">
                    <div>
                      <el-select
                        v-model="item.signalName"
                        :placeholder="$t('m.请选择')"
                        @change="signalChange($event, item)"
                      >
                        <el-option
                          v-for="(val, idx) in signalList"
                          :key="idx"
                          :label="val.signalName"
                          :value="val"
                        >
                        </el-option>
                      </el-select>
                    </div>
                    <div class="signal-params">
                      <div class="one">
                        <el-select
                          v-model="item.paramBodyLabel"
                        :placeholder="$t('m.请选择')"
                          @change="paramBodyValue($event, item)"
                        >
                          <template v-if="item.paramBody">
                            <el-option
                              v-for="(e, idx) in item.paramBody"
                              :key="idx"
                              :label="e.label"
                              :value="e"
                            >
                            </el-option>
                          </template>
                        </el-select>
                      </div>
                      <div class="two">
                        <el-select
                          v-model="item.ops"
                        :placeholder="$t('m.请选择')"
                          @change="opsChange($event, item)"
                        >
                          <el-option
                            v-for="(ops, idx) in SymbolList"
                            :key="idx"
                            :label="ops.value"
                            :value="ops.value"
                          >
                          </el-option>
                        </el-select>
                      </div>
                      <div class="three">
                        <el-input
                          v-model="item.signalValue"
                          :placeholder="$t('m.请输入阈值')"
                          @change="signalValueChange($event, item)"
                        ></el-input>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </template>
          </template>

          <!-- 合约策略 -->
          <template
            v-if="
              strategyId == 'WL202007311426363425x' ||
              strategyId == 'WL202007311426363424x' ||
              strategyId == 'WL20210624182847743'">

            <template v-for="(item, index) in reserves">
              <!-- 合约网格 等比/等差 -->
              <template
                v-if="
                  (gridDistribution && item.name == '网格间隔(等比)') ||
                  (!gridDistribution && item.name == '网格间隔(等差)')
                ">
                <div class="parameter-box" :key="'reserves4' + index">
                  <div class="special-box">
                    <span class="special-box-title">
                      {{$t('m.网格分布')}}</span>
                    <div class="special-box-cont">
                      <span
                        :class="{ on: gridDistribution }"
                        @click="gridDistribution = true"
                        >{{$t('m.等比网格')}}</span>
                        
                        <span
                        :class="{ on: !gridDistribution }"
                        @click="gridDistribution = false"
                        >{{$t('m.等差网格')}}</span
                      >
                    </div>
                  </div>
                </div>

                <div class="parameter-box" :key="'reserves4' + index"
                v-if="item.visiable == '1'">
                  <div class="left">
                    <span class="reserves-name">
                      <span class="reserves-name">{{ item.name }}</span>
                      <img
                        class="jieshi-tips"
                        src="../../assets/icon/jieshi.png"
                        alt=""
                        @click="reservesTips(item.comment)"
                      />
                      <!-- <img src="../../assets/icon/jieshi.png" alt="" @click="reservesTips(item.comment)"> -->
                    </span>

                    <!-- 不计算范围 -->
                    <span
                      v-if="
                        (item.valueMin == '' && item.valueMax == '') ||
                        (item.valueMin == '0' && item.valueMax == '0')
                      ">{{
                        $t('m.范围')
                        + " " +
                        (item.valueMin && (item.valueMin != 0 || item.valueMax != 0)
                          ? item.valueMin
                          : item.start) 
                        + "~" +
                        (item.valueMax && (item.valueMin != 0 || item.valueMax != 0)
                          ? item.valueMax
                          : item.end)
                      }}</span
                    >

                    <!-- 计算范围 -->
                    <span
                      v-if="
                        item.valueMin != '' &&
                        item.valueMax != '' &&
                        item.valueMin != '0' &&
                        item.valueMax != '0'">{{
                        $t('m.范围') +
                        calculation(
                          tradePairs.investCoin,
                          tradePairs,
                          item.valueMin && (item.valueMin != "" || item.valueMax != "")
                          ? item.valueMin
                          : item.start) 
                        + "~" +
                        calculation(
                          tradePairs.investCoin,
                          tradePairs,
                          item.valueMax && (item.valueMin != 0 || item.valueMax != 0)
                          ? item.valueMax
                          : item.end)
                      }}</span
                    >
                  </div>
                  <div class="right">
                    <el-input
                      :disabled="tableActiveTwo == 1"
                      :placeholder="$t('m.请输入内容')"
                      v-model="item.value"
                      @blur="parameterVerification($event, item)">
                      <i
                        class="iconStyle"
                        slot="suffix"
                        v-if="item.valueMin != 0 || item.valueMax != 0">{{ tradePairs.investCoin }}</i>

                      <i
                        class="iconStyle"
                        slot="suffix"
                        v-if="item.valueType == 'percent'">%</i>
                    </el-input>
                  </div>
                </div>
              </template>

              <template
                v-if="
                  item.name != '价格上限' &&
                  item.name != '价格下限' &&
                  item.name != '最大做多单数' &&
                  item.name != '最大做空单数' &&
                  item.name != '网格间隔(等差)' &&
                  item.name != '网格间隔(等比)' &&
                  item.name !== '补仓金额模式' &&
                  item.name !== '补仓下单模式' &&
                  item.name !== '止盈平仓模式' &&
                  item.name !== '止盈下单模式'
                ">
                <div class="parameter-box"  :key="'reserves4' + index"
                v-if="item.visiable == '1'">
                  <div class="left">
                    <span class="reserves-name">
                      <span class="reserves-name">{{ item.name }}</span>
                      <img
                        class="jieshi-tips"
                        src="../../assets/icon/jieshi.png"
                        alt=""
                        @click="reservesTips(item.comment)"
                      />
                      <!-- <img src="../../assets/icon/jieshi.png" alt="" @click="reservesTips(item.comment)"> -->
                    </span>

                    <!-- 不计算范围 -->
                    <span
                      v-if="
                        (item.valueMin == '' && item.valueMax == '') ||
                        (item.valueMin == '0' && item.valueMax == '0')">{{
                        $t('m.范围') 
                        + " " +
                        (item.valueMin && (item.valueMin != 0 || item.valueMax != 0)
                          ? item.valueMin
                          : item.start) 
                        + "~" +
                        (item.valueMax && (item.valueMin != 0 || item.valueMax != 0)
                          ? item.valueMax
                          : item.end)
                      }}</span
                    >

                    <!-- 计算范围 -->
                    <span
                      v-if="
                        item.valueMin != '' &&
                        item.valueMax != '' &&
                        item.valueMin != '0' &&
                        item.valueMax != '0'
                      "
                      >{{
                        $t('m.范围') + 
                        calculation(
                          tradePairs.investCoin,
                          tradePairs,
                          item.valueMin && (item.valueMin != "" || item.valueMax != "")
                          ? item.valueMin
                          : item.start
                        ) 
                        + "~" +
                        calculation(
                          tradePairs.investCoin,
                          tradePairs,
                          item.valueMax && (item.valueMin != 0 || item.valueMax != 0)
                          ? item.valueMax
                          : item.end
                        )
                      }}</span
                    >
                  </div>
                  <div class="right">
                    <el-input
                      :disabled="tableActiveTwo == 1"
                      :placeholder="$t('m.请输入内容')"
                      v-model="item.value"
                      @blur="parameterVerification($event, item)"
                    >
                      <!-- <template slot="append" v-if="(item.valueMin != 0 || item.valueMax != 0)">{{tradePairs.investCoin}}</template> -->
                      <i
                        class="iconStyle"
                        slot="suffix"
                        v-if="item.valueMin != 0 || item.valueMax != 0"
                        >{{ tradePairs.investCoin }}</i>
                      <i
                        class="iconStyle"
                        slot="suffix"
                        v-if="item.valueType == 'percent'">%</i>
                    </el-input>
                  </div>
                </div>
              </template>

              <template
                v-if="
                  (item.name == '最大做多单数' && contractType == 1) ||
                  (item.name == '最大做空单数' && contractType == 0) ||
                  (contractType == 2 && (item.name == '最大做空单数' || item.name == '最大做多单数'))">

                <div class="parameter-box" :key="'reserves4' + index"
                v-if="item.visiable == '1'">
                  <div class="left">
                    <span class="reserves-name">
                      <span class="reserves-name">{{ item.name }}</span>
                      <img
                        class="jieshi-tips"
                        src="../../assets/icon/jieshi.png"
                        alt=""
                        @click="reservesTips(item.comment)"
                      />
                      <!-- <img src="../../assets/icon/jieshi.png" alt="" @click="reservesTips(item.comment)"> -->
                    </span>

                    <!-- 不计算范围 -->
                    <span
                      v-if="
                        (item.valueMin == '' && item.valueMax == '') ||
                        (item.valueMin == '0' && item.valueMax == '0')
                      "
                      >{{
                        $t('m.范围')
                        + " " +
                        (item.valueMin && (item.valueMin != 0 || item.valueMax != 0)
                        ? item.valueMin
                        : item.start) 
                        + "~" +
                        (item.valueMax && (item.valueMin != 0 || item.valueMax != 0)
                        ? item.valueMax
                        : item.end)
                      }}</span>

                    <!-- 计算范围 -->
                    <span
                      v-if="
                        item.valueMin != '' &&
                        item.valueMax != '' &&
                        item.valueMin != '0' &&
                        item.valueMax != '0'">{{
                        $t('m.范围') +
                        calculation(
                          tradePairs.investCoin,
                          tradePairs,
                          item.valueMin && (item.valueMin != "" || item.valueMax != "")
                          ? item.valueMin
                          : item.start
                        ) 
                        + "~" +
                        calculation(
                          tradePairs.investCoin,
                          tradePairs,
                          item.valueMax && (item.valueMin != 0 || item.valueMax != 0)
                          ? item.valueMax
                          : item.end
                        )
                      }}</span
                    >
                  </div>
                  <div class="right">
                    <el-input
                      :disabled="tableActiveTwo == 1"
                      :placeholder="$t('m.请输入内容')"
                      v-model="item.value"
                      @blur="parameterVerification($event, item)">
                      <i
                        class="iconStyle"
                        slot="suffix"
                        v-if="item.valueMin != 0 || item.valueMax != 0"
                        >{{ tradePairs.investCoin }}</i>
                      <i
                        class="iconStyle"
                        slot="suffix"
                        v-if="item.valueType == 'percent'">%</i>
                    </el-input>
                  </div>
                </div>
              </template>

              <!-- SMT合约 -->
              <template v-if="strategyId == 'WL20210624182847743'">

                <div class="parameter-box-smt" :key="'reserves4' + index"
                  v-if="item.name == '补仓金额模式'">

                  <div class="right">
                    <div class="special-box-cont">
                      <span
                        :class="{ on: replenishmentbutionOne }"
                        @click="replenishmentbutionSelect(true, '倍数补仓')">
                        {{$t('m.倍数补仓')}}</span>
                        <span
                        :class="{ on: !replenishmentbutionOne }"
                        @click="replenishmentbutionSelect(false, '增量补仓')">
                        {{$t('m.增量补仓')}}</span>
                    </div>
                  </div>
                </div>

                <div class="parameter-box-smt" :key="'reserves4' + index"
                  v-if="item.name == '补仓下单模式'">
    
                  <div class="right">
                    <div class="special-box-cont">
                      <span
                        :class="{ on: replenishmentbutionTwo }"
                        @click="replenishmentbutionSelect(true, '追踪补仓')">
                        {{$t('m.追踪补仓')}}</span>
                      <span
                        :class="{ on: !replenishmentbutionTwo }"
                        @click="replenishmentbutionSelect(false, '预埋单补仓')">
                        {{$t('m.预埋单补仓')}}</span>
                    </div>
                  </div>
                </div>

                <div class="parameter-box-smt" :key="'reserves4' + index"
                  v-if="item.name == '止盈平仓模式'">

                  <div class="right">
                    <div class="special-box-cont">
                      <span
                        :class="{ on: replenishmentbutionThree }"
                        @click="replenishmentbutionSelect(true, '一次性止盈')">
                        {{$t('m.一次性止盈')}}</span>
                      <span
                        :class="{ on: !replenishmentbutionThree }"
                        @click="replenishmentbutionSelect(false, '逐单止盈')">
                        {{$t('m.逐单止盈')}}</span>
                    </div>
                  </div>
                </div>
                <div class="parameter-box-smt"  :key="'reserves4' + index"
                  v-if="item.name == '止盈下单模式'">

                  <div class="right">
                    <div class="special-box-cont">
                      <span
                        :class="{ on: replenishmentbutionFour }"
                        @click="replenishmentbutionSelect(true, '追踪止盈')">
                        {{$t('m.追踪止盈')}}</span>
                      <span
                        :class="{ on: !replenishmentbutionFour }"
                        @click="replenishmentbutionSelect(false, '预埋单止盈')">
                        {{$t('m.预埋单止盈')}}</span>
                    </div>
                  </div>
                </div>
              </template>

              <template v-if="item.name == '价格上限' || item.name == '价格下限'" >
                <div class="parameter-box"  :key="'reserves4' + index">
                  <div class="left">
                    <span class="reserves-name">
                      <span class="reserves-name">{{ item.name }}</span>
                      <img
                        class="jieshi-tips"
                        src="../../assets/icon/jieshi.png"
                        alt=""
                        @click="reservesTips(item.comment)"
                      />
                    </span>
                  </div>
                  <div class="right">
                    <el-input
                      :disabled="tableActiveTwo == 1"
                      :placeholder="$t('m.请输入内容')"
                      v-model="item.value"
                      @blur="parameterVerification($event, item)">
                      <i class="iconStyle" slot="suffix">
                        {{$t('m.选填')}}</i>
                    </el-input>
                  </div>
                </div>
              </template>
            </template>

            <!-- 开仓阈值 -->
            <div class="parameter-box">
              <div class="left">
                <span class="reserves-name">
                  <span>{{$t('m.策略开仓阈值')}}</span>
                </span>
              </div>
              <div class="right">
                <el-input
                  :disabled="tableActiveTwo == 1"
                  :placeholder="$t('m.请输入内容')"
                  v-model="openingThreshold"
                  @change="openingThresholdChange">
                  <i class="iconStyle" slot="suffix">%</i>
                </el-input>
              </div>
            </div>
          </template>

          <p class="otherSetting-title">{{$t('m.其他设置')}}</p>

          <div class="otherSetting">
            <div class="parameter-box">
              <div class="title">
                <span>{{$t('m.总收益止盈')}}</span>
                <img
                  class="jieshi-tips"
                  src="../../assets/icon/jieshi.png"
                  alt=""
                  @click="SP()"/>
              </div>
              <div class="right">
                <el-input
                  :disabled="tableActiveTwo == 1"
                  :placeholder="$t('m.选填')"
                  v-model="stopProfit"
                  @blur="otherSettingCheck('止盈', stopProfit)">
                  <i class="iconStyle" slot="suffix">%</i>
                </el-input>
              </div>
            </div>
            <div class="parameter-box">
              <div class="title">
                <span>{{$t('m.总亏损止损')}}</span>
                <img
                  class="jieshi-tips"
                  src="../../assets/icon/jieshi.png"
                  alt=""
                  @click="SL()"
                />
              </div>
              <div class="right">
                <el-input
                  :disabled="tableActiveTwo == 1"
                  :placeholder="$t('m.选填')"
                  v-model="stopLoss"
                  @blur="otherSettingCheck('止损', stopLoss)">
                  <i class="iconStyle" slot="suffix">%</i>
                </el-input>
              </div>
            </div>

            <div class="parameter-box">
              <div class="title">
                <span>{{$t('m.定时自动终止')}}</span>
                <img
                  class="jieshi-tips"
                  src="../../assets/icon/jieshi.png"
                  alt=""
                  @click="timeTips()"/>
              </div>
              <div class="right">
                <el-date-picker
                  :disabled="tableActiveTwo == 1"
                  v-model="stopTime"
                  class="date-picker"
                  type="datetime"
                  :placeholder="$t('m.选填')"
                  :picker-options="pickerOptions"
                  :clearable="false">
                </el-date-picker>
              </div>
            </div>
          </div>
        </div>
        <span slot="footer" class="dialog-footer" v-if="nowData !== false">
          <div class="btn" @click="confirmUpdateParam()">
            {{$t('m.修改参数')}}</div>
        </span>
      </el-dialog>
    </template>

    <!-- 修改订单链 -->
    <el-dialog
      :title="$t('m.提示')"
      :visible.sync="dialogVisible"
      width="70%"
      :before-close="handleClose"
      :show-close="false"
    >
      <div>
        <OrderChain
          ref="child"
          :transactionType="transactionType"
          :controlsPrice="controlsPrice"
        />
      </div>
      <span v-if="!btnSubmitShow" slot="footer" class="dialog-footer">
        <el-button
          @click="
            dialogVisible = false;
            batchModify = false;">
            {{$t('m.取 消')}}</el-button>
        <el-button type="primary" @click="updateOrderChain()">确 定</el-button>
      </span>
      <span v-else slot="footer" class="dialog-footer">
        <el-button
          @click="
            dialogVisible = false;
            batchModify = false;
          ">
          {{$t('m.关 闭')}}</el-button
        >
      </span>
    </el-dialog>
    <!-- 划转 -->
    <el-dialog
      :title="$t('m.划转')"
      :visible.sync="transfer"
      width="400px"
      center
      :before-close="handleClose"
      :close-on-click-modal="false"
    >
      <div class="dialog-nav">
        <div class="dialog-box">
          <div>
            <div class="dialog-top">
              {{$t('m.从')}}:

              <el-select
                size="medium"
                v-model="topValue"
                @change="topValueChange"
                :placeholder="$t('m.请选择')">
                <el-option
                  v-for="(item, index) in topOptions"
                  :key="index"
                  :value="item.name">
                </el-option>
              </el-select>

            </div>
            <div class="dialog-top">
             {{$t('m.到')}}:
              <el-select
                size="medium"
                v-model="twoValue"
                @change="twoValueChange"
                :placeholder="$t('m.请选择')">
                <el-option
                  v-for="(item, index) in twoOptions"
                  :key="index"
                  :value="item.name">
                </el-option>
              </el-select>
            </div>
          </div>
          <img
            @click="transformationBtn()"
            class="dialog-img"
            src="../../assets/icon/zhuanhuan-big.png"
            alt=""
          />
        </div>
        <div class="dialog-currency" style="margin-bottom: 15px">
          <span>{{$t('m.币种')}}</span>
          <el-select size="medium" v-model="currencyValue" 
          :placeholder="$t('m.请选择')">
            <el-option
              v-for="(item, index) in currencyList"
              :key="index"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </div>
        <div class="dialog-currency">
          <span>{{$t('m.划转数量')}}</span>
          <el-input
            v-model="inputOptions"
            type="number"
            placeholder="请输入内容"
            :class="inputStyle ? 'inputStyleItem' : ''"
            @focus="inputNumberFocus($event)"
            @blur="inputNumber($event)" >
            <template slot="append">USDT | 
              <span
                style="cursor: pointer; color: #2174ff"
                @click="inputOptions = usdtAvailable"
                >{{$t('m.全部')}}</span>
            </template>
          </el-input>
        </div>
        <span style="margin-top: 10px"
          >{{$t('m.可用划转')}} {{ usdtAvailable + " " + currencyValue }}</span
        >
      </div>
      <div slot="footer">
        <div class="dialog-footer">
          <div
            class="footerBack"
            @click="transfer = false; inputOptions = ''; currencyValue = 'USDT';">
            {{$t('m.取消')}}</div>
          <div class="footerBtn" @click="transferDetermine()">
            {{$t('m.确定')}}</div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>









<script>
import * as echarts from "echarts";
import DetailsData from "../../components/detailsData.vue";
import SearchBox from "../../components/searchBox.vue";
import OrderChain from "../../components/orderChain.vue";
import { times } from "../../assets/js/time";
import moment from "moment";
import {
  getSpotApiList,
  queryOrderChainList,
  queryAccountData,
  userManagementDetails,
  userManagementExchangelist,
  batchSynchSpotApi,
  batchSuspend,
  batchStrategyEnd,
  fundHistoryDetails,
  queryHistoryAccountData,
  batchOrderChainEnd,
  batchUpdateParam,
  executeDetail,
  updateParam,
  updateOrderChain,
  findByIdOrderChain,
  contractUpdateParam,
  queryExchangeList,
  getContractApiList,
  batchSynchContractApi,
  quickFlatPosition,
  findFundSpot,
  transfer,
  findContract,
  synchSingelContractApi,
  getProject,
  queryPair,
  quickSwap,
  synchSingleSpotApi,
  batchRestart,
  synchSingelAssetApi,
  batchSynchAssetApi,
  getAssetApiList,
  findFundAssets,
  findContractId,
  querySignalList,
} from "../../request/api";
export default {
  components: { DetailsData, SearchBox, OrderChain },
  watch: {

    currencyValue() {
      if (this.transformation == false) {
        let payload = {
          token: sessionStorage.getItem("token"),
          apiId: this.spotByApp.apiId,
          coin: this.currencyValue,
        };
        this.findFundSpot(payload);
      } else {
        let payload = {
          token: sessionStorage.getItem("token"),
          apiId: this.spotByApp.apiId,
          coin: this.currencyValue,
        };
        this.findFundAssets(payload);
      }
    },
    transfer() {
      if (this.transfer == false) {
        sessionStorage.removeItem("contractDataTwo");
        sessionStorage.removeItem("contractDataOne");
      }
    },
  },
  
  data() {
    return {
      moment,
      textData: [1, 2, 3],
      topTab: ["策略交易", "智能订单", "持仓组合"],
      Tab: ["币币", "合约"],
      TabTwo: ["当前策略", "历史策略"],
      tableActive: 0, //table切换
      tableTopActive: 0,
      tableActiveTwo: 0,
      modify: true, //修改按钮
      details: false, //取消详情按钮
      detailBtn: true,
      detailsDelete: false,
      // 表格数据
      tableData: [],
      // 搜索数据
      searchTable: [],
      orderData: [],
      orderChainList: [
        [
          {
            title: "标签",
            nav: "label",
          },
          {
            title: "账户",
            nav: "publicPeopleName",
          },
          {
            title: "初始投入",
            nav: "investNum",
          },
          {
            title: "订单收益",
            nav: "profit",
          },
          {
            title: "执行时长",
            nav: "createTime",
          },
          {
            title: "订单链",
            nav: "order",
          },
          {
            title: "当前订单状态",
            nav: "states",
          },
          {
            title: "当前方向",
            nav: "orderDirection",
          },
        ],
        [
          {
            title: "标签",
            nav: "label",
          },
          {
            title: "账户",
            nav: "publicPeopleName",
          },
          {
            title: "初始投入",
            nav: "investNum",
          },
          {
            title: "订单收益",
            nav: "profit",
          },
          {
            title: "执行时长",
            nav: "createTime",
          },
          {
            title: "订单链",
            nav: "order",
          },
          {
            title: "当前订单状态",
            nav: "states",
          },
        ],
      ],
      // 表头数据
      orderDataList: [
        [
          {
            title: "标签",
            nav: "templateName",
          },
          {
            title: "启动时间",
            nav: "createTime",
          },
          {
            title: "执行时长",
            nav: "nowTime",
          },
          {
            title: "实例编号",
            nav: "codeName",
          },
          {
            title: "总投入",
            nav: "investCoinnNum",
          },
          {
            title: "总收益",
            nav: "floatEarnings",
          },
          {
            title: "总年化",
            nav: "realYearIncome",
          },
          {
            title: "状态",
            nav: "states",
          },
        ],
        [
          {
            title: "标签",
            nav: "label",
          },
          {
            title: "账户",
            nav: "publicPeopleName",
          },
          {
            title: "初始投入",
            nav: "investNum",
          },
          {
            title: "订单收益",
            nav: "profit",
          },
          {
            title: "执行时长",
            nav: "nowTime",
          },
          {
            title: "订单链",
            nav: "order",
          },
          {
            title: "当前订单状态",
            nav: "states",
          },
          {
            title: "当前方向",
            nav: "orderDirection",
          },
        ],
        [
          {
            title: "币种",
            nav: "coinType",
            // width: 100,
          },
          {
            title: "可用",
            nav: "available",
            // width: 120,
          },
          {
            title: "冻结",
            nav: "lock",
            // width: 120,
          },
          {
            title: "折合 投入币种 估值",
            nav: "coinValue",
          },
          // {
          //   title: "折合【投入币种】成本",
          //   nav: "coinCost",
          // },
          // {
          //   title: "折合【投入币种】收益",
          //   nav: "coinIncome",
          // },
          {
            title: "上一次同步时间",
            nav: "updateTime",
          },
        ],
      ],
      // 具体样式
      rowStyleData: [],
      rowStyleList: [
        [
          {
            columnIndex: 5,
            style: [
              {
                state: "true5",
                style: "color:#E12525",
              },

              {
                state: "zero5",
                style: "color:#102859",
              },
              {
                state: "false5",
                style: "color:#26B34B",
              },
            ],
          },
          {
            columnIndex: 6,
            style: [
              {
                state: "true6",
                style: "color:#E12525",
              },

              {
                state: "zero6",
                style: "color:#102859",
              },
              {
                state: "false6",
                style: "color:#26B34B",
              },
            ],
          },
          {
            columnIndex: 7,
            style: [
              {
                state: "执行中",
                style: "color:#2174FF",
              },
              {
                state: "待触发",
                style: "color:#E12525",
              },
              {
                state: "已完成",
                style: "color:#102859",
              },
            ],
          },
        ],
        [
          {
            columnIndex: 6,
            style: [
              {
                state: "执行中",
                style: "color:#2174FF",
              },
              {
                state: "已完成",
                style: "color:#102859",
              },
              {
                state: "订单错误",
                style: "color:#E12525",
              },
            ],
          },
          {
            columnIndex: 7,
            style: [
              {
                state: "买入",
                style: "color:#E12525",
              },
              {
                state: "卖出",
                style: "color:#26B34B",
              },
              {
                state: "开多",
                style: "color:#E12525",
              },
              {
                state: "平空",
                style: "color:#E12525",
              },
              {
                state: "开空",
                style: "color:#26B34B",
              },
              {
                state: "平多",
                style: "color:#26B34B",
              },
            ],
          },
        ],
        [
          // {
          //   columnIndex: 5,
          //   style: [
          //     {
          //       state: "true5",
          //       style: "color:#E12525",
          //     },
          //     {
          //       state: "zero5",
          //       style: "color:#102859",
          //     },
          //     {
          //       state: "false5",
          //       style: "color:#26B34B",
          //     },
          //   ],
          // },
          // {
          //   columnIndex: 4,
          //   style: [
          //     {
          //       state: "true4",
          //       style: "color:#E12525",
          //     },
          //     {
          //       state: "zero4",
          //       style: "color:#102859",
          //     },
          //     {
          //       state: "false4",
          //       style: "color:#26B34B",
          //     },
          //   ],
          // },
          // {
          //   columnIndex: 3,
          //   style: [
          //     {
          //       state: "true3",
          //       style: "color:#E12525",
          //     },
          //     {
          //       state: "zero3",
          //       style: "color:#102859",
          //     },
          //     {
          //       state: "false3",
          //       style: "color:#26B34B",
          //     },
          //   ],
          // },
        ],
      ],
      strategyType: [1, 2],
      options: [1, 2],
      searchData: [],
      searchDataList: [
        [
          {
            name: "标签",
            type: "input",
            filterable: true,
          },
          {
            name: "状态",
            data: ["全部", "执行中", "终止中", "暂停中", "启动中", "启动错误"],
          },
        ],
        [
          {
            name: "标签",
            filterable: true,
            type: "input",
          },
          {
            name: "订单链状态",
            data: ["执行中", "已完成"],
          },
          // {
          //   name: "总投入",
          //   type: "inputTwo",
          // },
        ],
        [
          {
            name: "币种",
            type: "input",
          },
        ],
      ],
      listNumber: 0, //数据总条数
      selectRowList: [], //选中的数据
      currentPage: 1, //分页页数
      publicPeopleMap: null, //左侧栏数据
      val: null, //传递过来的数据
      Example: null, //策略列表
      name: null,
      startCoin: null, //币
      activeShow: false, //快速兑换
      chainShow: false, //订单链
      terminationShow: false, //终止

      //   ******参数*****//
      selectStrategy: null,
      executeDetail: null,
      config: null,
      tradePairs: null,

      modifyParametersShow: false,
      reserves: [],
      reservesCopy: [],
      strategyId: null,
      strategyName: null,

      parameterCheck: true, //参数校验
      otherCheck: true,

      replenishment: true, //海风补仓模式
      intervalMode: true, //海风间隔模式

      fixedTime: [], //恒量定投周期
      copyFixedTime: [], //备份
      times: times,

      // *********合约*************//
      contractTypeShow: false, //合约模式显示
      contractType: 1, //当前合约做多/空
      gridDistribution: true, //网格分布
      openingThreshold: null, //合约开仓阈值

      stopProfit: null,
      stopLoss: null,
      stopTime: null,
      // 合约数据
      //合约交易所
      exchangeList: [],
      // 合约搜索框
      contractSearch: [
        {
          name: "币种",
          type: "input",
        },
      ],
      contractTable: [],
      usdtAvailable: 0,
      inputOptions: null,
      currencyList: ["USDT"],
      currencyValue: "USDT",
      activeShow: false, //快速兑换
      startCoin: null, //初始投入币种
      selectRowList: [], // 币币选中数据
      contractRowList: [], // 合约选中数据
      listNumber: null, //总数据条数
      currentPage: 1, //当前页数

      transfer: false, //划转
      warehouse: null,
      userId: null, //操作员id
      spotByApp: null, //当前划转用户
      topOptions: [{ name: "币币账户", id: 0 }],
      topValue: "币币账户",
      twoOptions: ["永续合约账户"],
      twoValue: "",
      contractActive: 0,
      contractSelectBtn: false, //全选

      // ******自动终止时间日期禁用*****//
      pickerOptions: {
        disabledDate: (time) => {
          return time.getTime() < new Date().getTime();
        },
      },

      // 订单链***********
      dialogVisible: false,
      batchModify: false,
      transactionType: "spot",
      controlsPrice: null,

      orderList: null,
      btnSubmitShow: false, //订单链查看按钮
      tableActiveData: 0,

      inputVerification: null,
      inputStyle: false,
      signalList: [], //信号列表
      SymbolList: [
        { value: "=" },
        { value: ">" },
        { value: "<" },
        { value: "≥" },
        { value: "≦" },
      ],
      parameterCheckList: [], //校验补充

      //SMT合约
      replenishmentbutionOne: true,
      replenishmentbutionTwo: true,
      replenishmentbutionThree: true,
      replenishmentbutionFour: true,
    };
  },
  methods: {
    // 参数解释文案
    reservesTips(v) {
      this.$alert("<div style='color:#102859'>" + v + "</div>", "", {
        confirmButtonText: this.$t('m.确定'),
        dangerouslyUseHTMLString: true,
      });
    },

    // 止盈
    SP() {
      if (this.transactionType == "spot") {
        this.$alert(
          "<div style='color:#102859'>"+
          this.$t('m.止盈根据总收益率计算，策略暂停操作优先级高于止盈止损及定时终止，即暂停状态下此类操作不生效。触发止盈后自动终止策略，系统根据你的选择决定是否执行撤单兑换操作。')+
          "</div>",
          this.$t('m.总收益止盈'),
          {
            confirmButtonText: this.$t('m.确定'),
            dangerouslyUseHTMLString: true,
          }
        );
      } else {
        this.$alert(
          "<div style='color:#102859'>"+
          this.$t('m.止盈根据总收益率计算，触发止盈后系统自动撤单平仓后终止策略。')+
          "</div>",
          this.$t('m.总收益止盈'),
          {
            confirmButtonText: this.$t('m.确定'),
            dangerouslyUseHTMLString: true,
          }
        );
      }
    },
    // 止损
    SL() {
      if (this.transactionType == "spot") {
        this.$alert(
          "<div style='color:#102859'>"+
          this.$t('m.止损根据总收益率计算，策略暂停操作优先级高于止盈止损及定时终止，即暂停状态下此类操作不生效。触发止损后自动终止策略，系统根据你的选择决定是否执行撤单兑换操作。')+
          "</div>",
          this.$t('m.总收益止损'),
          {
            confirmButtonText: this.$t('m.确定'),
            dangerouslyUseHTMLString: true,
          }
        );
      } else {
        this.$alert(
          "<div style='color:#102859'>"+
          this.$t('m.止损根据总收益率计算，触发止损后系统自动撤单平仓后终止策略。')+
          "</div>",
          this.$t('m.总收益止损'),
          {
            confirmButtonText: this.$t('m.确定'),
            dangerouslyUseHTMLString: true,
          }
        );
      }
    },

    // 定时终止
    timeTips() {
      if (this.transactionType == "spot") {
        this.$alert(
          "<div style='color:#102859'>"+
          this.$t('m.策略暂停操作优先级高于止盈止损及定时终止，即暂停状态下此类操作不生效。定时终止可能会有几分钟的偏差，触发后自动终止策略，系统根据你的选择决定是否执行撤单兑换操作。')+
          "</div>",
          this.$t('m.定时自动终止'),
          {
            confirmButtonText: this.$t('m.确定'),
            dangerouslyUseHTMLString: true,
          }
        );
      } else {
        this.$alert(
          "<div style='color:#102859'>"+
          this.$t('m.定时终止可能会有几分钟的偏差，触发后系统自动撤单平仓后终止策略。')+
          "</div>",
          this.$t('m.定时自动终止'),
          {
            confirmButtonText: this.$t('m.确定'),
            dangerouslyUseHTMLString: true,
          }
        );
      }
    },

    //币币合约
    tableDataBtn(e) {
      this.tableActive = e;
      this.tableActiveTwo = 0;
      console.log(e);
      console.log(this.tableTopActive);

      if (this.tableTopActive == 1) {
        this.contractSearch = [
          {
            name: "标签",
            type: "input",
          },
          {
            name: "状态",
            filterable: true,
            data: ["执行中", "已完成"],
          },
        ];
        
        let orderData = this.orderChainList[0];
        if (this.tableActive == 1) {
          orderData.forEach((e, index) => {
            if (e.title == "订单收益") {
              return orderData.splice(index, 1);
            }
          });
        } else {
          this.orderData.forEach((e, index) => {
            if (e.title == "订单收益") {
              return;
            }
            if (index == this.orderData) {
              return this.orderData.splice(3, 0, {
                title: "订单收益",
                nav: "profit",
              });
            }
          });
        }
        this.orderData = orderData;

        this.$refs.child.btnBack();
      } else if (this.tableTopActive == 0) {
        if (this.nowData) {
          this.searchData = [
            {
              name: "标签",
              type: "input",
            },
            {
              name: "状态",
              filterable: true,
              data: [
                "全部",
                "执行中",
                "终止中",
                "暂停中",
                "启动中",
                "启动错误",
              ],
            },
          ];
          this.orderData = [
            {
              title: "标签",
              nav: "templateName",
            },
            {
              title: "启动时间",
              nav: "createTime",
            },
            {
              title: "执行时长",
              nav: "nowTime",
            },
            {
              title: "实例编号",
              nav: "codeName",
            },
            {
              title: "总投入",
              nav: "investCoinnNum",
            },
            {
              title: "总收益",
              nav: "floatEarnings",
            },
            {
              title: "总年化",
              nav: "realYearIncome",
            },
            {
              title: "状态",
              nav: "states",
            },
          ];
        } else {
          this.searchData = [
            {
              name: "标签",
              type: "input",
            },
          ];
          this.orderData = [
            {
              title: "标签",
              nav: "templateName",
            },
            {
              title: "启动时间",
              nav: "createTime",
            },
            {
              title: "终止时间",
              nav: "terminationTime",
            },
            {
              title: "执行时长",
              nav: "nowTime",
            },
            {
              title: "实例编号",
              nav: "codeName",
            },
            {
              title: "总投入",
              nav: "investCoinnNum",
            },
            {
              title: "总收益",
              nav: "floatEarnings",
            },
            {
              title: "总年化",
              nav: "realYearIncome",
            },
          ];
        }

        this.$refs.child.btnBack();
      } else {
        let apiName = this.name.split("-");
        this.contractActive = 0;
        if (e == 0) {
          this.tableActiveData = 0;
          this.btnBack();
        } else if (e == 1) {
          this.tableActiveData = 1;
          this.btnBack();
        } else {
          let payload = {
            token: sessionStorage.getItem("token"),
            apiId: this.apiId,
            pageSize: 10,
            pageNo: 1,
          };
          this.getAssetApiList(payload);
        }
      }
    },
    //策略 历史当前
    tableDataTwoBtn(e) {
      this.tableActiveTwo = e;
      if (this.nowData) {
        let payload = {
          token: sessionStorage.getItem("token"),
          id: this.val.id,
          pageSize: 10,
          pageNo: 1,
        };
        this.userManagementDetails(payload);
        if (e == 0) {
          console.log(this.nowadayExample);
          this.searchData = [
            {
              name: "标签",
              type: "input",
            },
            {
              name: "状态",
              filterable: true,
              data: [
                "全部",
                "执行中",
                "终止中",
                "暂停中",
                "启动中",
                "启动错误",
              ],
            },
          ];
          this.orderData = [
            {
              title: "标签",
              nav: "templateName",
            },
            {
              title: "启动时间",
              nav: "createTime",
            },
            {
              title: "执行时长",
              nav: "nowTime",
            },
            {
              title: "实例编号",
              nav: "codeName",
            },
            {
              title: "总投入",
              nav: "investCoinnNum",
            },
            {
              title: "总收益",
              nav: "floatEarnings",
            },
            {
              title: "总年化",
              nav: "realYearIncome",
            },
            {
              title: "状态",
              nav: "states",
            },
          ];
        } else {
          this.searchData = [{ name: "标签", type: "input" }];
          this.orderData = [
            {
              title: "标签",
              nav: "templateName",
            },
            {
              title: "启动时间",
              nav: "createTime",
            },
            {
              title: "终止时间",
              nav: "terminationTime",
            },
            {
              title: "执行时长",
              nav: "nowTime",
            },
            {
              title: "实例编号",
              nav: "codeName",
            },
            {
              title: "总投入",
              nav: "investCoinnNum",
            },
            {
              title: "总收益",
              nav: "floatEarnings",
            },
            {
              title: "总年化",
              nav: "realYearIncome",
            },
          ];
        }
      } else {
        this.searchData = [
          {
            name: "标签",
            type: "input",
          },
        ];
        this.orderData = [
          {
            title: "标签",
            nav: "templateName",
          },
          {
            title: "启动时间",
            nav: "createTime",
          },
          {
            title: "终止时间",
            nav: "terminationTime",
          },
          {
            title: "执行时长",
            nav: "nowTime",
          },
          {
            title: "实例编号",
            nav: "codeName",
          },
          {
            title: "总投入",
            nav: "investCoinnNum",
          },
          {
            title: "总收益",
            nav: "floatEarnings",
          },
          {
            title: "总年化",
            nav: "realYearIncome",
          },
        ];
      }
    },
    selectRow(e) {
      this.selectRowList = e;
    },
    //重置按钮
    btnBack() {
      this.currentPage = 1;
      this.searchTable = [];
      if (this.tableTopActive == 2) {
        let apiName = this.name.split("-");
        if (this.tableActive == 0) {
          let payload = {
            token: sessionStorage.getItem("token"),
            teamProjectId: this.project.teamProjectId,
            userId: this.userId,
            apiName: apiName[0] + "-" + apiName[1],

            exchangeName: this.exchangeList.exchangeName,
            pageSize: 10,
            pageNo: 1,
          };
          this.getSpotApiList(payload);
        } else {
          let payload = {
            token: sessionStorage.getItem("token"),
            exchangeName: this.exchangeList.exchangeName,
            teamProjectId: this.project.teamProjectId,
            accountMode: this.exchangeList.warehouse ? "isolated" : "cross",
            userId: this.userId,
            apiName: apiName[0] + "-" + apiName[1],
            pageSize: 10,
            pageNo: 1,
          };
          this.getContractApiList(payload);
        }
        this.currentPage = 1;
      } else if (this.tableTopActive == 1) {
        this.orderData = this.orderChainList[0];
        let payload = {
          token: sessionStorage.getItem("token"),
          publicPeopleId: this.publicPeopleId,
          teamProjectId: this.project.teamProjectId,
          states: this.nowData ? "EXECUTE" : "COMPLETED",
          transactionType: this.tableActive == 0 ? "spot" : "contract_usdt",
          pageSize: 10,
          pageNo: 1,
        };
        this.queryOrderChainList(payload);
      } else {
        if (this.nowData) {
          let payload = {
            token: sessionStorage.getItem("token"),
            id: this.val.id,
            pageSize: 10,
            pageNo: 1,
          };
          this.userManagementDetails(payload);
        } else {
          let payload = {
            token: sessionStorage.getItem("token"),
            id: this.val.id,
          };
          this.fundHistoryDetails(payload);
        }
      }
    },
    //分页
    handleCurrentChange(e) {
      this.currentPage = e;
      if (this.tableTopActive == 1) {
        let payload = {
          token: sessionStorage.getItem("token"),
          publicPeopleId: this.publicPeopleId,
          teamProjectId: this.project.teamProjectId,
          pageSize: 10,
          pageNo: e,
          states: this.nowData ? "EXECUTE" : "COMPLETED",
          ...this.searchTable,
        };
        this.queryOrderChainList(payload);
      } else if (this.tableTopActive == 2) {
        let apiName = this.name.split("-");
        if (this.tableActive == 0) {
          let payload = {
            token: sessionStorage.getItem("token"),
            teamProjectId: this.project.teamProjectId,
            userId: this.userId,

            apiName: apiName[0] + "-" + apiName[1],
            pageSize: 10,
            pageNo: e,
            ...this.searchTable,
          };
          this.getSpotApiList(payload);
        } else {
          let payload = {
            token: sessionStorage.getItem("token"),
            exchangeName: this.exchangeList.exchangeName,
            teamProjectId: this.project.teamProjectId,
            accountMode: this.exchangeList.warehouse ? "isolated" : "cross",
            userId: this.userId,
            apiName: apiName[0] + "-" + apiName[1],
            pageSize: 10,
            pageNo: e,
            ...this.searchTable,
          };
          this.getContractApiList(payload);
        }
      } else {
        if (this.nowData) {
          let payload = {
            token: sessionStorage.getItem("token"),
            id: this.val.id,
            pageSize: 10,
            pageNo: e,
            ...this.searchTable,
          };
          this.userManagementDetails(payload);
        } else {
          let payload = {
            token: sessionStorage.getItem("token"),
            id: this.val.id,
            pageSize: 10,
            pageNo: e,
          };
          this.fundHistoryDetails(payload);
        }
      }
    },
    // top栏按钮
    tableTopBtn(e) {
      this.orderData = this.orderDataList[e];
      this.searchData = this.searchDataList[e];
      this.rowStyleData = this.rowStyleList[e];
      this.tableTopActive = e;
      this.tableActiveData = 0;
      this.tableActive = 0;
      this.currentPage = 1;
      this.searchTable = [];
      console.log(e);
      if (e == 2) {
        console.log(this.exchangeName);

        if (this.exchangeName == "OkexV5") {
          this.Tab = ["币币(统一账户)", "合约(统一账户)", "资金账户"];
        }
        this.modify = false;
        this.detailsDelete = true;
        let apiName = this.name.split("-");
        let payload = {
          token: sessionStorage.getItem("token"),
          teamProjectId: this.project.teamProjectId,
          apiName: apiName[0] + "-" + apiName[1],
          pageSize: 10,
          pageNo: 1,
        };
        this.getSpotApiList(payload);
      } else if (e == 1) {
        this.Tab = ["币币", "合约"];
        this.modify = true;
        this.details = false;
        this.orderData = this.orderChainList[0];
        let payload = {
          token: sessionStorage.getItem("token"),
          publicPeopleId: this.publicPeopleId,
          teamProjectId: this.project.teamProjectId,
          states: this.nowData ? "EXECUTE" : "COMPLETED",
          transactionType: this.tableActive == 0 ? "spot" : "contract_usdt",
          pageSize: 10,
          pageNo: 1,
        };
        this.queryOrderChainList(payload);
      } else {
        this.Tab = ["币币", "合约"];
        this.modify = true;
        this.details = true;
        this.detailBtn = false;
        if (this.nowData) {
          let payload = {
            token: sessionStorage.getItem("token"),
            id: this.val.id,
            pageSize: 10,
            pageNo: 1,
          };
          this.userManagementDetails(payload);
        } else {
          let picData = {
            token: sessionStorage.getItem("token"),
            publicPeopleId: this.val.id,
          };
          this.queryHistoryAccountData(picData);
          this.TabTwo = ["历史策略"];
          this.orderData = [
            {
              title: "标签",
              nav: "templateName",
            },
            {
              title: "启动时间",
              nav: "createTime",
            },
            {
              title: "终止时间",
              nav: "terminationTime",
            },
            {
              title: "执行时长",
              nav: "nowTime",
            },
            {
              title: "实例编号",
              nav: "codeName",
            },
            {
              title: "总投入",
              nav: "investCoinnNum",
            },
            {
              title: "总收益",
              nav: "floatEarnings",
            },
            {
              title: "总年化",
              nav: "realYearIncome",
            },
          ];
          this.searchData = [{ name: "标签", type: "input" }];
          let payload = {
            token: sessionStorage.getItem("token"),
            id: this.val.id,
          };
          this.fundHistoryDetails(payload);
        }
      }
    },
    //返回上一页
    backBtn() {
      this.$router.back(-1);
    },
    typeSelest() {
      console.log(111111111111);
    },
    // 查询
    searchBtn(data) {
      this.currentPage = 1;
      if (this.tableTopActive == 2) {
        this.modify = false;
        if (data[0] !== "全部") {
          if (this.tableActive == 0) {
            this.searchTable = {
              coinType: data[0],
            };
          } else {
            this.searchTable = {
              counterCoin: data[0],
            };
          }
        } else {
          this.searchTable = [];
        }
        let apiName = this.name.split("-");
        if (this.tableActive == 0) {
          let payload = {
            token: sessionStorage.getItem("token"),
            teamProjectId: this.project.teamProjectId,
            userId: this.userId,
            apiName: apiName[0] + "-" + apiName[1],
            pageSize: 10,
            pageNo: 1,
            ...this.searchTable,
          };
          this.getSpotApiList(payload);
        } else {
          let payload = {
            token: sessionStorage.getItem("token"),
            exchangeName: this.exchangeList.exchangeName,
            teamProjectId: this.project.teamProjectId,
            accountMode: this.exchangeList.warehouse ? "isolated" : "cross",
            userId: this.userId,
            apiName: apiName[0] + "-" + apiName[1],
            pageSize: 10,
            pageNo: 1,
            ...this.searchTable,
          };
          this.getContractApiList(payload);
        }
      } else if (this.tableTopActive == 1) {
        this.modify = true;
        if (data[1] == "已完成") {
          this.orderData = this.orderChainList[1];
        } else {
          this.orderData = this.orderChainList[0];
        }
        if (this.tableActive == 1) {
          this.orderData.forEach((e, index) => {
            if (e.title == "订单收益") {
              return this.orderData.splice(index, 1);
            }
          });
        } else {
          this.orderData.forEach((e, index) => {
            if (e.title == "订单收益") {
              return;
            }
            if (index == this.orderData.length - 1) {
              return this.orderData.splice(3, 0, {
                title: "订单收益",
                nav: "profit",
              });
            }
          });
        }
        if (data[0] !== "" || data[1] !== "执行中") {
          this.searchTable = {
            states:
              data[1] == "执行中"
                ? "EXECUTE"
                : data[1] == "已完成"
                ? "COMPLETED"
                : "",
            label: data[0],
          };
        } else {
          this.searchTable = [];
        }
        let payload = {
          token: sessionStorage.getItem("token"),
          publicPeopleId: this.publicPeopleId,
          teamProjectId: this.project.teamProjectId,
          pageSize: 10,
          pageNo: 1,
          states: this.nowData ? "EXECUTE" : "COMPLETED",
          transactionType: this.tableActive == 0 ? "spot" : "contract_usdt",
          ...this.searchTable,
        };
        this.queryOrderChainList(payload);
      } else {
        this.modify = true;
        console.log(this.nowData);
        if (data[0] !== "" || data[1] !== "待执行") {
          if (this.nowData) {
            this.searchTable = {
              templateName: data[0],
              states:
                data[1] == "待执行"
                  ? "EXECUTE"
                  : data[1] == "已完成"
                  ? "COMPLETED"
                  : "",
            };
            let payload = {
              token: sessionStorage.getItem("token"),
              id: this.val.id,
            };
            this.fundHistoryDetails(payload);
          } else {
            let payload = {
              token: sessionStorage.getItem("token"),
              id: this.val.id,
            };
            this.fundHistoryDetails(payload);
          }
        } else {
          this.searchTable = [];
        }
      }
    },
    //终止订单链按钮
    stopChainSubmit() {
      if (this.selectRowList.length !== 0) {
        this.chainShow = true;
      } else {
        return this.$message.warning("请先勾选实例");
      }
    },
    //终止订单链确认按钮
    chainBtn() {
      let orderChainIdList = "";
      this.selectRowList.forEach((e, index) => {
        if (index == 0) {
          orderChainIdList += e.orderChainId;
        } else {
          orderChainIdList += "," + e.orderChainId;
        }
      });
      let payload = {
        token: sessionStorage.getItem("token"),
        orderChainStr: orderChainIdList,
      };

      batchOrderChainEnd(payload).then((res) => {
        if (res.status == 200) {
          let payload = {
            token: sessionStorage.getItem("token"),
            publicPeopleId: this.publicPeopleId,
            teamProjectId: this.project.teamProjectId,
            pageSize: 10,
            pageNo: 1,
            states: this.nowData ? "EXECUTE" : "COMPLETED",
          };
          this.queryOrderChainList(payload);
          this.currentPage = 1;
          this.chainShow = false;
        }
      });
    },

    handleSizeChange() {
      console.log(2);
    },
    //页面左侧数据
    queryAccountData(payload) {
      queryAccountData(payload).then((res) => {
        console.log("左侧数据111111111111", res);
        if (res.status == 200) {
          let publicPeopleMap = res.data.publicPeopleMap;
          publicPeopleMap.createTime = this.timestampToTime(
            publicPeopleMap.createTime
          );
          let investment =
            publicPeopleMap.investCoinNum - publicPeopleMap.availableNum;
          if (investment < 0) {
            publicPeopleMap.investment = 0;
          } else {
            publicPeopleMap.investment = investment
              .toString()
              .slice(0, investment.toString().indexOf(".") + 7);
          }

          console.log(publicPeopleMap.investment);
          this.apiCapitalList = res.data.apiCapitalList;
          let other = 0;
          let apiCapital = [];
          let numberTotal = 0;
          this.apiCapitalList.forEach((e) => {
            numberTotal += Number(e.convertUsdtNum);
          });
          this.apiCapitalList.forEach((e) => {
            if (
              e.coinType == "BTC" ||
              e.coinType == "USDT" ||
              e.coinType == "ETH"
            ) {
              let convertUsdtNum = 0;
              if (Number(e.convertUsdtNum) == 0) {
                convertUsdtNum = 0 + "%";
              } else {
                convertUsdtNum = (
                  (Number(e.convertUsdtNum) / numberTotal) *
                  100
                ).toString();
                convertUsdtNum =
                  convertUsdtNum.slice(0, convertUsdtNum.indexOf(".") + 3) +
                  "%";
              }
              console.log(convertUsdtNum);
              apiCapital.push({
                value: e.convertUsdtNum,
                name: e.coinType + " " + convertUsdtNum,
                index:
                  e.coinType == "USDT"
                    ? 1
                    : e.coinType == "BTC"
                    ? 2
                    : e.coinType == "ETH"
                    ? 3
                    : "",
                color:
                  e.coinType == "USDT"
                    ? "#6E78E4"
                    : e.coinType == "BTC"
                    ? "#FE4E75"
                    : e.coinType == "ETH"
                    ? "#FFDA5D"
                    : "",
              });
            } else {
              other += Number(e.convertUsdtNum);
            }
          });
          if (other == 0) {
            apiCapital.push({
              value: other,
              name: "其他 " + "0.00%",
              index: 4,
              color: "#3F4693",
            });
          } else {
            let otherPercent = ((other / numberTotal) * 100).toString();
            console.log(otherPercent);
            apiCapital.push({
              value: other,
              name:
                "其他 " +
                otherPercent.slice(0, otherPercent.indexOf(".") + 3) +
                "%",
              index: 4,
              color: "#3F4693",
            });
          }
          for (let i = 0; i < apiCapital.length - 1; i++) {
            for (let item = i + 1; item < apiCapital.length; item++) {
              if (apiCapital[i].index > apiCapital[item].index) {
                let index = apiCapital[item];
                apiCapital[item] = apiCapital[i];
                apiCapital[i] = index;
                item--;
              }
            }
          }

          let colorList = [];
          apiCapital.forEach((e) => {
            colorList.push(e.color);
          });
          console.log(colorList);
          //环形图
          var option = {
            tooltip: {
              trigger: "item",
              confine: true,
            },
            legend: {
              top: "5%",
              left: "center",
              textStyle: {
                color: "#E6E9EE",
              },
            },
            color: colorList,
            series: [
              {
                // name: "访问来源",
                type: "pie",
                radius: ["40%", "70%"],
                avoidLabelOverlap: false,
                label: {
                  show: false,
                  position: "center",
                },
                emphasis: {
                  label: {
                    show: true,
                    fontSize: "40",
                    fontWeight: "bold",
                  },
                },
                labelLine: {
                  show: false,
                },
                data: apiCapital,
              },
            ],
          };
          var chartDom = document.getElementById("main");
          var myChart = echarts.init(chartDom);
          myChart.on("legendselectchanged", function (params) {
            myChart.setOption({
              legend: { selected: { [params.name]: true } },
            });
          }); 
          myChart.setOption(option);
          this.publicPeopleMap = publicPeopleMap;
        }
      });
    },
    //页面左侧历史数据
    queryHistoryAccountData(payload) {
      queryHistoryAccountData(payload).then((res) => {
        console.log(res);
        if (res.status == 200) {
          let publicPeopleMap = res.data.publicPeopleMap;
          publicPeopleMap.createTime = this.timestampToTime(
            publicPeopleMap.createTime
          );
          publicPeopleMap.availableNum = "--";
          publicPeopleMap.investment = "--";
          publicPeopleMap.strategyNumber = "--";
          this.publicPeopleMap = publicPeopleMap;
          let apiCapital = [];
          apiCapital.push({ name: "其他 (100%)", value: 100 });
          var option = {
            tooltip: {
              trigger: "item",
              confine: true,
            },
            legend: {
              top: "5%",
              left: "center",
              textStyle: {
                color: "#E6E9EE",
              },
              selectedMode: false,
            },
            series: [
              {
                // name: "访问来源",
                type: "pie",
                radius: ["40%", "70%"],
                avoidLabelOverlap: false,
                label: {
                  show: false,
                  position: "center",
                },
                emphasis: {
                  label: {
                    show: true,
                    fontSize: "40",
                    fontWeight: "bold",
                  },
                },
                labelLine: {
                  show: false,
                },
                data: apiCapital,
              },
            ],
          };
          var chartDom = document.getElementById("main");
          var myChart = echarts.init(chartDom);
          myChart.setOption(option);
        }
      });
    },
    // 获取历史页面详情
    fundHistoryDetails(payload) {
      fundHistoryDetails(payload).then((res) => {
        if (res.status == 200) {
          console.log(res.data);
          let historyExample = res.data.historyExample;
          this.listNumber = res.data.historyExampleCount;
          let coinData = [];
          let contractData = [];
          historyExample.forEach((e) => {
            if (e.strategyName == "合约网格" || e.strategyName == "合约马丁") {
              coinData.push(e);
            } else {
              contractData.push(e);
            }
          });
          if (this.tableActive == 0) {
            this.historyExample = contractData;
          } else {
            this.historyExample = coinData;
          }
          this.historyExample.forEach((e) => {
            e.state = [];
            e.nowTime = this.date_format(e.endTime - e.startTime);
            e.createTime = this.timestampToTime(e.startTime);
            e.terminationTime = this.timestampToTime(e.endTime);
            e.codeName = e.strategyName + "(" + e.code + ")";
          });
          this.tableData = this.historyExample;
        }
      });
    },

    //获取订单链信息
    queryOrderChainList(payload) {
      queryOrderChainList(payload).then((res) => {
        if (res.status == 200) {
          let orderChainList = res.data.orderChainList;
          this.listNumber = res.data.total;
          console.log(res.data);
          orderChainList.forEach((e) => {
            let orderList = "";

            //判断订单链的长度

            if (e.states == "COMPLETED") {
              if (e.orderList.length > 3) {
                orderList =
                  e.orderList[e.orderList.length - 1].investUnit +
                  "-" +
                  e.orderList[e.orderList.length - 2].investUnit +
                  "-" +
                  e.orderList[e.orderList.length - 3].investUnit +
                  "...";
              } else {
                if (e.orderList.length == 3) {
                  orderList =
                    e.orderList[2].investUnit +
                    "-" +
                    e.orderList[1].investUnit +
                    "-" +
                    e.orderList[0].investUnit;
                } else if (e.orderList.length == 2) {
                  console.log(e.orderList[0].transactionPair);
                  let transactionPair;
                  if (e.orderList[0].transactionPair.indexOf("/") > 0) {
                    transactionPair = e.orderList[0].transactionPair.split("/");
                  } else if (e.orderList[0].transactionPair.indexOf("-") > 0) {
                    transactionPair = e.orderList[0].transactionPair.split("-");
                  } else if (
                    e.orderList[0].transactionPair.indexOf("SWAP") > 0
                  ) {
                    transactionPair = e.orderList[0].transactionPair.split("-");
                  } else {
                    let investUnitLength = e.orderList[0].investUnit.length;
                    if (
                      e.orderList[0].transactionPair.indexOf(
                        e.orderList[0].investUnit
                      ) > 0
                    ) {
                      transactionPair = [
                        e.orderList[0].transactionPair.slice(
                          e.orderList[0].transactionPair.length -
                            investUnitLength +
                            1
                        ),
                        e.orderList[0].investUnit,
                      ];
                    } else {
                      transactionPair = [
                        e.orderList[0].investUnit,
                        e.orderList[0].transactionPair.slice(investUnitLength),
                      ];
                    }

                    if (e.orderList[0].investUnit == transactionPair[0]) {
                      orderList = transactionPair[0] + "-" + transactionPair[1];
                    } else {
                      console.log(transactionPair);
                      orderList = transactionPair[1] + "-" + transactionPair[0];
                      console.log(orderList);
                    }
                  }
                  if (e.orderList[0].investUnit == transactionPair[0]) {
                    transactionPair =
                      transactionPair[0] + "-" + transactionPair[1];
                  } else {
                    transactionPair =
                      transactionPair[1] + "-" + transactionPair[0];
                  }
                  orderList = e.orderList[1].investUnit + "-" + transactionPair;
                } else if (e.orderList.length == 1) {
                  let transactionPair;
                  if (this.tableActive == 1) {
                    if (e.orderList[0].transactionPair.indexOf("-SWAP") > 0) {
                      orderList =
                        e.orderList[0].transactionPair.split("-SWAP")[0];
                    } else {
                      orderList = e.orderList[0].transactionPair;
                    }
                  } else {
                    if (e.orderList[0].transactionPair.indexOf("/") > 0) {
                      transactionPair =
                        e.orderList[0].transactionPair.split("/");
                    } else if (
                      e.orderList[0].transactionPair.indexOf("-") > 0
                    ) {
                      transactionPair =
                        e.orderList[0].transactionPair.split("-");
                    } else if (
                      e.orderList[0].transactionPair.indexOf("SWAP") > 0
                    ) {
                      transactionPair =
                        e.orderList[0].transactionPair.split("-");
                    } else {
                      let investUnitLength = e.orderList[0].investUnit.length;
                      if (
                        e.orderList[0].transactionPair.indexOf(
                          e.orderList[0].investUnit
                        ) > 0
                      ) {
                        transactionPair = [
                          e.orderList[0].transactionPair.slice(
                            e.orderList[0].transactionPair.length -
                              investUnitLength +
                              1
                          ),
                          e.orderList[0].investUnit,
                        ];
                      } else {
                        transactionPair = [
                          e.orderList[0].investUnit,
                          e.orderList[0].transactionPair.slice(
                            investUnitLength
                          ),
                        ];
                      }
                    }

                    if (e.orderList[0].investUnit == transactionPair[0]) {
                      orderList = transactionPair[0] + "-" + transactionPair[1];
                    } else {
                      console.log(transactionPair);
                      orderList = transactionPair[1] + "-" + transactionPair[0];
                      console.log(orderList);
                    }

                    if (e.orderList[0].investUnit == transactionPair[0]) {
                      transactionPair =
                        transactionPair[0] + "-" + transactionPair[1];
                    } else {
                      transactionPair =
                        transactionPair[1] + "-" + transactionPair[0];
                    }
                    orderList = transactionPair;
                  }
                }
              }
            } else {
              if (e.orderList.length > 3) {
                for (let i = e.orderList.length - 1; i >= 0; i--) {
                  console.log(e.orderList[i]);
                  if (e.orderList[i].executeStates == "EXECUTE") {
                    console.log(i);
                    //判断省略号的方向
                    if (i == 0) {
                      orderList =
                        "..." +
                        e.orderList[2].investUnit +
                        "-" +
                        e.orderList[1].investUnit +
                        "-<span style='color:red'>" +
                        e.orderList[0].investUnit +
                        "</span>";
                      console.log(orderList);
                    } else if (i == 1) {
                      orderList =
                        "..." +
                        e.orderList[i - 1].investUnit +
                        "-<span style='color:red'>" +
                        e.orderList[i].investUnit +
                        "</span>-" +
                        e.orderList[i + 1].investUnit;
                    } else if (i == e.orderList.length - 2) {
                      orderList =
                        e.orderList[i + 1].investUnit +
                        "-<span style='color:red'>" +
                        e.orderList[i].investUnit +
                        "</span>-" +
                        e.orderList[i - 1].investUnit +
                        "...";
                    } else if (i == e.orderList.length - 1) {
                      orderList =
                        "<span style='color:red'>" +
                        e.orderList[i].investUnit +
                        "</span>-" +
                        e.orderList[i - 1].investUnit +
                        "-" +
                        e.orderList[i - 2].investUnit +
                        "...";
                    } else {
                      orderList =
                        "..." +
                        e.orderList[i + 1].investUnit +
                        "-<span style='color:red'>" +
                        e.orderList[i].investUnit +
                        "</span>-" +
                        e.orderList[i - 1].investUnit +
                        "...";
                    }
                  }
                }
              } else {
                if (e.orderList.length == 3) {
                  for (let i = 0; i < e.orderList.length; i++) {
                    //在执行中的链上加样式
                    if (e.orderList[i].executeStates == "EXECUTE") {
                      orderList =
                        "<span style='color:red'>" +
                        e.orderList[i].investUnit +
                        "</span>-" +
                        orderList;
                    } else {
                      orderList = e.orderList[i].investUnit + "-" + orderList;
                    }
                  }
                } else {
                  if (e.orderList.length == 1 && this.tableActive == 1) {
                    // console.log(e.transactionPair, transactionPair);
                    if (e.orderList[0].transactionPair.indexOf("-SWAP") > 0) {
                      orderList =
                        e.orderList[0].transactionPair.split("-SWAP")[0];
                    } else if (
                      e.orderList[0].transactionPair.indexOf("-") == -1
                    ) {
                      let investUnitLength = e.orderList[0].investUnit.length;
                      let transactionPair = [];
                      if (
                        e.orderList[0].transactionPair.slice(
                          0,
                          e.orderList[0].investUnit.length
                        ) == e.orderList[0].investUnit
                      ) {
                        transactionPair = [
                          e.orderList[0].transactionPair.slice(
                            e.orderList[0].transactionPair.length,
                            investUnitLength
                          ),
                          e.orderList[0].investUnit,
                        ];
                      } else {
                        if (
                          e.orderList[0].orderDirection == "open_many" ||
                          e.orderList[0].orderDirection == "flat_empty"
                        ) {
                          transactionPair = [
                            e.orderList[0].investUnit,
                            e.orderList[0].transactionPair.slice(
                              0,
                              investUnitLength - 1
                            ),
                          ];
                        } else {
                          transactionPair = [
                            e.orderList[0].transactionPair.slice(
                              0,
                              investUnitLength - 1
                            ),
                            e.orderList[0].investUnit,
                          ];
                        }
                      }

                      if (e.orderList[0].investUnit == transactionPair[0]) {
                        orderList =
                          transactionPair[0] + "-" + transactionPair[1];
                      } else {
                        console.log(transactionPair);
                        orderList =
                          transactionPair[1] + "-" + transactionPair[0];
                        console.log(orderList);
                      }
                    } else {
                      orderList = e.orderList[0].transactionPair;
                    }
                    orderList =
                      "<span style='color:red'>" +
                      orderList.split("-")[0] +
                      "</span>-" +
                      orderList.split("-")[1];
                  } else {
                    for (let i = 0; i < e.orderList.length; i++) {
                      //在执行中的链上加样式
                      if (e.orderList[i].executeStates == "EXECUTE") {
                        if (i == 0) {
                          let transactionPair;
                          if (e.orderList[0].transactionPair.indexOf("/") > 0) {
                            transactionPair =
                              e.orderList[0].transactionPair.split("/");
                          } else if (
                            e.orderList[0].transactionPair.indexOf("-") > 0
                          ) {
                            transactionPair =
                              e.orderList[0].transactionPair.split("-");
                          } else if (
                            e.orderList[0].transactionPair.indexOf("SWAP") > 0
                          ) {
                            transactionPair =
                              e.orderList[0].transactionPair.split("-");
                          } else {
                            let investUnitLength =
                              e.orderList[0].investUnit.length;
                            if (
                              e.orderList[0].transactionPair.indexOf(
                                e.orderList[0].investUnit
                              ) > 0
                            ) {
                              transactionPair = [
                                e.orderList[0].transactionPair.slice(
                                  e.orderList[0].transactionPair.length -
                                    investUnitLength +
                                    1
                                ),
                                e.orderList[0].investUnit,
                              ];
                            } else {
                              transactionPair = [
                                e.orderList[0].investUnit,
                                e.orderList[0].transactionPair.slice(
                                  investUnitLength
                                ),
                              ];
                            }

                            if (
                              e.orderList[0].investUnit == transactionPair[0]
                            ) {
                              orderList =
                                transactionPair[0] + "-" + transactionPair[1];
                            } else {
                              console.log(transactionPair);
                              orderList =
                                transactionPair[1] + "-" + transactionPair[0];
                              console.log(orderList);
                            }
                          }
                          if (e.orderList[0].investUnit == transactionPair[0]) {
                            orderList =
                              "<span style='color:red'>" +
                              transactionPair[0] +
                              "</span>-" +
                              transactionPair[1];
                          } else {
                            orderList =
                              "<span style='color:red'>" +
                              transactionPair[1] +
                              "</span>-" +
                              transactionPair[0];
                          }
                        } else {
                          orderList =
                            "<span style='color:red'>" +
                            e.orderList[i].investUnit +
                            "</span>-" +
                            orderList;
                        }
                      } else {
                        if (i == 0) {
                          console.log(e.orderList[0].transactionPair);
                          let transactionPair;
                          if (e.orderList[0].transactionPair.indexOf("/") > 0) {
                            transactionPair =
                              e.orderList[0].transactionPair.split("/");
                          } else if (
                            e.orderList[0].transactionPair.indexOf("-") > 0
                          ) {
                            transactionPair =
                              e.orderList[0].transactionPair.split("-");
                          } else if (
                            e.orderList[0].transactionPair.indexOf("SWAP") > 0
                          ) {
                            transactionPair =
                              e.orderList[0].transactionPair.split("-");
                          } else {
                            let investUnitLength =
                              e.orderList[0].investUnit.length;
                            if (
                              e.orderList[0].transactionPair.indexOf(
                                e.orderList[0].investUnit
                              ) > 0
                            ) {
                              transactionPair = [
                                e.orderList[0].transactionPair.slice(
                                  e.orderList[0].transactionPair.length -
                                    investUnitLength +
                                    1
                                ),
                                e.orderList[0].investUnit,
                              ];
                            } else {
                              transactionPair = [
                                e.orderList[0].investUnit,
                                e.orderList[0].transactionPair.slice(
                                  investUnitLength
                                ),
                              ];
                            }
                          }
                          if (e.orderList[0].investUnit == transactionPair[0]) {
                            orderList =
                              transactionPair[0] + "-" + transactionPair[1];
                          } else {
                            console.log(transactionPair);
                            orderList =
                              transactionPair[1] + "-" + transactionPair[0];
                            console.log(orderList);
                          }
                        } else {
                          console.log(orderList, 111111);
                          orderList =
                            e.orderList[i].investUnit + "-" + orderList;
                        }
                      }
                    }
                  }
                }
              }
            }

            //去除首尾的 '-'

            console.log(orderList);
            orderList = orderList.replace(/^(\s|-)+|(\s|-)+$/g, "");
            //在末尾加上订单的数量
            e.order = orderList + " (" + e.orderList.length + "单)";
            //给的 style 样式写在修改的数据前面
            e.state = [];
            if (e.orderList.length !== 0) {
              e.state.push(
                Number(e.totalIncomeNum) == 0
                  ? "zero3"
                  : Number(e.totalIncomeNum) -
                      Number(e.orderList[e.orderList.length - 1].investNum) >
                    0
                  ? "true3"
                  : Number(e.totalIncomeNum) -
                      Number(e.orderList[e.orderList.length - 1].investNum) <
                    0
                  ? "false3"
                  : "zero3"
              );
            }
            e.state.push(
              e.error
                ? "订单错误"
                : e.states == "EXECUTE"
                ? "执行中"
                : e.states == "COMPLETED"
                ? "已完成"
                : ""
            );

            //渲染到页面
            if (e.states == "COMPLETED") {
              e.createTime = this.date_format(e.endTime - e.createTime);
            } else {
              e.createTime = this.date_format(
                Date.parse(new Date()) - e.createTime
              );
            }
            if (e.orderList.length == 1) {
              e.profit = "--";
            } else {
              if (!e.totalIncomeNum) {
                e.profit = 0;
              } else {
                // investUnit 是初始投入币种
                let profit =
                  e.totalIncomeNum -
                  e.orderList[e.orderList.length - 1].investNum;
                profit = Number(profit).toFixed(10);
                if (profit == 0) {
                  e.profit =
                    0 + " " + e.orderList[e.orderList.length - 1].investUnit;
                } else {
                  if (
                    e.orderList[e.orderList.length - 1].investUnit !== "BTC"
                  ) {
                    //获取收益百分比
                    let percentNum = (
                      (profit /
                        Number(e.orderList[e.orderList.length - 1].investNum)) *
                      100
                    ).toString();
                    e.profit =
                      Number(
                        profit
                          .toString()
                          .slice(0, profit.toString().indexOf(".") + 5)
                      ) +
                      " " +
                      e.orderList[e.orderList.length - 1].investUnit +
                      " (" +
                      percentNum.slice(0, percentNum.indexOf(".") + 3) +
                      "%)";
                  } else {
                    let percentNum = (
                      (profit /
                        Number(e.orderList[e.orderList.length - 1].investNum)) *
                      100
                    ).toString();
                    e.profit =
                      Number(
                        profit
                          .toString()
                          .slice(0, profit.toString().indexOf(".") + 7)
                      ) +
                      " " +
                      e.orderList[e.orderList.length - 1].investUnit +
                      " (" +
                      percentNum.slice(0, percentNum.indexOf(".") + 3) +
                      "%)";
                  }
                }
              }
            }

            let totalIncomeNum = e.totalIncomeNum;
            if (e.orderList.length !== 0) {
              e.totalIncomeNum =
                e.orderList[e.orderList.length - 1].investNum - totalIncomeNum;

              e.states = e.error
                ? "订单错误"
                : e.states == "EXECUTE"
                ? "执行中"
                : e.states == "COMPLETED"
                ? "已完成"
                : "";
              e.orderList.forEach((item) => {
                if (item.orderSort == 1) {
                  if (e.transactionType == "contract_usdt") {
                    e.investNum = item.investNum + " 张";
                  } else {
                    if (item.investUnit !== "BTC") {
                      item.investNum =
                        item.investNum.slice(
                          0,
                          item.investNum.indexOf(".") + 5
                        ) + " ";
                    } else {
                      item.investNum =
                        item.investNum.slice(
                          0,
                          item.investNum.indexOf(".") + 7
                        ) + " ";
                    }
                    if (Number(item.investNum) == 0) {
                      e.investNum = 0 + " " + item.investUnit;
                    } else {
                      e.investNum = item.investNum + item.investUnit;
                    }
                  }
                }
                if (item.executeStates == "EXECUTE") {
                  e.orderDirection =
                    item.orderDirection == "buy"
                      ? "买入"
                      : item.orderDirection == "sell"
                      ? "卖出"
                      : item.orderDirection == "open_many"
                      ? "开多 " + item.leverRate + "x"
                      : //买
                      item.orderDirection == "open_empty"
                      ? "开空 " + item.leverRate + "x"
                      : item.orderDirection == "flat_many"
                      ? "平多 " + item.leverRate + "x"
                      : item.orderDirection == "flat_empty"
                      ? "平空 " + item.leverRate + "x"
                      : "";
                }
              });
              if (e.orderDirection) {
                e.state.push(e.orderDirection.split(" ")[0]);
              }
            }
          });
          this.tableData = orderChainList;
          console.log(this.tableData);
        }
      });
    },

    //获取持仓页面数据
    getSpotApiList(payload) {
      getSpotApiList(payload).then((res) => {
        console.log(res);
        if (res.status == 200) {
          if (res.data.totalNum !== 0) {
            let spotApiList = res.data.spotApiList;
            this.listNumber = res.data.totalNum;
            this.startCoin = res.data.spotApiList[0].investCoin;
            spotApiList.forEach((e) => {
              e.updateTime = this.timestampToTime(e.updateTime);
              if (e.coinType == e.investCoin) {
                console.log(e);
                e.coinValue = "--";
                e.coinCost = "--";
                e.coinIncome = "--";
              }
              e.lock = Number(e.lock);
              e.available = Number(e.available);
              if (e.coinType == "BTC") {
                e.available = e.available
                  .toString()
                  .slice(0, e.available.toString().indexOf(".") + 7);
              } else {
                e.available = e.available
                  .toString()
                  .slice(0, e.available.toString().indexOf(".") + 5);
              }

              e.coinType += e.states == "SWAPING" ? " (快速兑换中)" : "";
              e.state = [];
              //注释了 收益和成本
              e.state.push();
            });
            this.orderData.forEach((e) => {
              if (e.title.indexOf("投入币种") != -1) {
                e.title = e.title.replace(
                  /投入币种/,
                  spotApiList[0].investCoin
                );
              }
            });
            this.tableData = spotApiList;
          } else {
            this.startCoin = "--";
            this.tableData = [];
            this.listNumber = 0;
          }
        }
      });
    },

    //获取策略数据
    userManagementDetails(payload) {
      userManagementDetails(payload).then((res) => {
        if (res.status == 200) {
          console.log("详情", res.data);
          //历史
          if (this.tableActiveTwo == 1) {
            this.historyExample = res.data.historyExample;
            let coinData = [];
            let contractData = [];
            this.historyExample.forEach((e) => {
              if (
                e.strategyName == "合约网格" ||
                e.strategyName == "合约马丁"
              ) {
                coinData.push(e);
              } else {
                contractData.push(e);
              }
            });
            let historyExample;
            if (this.tableActive == 1) {
              historyExample = coinData;
            } else {
              historyExample = contractData;
            }
            historyExample.forEach((e) => {
              e.nowTime = this.date_format(e.endTime - e.startTime);
              e.createTime = this.timestampToTime(e.startTime);
              e.terminationTime = this.timestampToTime(e.endTime);
              let pairs = JSON.parse(e.result).pairs[0].data;
              console.log(pairs);
              if (pairs.investAmount) {
                e.investCoinnNum = pairs.investAmount;
              } else {
                e.investCoinnNum = pairs.initMarginBalance.slice(
                  0,
                  pairs.initMarginBalance.indexOf(".") + 3
                );
              }
              e.state = [];
              e.state.push(
                e.states,
                e.realEarnings > 0
                  ? "true7"
                  : e.realEarnings < 0
                  ? "false7"
                  : "zero7",
                e.realYearIncome > 0
                  ? "true6"
                  : e.realYearIncome < 0
                  ? "false6"
                  : "zero6"
              );

              if (e.realEarnings == 0) {
                e.floatEarnings = 0;
              } else {
                e.floatEarnings =
                  e.realEarnings.slice(0, e.realEarnings.indexOf(".") + 3) +
                  "(" +
                  e.realEarningsRate.slice(
                    0,
                    e.realEarningsRate.indexOf(".") + 3
                  ) +
                  "%)";
              }

              // realEarnings 总收益

              e.realYearIncome =
                e.realYearIncome > 0
                  ? "+" + e.realYearIncome + "%"
                  : e.realYearIncome == 0
                  ? "0"
                  : e.realYearIncome + "%";
              e.state = [];
              e.codeName = e.strategyName + "(" + e.code + ")";
            });

            this.listNumber = historyExample.length;

            this.tableData = historyExample;
            console.log(this.tableData);
          } else {
            //当前
            this.nowadayExample = res.data.nowadayExample;
            let coinData = [];
            let contractData = [];
            this.nowadayExample.forEach((e) => {
              if (
                e.strategyName == "合约网格" ||
                e.strategyName == "合约马丁"
              ) {
                coinData.push(e);
              } else {
                contractData.push(e);
              }
            });
            let nowadayExample;
            if (this.tableActive == 1) {
              nowadayExample = coinData;
            } else {
              nowadayExample = contractData;
            }
            console.log(nowadayExample);
            nowadayExample.forEach((e) => {
              e.nowTime = this.date_format(
                Date.parse(new Date()) - e.createTime
              );

              e.createTime = this.timestampToTime(e.createTime);
              e.codeName = e.strategyName + "(" + e.code + ")";
              e.states =
                e.states == "EXECUTE"
                  ? "执行中"
                  : e.states == "SUSPEND"
                  ? "暂停中"
                  : e.states == "STOPING"
                  ? "终止中"
                  : e.states == "STARTING"
                  ? "启动中"
                  : e.states == "STARTERROR"
                  ? "启动错误"
                  : "";
              e.state = [];
              e.state.push(
                e.states,
                e.realEarnings > 0
                  ? "true5"
                  : e.realEarnings < 0
                  ? "false5"
                  : "zero5",
                e.realYearIncome > 0
                  ? "true6"
                  : e.realYearIncome < 0
                  ? "false6"
                  : "zero6"
              );
              e.realYearIncome =
                e.realYearIncome > 0
                  ? "+" + e.realYearIncome + "%"
                  : e.realYearIncome + "%";
              if (e.realEarnings == 0) {
                e.floatEarnings = 0;
              } else {
                e.floatEarnings =
                  e.realEarnings.slice(0, e.realEarnings.indexOf(".") + 3) +
                  "(" +
                  e.realEarningsRate +
                  "%)";
              }
            });
            console.log(nowadayExample);
            this.listNumber = nowadayExample.length;
            this.tableData = nowadayExample;
            console.log(this.tableData);
          }
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    //获取交易所列表
    userManagementExchangelist(payload) {
      userManagementExchangelist(payload).then((res) => {
        if (res.status == 200) {
          console.log(res.data.exchangeLimit, "交易所列表");
          let exchangeLimit = res.data.exchangeLimit;
          let exchangeList = res.data.exchangeLimit;
          exchangeList.forEach((e) => {
            if (e.exchangeName == this.name.split("-")[0]) {
              this.exchangeName = e.exchangeName;
            }
          });
          this.searchData.forEach((e) => {
            if (e.name == "交易所") {
              e.data = [];
              exchangeLimit.forEach((item) => {
                e.data.push(item.exchangeName);
              });
              e.data.forEach((item, index) => {
                if (item == "Huobi" && index !== 0) {
                  let number = "";
                  number = e.data[0];
                  e.data[0] = e.data[index];
                  e.data[index] = number;
                }
              });
            }
          });
          exchangeList.forEach((e) => {
            console.log(this.val.exchangeName, e);
            if (e.exchangeName == this.val.exchangeName) {
              if (e.exchangeName == "Huobi" || e.exchangeName == "Okex") {
                e.warehouse = true;
              } else {
                e.warehouse = false;
              }
              this.exchangeList = e;
              return;
            }
          });
          console.log(this.exchangeList);
          this.exchangeLimit = exchangeLimit;
        }
      });
    },
    //快速兑换
    exchangeBtn() {
      let list = this.selectRowList;
      if (list.length == 0) {
        this.$message.warning("请先选择账户");
        return;
      }

      this.activeShow = true;
    },
    // 确定兑换
    async activeBtn() {
      console.log("账户数据", this.selectRowList);

      console.log(this.exchangeList);
      let exchangeList = [];
      exchangeList.push(this.exchangeList);
      let list = this.selectRowList;
      let swapStr = [];
      let swapStrErr = [];

      for (let key of list) {
        let item = {};
        item.apiId = key.apiId + "";
        item.counterCoinAmount = key.available + "";
        item.userId = key.userId;
        item.swapCoin = key.coinType;
        for (let val of exchangeList) {
          if (key.exchangeName == val.exchangeName) {
            item.exchangeId = val.exchangeId + "";
            let params = {
              token: sessionStorage.getItem("token"),
              exchangeId: item.exchangeId + "",
            };
            let pairList = null;
            await queryPair(params).then((res) => {
              if (res.status == 200) {
                pairList = res.data.pairList;
              }
            });
            console.log("交易对", pairList);
            for (let pair of pairList) {
              if (
                key.investCoin == pair.baseCoin &&
                key.coinType == pair.counterCoin
              ) {
                item.pairId = pair.pairId + "";
                console.log("交易对1111", pair);
              }
            }
          }
        }
        if (item.pairId) {
          swapStr.push(item);
        } else {
          swapStrErr.push(item);
        }
      }
      if (swapStrErr.length > 0) {
        let coin = "";
        swapStrErr.forEach((e, index) => {
          if (index == 0) {
            coin = e.swapCoin;
          } else {
            coin = coin + "," + e.swapCoin;
          }
        });
        this.$message.error(this.$t('m.平台不支持&(1)快速兑换').replace('&(1)', coin));
      }
      if (swapStrErr.length == list.length) {
        this.activeShow = false;
        return;
      }

      console.log(swapStr);
      let payload = {
        token: sessionStorage.getItem("token"),
        swapStr: JSON.stringify(swapStr),
      };

      quickSwap(payload).then((res) => {
        if (res.status == 200) {
          this.$message.success(this.$t('m.操作成功'));
          console.log(res.data);
          let payload = {
            token: sessionStorage.getItem("token"),
            teamProjectId: this.project.teamProjectId,
            exchangeName: this.exchangeName,
            userId: this.userId,
            pageSize: 10,
            pageNo: 1,
            ...this.searchTable,
          };
          this.getSpotApiList(payload);
          this.currentPage = 1;
        }
      });
      this.activeShow = false;
    },
    //终止按钮
    stopSubmit() {
      if (this.selectRowList.length !== 0) {
        this.terminationShow = true;
      } else {
        return this.$message.warning("请先勾选实例");
      }
    },
    // 批量终止
    batchStrategyEnd() {
      let executeIds = "";
      this.selectRowList.forEach((e, index) => {
        if (index == 0) {
          executeIds = e.executeId;
        } else {
          executeIds += "," + e.executeId;
        }
      });
      let payload = {
        token: sessionStorage.getItem("token"),
        executeIds: executeIds,
      };
      batchStrategyEnd(payload).then((res) => {
        if (res.status == 200) {
          this.$message.success(this.$t('m.终止成功'));
          this.terminationShow = false;
          let payload = {
            token: sessionStorage.getItem("token"),
            id: this.val.id,
            pageSize: 10,
            pageNo: e,
            ...this.searchTable,
          };
          // this.userManagementDetails(payload);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 批量暂停
    confirmSuspend() {
      if (this.selectRowList.length !== 0) {
        let executeIds = "";
        this.selectRowList.forEach((e, index) => {
          if (index == 0) {
            executeIds = e.executeId;
          } else {
            executeIds += "," + e.executeId;
          }
        });
        let payload = {
          token: sessionStorage.getItem("token"),
          executeIds: executeIds,
        };
        batchSuspend(payload).then((res) => {
          if (res.status == 200) {
            console.log(res.data);
            this.$message.success(this.$t('m.暂停成功'));
            let payload = {
              token: sessionStorage.getItem("token"),
              id: this.val.id,
              pageSize: 10,
              pageNo: this.pageNo,
              ...this.searchTable,
            };
            this.userManagementDetails(payload);
          } else {
            this.$message.error(res.msg);
          }
        });
      } else {
        this.$message.warning("请选择实例");
      }
    },
    // 批量重启
    operationBtn() {
      if (this.selectRowList.length !== 0) {
        let executeIds = "";
        this.selectRowList.forEach((e, index) => {
          if (index == 0) {
            executeIds = e.executeId;
          } else {
            executeIds += "," + e.executeId;
          }
        });
        let payload = {
          token: sessionStorage.getItem("token"),
          executeIds: executeIds,
        };
        batchRestart(payload).then((res) => {
          if (res.status == 200) {
            console.log(res.data);
            this.$message.success(this.$t('m.重启成功'));
            let payload = {
              token: sessionStorage.getItem("token"),
              id: this.val.id,
              pageSize: 10,
              pageNo: this.pageNo,
              ...this.searchTable,
            };
            this.userManagementDetails(payload);
          } else {
            this.$message.error(res.msg);
          }
        });
      } else {
        this.$message.warning("请选择实例");
      }
    },
    //同步资产按钮
    batchSpotApiBtn() {
      let api = [];
      let coin = [];
      this.selectRowList.forEach((e) => {
        api.push(e.apiId);
        coin.push(e.coinType);
      });
      api = Array.from(new Set(api));

      if (api.length == 1 && coin.length == 1) {
        let payload = {
          token: sessionStorage.getItem("token"),
          apiId: api[0],
          coin: coin,
        };
        this.synchSingleSpotApi(payload);
      } else {
        this.batchSynchSpotApi();
      }
    },
    //币币同步单个资产
    synchSingleSpotApi(payload) {
      synchSingleSpotApi(payload).then((res) => {
        if (res.status == 200) {
          this.$message.success(this.$t('m.操作成功'));

          let payload = {
            token: sessionStorage.getItem("token"),
            teamProjectId: this.project.teamProjectId,
            userId: this.userId,
            pageSize: 10,
            pageNo: 1,
            ...this.searchTable,
          };
          this.getSpotApiList(payload);
        }
      });
    },
    //同步币币全部资产
    batchSynchSpotApi() {
      let payload = {
        token: sessionStorage.getItem("token"),
      };
      batchSynchSpotApi(payload).then((res) => {
        if (res.status == 200) {
          if (this.batchShow == true) {
            if (this.tableActive == 0) {
              let payload = {
                token: sessionStorage.getItem("token"),
                teamProjectId: this.project.teamProjectId,
                exchangeName: this.exchangeName,
                userId: this.userId,
                pageSize: 10,
                pageNo: 1,
                ...this.searchTable,
              };
              this.getSpotApiList(payload);
            }
          } else {
            this.$message.success(this.$t('m.操作成功'));
            let payload = {
              token: sessionStorage.getItem("token"),
              teamProjectId: this.project.teamProjectId,
              exchangeName: this.exchangeName,
              userId: this.userId,
              pageSize: 10,
              pageNo: 1,
              ...this.searchTable,
            };
            this.getSpotApiList(payload);
          }
        }
      });
    },

    handleClose() {
      this.modifyParametersShow = false;
      this.terminationShow = false;
      this.chainShow = false;
      this.activeShow = false;
      this.transfer = false;
    },
    //日期换算
    timestampToTime(timestamp) {
      var date = new Date(timestamp); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
      var Y = date.getFullYear() + "-";
      var M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      var D =
        date.getDate() < 10
          ? "0" + date.getDate() + " "
          : +date.getDate() + " ";
      var h =
        date.getHours() < 10
          ? "0" + date.getHours() + ":"
          : date.getHours() + ":";
      var m =
        date.getMinutes() < 10
          ? "0" + date.getMinutes() + ":"
          : date.getMinutes() + ":";
      var s =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      return Y + M + D + h + m + s;
    },
    //时分秒换算
    date_format(micro_second) {
      var second = Math.floor(micro_second / 1000);
      var day = Math.floor(second / 3600 / 24);
      var hr = Math.floor((second / 3600) % 24);
      var min = Math.floor((second / 60) % 60);
      return day + "d " + hr + "h " + min + "min";
    },

    // 参数校验
    parameterVerification(e, item) {
      let value = Number(item.value);
      let currency = this.tradePairs.investCoin;
      let pairMap = this.tradePairs;
      let min = null;
      let max = null;

      if (item.valueMin && item.valueMin != "0") {
        min = this.calculation(currency, pairMap, Number(item.valueMin));
      } else {
        min = Number(item.start);
      }

      if (item.valueMax && item.valueMax != "0") {
        max = this.calculation(currency, pairMap, Number(item.valueMax));
      } else {
        max = Number(item.end);
      }
      console.log(item.name);
      if (!value && value != 0) {
        this.$message.error(this.$t('m.参数不能为空'));
        e.target.style.border = "1px solid #F23A4C";
        if (this.parameterCheckList.indexOf(item.name) > -1) {
          return;
        } else {
          this.parameterCheckList.push(item.name);
        }
        return;
      } else if (
        min > value &&
        item.name != "网格间隔(等差)" &&
        item.name != "价格上限" &&
        item.name != "价格下限"
      ) {
        console.log(value, min, item);
        this.$message.error(this.$t('m.参数不能小于最小范围'));
        e.target.style.border = "1px solid #F23A4C";
        if (this.parameterCheckList.indexOf(item.name) > -1) {
          return;
        } else {
          this.parameterCheckList.push(item.name);
        }
        return;
      } else if (
        max < value &&
        item.name != "网格间隔(等差)" &&
        item.name != "价格上限" &&
        item.name != "价格下限"
      ) {
        console.log(value, max);
        this.$message.error(this.$t('m.参数不能大于最大范围'));
        e.target.style.border = "1px solid #F23A4C";
        if (this.parameterCheckList.indexOf(item.name) > -1) {
          return;
        } else {
          this.parameterCheckList.push(item.name);
        }
        return;
      } else if (item.name == "网格间隔(等差)" && item.value == 0) {
        this.$message.error(this.$t('m.网格间隔(等差)不能为0'));
        e.target.style.border = "1px solid #F23A4C";
        if (this.parameterCheckList.indexOf(item.name) > -1) {
          return;
        } else {
          this.parameterCheckList.push(item.name);
        }
      } else if (item.name == "价格上限" && item.value < 0) {
        this.$message.error(this.$t('m.价格上限不能小于0'));
        if (this.parameterCheckList.indexOf(item.name) > -1) {
          return;
        } else {
          this.parameterCheckList.push(item.name);
        }
        e.target.style.border = "1px solid #F23A4C";
        return;
      } else if (item.name == "价格下限" && item.value < 0) {
        this.$message.error(this.$t('m.价格下限不能小于0'));
        if (this.parameterCheckList.indexOf(item.name) > -1) {
          return;
        } else {
          this.parameterCheckList.push(item.name);
        }
        e.target.style.border = "1px solid #F23A4C";
        return;
      } else {
        if (this.parameterCheckList.indexOf(item.name) > -1) {
          this.parameterCheckList.splice(
            this.parameterCheckList.indexOf(item.name),
            1
          );
        }
        e.target.style.border = "1px solid #C0C4CC";
      }
      console.log(item.value);
      console.log(item);
    },

    // 参数二次校验
    secondaryVerification() {
      let reserves = this.reserves;
      let isOk = null;
      for (let key of reserves) {
        if (key.name == "单格投入" && key.value == 0) {
          if (this.parameterCheckList.indexOf(item.name) > -1) {
            return;
          } else {
            this.parameterCheckList.push(item.name);
          }
          this.$message.error(this.$t('m.单格投入不正确，请检查修改'));
          isOk = false;
        } else if (
          key.name == "首单投入" &&
          (key.value == "" || key.value == 0)
        ) {
          if (this.parameterCheckList.indexOf(item.name) > -1) {
            return;
          } else {
            this.parameterCheckList.push(item.name);
          }
          this.$message.error(this.$t('m.首单投入不正确，请检查修改'));
          isOk = false;
        } else if (
          key.name == "首单金额" &&
          (key.value == "" || key.value == 0)
        ) {
          if (this.parameterCheckList.indexOf(item.name) > -1) {
            return;
          } else {
            this.parameterCheckList.push(item.name);
          }
          this.$message.error(this.$t('m.首单金额不正确，请检查修改'));
          isOk = false;
        } else if (
          key.name == "单笔金额" &&
          (key.value == "" || key.value == 0)
        ) {
          if (this.parameterCheckList.indexOf(item.name) > -1) {
            return;
          } else {
            this.parameterCheckList.push(item.name);
          }
          this.$message.error(this.$t('m.单笔金额不正确，请检查修改'));
          isOk = false;
        } else if (
          key.name == "买入价格" &&
          (key.value == "" || key.value == 0)
        ) {
          if (this.parameterCheckList.indexOf(item.name) > -1) {
            return;
          } else {
            this.parameterCheckList.push(item.name);
          }
          this.$message.error(this.$t('m.买入价格不正确，请检查修改'));
          isOk = false;
        } else if (
          key.name == "卖出价格" &&
          (key.value == "" || key.value == 0)
        ) {
          if (this.parameterCheckList.indexOf(item.name) > -1) {
            return;
          } else {
            this.parameterCheckList.push(item.name);
          }
          this.$message.error(this.$t('m.卖出价格不正确，请检查修改'));
          isOk = false;
        } else {
          isOk = true;
        }
      }
      return isOk;
    },

    // 止盈/止损校验
    otherSettingCheck(type, v) {
      console.log(type);
      if (type == "止盈" && Number(v) < 0) {
        this.$message.warning("止盈比例不能小于0");
        this.otherCheck = false;
      } else if (type == "止损" && Number(v) < 0) {
        this.$message.warning("止损比例不能小于0");
        this.otherCheck = false;
      } else if (type == "止损" && Number(v) > 100) {
        this.$message.warning("止损比例不能大于100");
        this.otherCheck = false;
      } else {
        this.otherCheck = true;
      }
    },

    // 策略阈值校验
    openingThresholdChange(v) {
      console.log(v);
      if (v < 0) {
        this.$message.error(this.$t('m.策略开仓阈值不能小于0'));
        if (this.parameterCheckList.indexOf(item.name) > -1) {
          return;
        } else {
          this.parameterCheckList.push(item.name);
        }
        return;
      } else if (!v) {
        this.$message.error(this.$t('m.策略开仓阈值不能为空'));
        if (this.parameterCheckList.indexOf(item.name) > -1) {
          return;
        } else {
          this.parameterCheckList.push(item.name);
        }
      } else {
        if (this.parameterCheckList.indexOf(item.name) > -1) {
          this.parameterCheckList.splice(
            this.parameterCheckList.indexOf(item.name),
            1
          );
        }
      }
    },

    // 修改参数
    confirmUpdateParam() {
      this.secondaryVerification();

      if (this.parameterCheckList.length !== 0) {
        this.$message.error(this.$t('m.参数配置不正确，请重新修改'));
        return;
      }
      if (!this.otherCheck) {
        this.$message.error(this.$t('m.其他设置参数不正确，请重新修改'));
        return;
      }

      if (this.strategyId == "WL202X0512112808681") {
        for (let key of this.reserves) {
          if (
            (key.id == "reserve_52" ||
              key.id == "reserve_53" ||
              key.id == "reserve_54") &&
            !key.signalValue
          ) {
            this.$message.error(key.name + "阈值不能为空");
            return;
          } else if (
            (key.id == "reserve_52" ||
              key.id == "reserve_53" ||
              key.id == "reserve_54") &&
            Number(key.signalValue) <= 0
          ) {
            this.$message.error(key.name + "阈值不能小于0");
            return;
          } else if (
            key.id == "reserve_52" ||
            key.id == "reserve_53" ||
            key.id == "reserve_54"
          ) {
            let item = {};
            item.signalId = key.signal.signalId;
            item.value = Number(key.signalValue);
            item.attrName = key.paramBodyValue;
            switch (key.ops) {
              case "=":
                item.opt = "eq";
                break;
              case ">":
                item.opt = "gt";
                break;
              case "<":
                item.opt = "lt";
                break;
              case "≦":
                item.opt = "le";
                break;
              case "≥":
                item.opt = "ge";
                break;
              default:
                break;
            }

            key.value = JSON.stringify(item);

            // "value": "{\"opt\":\"eq\",\"signalId\":\"d8aa6c8b8b874e31bd201e9f5510ac43\",\"value\":\"2\",\"attrName\":\"long\"}"
          }
        }
        console.log(this.reserves);
      }
      if (this.strategyId == "WL20190402171936974x") {
        for (let key of this.reserves) {
          if (key.name == "定投类型") {
            key.value = this.fixedTime[0];
          } else if (key.name == "定投周期") {
            key.value = this.fixedTime[1];
          }
        }
        console.log(this.reserves);
      }
      if (this.strategyId == "WL20210624182847743") {
        this.reserves.forEach((key) => {
          if (key.name == "补仓下单模式") {
            console.log(key);
            key.value = this.replenishmentbutionTwo ? 0 : 1;
          } else if (key.name == "止盈平仓模式") {
            key.value = this.replenishmentbutionThree ? 0 : 1;
          } else if (key.name == "止盈下单模式") {
            key.value = this.replenishmentbutionFour ? 0 : 1;
          } else if (key.name == "补仓金额模式") {
            key.value = this.replenishmentbutionOne ? 0 : 1;
          }
        });
      }
      if (
        this.strategyId == "WL20210512112808681" ||
        this.strategyId == "WL202X0512112808681"
      ) {
        for (let key of this.reserves) {
          if (key.name == "补仓金额模式") {
            key.value = this.replenishment ? 0 : 1;
          } else if (key.name == "补仓间隔模式") {
            key.value = this.intervalMode ? 0 : 1;
          }
        }
      }

      if (this.strategyId == "WL202007311426363424x") {
        let list = JSON.parse(JSON.stringify(this.reserves));
        for (let key of list) {
          if (key.id == "reserve_12" && this.gridDistribution) {
            key.value = 0;
          } else if (key.id == "reserve_11" && !this.gridDistribution) {
            key.value = 0;
          }
        }
        this.reserves = list;
      }
      console.log(this.reserves);

      let payload = {
        token: sessionStorage.getItem("token"),
        executeId: this.executeId,
        reserves: JSON.stringify(this.reserves),
        stopProfit: this.stopProfit ? Number(this.stopProfit) / 100 + "" : "",
        stopLoss: this.stopLoss ? (Number(this.stopLoss) / 100) * -1 : "",
        stopTime: this.stopTime ? new Date(this.stopTime).getTime() : "",
      };

      console.log(payload);

      // 合约
      if (
        this.strategyId == "WL202007311426363425x" ||
        this.strategyId == "WL202007311426363424x"
      ) {
        (payload.openThreshold = this.openingThreshold / 100),
          contractUpdateParam(payload).then((res) => {
            if (res.status == 200) {
              console.log(res.data);
              this.$message.success(this.$t('m.修改参数成功'));
              this.modifyParametersShow = false;
            } else {
              this.$message.error(res.msg);
            }
          });
      } else {
        updateParam(payload).then((res) => {
          if (res.status == 200) {
            console.log(res.data);
            this.$message.success(this.$t('m.修改参数成功'));
            this.modifyParametersShow = false;
          } else {
            this.$message.error(res.msg);
          }
        });
      }
    },

    // 兑U计算
    calculation(currency, pairMap, val) {
      if (!pairMap) {
        return;
      }
      var number = null;
      if (currency == pairMap.baseCoin) {
        number = val / (Number(pairMap.usdPrice) / Number(pairMap.last));
      } else {
        number = Number(val) / Number(pairMap.usdPrice);
      }
      return Math.floor(number * 1000000) / 1000000;
    },

    // 修改参数弹窗
    updateParams() {
      let strategy = JSON.parse(sessionStorage.getItem("selectStrategy"));
      this.executeId = strategy[0].executeId;
      if (this.TabTwo[this.tableActiveTwo] == "当前策略") {
        let payload = {
          token: sessionStorage.getItem("token"),
          executeId: strategy[0].executeId,
        };

        executeDetail(payload).then((res) => {
          if (res.status == 200) {
            console.log(res.data);
            let list = res.data.executeDetail;

            this.config = JSON.parse(list.config);
            console.log(this.config);

            this.strategyName = list.strategyName;
            this.strategyId = list.strategyId;
            this.tradePairs = this.config.tradePairs[0];
            this.openingThreshold = this.tradePairs.openThreshold * 100;
            this.contractType = this.tradePairs.contractType;
            this.reserves = JSON.parse(JSON.stringify(this.config.reserves));
            this.reservesCopy = JSON.parse(
              JSON.stringify(this.config.reserves)
            );

            this.stopProfit = this.config.stopProfit
              ? this.config.stopProfit * 100
              : null;
            this.stopLoss = this.config.stopLoss
              ? Number(this.config.stopLoss) * -1 * 100
              : null;
            this.stopTime = this.config.stopTime
              ? Number(this.config.stopTime)
              : null;

            if (
              this.strategyId == "WL20210512112808681" ||
              this.strategyId == "WL202X0512112808681"
            ) {
              for (let key of this.reserves) {
                if (key.name == "补仓金额模式") {
                  this.replenishment = key.value == 0 ? true : false;
                } else if (key.name == "补仓间隔模式") {
                  this.intervalMode = key.value == 0 ? true : false;
                }
              }
            }
            if (this.strategyId == "WL20210624182847743") {
              this.reserves.forEach((key) => {
                if (key.name == "补仓下单模式") {
                  this.replenishmentbutionTwo = key.value == 0 ? true : false;
                } else if (key.name == "止盈平仓模式") {
                  this.replenishmentbutionThree = key.value == 0 ? true : false;
                } else if (key.name == "止盈下单模式") {
                  this.replenishmentbutionFour = key.value == 0 ? true : false;
                } else if (key.name == "补仓金额模式") {
                  this.replenishmentbutionOne = key.value == 0 ? true : false;
                }
              });
            }

            if (this.strategyId == "WL20190402171936974x") {
              for (let key of this.reserves) {
                if (key.name == "定投类型") {
                  this.fixedTime[0] = key.value;
                  this.copyFixedTime[0] = key.value;
                } else if (key.name == "定投周期") {
                  this.fixedTime[1] = key.value;
                  this.copyFixedTime[1] = key.value;
                }
              }
            }

            if (this.strategyId == "WL202007311426363424x") {
              for (let key of this.reserves) {
                if (key.name == "网格间隔(等比)" && key.value != 0) {
                  this.gridDistribution = true;
                } else if (key.name == "网格间隔(等差)" && key.value != 0) {
                  this.gridDistribution = false;
                }
              }
            }

            this.modifyParametersShow = true;
          } else {
            this.$message.error(res.msg);
          }
        });
      } else {
        let list = JSON.parse(sessionStorage.getItem("selectStrategy"));
        console.log(list);
        this.config = JSON.parse(list[0].config);
        console.log(this.config);
        this.strategyName = list[0].strategyName;
        this.strategyId = list[0].strategyId;

        this.tradePairs = this.config.tradePairs[0];
        console.log(this.config);
        this.openingThreshold = this.tradePairs.openThreshold * 100;
        this.contractType = this.tradePairs.contractType;
        this.reserves = JSON.parse(JSON.stringify(this.config.reserves));
        this.reservesCopy = JSON.parse(JSON.stringify(this.config.reserves));

        this.stopProfit = this.config.stopProfit
          ? Number(this.config.stopProfit) * 100
          : null;
        this.stopLoss = this.config.stopLoss
          ? Number(this.config.stopLoss) * -1 * 100
          : null;
        this.stopTime = this.config.stopTime
          ? Number(this.config.stopTime)
          : null;

        if (
          this.strategyId == "WL20210512112808681" ||
          this.strategyId == "WL202X0512112808681"
        ) {
          for (let key of this.reserves) {
            if (key.name == "补仓金额模式") {
              this.replenishment = key.value == 0 ? true : false;
            } else if (key.name == "补仓间隔模式") {
              this.intervalMode = key.value == 0 ? true : false;
            }
          }
        }
        if (this.strategyId == "WL20210624182847743") {
          this.reserves.forEach((key) => {
            if (key.name == "补仓下单模式") {
              this.replenishmentbutionTwo = key.value == 0 ? true : false;
            } else if (key.name == "止盈平仓模式") {
              this.replenishmentbutionThree = key.value == 0 ? true : false;
            } else if (key.name == "止盈下单模式") {
              this.replenishmentbutionFour = key.value == 0 ? true : false;
            } else if (key.name == "补仓金额模式") {
              this.replenishmentbutionOne = key.value == 0 ? true : false;
            }
          });
        }

        if (this.strategyId == "WL20190402171936974x") {
          for (let key of this.reserves) {
            if (key.name == "定投类型") {
              this.fixedTime[0] = key.value;
              this.copyFixedTime[0] = key.value;
            } else if (key.name == "定投周期") {
              this.fixedTime[1] = key.value;
              this.copyFixedTime[1] = key.value;
            }
          }
        }

        if (this.strategyId == "WL202007311426363424x") {
          for (let key of this.reserves) {
            if (key.name == "网格间隔(等比)" && key.value != 0) {
              this.gridDistribution = true;
            } else if (key.name == "网格间隔(等差)" && key.value != 0) {
              this.gridDistribution = false;
            }
          }
        }

        this.modifyParametersShow = true;
      }
      console.log(strategy);
      // this.reserves=strategy[0].reserves
      // this.strategyName=  strategy[0].strategyName

      // this.modifyParametersShow=true
    },

    // 查询订单链详情
    findByIdOrderChain(payload) {
      findByIdOrderChain(payload).then((res) => {
        if (res.status == 200) {
          console.log("查询的订单链详情", res.data.intelligencOrderChain);

          let orderType = res.data.intelligencOrderChain.transactionType;
          console.log(orderType);

          if (orderType == "spot") {
            this.transactionType = "spot";
            this.orderList = JSON.parse(
              JSON.stringify(res.data.intelligencOrderChain)
            );

            let list = res.data.intelligencOrderChain.orderList;

            console.log(list);
            console.log(JSON.parse(list[0].displayConfig));
            let newList = [];
            for (let key of list) {
              console.log(key);
              let item = JSON.parse(key.displayConfig);
              console.log(key.executeStates, item);
              item.executeStates = key.executeStates;

              if (key.actionIndex) {
                item.actionIndex = key.actionIndex;
                // 判断是否有关闭的动作
                for (let i = 0; i < item.actionList.length; i++) {
                  console.log(item.actionList[i]);
                  if (!item.actionList[i].isCheck) {
                    // console.log('111111111111111',i)
                    item.actionIndex = Number(item.actionIndex) + 1 + "";
                  }
                  // console.log(item.actionList[i].isCheck)
                }
              }

              console.log(item);
              newList.push(item);
            }

            newList.reverse();

            this.$refs.child.orderList = newList;
            this.$refs.child.singleDirection = newList[0].singleDirection;
            this.$refs.child.investmentType = newList[0].investmentType;
            this.$refs.child.actionIdx = 0;
            this.$refs.child.showPair =
              newList[0].pair.counterCoin + "/" + newList[0].pair.baseCoin;
            this.$refs.child.investment = newList[0].investment;
            this.$refs.child.createdActionList = newList[0].actionList;
            this.$refs.child.inputCurrency = newList[0].inputCurrency;
            this.$refs.child.actionParams = JSON.parse(
              newList[0].actionList[0].params
            );

            console.log(newList);

            if (this.batchModify) {
              this.$refs.child.executeStates = true;
            } else {
              this.$refs.child.executeStates = false;
            }
            setTimeout(() => {
              this.$refs.child.$refs.child.percentage =
                newList[0].actionList[0].percentage;
              // this.$refs.child.$refs.child.executeStates=list[0].executeStates
            }, 300);
            this.$refs.child.isUpdate = true;
            this.$refs.child.detailIsFalse = false;

            console.log(newList);
            console.log(1, this.$refs.child.orderList);
          } else {
            this.transactionType = "contract_usdt";
            this.orderList = JSON.parse(
              JSON.stringify(res.data.intelligencOrderChain)
            );

            let list = res.data.intelligencOrderChain.orderList;

            this.controlsPrice = Number(list[list.length - 1].price);

            console.log(list);
            let newList = [];

            for (let key of list) {
              let item = JSON.parse(key.displayConfig);
              console.log(key.executeStates, item, key);
              item.executeStates = key.executeStates;
              item.actionIndex = key.actionIndex;
              newList.push(item);
            }

            newList.reverse();

            console.log(newList);

            // // 查询合约交易对
            let payload = {
              teamProjectId: this.project.teamProjectId,
              exchangeId: res.data.intelligencOrderChain.exchangeId,
              token: sessionStorage.getItem("token"),
            };
            this.$refs.child.queryOrderChainContractPair(payload, "update");

            // // 查询合约杠杆
            let params = {
              exchangeId: res.data.intelligencOrderChain.exchangeId,
              token: sessionStorage.getItem("token"),
            };

            this.$refs.child.findByIdLeverMultiple(params);

            console.log();

            this.$refs.child.orderList = newList;
            this.$refs.child.contractPair = newList[0].contractPair;
            this.$refs.child.lever = newList[0].leverRate;
            this.$refs.child.investmentType = newList[0].investmentType;
            this.$refs.child.proportionMax = newList[0].proportionMax;
            this.$refs.child.proportionMin = newList[0].proportionMin;

            this.$refs.child.investment = newList[0].investment;
            this.$refs.child.createdActionList = newList[0].actionList;

            this.$refs.child.actionParams = JSON.parse(
              newList[0].actionList[0].params
            );
            this.$refs.child.singleDirection = newList[0].singleDirection;
            console.log(newList);

            if (this.batchModify) {
              this.$refs.child.executeStates = true;
            } else {
              this.$refs.child.executeStates = false;
            }
            setTimeout(() => {
              this.$refs.child.$refs.child.percentage =
                newList[0].actionList[0].percentage;
              // this.$refs.child.$refs.child.executeStates=list[0].executeStates
            }, 300);
            this.$refs.child.isUpdate = true;
            this.$refs.child.detailIsFalse = false;

            console.log(newList);
            console.log(1, this.$refs.child.orderList);
          }
        }
      });
    },

    // 修改订单链
    updateOrderChain() {
      let orderIndex = this.$refs.child.orderIndex; //当前订单索引
      let actionIdx = this.$refs.child.actionIdx; //当前动作索引

      let orderList = this.$refs.child.orderList; //当前订单链

      let orderChainList = JSON.parse(JSON.stringify(this.orderList)); //原订单链

      let controls = this.$refs.child.$refs.child.childParams; //当前订单动作参数
      let links = this.$refs.child.$refs.child.linksParams;
      let percentage = this.$refs.child.$refs.child.percentage; //当前订单动作下单资金比例
      let payload = {
        controls,
        links,
      };
      orderList[orderIndex].actionList[actionIdx].params =
        JSON.stringify(payload);

      orderList[orderIndex].actionList[actionIdx].percentage = percentage;

      let newOrderList = JSON.parse(
        JSON.stringify(orderChainList.orderList.reverse())
      );

      let num = newOrderList.length - orderList.length;
      newOrderList.splice(orderList.length, num);
      console.log(newOrderList);

      let params = {
        token: sessionStorage.getItem("token"),
        transactionType: this.tableActive == 0 ? "spot" : "contract_usdt",
        orderChainId: this.orderChainId,
        orderChain: [],
      };

      for (let i = 0; i < newOrderList.length; i++) {
        let item = null; //新的订单集
        item = JSON.parse(JSON.stringify(newOrderList[i]));
        item.displayConfig = JSON.parse(item.displayConfig);
        item.executeConfig = JSON.parse(item.executeConfig);
        item.executeConfig.actions = [];

        // console.log(JSON.parse(item.displayConfig))
        // console.log(JSON.parse(item.executeConfig))
        console.log(orderList[i]);
        console.log(item);

        let actionList = orderList[i].actionList;
        for (let z = 0; z < actionList.length; z++) {
          item.displayConfig.actionList[z] = actionList[z];

          for (let info of actionList) {
            console.log(info);

            // 动作是否开启
            if (info.isCheck) {
              let actionsItem = {
                name: info.name,
                investRate: info.percentage,
              };

              // 添加动作参数
              let list = JSON.parse(info.params).controls;
              let params = [];
              for (let cont of list) {
                console.log(cont);

                if (
                  cont.build != false &&
                  cont.controltype != "multi-switch" &&
                  cont.controltype != "signal-input"
                ) {
                  let val = {};
                  val.name = cont.name;
                  val.value = cont.value ? cont.value : null;
                  val.valueType = cont.valueType;
                  params.push(val);
                } else if (cont.controltype == "multi-switch") {
                  let val = {};
                  val.name = cont.name;
                  val.value = cont.proposed;
                  val.valueType = cont.valueType;
                  params.push(val);
                  console.log(cont);
                } else if (cont.controltype == "signal-input") {
                  console.log(cont);
                  let val = {};
                  val.name = cont.name;
                  val.valueType = cont.valueType;
                  val.value = {
                    signalId: cont.signal.signalId,
                    attrName: cont.signalAttribute.name,
                    opt: cont.signalOpt,
                    value: cont.value,
                  };
                  params.push(val);
                }
              }
              if (params.length > 0) {
                actionsItem.params = params;
              }
              console.log(actionsItem);
              item.executeConfig.actions.push(actionsItem);
            }
          }
        }

        console.log(item);
        params.orderChain.push(item);
      }

      for (let key of params.orderChain) {
        key.displayConfig = JSON.stringify(key.displayConfig);
        key.executeConfig = JSON.stringify(key.executeConfig);
      }

      params.orderChain = params.orderChain.reverse();

      console.log("参数", params);

      if (this.batchModify) {
        console.log(this.selectRowList);
        let orderChain = "";
        this.selectRowList.forEach((e, index) => {
          if (index == 0) {
            orderChain = e.orderChainId;
          } else {
            orderChain += "," + e.orderChainId;
          }
        });

        let payload = {
          token: sessionStorage.getItem("token"),
          orderChainIdStr: orderChain,
          orderChain: params.orderChain,
          transactionType: this.tableActive == 0 ? "spot" : "contract_usdt",
        };

        batchUpdateOrderChain(payload).then((res) => {
          if (res.status == 200) {
            this.batchModify = false;
            this.dialogVisible = false;
            this.$refs.child.executeStates = false;
            this.orderChainId = null;
            this.$message.success(this.$t('m.操作成功'));
          } else {
            this.$message.error(res.msg);
          }
        });
      } else {
        updateOrderChain(params).then((res) => {
          if (res.status == 200) {
            this.dialogVisible = false;
            this.$refs.child.executeStates = false;
            this.orderChainId = null;
            this.batchModify = false;
            this.$message.success(this.$t('m.操作成功'));
          } else {
            this.$message.error(res.msg);
          }
        });
      }
    },

    //**************持仓 */
    setActive(e) {
      console.log(e);
      sessionStorage.setItem("projectActive", e);
      this.$router.push("Project");
    },

    //币币划转
    transferDetermineBtn(e) {
      this.transformation = true;
      this.transfer = true;
      this.spotByApp = e;
      this.currencyValue = e.coinType;
      this.warehouse = this.exchangeList.warehouse;
      console.log(this.spotByApp);
      if (this.exchangeName == "OkexV5") {
        this.topValue = "统一账户";
        this.topOptions = [{ name: "统一账户" }];
        this.twoValue = "资金账户";
        this.twoOptions = [{ name: "资金账户" }];
        this.currencyList = this.tradePair;
        this.transformation = false;

        setTimeout(() => {
          let payload = {
            token: sessionStorage.getItem("token"),
            apiId: this.spotByApp.apiId,
            coin: this.currencyValue,
          };
          this.findFundSpot(payload);
        }, 300);
      } else {
        if (this.exchangeName == "Huobi") {
          let payload = {
            token: sessionStorage.getItem("token"),
            teamProjectId: this.project.teamProjectId,
            exchangeName: this.exchangeName,
          };
          this.findContractId(payload);
        } else {
          let payloadData = {
            token: sessionStorage.getItem("token"),
            teamProjectId: this.project.teamProjectId,
            accountMode: this.warehouse ? "isolated" : "cross",
            exchangeName: e.exchangeName,
            apiName: e.apiName,
          };
          this.getContractApiList(payloadData);
        }
        setTimeout(() => {
          let payload = {
            token: sessionStorage.getItem("token"),
            apiId: this.spotByApp.apiId,
            coin: "USDT",
          };
          this.findFundSpot(payload);
        }, 300);
      }
    },
    topValueChange(e) {
      if (!this.transformation) {
        let payload = {
          token: sessionStorage.getItem("token"),
          apiId: this.spotByApp.apiId,
          exchangeName: this.spotByApp.exchangeName,
          contractId: e.split(" ")[0],
        };
        this.findContract(payload);
      }
    },
    twoValueChange(e) {},

    //**************************合约持仓*********************
    /////////////////////////////合约
    //创建策略
    templateBtn(e) {
      if (!this.detailsOperationAuthority) {
        this.$alert(
          this.$t('m.您没有权限使用该功能，敬请谅解！'), 
          this.$t('m.额，好像没有权限'), {
          confirmButtonText: this.$t('m.知道啦'),
        });
        return;
      }
      console.log(this.exchangeList);
      e.exchangeId = this.exchangeList.exchangeId;
      e.contract = true;
      let list = [e];
      sessionStorage.setItem("startAcc", JSON.stringify(list));
      this.$emit("childFn", "1-1");
    },
    //创建订单链
    orderBtn(e) {
      if (!this.detailsOperationAuthority) {
        this.$alert(
          this.$t('m.您没有权限使用该功能，敬请谅解！'), 
          this.$t('m.额，好像没有权限'), {
          confirmButtonText: this.$t('m.知道啦'),
        });
        return;
      }
      e.exchangeId = this.exchangeList.exchangeId;
      e.contract = true;
      let list = [e];
      sessionStorage.setItem("startAcc", JSON.stringify(list));
      this.$emit("childFn", "2-1");
    },

    //合约划转按钮
    transferBtn(e) {
      this.transformation = true;
      this.spotByApp = e;
      this.transfer = true;
      this.warehouse = this.exchangeList.warehouse;

      let payload = {
        token: sessionStorage.getItem("token"),
        apiId: e.apiId,
        coin: "USDT",
      };

      this.findFundSpot(payload);

      this.currencyList = ["USDT"];

      let payloadData = {
        token: sessionStorage.getItem("token"),
        teamProjectId: this.project.teamProjectId,
        accountMode: this.warehouse ? "isolated" : "cross",
        exchangeName: e.exchangeName,
        apiName: e.apiName,
      };
      this.getContractApiList(payloadData);
    },
    //输入框校验
    inputNumber(e) {
      console.log(this.inputOptions, this.usdtAvailable);
      if (Number(this.inputOptions) <= 0) {
        this.$message.warning("划转不能小于0");
        e.target.style.border = "1px solid red";
        this.inputVerification = false;
      } else if (Number(this.inputOptions) > this.usdtAvailable) {
        console.log(1111111111111);
        this.$message.warning("划转不能大于可用划转金额");
        e.target.style.border = "1px solid red";
        this.inputVerification = false;
      } else {
        e.target.style.border = "1px solid #DCDFE6";
        this.inputVerification = true;
      }
    },
    inputNumberFocus(e) {
      e.target.style.border = "1px solid #DCDFE6";
    },

    //获取合约可划转账户
    findFundSpot(payload) {
      findFundSpot(payload).then((res) => {
        console.log(res);
        let data = res.data;
        let available = data.available;
        if (Number(available) == 0) {
          available = 0;
        } else {
          available = available.slice(0, available.indexOf(".") + 5);
        }
        this.usdtAvailable = available;
      });
    },
    //划转
    transferDetermine() {
      if (Number(this.inputOptions) <= 0) {
        return this.$message.warning("划转不能小于0");
      } else if (Number(this.inputOptions) > this.usdtAvailable) {
        return this.$message.warning("划转不能大于可用划转金额");
      }
      let payload = {};

      if (this.transformation) {
        payload = {
          token: sessionStorage.getItem("token"),
          paramStr: {
            from: "spot",
            to: "linear-swap",
            currency: this.currencyValue,
            amount: this.inputOptions,
            "margin-account": this.warehouse
              ? this.twoValue.split(" ")[2]
              : this.twoValue.split(" ")[0],
          },
          apiId: this.spotByApp.apiId,
        };

        if (this.exchangeName == "OkexV5") {
          payload.paramStr = {
            ...payload.paramStr,
            "margin-account": this.currencyValue,
          };
        }
      } else {
        payload = {
          token: sessionStorage.getItem("token"),
          paramStr: {
            from: "linear-swap",
            to: "spot",
            currency: this.currencyValue,
            amount: this.inputOptions,
            "margin-account": this.warehouse
              ? this.topValue.split(" ")[2]
              : this.topValue.split(" ")[0],
          },
          apiId: this.spotByApp.apiId,
        };
        if (this.exchangeName == "OkexV5") {
          payload.paramStr = {
            ...payload.paramStr,
            "margin-account": this.currencyValue,
          };
        }
      }

      console.log(payload);
      transfer(payload).then((res) => {
        if (res.status == 200) {
          this.transfer = false;
          this.inputOptions = "";
          this.pageNo = 1;
          this.batchShow = true;

          this.$message.success(this.$t('m.操作成功'));
          this.batchSynchSpotApi();
          this.batchSynchContractApi();
          this.batchSynchAssetApi();
          if (this.tableActive == 0) {
            this.tableActiveData = 0;
            this.$refs.child.btnBack();
          } else if (this.tableActive == 1) {
            this.tableActiveData = 1;
            this.$refs.child.btnBack();
          } else {
            let payload = {
              token: sessionStorage.getItem("token"),
              apiId: this.apiId,
              pageSize: 10,
              pageNo: 1,
            };
            this.getAssetApiList(payload);
            this.$refs.child.btnBack();
          }
          setTimeout(() => {
            this.batchShow = false;
          }, 3000);
        } else {
          this.$message.error(res.msg);
        }
      });
    },

    //合约select
    contractTableBtn(e) {
      this.pageNo = 1;
      console.log(this.warehouse);
      let payload = {
        token: sessionStorage.getItem("token"),
        exchangeName: this.exchangeList.exchangeName,
        apiName: this.name.split("-")[0] + "-" + this.name.split("-")[1],
        teamProjectId: this.project.teamProjectId,
        userId: this.userId,
        accountMode: this.exchangeList.warehouse ? "isolated" : "cross",
        pageSize: 10,
        pageNo: 1,
      };
      this.getContractApiList(payload);
    },

    //全选
    allContractSelect() {
      this.contractTable.forEach((e) => {
        e.select = this.contractSelectBtn;
        if (e.positions == null) {
          return;
        } else if (e.positions.length == 0) {
          return;
        } else {
          e.positions.forEach((item) => {
            item.select = this.contractSelectBtn;
          });
        }
      });
    },

    //外层的选择框
    FirstSelect(data) {
      console.log(data);
      if (data.select == false) {
        this.contractSelectBtn = false;
        if (data.positions == null) {
          return;
        } else if (data.positions.length == 0) {
          return;
        } else {
          data.positions.forEach((e) => {
            e.select = false;
          });
        }
      } else {
        let number = 0;
        for (let i = 0; i < this.contractTable.length; i++) {
          if (this.contractTable[i].select == true) {
            number++;
          }
        }
        if (number == this.contractTable.length) {
          this.contractSelectBtn = true;
        }
        if (data.positions == null) {
          return;
        } else if (data.positions.length == 0) {
          return;
        } else {
          data.positions.forEach((e) => {
            e.select = true;
          });
        }
      }
    },
    //里面的选择框
    TwoSelect(data) {
      let number = 0;
      data.positions.forEach((e) => {
        if (e.select == false) {
          this.contractSelectBtn = false;
          data.select = false;
        } else {
          number++;
          if (number == data.positions.length) {
            data.select = true;
            let numberAll = 0;
            for (let i = 0; i < this.contractTable.length; i++) {
              if (this.contractTable[i].select == true) {
                numberAll++;
              }
            }
            console.log(numberAll);
            if (numberAll == this.contractTable.length) {
              this.contractSelectBtn = true;
            }
          }
        }
      });
      console.log(data);
    },
    //获取合约页面信息
    getContractApiList(payload) {
      payload.coinValue = this.showSmallCoin == true ? 10 : "";
      getContractApiList(payload).then((res) => {
        if (res.status == 200) {
          console.log(res.data, "合约页面信息");
          let contractList = res.data.contractList;
          this.listNumber = res.data.totalNum;

          contractList.forEach((e) => {
            e.select = false;
            e.show = true;
            //如果不是项目支持的币种 不给按钮显示
            if (this.tradePair.indexOf(e.coinType) == -1) {
              e.news = true;
            }
            if (!e.contractId) {
              e.contractId = "USDT 合约全仓";
            } else {
              let number = 0;
              this.tradePair.forEach((item) => {
                if (item !== e.contractId.split("-")[0]) {
                  number++;
                } else {
                  e.state = true;
                }
              });
              if (number == this.tradePair.length) {
                e.state = false;
              }
            }
            if (Number(e.available) == 0) {
              e.available = 0;
            } else {
              e.available = Number(e.available)
                .toString()
                .slice(0, Number(e.available).toString().indexOf(".") + 5);
            }
            if (Number(e.equity) == 0) {
              e.equity = 0;
            } else {
              e.equity = Number(e.equity)
                .toString()
                .slice(0, Number(e.equity).toString().indexOf(".") + 5);
            }
            if (e.positions == null) {
              return;
            } else {
              if (JSON.parse(e.positions).length == 0) {
                e.positions = null;
              } else {
                let positions = JSON.parse(e.positions);
                console.log(positions);
                positions.forEach((item) => {
                  item.select = false;
                  item.volumeNum = item.volumeNum.toString().split(".")[0];
                  console.log(item.volumeNum);
                  item.availableNum = Number(item.availableNum)
                    .toString()
                    .split(".")[0];
                  item.costOpen = item.costOpen
                    .toString()
                    .slice(0, item.costOpen.toString().indexOf(".") + 5);
                  if (item.contractId.indexOf("SWAP") > -1) {
                    item.contractIdType = item.contractId.split("-SWAP")[0];
                  } else {
                    item.contractIdType = item.contractId;
                  }
                  item.riskRate = item.riskRate * 100;
                  item.riskRate = item.riskRate
                    .toString()
                    .slice(0, item.riskRate.toString().indexOf(".") + 5);
                  if (item.liquidationPrice !== 0) {
                    item.liquidationPrice = item.liquidationPrice.slice(
                      0,
                      item.liquidationPrice.indexOf(".") + 3
                    );
                  }
                  if (item.investCoin !== "BTC") {
                    item.profit = item.profit.slice(
                      0,
                      item.profit.indexOf(".") + 5
                    );
                  } else {
                    item.profit = item.profit.slice(
                      0,
                      item.profit.indexOf(".") + 7
                    );
                  }
                  item.profitRate = (Number(item.profitRate) * 100).toString();
                  item.profitRate = item.profitRate.slice(
                    0,
                    item.profit.indexOf(".") + 3
                  );
                });
                e.positions = positions;
              }
            }
          });
          if (!this.transfer) {
            this.contractTable = contractList;
            this.warehouse = this.exchangeList.warehouse;
          } else {
            if (!this.transformation) {
              console.log(this.spotByApp);
              if (contractList.length == 0) {
                this.tradePair.forEach((e) => {
                  if (e !== this.spotByApp.investCoin) {
                    contractList.push({
                      contractId: e + "-" + this.spotByApp.investCoin,
                    });
                  }
                });
              }

              contractList.forEach((e) => {
                console.log(this.warehouse);
                if (this.spotByApp.contractId) {
                  if (e.contractId == this.spotByApp.contractId) {
                    if (this.warehouse) {
                      this.topValue = "USDT 永续合约 " + e.contractId;
                    } else {
                      this.topValue = e.contractId;
                    }
                  }
                } else {
                  if (this.warehouse) {
                    this.topValue = "USDT 永续合约 BTC-USDT";
                  } else {
                    this.topValue = "USDT 合约全仓";
                  }
                }

                if (this.warehouse) {
                  e.name = "USDT 永续合约 " + e.contractId;
                } else {
                  e.name = e.contractId;
                }
              });

              this.topOptions = contractList;
              this.contractTable = contractList;
              this.contractList = contractList;

              this.twoOptions = [{ name: "USDT 币币账户" }];
              this.twoValue = "USDT 币币账户";
              console.log(this.topValue);
            } else {
              console.log(this.tradePair, this.spotByApp);
              if (contractList.length == 0) {
                this.tradePair.forEach((e) => {
                  if (e !== this.spotByApp.investCoin) {
                    contractList.push({
                      contractId: e + "-" + this.spotByApp.investCoin,
                    });
                  }
                });
              }
              contractList.forEach((e) => {
                console.log(this.warehouse);
                if (this.warehouse) {
                  e.name = "USDT 永续合约 " + e.contractId;
                  if (e.contractId == this.spotByApp.contractId) {
                    this.twoValue = "USDT 永续合约 " + e.contractId;
                  }
                } else {
                  e.name = e.contractId;
                  if (e.contractId == this.spotByApp.contractId) {
                    this.twoValue = e.contractId;
                  }
                }
              });
              console.log(this.spotByApp);
              if (!this.spotByApp.contractId) {
                if (this.spotByApp.exchangeName == "Huobi") {
                  this.twoValue = "USDT 永续合约" + e.contractId;
                } else {
                  this.twoValue = "USDT 合约全仓";
                }
              }
              this.topOptions = [{ name: "USDT 币币账户" }];
              this.topValue = "USDT 币币账户";

              console.log(this.twoValue);
              this.twoOptions = contractList;
            }
          }
        }
      });
    },
    //获取币币同步全部资产
    findContract(payload) {
      findContract(payload).then((res) => {
        if (res.status == 200) {
          if (res.data) {
            let data = res.data;
            if (Number(data.available) == 0) {
              data.available = 0;
            } else {
              data.available = Number(data.available)
                .toString()
                .slice(0, Number(data.available).toString().indexOf(".") + 5);
            }
            this.usdtAvailable = data.available;
            this.contractData = data;
          } else {
            this.usdtAvailable = 0;
          }
        }
      });
    },

    //同步资产按钮
    synchronization() {
      let payload = {
        token: sessionStorage.getItem("token"),
        // api: "9634",
      };
      let number = [];
      let posititonsNumber = [];
      this.contractTable.forEach((e) => {
        if (e.select == true) {
          number.push(e);
          if (number.length > 1) {
            return this.batchSynchContractApi();
          }
        }
      });
      if (number.length !== 0) {
        payload.apiId = number[0].apiId;
        let contractNumber = 0;
        console.log(number);
        if (number[0].positions !== null) {
          number[0].positions.forEach((e) => {
            if (e.select == true) {
              contractNumber++;
            }
            if (contractNumber > 1) {
              return this.synchSingelContractApi(payload);
            }
          });
          if (contractNumber !== 0) {
            payload.contractId = number[0].positions[0].contractId;
            return this.synchSingelContractApi(payload);
          }
        } else {
          payload.contractId = number[0].contractId;
          return this.synchSingelContractApi(payload);
        }
      } else {
        this.contractTable.forEach((e) => {
          if (e.positions !== null) {
            e.positions.forEach((item) => {
              if (item.select == true) {
                posititonsNumber.push(item);
              }
              if (posititonsNumber.length > 1) {
                payload.apiId = e.apiId;
                return this.synchSingelContractApi(payload);
              }
            });
          }
        });
        if (posititonsNumber.length !== 0) {
          payload.apiId = e.apiId;
          payload.contractId = posititonsNumber[0].contractId;
          return this.synchSingelContractApi(payload);
        } else {
          return this.batchSynchContractApi();
        }
      }
    },
    //合约同步资产
    batchSynchContractApi() {
      let payload = {
        token: sessionStorage.getItem("token"),
      };
      batchSynchContractApi(payload).then((res) => {
        if (res.status == 200) {
          if (this.batchShow == true) {
            if (this.tableActive == 1) {
              let payload = {
                token: sessionStorage.getItem("token"),
                exchangeName: this.exchangeName,
                teamProjectId: this.project.teamProjectId,
                accountMode: this.warehouse ? "isolated" : "cross",
                userId: this.userId,
                pageSize: 10,
                pageNo: 1,
              };
              this.getContractApiList(payload);
            }
          } else {
            this.$message.success(this.$t('m.操作成功'));
            let apiName = this.name.split("-");
            if (this.tableActive == 1) {
              let payload = {
                token: sessionStorage.getItem("token"),
                exchangeName: this.tradePair.exchangeName,
                teamProjectId: this.project.teamProjectId,
                accountMode: this.warehouse ? "isolated" : "cross",
                userId: this.userId,
                apiName: apiName[0] + "-" + apiName[1],
                pageSize: 10,
                pageNo: 1,
              };
              this.getContractApiList(payload);
            } else {
              let payload = {
                token: sessionStorage.getItem("token"),
                teamProjectId: this.project.teamProjectId,
                userId: this.userId,
                apiName: apiName[0] + "-" + apiName[1],
                ...this.searchData,
                pageSize: 10,
                pageNo: 1,
              };
              this.getSpotApiList(payload);
            }
          }
        }
      });
    },
    //合约同步单个资产
    synchSingelContractApi(payload) {
      synchSingelContractApi(payload).then((res) => {
        if (res.status == 200) {
          this.$message.success(this.$t('m.操作成功'));
        }
        console.log(res);
      });
    },
    //合约快速平仓
    quickFlatPosition() {
      if (!this.detailsOperationAuthority) {
         this.$alert(
          this.$t('m.您没有权限使用该功能，敬请谅解！'), 
          this.$t('m.额，好像没有权限'), {
          confirmButtonText: this.$t('m.知道啦'),
        });
        return;
      }
      let number = 0;
      this.contractTable.forEach((e) => {
        if (e.select == true) {
          number++;
        }
        if (e.positions !== null) {
          e.positions.forEach((item) => {
            if (item.select == true) {
              number++;
            }
          });
        }
      });
      if (number !== 0) {
        let flatPositionStr = [];
        this.contractTable.forEach((e) => {
          if (e.positions !== null) {
            e.positions.forEach((item) => {
              console.log(e);
              if (item.select == true) {
                console.log(item);
                flatPositionStr.push({
                  apiId: e.apiId,
                  exchangeName: e.exchangeName,
                  investCoin: item.counterCoin,
                  contractId: item.contractId,
                  direction: item.direction,
                  userId: e.userId,
                });
              }
            });
          }
        });
        let payload = {
          token: sessionStorage.getItem("token"),
          flatPositionStr: flatPositionStr,
        };
        quickFlatPosition(payload).then((res) => {
          if (res.status == 200) {
            let payloadData = {
              token: sessionStorage.getItem("token"),
            };
            batchSynchSpotApi(payloadData).then((res) => {
              if (res.status == 200) {
                console.log("同步成功");
              }
            });
            batchSynchContractApi(payloadData).then((res) => {
              if (res.status == 200) {
                console.log("同步成功");
              }
            });
            batchSynchAssetApi(payloadData).then((res) => {
              if (res.status == 200) {
                console.log("同步成功");
              }
            });
            let apiName = this.name.split("-");
            let payload = {
              token: sessionStorage.getItem("token"),
              exchangeName: this.exchangeList.exchangeName,
              teamProjectId: this.project.teamProjectId,
              apiName: apiName[0] + "-" + apiName[1],
              accountMode: this.exchangeList.warehouse ? "isolated" : "cross",
              userId: this.userId,
              pageSize: 10,
              pageNo: this.pageNo,
            };

            setTimeout(() => {
              this.getContractApiList(payload);
            }, 1000);
            this.contractSelectBtn = false;
            this.$message.success(this.$t('m.操作成功'));
          } else {
            this.$message.error(this.$t('m.未选中可平的持仓'));
          }
        });
      } else {
        this.$message.warning("请选择实例");
      }
    },
    //获取项目可用币种
    getProject() {
      let payload = {
        teamProjectId: this.project.teamProjectId,
        token: sessionStorage.getItem("token"),
      };
      getProject(payload).then((res) => {
        console.log("项目信息", res.data.project);
        let tradePair = JSON.parse(res.data.project.tradePair);
        this.tradePair = tradePair;
      });
    },

    //转换按钮
    transformationBtn() {
      this.transformation = !this.transformation;
      if (this.exchangeName == "OkexV5") {
        if (this.transformation == false) {
          this.topValue = "统一账户";
          this.topOptions = [{ name: "统一账户" }];
          this.twoValue = "资金账户";
          this.twoOptions = [{ name: "资金账户" }];
          this.currencyList = this.tradePair;

          setTimeout(() => {
            let payload = {
              token: sessionStorage.getItem("token"),
              apiId: this.spotByApp.apiId,
              coin: this.currencyValue,
            };
            this.findFundSpot(payload);
          }, 300);
        } else {
          this.twoValue = "统一账户";
          this.twoOptions = [{ name: "统一账户" }];
          this.topValue = "资金账户";
          this.topOptions = [{ name: "资金账户" }];
          let payload = {
            token: sessionStorage.getItem("token"),
            apiId: this.spotByApp.apiId,
            coin: this.currencyValue,
          };

          this.findFundAssets(payload);
        }
      } else {
        if (this.exchangeName == "Huobi") {
          let payload = {
            token: sessionStorage.getItem("token"),
            teamProjectId: this.project.teamProjectId,
            exchangeName: this.exchangeName,
          };
          this.findContractId(payload);
        } else {
          let payloadData = {
            token: sessionStorage.getItem("token"),
            teamProjectId: this.project.teamProjectId,
            accountMode: this.warehouse ? "isolated" : "cross",
            exchangeName: this.spotByApp.exchangeName,
            apiName: this.spotByApp.apiName,
          };
          this.getContractApiList(payloadData);
        }
        console.log(this.topValue);
        setTimeout(() => {
          if (!this.transformation) {
            let payload = {
              token: sessionStorage.getItem("token"),
              apiId: this.spotByApp.apiId,
              exchangeName: this.spotByApp.exchangeName,
            };
            if (this.exchangeName == "Huobi") {
              payload.contractId = this.topValue.split(" ")[2];
            } else {
              payload.contractId = this.topValue.split(" ")[0];
            }
            this.findContract(payload);
          } else {
            let payload = {
              token: sessionStorage.getItem("token"),
              apiId: this.spotByApp.apiId,
              coin: "USDT",
            };
            this.findFundSpot(payload);
          }
        }, 300);
      }
    },

    findFundAssets(payload) {
      findFundAssets(payload).then((res) => {
        if (res.status == 200) {
          console.log(res, 1111111111);
          this.usdtAvailable = res.data.available;
        }
      });
    },
    // v5同步资产按钮
    synchronizationV5() {
      if (!this.detailsOperationAuthority) {
        this.$alert(
          this.$t('m.您没有权限使用该功能，敬请谅解！'), 
          this.$t('m.额，好像没有权限'), {
          confirmButtonText: this.$t('m.知道啦'),
        });
        return;
      }
      let payload = {
        token: sessionStorage.getItem("token"),
      };
      let number = [];
      this.contractTable.forEach((e) => {
        if (e.select == true) {
          number.push(e);
          if (number.length > 1) {
            return this.batchSynchAssetApi();
          }
        }
      });
      if (number.length == 0) {
        return this.batchSynchAssetApi();
      }
      payload.contractId = number[0].coinType;
      payload.api = this.apiId;
      this.synchSingelAssetApi(payload);
    },
    //同步v5资产
    batchSynchAssetApi() {
      let payload = {
        token: sessionStorage.getItem("token"),
      };
      batchSynchAssetApi(payload).then((res) => {
        if (res.status == 200) {
          if (this.batchShow == true) {
            if (this.tableActive == 2) {
              let payload = {
                token: sessionStorage.getItem("token"),
                apiId: this.apiId,
                pageSize: 10,
                pageNo: 1,
              };
              this.getAssetApiList(payload);
              this.contractSelectBtn = false;
            }
          } else {
            this.$message.success(this.$t('m.操作成功'));
            let payload = {
              token: sessionStorage.getItem("token"),
              apiId: this.apiId,
              pageSize: 10,
              pageNo: 1,
            };
            this.getAssetApiList(payload);
          }
        }
      });
    },
    //同步单个v5资产
    synchSingelAssetApi(payload) {
      synchSingelAssetApi(payload).then((res) => {
        if (res.status == 200) {
          this.$message.success(this.$t('m.操作成功'));
          let payload = {
            token: sessionStorage.getItem("token"),
            apiId: this.apiId,
            pageSize: 10,
            pageNo: 1,
          };

          this.getAssetApiList(payload);
        }
      });
    },
    getAssetApiList(payload) {
      getAssetApiList(payload).then((res) => {
        if (res.status == 200) {
          console.log(res);
          let assetApiList = res.data.assetApiList;
          this.contractTable = assetApiList;
        } else {
          this.contractTable = [];
        }
      });
    },
    //获取可用币对合约
    findContractId(payload) {
      findContractId(payload).then((res) => {
        console.log(res);
        let data = res.data;
        let contractIdList = [];
        data.forEach((e) => {
          contractIdList.push({ name: "USDT 永续合约 " + e.contractId });
        });
        if (this.transformation) {
          this.twoOptions = contractIdList;
          this.twoValue = contractIdList[0].name;
          this.topOptions = [{ name: "USDT 币币账户" }];
          this.topValue = "USDT 币币账户";
        } else {
          this.topOptions = contractIdList;
          this.topValue = contractIdList[0].name;
          this.twoOptions = [{ name: "USDT 币币账户" }];
          this.twoValue = "USDT 币币账户";
        }
      });
    },
    //查询信号列表
    querySignalList() {
      let payload = {
        token: sessionStorage.getItem("token"),
        teamId: JSON.parse(sessionStorage.getItem("defaultTeam")).teamId,
        isTotal: true,
        // this.project.teamProjectId
      };
      querySignalList(payload).then((res) => {
        if (res.status == 200) {
          console.log("信号列表", res.data.signalList);
          this.signalList = res.data.signalList;
          console.log(this.reserves);
        }
      });
    },

    // 信号选择
    signalChange(v, item) {
      console.log(v, item);
      item.signalName = v.signalName;
      item.signal = v;
      item.paramBody = JSON.parse(v.paramBody);
      item.paramBodyValue = item.paramBody[0].name;
      item.paramBodyLabel = item.paramBody[0].label;
      item.ops = "=";
      this.$forceUpdate();
    },

    // 信号参数选择
    paramBodyValue(v, item) {
      // item.paramBody = [v];
      item.paramBodyValue = v.name;
      item.paramBodyLabel = v.label;
      this.$forceUpdate();
      console.log(item);
    },

    // 符号选择
    opsChange(v, item) {
      item.ops = v;
      console.log(v, item);
      this.$forceUpdate();
    },
    // 信号阈值
    signalValueChange(v, item) {
      item.signalValue = v.trim();
      this.$forceUpdate();
      console.log(item);
    },

    //SMT合约
    replenishmentbutionSelect(v, item) {
      if (item == "一次性止盈" || item == "逐单止盈") {
        this.replenishmentbutionThree = !this.replenishmentbutionThree;
      }
      if (v) {
        for (let e of this.reserves) {
          if (item == "倍数补仓") {
            if (e.name == "补仓金额增量") {
              e.visiable = 0;
            }
            if (e.name == "补仓金额倍数" || e.name == "倍投起始单") {
              e.visiable = 1;
            }
            this.replenishmentbutionOne = true;
          } else if (item == "追踪补仓") {
            if (e.name == "补仓反弹比例") {
              e.visiable = 1;
            }
            this.replenishmentbutionTwo = true;
          } else if (item == "追踪止盈") {
            if (e.name == "止盈回撤比例") {
              e.visiable = 1;
            }
            this.replenishmentbutionFour = true;
          }
        }
      } else {
        for (let e of this.reserves) {
          if (item == "增量补仓") {
            if (e.name == "补仓金额增量") {
              e.visiable = 1;
            }
            if (e.name == "补仓金额倍数" || e.name == "倍投起始单") {
              e.visiable = 0;
            }

            this.replenishmentbutionOne = false;
          } else if (item == "预埋单补仓") {
            if (e.name == "补仓反弹比例") {
              e.visiable = 0;
            }
            this.replenishmentbutionTwo = false;
          } else if (item == "预埋单止盈") {
            if (e.name == "止盈回撤比例") {
              e.visiable = 0;
            }
            this.replenishmentbutionFour = false;
          }
        }
      }
    },
  },
  created() {
    let project = JSON.parse(sessionStorage.getItem("project"));
    this.project = project;
    let val = JSON.parse(this.$route.query.val);
    this.val = val;
    let nowData = JSON.parse(sessionStorage.getItem("Now"));
    this.nowData = nowData;
    let detailsOperationAuthority = sessionStorage.getItem(
      "detailsOperationAuthority"
    );
    this.detailsOperationAuthority = JSON.parse(detailsOperationAuthority);
    this.name = this.val.remarkCode ? this.val.remarkCode : this.val.publicCode;
    this.publicPeopleId = this.val.id;
    //获取左侧数据
    if (nowData) {
      let picData = {
        token: sessionStorage.getItem("token"),
        publicPeopleId: this.val.id,
      };
      this.queryAccountData(picData);
      this.searchData = this.searchDataList[0];
      this.orderData = this.orderDataList[0];
      let payloadLibrary = {
        token: sessionStorage.getItem("token"),
        id: this.val.id,
        pageSize: 10,
        pageNo: 1,
      };
      this.userManagementDetails(payloadLibrary);
    } else {
      let picData = {
        token: sessionStorage.getItem("token"),
        publicPeopleId: this.val.id,
      };
      this.queryHistoryAccountData(picData);
      this.TabTwo = ["历史策略"];
      this.orderData = [
        {
          title: "标签",
          nav: "templateName",
        },
        {
          title: "启动时间",
          nav: "createTime",
        },
        {
          title: "终止时间",
          nav: "terminationTime",
        },
        {
          title: "执行时长",
          nav: "nowTime",
        },
        {
          title: "实例编号",
          nav: "codeName",
        },
        {
          title: "总投入",
          nav: "investCoinnNum",
        },
        {
          title: "总收益",
          nav: "floatEarnings",
        },
        {
          title: "总年化",
          nav: "realYearIncome",
        },
      ];
      this.searchData = [{ name: "标签", type: "input" }];
      let payload = {
        token: sessionStorage.getItem("token"),
        id: this.val.id,
      };
      this.fundHistoryDetails(payload);

      this.topTab = ["策略交易", "智能订单"];
      this.searchDataList[1] = [
        {
          name: "标签",
          filterable: true,
          type: "input",
        },
      ];
      this.orderChainList[0] = [
        {
          title: "标签",
          nav: "label",
        },
        {
          title: "账户",
          nav: "publicPeopleName",
        },
        {
          title: "初始投入",
          nav: "investNum",
        },
        {
          title: "订单收益",
          nav: "profit",
        },
        {
          title: "执行时长",
          nav: "createTime",
        },
        {
          title: "订单链",
          nav: "order",
        },
        {
          title: "当前订单状态",
          nav: "states",
        },
      ];
    }
    this.getProject();
    this.rowStyleData = this.rowStyleList[0];
    this.modify = true;
    this.details = true;
    this.detailBtn = false;
  },
  mounted() {
    //获取交易所
    let payloadData = {
      token: sessionStorage.getItem("token"),
      teamProjectId: this.project.teamProjectId,
    };
    this.userManagementExchangelist(payloadData);
    this.querySignalList();
  },
  deactivated() {
    this.$destroy();
  },
};
</script>











<style scoped lang='scss'>
.navs {
  display: flex;
  flex-direction: column;
  background: #f7f7f7;
  width: 100%;
  height: 935px;
  position: absolute;
  .top {
    height: 70px;
    background: #32333d;
    color: #fff;
    align-items: center;
    display: flex;
    padding-left: 30px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 2;
  }
  .nav {
    margin-top: 70px;
    display: flex;
    width: 100%;
    height: 867px;
    .left {
      font-size: 14px;
      width: 260px;
      background-color: #282933;
      color: #e6e9ee;
      height: 100%;
      .title {
        font-size: 16px;
        padding: 20px;
        border-bottom: 2px solid #1c1d28;
      }
      .navItem {
        display: flex;
        flex-direction: column;
        div {
          display: flex;
          justify-content: space-between;
          padding: 10px 20px;
        }
      }
    }
    .box {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      // margin: 20px;
      // overflow-x: scroll;
      height: 840px;
      width: 1620px;
      margin: 20px;
      background: #fff;
      overflow-y: hidden;
      .right {
        padding: 20px;
        display: flex;
        flex-direction: column;
        width: 1580px;
        .topTab {
          display: flex;
          border-bottom: 1px solid #e6e9ee;
          margin-bottom: 10px;
          span {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 120px;
            height: 32px;
            border-radius: 3px 3px 0px 0px;
            border: 1px solid #e6e9ee;
            margin-right: 10px;
            cursor: pointer;
          }
        }
        .topData {
          display: flex;
          border-bottom: 1px solid #e6e9ee;
          height: 40px;
          font-size: 16px;
          align-items: center;
          margin-bottom: 10px;
          .topDataItem {
            height: 100%;
            line-height: 40px;
            padding: 0 20px;
            cursor: pointer;
            color: #55698a;
          }
          .brdStyle {
            border-bottom: 2px solid #2174ff;
            color: #2174ff;
          }
        }
        .bottomColumn {
          .topColumn {
            display: flex;
            height: 36px;
            font-size: 12px;
            align-items: center;
            justify-content: space-between;
            padding: 0 30px;
            background-color: #32333d;
            color: #ffffff;
            /deep/.el-input__inner {
              background-color: #1c1d28 !important;
              border: 1px solid #1c1d28 !important;
            }
            .one {
              display: flex;
              align-items: center;
              margin-right: 30px;
              span {
                white-space: nowrap;
              }
            }
            .btnData {
              display: flex;
              height: 100%;
              span {
                width: 60px;
                height: 20px;
                margin: 5px 0;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                border-radius: 2px;
              }
            }
            .stopBtn {
              width: 80px;
              display: flex;
              align-items: center;
              justify-content: center;
              margin-left: 20px;
              cursor: pointer;
              .headerIcon {
                margin: 1px 4px 0 0;
              }
            }
          }
        }
      }
      .contract {
        display: flex;
        flex-direction: column;
        background: #ffffff;
        .contractSelect {
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-bottom: 1px solid #e6e9ee;
          margin-bottom: 20px;
          .contractData {
            display: flex;
            height: 40px;
            font-size: 16px;
            align-items: center;
            color: #55698a;
            .contractDataItem {
              height: 100%;
              line-height: 40px;
              padding: 0 20px;
              cursor: pointer;
              color: #55698a;
              border: 1px solid #e6e9ee;
              border-bottom: 0;
              margin-right: 20px;
            }
            .bcgStyle {
              background-color: #2174ff;
              color: #fff;
              border-radius: 3px 3px 0px 0px;
            }
          }
          .contractType {
          }
        }
        .title {
          display: flex;
          color: #ffffff;
          justify-content: space-between;
          align-items: center;
          background-color: #32333d;
          font-size: 12px;
          padding: 0 30px;
          .topColumn {
            flex: 1;
            display: flex;
            height: 36px;
            align-items: center;
            justify-content: space-between;
            color: #ffffff;
            /deep/.el-input__inner {
              background-color: #1c1d28 !important;
              border: 1px solid #1c1d28 !important;
            }
            .one {
              display: flex;
              align-items: center;
              margin-right: 30px;
              span {
                white-space: nowrap;
              }
            }
            .btnData {
              display: flex;
              height: 100%;
              span {
                width: 60px;
                margin: 5px 0;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                border-radius: 2px;
              }
            }
          }
          .columnRight {
            display: flex;
            span {
              margin-left: 20px;
              display: flex;
              align-items: center;
              cursor: pointer;
              img {
                margin-right: 3px;
              }
            }
          }
        }
        .tableStyle {
          display: flex;
          flex-direction: column;
          border: 1px solid #e6e9ee;
          padding-bottom: 20px;
          .tableTitle {
            display: flex;
            padding: 5px 30px;
            background: #f6f9fd;
            color: #54698a;
            span {
              flex: 1;
            }
            div {
              flex: 1;
            }
          }
          .tableNav {
            display: flex;
            flex-direction: column;
            padding: 0 10px;
            .tableItem {
              display: flex;
              flex-direction: column;
              .tableFirst {
                display: flex;
                padding: 20px;
                color: #102859;
                font-size: 14px;
                div {
                  flex: 1;
                }
                .contractBtn {
                  display: flex;
                  span {
                    cursor: pointer;

                    margin-left: 10px;
                    color: #2174ff;
                    border: 1px solid rgba(33, 116, 255, 0.3);
                    padding: 0 5px;
                  }
                }
              }
              .tableTwo {
                display: flex;
                flex-direction: column;
                background: rgba(230, 233, 238, 0.1);
                border-radius: 8px;
                border: 1px solid #e6e9ee;
                padding: 20px;
                font-size: 14px;
                .tableTwoTitle {
                  display: flex;
                  color: #54698a;
                  span {
                    flex: 1;
                  }
                }
                .tableTwoNav {
                  display: flex;
                  margin-top: 20px;
                  color: #102859;
                  span {
                    flex: 1;
                  }
                }
              }
            }
          }
        }
        .noTableStyle {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 50px;
          color: #909399;
          border: 1px solid #e6e9ee;
          border-top: 0;
        }
      }
      .paginStyle {
        display: flex;
        color: #55698a;
        justify-content: space-between;
        align-items: center;
        padding: 20px 100px;
        width: 1400px;
        .paginCenter {
          display: flex;
          align-items: center;
        }
      }
    }
  }
  // 修改参数
  .modifyParameters {
    height: 500px;
    overflow: scroll;
    padding-right: 20px;
    padding-bottom: 20px;
    // .box {
    //   margin-bottom: 10px;
    //   .box-title {
    //     display: inline-block;
    //     font-size: 14px;
    //     color: #333;
    //     margin-bottom: 4px;
    //     :last-child {
    //       font-size: 12px;
    //       color: #666;
    //       margin-left: 4px;
    //     }
    //   }
    //   ::v-deep.el-cascader {
    //     width: 100%;
    //   }
    // }
    .parameter-box {
      margin-bottom: 10px;
      .left {
        margin-bottom: 10px;
        .jieshi-tips {
          width: 12px;
          height: 12px;
          cursor: pointer;
          margin: 0 6px;
        }
      }
      .right {
        .el-input--suffix {
          line-height: 40px;
        }
        .el-select {
          width: 100%;
        }
        .signal-params {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 20px;

          .one {
            width: 35%;
          }
          .two {
            width: 30%;
          }
          .three {
            width: 30%;
          }
        }
      }
    }
    //SMT
    .parameter-box-smt {
      display: flex;
      // align-items: center;
      padding: 0 0 20px 0;
      .left {
        display: flex;
        flex-direction: column;
        width: 120px;
        .reserves-name {
          display: flex;
          align-items: center;
          color: #102859 !important;
          font-size: 14px !important;
          margin-bottom: 4px;
          img {
            width: 12px;
            height: 12px;
            cursor: pointer;
            margin-left: 10px;
          }
        }
        :last-child {
          color: #55698a;
          font-size: 12px;
        }
      }
      .right {
        flex: 1;
        .el-input {
          height: 40px;
        }
        .el-cascader {
          width: 100%;
        }
        ::v-deep.el-input__inner {
          height: 40px;
          line-height: 40px;
        }
        ::v-deep.el-input__suffix {
          line-height: 40px;
        }
        .el-select {
          width: 100%;
        }
        .signal-params {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 20px;

          .one {
            width: 45%;
          }
          .two {
            width: 20%;
            /deep/.el-icon-arrow-up:before {
              content: "";
            }
            /deep/.el-input__inner {
              display: flex;
              align-items: center;
              padding-right: 10px;
            }
          }
          .three {
            width: 35%;
            /deep/.el-input__inner {
              padding-right: 10px;
            }
          }
        }
      }
      // 海风特殊参数样式
      .special-box {
        width: 100%;
        .special-box-title {
          color: #102859;
          font-size: 16px;
        }
        .special-box-cont {
          width: 100%;
          height: 40px;
          display: flex;
          justify-content: center;
          align-items: center;

          margin-top: 10px;
          span {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 50%;
            height: 40px;
            background: #f7f7f7;
            color: #102859;
            cursor: pointer;
            border-radius: 4px;
          }
          .on {
            background: #2174ff;
            color: #fff;
          }
        }
      }
      .special-box-cont {
        width: 100%;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;

        margin-top: 10px;
        span {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 50%;
          height: 40px;
          background: #f7f7f7;
          color: #102859;
          cursor: pointer;
          border-radius: 4px;
        }
        .on {
          background: #2174ff;
          color: #fff;
        }
      }
    }
    .otherSetting-title {
      color: #2174ff;
      margin-bottom: 10px;
    }
    // 海风特殊参数样式
    .special-box {
      width: 100%;
      .special-box-title {
        color: #102859;
      }
      .special-box-cont {
        width: 100%;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;

        margin-top: 10px;
        span {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 50%;
          height: 40px;
          background: #f7f7f7;
          color: #102859;
          cursor: pointer;
          border-radius: 4px;
        }
        .on {
          background: #2174ff;
          color: #fff;
        }
      }
    }
    .otherSetting {
      .parameter-box {
        .title {
          margin-bottom: 10px;
          .jieshi-tips {
            width: 12px;
            height: 12px;
            cursor: pointer;
            margin: 0 6px;
          }
        }
      }
    }
    .el-date-editor {
      width: 100%;
    }
  }
  // 修改参数确认
  .btn {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #007aff;
    color: #fff;
    font-size: 16px;
    border-radius: 4px;
    cursor: pointer;
  }
}
/deep/.el-dialog__body {
  padding: 0 30px;
  border-top: 1px solid #f0f0f0;
  border-bottom: 1px solid #f0f0f0;
  .dialog-nav {
    color: #55698a;
    padding: 20px 0 70px;
    font-size: 12px;
    .dialog-box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .dialog-img {
        width: 36px;
        height: 36px;
        cursor: pointer;
        margin: 0 20px 20px 0;
      }
      .dialog-top {
        font-size: 16px;
        margin-bottom: 20px;
        color: #102859;
      }
    }
    .dialog-currency {
      display: flex;
      flex-direction: column;
      font-size: 16px;
      span {
        margin-bottom: 10px;
      }
    }
  }
}
.dialog-footer {
  display: flex;
  justify-content: flex-end;
  div {
    width: 72px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    cursor: pointer;
  }
  .footerBack {
    border: 1px solid #c2d9ff;
    color: #2174ff;
  }
  .footerBtn {
    color: #ffffff;
    background: #2174ff;
    border: 1px solid #c2d9ff;
    margin-left: 12px;
  }
}
.brdTopStyle {
  background-color: #2174ff;
  color: #ffffff;
}
.chartData {
  .chartData-title {
    font-size: 16px;
    padding: 20px;
    border-bottom: 2px solid #1c1d28;
  }
}
.chartImg {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
}
</style>