<template>
  <div class="box">
    <div class="navItem">
      <div class="nav">
        <div class="topData">
          <template v-for="(item, index) in Tab">
            <div
              :class="index == tableActive ? 'brdStyle' : ''"
              class="topDataItem"
              @click="tableDataBtn(index)"
              :key="index">
              {{ $t('m.'+item) }}
            </div>
          </template>
        </div>
        <div class="tableStyle">
          <div class="topColumn">
            <SearchBox
              class="searchStyle"
              @btnSubmit="btnSubmit"
              @btnBack="btnBack"
              :searchData="searchData"
              ref="childSearch"
            />
          </div>
          <div class="centerColumn" v-if="tableActive == 0">
            <span @click="confromBtn">
              {{$t('m.迁移用户')}}</span>
            <span style="color: #e12525" @click="activeBtn">
              {{$t('m.强制结算')}}</span>
          </div>
          <DetailsData
            @selectRow="selectRow"
            :details="details"
            :data="tableData"
            news="false"
            :rowStyleData="rowStyleData"
            :label="orderData"
            @modifyBtn="modifyBtn"
            class="detailsData"
          />
        </div>
      </div>
      <div class="paginStyle">
        <div></div>
        <div v-if="listNumber >= 10" class="paginCenter">
          <span>{{$t('m.共计订单&(1)条').replace('&(1)', (listNumber ? listNumber : 0))}}</span>
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="currentPage"
            :page-size="100"
            background
            layout="prev, pager, next, jumper"
            :total="tableData ? tableData.length : 0">
          </el-pagination>
        </div>
        <span>{{$t('m.已选择&(1)条').replace('&(1)', (selectRowList ? selectRowList.length : 0))}}</span>
      </div>
    </div>
    <el-dialog
      :title="$t('m.强制结算')"
      :visible.sync="activeShow"
      width="400px"
      center
      :before-close="handleClose"
      :close-on-click-modal="false">
      <div class="dialog-nav">
        <div class="dialog-top">
          {{$t('m.确认强制结算后账户将被服务器接管，所有持仓自动兑换成托管币种后等待下一结算期结算。')}}
        </div>
        <div style="color: #e12525; margin-top: 10px">
          {{$t('m.请耐心等待，结算完成后您可在历史账户中查看。')}}
        </div>
        <div style="color: #e12525; margin-top: 10px">
          {{$t('m.强制结算会被记录在项目及团队评分系统中，非必要请勿进行此项操作。')}}
        </div>
      </div>
      <div slot="footer">
        <div class="dialog-footer">
          <div class="footerBack" @click="activeShow = false">
            {{$t('m.取 消')}}</div>
          <div class="footerBtn" @click="batchForceSettlement">
            {{$t('m.确 定')}}</div>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      :title="$t('m.迁移用户')"
      :visible.sync="confromVisible"
      width="30%"
      :before-close="handleClose">
      <el-select
        style="padding: 30px 0"
        v-model="operatorLater"
      :placeholder="$t('m.请选择操作员')">
        <el-option
          v-for="(item, idx) in operatorList"
          :key="idx"
          :label="item.nickName"
          :value="item.userId">
        </el-option>
      </el-select>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancel">
          {{$t('m.取 消')}}</el-button>
        <el-button type="primary" @click="migrationUser">
          {{$t('m.确 定')}}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import DetailsData from "../../components/detailsData.vue";
import SearchBox from "../../components/searchBox.vue";
import OrderChain from "../../components/orderChain.vue";
import {
  findByIdOrderChain,
  userManagement,
  fundHistoryList,
  userManagementExchangelist,
  operatorList,
  migrationUser,
  batchForceSettlement,
  batchSynchAssetApi,
  getAssetApiList,
  batchSynchContractApi,
  getContractApiList,
} from "../../request/api";

export default {
  components: { DetailsData, OrderChain, SearchBox },
  data() {
    return {
      dialogVisible: false,
      Tab: ["当前账户", "历史账户"],
      details: true, //详情按钮
      // 表格数据
      tableData: [],
      // 搜索数据
      searchTable: [],
      //搜索框数据
      searchData: [
        {
          name: "交易所",
          data: [this.$t('m.全部')],
          filterable: true,
        },
        {
          name: "托管时间",
          type: "picker",
        },
        {
          name: "总投入",
          type: "inputTwo",
        },
        {
          name: "操作员",
          data: [this.$t('m.全部')],
          filterable: true,
        },
        {
          name: "剩余可用",
          type: "inputTwo",
        },
      ],
      //表格样式
      rowStyleData: [],
      rowStyleDataList: [
        [
          {
            columnIndex: 6,
            style: [
              {
                state: "true6",
                style: "color:#E12525",
              },
              {
                state: "zero6",
                style: "color:#102859",
              },
              {
                state: "false6",
                style: "color:#26B34B",
              },
            ],
          },
          {
            columnIndex: 7,
            style: [
              {
                state: "true7",
                style: "color:#E12525",
              },
              {
                state: "zero7",
                style: "color:#102859",
              },
              {
                state: "false7",
                style: "color:#26B34B",
              },
            ],
          },
          {
            columnIndex: 8,
            style: [
              {
                state: "正常",
                style: "color:#2174FF",
              },
              {
                state: "结算中",
                style: "color:#FF9600",
              },
            ],
          },
        ],
        [
          {
            columnIndex: 5,
            style: [
              {
                state: "true5",
                style: "color:#E12525",
              },
              {
                state: "zero5",
                style: "color:#102859",
              },
              {
                state: "false5",
                style: "color:#26B34B",
              },
            ],
          },
          {
            columnIndex: 6,
            style: [
              {
                state: "true6",
                style: "color:#E12525",
              },
              {
                state: "zero6",
                style: "color:#102859",
              },
              {
                state: "false6",
                style: "color:#26B34B",
              },
            ],
          },
        ],
      ],
      // 表头数据
      orderData: [],
      orderDataList: [
        [
          {
            title: "托管时间",
            nav: "createTime",
          },
          {
            title: "执行时长",
            nav: "newTime",
          },
          {
            title: "账户",
            nav: "publicCode",
          },
          {
            title: "总投入",
            nav: "investCoinNum",
          },
          {
            title: "仓位",
            nav: "alreadyDevoted",
            width: 100,
          },
          {
            title: "剩余可用",
            nav: "availableNum",
          },
          {
            title: "总收益",
            nav: "sumFloatEarnings",
          },
          {
            title: "总年化",
            nav: "sumYearIncome",
            width: 100,
          },
          {
            title: "API状态",
            nav: "isExpire",
          },
          {
            title: "操作员",
            nav: "nickName",
            width: 100,
          },
        ],
        [
          {
            title: "托管时间",
            nav: "createTime",
          },
          {
            title: "执行时长",
            nav: "newTime",
          },
          {
            title: "退出时间",
            nav: "endTime",
          },
          {
            title: "账户",
            nav: "publicCode",
          },
          {
            title: "总投入",
            nav: "investCoinNum",
          },
          {
            title: "总收益",
            nav: "sumFloatEarnings",
          },
          {
            title: "总年化",
            nav: "sumYearIncome",
          },
          {
            title: "操作员",
            nav: "nickName",
          },
        ],
      ],
      project: null,
      tableActive: 0, //table切换
      currentPage: 1,
      showData: [1, 2, 3],
      modify: true, //修改按钮
      strategyType: 1,

      orderList: null, //订单链详情
      orderChainId: null, //当前订单Id
      selectRowList: [], //选中数据
      batchModify: false, //批量修改
      exchangeLimit: null, //交易所列表
      operatorList: null, //操作员列表
      activeShow: false, //二次确认弹框
      confromVisible: false,
      operatorLater: null,
      multipleSelection: [],
      listNumber: 1, //总数据
      detailsOperationAuthority: null,
    };
  },
  methods: {
    tableDataBtn(e) {
      this.tableActive = e;
      this.orderData = this.orderDataList[e];
      this.rowStyleData = this.rowStyleDataList[e];
      this.searchTable = [];
      this.$refs.childSearch.btnBack();
      if (e == 0) {
        sessionStorage.setItem("Now", "true");

        this.batchSynchAssetApi();
        this.getAssetApiList();
        this.batchSynchContractApi();
        this.getContractApiList();
      } else {
        sessionStorage.setItem("Now", "false");
      }
    },
    handleSizeChange() {
      console.log(2);
    },
    handleCurrentChange(e) {
      console.log("点击分页111111111");
      let payload = {
        token: sessionStorage.getItem("token"),
        teamProjectId: this.project.teamProjectId,
        roleUserId: this.roleUserId,
        pageNo: e,
        pageSize: 10,
        ...this.searchTable,
      };
      if (this.tableActive == 0) {
        this.userManagement(payload);
      } else {
        this.fundHistoryList(payload);
      }
    },
    typeSelest() {
      console.log(1);
    },
    //查询按钮
    btnSubmit(data) {
      
      if (
        data[0] !== this.$t('m.全部') ||
        data[1] ||
        data[2][0] !== "" ||
        data[2][1] !== "" ||
        data[3] !== this.$t('m.全部') ||
        data[4][0] !== "" ||
        data[4][1] !== ""
      ) {
        this.searchTable = {
          availableNumEnd: data[4][1] == "" ? 0 : Number(data[4][1]),
          availableNumStrat: data[4][0] == "" ? 0 : Number(data[4][0]),
          exchangeName: data[0] == this.$t('m.全部') ? null : data[0],
          investCoinNumEnd: data[2][1] == "" ? 0 : Number(data[2][1]),
          investCoinNumStart: data[2][0] == "" ? 0 : Number(data[2][0]),
        };

        if (data[1][0] !== "") {
          this.searchTable.timeStart = new Date(data[1][0]).getTime();
          this.searchTable.timeEnd = new Date(data[1][1]).getTime();
        }
        if (data[3] !== this.$t('m.全部')) {
          this.operatorList.forEach((e) => {
            if (e.nickName == data[3]) {
              this.searchTable.operator = e.userId;
            }
          });
        }
      } else {
        this.searchTable = [];
      }
      let payload = {
        token: sessionStorage.getItem("token"),
        teamProjectId: this.project.teamProjectId,
        pageNo: 1,
        pageSize: 10,
        ...this.searchTable,
      };
      sessionStorage.setItem("searchData", JSON.stringify(data));
      if (this.tableActive == 0) {
        this.userManagement(payload);
      } else {
        this.fundHistoryList(payload);
      }
    },
    //重置按钮
    btnBack() {
      
      sessionStorage.removeItem("searchData");
      let payload = {
        token: sessionStorage.getItem("token"),
        teamProjectId: this.project.teamProjectId,
      };
      this.searchTable = [];
      if (this.tableActive == 0) {
        this.userManagement(payload);
      } else {
        this.fundHistoryList(payload);
      }
    },

    handleClose(done) {
      this.operatorLater = null;
      done();
    },
    //获取选中数据
    selectRow(e) {
      this.selectRowList = e;
    },
    //迁移按钮
    confromBtn() {
      if (!this.detailsOperationAuthority) {
        // this.$message.error("不是模板创建人，无权操作");
        this.$alert("您没有权限使用该功能，敬请谅解！", "额，好像没有权限", {
          confirmButtonText: "知道啦",
        });
        return;
      }
      if (this.selectRowList.length == 0) {
        return this.$message.warning("请选择实例");
      }
      this.confromVisible = true;
    },
    //迁移确认按钮
    migrationUser() {
      if (!this.operatorLater) {
        this.$message.error("请选择要迁移的操作员");
        return;
      }
      let idStr = [];
      for (let i = 0; i < this.selectRowList.length; i++) {
        idStr.push(this.selectRowList[i].id);
      }
      let payload = {
        token: sessionStorage.getItem("token"),
        teamProjectId: this.project.teamProjectId,
        idStr: idStr.join(","),
        operatorFront: "",
        operatorLater: this.operatorLater,
      };
      migrationUser(payload).then((res) => {
        if (res.status == 200) {
          this.$message.success("迁移成功");
          this.confromVisible = false;
          this.operatorLater = null;
          let payload = {
            token: sessionStorage.getItem("token"),
            teamProjectId: this.project.teamProjectId,
            ...this.searchTable,
          };
          this.userManagement(payload);
          console.log(res.data);
        } else {
          this.$message.error(res.msg);
        }
      });
      console.log(payload);
    },
    //强制结算
    activeBtn() {
      if (!this.detailsOperationAuthority) {
        // this.$message.error("不是模板创建人，无权操作");
        this.$alert("您没有权限使用该功能，敬请谅解！", "额，好像没有权限", {
          confirmButtonText: "知道啦",
        });
        return;
      }
      if (this.selectRowList.length !== 0) {
        this.activeShow = true;
      } else {
        return this.$message.warning("请选择实例");
      }
    },
    //强制结算
    batchForceSettlement() {
      let peopleIds;
      this.selectRowList.forEach((e, index) => {
        if (index == 0) {
          peopleIds = e.id.toString();
        } else {
          peopleIds += "," + e.id.toString();
        }
      });
      let payload = {
        token: sessionStorage.getItem("token"),
        peopleIds: peopleIds,
      };
      batchForceSettlement(payload).then((res) => {
        console.log(res);
        if (res.status == 200) {
          this.activeShow = false;
          let payload = {
            token: sessionStorage.getItem("token"),
            teamProjectId: this.project.teamProjectId,
            roleUserId: this.roleUserId,
          };
          this.userManagement(payload);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    //修改按钮
    modifyBtn(e) {
      this.orderChainId = e.orderChainId;
      this.dialogVisible = true;
      let payload = {
        token: sessionStorage.getItem("token"),
        orderChainId: e.orderChainId,
      };
      console.log(this.$refs.child);
      if (this.$refs.child) {
        this.$refs.child.orderIndex = 0;
      }
      this.findByIdOrderChain(payload);
    },

    //同步资产
    batchSynchSpotApi() {
      let payload = {
        token: sessionStorage.getItem("token"),
      };
      batchSynchSpotApi(payload).then((res) => {
        console.log(res);
        let payload = {
          token: sessionStorage.getItem("token"),
          teamProjectId: this.project.teamProjectId,
        };
        this.userManagement(payload);
      });
    },
    // 取消迁移
    cancel() {
      this.confromVisible = false;
      this.operatorLater = null;
    },
    // 查询订单链详情
    findByIdOrderChain(payload) {
      findByIdOrderChain(payload).then((res) => {
        if (res.status == 200) {
          console.log("``````````11111``````````", res.data);
          this.orderList = JSON.parse(
            JSON.stringify(res.data.intelligencOrderChain)
          );
          let list = res.data.intelligencOrderChain.orderList;

          let newList = [];
          console.log(list);
          for (let key of list) {
            let item = JSON.parse(key.displayConfig);
            console.log(key.executeStates, item);
            item.executeStates = key.executeStates;
            newList.push(item);
          }
          newList.reverse();
          this.$refs.child.orderList = newList;
          this.$refs.child.showPair =
            newList[0].pair.counterCoin + "/" + newList[0].pair.baseCoin;
          this.$refs.child.investment = newList[0].investment;
          this.$refs.child.createdActionList = newList[0].actionList;
          this.$refs.child.actionDetailParams = JSON.parse(
            newList[0].actionList[0].params
          ).controls;
          if (this.batchModify) {
            this.$refs.child.executeStates = true;
          } else {
            this.$refs.child.executeStates = false;
          }
          setTimeout(() => {
            this.$refs.child.$refs.child.percentage =
              newList[0].actionList[0].percentage;
            // this.$refs.child.$refs.child.executeStates=list[0].executeStates
          }, 300);
          this.$refs.child.isUpdate = true;
          console.log(newList);
          console.log(1, this.$refs.child.orderList);
        }
      });
    },
    // 获取当前用户列表
    userManagement(payload) {
      userManagement(payload).then((res) => {
        if (res.status == 200) {
          if (res.data.userList) {
            let userList = res.data.userList;
            this.listNumber = res.data.total;
            userList.forEach((e) => {
              e.newTime = this.date_format(
                Date.parse(new Date()) - e.createTime
              );
              e.createTime = this.timestampToTime(e.createTime);
              e.isExpire = e.isExpire == false ? "正常" : "过期";
              e.isExpire = e.states == "STOPING" ? "结算中" : e.isExpire;
              e.state = [];
              e.state.push(
                e.sumFloatEarnings > 0
                  ? "true6"
                  : e.sumFloatEarnings < 0
                  ? "false6"
                  : "zero6",
                e.sumYearIncome > 0
                  ? "true7"
                  : e.sumYearIncome < 0
                  ? "false7"
                  : "zero7",
                e.isExpire
              );
              let alreadyDevoted;
              console.log(this.contractTable);
              console.log(this.contractAssetTable);
              console.log(e.exchangeName);
              if (e.exchangeName == "OkexV5") {
                if (
                  this.contractAssetTable &&
                  this.contractAssetTable.length !== 0
                ) {
                  this.contractAssetTable.forEach((item) => {
                    if (e.apiId == item.apiId && item.coinType == "USDT") {
                      let investCoinNum =
                        Number(e.investCoinNum) + Number(e.sumFloatEarnings);
                      console.log(e);
                      alreadyDevoted = (
                        (Number(e.lock) / investCoinNum) *
                        100
                      ).toString();
                    }
                  });
                  console.log(alreadyDevoted);
                } else {
                  let investCoinNum =
                    Number(e.investCoinNum) + Number(e.sumFloatEarnings);
                  alreadyDevoted = (
                    (Number(e.lock) / investCoinNum) *
                    100
                  ).toString();
                }
              } else if (
                e.exchangeName !== "OkexV5" &&
                this.contractTable.length !== 0
              ) {
                let investCoinNum =
                  Number(e.investCoinNum) + Number(e.sumFloatEarnings);
                alreadyDevoted = (
                  ((investCoinNum - Number(e.availableNum) - Number(e.lock)) /
                    investCoinNum) *
                  100
                ).toString();

                if (Number(e.investCoinNum) + Number(e.sumFloatEarnings) == 0) {
                  alreadyDevoted = "0";
                }

                console.log(alreadyDevoted);
              }
              console.log(alreadyDevoted);
              if (alreadyDevoted && alreadyDevoted.indexOf(".") > 0) {
                e.alreadyDevoted = alreadyDevoted.slice(
                  0,
                  alreadyDevoted.indexOf(".") + 3
                );
              } else {
                e.alreadyDevoted = alreadyDevoted;
              }
              if (Number(e.alreadyDevoted) <= 0) {
                e.alreadyDevoted = 0;
              } else {
                e.alreadyDevoted += "%";
              }

              e.sumYearIncome =
                e.sumYearIncome
                  .toString()
                  .slice(0, e.sumYearIncome.indexOf(".") + 3) + "%";
              let sumFloatEarnings = (
                (Number(e.sumFloatEarnings) / Number(e.investCoinNum)) *
                100
              ).toString();
              console.log(sumFloatEarnings);
              if (Number(e.sumFloatEarnings) !== 0) {
                if (sumFloatEarnings.indexOf(".") > 0) {
                  e.sumFloatEarnings =
                    Number(e.sumFloatEarnings) +
                    "(" +
                    sumFloatEarnings.slice(
                      0,
                      sumFloatEarnings.indexOf(".") + 3
                    ) +
                    "%" +
                    ")";
                } else {
                  e.sumFloatEarnings =
                    Number(e.sumFloatEarnings) +
                    "(" +
                    sumFloatEarnings +
                    "%" +
                    ")";
                }
              }
              if (e.availableNum !== null) {
                if (e.investCoin !== "BTC") {
                  e.availableNum = Number(e.availableNum)
                    .toFixed(18)
                    .replace(/\.0+$/, "")
                    .replace(/(\.\d+[1-9])0+$/, "$1");
                  e.availableNum = Number(
                    e.availableNum
                      .toString()
                      .slice(0, e.availableNum.toString().indexOf(".") + 5)
                  );
                } else {
                  e.availableNum = Number(e.availableNum)
                    .toFixed(18)
                    .replace(/\.0+$/, "")
                    .replace(/(\.\d+[1-9])0+$/, "$1");
                  e.availableNum = Number(
                    e.availableNum
                      .toString()
                      .slice(0, e.availableNum.toString().indexOf(".") + 7)
                  );
                }
              } else {
                e.availableNum = 0;
              }

              this.orderData.forEach((item) => {
                if (
                  item.title == "总投入" ||
                  item.title == "总收益" ||
                  item.title == "剩余可用"
                ) {
                  item.title += "(" + e.investCoin + ")";
                }
              });

              if (e.isExpire == "结算中") {
                e.alreadyDevoted = "--";
                e.availableNum = "--";
              }
            });
            console.log(userList);

            this.tableData = userList;
          } else {
            this.tableData = null;
          }
        }
      });
    },
    // v5资金账户
    getAssetApiList() {
      let payload = {
        token: sessionStorage.getItem("token"),
        coinType: "USDT",
      };
      getAssetApiList(payload).then((res) => {
        if (res.status == 200) {
          console.log(res);
          let assetApiList = res.data.assetApiList;
          this.contractAssetTable = assetApiList;
        } else {
          this.contractAssetTable = [];
        }
      });
    },
    //同步v5资金账户
    batchSynchAssetApi() {
      let payload = {
        token: sessionStorage.getItem("token"),
      };
      batchSynchAssetApi(payload).then((res) => {});
    },
    //获取合约账户
    getContractApiList() {
      let payload = {
        token: sessionStorage.getItem("token"),
        teamProjectId: this.project.teamProjectId,
        accountMode: "cross",
        exchangeName: "Binance",
        userId: this.userId,
      };
      getContractApiList(payload).then((res) => {
        if (res.status == 200) {
          console.log(res);
          let contractList = res.data.contractList;
          this.contractTable = contractList;
        } else {
          this.contractTable = [];
        }
      });
    },
    //同步合约账户
    batchSynchContractApi() {
      let payload = {
        token: sessionStorage.getItem("token"),
      };
      batchSynchContractApi(payload).then((res) => {});
    },
    //获取页面信息
    queryOrderChainList(payload) {
      queryOrderChainList(payload).then((res) => {
        if (res.status == 200) {
          let orderChainList = res.data.orderChainList;
          this.listNumber = res.data.total;
          console.log(res.data);
          orderChainList.forEach((e) => {
            let orderList = "";

            //判断订单链的长度
            if (e.states == "COMPLETED") {
              if (e.orderList.length > 3) {
                orderList =
                  e.orderList[e.orderList.length - 1].investUnit +
                  "-" +
                  e.orderList[e.orderList.length - 2].investUnit +
                  "-" +
                  e.orderList[e.orderList.length - 3].investUnit +
                  "...";
              } else {
                if (e.orderList.length == 3) {
                  orderList =
                    e.orderList[2].investUnit +
                    "-" +
                    e.orderList[1].investUnit +
                    "-" +
                    e.orderList[0].investUnit;
                } else if (e.orderList.length == 2) {
                  let transactionPair = e.orderList[0].transactionPair.replace(
                    "/",
                    "-"
                  );
                  orderList = e.orderList[1].investUnit + "-" + transactionPair;
                } else if (e.orderList.length == 1) {
                  let transactionPair = e.orderList[0].transactionPair.replace(
                    "/",
                    "-"
                  );
                  orderList = transactionPair;
                }
              }
            } else {
              if (e.orderList.length > 3) {
                for (let i = e.orderList.length - 1; i > 0; i--) {
                  if (e.orderList[i].executeStates == "EXECUTE") {
                    //判断省略号的方向
                    if (i == 0) {
                      orderList =
                        "...<span style='color:red'>" +
                        e.orderList[i].investUnit +
                        "</span>-" +
                        e.orderList[i + 1].investUnit +
                        "-" +
                        e.orderList[i + 2].investUnit;
                    } else if (i == 1) {
                      orderList =
                        "..." +
                        e.orderList[i - 1].investUnit +
                        "-<span style='color:red'>" +
                        e.orderList[i].investUnit +
                        "</span>-" +
                        e.orderList[i + 1].investUnit;
                    } else if (i == e.orderList.length - 2) {
                      orderList =
                        e.orderList[i + 1].investUnit +
                        "-<span style='color:red'>" +
                        e.orderList[i].investUnit +
                        "</span>-" +
                        e.orderList[i - 1].investUnit +
                        "...";
                    } else if (i == e.orderList.length - 1) {
                      orderList =
                        "<span style='color:red'>" +
                        e.orderList[i].investUnit +
                        "</span>-" +
                        e.orderList[i - 1].investUnit +
                        "-" +
                        e.orderList[i - 2].investUnit +
                        "...";
                    } else {
                      "..." +
                        e.orderList[i + 1].investUnit +
                        "-<span style='color:red'>" +
                        e.orderList[i].investUnit +
                        "</span>-" +
                        e.orderList[i - 1].investUnit +
                        "...";
                    }
                  }
                }
              } else {
                for (let i = 0; i < e.orderList.length; i++) {
                  //在执行中的链上加样式
                  if (e.orderList[i].executeStates == "EXECUTE") {
                    orderList =
                      "<span style='color:red'>" +
                      e.orderList[i].investUnit +
                      "</span>" +
                      orderList;
                    1;
                  } else {
                    if (orderList.charAt(orderList.length - 1) == "-") {
                      orderList = orderList.substring(0, orderList.length);
                    }
                    orderList =
                      "-" + e.orderList[i].investUnit + "-" + orderList;
                  }
                }
              }
            }

            //去除首尾的 '-'
            orderList = orderList.replace(/^(\s|-)+|(\s|-)+$/g, "");
            //在末尾加上订单的数量
            e.order = orderList + " (" + e.orderList.length + "单)";
            //给的 style 样式写在修改的数据前面
            e.state = [];
            e.state.push(
              e.states == "EXECUTE"
                ? "执行中"
                : e.states == "COMPLETED"
                ? "已完成"
                : "",
              e.totalIncomeNum - e.orderList[e.orderList.length - 1].investNum >
                0
                ? "true3"
                : e.totalIncomeNum -
                    e.orderList[e.orderList.length - 1].investNum <
                  0
                ? "false3"
                : "zero3"
            );
            //渲染到页面
            if (e.states == "COMPLETED") {
              e.createTime = this.date_format(e.endTime - e.createTime);
            } else {
              e.createTime = this.date_format(
                Date.parse(new Date()) - e.createTime
              );
            }

            if (Number(e.totalIncomeNum) == 0) {
              e.profit = 0;
            } else {
              // investUnit 是初始投入币种
              let profit =
                e.totalIncomeNum -
                e.orderList[e.orderList.length - 1].investNum;
              if (profit == 0) {
                e.profit =
                  0 + " " + e.orderList[e.orderList.length - 1].investUnit;
              } else {
                if (e.orderList[e.orderList.length - 1].investUnit !== "BTC") {
                  //获取收益百分比
                  let percentNum = (
                    (profit /
                      Number(e.orderList[e.orderList.length - 1].investNum)) *
                    100
                  ).toString();
                  e.profit =
                    profit
                      .toString()
                      .slice(0, profit.toString().indexOf(".") + 5) +
                    " " +
                    e.orderList[e.orderList.length - 1].investUnit +
                    " (" +
                    percentNum.slice(0, percentNum.indexOf(".") + 3) +
                    "%)";
                } else {
                  let percentNum = (
                    (profit /
                      Number(e.orderList[e.orderList.length - 1].investNum)) *
                    100
                  ).toString();
                  e.profit =
                    profit
                      .toString()
                      .slice(0, profit.toString().indexOf(".") + 7) +
                    " " +
                    e.orderList[e.orderList.length - 1].investUnit +
                    " (" +
                    percentNum.slice(0, percentNum.indexOf(".") + 3) +
                    "%)";
                }
              }
            }
            // let totalIncomeNum = e.totalIncomeNum;
            // e.totalIncomeNum =
            //   e.orderList[e.orderList.length - 1].investNum - totalIncomeNum;
            // console.log(e.totalIncomeNum);
            e.states =
              e.states == "EXECUTE"
                ? "执行中"
                : e.states == "COMPLETED"
                ? "已完成"
                : "";
            e.orderList.forEach((item) => {
              if (item.orderSort == 1) {
                e.investNum = item.investNum + " " + item.investUnit;
              }
              if (item.executeStates == "EXECUTE") {
                e.orderDirection =
                  item.orderDirection == "buy"
                    ? "买入"
                    : item.orderDirection == "sell"
                    ? "卖出"
                    : "";
              }
            });
            e.state.push(e.orderDirection);
          });
          this.tableData = orderChainList;
          console.log(this.tableData);
        }
      });
    },
    // 查询历史入金用户列表
    fundHistoryList(payload) {
      fundHistoryList(payload).then((res) => {
        if (res.status == 200) {
          this.listNumber = res.data.total;
          if (res.data.historyUserList) {
            let historyUserList = res.data.historyUserList;
            historyUserList.forEach((e) => {
              e.newTime = this.date_format(e.endTime - e.createTime);
              e.createTime = this.timestampToTime(e.createTime);
              e.endTime = this.timestampToTime(e.endTime);
              e.state = [];
              e.state.push(
                e.sumFloatEarnings > 0
                  ? "true5"
                  : e.sumFloatEarnings < 0
                  ? "false5"
                  : "zero5",
                e.sumYearIncome > 0
                  ? "true6"
                  : e.sumYearIncome < 0
                  ? "false6"
                  : "zero6"
              );
              e.sumYearIncome =
                e.sumYearIncome
                  .toString()
                  .slice(0, e.sumYearIncome.indexOf(".") + 3) + "%";
              let sumFloatEarnings = (
                (Number(e.sumFloatEarnings) / Number(e.investCoinNum)) *
                100
              ).toString();
              if (Number(e.sumFloatEarnings) !== 0) {
                e.sumFloatEarnings =
                  Number(e.sumFloatEarnings) +
                  "(" +
                  sumFloatEarnings.slice(0, sumFloatEarnings.indexOf(".") + 3) +
                  "%" +
                  ")";
              }
              this.orderData.forEach((item) => {
                if (item.title == "总投入" || item.title == "总收益") {
                  item.title += "(" + e.investCoin + ")";
                }
              });
            });
            this.tableData = historyUserList;
            console.log("历史用户列表", res.data.historyUserList);
          } else {
            this.tableData = [];
          }
        }
      });
    },
    // 查询交易所列表
    getExchangeList() {
      let payload = {
        token: sessionStorage.getItem("token"),
        teamProjectId: this.project.teamProjectId,
      };
      userManagementExchangelist(payload).then((res) => {
        if (res.status == 200) {
          this.exchangeLimit = res.data.exchangeLimit;
          this.searchData.forEach((e) => {
            if (e.name == "交易所") {
              let exchangeLimit = res.data.exchangeLimit;
              exchangeLimit.forEach((item) => {
                e.data.push(item.exchangeName);
              });
            }
          });
        } else {
          this.$message.error(res.msg);
        }
      });
    },

    // 查询操作员列表
    getOperatorList() {
      let payload = {
        token: sessionStorage.getItem("token"),
        teamProjectId: this.project.teamProjectId,
      };
      operatorList(payload).then((res) => {
        if (res.status == 200) {
          this.operatorList = res.data.operatorList;
          this.searchData.forEach((e) => {
            if (e.name == "操作员") {
              console.log(e);
              let operatorList = res.data.operatorList;
              operatorList.forEach((item) => {
                e.data.push(item.nickName);
              });
            }
          });
          console.log("操作员列表", res.data);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    //日期换算
    timestampToTime(timestamp) {
      var date = new Date(timestamp); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
      var Y = date.getFullYear() + "-";
      var M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      var D =
        date.getDate() < 10
          ? "0" + date.getDate() + " "
          : +date.getDate() + " ";
      var h =
        date.getHours() < 10
          ? "0" + date.getHours() + ":"
          : date.getHours() + ":";
      var m =
        date.getMinutes() < 10
          ? "0" + date.getMinutes() + ":"
          : date.getMinutes() + ":";
      var s =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      return Y + M + D + h + m + s;
    },
    //时分秒换算
    date_format(micro_second) {
      var second = Math.floor(micro_second / 1000);
      var day = Math.floor(second / 3600 / 24);
      var hr = Math.floor((second / 3600) % 24);
      var min = Math.floor((second / 60) % 60);
      return day + "d " + hr + "h " + min + "min";
    },
  },
  mounted() {},
  created() {
    this.orderData = this.orderDataList[0];
    this.rowStyleData = this.rowStyleDataList[0];
    let account = sessionStorage.getItem("account");
    this.account = JSON.parse(account);
    let project = sessionStorage.getItem("project");
    this.project = JSON.parse(project);
    let payload = {
      token: sessionStorage.getItem("token"),
      teamProjectId: this.project.teamProjectId,
    };
    this.batchSynchAssetApi();
    this.getAssetApiList();
    this.batchSynchContractApi();
    this.getContractApiList();
    if (sessionStorage.getItem("searchData")) {
      let searchData = JSON.parse(sessionStorage.getItem("searchData"));
      payload.availableNumEnd =
        searchData[3][1] == "" ? 0 : Number(searchData[4][1]);
      payload.availableNumStrat =
        searchData[3][0] == "" ? 0 : Number(searchData[4][0]);
      payload.exchangeName = searchData[0] == this.$t('m.全部') ? null : searchData[0];
      payload.investCoinNumEnd =
        searchData[2][1] == "" ? 0 : Number(searchData[2][1]);
      payload.investCoinNumStart =
        searchData[2][0] == "" ? 0 : Number(searchData[2][0]);
      payload.timeStart = new Date(searchData[1][0]).getTime();
      payload.timeEnd = new Date(searchData[1][1]).getTime();
    }
    setTimeout(() => {
      if (sessionStorage.getItem("Now")) {
        let now = sessionStorage.getItem("Now");
        console.log(now == true);
        if (now == "true") {
          this.tableActive = 0;
          this.userManagement(payload);
        } else {
          this.tableActive = 1;
          this.fundHistoryList(payload);
        }
        this.orderData = this.orderDataList[this.tableActive];
        this.rowStyleData = this.rowStyleDataList[this.tableActive];
      } else {
        sessionStorage.setItem("Now", "true");
        this.tableActive = 0;
        this.userManagement(payload);
      }
    }, 100);
    this.getExchangeList();
    this.getOperatorList();
    let detailsOperationAuthority = sessionStorage.getItem(
      "detailsOperationAuthority"
    );
    this.detailsOperationAuthority = JSON.parse(detailsOperationAuthority);
  },
};
</script>

<style lang='scss' scoped>
.box {
  width: 100%;
  height: 100%;
  // height: 825px;
}
.navItem {
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #ffffff;
  width: 1680px;
  min-height: 600px;
  .nav {
    border-radius: 8px;
    padding: 10px 20px;
    .topData {
      display: flex;
      border-bottom: 1px solid #e6e9ee;
      height: 40px;
      font-size: 16px;
      align-items: center;
      margin-bottom: 20px;
      .topDataItem {
        height: 100%;
        line-height: 40px;
        padding: 0 20px;
        cursor: pointer;
        color: #55698a;
      }
      .brdStyle {
        border-bottom: 2px solid #2174ff;
        color: #2174ff;
      }
    }

    .tableStyle {
      border: 1px solid #e6e9ee;
      padding-bottom: 20px;
      .topColumn {
        display: flex;
        font-size: 12px;
        align-items: flex-start;
        justify-content: space-between;
        padding: 0 30px;
        background-color: #32333d;
        color: #ffffff;
        /deep/.el-input__inner {
          background-color: #1c1d28 !important;
          border: 1px solid #1c1d28 !important;
        }
        .one {
          display: flex;
          align-items: center;
          margin-right: 30px;
          span {
            white-space: nowrap;
          }
        }
        .searchStyle {
          background-color: #32333d;
          color: #ffffff;
        }
        .btnData {
          display: flex;
          height: 100%;
          .btn-submit {
            margin-right: 10px;
            background-color: #2174ff;
          }
          span {
            width: 60px;
            margin: 5px 0;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            border-radius: 2px;
          }
        }
      }
      .centerColumn {
        display: flex;
        justify-content: flex-end;
        padding: 10px 40px 10px 0;
        color: #2174ff;
        font-size: 12px;
        span {
          margin-left: 20px;
          cursor: pointer;
        }
        .centerColumnItem {
          display: flex;
          align-items: center;
          height: 16px;
          .headerIcon {
            margin: 1px 3px 0 0;
          }
        }
      }
    }
  }
  .paginStyle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #55698a;
    padding: 20px 100px 40px;
    .paginCenter {
      display: flex;
      align-items: center;
    }
  }
}
/deep/.el-dialog__body {
  padding: 0 30px;
  border-top: 1px solid #f0f0f0;
  border-bottom: 1px solid #f0f0f0;
  .dialog-nav {
    color: #55698a;
    padding: 20px 0 30px;
    font-size: 12px;
    .dialog-top {
      font-size: 16px;
      color: #102859;
    }
  }
}
.dialog-footer {
  display: flex;
  justify-content: flex-end;
  div {
    width: 72px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    cursor: pointer;
  }
  .footerBack {
    border: 1px solid #c2d9ff;
    color: #2174ff;
  }
  .footerBtn {
    color: #ffffff;
    background: #2174ff;
    border: 1px solid #c2d9ff;
    margin-left: 12px;
  }
}
table th,
table td {
  border-bottom: none !important;
}
.el-input--mini .el-input__inner {
  height: 22px !important;
  line-height: 22px !important;
}
.el-input--mini .el-input__icon {
  line-height: 22px !important;
}
</style>