<template>
  <div class="details">
    <el-container>

      <el-header>
        <div class="header-warp">
          <i class="el-icon-arrow-left" @click="goBack"></i>
          <span>{{$t('m.详情')}}</span>
          <span></span>
        </div>
      </el-header>

      <el-container>
        <!-- main -->
        <el-main>
          <div class="content">
            <!--  -->
            <div class="strategyDetails">
              <el-table
                ref="multipleTable"
                :data="userData"
                tooltip-effect="dark"
                style="width: 100%"
                border>

                <el-table-column prop="createTime" :label="$t('m.入金时间')" width="200">
                  <template slot-scope="scope">{{
                    moment(scope.row.createTime).format("YYYY-MM-DD HH:mm:ss")
                  }}</template>
                </el-table-column>

                <el-table-column
                  :label="$t('m.总资金(USDT)')"
                  prop="investCoinNum"
                  width="150"
                  sortable>
                </el-table-column>

                <el-table-column
                  prop="alreadyDevoted"
                  :label="$t('m.已投入策略(USDT)')"
                  width="150">
                  <template slot-scope="scope">{{
                    Math.floor(Number(scope.row.alreadyDevoted) * 10000) / 10000
                  }}</template>
                </el-table-column>

                <el-table-column
                  prop="availableNum"
                  :label="$t('m.剩余可用(USDT)')"
                  width="150">
                  <template slot-scope="scope">{{
                    Math.floor(Number(scope.row.availableNum) * 10000) / 10000
                  }}</template>
                </el-table-column>

                <el-table-column
                  prop="executionNumber"
                  :label="$t('m.当前策略数')"
                  width="120">
                </el-table-column>

                <el-table-column
                  prop="realEarnings"
                  :label="$t('m.总收益(USDT)')"
                  width="120">
                  <template slot-scope="scope">{{
                    scope.row.realEarnings
                      ? Math.floor(Number(scope.row.realEarnings) * 10000) /
                        10000
                      : "--"
                  }}</template>
                </el-table-column>

                <el-table-column
                  prop="realYearIncome"
                  :label="$t('m.总年化')"
                  width="120">
                </el-table-column>

                <el-table-column :label="$t('m.操作')">
                  <template slot-scope="scope">
                    <div class="deposit-operation">
                      <span
                        @click="
                          startUp(
                            scope.row.availableNum,
                            scope.row.exchangeName,
                            scope.row.exchangeId
                          )
                        ">
                        {{$t('m.启动策略')}}</span>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </div>

            <!--  -->
            <el-tabs v-model="activeName" @tab-click="handleClick">
              <el-tab-pane :label="$t('m.当前策略')" name="0">
                <!-- 当前策略 -->
                <div class="current">
                  <div class="current-title">{{$t('m.当前策略')}}</div>
                  <el-table
                    ref="multipleTable"
                    :data="nowadayExample"
                    tooltip-effect="dark"
                    style="width: 100%"
                    border>
                    <el-table-column
                      prop="createTime"
                      :label="$t('m.时间')"
                      width="180"
                      sortable>
                      <template slot-scope="scope">{{
                        moment(scope.row.createTime).format("YYYY-MM-DD HH:mm:ss")
                      }}</template>
                    </el-table-column>
                    <el-table-column
                      prop="strategyName"
                      :label="$t('m.实例')"
                      width="170">
                      <template slot-scope="scope">{{
                        $t('m.'+scope.row.strategyName) +
                        "( " +
                        scope.row.oneId +
                        "-" +
                        scope.row.threeId +
                        " )"
                      }}</template>
                    </el-table-column>

                    <el-table-column
                      prop="strategyName"
                      :label="$t('m.策略名称')"
                      width="120">
                    </el-table-column>
                    <el-table-column prop="oneId" :label="$t('m.策略模式')" width="100">
                      <template slot-scope="scope">{{
                        scope.row.templatePattern == 0
                          ? $t('m.自定义模式')
                          : $t('m.多币对模式')
                      }}</template>
                    </el-table-column>

                    <el-table-column
                      prop="investCoinnNum"
                      :label="$t('m.总投入')"
                      width="100"
                      sortable>
                      <template slot-scope="scope">{{
                        Math.floor(Number(scope.row.investCoinnNum) * 10000) /
                        10000
                      }}</template>
                    </el-table-column>

                    <el-table-column
                      prop="realEarnings"
                      :label="$t('m.总收益')"
                      width="100">
                      <template slot-scope="scope">{{
                        Math.floor(Number(scope.row.realEarnings) * 10000) /
                        10000
                      }}</template>
                    </el-table-column>

                    <el-table-column
                      prop="realEarningsRate"
                      :label="$t('m.总收益率')"
                      width="100">
                    </el-table-column>

                    <el-table-column
                      prop="earnings"
                      :label="$t('m.实现收益')"
                      width="100">
                      <template slot-scope="scope">{{
                        Math.floor(Number(scope.row.earnings) * 10000) / 10000
                      }}</template>
                    </el-table-column>

                    <el-table-column
                      prop="floatEarnings"
                      :label="$t('m.浮动收益')"
                      width="100">
                      <template slot-scope="scope">{{
                        Math.floor(Number(scope.row.floatEarnings) * 10000) /
                        10000
                      }}</template>
                    </el-table-column>

                    <el-table-column
                      prop="yearIncome"
                      :label="$t('m.总年化/实现年化')"
                      width="140">
                      <template slot-scope="scope">{{
                        Math.floor(Number(scope.row.yearIncome) * 10000) /
                          10000 +
                        " / " +
                        Math.floor(Number(scope.row.realYearIncome) * 10000) /
                          10000
                      }}</template>
                    </el-table-column>

                    <el-table-column
                      prop="finishBuyCount"
                      :label="$t('m.交易数（买入）（卖出）')"
                      width="180">
                      <template slot-scope="scope">{{
                        (scope.row.finishBuyCount
                          ? scope.row.finishBuyCount
                          : "0") +
                        " / " +
                        (scope.row.finishSellCount
                          ? scope.row.finishSellCount
                          : "0")
                      }}</template>
                    </el-table-column>

                    <el-table-column prop="states" :label="$t('m.状态')" width="100">
                      <template slot-scope="scope">
                        <span v-if="scope.row.states == 'EXECUTE'">
                          {{$t('m.执行中')}}</span>
                        <span v-if="scope.row.states == 'SUSPEND'">
                          {{$t('m.暂停中')}}</span>
                        <span v-if="scope.row.states == 'STARTING'">
                          {{$t('m.启动中')}}</span>
                        <span v-if="scope.row.states == 'RESTARTING'">
                          {{$t('m.重启中')}}</span>
                        <span v-if="scope.row.states == 'STOPING'">
                          {{$t('m.终止中')}}</span>
                        <span v-if="scope.row.states == 'STARTERROR'">
                          {{$t('m.启动错误')}}</span>
                      </template>
                    </el-table-column>

                    <el-table-column :label="$t('m.操作')" min-width="200">
                      <template slot-scope="scope">
                        <div class="deposit-operation">
                          <span @click="confirmTermination(scope.row.executeId)">
                            {{$t('m.终止')}}</span>
                          <span
                            v-if="scope.row.states == 'EXECUTE'"
                            @click="suspend(scope.row.executeId)">
                            {{$t('m.暂停')}}</span>

                          <span
                            v-if="scope.row.states == 'SUSPEND'"
                            @click="restart(scope.row.executeId)">
                            {{$t('m.重启')}}</span>

                          <span @click="modify(scope.row)">
                            {{$t('m.参数')}}</span>
                          <span @click="toMessage(scope.row.executeId)">
                            {{$t('m.消息')}}</span>

                          <span 
                          v-if="scope.row.templatePattern == 1 && scope.row.states != 'STOPING'"
                            @click="getPairPage(scope.row)">
                            {{$t('m.增加子币对')}}</span>

                          <span
                            v-if="scope.row.templatePattern == 1"
                            @click="subCoinPair(scope.row.executeId)">
                            {{$t('m.子币对操作')}}</span>
                        </div>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
              </el-tab-pane>

              <el-tab-pane :label="$t('m.历史策略')" name="1">
                <!--历史策略  -->
                <div class="current">
                  <div class="current-title">
                    {{$t('m.历史策略')}}</div>
                  <el-table
                    ref="multipleTable"
                    :data="historyExample"
                    tooltip-effect="dark"
                    style="width: 100%"
                    border>

                    <el-table-column
                      prop="createTime"
                      :label="$t('m.启动时间')"
                      width="200"
                      sortable>
                      <template slot-scope="scope">{{
                        moment(scope.row.createTime).format("YYYY-MM-DD HH:mm:ss")
                      }}</template>
                    </el-table-column>

                    <el-table-column
                      prop="endTime"
                      :label="$t('m.终止时间')"
                      width="200"
                      sortable>
                      <template slot-scope="scope">{{
                        moment(scope.row.endTime).format("YYYY-MM-DD HH:mm:ss")
                      }}</template>
                    </el-table-column>

                    <el-table-column prop="oneId" :label="$t('m.实例')" width="170">
                      <template slot-scope="scope">{{
                        $t('m.'+scope.row.strategyName) +
                        "( " +
                        scope.row.oneId +
                        "-" +
                        scope.row.threeId +
                        " )"
                      }}</template>
                    </el-table-column>

                    <el-table-column
                      prop="investCoinnNum"
                      :label="$t('m.总投入')"
                      width="100"
                      sortable>
                      <template slot-scope="scope">{{
                        Math.floor(Number(scope.row.investCoinnNum) * 10000) /
                        10000
                      }}</template>
                    </el-table-column>

                    <el-table-column
                      prop="realEarnings"
                      :label="$t('m.总收益')"
                      width="100">
                      <template slot-scope="scope">{{
                        Math.floor(Number(scope.row.realEarnings) * 10000) /
                        10000
                      }}</template>
                    </el-table-column>

                    <el-table-column
                      prop="realEarningsRate"
                      :label="$t('m.总收益率')"
                      width="100">
                    </el-table-column>

                    <el-table-column
                      prop="yearIncome"
                      :label="$t('m.总年化/实现年化')"
                      width="200">
                      <template slot-scope="scope">{{
                        scope.row.yearIncome + "/" + scope.row.realYearIncome
                      }}</template>
                    </el-table-column>

                    <el-table-column
                      prop="name"
                      :label="$t('m.交易数（买入）（卖出）')"
                      width="200">
                      <template slot-scope="scope">{{
                        scope.row.finishBuyCount +
                        "/" +
                        scope.row.finishSellCount
                      }}</template>
                    </el-table-column>

                    <el-table-column :label="$t('m.操作')" min-width="200">
                      <template slot-scope="scope">
                        <div class="deposit-operation">
                          <span @click="modify(scope.row, 'history')">
                            {{$t('m.参数')}}</span>
                          <span @click="toMessage(scope.row.executeId)">
                            {{$t('m.消息')}}</span>
                        </div>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
              </el-tab-pane>
            </el-tabs>
          </div>
        </el-main>
      </el-container>
    </el-container>

    <!-- 修改参数 -->
    <template v-if="modifyParametersShow">
      <el-dialog
        :title="$t('m.参数')"
        :visible.sync="modifyParametersShow"
        width="20%"
        :close-on-click-modal="false">
        <div class="modifyParameters">
          <template v-for="(item, idx) in reserves">
            <div
              class="box"
              :key="idx"
              v-if="
                item.visiable != '0' &&
                item.name != '定投类型' &&
                item.name != '定投周期'
              ">
              <span class="box-title">
                <span>{{ item.name }}</span>
                <span v-if="item.valueMin || item.start">{{
                  $t('m.范围：') +
                  (item.valueMin && item.valueMin != "0"
                    ? item.valueMin
                    : item.start) +
                  "~" +
                  (item.valueMax && item.valueMax != "0"
                    ? item.valueMax
                    : item.end)
                }}</span>
              </span>
              <el-input
                v-model="item.value"
                :placeholder="$t('m.请输入内容')"
                @blur="inpCheck($event, item, idx)"
                :readonly="isHistory"
                type="number">
              </el-input>
            </div>

            <div class="box" v-if="item.name == '定投周期'" :key="'dtlx'+ idx">
              <span class="box-title">
                <span>{{ item.name }}</span>
                <span v-if="item.valueMin || item.start">{{
                  $t('m.范围：') +
                  (item.valueMin != "" && item.valueMin
                    ? item.valueMin
                    : item.start) +
                  "~" +
                  (item.valueMax != "" && item.valueMax
                    ? item.valueMax
                    : item.end)
                }}</span>
              </span>
              <div class="parameter-box-text">
                <div class="block">
                  <el-cascader
                    v-model="fixedTime"
                    :options="times"
                    @change="handleChange"
                    :disabled="activeName==1?true:false"
                  ></el-cascader>
                </div>
              </div>
            </div>
          </template>
        </div>
        <span slot="footer" class="dialog-footer" v-if="!isHistory">
          <div class="btn" @click="modifyParam">
            {{$t('m.修改参数')}}</div>
        </span>
      </el-dialog>
    </template>

    <!-- 增加币对 -->
    <template v-if="addCurrencyPairShow">
      <el-dialog
        :title="$t('m.增加子币对')"
        :visible.sync="addCurrencyPairShow"
        width="20%"
        :close-on-click-modal="false">
        <div class="addCurrencyPair">
          <div class="title">
            {{$t('m.请谨慎增加币对，避免操作失败致使实例，被大量移至未分组')}}
          </div>
          <div class="box" v-for="(item, idx) in pairList" :key="'pairList'+idx">
            <div class="checkbox-warp">
              <el-checkbox
                :checked="item.type"
                :key="idx"
                @change="pairChange(item)">
                <div class="box-text">
                  <span>{{ item.counterCoin }}</span>
                  <span>{{ "/" + item.baseCoin }}</span>
                </div>
              </el-checkbox>
            </div>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="addCurrencyPairShow = false">
            {{$t('m.取 消')}}</el-button>
          <el-button type="primary" @click="addPair()">
            {{$t('m.确 定')}}</el-button>
        </span>
      </el-dialog>
    </template>
  </div>
</template>

<script>
import {
  userManagementDetails,
  strategyEnd,
  suspend,
  executeDetail,
  manyAddSymbol,
  updateParam,
  restart,
  getPairPage,
  getPairPageById,
} from "../../request/api";
import moment from "moment";
import { times } from "../../assets/js/time";
export default {
  components: {},
  data() {
    return {
      moment,
      id: null,
      userData: [], //用户信息
      historyExample: [], //历史列表
      nowadayExample: [], //当前策略
      activeName: "0",
      pageSize: 10,
      pageNo: 1,
      total: 0,
      modifyParametersShow: false, //修改参数
      paramsType: true, //
      reserves: null,
      reservesCopy: null,
      addCurrencyPairShow: false, //增加子币对
      pairList: [],
      isHistory: false,
      project: null,
      type: null, //操作权限
      times: times,
      fixedTime: [1, 1],
      copyFixedTime: [1, 1],
    };
  },
  computed: {},
  watch: {},
  methods: {
    // 返回上一页
    goBack() {
      this.$router.back(-1);
    },
    handleClick(tab, event) {
      if (this.activeName == "0") {
        this.total = this.nowadayExample.length;
      } else {
        this.total = this.historyExample.length;
      }
    },
    handleSizeChange(val) {
      this.pageSize = val;
    },
    handleCurrentChange(val) {
      this.pageNo = val;
    },
    // 获取详情数据
    userManagementDetails() {
      let payload = {
        token: sessionStorage.getItem("token"),
        id: this.id,
      };
      userManagementDetails(payload).then((res) => {
        if (res.status == 200) {
          this.userData = [];
          this.userData.push(res.data.userData);
          this.historyExample = res.data.historyExample;
          this.nowadayExample = res.data.nowadayExample;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 跳启动策略
    startUp(availableNum, exchangeName, exchangeId) {
      if (!this.type) {
        // this.$message.error("该用户不是名下用户，没有操作权限");
        this.$alert(
          this.$t('m.您没有权限使用该功能，敬请谅解！'), 
          this.$t('m.额，好像没有权限'), {
          confirmButtonText: this.$t('m.知道啦'),
        });
        return;
      }
      let id = this.id;
      console.log(id);
      this.$router.push({
        path: "startUp",
        query: { id, availableNum, exchangeName, exchangeId },
      });
    },
    // 消息
    toMessage(id) {
      this.$router.push({ path: "strategyMessage", query: { executeId: id } });
    },
    // 终止
    confirmTermination(id) {
      if (!this.type) {
        // this.$message.error("该用户不是名下用户，没有操作权限");
        this.$alert(
          this.$t('m.您没有权限使用该功能，敬请谅解！'), 
          this.$t('m.额，好像没有权限'), {
          confirmButtonText: this.$t('m.知道啦'),
        });
        return;
      }
      this.$confirm(this.$t('m.确认终止策略？'))
        .then((_) => {
          let payload = {
            executeId: id,
            token: sessionStorage.getItem("token"),
          };
          console.log(payload);
          strategyEnd(payload).then((res) => {
            if (res.status == 200) {
              console.log(res.data);
              this.$message.success(this.$t('m.终止成功'));
              this.userManagementDetails();
            } else {
              this.$message.error(res.msg);
            }
          });
        })
        .catch((_) => {});
    },
    // 暂停
    suspend(id) {
      if (!this.type) {
        // this.$message.error("该用户不是名下用户，没有操作权限");
        this.$alert(
          this.$t('m.您没有权限使用该功能，敬请谅解！'), 
          this.$t('m.额，好像没有权限'), {
          confirmButtonText: this.$t('m.知道啦'),
        });
        return;
      }
      this.$confirm(this.$t('m.确认暂停该策略？'))
        .then((_) => {
          let payload = {
            executeId: id,
            token: sessionStorage.getItem("token"),
          };
          suspend(payload).then((res) => {
            if (res.status == 200) {
              this.$message.success(this.$t('m.暂停成功'));
              this.userManagementDetails();
            } else {
              this.$message.error(res.msg);
            }
          });
        })
        .catch((_) => {});
    },
    // 重启
    restart(id) {
      if (!this.type) {
        // this.$message.error("该用户不是名下用户，没有操作权限");
        this.$alert(
          this.$t('m.您没有权限使用该功能，敬请谅解！'), 
          this.$t('m.额，好像没有权限'), {
          confirmButtonText: this.$t('m.知道啦'),
        });
        return;
      }
      this.$confirm(this.$t('m.确认重启该策略？'))
        .then((_) => {
          let payload = {
            executeId: id,
            token: sessionStorage.getItem("token"),
          };
          restart(payload).then((res) => {
            if (res.status == 200) {
              this.$message.success(this.$t('m.重启成功'));
              this.userManagementDetails();
            } else {
              this.$message.error(res.msg);
            }
          });
        })
        .catch((_) => {});
    },
    // 修改参数弹窗
    modify(row, val) {
      console.log(row);
      console.log(val);
      if (!this.type) {
        // this.$message.error("该用户不是名下用户，没有操作权限");
        this.$alert(
          this.$t('m.您没有权限使用该功能，敬请谅解！'), 
          this.$t('m.额，好像没有权限'), {
          confirmButtonText: this.$t('m.知道啦'),
        });
        return;
      }
      if (val) {
        this.isHistory = true;
        this.reserves = row.reserves;
        this.modifyParametersShow = true;
      } else {
        this.isHistory = false;
        this.executeId = row.executeId;
        let payload = {
          executeId: this.executeId,
          token: sessionStorage.getItem("token"),
        };
        executeDetail(payload).then((res) => {
          if (res.status == 200) {
            console.log(res.data);
            let reserves = res.data.executeDetail.config.reserves;
            this.strategyName=res.data.executeDetail.strategyName
            this.reserves = JSON.parse(JSON.stringify(reserves));
            this.reservesCopy = JSON.parse(JSON.stringify(reserves));
            if (res.data.executeDetail.strategyName == "恒量定投") {
              for (let key of this.reserves) {
                if (key.name == "定投类型") {
                  this.fixedTime[0] = key.value;
                  this.copyFixedTime[0] = key.value;
                } else if (key.name == "定投周期") {
                  this.fixedTime[1] = key.value;
                  this.copyFixedTime[1] = key.value;
                }
              }
            }
            this.modifyParametersShow = true;
          } else {
            this.$message.error(res.msg);
          }
        });
      }
    },
    // 参数校验
    inpCheck(e, item, index) {
      console.log(item, index);
      let value = item.value;
      let min =
        item.valueMin && (item.valueMin != 0 || item.valueMax != 0)
          ? item.valueMin
          : item.start;
      let max =
        item.valueMax && (item.valueMax != 0 || item.valueMin != 0)
          ? item.valueMax
          : item.end;
      console.log(item, item.value, max, min);
      console.log(value === "");
      if (value === "") {
        this.$message.error(this.$t('m.参数不能为空'));
        e.target.style.border = "1px solid #F23A4C";
        e.target.style.borderRadius = "4px";
        this.paramsType = false;
        return;
      } else if (min != "" && Number(value) < min) {
        this.$message.error(this.$t('m.参数不能小于最低范围'));
        e.target.style.border = "1px solid #F23A4C";
        e.target.style.borderRadius = "4px";
        console.log(4);
        this.paramsType = false;
        return;
      } else if (max != "" && Number(value) > max) {
        this.$message.error(this.$t('m.参数不能大于最大范围'));
        e.target.style.border = "1px solid #F23A4C";
        e.target.style.borderRadius = "4px";
        console.log(5);
        this.paramsType = false;
        return;
      } else if (
        (item.name == "价格上限" || item.name == "价格下限") &&
        Number(value) < 0
      ) {
        this.$message.error(this.$t('m.价格上限/价格下限不能小于0'));
        e.target.style.border = "1px solid #F23A4C";
        console.log(5);
        this.paramsType = false;
        return;
      } else {
        e.target.style.border = "1px solid #DCDFE6";
        console.log(6);
        this.paramsType = true;
      }
    },
    // 参数二次校验
    secondaryCheck(item) {
      for (let key in item) {
        let min =
          item[key].valueMin &&
          (item[key].valueMax != 0 || item[key].valueMin != 0)
            ? item[key].valueMin
            : item[key].start;
        let max =
          item[key].valueMax &&
          (item[key].valueMax != 0 || item[key].valueMin != 0)
            ? item[key].valueMax
            : item[key].end;
        let value = item[key].value;

        if (item[key].visiable == "1" && value === "") {
          console.log(item[key], value);
          this.paramsType = false;
          return;
        } else if (
          item[key].visiable == "1" &&
          min != "" &&
          Number(value) < min
        ) {
          console.log(item[key]);
          this.paramsType = false;
          return;
        } else if (
          item[key].visiable == "1" &&
          max != "" &&
          Number(value) > max
        ) {
          console.log(item[key]);
          this.paramsType = false;
          return;
        } else if (
          (item[key].name == "价格上限" || item[key].name == "价格下限") &&
          Number(value) < 0
        ) {
          console.log(item[key]);
          this.paramsType = false;
          return;
        } else {
          this.paramsType = true;
        }
      }
    },
    // 修改参数
    modifyParam() {
      this.secondaryCheck(this.reserves);
      if (!this.paramsType) {
        this.$message.error(this.$t('m.参数配置不正确，请重新修改'));
        return;
      }
      // else if (this.strategyId == "CL20181115155003478x") {
      let item = this.reserves;
      var min = null;
      var max = null;
      console.log(item);
      for (let key of item) {
        console.log(key);
        if (key.name == "价格上限") {
          max = key.value;
        } else if (key.name == "价格下限") {
          min = key.value;
        }
      }
      console.log(max, min);
      if (Number(max) < min) {
        this.$message.error(this.$t('m.价格上限不能低于价格下限'));
        // this.paramsType = false;
        return;
      }
      // }
      if (this.strategyName == "恒量定投") {
        for (let key of this.reserves) {
          if (key.name == "定投类型") {
            key.value = this.fixedTime[0];
          } else if (key.name == "定投周期") {
            key.value = this.fixedTime[1];
          }
        }
        console.log(this.reserves);
      }
      
      let payload = {
        token: sessionStorage.getItem("token"),
        executeId: this.executeId,
        reserves: JSON.stringify(this.reserves),
      };
      console.log(JSON.parse(payload.reserves))
      updateParam(payload).then((res) => {
        if (res.status == 200) {
          this.$message.success(this.$t('m.修改成功'));
          this.executeId = null;
          this.modifyParametersShow = false;
          this.userManagementDetails();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 查询交易对
    getPairPage(row) {
      if (!this.type) {
        // this.$message.error("该用户不是名下用户，没有操作权限");
        this.$alert(
          this.$t('m.您没有权限使用该功能，敬请谅解！'), 
          this.$t('m.额，好像没有权限'), {
          confirmButtonText: this.$t('m.知道啦'),
        });
        return;
      }
      console.log(row);
      this.executeId = row.executeId;
      let payload = {
        executeId: this.executeId,
        token: sessionStorage.getItem("token"),
      };
      executeDetail(payload).then((res) => {
        if (res.status == 200) {
          console.log(res.data);
          let pairs = [];
          let list = res.data.sonExecuteList;
          for (let i = 0; i < list.length; i++) {
            let val = {};
            val.counterCoin = list[i].counterCoin.toUpperCase();
            val.baseCoin = list[i].baseCoin.toUpperCase();
            pairs.push(val);
          }
          console.log(pairs);
          let exchangeId =
            res.data.executeDetail.config.tradePairs[0].exchangeId;
          let params = {
            token: sessionStorage.getItem("token"),
            teamProjectId: this.project.teamProjectId,
            exchangeId: exchangeId,
          };

          getPairPageById(params).then((res) => {
            if (res.status == 200) {
              console.log(res.data);
              this.addCurrencyPairShow = true;
              let list = res.data.pairList;
              console.log(pairs);
              if (pairs.length != 0) {
                for (let i = 0; i < list.length; i++) {
                  for (let j = 0; j < pairs.length; j++) {
                    if (
                      list[i].baseCoin == pairs[j].baseCoin &&
                      list[i].counterCoin == pairs[j].counterCoin
                    ) {
                      list[i].type = true;
                    }
                  }
                }
                let newList = list.filter(function (item) {
                  return item.type != true;
                });
                this.pairList = newList;
              } else {
                this.pairList = list;
              }
            } else {
              this.$message.error(res.msg);
            }
          });
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 币对选中
    pairChange(item) {
      if (!item.type) {
        item.type = true;
      } else {
        item.type = false;
      }
      console.log(item);
    },
    // 增加币对
    addPair() {
      if (!this.type) {
        // this.$message.error("该用户不是名下用户，没有操作权限");
        this.$alert(
          this.$t('m.您没有权限使用该功能，敬请谅解！'), 
          this.$t('m.额，好像没有权限'), {
          confirmButtonText: this.$t('m.知道啦'),
        });
        return;
      }
      let list = this.pairList;
      let pairs = [];
      for (let i = 0; i < list.length; i++) {
        if (list[i].type == true) {
          let pair = {};
          pair.baseCoin = list[i].baseCoin;
          pair.counterCoin = list[i].counterCoin;
          pairs.push(pair);
        }
      }
      if (pairs.length == 0) {
        this.$message.error(this.$t('m.没有选择币对'));
        return;
      }
      let payload = {
        token: sessionStorage.getItem("token"),
        executeId: this.executeId,
        pairs: JSON.stringify(pairs),
      };
      console.log(payload);
      manyAddSymbol(payload).then((res) => {
        if (res.status == 200) {
          console.log(res.data);
          this.$message.success(this.$t('m.增加币对成功'));
          this.userManagementDetails();
          this.addCurrencyPairShow = false;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 跳转子币对操作
    subCoinPair(executeId) {
      if (!this.type) {
        // this.$message.error("该用户不是名下用户，没有操作权限");
        this.$alert(
          this.$t('m.您没有权限使用该功能，敬请谅解！'), 
          this.$t('m.额，好像没有权限'), {
          confirmButtonText: this.$t('m.知道啦'),
        });
        return;
      }
      this.$router.push({
        path: "currencyPairOperation",
        query: { executeId, isDetails: true },
      });
    },
    // 定投参数
    handleChange(value) {
      this.fixedTime = value;
    },
  },
  created() {},
  mounted() {},
  beforeCreate() {},
  beforeMount() {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {},
  activated() {
    let project = JSON.parse(sessionStorage.getItem("project"));
    this.project = project;
    this.id = this.$route.query.id;
    this.type = JSON.parse(this.$route.query.type);
    console.log(this.type);
    this.userManagementDetails();
  },
};
</script>
<style lang='scss' scoped>
.details {
  width: 100%;
  height: 100%;
  .el-container {
    height: 100%;
    background: #eff2f5;
  }
  .el-header {
    background-color: #fff;
    color: #333;
    text-align: center;
    line-height: 60px;
    // margin-bottom: 20px;
    .header-warp {
      display: flex;
      justify-content: space-between;
      align-items: center;
      i {
        cursor: pointer;
      }
    }
  }

  .el-main {
    // background-color: #fff;
    color: #333;
    margin: 0px 20px 20px 20px;
    padding: 20px 10%;
    font-size: 14px;
    .content {
      padding: 10px 20px;
      background: #fff;

      .strategyDetails {
        margin: 20px 0;
      }

      //   当前策略
      .current {
        margin-top: 20px;
        background: #fff;
        padding-top: 20px;
        .current-title {
          color: #333;
          font-size: 14px;
          font-weight: 600;
          padding-left: 10px;
          margin-bottom: 10px;
        }
      }

      .deposit-operation {
        display: flex;
        // justify-content: space-between;
        flex-wrap: wrap;
        padding: 0 5px;
        span {
          font-size: 12px;
          color: #007aff;
          cursor: pointer;
          margin-right: 10px;
        }
      }
      // 分页
      .pagination-warp {
        display: flex;
        justify-content: flex-end;
        margin: 20px 0;
      }
    }
  }
  // 修改参数
  .modifyParameters {
    .box {
      margin-bottom: 10px;
      .box-title {
        display: inline-block;
        font-size: 14px;
        color: #333;
        margin-bottom: 4px;
        :last-child {
          font-size: 12px;
          color: #666;
          margin-left: 4px;
        }
      }
      ::v-deep.el-cascader {
        width: 100%;
      }
    }
  }
  // 修改参数确认
  .btn {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #007aff;
    color: #fff;
    font-size: 16px;
    border-radius: 4px;
    cursor: pointer;
  }
  // 增加币对
  .addCurrencyPair {
    height: 400px;
    overflow: scroll;
    .title {
      font-size: 12px;
      color: #999;
      margin-bottom: 10px;
    }
    .checkbox-warp {
      margin-bottom: 10px;
      padding: 5px;
      .box {
        .box-text {
          margin-left: 30px;
          font-size: 14px;
          color: #666;
          :last-child {
            font-size: 12px;
            color: #999;
          }
        }
      }
    }

    .checkbox-warp:hover {
      background: #d5d5d5;
      border-radius: 4px;
    }
  }
}
</style>