<template>
  <!-- 全部成员 -->
  <div class="allMembers">
    
    <div class="content">
      <div class="header">
        <div>
          <span>{{$t('m.成员列表')}}</span>
          <span class="headerVip">
            {{$t('m.成员权限')}}
            <span class="headerVipItem" @click="jurisdictionTips = true">?</span>
          </span>
        </div>
        <div
          v-if="active !== 1 && active !== 2"
          style="
            display: flex;
            align-items: center;
            background: #0079fd;
            color: #fff;
            border-radius: 8px;
            padding: 5px 16px;
            cursor: pointer;" 
            @click="getUnSelectTeamMember">+
          <span style="font-size: 12px; margin-left: 4px">
            {{$t('m.添加成员')}}</span>
        </div>
      </div>
      <div>
        <el-table
          ref="multipleTable"
          :data="teamMemberList"
          tooltip-effect="dark"
          style="width: 100%">
          <el-table-column prop="name" :label="$t('m.用户名')">
            <template slot-scope="scope">
              <div class="member-info">
                <span class="member-name">{{ scope.row.nickName }}</span>
                <span v-if="scope.row.userId === userId" class="me">{{$t('m.我')}}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="projectRole" :label="$t('m.账号')">
            <template slot-scope="scope">
              <div class="title-box">{{ scope.row.account }}</div>
            </template>
          </el-table-column>
          <el-table-column
            v-if="active !== 1 && active !== 2"
            prop="projectRole"
            :label="$t('m.权限')"
            width="150">
            <template slot-scope="scope">
              <div class="title-box">{{ scope.row.mark }}</div>
            </template>
          </el-table-column>
          <el-table-column prop="loginTime" :label="$t('m.最后操作')">
            <template slot-scope="scope">
              <span>{{
                scope.row.loginTime
                  ? moment(scope.row.loginTime).format("YYYY-MM-DD HH:mm:ss")
                  : $t('m.无')
              }}</span>
            </template>
          </el-table-column>

          <el-table-column :label="$t('m.操作')" v-if="isAll || projectMember">
            <template slot-scope="scope">
              <div style="display: flex; align-item: center;">

                <template>
                  <span
                    v-if="
                      (active == 1 || active == 2) &&
                      scope.row.userId == userId &&
                      userIdShow == 'false'"
                    @click="shiftOutTeam(scope.row)"
                    style="color: #f56c6c; cursor: pointer;line-height: 12px;">
                    {{$t('m.退出团队')}}</span>
                </template>

                <span
                  @click="shiftOutTeam(scope.row)"
                  style="color: #f56c6c; cursor: pointer;line-height: 12px;"
                  v-if="
                    (active == 1 || active == 2) &&
                    scope.row.userId !== userId &&
                    scope.row.userId !== defaultTeam.createUser">
                  {{$t('m.移出团队')}}</span>

                <span
                  @click="operation(scope.row)"
                  style="
                    line-height: 12px;
                    color: #007aff;
                    margin-right: 6px;
                    cursor: pointer;"
                  v-if="active !== 1 && active !== 2">
                  {{$t('m.权限')}}</span>
                  
                <el-dropdown-item
                  @click.native="deleteProjectMember(scope.row)"
                  style="
                    line-height: 12px;
                    color: #f56c6c;
                    cursor: pointer;
                    display: flex;
                    padding: 0;">
                  <span v-if="active !== 1 && active !== 2 && scope.row.userId === userId">
                    {{$t('m.退出项目')}}
                  </span>
                  <span v-if="active !== 1 && active !== 2 && scope.row.userId != userId">
                    {{$t('m.移出项目')}}
                  </span>
                </el-dropdown-item>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <!-- 成员设置 -->
    <el-dialog
      :title="$t('m.成员设置')"
      :visible.sync="dialogVisible"
      width="30%"
      center
      :close-on-click-modal="false">
      <div class="member-settings">
        <div class="member-info">
          <span>{{$t('m.成员')}}：</span>
          <span>{{ member.nickName }}</span>
        </div>
        <div class="member-info">
          <span>{{$t('m.账号')}}：</span>
          <span>{{ member.account }}</span>
        </div>
        <div class="jurisdiction-set">
          <div class="title">
            <span>{{$t('m.团队项目')}}</span>
            <span>{{$t('m.权限')}}</span>
          </div>

          <template v-if="isAll">
            <div class="box"
              v-for="(item, idx) in member.projectRole"
              :key="idx">
              <span>{{ item.projectName }}</span>
              <el-dropdown trigger="click" placement="bottom-start">
                <span class="el-dropdown-link" style="cursor: pointer">
                  <span>{{ item.mark }}</span>
                  <i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    @click.native="modifyPermissions(item, $t('m.管理员'))">
                    {{$t('m.管理员')}}</el-dropdown-item>
                  <el-dropdown-item
                    @click.native="modifyPermissions(item, $t('m.操作员'))">
                    {{$t('m.操作员')}}</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </template>
          <template v-if="projectMember">
            <div class="box">
              <span>{{ member.projectName }}</span>
              <el-dropdown trigger="click" placement="bottom-start">
                <span class="el-dropdown-link" style="cursor: pointer">
                  <span>{{ member.mark }}</span>
                  <i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    @click.native="modifyPermissions(member, $t('m.管理员'))">
                    {{$t('m.管理员')}}</el-dropdown-item>
                  <el-dropdown-item
                    @click.native="modifyPermissions(member, $t('m.操作员'))">
                    {{$t('m.操作员')}}</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </template>
        </div>
      </div>
    </el-dialog>

    <!-- 权限解释 -->
    <el-dialog
      :title="$t('m.成员权限')"
      center
      :visible.sync="jurisdictionTips"
      width="570px"
      top="260px">
      <div class="jurisdiction">
        <div>
          <span class="title">
            {{$t('m.超级管理员')}}</span>
          <span>({{$t('m.团队唯一')}})</span>
        </div>
        <div style="border-bottom: 1px solid #e6e7ed" class="box">
          <span>{{$t('m.创建/删除项目')}}</span>
          <span>{{$t('m.管理团队成员')}}</span>
          <span>{{$t('m.兼容管理员')}}</span>
        </div>
        <div>
          <span class="title">{{$t('m.管理员')}}</span>
          <span>({{$t('m.项目唯一')}})</span>
        </div>
        <div style="border-bottom: 1px solid #e6e7ed" class="box">
          <span>{{$t('m.管理项目')}}</span>
          <span>{{$t('m.分流用户')}}</span>
          <span>{{$t('m.兼容操作员')}}</span>
        </div>
        <div>
          <span class="title">{{$t('m.操作员')}}</span>
        </div>
        <div class="box" style="margin: 0">
          <span>{{$t('m.配置自动交易')}}</span>
          <span>{{$t('m.批量操作')}}</span>
        </div>
      </div>
      <div class="btn">
        <span @click="jurisdictionTips = false">
          {{$t('m.知道啦')}}</span>
      </div>
    </el-dialog>

    <!-- 添加成员 -->
    <el-dialog
      :title="$t('m.邀请成员')"
      :visible.sync="SelectTeamMember"
      width="30%"
      :before-close="handleClose">
      <div class="dialog-content">
        <div class="title">
          <span>{{$t('m.成员')}}</span>
          <span>{{$t('m.账号')}}</span>
          <span>{{$t('m.选择')}}</span>
        </div>
        <div class="content" v-for="(item, idx) in notMemberList" :key="idx">
          <div>
            <img :src="item.avatar" alt="" v-if="item.avatar" />
            <img src="../../assets/image/img.png" alt="" v-else />
            <span class="members-name">{{ item.nickName }}</span>
            <!-- <span>(超级管理员)</span> -->
          </div>
          <span class="members-phone">{{ item.phoneUnique }}</span>
          <el-checkbox
            @change="change(item)"
            :checked="item.type == true"
            class="members-check"
            :key="new Date().getTime()"
          ></el-checkbox>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="SelectTeamMember = false">
          {{$t('m.取 消')}}</el-button>
        <el-button type="primary" @click.native="addProjectMember">
          {{$t('m.保 存')}}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  findTeamMemberList,
  findNoProjectMember,
  findProjectMember,
  updateMemberRole,
  shiftOutTeam,
  deleteProjectMember,
  getUnSelectTeamMember,
  addProjectMember,
  getProjectMember,
  getProjectList,
} from "../../request/api";
import moment from "moment";
export default {
  components: {},
  props: ["active"],
  data() {
    return {
      moment,
      teamMemberList: [], //全部成员
      dialogVisible: false,
      member: {}, //操作成员对象
      isAll: null, //是否全部成员
      projectMember: false, //项目下成员
      mark: null, //弹窗显示的权限
      type: null, //记录进来参数
      defaultTeam: null,
      jurisdictionTips: false,
      dialogProject: false, //邀请成员
      userId: JSON.parse(sessionStorage.getItem("account")).userId, //个人Id
      notMemberList: null, //未加入成员
      SelectTeamMember: false, //添加成员
      projectInfo: null, //项目信息
      closeOnClick: false, //移出团队
      userIdShow: null,
    };
  },
  computed: {},
  watch: {
    active() {
      this.selsectList(this.active);
    },
  },
  methods: {
    selsectList(v) {
      this.type = v;
      if (v == 1) {
        this.isAll = true;
        this.projectMember = false;
        let payload = {
          token: sessionStorage.getItem("token"),
          teamId: this.defaultTeam.teamId,
        };
        this.findTeamMemberList(payload);
      } else if (v == 2) {
        this.isAll = false;
        this.projectMember = false;
        let payload = {
          token: sessionStorage.getItem("token"),
          teamId: this.defaultTeam.teamId,
        };
        this.findNoProjectMember(payload);
      } else {
        this.projectMember = true;
        this.isAll = false;
        let payload = {
          token: sessionStorage.getItem("token"),
          teamProjectId: v,
        };
        this.teamProjectId = v;
        this.findProjectMember(payload);
      }
    },
    // 查询全部成员
    findTeamMemberList(payload) {
      findTeamMemberList(payload).then((res) => {
        if (res.status == 200) {
          this.teamMemberList = res.data.teamMemberList;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 查询未加入成员
    findNoProjectMember(payload) {
      findNoProjectMember(payload).then((res) => {
        if (res.status == 200) {
          this.teamMemberList = res.data.noProjectMemberList;
        } else {
          this.$message.error(res.msg);
        }
      });
    },

    // 查询项目下成员
    findProjectMember(payload) {
      findProjectMember(payload).then((res) => {
        if (res.status == 200) {
          this.teamMemberList = res.data.projectMemberList;
          sessionStorage.setItem("member", res.data.projectMemberList.length);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    //
    modifyPermissions(item, val) {
      console.log(item, val);
      if (item.mark == this.$t('m.超级管理员')) {
        this.$message.error(this.$t('m.不能修改超级管理员角色'));
        return;
      }
      if (val == this.$t('m.管理员')) {
        item.mark = this.$t('m.管理员');
        item.roleName = "MANAGE";
      } else if (val == this.$t('m.操作员')) {
        item.mark = this.$t('m.操作员');
        item.roleName = "OPERATOR";
      }

      console.log(item);
      let payload = {
        token: sessionStorage.getItem("token"),
        teamProjectId: this.teamProjectId,
        roleName: item.roleName,
        byUserId: item.userId,
      };
      console.log(payload);
      updateMemberRole(payload).then((res) => {
        if (res.status == 200) {
          this.$message.success(this.$t('m.修改成功'));
          console.log(res.data);
          this.selsectList(this.type);
          this.dialogVisible = false;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 操作
    operation(item) {
      this.dialogVisible = true;
      this.member = item;
      console.log(this.member);
      // this.mark = this.member.mark;
    },

    // 修改成员权限
    // updateMemberRole() {
    //   console.log(this.member);
    //   let roleName = this.mark;
    //   switch (roleName) {
    //     case "操作员":
    //       roleName = "OPERATOR";
    //       break;
    //     case "管理员":
    //       roleName = "MANAGE";
    //     default:
    //       break;
    //   }
    //   let payload = {
    //     token: sessionStorage.getItem("token"),
    //     teamProjectId: this.member.teamProjectId,
    //     roleName: roleName,
    //     byUserId: this.member.userId,
    //   };
    //   console.log(payload);
    //   // updateMemberRole(payload).then((res) => {
    //   //   if (res.status == 200) {
    //   //     this.$message.success("修改成功");
    //   //     console.log(res.data);
    //   //     this.selsectList(this.type);
    //   //     this.dialogVisible = false;
    //   //   } else {
    //   //     this.$message.error(res.msg);
    //   //   }
    //   // });
    // },
    // 移出团队
    shiftOutTeam(item) {
      if (this.$route.query.userId == "false") {
        return this.$alert(
          this.$t('m.您没有权限使用该功能，敬请谅解！'),
          this.$t('m.额，好像没有权限'),
          {
            confirmButtonText: this.$t('m.知道啦'),
          }
        );
      }
      this.$confirm(this.$t('m.移出团队后，该成员将被从成员列表移除'), this.$t('m.移出团队'), {
        confirmButtonText: this.$t('m.确定'),
        cancelButtonText: this.$t('m.取消'),
        closeOnClickModal: false,
      })
        .then(() => {
          let payload = {
            token: sessionStorage.getItem("token"),
            teamId: item.teamId,
            userId: item.userId,
          };

          shiftOutTeam(payload).then((res) => {
            if (res.status == 200) {
              this.$message.success(this.$t('m.移除成功'));
              this.selsectList(this.type);
              this.dialogVisible = false;
              console.log(res.data);
            } else {
              this.$message.error(res.msg);
            }
          });
        })
        .catch(() => {
          this.dialogVisible = false;
        });
    },
    // 查询项目列表
    getProjectList() {
      if (!this.defaultTeam) {
        return;
      }
      let payload = {
        token: sessionStorage.getItem("token"),
        teamId: this.defaultTeam.teamId,
        pageNo: 1,
        pageSize: 100,
      };
      getProjectList(payload).then((res) => {
        if (res.status == 200) {
          this.teamMemberList = res.data.projectList;
        } else {
          this.$message.error(res.msg);
        }
      });
    },

    // 移出项目
    deleteProjectMember(item) {
      if (this.$route.query.userId == "false") {
        return this.$alert(
          this.$t('m.您没有权限使用该功能，敬请谅解！'),
          this.$t('m.额，好像没有权限'),
          {
            confirmButtonText: this.$t('m.知道啦'),
          }
        );
      }
      this.$confirm(
        this.$t('m.移出项目后，该成员所属的用户将被分配给项目管理员'),
        this.$t('m.移出项目'),
        {
          confirmButtonText: this.$t('m.确定'),
          cancelButtonText: this.$t('m.取消'),
          closeOnClickModal: false,
        }
      )
        .then(() => {
          let payload = {
            teamProjectId: item.teamProjectId,
            token: sessionStorage.getItem("token"),
            userId: item.userId,
          };
          deleteProjectMember(payload).then((res) => {
            if (res.status == 200) {
              this.$message.success(this.$t('m.操作成功'));
              let payload = {
                token: sessionStorage.getItem("token"),
                teamProjectId: this.teamProjectId,
              };
              this.findProjectMember(payload);
              this.$emit("member");
            } else {
              this.$message.error(res.msg);
            }
          });
        })
        .catch(() => {});
    },
    handleClose(done) {
      done();
    },
    // 添加未加入项目成员
    getUnSelectTeamMember() {
      if (this.$route.query.userId == "false") {
        return this.$alert(
          this.$t('m.您没有权限使用该功能，敬请谅解！'),
          this.$t('m.额，好像没有权限'),
          {
            confirmButtonText: this.$t('m.知道啦'),
          }
        );
      }
      this.SelectTeamMember = true;
      let payload = {
        teamId: this.defaultTeam.teamId,
        token: sessionStorage.getItem("token"),
        teamProjectId: this.teamMemberList[0].teamProjectId,
      };
      getUnSelectTeamMember(payload).then((res) => {
        if (res.status == 200) {
          console.log(res.data);
          this.notMemberList = res.data.memberList;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 选中
    change(item) {
      item.type = !item.type ? true : false;
      console.log(item);
    },
    // 查询项目成员
    getProjectMember() {
      let payload = {
        token: sessionStorage.getItem("token"),
        teamProjectId: this.teamMemberList[0].teamProjectId,
      };
      getProjectMember(payload).then((res) => {
        if (res.status == 200) {
          this.teamMemberList = res.data.memberList;
          this.dialogVisible = false;
          console.log(res.data);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    addProjectMember() {
      let list = this.notMemberList;
      let newList = list.filter(function (item) {
        return item.type;
      });
      let userIds = [];
      for (let i = 0; i < newList.length; i++) {
        userIds.push(newList[i].userId);
      }
      let payload = {
        teamProjectId: this.teamMemberList[0].teamProjectId,
        token: sessionStorage.getItem("token"),
        userIds: userIds,
      };
      addProjectMember(payload).then((res) => {
        if (res.status == 200) {
          this.SelectTeamMember = false;
          let payload = {
            token: sessionStorage.getItem("token"),
            teamProjectId: this.teamProjectId,
          };
          this.findProjectMember(payload);
          this.$emit("member");
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
  created() {
    this.userIdShow = this.$route.query.userId;
    console.log(this.userIdShow);
  },
  mounted() {
    let defaultTeam = sessionStorage.getItem("defaultTeam");
    this.defaultTeam = JSON.parse(defaultTeam);
    this.selsectList(this.active);
  },
  beforeCreate() {},
  beforeMount() {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {},
  activated() {},
};
</script>
<style lang='scss' scoped>
.allMembers {
  .content {
    .header {
      color: #313237;
      font-size: 24px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      div {
        display: flex;
        .headerVip {
          color: #8f9096;
          font-size: 16px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0 0 0 8px;
          .headerVipItem {
            width: 16px;
            height: 16px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #c1c3cb;
            border-radius: 8px;
            font-size: 12px;
            color: #fff;
            margin: 0 0 0 4px;
            cursor: pointer;
          }
        }
      }
    }
    p {
      font-size: 14px;
      color: #333;
      margin-bottom: 40px;
    }
    .title-box {
      display: flex;
      align-items: center;
    }
    .icon {
      width: 12px;
      height: 12px;
      border-radius: 50%;
      margin-left: 10px;
      cursor: pointer;
    }

    .member-info {
      display: flex;
      align-items: center;
      .member-img {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        margin-right: 5px;
      }
      .me {
        margin-left: 5px;
        color: #fff;
        background: #2174ff;
        border-radius: 8px;
        width: 28px;
        height: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
      }
      div {
        display: flex;
        flex-direction: column;
        .member-name {
          font-size: 14px;
          color: #333;
        }
        .member-phone {
          font-size: 12px;
          color: #666;
        }
      }
    }
    .operation {
      cursor: pointer;
    }
  }
  // 弹窗
  .member-settings {
    font-size: 12px;
    .member-info {
      color: #333;
      font-size: 14px;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      :last-child {
        font-size: 14px;
        color: #666;
      }
    }
    .jurisdiction-set {
      border-top: 1px solid #f3f3f3;
      margin-top: 10px;
      .title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 10px 0;
        color: #999;
      }
      .box {
        font-size: 14px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 15px;
      }
    }
  }
  .jurisdiction {
    color: #999;
    font-size: 12px;
    line-height: 20px;
    .title {
      color: #333;
      margin-right: 5px;
    }
    .box {
      padding: 3px 0 18px;
      margin: 0 0 20px;
    }
    span {
      margin-right: 10px;
    }
  }
  .btn {
    display: flex;
    font-size: 14px;
    justify-content: flex-end;
    margin-right: 10px;
    span {
      width: 70px;
      height: 33px;
      background: #66b1ff;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      cursor: pointer;
    }
  }
  .dialog-content {
    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 10px;
      margin-bottom: 10px;
      border-bottom: 1px solid #f5f5f5;
      :nth-child(1) {
        width: 40%;
      }
      span {
        width: 25%;
      }
      :last-child {
        text-align: right;
      }
    }
    .content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 14px;
      color: #333;
      margin-bottom: 20px;
      div {
        width: 40%;
        display: flex;
        align-items: center;
        img {
          width: 40px;
          height: 40px;
          border-radius: 50%;
        }
        .members-name {
          margin: 0 8px;
        }
        :last-child {
          color: #999999;
          font-size: 12px;
        }
      }
      .members-phone,
      .members-check {
        width: 25%;
      }
      .members-check {
        text-align: right;
      }
    }
  }
}
.el-table::before {
  width: 0;
}
.dialog-footer {
  display: flex;
  justify-content: flex-end;
}
</style>