<template>
  <div class="addAccount">
    <el-container>

      <el-header>
        <div class="header-warp">
          <i class="el-icon-arrow-left" @click="goBack"></i>
          <span>{{currentTemplate ? currentTemplate.templateName : null}}</span>
          <span></span>
        </div>
      </el-header>

      <el-container>
        <!-- main -->
        <el-main>
          <div class="content">
            <el-table
              ref="multipleTable"
              :data="availableList"
              tooltip-effect="dark"
              style="width: 100%"
              @selection-change="handleSelectionChange">

              <el-table-column :label="$t('m.用户')" width="200">
                <template slot-scope="scope">{{
                  scope.row.remarkCode
                    ? scope.row.remarkCode
                    : scope.row.publicCode
                }}</template>
              </el-table-column>

              <el-table-column prop="availableNum" :label="$t('m.可用金额')" width="200">
                <template slot-scope="scope">{{
                  Math.floor(Number(scope.row.available) * 10000) / 10000
                }}</template>
              </el-table-column>

              <el-table-column
                prop="investCoinNum"
                label="可用占比"
                width="200">
                <template slot-scope="scope">{{
                  (
                    Math.floor((Number(scope.row.available) / Number(scope.row.investCoinNum)) * 10000) 
                    / 100 > 100  ? 100  : Math.floor((Number(scope.row.available) / Number(scope.row.investCoinNum)) * 10000) 
                    / 100) + "%"
                }}</template>
              </el-table-column>
              <el-table-column prop="name" :label="$t('m.入金时间')" width="200">
                <template slot-scope="scope">
                  {{
                    moment(scope.row.createTime).format("YYYY-MM-DD HH:mm:ss")
                  }}
                </template>
              </el-table-column>
              <el-table-column type="selection" width="55"> </el-table-column>
            </el-table>

            <div class="btn-warp">
              <span>{{
                $t('m.账户列表&(1)条，已选择&(2)条')
                .replace('&(1)', availableList.length)
                .replace('&(2)', multipleSelection.length)}}</span>
              <span class="btn" @click="confirm">{{$t('m.确定')}}</span>
            </div>
          </div>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import moment from "moment";
import {
  getAvailableList,
  batchStart,
  queryOperatorUserList,
  contractBatchStart,
} from "../../request/api";
export default {
  components: {},
  data() {
    return {
      moment,
      availableList: [], //列表数据
      multipleSelection: [],
      templateId: null, //模板ID
      currentPage4: 4,

      project: null,
      currentTemplate: null, //当前模板
    };
  },
  computed: {},
  watch: {},
  methods: {
    // 返回上一页
    goBack() {
      this.$router.back(-1);
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
      console.log(this.multipleSelection);
    },

    // // 查询账户列表
    // getAvailableList() {
    //   let payload = {
    //     token: sessionStorage.getItem("token"),
    //     teamProjectId: this.project.teamProjectId,
    //     templateId: this.templateId,
    //     errorInfo: false,
    //   };
    //   getAvailableList(payload).then((res) => {
    //     if (res.status == 200) {
    //       console.log("账户列表", res.data);
    //       // let availableList = res.data.availableList;
    //       // let list = [];
    //       // availableList.forEach((e) => {
    //       //   if (e.available > e.investCoinNum) {
    //       //     list.push(e);
    //       //   }
    //       // });
    //       this.availableList = res.data.availableList;
    //       // console.log(JSON.parse(res.data.investConfig));
    //       // this.investConfig = JSON.parse(res.data.investConfig);
    //       // this.templateList = res.data.templateList;
    //     } else {
    //       this.$message.error(res.msg);
    //     }
    //   });
    // },
    queryOperatorUserList() {
      let payload = {
        token: sessionStorage.getItem("token"),
        exchangeName: this.currentTemplate.exchangeName,
        teamProjectId: this.project.teamProjectId,
        transactionType:
          this.currentTemplate.transactionType == "spot"
            ? "spot"
            : "contract_usdt",
      };

      if (this.currentTemplate.transactionType == "spot") {
        payload.investCoin = this.currentTemplate.investCoin;
      } else {
        payload.contractId = this.currentTemplate.transactionPair;
      }

      queryOperatorUserList(payload).then((res) => {
        if (res.status == 200) {
          this.availableList = res.data.operatorUserList;
        } else {
          this.$message.error(res.msg);
        }
      });
    },

    // 添加账户
    confirm() {
      let list = this.multipleSelection;
      if (list.length == 0) {
        this.$message.error(this.$t('m.请先选择账户'));
        return;
      } else {
        let ids = [];
        for (let i = 0; i < list.length; i++) {
          ids.push(list[i].id + "");
        }
        let payload = {
          token: sessionStorage.getItem("token"),
          templateId: this.templateId,
          ids: ids,
        };

        if (this.currentTemplate.transactionType == "spot") {
          batchStart(payload).then((res) => {
            if (res.status == 200) {
              this.$message.success(this.$t('m.操作成功'));
              sessionStorage.setItem("projectActive", "1-2");
              this.$router.push({ path: "project" });
              this.$destroy();
            } else {
              this.$message.error(res.msg);
            }
          });
        } else {
          contractBatchStart(payload).then((res) => {
            if (res.status == 200) {
              this.$message.success(this.$t('m.操作成功'));
              sessionStorage.setItem("projectActive", "1-2");
              this.$router.push({ path: "project" });
              this.$destroy();
            } else {
              this.$message.error(res.msg);
            }
          });
        }
      }
    },
  },
  created() {},
  mounted() {},
  beforeCreate() {},
  beforeMount() {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {},
  activated() {
    this.templateId = this.$route.query.templateId;
    console.log(this.$route.query.templateId);
    let project = sessionStorage.getItem("project");
    this.project = JSON.parse(project);
    let currentTemplate = sessionStorage.getItem("currentTemplate");
    this.currentTemplate = JSON.parse(currentTemplate);
    this.queryOperatorUserList();
  },

  deactivated() {
    this.$destroy();
  },
};
</script>
<style lang='scss' scoped>
.addAccount {
  width: 100%;
  height: 100%;
  .el-container {
    height: 100%;
    background: #eff2f5;
  }
  .el-header {
    background-color: #fff;
    color: #333;
    text-align: center;
    line-height: 60px;
    margin-bottom: 20px;
    .header-warp {
      display: flex;
      justify-content: space-between;
      align-items: center;
      i {
        cursor: pointer;
      }
    }
  }
  .el-main {
    color: #333;
    padding: 0 20%;
    font-size: 14px;
    .content {
      background: #fff;
      border-radius: 8px;
      padding: 20px;
      p {
        font-size: 14px;
        color: #333;
        margin-bottom: 16px;
      }
      .box-pagination {
        margin-top: 20px;
        text-align: right;
      }
      .btn-warp {
        padding: 20px 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        color: #999;
        .btn {
          width: 200px;
          height: 32px;
          text-align: center;
          line-height: 32px;
          color: #fff;
          background: #007aff;
          margin-top: 20px;
          border-radius: 8px;
          cursor: pointer;
        }
      }
    }
  }
}
</style>