<template>
  <div
    class="selectNav"
    ref="companyStyle"
    :style="{ height: this.searchHeight + 'px' }">

    <div class="navItem">
      <div
        class="selectItem"
        :style="{
          'margin-top': widthStyle ? (index >= widthStyle ? '10px' : '') : '',
        }"
        v-for="(item, index) in searchData"
        :key="index">

        <span>{{ $t('m.'+item.name) }}：</span>

        <span style="height: 28px" v-if="item.hasOwnProperty('type')">
          <el-input
            v-if="item.type == 'input'"
            v-model="selectList[index]"
            size="mini"
            style="width: 120px"
            :placeholder="$t('m.全部')">
          </el-input>
          <el-date-picker
            v-if="item.type == 'picker'"
            v-model="selectList[index]"
            type="daterange"
            size="mini"
            class="pickerStyle"
            :picker-options="pickerOptions"
            clearable
            :range-separator="$t('m.至')"
            :start-placeholder="$t('m.开始时间')"
            :end-placeholder="$t('m.结束时间')">
          </el-date-picker>
          <template v-if="item.type == 'inputTwo'">
            <input
              :placeholder="$t('m.全部')"
              v-model="selectList[index][0]"
              type="text"
              style="margin-right: 10px"
            />
            <input
              :placeholder="$t('m.全部')"
              type="text"
              v-model="selectList[index][1]"
            />
          </template>
        </span>
        <el-select
          v-else
          :filterable="item.filterable ? true : false"
          size="mini"
          v-model="selectList[index]"
          style="width: 120px">
          <el-option v-for="(i, idx) in item.data" :key="idx" :value="i">
          </el-option>
        </el-select>
      </div>
    </div>

    <div
      class="btnData"
      :style="{
        height: this.searchBtn + 'px',
        'align-items': this.searchBtn <= 30 ? 'center' : 'flex-start',
      }">
      <span class="btn-submit" @click="btnSubmit">{{$t('m.查询')}}</span>
      <span style="background-color: #55698a" @click="btnBack">{{$t('m.重置')}}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: ["searchData"],
  data() {
    return {
      selectList: [],
      searchBtn: null,
      searchHeight: null, //标签高度
      widthStyle: null, //标签宽度
      input: null,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
    };
  },
  watch: {
    searchData() {
      let selectList = [];
      console.log(this.searchData);
      for (let i = 0; i < this.searchData.length; i++) {
        if (this.searchData[i].hasOwnProperty("type")) {
          if (this.searchData[i].type == "input") {
            selectList[i] = "";
          } else if (this.searchData[i].type == "picker") {
            selectList[i] = ["", ""];
          } else if (this.searchData[i].type == "inputTwo") {
            selectList[i] = ["", ""];
          }
        } else {
          selectList[i] = this.searchData[i].data[0];
        }
      }
      this.selectList = selectList;
    },
  },
  methods: {
    //查询按钮
    btnSubmit() {
      this.selectList.forEach((e, index) => {
        if (typeof e == "object") {
          e.forEach((item, idx) => {
            this.selectList[index][idx] = item.toString().trim();
          });
        } else {
          this.selectList[index] = e.trim();
        }
      });
      console.log(this.selectList);
      this.$emit("btnSubmit", this.selectList);
    },
    //重置按钮
    btnBack() {
      let selectList = [];
      sessionStorage.removeItem('searchData')
      for (let i = 0; i < this.searchData.length; i++) {
        if (this.searchData[i].hasOwnProperty("type")) {
          if (this.searchData[i].type == "input") {
            selectList[i] = "";
          } else if (this.searchData[i].type == "picker") {
            selectList[i] = ["", ""];
          } else if (this.searchData[i].type == "inputTwo") {
            selectList[i] = ["", ""];
          }
        } else {
          selectList[i] = this.searchData[i].data[0];
        }
      }
      this.selectList = selectList;
      this.$emit("btnBack", selectList);
    },
    //禁止时间按钮
    disabledDate(current) {
      return current > moment().endOf("day");
    },
  },
  created() {
    for (let i = 0; i < this.searchData.length; i++) {
      if (this.searchData[i].hasOwnProperty("type")) {
        if (this.searchData[i].type == "input") {
          this.selectList[i] = "";
        } else if (
          this.searchData[i].type == "picker" ||
          this.searchData[i].type == "inputTwo"
        ) {
          this.selectList[i] = ["", ""];
        }
      } else {
        this.selectList[i] = this.searchData[i].data[0];
      }
    }
    if (sessionStorage.getItem("searchData")) {
      let searchData = JSON.parse(sessionStorage.getItem("searchData"));
      this.selectList = searchData;
    }
    console.log(this.selectList);
  },
  mounted() {
    this.widthStyle = parseInt(
      (this.$refs.companyStyle.offsetWidth - 60) / 200
    );
    if (this.searchData.length % this.widthStyle == 0) {
      this.searchHeight =
        parseInt(this.searchData.length / this.widthStyle) * 36;
      this.searchBtn = parseInt(this.searchData.length / this.widthStyle) * 30;
    } else {
      this.searchHeight =
        parseInt(this.searchData.length / this.widthStyle + 1) * 36;
      this.searchBtn =
        parseInt(this.searchData.length / this.widthStyle + 1) * 30;
    }
  },
};
</script>

<style scoped lang='scss'>
.selectNav {
  width: 100%;
  height: 36px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  .navItem {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    /deep/.el-input__inner {
      background-color: #1c1d28 !important;
      border: 1px solid #1c1d28 !important;
      color: #fff;
    }
    .selectItem {
      display: flex;
      align-items: center;
      margin-right: 20px;
    }
    /deep/.pickerStyle {
      background-color: #1c1d28;
      width: 250px;
      .el-range-input {
        flex: 1;
        background-color: #1c1d28;
        color: #fff;
      }
      .el-range-separator {
        color: #fff;
      }
      .el-icon-date {
        display: none;
      }
      .el-input__icon {
        color: #fff;
      }
    }
  }
  .btnData {
    display: flex;
    justify-content: center;
    .btn-submit {
      margin-right: 10px;
      background-color: #2174ff;
    }
    span {
      height: 20px;
      width: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      border-radius: 2px;
    }
  }
}
input {
  background-color: #1c1d28;
  border: 0;
  color: #fff;
  height: 100%;
  width: 105px;
  padding-left: 15px;
  border-radius: 5px;
}
input:-ms-input-placeholder {
  color: #a9a9a9;
} /* Internet Explorer 10+ */

input::-webkit-input-placeholder {
  color: #a9a9a9;
} /* WebKit browsers */

input::-moz-placeholder {
  color: #a9a9a9;
} /* Mozilla Firefox 4 to 18 */

input:-moz-placeholder {
  color: #a9a9a9;
} /* Mozilla Firefox 19+ */
</style>