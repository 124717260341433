<template>
  <!-- 交易操作 -->
  <div class="tradingOperation">
    <div class="content">
      <div class="content-box" v-for="(item, idx) in managerList" :key="idx">
        <div class="content-box-title">
          <div>
            <span>{{item.userData.mark + "：" + item.userData.nickName}}</span>
            <span v-if="item.userData.isDefault == 1">({{$t('m.默认')}})</span>
            <span class="title-me" v-if="account.userId == item.userData.userId">{{$t('m.我')}}</span>
          </div>
          <el-dropdown placement="bottom-start" trigger="click">
            <span class="el-dropdown-link">
              <i class="el-icon-more" style="cursor: pointer"></i>
            </span>

            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click.native="settingOperator(item.userData.userId)">
                {{$t('m.设置默认操作员')}}</el-dropdown-item>
              <el-dropdown-item @click.native="migratingUsers(item.userData.userId)">
                {{$t('m.整体迁移用户')}}</el-dropdown-item>
              <el-dropdown-item @click.native="deleteProjectMember(item.userData)">
                {{$t('m.移出项目')}}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>

        <div @click="toDetails(item)">

          <div class="content-box-text">
            <span>{{$t('m.当前/累计账户数量：')}}</span>
            <span>{{
              (item.currentUserCount
                ? Math.floor(Number(item.currentUserCount) * 10000) / 10000
                : "--") +
              " / " +
              (item.accumulatedUserCount
                ? Math.floor(Number(item.accumulatedUserCount) * 10000) / 10000
                : "--")
            }}</span>
          </div>

          <div class="content-box-text">
            <span>{{$t('m.当前/累计执行策略：')}}</span>
            <span>{{
              (item.currentExecuteCount
                ? Math.floor(Number(item.currentExecuteCount) * 10000) / 10000
                : "--") +
              " / " +
              (item.accumulatedExecuteCount
                ? Math.floor(Number(item.accumulatedExecuteCount) * 10000) /
                  10000
                : "--")
            }}</span>
          </div>

          <div class="content-box-text">
            <span>{{$t('m.当前/累计资产：')}}</span>
            <span>{{
              (item.currentAssets
                ? Math.floor(Number(item.currentAssets) * 10000) / 10000
                : "--") +
              " / " +
              (item.accumulatedAssets
                ? Math.floor(Number(item.accumulatedAssets) * 10000) / 10000
                : "--")
            }}</span>
          </div>

          <div class="content-box-text">
            <span>{{$t('m.当前/累计账户盈亏：')}}</span>
            <span>{{
              (item.currentProfitLoss
                ? Math.floor(Number(item.currentProfitLoss) * 10000) / 10000
                : "--") +
              " / " +
              (item.cumulatedProfitLoss
                ? Math.floor(Number(item.cumulatedProfitLoss) * 10000) / 10000
                : "--")
            }}</span>
          </div>

          <div class="content-box-text">
            <span>{{$t('m.最大盈利/回撤率：')}}</span>
            <span>{{
              (item.maxReturn
                ? Math.floor(Number(item.maxReturn) * 10000) / 10000
                : "--") +
              " / " +
              (item.minReturn
                ? Math.floor(Number(item.minReturn) * 10000) / 10000
                : "--")
            }}</span>
          </div>

          <div class="content-box-text">
            <span>{{$t('m.当前平均年化：')}}</span>
            <span>{{
              item.currentAverageAnnualized
                ? item.currentAverageAnnualized
                : "--"
            }}</span>
          </div>
        </div>
      </div>
    </div>
    <!-- 迁移用户弹窗 -->
    <el-dialog
      :title="$t('m.迁移用户')"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      <el-select v-model="operatorLater" :placeholder="$t('m.请选择操作员')">
        <el-option
          v-for="(item, idx) in OperatorList"
          :key="idx"
          :label="item.nickName"
          :value="item.userId"
        >
        </el-option>
      </el-select>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancel">{{$t('m.取 消')}}</el-button>
        <el-button type="primary" @click="migrationUser">{{$t('m.确 定')}}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  operationManagement,
  settingOperator,
  migrationUser,
  operatorList,
  deleteProjectMember,
} from "../../request/api";
export default {
  components: {},
  data() {
    return {
      role: null,
      project: null,
      account: null,
      managerList: [], //操作员带数据列表
      OperatorList: [], //操作员列表
      dialogVisible: false,
      operatorLater: null, //被迁移操作员ID
    };
  },
  computed: {},
  watch: {},
  methods: {

    handleClose(done) {
      this.operatorFront = null;
      this.operatorLater = null;
      done();
    },

    // 操作详情页
    toDetails(item) {
      console.log(item);
      if (item.userData.nickName == this.role.nickName) {
        sessionStorage.setItem("detailsOperationAuthority", true);
      } else {
        sessionStorage.setItem("detailsOperationAuthority", false);
      }
      this.$router.push({
        path: "Projectoperator",
        query: { userId: item.userData.userId },
      });
    },

    // 查询操作员列表
    operationManagement() {
      let payload = {
        token: sessionStorage.getItem("token"),
        teamProjectId: this.project.teamProjectId,
      };

      operationManagement(payload).then((res) => {
        if (res.status == 200) {
          this.managerList = res.data;
        } else {
          this.$message.error(res.msg);
        }
      });
    },

    // 设置默认操作员
    settingOperator(id) {
      let payload = {
        teamProjectId: this.project.teamProjectId,
        token: sessionStorage.getItem("token"),
        userId: id,
      };
      console.log(payload);
      settingOperator(payload).then((res) => {
        if (res.status == 200) {
          this.$message.success(this.$t('m.设置成功'));
          this.operationManagement();
          console.log(res.data);
        } else {
          this.$message.error(res.msg);
        }
      });
    },

    // 查询操作员列表
    operatorList() {
      let payload = {
        teamProjectId: this.project.teamProjectId,
        token: sessionStorage.getItem("token"),
      };
      console.log(payload);
      operatorList(payload).then((res) => {
        if (res.status == 200) {
          this.OperatorList = res.data.operatorList;
        } else {
          this.$message.error(res.msg);
        }
      });
    },

    // 迁移用户弹窗
    migratingUsers(id) {
      console.log(id);
      this.operatorFront = id;
      this.operatorList();
      this.dialogVisible = true;
    },

    // 迁移用户
    migrationUser() {

      if (!this.operatorLater) {
        this.$message.error(this.$t('m.请先选择操作员'));
        return;
      }
      let payload = {
        teamProjectId: this.project.teamProjectId,
        token: sessionStorage.getItem("token"),
        operatorFront: this.operatorFront,
        operatorLater: this.operatorLater,
      };
      
      migrationUser(payload).then((res) => {
        if (res.status == 200) {
          this.$message.success(this.$t('m.迁移成功'));
          this.dialogVisible = false;
          console.log(res.data);
        } else {
          this.$message.error(res.msg);
        }
      });
    },

    // 取消弹窗
    cancel() {
      (this.operatorFront = null),
        (this.operatorLater = null),
        (this.dialogVisible = false);
    },

    // 权限设置
    authorityManagement() {
      this.$router.push({ path: "projectSetup" });
    },

    // 移除项目成员
    deleteProjectMember(item) {
      console.log(item);

      this.$confirm(
        this.$t('m.退出项目后，该成员所属的用户将被分配给项目管理员'),
        this.$t('m.退出项目'),
        {
          confirmButtonText: this.$t('m.确定'),
          cancelButtonText: this.$t('m.取消'),
          closeOnClickModal: false,
        }
      )
        .then(() => {
          let payload = {
            teamProjectId: this.project.teamProjectId,
            token: sessionStorage.getItem("token"),
            userId: item.userId,
          };
          deleteProjectMember(payload).then((res) => {
            if (res.status == 200) {
              this.$message.success(this.$t('m.移出成功'));
              if (item.mark == "超级管理员") {
                this.$router.push("/");
              } else {
                this.operationManagement();
              }
              console.log(res.data);
            } else {
              this.$message.error(res.msg);
            }
          });
        })
        .catch(() => {});
    },
  },
  created() {},
  mounted() {
    let project = sessionStorage.getItem("project");
    this.project = JSON.parse(project);
    let account = sessionStorage.getItem("account");
    this.account = JSON.parse(account);
    let role = sessionStorage.getItem("role");
    this.role = JSON.parse(role);
    this.operationManagement();
  },
  beforeCreate() {},
  beforeMount() {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {},
  activated() {
    
    let project = sessionStorage.getItem("project");
    this.project = JSON.parse(project);
    let account = sessionStorage.getItem("account");
    this.account = JSON.parse(account);
    let role = sessionStorage.getItem("role");
    this.role = JSON.parse(role);
    this.operationManagement();
  },
  deactivated() {
  },
};
</script>
<style lang='scss' scoped>
.tradingOperation {
  width: 100%;
  height: 100%;

  .content {
    font-size: 14px;
    color: #333;
    display: flex;
    flex-wrap: wrap;
    .content-box {
      width: 300px;
      padding: 24px;
      margin: 0 24px 24px 0;
      background: #fff;
      border-radius: 8px;
      .content-box-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 40px;
        div {
          display: flex;
          align-items: center;
          :nth-child(2) {
            font-size: 12px;
            color: #999;
            margin: 0 5px;
          }
          .title-me {
            font-size: 12px;
            color: #007aff;
          }
        }
      }
      .content-box-text {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #999;
        font-size: 14px;
        margin-bottom: 10px;
      }
      .el-select {
        width: 100% !important;
      }
    }
  }
}
</style>