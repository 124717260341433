<template>
  <!-- 策略消息 -->
  <div class="strategyMessage">
    <el-container>
      <el-header>
        <div class="header-warp">
          <i v-if="!showName" class="el-icon-arrow-left" @click="goBack"></i>
          <span>{{$t('m.策略消息')}}</span>
          <span></span>
        </div>
      </el-header>
      <el-container>
        <!-- main -->
        <el-main>
          <div class="content" v-if="messageList.length != 0">
            <div class="log-box" v-for="(item, idx) in messageList" :key="idx">
              <span>{{
                moment(item.createTime).format("YYYY-MM-DD HH:mm:ss")
              }}</span>
              <span>{{ $t('m.'+item.strategyName) }}</span>
              <span>{{ item.content }}</span>
            </div>

            <div class="pagination-warp">
              <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :page-sizes="[10, 20, 30, 40, 50]"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total"
              >
              </el-pagination>
            </div>
          </div>
          <div v-else class="defaultPage">
            <img src="../../assets/image/img_01@2x (1).png" alt="" />
            <span>{{$t('m.暂无数据')}}</span>
          </div>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import moment from "moment";
import { queryStrategyMessageList } from "../../request/api";
export default {
  components: {},
  data() {
    return {
      moment,
      templateId: null, //模板ID
      project: null,
      logList: [],
      pageNo: 1,
      pageSize: 10,
      total: 0,
      executeId: null,
      messageList: [],
      showName: false,
    };
  },
  computed: {},
  watch: {},
  methods: {
    // 返回上一页
    goBack() {
      this.$router.back(-1);
    },
    // 查询策略消息
    queryStrategyMessageList() {
      let payload = {
        token: sessionStorage.getItem("token"),
        executeId: this.executeId,
        pageNo: this.pageNo,
        pageSize: this.pageSize,
      };
      queryStrategyMessageList(payload).then((res) => {
        if (res.status == 200) {
          
          if (res.data.length != 0) {
            this.messageList = res.data.messageList;
            this.total = res.data.messageCount;
          } else {
            this.total = 0;
            this.messageList = [];
          }
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.queryStrategyMessageList();
    },
    handleCurrentChange(val) {
      this.pageNo = val;
      this.queryStrategyMessageList();
    },
  },
  created() {},
  mounted() {},
  beforeCreate() {},
  beforeMount() {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {},
  activated() {
    if (sessionStorage.getItem("Now")) {
      let strategyMessage = JSON.parse(
        sessionStorage.getItem("strategyMessage")
      );
      this.executeId = strategyMessage.executeId;
      this.showName = true;
    } else {
      this.executeId = this.$route.query.executeId;
    }
    this.queryStrategyMessageList();
  },
  deactivated() {
    this.$destroy();
  },
};
</script>
<style lang='scss' scoped>
.strategyMessage {
  width: 100%;
  height: 100%;
  .el-container {
    height: 100%;
    background: #eff2f5;
  }
  .el-header {
    background-color: #fff;
    color: #333;
    text-align: center;
    line-height: 60px;
    margin-bottom: 20px;
    .header-warp {
      display: flex;
      justify-content: space-between;
      align-items: center;
      i {
        cursor: pointer;
      }
    }
  }
  .el-main {
    color: #333;
    padding: 0 10%;
    font-size: 14px;
    .content {
      background: #fff;
      border-radius: 8px;
      padding: 20px;
      .log-box {
        color: #666;
        font-size: 14px;
        line-height: 30px;
        border-bottom: 1px solid #eaeaec;
        span {
          display: inline-block;
          margin-right: 10px;
          padding: 10px 0;
        }
      }
      .pagination-warp {
        display: flex;
        justify-content: flex-end;
        margin: 20px 0;
      }
    }
    .defaultPage {
      height: 500px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: #fff;
      span {
        margin-top: 10px;
        color: #999;
        font-size: 12px;
      }
    }
  }
}
</style>