<template>
  <div class="allMessage">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane :label="$t('m.策略交易')" name="0"></el-tab-pane>
      <el-tab-pane :label="$t('m.智能交易')" name="1"></el-tab-pane>
      <el-tab-pane :label="$t('m.托管账户')" name="2"></el-tab-pane>
      <el-tab-pane :label="$t('m.项目消息')" name="3"></el-tab-pane>
    </el-tabs>

    <div class="content">
      <div>
        <div class="select">
          <div class="farst" v-if="activeName != '3'">
            <span class="label">{{$t('m.操作员')}}：</span>
            <el-select
              v-model="operatorId"
              :placeholder="$t('m.请选择')"
              filterable
              @change="operatorChange()"
            >
              <el-option
                v-for="item in operatorMap"
                :key="item.userId"
                :label="item.nickName"
                :value="item.userId"
              >
              </el-option>
            </el-select>
          </div>
          <div>
            <span class="label">{{$t('m.账户')}}：</span>
            <el-select
              v-model="acc"
              :placeholder="$t('m.请选择')"
              filterable
              @change="accChange()"
            >
              <el-option
                v-for="item in accList"
                :key="item.id"
                :label="item.remarkCode ? item.remarkCode : item.publicCode"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
        </div>

        <div class="table-warp" v-if="messageList">
          <el-table :data="messageList" style="width: 100%; height: 100%">
            <el-table-column prop="date" :label="$t('m.时间')" width="180">
              <template slot-scope="scope">
                {{ moment(scope.row.createTime).format("YYYY-MM-DD HH:mm:ss") }}
              </template>
            </el-table-column>

            <el-table-column
              prop="name"
              :label="$t('m.信息')"
              width="600"
              v-if="activeName == '0'"
            >
              <template slot-scope="scope">
                {{ scope.row.content }}
              </template>
            </el-table-column>

            <el-table-column
              prop="name"
              :label="$t('m.信息')"
              width="600"
              v-if="activeName == '1'"
            >
              <template slot-scope="scope">
                
                <span>{{ "[" + scope.row.label + "]" }}</span>
                <span> - </span>
                <span>{{
                  scope.row.transactionType == "spot" ? "["+$t('m.现货')+"]" : "["+$t('m.合约')+"]"
                }}</span>
                <span> - </span>
                <span>[</span>

                <span v-if="JSON.parse(scope.row.log).amount">{{$t('m.数量')
                  + "：" +
                  Math.floor(Number(JSON.parse(scope.row.log).amount) * 10000) 
                  / 10000}}</span>

                <span v-if="JSON.parse(scope.row.log).price">{{"，"
                  + $t('m.价格')
                  + "：" +
                  Math.floor(Number(JSON.parse(scope.row.log).price) * 10000) /
                    10000}}</span>

                <span v-if="JSON.parse(scope.row.log).counterCoin">{{
                  "，"
                  +$t('m.交易对')
                  +"：" +
                  JSON.parse(scope.row.log).counterCoin +
                  "/" +
                  JSON.parse(scope.row.log).baseCoin
                }}</span>

                <span v-if="JSON.parse(scope.row.log).tradeType">{{
                  "，"
                  + $t('m.交易类型')
                  +"：" +
                  (JSON.parse(scope.row.log).status == "0" 
                  ? $t('m.委托') : JSON.parse(scope.row.log).tradeType == "0"
                    ? $t('m.卖出') : $t('m.买入'))
                }}</span>
                <span v-if="JSON.parse(scope.row.log).states">
                  {{
                  "，"
                  + $t('m.交易状态')
                  + "：" +
                  (JSON.parse(scope.row.log).states == "0" ? $t('m.成功') : $t('m.失败'))
                }}</span>
                <span v-if="JSON.parse(scope.row.log).error">{{
                  "，"
                  + $t('m.错误码')
                  + "：" + JSON.parse(scope.row.log).error
                }}</span>
                <span>]</span>
              </template>
            </el-table-column>

            <el-table-column
              prop="name"
              label="信息"
              width="600"
              v-if="activeName == '2'"
            >
              <template slot-scope="scope">
                <span v-if="scope.row.logType == 'input'">【{{$t('m.入金')}}】</span>
                <span v-if="scope.row.logType == 'out'">【{{$t('m.退出入金')}}】</span>
                <span v-if="scope.row.logType == 'triggerEettlement'">【{{$t('m.触发结算')}}】</span>
                <span v-if="scope.row.logType == 'apiExpire'">【{{$t('m.api无效')}}】</span>

                <span v-if="scope.row.endType == 'STOP_HAND'"
                  >- 【{{$t('m.手动终止')}}】</span
                >
                <span v-if="scope.row.endType == 'STOP_AUTO'"
                  >- 【{{$t('m.自动终止')}}】</span
                >
                <span v-if="scope.row.endType == 'STOP_OPERATOR'"
                  >- 【{{$t('m.操作员终止')}}】</span
                >
              </template>
            </el-table-column>

            <el-table-column
              prop="name"
              :label="$t('m.信息')"
              width="600"
              v-if="activeName == '3'"
            >
              <template slot-scope="scope">
                <span>{{ "[" + scope.row.projectName + "}" }}</span>
                <span>{{ "-" + scope.row.log }}</span>
              </template>
            </el-table-column>

            <el-table-column
              prop="address"
              :label="$t('m.所属账户')"
              width="200"
              v-if="activeName != '3'"
            >
              <template slot-scope="scope">
                {{
                  scope.row.remarkCode
                    ? scope.row.remarkCode
                    : scope.row.publicCode
                    ? scope.row.publicCode
                    : "--"
                }}
              </template>
            </el-table-column>
            <el-table-column
              prop="address"
              :label="$t('m.所属操作员')"
              v-if="activeName != '3'"
            >
              <template slot-scope="scope">
                {{ operaTor(scope.row.operator) }}
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>

      <div class="pagination-warp">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="[10, 20, 50, 100, 500]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import {
  strategyMessageList,
  IntelligenceOrderMessageList,
  getOperator,
  getPublicUser,
  trustAccountMessageList,
  projectMessageList,
} from "../../request/api";
export default {
  components: {},
  data() {
    return {
      moment,
      activeName: "0",

      operatorId: null, //操作员ID
      operatorMap: [], //操作员列表
      accList: [], //账户列表
      acc: null, //选中账户
      messageList: [],
      total: 0,
      pageSize: 10,
      pageNo: 1,
    };
  },
  computed: {},
  watch: {},
  methods: {
    handleClick(tab, event) {
      console.log(this.activeName);
      this.acc = null;
      this.operatorId = "All";
      this.acc = "All";
      this.pageNo = 1;
      switch (this.activeName) {
        case "0":
          this.strategyMessageList();
          break;
        case "1":
          this.IntelligenceOrderMessageList();
          break;
        case "2":
          this.trustAccountMessageList();
          break;
        case "3":
          this.projectMessageList();
          break;
        default:
          break;
      }
    },
    handleSizeChange(val) {
      this.pageSize = val;
      switch (this.activeName) {
        case "0":
          this.strategyMessageList();
          break;
        case "1":
          this.IntelligenceOrderMessageList();
          break;
        case "2":
          this.trustAccountMessageList();
          break;
        case "3":
          this.projectMessageList();
          break;
        default:
          break;
      }
    },
    handleCurrentChange(val) {
      this.pageNo = val;
      switch (this.activeName) {
        case "0":
          this.strategyMessageList();
          break;
        case "1":
          this.IntelligenceOrderMessageList();
          break;
        case "2":
          this.trustAccountMessageList();
          break;
        case "3":
          this.projectMessageList();
          break;
        default:
          break;
      }
    },

    operaTor(id) {
      let list = this.operatorMap;
      let nickName = null;
      for (let key of list) {
        if (id == key.userId) {
          nickName = key.nickName;
        }
      }
      return nickName;
    },

    // 操作员列表
    getOperator() {
      let payload = {
        token: sessionStorage.getItem("token"),
        teamProjectId: this.project.teamProjectId,
      };
      getOperator(payload).then((res) => {
        if (res.status == 200) {
          console.log(res.data);
          let operatorMap = res.data.operatorMap;
          operatorMap.unshift({ userId: "All", nickName: $t('m.全部') });
          this.operatorId = "All";
          this.operatorMap = operatorMap;
          this.strategyMessageList();
        }
      });
    },

    // 操作员选中
    operatorChange() {
      console.log(this.operatorId);
      this.acc = null;

      switch (this.activeName) {
        case "0":
          this.strategyMessageList();
          break;
        case "1":
          this.IntelligenceOrderMessageList();
          break;
        default:
          break;
      }

      let payload = {
        token: sessionStorage.getItem("token"),
        operator:
          this.operatorId == "All"
            ? ""
            : this.operatorId == null
            ? ""
            : this.operatorId,
        teamProjectId: this.project.teamProjectId,
      };
      getPublicUser(payload).then((res) => {
        if (res.status == 200) {
          console.log(res.data);
          let publicUserMap = res.data.publicUserMap;
          publicUserMap.unshift({ id: "All", publicCode: $t('m.全部') });
          this.acc = "All";
          this.accList = publicUserMap;
        }
      });
    },

    // 账户选中
    accChange() {
      switch (this.activeName) {
        case "0":
          this.strategyMessageList();
          break;
        case "1":
          this.IntelligenceOrderMessageList();
          break;
        case "2":
          this.trustAccountMessageList();
          break;
        case "3":
          this.projectMessageList();
          break;
        default:
          break;
      }
    },

    //策略消息
    strategyMessageList() {
      let payload = {
        pageSize: this.pageSize,
        pageNo: this.pageNo,
        token: sessionStorage.getItem("token"),
        teamProjectId: this.project.teamProjectId,
        operator: this.operatorId == "All" ? "" : this.operatorId,
        publicId: this.acc == "All" ? "" : this.acc,
      };
      strategyMessageList(payload).then((res) => {
        if (res.status == 200) {
          console.log(res.data);
          this.messageList = res.data.strategyMessageList;
          this.total = res.data.total;
        }
      });
    },

    // 智能交易消息
    IntelligenceOrderMessageList() {
      let payload = {
        pageSize: this.pageSize,
        pageNo: this.pageNo,
        token: sessionStorage.getItem("token"),
        teamProjectId: this.project.teamProjectId,
        operator: this.operatorId == "All" ? "" : this.operatorId,
        publicId: this.acc == "All" ? "" : this.acc,
      };
      IntelligenceOrderMessageList(payload).then((res) => {
        if (res.status == 200) {
          console.log(res.data);
          this.messageList = res.data.intelligenceOrderMessageList;
          this.total = res.data.total;
        }
      });
    },

    // 智能交易消息
    trustAccountMessageList() {
      let payload = {
        pageSize: this.pageSize,
        pageNo: this.pageNo,
        token: sessionStorage.getItem("token"),
        teamProjectId: this.project.teamProjectId,
        operator: this.operatorId == "All" ? "" : this.operatorId,
        publicId: this.acc == "All" ? "" : this.acc.toString(),
      };
      trustAccountMessageList(payload).then((res) => {
        if (res.status == 200) {
          console.log(res.data);
          this.messageList = res.data.trustAccountMessageList;
          this.total = res.data.total;
        }
      });
    },

    // 项目消息

    projectMessageList() {
      let payload = {
        pageSize: this.pageSize,
        pageNo: this.pageNo,
        token: sessionStorage.getItem("token"),
        teamProjectId: this.project.teamProjectId,
      };
      projectMessageList(payload).then((res) => {
        if (res.status == 200) {
          console.log(res.data);
          this.messageList = res.data.projectMessageList;
          this.total = res.data.total;
        }
      });
    },
  },
  created() {
    let project = sessionStorage.getItem("project");
    this.project = JSON.parse(project);
    this.getOperator();
  },
  mounted() {
    this.operatorChange();
  },
  beforeCreate() {},
  beforeMount() {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {},
  activated() {},
};
</script>
<style lang='scss' scoped>
//@import url(); 引入公共css类
.allMessage {
  padding: 20px;
  border-radius: 8px;
  background: #fff;
  height: calc(100% - 50px);
  // height: 95%;
  overflow-y: scroll;
  .content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100% - 55px);
    .select {
      display: flex;
      align-items: center;
      font-size: 12px;
      color: #55698a;
      margin: 20px 0;
      .farst {
        margin-right: 30px;
      }
      .label {
        margin-right: 10px;
      }
    }
    .table-warp {
      min-height: 350px;
      // overflow: scroll;
    }
    .pagination-warp {
      margin-top: 30px;
      display: flex;
      justify-content: center;
      // overflow: hidden;
    }
  }
}
</style>