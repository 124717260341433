<template>
  <div class="Signal">
    <el-container>
      <div class="bg">
        <div class="container">
          <div class="header">
            <div class="add" @click="initAddData">
              <img
                class="icon-add"
                src="@/assets/icon/xinzeng-bai.png"
                alt=""
              />
              <span>{{ $t('m.创建信号') }}</span>
            </div>
            <span class="course" @click="toOperationManual()">
              {{$t('m.TradingView信号订阅教程>>')}}</span>
          </div>

          <el-tabs v-model="tab" @tab-click="tabClick">
            <el-tab-pane
              v-for="(item, idx) in tabs"
              :key="'tab' + idx"
              :label="item"
              :name="item"
            ></el-tab-pane>
          </el-tabs>

          <div class="scroll">
            <template v-if="datas.length > 0">
              <div
                class="cell"
                v-for="(item, idx) in datas"
                :key="'cell' + idx"
              >
                <span>{{ item.signalName }}</span>
                <div class="row">
                  <div class="column">
                    <span class="text single">{{ $t('m.创建者') }}</span>
                    <span class="single">{{ item.nickName }}</span>
                  </div>
                  <div class="column">
                    <span class="text single">{{ $t('m.平台') }}</span>
                    <span class="single">{{ item.platName }}</span>
                  </div>
                  <div class="column">
                    <span class="text single">{{ $t('m.有效期') }}</span>
                    <span class="single">{{ item.expireTime + " s" }}</span>
                  </div>
                  <div class="column">
                    <span class="text single">{{ $t('m.属性') }}</span>
                    <span class="single">{{ item.label }}</span>
                  </div>

                  <div class="column">
                    <span class="text single" v-if="item.date">{{
                      item.name
                    }}</span>
                    <span class="single" v-if="item.date">{{ item.date }}</span>
                  </div>

                  <div class="column">
                    <span v-if="item.recodeMessage" class="text single">
                      {{$t('m.接收结果') }}</span>
                    <span v-if="item.recodeMessage" class="single">{{
                      item.recodeMessage ? item.recodeMessage : "--"
                    }}</span>
                    <span v-if="item.recodeMessage" class="time">{{
                      item.recodeCreateTime
                        ? item.recodeCreateTime
                        : item.createTime
                    }}</span>
                    <span v-else>{{$t('m.暂未收到信息')}}</span>
                  </div>
                  <span class="but" @click="infoClick(item)">{{
                    "详情>>"
                  }}</span>
                </div>
              </div>
            </template>

            <div class="no-signal" v-else>
              <img src="../../assets/image/img_01@2x.png" alt="" />
              <span>{{$t('m.暂无数据')}}</span>
            </div>
          </div>
        </div>
      </div>
    </el-container>

    <el-dialog
      :title="$t('m.创建信号')"
      :visible.sync="isAdd1"
      width="642px"
      :close-on-click-modal="false"
    >
      <div class="dialog-container">
        <!--信息-->
        <div class="row">
          <div class="column flex1">
            <span>{{ $t('m.平台') }}</span>
            <div class="height10"></div>
            <el-select v-model="plat" filterable :placeholder="$t('m.请选择')">
              <el-option
                v-for="item in plats"
                :key="item"
                :label="item"
                :value="item"
              ></el-option>
            </el-select>
          </div>

          <div class="width30"></div>
          <div class="column flex1">
            <span>{{ $t('m.名称') }}</span>
            <div class="height10"></div>
            <el-input
              v-model="name"
              :placeholder="$t('m.请输入名称')"
              clearable
            ></el-input>
          </div>

          <div class="width30"></div>
          <div class="column flex1">
            <div class="row">
              <span>{{ $t('m.有效期') }}</span>
              <i
                class="el-icon-warning-outline"
                @click="createSignalTips()"
              ></i>
            </div>
            <div class="height10"></div>
            <el-input
              v-model="time"
              type="number"
              :placeholder="$t('m.请输入有效期')"
              clearable
            >
              <i class="unit" slot="suffix">{{ "s" }}</i>
            </el-input>
          </div>
        </div>

        <!--属性-->
        <div class="height30"></div>
        <div class="column">
          <span>{{ $t('m.属性映射') }}</span>
          <div class="line"></div>
          <div class="column" v-for="(item, idx) in attrs" :key="'attr' + idx">
            <div class="row">
              <div class="column flex1">
                <div class="row">
                  <span>{{ $t('m.属性') + (idx + 1) }}</span>
                  <div class="width30"></div>
                  <span
                    class="but"
                    v-if="attrs.length > 1"
                    @click="remAttrClick(idx)">
                    {{ $t('m.删除-') }}</span>
                </div>
                <div class="height10"></div>
                <el-input
                  v-model="item.name"
                  :placeholder="$t('m.请输入属性')"></el-input>

              </div>
              <img class="icon-arrow" src="@/assets/icon/jiantou.png" alt="" />
              <div class="column flex1">
                <span>{{ $t('m.显示名称') }}</span>
                <div class="height10"></div>
                <el-input
                  v-model="item.label"
                  :placeholder="$t('m.请输入显示名称')"
                ></el-input>
              </div>
              <div class="width30"></div>
              <div class="column flex1">
                <span>{{ $t('m.固定值') }}</span>
                <div class="height10"></div>
                <el-input v-model="item.value" :placeholder="$t('m.可选')"></el-input>
              </div>
            </div>

            <div class="height20"></div>
            <span>{{ $t('m.注释') }}</span>
            <div class="height10"></div>
            <el-input v-model="item.note" :placeholder="$t('m.可选')"></el-input>
            <div class="height30"></div>
          </div>
        </div>

        <span class="but" @click="addAttrClick">{{ $t('m.新增字段+') }}</span>
      </div>
      <div class="dialog-footer" slot="footer">
        <el-button @click="isAdd1 = false">{{ $t('m.取消') }}</el-button>
        <el-button type="primary" @click="addSignalClick()">
          {{$t('m.创建')}}</el-button>
      </div>
    </el-dialog>

    <el-dialog :title="$t('m.创建成功')" :visible.sync="isAdd2" width="642px">
      <div class="dialog-container" v-if="newAttr">
        <span>{{ "Webhook URL" }}</span>
        <div class="height10"></div>
        <div class="row">
          <div class="edit-no">
            <el-input
              v-model="newAttr.webhookUrl"
              :disabled="true"
              resize="none"
            ></el-input>
          </div>
          <div class="width20"></div>
          <span class="but" @click="copyClick(newAttr.webhookUrl)">
            {{$t('m.复制')}}</span>
        </div>

        <div class="height20"></div>
        <span>"Message"</span>
        <div class="height10"></div>
        <div class="row">
          <div class="edit-no">
            <el-input
              v-model="newAttr.message"
              type="textarea"
              :autosize="{ minRows: 10, maxRows: 10 }"
              :disabled="true"
              resize="none"
            ></el-input>
          </div>
          <div class="width20"></div>
          <span class="but" @click="copyClick(newAttr.message)">
            {{$t('m.复制')}}</span>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { addSignal, querySignalList } from "../../request/api";
export default {
  components: {},

  data() {
    return {
      tabs: [this.$t('m.全部'), this.$t('m.我的创建')],
      tab: this.$t('m.全部'),

      isAdd1: false, //true 显示创建弹窗1
      isAdd2: false, //true 显示创建弹窗2
      plats: undefined, //平台列表
      plat: undefined, //平台
      name: undefined, //名称
      time: undefined, //有效期
      attrs: undefined, //属性列表
      newAttr: undefined, //新创建信号

      datas: [], //信号列表

      defaultTeam: null,
      project: null,
    };
  },

  computed: {},

  watch: {},

  methods: {
    //初始化创建信息
    initAddData() {
      this.plats = ["tradingview"]; //平台列表
      this.plat = "tradingview"; //平台
      this.name = this.$t('m.新信号'); //名称
      this.time = "30"; //有效期
      this.attrs = [{ name: "", label: "", value: "", note: "" }];
      this.isAdd1 = true;
    },

    // initData(datas) {
    //   for (let i = 0; i < datas.length; i++) {
    //     var data = datas[i];
    //     var params = JSON.parse(data.paramBody);
    //     var label;
    //     for (let j = 0; j < params.length; j++) {
    //       var param = params[j];
    //       label = label ? label + "、" + param.label : param.label;
    //     }
    //     data.label = label;
    //   }
    //   return datas;
    // },

    /************************ https*/
    //信号列表
    httpSignalList() {
      var isTotal = this.tab == this.$t('m.全部');
      var param = {
        token: sessionStorage.getItem("token"),
        // teamId: "04d1af21b3ac484693aa5659ce728e91", //团队ID
        // teamProjectId: "dc9f05a9fd634ae1a9d547bbbb1fe101", //项目编号
        teamId: this.defaultTeam.teamId, //团队ID
        teamProjectId: this.project.teamProjectId, //项目编号
        isTotal: isTotal,
      };
      querySignalList(param).then((res) => {
        if (res.status == 200) {
          console.log(res);
          let signalList = res.data.signalList;
          signalList.forEach((e) => {
            e.message = JSON.parse(e.message);
            e.paramBody = JSON.parse(e.paramBody);
            e.label = "";
            e.paramBody.forEach((item, index) => {
              if (index == 0) {
                e.label = item.label;
              } else {
                e.label = e.label + "," + item.label;
              }
            });
            if (e.recodeCreateTime) {
              e.recodeCreateTime = this.timestampToTime(e.recodeCreateTime);
            }
            e.createTime = this.timestampToTime(e.createTime);
          });
          console.log(signalList);
          this.datas = signalList;
        } else {
          this.$message.error(data.msg);
        }
      });
    },

    //创建信号
    httpAddSignal() {
      var param = {
        token: sessionStorage.getItem("token"),
        // teamId: "04d1af21b3ac484693aa5659ce728e91", //团队ID
        // teamProjectId: "dc9f05a9fd634ae1a9d547bbbb1fe101", //项目编号
        teamId: this.defaultTeam.teamId, //团队ID
        teamProjectId: this.project.teamProjectId, //项目编号
        isTotal: true,
        platName: this.plat,
        signalName: this.name,
        expireTime: this.time,
        paramBody: JSON.stringify(this.attrs),
      };
      addSignal(param).then((data) => {
        if (data.status == 200) {
          console.log(JSON.stringify(data.data));
          this.isAdd1 = false;
          this.isAdd2 = true;
          this.newAttr = data.data;
          this.httpSignalList();
        } else {
          this.$message.error(data.msg);
        }
      });
    },

    /************************ 点击*/
    //类型点击
    tabClick() {
      this.datas = [];
      this.httpSignalList();
    },

    //详情
    infoClick(item) {
      var param = { signalId: item.signalId };
      this.$router.push({ path: "/SignalInfo", query: param });

      sessionStorage.setItem("Now", this.tab);
    },

    //添加属性
    addAttrClick() {
      var attrs = { name: "", label: "", value: "", note: "" };
      this.attrs.push(attrs);
    },

    //创建信号
    addSignalClick() {
      if (this.plat == "") {
        this.$message({ message: this.$t('m.请选择平台'), type: "warning" });
      } else if (this.name == "") {
        this.$message({ message: this.$t('m.请输入名称'), type: "warning" });
      } else if (this.time == "") {
        this.$message({ message: this.$t('m.请输入有效期'), type: "warning" });
      } else {
        for (let idx = 0; idx < this.attrs.length; idx++) {
          var attr = this.attrs[idx];
          if (attr.name == "") {
            var message = this.$t('m.请输入属性&(1)的属性').replace('&(1)', (idx + 1));
            this.$message({ message: message, type: "warning" });
            return;
          }
          if (attr.label == "") {
            var message = this.$t('m.请输入属性&(1)的显示名称').replace('&(1)', (idx + 1));
            this.$message({ message: message, type: "warning" });
            return;
          }
        }
        this.httpAddSignal();
      }
    },

    //复制
    copyClick(text) {
      var textarea = document.createElement("input"); //创建input对象
      var currentFocus = document.activeElement; //当前获得焦点的元素
      document.body.appendChild(textarea); //添加元素
      textarea.value = text;
      textarea.focus();
      if (textarea.setSelectionRange) {
        textarea.setSelectionRange(0, textarea.value.length); //获取光标起始位置到结束位置
      } else {
        textarea.select();
      }
      var flag;
      try {
        flag = document.execCommand("copy"); //执行复制
      } catch (eo) {
        flag = false;
      }
      document.body.removeChild(textarea); //删除元素
      currentFocus.focus();
      this.$message.success(flag ? this.$t('m.复制成功') : this.$t('m.复制失败'));
    },

    // 删除
    remAttrClick(idx) {
      console.log(idx);
      console.log(this.attrs);
      this.attrs.splice(idx, 1);
    },

    createSignalTips() {
      this.$alert(
        "<div>" + this.$t('m.服务器接收到信号后开始推送并计时， 超时后信号失效并停止推送。') + "</div>",
        "",
        {
          confirmButtonText: this.$t('m.确定'),
          dangerouslyUseHTMLString: true,
        }
      );
    },

    // 跳信号订阅教程
    toOperationManual() {
      location.href;
      // https://shimo.im/docs/dPCWphVkPwGHcPcK/read
      console.log(1);
      // window.open='https://shimo.im/docs/dPCWphVkPwGHcPcK/read'
      window.open(
        "https://shimo.im/docs/CYx8gpgkr9CxcH8d/",
        "_blank",
        "width=1500,height=800,top=100px,left=0px"
      );
    },
    //日期换算
    timestampToTime(timestamp) {
      var date = new Date(timestamp); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
      var Y = date.getFullYear() + "-";
      var M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      var D =
        date.getDate() < 10
          ? "0" + date.getDate() + " "
          : +date.getDate() + " ";
      var h =
        date.getHours() < 10
          ? "0" + date.getHours() + ":"
          : date.getHours() + ":";
      var m =
        date.getMinutes() < 10
          ? "0" + date.getMinutes() + ":"
          : date.getMinutes() + ":";
      var s =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      return Y + M + D + h + m + s;
    },
  },
  mounted() {
    let project = JSON.parse(sessionStorage.getItem("project"));
    this.project = project;
    let defaultTeam = JSON.parse(sessionStorage.getItem("defaultTeam"));
    this.defaultTeam = defaultTeam;
    if (sessionStorage.getItem("Now")) {
      this.tab = sessionStorage.getItem("Now");
    }
    this.httpSignalList();
  },
};
</script>

<style lang='scss' scoped>
.Signal * {
  box-sizing: border-box;
}
.bg * {
  display: flex;
}
.dialog-container * {
  display: flex;
}

.Signal {
  width: 100%;
  height: 100%;
  .el-container {
    height: 100%;
    font-size: 16px;
    font-weight: 500;
    color: #102859;
    .bg {
      flex: 1;
      // background-color: #2174ff;
      width: 1680px;
      .container {
        flex-direction: column;
        background-color: #ffffff;
        height: 100%;
        // max-width: 1400px;
        border-radius: 8px;
        padding: 20px;
        margin: 0 auto;

        .header {
          align-items: center;
          justify-content: space-between;
          margin-bottom: 20px;
          .add {
            cursor: pointer;
            align-items: center;
            background-color: #2174ff;
            height: 34px;
            border-radius: 4px;
            padding: 0 20px 0 20px;
            font-size: 14px;
            color: #ffffff;
            .icon-add {
              width: 14px;
              height: 14px;
              margin-right: 4px;
            }
          }
          .course {
            cursor: pointer;
            font-size: 14px;
            color: #2174ff;
          }
        }

        .el-tabs {
          display: block;
          height: 40px;
          ::v-deep.el-tabs__item {
            padding: 0;
            min-width: 90px;
            color: #55698a;
            text-align: center;
          }
          ::v-deep.el-tabs__item.is-active {
            color: #2174ff;
          }
          ::v-deep.el-tabs__nav-wrap::after {
            background-color: #e6e9ee;
          }
          ::v-deep.el-tabs__active-bar {
            background-color: #2174ff;
          }
        }

        .scroll {
          flex: 1;
          flex-direction: column;
          overflow-y: scroll;
          padding-top: 20px;
          .cell {
            flex-direction: column;
            background-color: #f7f7f7;
            border-radius: 8px;
            padding: 30px;
            margin-bottom: 20px;
            .row {
              align-items: center;
              margin-top: 20px;
              .column {
                flex: 1;
                width: 1px;
                flex-direction: column;
                margin-right: 10px;
                .text {
                  font-weight: 400;
                  color: #55698a;
                }
                .single {
                  white-space: nowrap; //强制只显示1行
                  overflow: hidden; //超出隐藏
                  text-overflow: ellipsis; //超出省略号显示
                  display: inline-block; //快级元素
                  margin: 6px 0;
                }
                .time {
                  font-size: 12px;
                  color: #55698a;
                }
              }
            }
            .but {
              cursor: pointer;
              font-size: 14px;
              color: #2174ff;
            }
          }
          .no-signal {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-top: 200px;
            img {
              width: 200px;
              height: 140px;
              margin-bottom: 20px;
            }
            .text {
              font-size: 14px;
              color: #55698a;
            }
          }
        }
      }
    }
  }

  .dialog-container {
    overflow-y: scroll;
    max-height: 500px;
    padding: 20px 30px 40px;
    border-top: 1px solid #e6e9ee;
    border-bottom: 1px solid #e6e9ee;
    font-size: 16px;
    font-weight: 500;
    color: #102859;
    .but {
      cursor: pointer;
      color: #2174ff;
      white-space: nowrap; //强制只显示1行
    }
    .line {
      height: 1px;
      background-color: #e6e9ee;
      margin: 20px 0;
    }
    .row {
      align-items: center;
    }
    .column {
      flex-direction: column;
    }
    .flex1 {
      flex: 1;
    }
    .width20 {
      width: 20px;
    }
    .width30 {
      width: 30px;
    }
    .height10 {
      height: 10px;
    }
    .height20 {
      height: 20px;
    }
    .height30 {
      height: 30px;
    }
    .icon-arrow {
      width: 24px;
      height: 10px;
      margin: 0 20px;
      // background-color: #2174ff;
      margin-top: 30px;
    }

    ::v-deep.el-input__inner {
      flex: 1;
      font-size: 16px;
      font-weight: 500;
      color: #102859;
    }
    ::v-deep.el-textarea__inner {
      flex: 1;
      font-size: 16px;
      font-weight: 500;
      color: #102859;
    }
    .edit-no {
      flex: 1;
      ::v-deep.el-input__inner {
        flex: 1;
        background-color: #f7f7f7;
        color: #102859;
        border: none;
        padding: 10px;
      }
      ::v-deep.el-textarea__inner {
        flex: 1;
        background-color: #f7f7f7;
        color: #102859;
        border: none;
        padding: 10px;
      }
    }
    ::v-deep.el-icon-warning-outline {
      cursor: pointer;
      margin-left: 4px;
    }
    .unit {
      height: 40px;
      line-height: 40px;
      font-size: 16px;
      font-weight: 500;
      color: #102859;
      margin-right: 10px;
    }
  }

  ::v-deep.el-dialog__header {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 47px;
    padding: 0;
    border-width: 1px;
    .el-dialog__title {
      font-size: 16px;
      font-weight: 500;
      color: #102859;
    }
  }
  ::v-deep.el-dialog__body {
    padding: 0;
  }
  ::v-deep.el-dialog__footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 53px;
    padding: 0 53px;
    .el-button {
      height: 34px;
      border-radius: 4px;
      font-size: 14px;
      font-weight: 400;
      padding: 0 20px;
      
    }
    .el-button--default {
      cursor: pointer;
      border: 1px solid #c2d9ff;
      color: #2174ff;
    }
    .el-button--primary {
      cursor: pointer;
      color: #ffffff;
    }
  }
}
</style>