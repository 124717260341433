<template>
  <!-- 启动策略 -->
  <div class="startUp">
    <el-container>
      <el-header>
        <div class="header-warp">
          <i class="el-icon-arrow-left" @click="goBack"></i>
          <span>
            <span>{{ templateName }}</span>
          </span>
          <span></span>
        </div>
      </el-header>
      <el-container>
        <!-- main -->
        <el-main>
          <div class="content">
            <div class="content-tamplate">
              <!-- 策略 -->
              <div class="strategy">
                <p class="strategy-title">{{$t('m.策略配置')}}</p>
                <!-- 策略 -->
                <div class="strategy-box">
                  <span class="strategy-box-label">{{$t('m.选择策略：')}}</span>
                  <div class="strategy-box-text">
                    <el-select
                      v-model="strategyId"
                      :placeholder="$t('m.请选择策略')"
                      @change="strategySelect">
                      <el-option
                        v-for="(item, idx) in strategyList"
                        :key="idx"
                        :label="
                          $t('m.'+item.strategyName) +
                          '( ' +
                          (item.type == 'spot' ? $t('m.现货') : $t('m.合约')) +
                          ' )'
                        "
                        :value="item.strategyId">
                      </el-option>
                    </el-select>
                  </div>
                </div>
                <!-- 交易所 -->
                <div class="strategy-box">
                  <span class="strategy-box-label">{{$t('m.选择交易所：')}}</span>
                  <div class="strategy-box-text">
                    <el-input v-model="exchangeName" :readonly='true' :placeholder="$t('m.请输入内容')"></el-input>
                  </div>
                </div>
                <!-- 模式 -->
                <div
                  class="strategy-box"
                  v-if="strategyId != 'CL20181115155643599x'">
                  <span class="strategy-box-label">{{$t('m.选择模式：')}}</span>
                  <div class="strategy-box-text">
                    <span
                      class="strategy-box-text-cont"
                      :class="[modeSelection ? 'true' : 'false']"
                      @click="modelSelect(true)">
                      {{$t('m.自定义模式')}}</span>
                    <span
                      class="strategy-box-text-cont"
                      :class="[!modeSelection ? 'true' : 'false']"
                      @click="modelSelect(false)">
                      {{$t('m.多币对模式')}}</span>
                  </div>
                </div>
                <!-- 交易对 -->
                <div class="strategy-box">
                  <span class="strategy-box-label">{{$t('m.选择交易对：')}}</span>
                  <div class="strategy-box-text tag" @click="selectPair">
                    <template v-if="pair.length != 0">
                      <el-tag
                        v-for="(item, idx) in pair"
                        closable
                        :key="idx"
                        @close="tagClose(item)"
                        >{{ item.counterCoin + "/" + item.baseCoin }}</el-tag>
                    </template>
                  </div>
                </div>
                <!-- 策略方向 -->
                <div
                  class="strategy-box"
                  v-if="
                    strategyId != 'CL20181115155643599x' &&
                    strategyId != 'WL20190402171936974x'
                  ">
                  <span class="strategy-box-label">{{$t('m.策略方向')}}：</span>
                  <div class="strategy-box-text">
                    <span
                      class="strategy-box-text-cont"
                      :class="[!strategicDirection ? 'true' : 'false']"
                      @click="directionSelect(false)">
                      {{$t('m.赚本币（看多）')}}</span>
                    <span
                      class="strategy-box-text-cont"
                      :class="[strategicDirection ? 'true' : 'false']"
                      @click="directionSelect(true)">
                      {{$t('m.赚交易币（看空）')}}</span>
                  </div>
                </div>
              </div>
              <!-- 参数 -->
              <div class="parameter">
                <p class="parameter-title">{{$t('m.参数配置')}}</p>

                <template v-for="(item, idx) in reserves">
                  <div
                    class="parameter-box"
                    v-if="
                      (item.visiable == '1' ||
                        strategyId == 'CL20181115155643599x' ||
                        strategyId == 'WL20190402171936974x') &&
                      item.name != '定投类型'
                    "
                    :key="idx">
                    <template v-if="item.name != '定投周期'">
                      <div class="parameter-box-label">
                        <span>{{ item.name }}</span>
                        <span v-if="item.valueMin || item.start">{{
                          $t('m.范围：') +
                          (item.valueMin != "" &&
                          item.valueMin &&
                         ( item.valueMin != 0|| item.valueMax != 0)
                            ? item.valueMin
                            : item.start) +
                          "~" +
                          (item.valueMax != "" &&
                          item.valueMax &&
                         ( item.valueMin != 0|| item.valueMax != 0)
                            ? item.valueMax
                            : item.end)
                        }}</span>
                      </div>
                      <div class="parameter-box-text">
                        <input
                          type="number"
                          :placeholder="$t('m.请输入内容')"
                          v-model="item.value"
                          @blur="inputCheck($event, item, idx)"
                        />
                      </div>
                    </template>
                    <template v-else>
                      <div class="parameter-box-label">
                        <span>{{ item.name }}</span>
                        <span v-if="item.valueMin || item.start">{{
                          $t('m.范围：') +
                          (item.valueMin != "" && item.valueMin
                            ? item.valueMin
                            : item.start) +
                          "~" +
                          (item.valueMax != "" && item.valueMax
                            ? item.valueMax
                            : item.end)
                        }}</span>
                      </div>
                      <div class="parameter-box-text">
                        <div class="block">
                          <el-cascader
                            v-model="fixedTime"
                            :options="times"
                            @change="handleChange"
                          ></el-cascader>
                        </div>
                      </div>
                    </template>
                  </div>
                </template>
              </div>
              <!-- 配置投入 -->
              <div class="investment">
                <div class="investment-title">
                  <span>{{$t('m.配置投入')}}</span>
                  <span>{{
                    "范围值" + minInvest + "~" + maxInvest + " USDT"
                  }}</span>
                </div>
                <el-tabs v-model="activeName" @tab-click="handleClick">
                  <el-tab-pane :label="$t('m.固定投入金额')" name="1">
                    <div class="investment-box">
                      <p>{{$t('m.固定投入金额')}}</p>
                      <el-input
                        :placeholder="$t('m.请输入投入金额')"
                        v-model="fixedInvestment"
                        type="number"
                      >
                        <template slot="append">USDT</template>
                      </el-input>
                      <div class="available">
                       {{ $t('m.可用余额：') + Math.floor(Number(availableNum)*10000)/10000 + " USDT" }}
                      </div>
                    </div></el-tab-pane
                  >
              
                </el-tabs>
                <div class="remarks">
                  {{$t('m.满足比例，但低于下限不投； 满足比例，但高于上限的按上限投；')}}
                  {{$t('m.比例投入或固定投入需满足策略本身投入限制，高于策略限制上限按策略上限投。')}}
                </div>
              </div>
            </div>
            <div class="btn">
              <span @click="createTemplate">{{$t('m.启动策略')}}</span>
            </div>
          </div>
        </el-main>
      </el-container>
    </el-container>
    <!-- 修改名称 -->
    <el-dialog
      :title="$t('m.修改模板名称')"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      <el-input v-model="templateName" :placeholder="$t('m.请输入内容')"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false">
          {{$t('m.确 定')}}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  strategyLibrary,
  getExchangeListByType,
  getStartPage,
  start,
} from "../../request/api";
import { times } from "../../assets/js/time";
export default {
  components: {},
  data() {
    return {
      input2: "",
      activeName: "1",
      project: null,
      defaultTeam: null,
      strategyList: [], //策略列表
      strategyName:null,
      exchangeList: [], //交易所列表
      strategyId: null, //策略ID
      exchangeName: null, //交易所
      exchangeId: null,
      pair: [], //交易对
      modeSelection: true, //模式  true  自定义
      strategicDirection: false, //策略方向  false  本币

      configMap: null, //策略配置
      limitMap: null, //投入限制
      pairMap: null, //交易对
      reserves: null, //策略参数
      params: null, //备份参数
      fixedInvestment: null, //固定投入
      Percentage: null, //百分比
      minInvest: 0, //投入min
      maxInvest: 0, // 投入max

      min: null, //投入下限
      max: null, //投入上限
      templateName: this.$t('m.启动策略'),
      dialogVisible: false,
      paramsType: true, //参数校验
      id: null,
      times: times,
      fixedTime: [1, 7],
      availableNum: 0,
    };
  },
  computed: {},
  watch: {},
  methods: {
    // 返回上一页
    goBack() {
      this.clear();
      this.$router.back(-1);
    },
    handleClick(tab, event) {
      // console.log(tab, event);
      console.log(this.activeName);
    },
    // 关闭弹窗
    handleClose(done) {
      done();
    },
    // 查询策略
    strategyLibrary() {
      let payload = {
        token: sessionStorage.getItem("token"),
      };
      strategyLibrary(payload).then((res) => {
        if (res.status == 200) {
          this.strategyList = res.data.publicStrategys;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 查询交易所
    getExchangeList() {
      let payload = {
        token: sessionStorage.getItem("token"),
        teamProjectId: this.project.teamProjectId,
      };
      getExchangeListByType(payload).then((res) => {
        if (res.status == 200) {
          this.exchangeList = res.data.exchangeList;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 跳交易对页
    selectPair() {
      if (!this.exchangeId) {
        this.$message.warning(this.$t('m.请先选择交易所'));
        return;
      }
      this.$router.push({
        path: "selectTradingPairs",
        query: { exchangeId: this.exchangeId, pair: this.pair },
      });
    },
    // 删除交易对
    tagClose(v) {
      let list = this.pair;
      for (let i = 0; i < list.length; i++) {
        if (list[i].pairId == v.pairId) {
          list.splice(i, 1);
          this.pair = list;
          return;
        }
      }
      console.log(v);
    },
    // 策略选择
    strategySelect(v) {
      console.log(v);
      this.payloadCheck();
    },
    // 交易所选择
    exchangeSelect(v) {
      console.log(v);
      let list = this.exchangeList;
      let newList = list.filter(function (item) {
        return item.exchangeName == v;
      });
      this.exchangeId = newList[0].exchangeId;
      this.payloadCheck();
    },
    // 模式选择
    modelSelect(v) {
      this.modeSelection = v;
      console.log(v);
      this.payloadCheck();
    },
    // 方向选择
    directionSelect(v) {
      this.strategicDirection = v;
      console.log(v);
      this.payloadCheck();
    },
    // 查询策略配置校验
    payloadCheck() {
      if (!this.strategyId) {
        return;
      } else if (!this.exchangeName) {
        return;
      } else if (this.pair.length == 0) {
        return;
      }
      this.getStartPage();
    },
    // 查询策略配置
    getStartPage() {
      let payload = {
        token: sessionStorage.getItem("token"),
        strategyId: this.strategyId,
        model: this.modeSelection ? "define" : "manyPair",
        investType: this.strategicDirection,
        exchangeName: this.exchangeName,
        counterCoin: this.pair[0].counterCoin,
        baseCoin: this.pair[0].baseCoin,
      };
      console.log(payload);
      getStartPage(payload).then((res) => {
        if (res.status == 200) {
          // this.exchangeList = res.data.exchangeList;

          this.configMap = JSON.parse(res.data.configMap);
          this.limitMap = res.data.limitMap;
          this.pairMap = res.data.pairMap;
          this.reserves = this.configMap.reserves;
          this.params = JSON.parse(JSON.stringify(this.configMap.reserves));
          this.minInvest = res.data.limitMap.minInvest;
          this.maxInvest = res.data.limitMap.maxInvest;
          this.min = res.data.limitMap.minInvest;
          this.max = res.data.limitMap.maxInvest;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 输入校验
    inputCheck(e, item, index) {
      console.log(item, index);
      let value = item.value;
      let min =
        item.valueMin && (item.valueMin != 0||item.valueMax != 0) ? item.valueMin : item.start;
      let max = item.valueMax && (item.valueMin != 0||item.valueMax != 0)? item.valueMax : item.end;
      console.log(item, item.value, max, min);
      console.log(value === "");
      if (value === "") {
        this.$message.error(this.$t('m.参数不能为空'));
        e.target.style.border = "1px solid #F23A4C";
        this.paramsType = false;
        return;
      } else if (min != "" && Number(value) < min&&this.strategyId != "CL20181115155643599x"&&this.strategyId!='WL20190402171936974x') {
        this.$message.error(this.$t('m.参数不能小于最低范围'));
        e.target.style.border = "1px solid #F23A4C";
        console.log(4);
        this.paramsType = false;
        return;
      } else if (max != "" && Number(value) > max&&this.strategyId != "CL20181115155643599x"&&this.strategyId!='WL20190402171936974x') {
        this.$message.error(this.$t('m.参数不能大于最大范围'));
        e.target.style.border = "1px solid #F23A4C";
        console.log(5);
        this.paramsType = false;
        return;
      } else if (
        (item.name == "价格上限" || item.name == "价格下限") &&
        Number(value) < 0
      ) {
        this.$message.error(this.$t('m.价格上限/价格下限不能小于0'));
        e.target.style.border = "1px solid #F23A4C";
        console.log(5);
        this.paramsType = false;
        return;
      } else {
        e.target.style.border = "1px solid #DCDFE6";
        console.log(6);
        this.params[index].value = value;
        this.paramsType = true;
      }
    },
    // 定投参数
    handleChange(value) {
      this.fixedTime = value;
    },
    // 参数二次校验
    secondaryCheck(item) {
      for (let key in item) {
        let min =
          item[key].valueMin && (item[key].valueMin != 0||item[key].valueMax != 0)
            ? item[key].valueMin
            : item[key].start;
        let max =
          item[key].valueMax && (item[key].valueMin != 0||item[key].valueMax != 0)
            ? item[key].valueMax
            : item[key].end;
        let value = item[key].value;

        if (item[key].visiable == "1" && value === "") {
          console.log(item[key], value);
          this.paramsType = false;
          return;
        } else if (min != "" && Number(value) < min&&this.strategyId != "CL20181115155643599x"&&this.strategyId!='WL20190402171936974x') {
          console.log(item[key]);
          this.paramsType = false;
          return;
        } else if (max != "" && Number(value) > max&&this.strategyId != "CL20181115155643599x"&&this.strategyId!='WL20190402171936974x') {
          console.log(item[key]);
          this.paramsType = false;
          return;
        } else if (
          (item[key].name == "价格上限" || item[key].name == "价格下限") &&
          Number(value) < 0
        ) {
          console.log(item[key]);
          this.paramsType = false;
          return;
        } else {
          this.paramsType = true;
        }
      }
    },
    //启动策略
    createTemplate() {
      if (!this.strategyId) {
        this.$message.error(this.$t('m.请先选择策略'));
        return;
      } else if (!this.exchangeName) {
        this.$message.error(this.$t('m.请先选择交易所'));
        return;
      } else if (this.pair.length == 0) {
        this.$message.error(this.$t('m.请选择交易对'));
        return;
      } else if (this.modeSelection && this.pair.length > 1) {
        this.$message.error(this.$t('m.当前选择模式为自定义模式，交易对数量不能超过1个'));
        return;
      } else if (!this.paramsType) {
        this.$message.error(this.$t('m.参数配置不正确'));
        return;
      }
      this.secondaryCheck(this.params);
      let strategyId = this.strategyId;
      let params = this.params;
      let status = true;
      console.log(strategyId);
      if (strategyId == "CL20181115155003478x") {
        params.filter((val) => {
          if (val.name == "单格投入" && val.value == 0) {
            this.$message.error(this.$t('m.单格投入不能为0'));
            status = false;
          }
        });
      } else if (strategyId == "WL202007311426363421x") {
        params.filter((val) => {
          if (val.name == "首单投入" && val.value == 0) {
            this.$message.error(this.$t('m.首单投入不能为0'));
            status = false;
          }
        });
      } else if (strategyId == "WL20200717202249188x") {
        params.filter((val) => {
          if (val.name == "首单金额" && val.value == 0) {
            this.$message.error(this.$t('m.首单金额不能为0'));
            status = false;
          }
        });
      } else if (strategyId == "WL20190402171936974x") {
        params.filter((val) => {
          if ((val.name == "单笔金额" && val.value == 0) || !val.value) {
            this.$message.error(this.$t('m.单笔金额不能为0'));
            status = false;
          }
        });
      }
      if (!status) {
        return;
      }

      // 投入
      if (this.activeName == "1") {
        if (!this.fixedInvestment || !this.fixedInvestment === "") {
          this.$message.error(this.$t('m.请输入投入金额'));
          return;
        } else if (this.fixedInvestment < Number(this.minInvest)) {
          this.$message.error(this.$t('m.投入金额不能低于最小范围'));
          return;
        } else if (this.fixedInvestment > Number(this.maxInvest)) {
          this.$message.error(this.$t('m.投入金额不能大于最大范围'));
          return;
        } else if (this.fixedInvestment > Number(this.availableNum)) {
          this.$message.error(this.$t('m.可用余额不足'));
          return;
        }
      } else {
        if (this.Percentage <= 0) {
          this.$message.error(this.$t('m.投入百分比不能小于0%'));
          return;
        } else if (this.Percentage > 100) {
          this.$message.error(this.$t('m.投入百分比不能大于100%'));
          return;
        } else if (this.min < Number(this.minInvest)) {
          this.$message.error(this.$t('m.投入下限不能低于最小范围'));
          return;
        } else if (this.max > Number(this.maxInvest)) {
          this.$message.error(this.$t('m.投入上限不能大于最大范围'));
          return;
        }
      }

      if (this.strategyId == "WL20190402171936974x") {
        this.params[0].value = this.fixedTime[1];
        this.params[1].value = this.fixedTime[0];
        // console.log(value, this.fixedTime);
      }
      this.pairMap.investCoin = this.pairMap.baseCoin;
      this.pairMap.exchange = this.pairMap.exchangeName;
      this.pairMap.id = this.configMap.tradePairs[0].id;
      this.pairMap.api = true;

      
      for(let key in this.strategyList){
        if(this.strategyId==this.strategyList[key].strategyId){
          this.strategyName=this.strategyList[key].strategyName
        }
      }
      let payload = {
        token: sessionStorage.getItem("token"),
        teamProjectId: this.project.teamProjectId,
        publicId: this.id, //操作员ID
        strategyId: this.strategyId,
        strategyName:this.strategyName,
        config: {
          isConvert: true,
          cancelOrder: true,
          stopLoss: "",
          stopTime: "",
          stopProfit: "",
          basic: this.configMap.basic,
          tradePairs: [this.pairMap],
          reserves: this.params,
        },
        investConfig: {
          fixInvest: this.fixedInvestment,
          proportionInvest: {
            proportion: this.Percentage,
            maxInvest: this.max,
            minInvest: this.min,
          },
        },
      };

      let pairs = [];
      let list = this.pair;
      if (!this.modeSelection) {
        for (let i = 0; i < list.length; i++) {
          let item = {};
          item.counterCoin = list[i].counterCoin;
          item.baseCoin = list[i].baseCoin;
          pairs.push(item);
        }
        payload.config.pairs = pairs;
      }
      console.log(payload);
      start(payload).then((res) => {
        if (res.status == 200) {
          this.$message.success(this.$t('m.启动成功'));
          this.$router.back(-1);
          // this.$destroy();
          this.clear();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    clear() {
      this.strategyId = null;
      this.exchangeName = null;
      this.pair = [];
      this.modeSelection = true;
      this.strategicDirection = false;
      this.reserves = null;
      this.fixedInvestment = null;
      this.paramsType = true;
    },
  },
  created() {},
  mounted() {},
  beforeCreate() {},
  beforeMount() {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {},
  activated() {
    let project = JSON.parse(sessionStorage.getItem("project"));
    let defaultTeam = sessionStorage.getItem("defaultTeam");
    this.defaultTeam = JSON.parse(defaultTeam);
    this.project = project;
    this.id = this.$route.query.id;
    this.exchangeName=this.$route.query.exchangeName
    this.exchangeId=this.$route.query.exchangeId
    
    this.availableNum = this.$route.query.availableNum;
    this.strategyLibrary();
    this.getExchangeList();
    console.log(project);

    let pair = JSON.parse(sessionStorage.getItem("pair"));
    if (pair && pair.length != 0) {
      for (let i = 0; i < pair.length; i++) {
        this.pair.push(pair[i]);
      }
      sessionStorage.removeItem("pair");
      this.payloadCheck();
    }
    console.log(this.pair);
  },
};
</script>
<style lang='scss' scoped>
.startUp {
  width: 100%;
  height: 100%;
  .el-container {
    height: 100%;
    background: #eff2f5;
  }
  .el-header {
    background-color: #fff;
    color: #333;
    text-align: center;
    line-height: 60px;
    margin-bottom: 20px;
    .header-warp {
      display: flex;
      justify-content: space-between;
      align-items: center;
      i {
        cursor: pointer;
        margin-left: 5px;
      }
    }
  }
  .el-main {
    color: #333;
    padding: 0 20px;
    font-size: 14px;
    .content {
      min-height: 100%;
      background: #fff;
      border-radius: 8px;
      padding: 0 20px;
      ::v-deep.el-input__inner {
        height: 30px;
        line-height: 30px;
      }
      ::v-deep.el-input__icon {
        line-height: 30px;
      }
      .content-tamplate {
        display: flex;
        justify-content: space-between;
        // 策略
        .strategy {
          width: 30%;
          padding-top: 20px;
          .strategy-title {
            color: #333;
            margin-bottom: 20px;
          }
          .strategy-box {
            margin-bottom: 30px;
            display: flex;
            // justify-content: space-between;
            align-items: center;
            .strategy-box-label {
              width: 25%;
              text-align: right;
            }
            .strategy-box-text {
              width: 75%;
              display: flex;
              justify-content: space-between;

              ::v-deep.el-select {
                width: 100%;
                margin-right: 0 !important;
              }

              ::v-deep.el-input__inner {
                cursor: pointer;
              }
              ::v-deep.el-input__suffix-inner {
                // margin-right: 0 !important;
              }
              .strategy-box-text-cont {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 45%;
                height: 26px;
                font-size: 12px;
                border-radius: 4px;
                cursor: pointer;
              }
              .true {
                color: #fff;
                background: #007aff;
                border: 1px solid #007aff;
              }
              .false {
                color: #999;
                border: 1px solid #c4c6cf;
              }
            }
            .tag {
              min-height: 26px;
              display: flex;
              justify-content: flex-start;
              flex-wrap: wrap;
              border: 1px solid #dcdfe6;
              padding: 2px;
              border-radius: 4px;
              cursor: pointer;
              .el-tag {
                margin: 2px 10px;
              }
            }
          }
        }
        // 参数
        .parameter {
          width: 30%;
          font-size: 14px;
          padding-top: 20px;
          .parameter-title {
            color: #333;
            margin-bottom: 20px;
            //   font-size: 12px;
          }
          .parameter-box {
            display: flex;
            align-items: center;
            margin-bottom: 20px;
            .parameter-box-label {
              width: 25%;
              display: flex;
              flex-direction: column;
              :nth-child(2) {
                color: #999;
              }
            }
            .parameter-box-text {
              width: 75%;
              .el-cascader {
                width: 100%;
              }
              input {
                width: 100%;
                height: 30px;
                line-height: 30px;
                border-radius: 4px;
                border: 1px solid #dcdfe6;
                text-indent: 10px;
                color: #666;
                // outline-color:#409EFF;
                // outline-width:0.5px;
                outline: none;
              }
            }
          }
        }
        //  配置投入
        .investment {
          width: 30%;
          padding-top: 20px;
          .investment-title {
            margin-bottom: 10px;
            :last-child {
              font-size: 12px;
              color: #999;
              margin-left: 8px;
            }
          }
          .investment-box {
            p {
              font-size: 12px;
              color: #666;
              margin: 10px 0;
            }
            .available {
              color: #666;
              font-size: 12px;
              // text-align: right;
              margin-top: 5px;
            }
          }
          .remarks {
            font-size: 12px;
            color: #999;
            margin-top: 20px;
          }
        }
      }
      .btn {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 60px;
        padding-bottom: 30px;
        span {
          border-radius: 4px;
          color: #fff;
          font-size: 14px;
          padding: 5px 30px;
          background: #007aff;
          cursor: pointer;
        }
      }
    }
  }
}
</style>