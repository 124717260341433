<template>
  <!-- 用户管理 -->
  <div class="userManagement">
    <div class="content">
      <!-- 顶部数据 -->
      <div class="content-top">
        <div class="content-top-title">
          
          <span>{{ $t('m.当前策略数：') + topData.topExecutionNumber }}</span>

          <span>{{
            $t('m.当前总资金(USDT)：') +
            Math.floor(Number(topData.topInvestCoinNum) * 10000) / 10000
          }}</span>
          <span>{{
            $t('m.当前总投入(USDT)：') +
            Math.floor(Number(topData.topAlreadyDevoted) * 10000) / 10000
          }}</span>
          <span>{{
            $t('m.当前总收益(USDT)：') +
            Math.floor(Number(topData.topSumFfloatEarnings) * 10000) / 10000
          }}</span>
          <span>{{
            $t('m.平均总年化：') +
            Math.floor(Number(topData.topAvgSumYearIncome) * 100) / 100
          }}</span>
          <span>{{ 
            $t('m.未执行策略用户：') + topData.topNoExecutionUser }}</span>
        </div>
      </div>

      <div class="tabs-warp">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-form
            status-icon
            ref="ruleForm"
            label-width="100px"
            class="demo-ruleForm"
          >
            <div class="content-top-box-warp" v-if="open">
              <div class="content-top-box">
                <el-form-item :label="$t('m.交易所')">
                  <el-select v-model="exchangeName" :placeholder="$t('m.请选择交易所')">
                    <el-option
                      :label="item.exchangeName"
                      :value="item.exchangeName"
                      v-for="(item, idx) in exchangeList"
                      :key="idx"
                    ></el-option>
                  </el-select>
                </el-form-item>

                <el-form-item :label="$t('m.操作员')">
                  <el-select v-model="operator" :placeholder="$t('m.请选择操作员')">
                    <el-option
                      :label="item.nickName"
                      :value="item.userId"
                      v-for="(item, idx) in operatorList"
                      :key="idx"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </div>
              <!-- two -->
              <div class="content-top-box">
                <el-form-item :label="$t('m.入金时间')">
                  <el-date-picker
                    v-model="time"
                    type="datetimerange"
                    range-separator="-"
                    :start-placeholder="$t('m.开始时间')"
                    :end-placeholder="$t('m.结束时间')" >
                  </el-date-picker>
                </el-form-item>

                <el-form-item
                  :label="$t('m.剩余可用')"
                  prop="checkPass"
                  v-if="activeName == 1">
                  <div class="Invested">
                    <el-input v-model="availableNumStrat" autocomplete="off">
                      <template slot="append">USDT</template>
                    </el-input>
                    <span class="line">-</span>
                    <el-input v-model="availableNumEnd" autocomplete="off">
                      <template slot="append">USDT</template>
                    </el-input>
                  </div>
                </el-form-item>
                <el-form-item :label="$t('m.退出时间')" v-else>
                  <el-date-picker
                    v-model="endTime"
                    type="datetimerange"
                    range-separator="-"
                    :start-placeholder="$t('m.开始时间')"
                    :end-placeholder="$t('m.结束时间')"
                  >
                  </el-date-picker>
                </el-form-item>
              </div>
              <div class="content-top-box">
                <el-form-item :label="$t('m.总投入')">
                  <div class="Invested">
                    <el-input v-model="investCoinNumStart" autocomplete="off">
                      <template slot="append">USDT</template>
                    </el-input>
                    <span class="line">-</span>
                    <el-input v-model="investCoinNumEnd" autocomplete="off">
                      <template slot="append">USDT</template>
                    </el-input>
                  </div>
                </el-form-item>
              </div>
            </div>

            <el-form-item>
              <div class="btn">
                <span></span>
                <div class="Expand-collapse">
                  <div @click="isOpen()">
                    <span v-if="open">{{$t('m.收起')}}</span>
                  </div>
                  <div @click="isOpen()">
                    <span v-if="!open">{{$t('m.展开')}}</span>
                  </div>
                </div>
                <div class="operation">
                  <el-button @click="resetForm()" v-if="open">{{$t('m.重置')}}</el-button>
                  <el-button type="primary" @click="submitForm()" v-if="open">
                    {{$t('m.查询')}}</el-button>
                  <el-button
                    type="primary"
                    @click="confrom()"
                    v-if="role && role.roleName != 'OPERATOR' && activeName == '1'">
                    {{$t('m.迁移用户')}}</el-button>
                </div>
              </div>
            </el-form-item>
          </el-form>

          <el-tab-pane :label="$t('m.当前用户')" name="1">
            <div class="content-bottom">
              <el-table
                ref="multipleTable"
                :data="userList"
                tooltip-effect="dark"
                style="width: 100%"
                @selection-change="handleSelectionChange"
              >
                <el-table-column type="selection" width="55"> </el-table-column>
                <el-table-column
                  prop="publicCode"
                  :label="$t('m.用户')"
                  width="130"
                ></el-table-column>

                <el-table-column
                  :label="$t('m.入金时间')"
                  prop="createTime"
                  width="150"
                  sortable>
                  <template slot-scope="scope">{{
                    moment(scope.row.createTime).format("YYYY-MM-DD HH:mm:ss")
                  }}</template>
                </el-table-column>

                <el-table-column
                  prop="investCoinNum"
                  :label="$t('m.总资金(USDT)')"
                  width="160"
                  sortable></el-table-column>

                <el-table-column
                  prop="alreadyDevoted"
                  :label="$t('m.已投入(USDT)')"
                  width="170"
                  sortable>
                  <template slot-scope="scope">{{
                    Math.floor(Number(scope.row.alreadyDevoted) * 10000) / 10000
                  }}</template>
                </el-table-column>

                <el-table-column
                  prop="availableNum"
                  :label="$t('m.剩余可用(USDT)')"
                  width="150"
                  sortable>
                  <template slot-scope="scope">{{
                    Math.floor(Number(scope.row.availableNum) * 10000) / 10000
                  }}</template>
                </el-table-column>

                <el-table-column
                  prop="executionNumber"
                  :label="$t('m.当前策略数')"
                  width="120"
                  sortable></el-table-column>

                <el-table-column
                  prop="sumFloatEarnings"
                  :label="$t('m.总收益(USDT)')"
                  width="140"
                  sortable>
                  <template slot-scope="scope">{{
                    scope.row.sumFloatEarnings
                      ? Math.floor(Number(scope.row.sumFloatEarnings) * 10000) /
                        10000
                      : "--"
                  }}</template>
                </el-table-column>
                <el-table-column
                  prop="sumFloatEarningsRate"
                  :label="$t('m.总收益率')"
                  width="120">
                  <template slot-scope="scope">{{
                    scope.row.sumFloatEarningsRate
                      ? Math.floor(
                          Number(scope.row.sumFloatEarningsRate) * 100
                        ) /
                          100 +
                        "%"
                      : "--"
                  }}</template>
                </el-table-column>
                <el-table-column
                  prop="sumYearIncome"
                  :label="$t('m.总年化')"
                  width="120">
                  <template slot-scope="scope">{{
                    scope.row.sumYearIncome
                      ? Math.floor(Number(scope.row.sumYearIncome) * 100) /
                          100 +
                        "%"
                      : "--"
                  }}</template>
                </el-table-column>

                <el-table-column width="120" prop="exchangeName" 
                :label="$t('m.交易所')">
                </el-table-column>


                <el-table-column width="130" prop="nickName" 
                :label="$t('m.操作员')">
                </el-table-column>

                <el-table-column :label="$t('m.操作')">
                  <template slot-scope="scope">
                    <div class="deposit-operation">
                      <span
                        @click="toDateil(scope.row)"
                        v-if="scope.row.states == 'EXECUTE'">
                        {{$t('m.详情')}}</span >
                      <span v-if="scope.row.states == 'STOPING'">
                        {{$t('m.结算中')}}</span>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
              <div class="box-pagination">
                <el-pagination
                  @size-change="handleSizeChange"
                  @current-change="handleCurrentChange"
                  :page-sizes="[10, 20, 30, 40]"
                  :page-size="pageSize"
                  layout="total, sizes, prev, pager, next, jumper"
                  :total="total"
                >
                </el-pagination>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane :label="$t('m.历史用户')" name="2">
            <div class="content-bottom">
              <el-table
                ref="multipleTable"
                :data="oldUserList"
                tooltip-effect="dark"
                style="width: 100%"
                @selection-change="handleSelectionChange"
              >
                <el-table-column type="selection" width="55"> </el-table-column>
                <el-table-column
                  prop="publicCode"
                  :label="$t('m.用户')"
                  width="130"
                ></el-table-column>
                <el-table-column
                  :label="$t('m.入金时间')"
                  prop="createTime"
                  width="150"
                  sortable
                >
                  <template slot-scope="scope">{{
                    moment(scope.row.createTime).format("YYYY-MM-DD HH:mm:ss")
                  }}</template>
                </el-table-column>
                <el-table-column
                  :label="$t('m.退出时间')"
                  prop="endTime"
                  width="150"
                  sortable
                >
                  <template slot-scope="scope">{{
                    moment(scope.row.endTime).format("YYYY-MM-DD HH:mm:ss")
                  }}</template>
                </el-table-column>
                <el-table-column
                  prop="investCoinNum"
                  :label="$t('m.总资金(USDT)')"
                  width="160"
                  sortable
                ></el-table-column>

                <el-table-column
                  prop="sumFloatEarnings"
                  :label="$t('m.总收益(USDT)')"
                  width="140"
                  sortable>
                  <template slot-scope="scope">{{
                    scope.row.sumFloatEarnings
                      ? Math.floor(Number(scope.row.sumFloatEarnings) * 10000) /
                        10000
                      : "--"
                  }}</template>
                </el-table-column>
                <el-table-column
                  prop="sumFloatEarningsRate"
                  :label="$t('m.总收益率')"
                  width="120"
                >
                  <template slot-scope="scope">{{
                    scope.row.sumFloatEarningsRate
                      ? Math.floor(
                          Number(scope.row.sumFloatEarningsRate) * 100
                        ) /
                          100 +
                        "%"
                      : "--"
                  }}</template>
                </el-table-column>
                <el-table-column
                  prop="sumYearIncome"
                  :label="$t('m.总年化')"
                  width="120"
                >
                  <template slot-scope="scope">{{
                    scope.row.sumYearIncome
                      ? Math.floor(Number(scope.row.sumYearIncome) * 100) /
                          100 +
                        "%"
                      : "--"
                  }}</template>
                </el-table-column>

                <el-table-column width="120" prop="exchangeName" 
                :label="$t('m.交易所')">
                </el-table-column>
                <el-table-column width="130" prop="nickName" 
                :label="$t('m.操作员')">
                </el-table-column>

                <el-table-column 
                :label="$t('m.操作')">
                  <template slot-scope="scope">
                    <div class="deposit-operation">
                      <span @click="toOldDateil(scope.row)">{{$t('m.详情')}}</span>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
              <div class="box-pagination">
                <el-pagination
                  @size-change="handleSizeChange"
                  @current-change="handleCurrentChange"
                  :page-sizes="[10, 20, 30, 40]"
                  :page-size="pageSize"
                  layout="total, sizes, prev, pager, next, jumper"
                  :total="total"
                >
                </el-pagination>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>

    <!-- 迁移用户弹窗 -->
    <el-dialog
      :title="$t('m.迁移用户')"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      <el-select v-model="operatorLater" :placeholder="$t('m.请选择操作员')">
        <el-option
          v-for="(item, idx) in operatorList"
          :key="idx"
          :label="item.nickName"
          :value="item.userId"
        >
        </el-option>
      </el-select>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancel">
          {{$t('m.取 消')}}</el-button>
        <el-button type="primary" @click="migrationUser">
          {{$t('m.确 定')}}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  userManagement,
  getExchangeList,
  operatorList,
  userManagementTopData,
  migrationUser,
  moneyCollection,
  userManagementExchangelist,
  fundHistoryList,
} from "../../request/api";
import moment from "moment";
export default {
  components: {},
  data() {
    return {
      moment,
      multipleSelection: [],

      project: null,
      exchangeList: [], //交易所列表
      exchangeId: null, //交易所
      exchangeName: null,
      operatorList: [], //操作员列表
      operator: null, //操作员
      time: null, //入金时间
      endTime: null, //退出时间
      availableNumStrat: null, //可用
      availableNumEnd: null,
      investCoinNumStart: null, //投入
      investCoinNumEnd: null,
      // executionNumberStart: null, //策略数
      // executionNumberEnd: null,
      userList: [], //用户数据列表
      oldUserList: [], //历史用户列表
      total: 0, // 条数
      pageSize: 10,
      pageNo: 1,

      topData: [], //顶部数据

      dialogVisible: false, ///迁移用户弹窗

      operatorLater: null, ////被迁移操作员ID

      role: null, //权限

      roleUserId: null,

      notCollection: null,

      account: null,

      open: true,

      activeName: "1",
    };
  },
  computed: {},
  watch: {
    time(New, old) {
      console.log(New);
    },
  },
  methods: {
    activeClick(v) {},
    // tabs
    handleClick(tab, event) {
      this.time = null;
      this.endTime=null
      this.operator = null;
      this.exchangeId = null;
      this.exchangeName = null;
      this.availableNumStrat = null; //可用
      this.availableNumEnd = null;
      this.investCoinNumStart = null; //投入
      this.investCoinNumEnd = null;
      console.log(this.activeName);
      let payload = {
        token: sessionStorage.getItem("token"),
        teamProjectId: this.project.teamProjectId,
        roleUserId: this.roleUserId,
      };
      if (this.activeName == "1") {
        this.userManagement(payload);
      } else {
        this.fundHistoryList(payload);
      }
    },
    submitForm() {
      let payload = {
        operator: this.operator,
        exchangeName: this.exchangeName,
        availableNumStrat: Number(this.availableNumStrat),
        availableNumEnd: Number(this.availableNumEnd),
        investCoinNumStart: Number(this.investCoinNumStart),
        investCoinNumEnd: Number(this.investCoinNumEnd),
        pageSize: this.pageSize,
        pageNo: this.pageNo,
        token: sessionStorage.getItem("token"),
        teamProjectId: this.project.teamProjectId,
        roleUserId: this.roleUserId,
      };

      if (this.time) {
        payload.timeStart = new Date(this.time[0]).getTime();
        payload.timeEnd = new Date(this.time[1]).getTime();
      }
      if (this.endTime) {
        payload.outStart = new Date(this.endTime[0]).getTime();
        payload.outEnd = new Date(this.endTime[1]).getTime();
      }
      console.log(payload);

      if (this.activeName == "1") {
        this.userManagement(payload);
      } else {
        this.fundHistoryList(payload);
      }
    },
    // 重置
    resetForm() {
      this.time = null;
      this.endTime=null;
      this.operator = null;
      this.exchangeId = null;
      this.exchangeName = null;
      this.availableNumStrat = null; //可用
      this.availableNumEnd = null;
      this.investCoinNumStart = null; //投入
      this.investCoinNumEnd = null;
      let payload = {
        token: sessionStorage.getItem("token"),
        teamProjectId: this.project.teamProjectId,
        roleUserId: this.roleUserId,
      };
      if (this.activeName == "1") {
        this.userManagement(payload);
      } else {
        this.fundHistoryList(payload);
      }
      console.log();
    },
    formatter(row, column) {
      return row.address;
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
      console.log(this.multipleSelection);
    },

    // 分页
    handleSizeChange(val) {
      this.pageSize = val;
      
      let payload = {
        token: sessionStorage.getItem("token"),
        teamProjectId: this.project.teamProjectId,
        roleUserId: this.roleUserId,
        pageSize: this.pageSize,
        pageNo: this.pageNo,
        operator: this.operator,
        exchangeName: this.exchangeName,
        availableNumStrat: Number(this.availableNumStrat),
        availableNumEnd: Number(this.availableNumEnd),
        investCoinNumStart: Number(this.investCoinNumStart),
        investCoinNumEnd: Number(this.investCoinNumEnd),
      };
      if (this.activeName == "1") {
        this.userManagement(payload);
      } else {
        this.fundHistoryList(payload);
      }
    },
    handleCurrentChange(val) {
      this.pageNo = val;
      
      let payload = {
        token: sessionStorage.getItem("token"),
        teamProjectId: this.project.teamProjectId,
        roleUserId: this.roleUserId,
        pageSize: this.pageSize,
        pageNo: this.pageNo,
        operator: this.operator,
        exchangeName: this.exchangeName,
        availableNumStrat: Number(this.availableNumStrat),
        availableNumEnd: Number(this.availableNumEnd),
        investCoinNumStart: Number(this.investCoinNumStart),
        investCoinNumEnd: Number(this.investCoinNumEnd),
      };
      // this.userManagement(payload);
            if (this.activeName == "1") {
        this.userManagement(payload);
      } else {
        this.fundHistoryList(payload);
      }
    },
    // 用户管理顶部数据
    userManagementTopData() {
      let payload = {
        token: sessionStorage.getItem("token"),
        teamProjectId: this.project.teamProjectId,
        roleUserId: this.roleUserId,
      };
      userManagementTopData(payload).then((res) => {
        if (res.status == 200) {
          this.notCollection = res.data.notCollection;
          this.topData = res.data.topData;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 查询用户列表
    userManagement(payload) {
      userManagement(payload).then((res) => {
        if (res.status == 200) {
          if (res.data.userList) {
            for (let item of res.data.userList) {
              item.investCoinNum = Number(item.investCoinNum);
              item.alreadyDevoted = Number(item.alreadyDevoted);
              item.availableNum = Number(item.availableNum);
              item.executionNumber = Number(item.executionNumber);
            }
            this.userList = res.data.userList;
          } else {
            this.userList = [];
          }
          this.total = res.data.total;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 查询历史入金用户列表
    fundHistoryList(payload) {
      fundHistoryList(payload).then((res) => {
        if (res.status == 200) {
          this.oldUserList = res.data.historyUserList;
          this.total = res.data.total;
        }
      });
    },
    // 查询交易所列表
    getExchangeList() {
      let payload = {
        token: sessionStorage.getItem("token"),
        teamProjectId: this.project.teamProjectId,
      };


      userManagementExchangelist(payload).then((res) => {
        if (res.status == 200) {
          this.exchangeList = res.data.exchangeLimit;
        } else {
          this.$message.error(res.msg);
        }
      });
    },

    // 查询操作员列表
    getOperatorList() {
      let payload = {
        token: sessionStorage.getItem("token"),
        teamProjectId: this.project.teamProjectId,
      };
      operatorList(payload).then((res) => {
        if (res.status == 200) {
          this.operatorList = res.data.operatorList;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 跳详情
    toDateil(item) {

      let type = this.account.userId == item.operator ? true : false;
      this.$router.push({ path: "details", query: { id: item.id, type } });
    },
    // 历史详情
    toOldDateil(row) {
      this.$router.push({ path: "oldDetails", query: { id: row.id } });
    },
    // 迁移用户弹窗
    confrom() {
      this.dialogVisible = true;
    },
    // 迁移用户
    migrationUser() {
      let list = this.multipleSelection;
      if (list.length == 0) {
        this.$message.error(this.$t('m.请先选择用户'));
        return;
      } else if (!this.operatorLater) {
        this.$message.error(this.$t('m.请选择要迁移的操作员'));
        return;
      }
      let idStr = [];
      for (let i = 0; i < list.length; i++) {
        idStr.push(list[i].id);
      }
      let payload = {
        token: sessionStorage.getItem("token"),
        teamProjectId: this.project.teamProjectId,
        idStr: idStr.join(","),
        operatorFront: "",
        operatorLater: this.operatorLater,
      };
      migrationUser(payload).then((res) => {
        if (res.status == 200) {
          this.$message.success(this.$t('m.迁移成功'));
          this.dialogVisible = false;
          this.operatorLater = null;
          let payload = {
            token: sessionStorage.getItem("token"),
            teamProjectId: this.project.teamProjectId,
            roleUserId: this.roleUserId,
          };
          this.userManagement(payload);
          console.log(res.data);
        } else {
          this.$message.error(res.msg);
        }
      });
      console.log(payload);
    },
    // 取消迁移
    cancel() {
      this.dialogVisible = false;
      this.operatorLater = null;
    },
    handleClose(done) {
      this.operatorLater = null;
      done();
    },
    isOpen() {
      this.open = !this.open;
    },
  },
  created() {},
  mounted() {
    let account = sessionStorage.getItem("account");
    this.account = JSON.parse(account);
    let project = sessionStorage.getItem("project");
    this.project = JSON.parse(project);
    let roleUserId = this.$route.query.userId;
    // this.roleUserId = roleUserId ? roleUserId : null;
    if (roleUserId) {
      this.roleUserId = roleUserId;
    } else {
      this.roleUserId = sessionStorage.getItem("userId");
    }
    this.getExchangeList();
    this.getOperatorList();
    let payload = {
      token: sessionStorage.getItem("token"),
      teamProjectId: this.project.teamProjectId,
      roleUserId: this.roleUserId,
    };
    this.userManagement(payload);
    // this.fundHistoryList(payload);
    this.userManagementTopData();
    let role = JSON.parse(sessionStorage.getItem("role"));
    this.role = role;
  },
  beforeCreate() {},
  beforeMount() {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {},
  activated() {
    let account = sessionStorage.getItem("account");
    this.account = JSON.parse(account);
    let project = sessionStorage.getItem("project");
    this.project = JSON.parse(project);

    let roleUserId = this.$route.query.roleUserId;
    // this.roleUserId = roleUserId ? roleUserId : null;
    if (roleUserId) {
      this.roleUserId = roleUserId;
    } else {
      this.roleUserId = sessionStorage.getItem("userId");
    }

    this.getExchangeList();
    this.getOperatorList();
    let payload = {
      token: sessionStorage.getItem("token"),
      teamProjectId: this.project.teamProjectId,
      roleUserId: this.roleUserId,
    };
    this.userManagement(payload);
    this.fundHistoryList(payload);
    this.userManagementTopData();
    let role = JSON.parse(sessionStorage.getItem("role"));
    this.role = role;
    
  },
};
</script>
<style lang='scss' scoped>
.userManagement {
  width: 100%;
  height: 100%;
  .content {
    //   padding-right: 20px;
    ::v-deep.el-input__inner {
      height: 30px;
      line-height: 30px;
    }
    //   ::v-deep.el-input__icon {
    //     line-height: 30px;
    //   }
    .content-top {
      background: #fff;
      border-radius: 8px;
      padding: 0 20px 0 20px;
      margin-bottom: 16px;
      ::v-deep.el-button {
        padding: 8px 20px;
      }
      .content-top-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 24px;
        // border-bottom: 1px solid #eaeaec;
        margin-bottom: 40px;
      }
    }
    .tabs-warp {
      background: #fff;
      padding: 20px;
      .content-top-box-warp {
        display: flex;
        justify-content: space-between;
        .content-top-box {
          width: 32%;
          ::v-deep.el-input__suffix {
            display: none;
          }
          ::v-deep.el-range-editor {
            padding: 0 0 0 10px;
            width: 100%;
          }
          ::v-deep.el-form-item {
            margin-bottom: 15px;
          }
          ::v-deep.el-select {
            width: 100%;
          }
          .Invested {
            display: flex;
            .line {
              height: 30px;
              line-height: 30px;
              margin: 0 5px;
            }
            .el-input {
              // width: 100px;
            }
            .el-select {
              width: 100%;
            }
            ::v-deep.el-input-group__append {
              padding: 0 5px;
              font-size: 12px;
            }
          }
        }
      }
      .btn {
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding-bottom: 20px;
        .Expand-collapse {
          font-size: 16px;
          color: #c1c3cb;
          cursor: pointer;
        }
        .operation {
          min-width: 260px;
          display: flex;
          justify-content: flex-end;
        }
      }
      .content-bottom {
        border-radius: 8px;
        background: #fff;
        // padding: 0 20px 20px 20px;
        .deposit-operation {
          span {
            font-size: 12px;
            color: #007aff;
            cursor: pointer;
          }
          :nth-child(2) {
            margin: 0 30px;
          }
        }
        .box-pagination {
          margin-top: 50px;
          text-align: right;
        }
      }
    }
  }
  ::v-deep.el-select {
    width: 100%;
  }
}
</style>