<template>
  <div class="projectSettings">
    <div class="top">
      <div class="project-operation">
        <span>{{$t('m.项目操作')}}</span>
      </div>

      <div class="project-status">
        <div class="project-status-left">
          <span>{{$t('m.项目状态：')}}</span>

          <span
            v-if="projiectStatus != '结算中' && projiectStatus != '结算完成'"
            class="radius"
            :class="{
              yellow: showStatus == '审核中',
              blur: showStatus == '已上线',
            }"></span>

          <span
            v-if="projiectStatus != '结算中' && projiectStatus != '结算完成'"
            :class="{
              yellowText: showStatus == '审核中',
              blurText: showStatus == '已上线',
            }">{{ showStatus }}</span>

          <span class="radius red" v-if="projiectStatus == '结算中'"></span>
          <span v-if="projiectStatus == '结算中'" class="redText">
            {{$t('m.下线结算中')}}</span>

          <span class="radius" v-if="projiectStatus == '结算完成'"></span>
          <span v-if="projiectStatus == '结算完成'">
            {{$t('m.已下线')}}</span>

          <span class="mark" v-if="mark && contentStatus == '未审核'">
            {{$t('m.审核未通过：') + mark}}</span>
          <span style="margin: 0 5px 0 40px">
            {{$t('m.项目保证金余额：')}}</span>
          <span>{{this.project ? Number(this.project.settled) + "VTN" : ""}}</span>
          <span
            style="margin-left: 5px; color: #e12525"
            v-if="
              project &&
              Number(project.settled) < Number(project.threshold) &&
              (contentStatus == '未审核' || showStatus == '未上线')
            ">({{$t('m.保证金不足,初始保证金不低于&(1)VTN').replace('&(1)', project.threshold)}})</span>
          <span
            class="transferBtn"
            v-if="projiectStatus !== '结算中'"
            @click="transferCapital()">
            {{$t('m.去划转')}}</span>
        </div>

        <div class="project-status-right">
          <span
            class="update"
            v-if="
              showStatus == '未上线' &&
              projiectStatus !== '结算完成' &&
              projiectStatus !== '结算中'
            "
            @click="advancedSettingsSave('online')">
            {{$t('m.申请审核上线')}}</span>

          <span
            class="update"
            v-if="content_draft && showStatus == '已上线'"
            @click="setType('contentOnline')">
            {{$t('m.申请审核修改内容')}}</span>

          <span
            class="revoke"
            v-if="
              (contentStatus == '审核中' && content_draft) ||
              (showStatus == '审核中' && content_draft)
            "
            @click="setType('withdraw')">
            {{$t('m.撤回申请')}}</span>

          <span
            class="ceshi"
            @click="setType('copyPwd')"
            v-if="showStatus == '已上线'">
            {{$t('m.测试口令')}}</span>

          <span
            class="ceshi"
            v-if="projiectStatus == '结算完成' && showStatus == '未上线'"
            @click="setType('reset')">
            {{$t('m.重置为未上线')}}</span>

          <span
            class="xiaxian"
            v-if="
              (showStatus == '未上线' || showStatus == '审核中') &&
              projiectStatus != '结算中'
            "
            @click="deteleProject()">
            {{$t('m.删除项目')}}</span>

          <span
            class="xiaxian"
            v-if="showStatus == '已上线'"
            @click="setType('offline')">
            {{$t('m.下线结算')}}</span>
        </div>
      </div>

      <div class="project-type-set">
        <div class="deposit-operation">
          <el-tooltip
            effect="dark"
            :content="$t('入金操作：开放入金时，用户可以将资金投入项目，暂停入金时，无法投入资金')"
            placement="top-start">
            <span
              style="border-bottom: 1px dashed #333; cursor: pointer"
              class="label">
              {{$t('m.入金操作：')}}</span>
          </el-tooltip>

          <template v-if="!updataGold">
            <span class="status">{{
              goldOpen == "1" ? $t('m.开放入金') : $t('m.暂停入金')
            }}</span>
            <span
              class="update"
              @click="updataGold = true"
              v-if="projiectStatus != '结算中' && projiectStatus != '结算完成'">
              {{$t('m.修改')}}</span>
          </template>

          <template v-if="updataGold">
            <el-radio v-model="goldOpen" label="1" @change="goldChange">
              {{$t('m.开放入金')}}</el-radio>
            <el-radio v-model="goldOpen" label="2" @change="goldChange">
              {{$t('m.暂停入金')}}</el-radio>
          </template>

          <template v-if="selectGold">
            <span class="update confirm" @click="goldConfirm()">
              {{$t('m.保存修改')}}</span>
          </template>
          <span v-if="updataGold" class="update" @click="goldCancel()">
            {{$t('m.取消')}}</span>
        </div>
        <div class="project-visible">
          <el-tooltip effect="dark" placement="top">
            <div slot="content">
              {{$t('m.项目可见：可见则显示在量化托管列表；不可见则不在量化托管列表显示，但可通过测试口令访问。')}}<br />{{$t('m.可见性设置在项目上线后生效。')}}</div>
            <span
              style="border-bottom: 1px dashed #333; cursor: pointer"
              class="label">
              {{$t('m.项目可见：')}}</span>
          </el-tooltip>

          <template v-if="!updateVisible">
            <span class="status">{{
              visibility == "1" ? $t('m.可见') : $t('m.不可见')
            }}</span>
            <span
              class="update"
              @click="updateVisible = true"
              v-if="
                projiectStatus != '结算中' &&
                projiectStatus != '结算完成' &&
                showStatus != '未上线' &&
                showStatus != '审核中'
              ">
              {{$t('m.修改')}}</span>
          </template>

          <template v-if="updateVisible">
            <el-radio v-model="visibility" label="1" @change="visibleChange">
              {{$t('m.可见')}}</el-radio>
            <el-radio v-model="visibility" label="2" @change="visibleChange">
              {{$t('m.不可见')}}</el-radio>
          </template>
          <template v-if="selectVisible">
            <span class="update confirm" @click="visibleConfirm()">
              {{$t('m.保存修改')}}</span>
          </template>
          <span v-if="updateVisible" class="update" @click="visibleCancel()">
            {{$t('m.取消')}}</span>
        </div>
      </div>
    </div>

    <div class="bottom">
      <div class="cont-left">
        <div class="title">
          <span>
            {{$t('m.基本信息')}}</span>
          <span class="text">
            {{$t('m.申请上线时将对内容进行审核')}}</span>
          <span class="draft" v-if="content_draft">
            {{$t('m.草稿')}}</span>
          <span class="draft" v-else>{{ showStatus }}</span>
        </div>
        <div class="project-name">
          <div class="name">
            {{$t('m.项目名称')}}</div>
          <el-input v-model="projectName" :placeholder="$t('m.请输入内容')"></el-input>
        </div>
        <div class="project-introduction">
          <div class="name">
            {{$t('m.项目简介')}}</div>
          <el-input
            type="textarea"
            :rows="16"
            :placeholder="$t('m.请输入内容')"
            v-model="projectIntroduction"
            :maxlength="500"
            resize="none">
          </el-input>
        </div>

      </div>
      <div class="cont-right">
        <div class="title">
          <span>{{$t('m.设置')}}</span>
          <span class="red">*</span>
          <span class="tips">
            {{$t('m.正式上线后不可修改')}}</span>
        </div>

        <div class="type-set">
          <div class="set-box">
            <span class="label">{{$t('m.托管类型：')}}</span>
            <div class="text">
              <span>{{$t('m.全API托管')}}</span>
              <!-- <span>(不再支持使用部分资金托管)</span> -->
            </div>
          </div>
          <div class="set-box">
            <span class="label">
              {{$t('m.交易类型：')}}</span>
            <el-select
              v-model="transactionType"
              :placeholder="$t('m.请选择')"
              @change="transactionTypeCheck"
              :disabled="
                showStatus != '未上线' ||
                projiectStatus == '结算中' ||
                projiectStatus == '结算完成' ||
                this.markShow !== '超级管理员'
              ">
              <el-option
                v-for="item in transactionTypeList"
                :key="item.value"
                :label="$t('m.'+item.label)" 
                :value="item.value">
              </el-option>
            </el-select>
          </div>
          <div class="set-box currency">
            <span class="label">
              {{$t('m.托管币种：')}}</span>
            <el-select
              v-model="symbol"
              :placeholder="$t('m.请选择')"
              @change="symbolChange"
              :disabled="
                showStatus != '未上线' ||
                projiectStatus == '结算中' ||
                projiectStatus == '结算完成' ||
                this.markShow !== '超级管理员'
              ">
              <el-option
                v-for="(item, idx) in tradePairList"
                :key="idx"
                :label="item"
                :value="item">
              </el-option>
            </el-select>
          </div>
        </div>

        <div class="capital-exchange">
          <div class="capital-exchange-left">
            <div class="left-title">
              <span>{{$t('m.投入资金限制')}}</span>
              <span>{{ "范围：" + minInvest + "~" + maxInvest }}</span>
            </div>
            <div class="left-box">
              <el-input
                v-model="min"
                :placeholder="$t('m.请输入内容')"
                @change="capitalRangCheck"
                :disabled="
                  showStatus != '未上线' ||
                  projiectStatus == '结算中' ||
                  projiectStatus == '结算完成' ||
                  this.markShow !== '超级管理员'
                "></el-input>
              <span class="line"></span>
              <el-input
                v-model="max"
                :placeholder="$t('m.请输入内容')"
                @change="capitalRangCheck"
                :disabled="
                  showStatus != '未上线' ||
                  projiectStatus == '结算中' ||
                  projiectStatus == '结算完成' ||
                  this.markShow !== '超级管理员'
                "></el-input>
            </div>
          </div>
          <div class="capital-exchange-right">
            <p class="right-title">
              {{$t('m.交易所限制')}}</p>
            <el-select
              v-model="exchange"
              :placeholder="$t('m.请选择')"
              filterable
              multiple
              @remove-tag="exchangeRemove"
              @change="exchangeChange"
              :class="showStatus == '未上线' ? 'capitealChangeOption' : ''"
              :disabled="
                showStatus !== '未上线' ||
                projiectStatus == '结算中' ||
                projiectStatus == '结算完成' ||
                this.markShow !== '超级管理员'
              ">
              <el-option
                v-for="item in exchangeList"
                :key="item.exchangeId"
                :label="item.exchangeName"
                :value="item.exchangeName"
              >
              </el-option>
            </el-select>
          </div>
        </div>

        <div class="transaction-currency">
          <div class="transaction-currency-title">
            <span>{{$t('m.交易币种限制')}}</span>
            <span>({{$t('m.最多支持20个币种，项目上线后不可修改，请谨慎选择')}})</span>
          </div>

          <div class="optional">
            <!-- <span>自选币种</span> -->
            <el-select
              v-model="pairTags"
              :placeholder="$t('m.请选择')"
              filterable
              multiple
              style="width: 100%"
              :multiple-limit="multipleLimit"
              :disabled="
                showStatus != '未上线' ||
                projiectStatus == '结算中' ||
                projiectStatus == '结算完成' ||
                this.markShow !== '超级管理员'
              "
              @change="pairChange">
              <el-option
                v-for="(item, idx) of pairList"
                :key="idx"
                :label="item"
                :value="item">
              </el-option>
            </el-select>
          </div>
        </div>

        <div class="commission">
          <p class="commission-title">{{$t('m.佣金形式')}}</p>
          <div class="commission-warp">
            <div class="commission-warp-left">
              <el-checkbox v-model="profitSharing" disabled>
                <span class="profit-sharing">{{$t('m.盈利分成')}}</span>
                <span class="required">({{$t('m.必填')}})</span>
              </el-checkbox>

              <div class="price-ratio">
                <span>{{$t('m.用户盈利')}}</span>
                <el-input
                  v-model="profitRatio"
                  placeholder="请输入"
                  type="number"
                  class="no-number"
                  @change="proportionalCheck"
                  :disabled="
                    showStatus != '未上线' ||
                    projiectStatus == '结算中' ||
                    projiectStatus == '结算完成' ||
                    this.markShow !== '超级管理员'
                  ">
                  <i class="iconStyle" slot="suffix">%</i>
                </el-input>
                <span>{{$t('m.的部分，要求分成比例')}} ：</span>
                <el-input
                  v-model="profitSharingRatio"
                  placeholder="请输入"
                  type="number"
                  @change="proportionalCheck"
                  :disabled="
                    showStatus != '未上线' ||
                    projiectStatus == '结算中' ||
                    projiectStatus == '结算完成' ||
                    this.markShow !== '超级管理员'
                  ">
                  <i class="iconStyle" slot="suffix">%</i>
                </el-input>
              </div>

              <div class="share-box">
                <div class="share-boxItem">
                  <p class="share-box-title">{{$t('m.每次分成上限')}}</p>
                  <el-input
                    v-model="shareCeiling"
                    placeholder="请输入"
                    type="number"
                    :disabled="
                      showStatus != '未上线' ||
                      projiectStatus == '结算中' ||
                      projiectStatus == '结算完成' ||
                      this.markShow !== '超级管理员'
                    ">
                    <i class="iconStyle" slot="suffix">VTN</i>
                  </el-input>
                </div>
                <div class="share-boxItem" style="margin-left: 10px">
                  <p class="share-box-title">{{$t('m.最小可用保证金要求')}}</p>
                  <el-input
                    v-model="minBond"
                    placeholder="请输入"
                    type="number"
                    :disabled="
                      showStatus != '未上线' ||
                      projiectStatus == '结算中' ||
                      projiectStatus == '结算完成' ||
                      this.markShow !== '超级管理员'
                    ">
                    <i class="iconStyle" slot="suffix">VTN</i>
                  </el-input>
                </div>
              </div>
            </div>
            <div class="commission-warp-right">
              <el-checkbox
                v-model="riskIndemnityCommentShow"
                :disabled="
                  showStatus != '未上线' ||
                  projiectStatus == '结算中' ||
                  projiectStatus == '结算完成' ||
                  this.markShow !== '超级管理员'
                ">
                <span class="profit-sharing">{{$t('m.风险赔付承诺')}}</span>
                <span class="required">({{$t('m.选填')}})</span>
              </el-checkbox>

              <div class="promisePay">
                <span>{{$t('m.当用户亏损')}}</span>
                <el-input
                  v-model="lossRatio"
                  type="number"
                  :disabled="
                    showStatus != '未上线' ||
                    projiectStatus == '结算中' ||
                    projiectStatus == '结算完成' ||
                    this.markShow !== '超级管理员'
                  ">
                  <i class="iconStyle" slot="suffix">%</i>
                </el-input>
                <span>{{$t('m.的部分，承诺赔偿比例：')}}</span>
                <el-input
                  v-model="IndemnityRatio"
                  type="number"
                  :disabled="
                    showStatus != '未上线' ||
                    projiectStatus == '结算中' ||
                    projiectStatus == '结算完成' ||
                    this.markShow !== '超级管理员'
                  ">
                  <i class="iconStyle" slot="suffix">%</i>
                </el-input>
              </div>
              <div class="share-box">
                <div class="share-box-title">
                  <span>{{$t('m.赔付承诺生效时间')}}</span>
                  <img
                    src="../../assets/icon/jieshi.png"
                    alt=""
                    @click="compensationTimeTips()"
                  />
                </div>
                <el-input
                  v-model="compensationTime"
                  type="number"
                  :disabled="
                    showStatus != '未上线' ||
                    projiectStatus == '结算中' ||
                    projiectStatus == '结算完成' ||
                    this.markShow !== '超级管理员'
                  ">
                  <i class="iconStyle" slot="suffix">
                    {{$t('m.天')}}</i>
                </el-input>
              </div>
            </div>
          </div>
        </div>
        <div class="btn-warp">
          <div class="btn-explain">
            <el-checkbox
              :disabled="
                showStatus != '未上线' ||
                projiectStatus == '结算中' ||
                projiectStatus == '结算完成' ||
                this.markShow !== '超级管理员'
              " v-model="explainChecked"></el-checkbox>
            <div>{{$t('m.是否在app端剔除托管时长不足7日的用户托管数据')}}</div>
            <img @click="open()" src="../../assets/icon/jieshi.png" alt="" />
          </div>
          <span class="btn" @click="updateBtn()">
            {{$t('m.保存')}}</span>
        </div>
      </div>
    </div>
    <el-dialog title="测试V口令" :visible.sync="dialogVisible" width="30%">
      <el-input
        v-model="testPassword"
        :readonly="true"
        id="testPass"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="copyTestPass()">
          {{$t('m.复 制')}}</el-button>
      </span>
    </el-dialog>
    <!-- 划转 -->
    <el-dialog
      title="划转"
      :visible.sync="transfer"
      width="30%"
      :close-on-click-modal="false"
      :show-close="false">
      <div class="transfer-box">
        <div class="sel">
          <div class="left">
            <div class="left-box">
              <span class="title">从</span>
              <el-select v-model="projectNameOne" placeholder="请选择账户">
                <el-option
                  v-for="item in accOne"
                  :key="item.projectName"
                  :label="item.projectName"
                  :value="item.projectName">
                </el-option>
              </el-select>
            </div>
            <div class="left-box">
              <img :src="require('../../assets/icon/zhuanhuan.png')" alt="" />
            </div>
            <div class="left-box">
              <span class="title">{{$t('m.到')}}</span>

              <el-select v-model="projectNameTwo" placeholder="请选择账户">
                <el-option
                  v-for="item in accTwo"
                  :key="item.projectName"
                  :label="item.projectName"
                  :value="item.projectName"
                >
                </el-option>
              </el-select>
            </div>
          </div>

          <div class="right">
            <img
              :src="require('../../assets/icon/huazhuan01.png')"
              alt=""
              @click="transSortChange()"
            />
          </div>
        </div>

        <div class="curr">
          <span class="title">{{$t('m.币种：')}}</span>
          <el-select v-model="transferCurrency" :placeholder="$t('m.请选择')">
            <template>
              <el-option
                v-for="item in options"
                :key="item"
                :label="item"
                :value="item">
              </el-option>
            </template>
          </el-select>
        </div>

        <div class="tran-num">
          <span class="title">{{$t('m.划转数量')}}</span>
          <el-input v-model="transferNum" :placeholder="$t('m.请输入内容')">
            <div slot="suffix" class="unit">
              <span>{{ transferCurrency }}</span>
              <span
                class="all"
                @click="
                  transferNum = parseFloat(
                    JSON.parse(JSON.stringify(currencyNumber))
                  )
                ">
                {{$t('m.全部')}}</span>
            </div>
          </el-input>
          <div class="available">
            {{
              "可用划转 " +
              Math.floor(Number(currencyNumber) * 10000) / 10000 +
              " " +
              transferCurrency
            }}
          </div>
          <div class="tips">
            {{$t('m.转入项目的USDT将自动按汇率兑换成VTN，并在转出时原价兑成USDT')}}
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="transferCancel()">
          {{$t('m.取 消')}}</el-button>
        <el-button type="primary" @click="transferConfirm()">
          {{$t('m.划 转')}}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  getProject,
  addProject,
  getExchangeList,
  getProjectPairById,
  advanceSetting,
  deleteProject,
  selectTemaBond,
  selectProjectListBond,
  bondisTransfer,
} from "../../request/api";
export default {
  components: {},
  data() {
    return {
      dialogVisible: false,
      defaultTeam: null, //团队信息
      projectInfo: null, //项目信息
      project: null, //项目详情
      projectName: null, //项目名字
      projectIntroduction: null, //项目简介
      contentStatus: null, // 项目名称/简介审核状态
      showStatus: null, //项目状态
      goldOpen: null, //是否入金
      copyGoldOpen: null, //备份入金参数
      visibility: null, //是否可见
      copyVisibility: null, // 可见参数备份

      updataGold: false, //
      selectGold: false, //切换入金

      updateVisible: false,
      selectVisible: false, //切换可见
      transactionTypeList: [
        {
          value: "币币",
          label:'币币',
        },
        {
          value: "币币&USDT本位永续合约",
          label:'USDT本位永续合约',
        },
      ],
      transactionType: null, //交易类型

      currencyList: [
        {
          value: "USDT",
          label: "USDT",
        },
        {
          value: "BTC",
          label: "BTC",
        },
        {
          value: "ETH",
          label: "ETH",
        },
      ],
      symbol: null, //托管币种

      advanceSetting: null, //项目高级设置
      content_draft: null, //是否草稿

      tradePairList: ["USDT", "KRW"], //币对列表
      tradePair: null, //币对

      min: null,
      max: null,
      maxInvest: null,
      minInvest: null, //不可修改

      exchangeList: null,
      exchange: [], // 交易所

      pairList: null, //币对列表
      selectPair: [],
      // mandatoryList: ["USDT", "BTC", "ETH", "DOT", "LTC"], //不可删除币

      pairTags: [], //选择的币对
      multipleLimit: 20, //交易对最大限制

      // 高级设置内容
      profitSharing: true, //分成选择显示
      riskIndemnityCommentShow: false, //赔付选择显示

      profitRatio: null, //盈利比例
      profitSharingRatio: null, //盈利分成比例
      shareCeiling: null, //分成上限
      minBond: null, //最小保证金
      lossRatio: null, //亏损比例
      IndemnityRatio: null, //赔付比例
      compensationTime: null, //赔付生效时间

      investmentLimit: true, //投入范围限制校验
      proporCheck: true, ///比例输入校验

      testPassword: null, //测试口令

      projiectStatus: null, //  项目状态

      mark: null, // 未通过备注

      //划转
      transfer: false, //弹框显隐
      currencyNumber: null,
      transferCurrency: null,
      accOne: null,
      accTwo: null,
      TemaBond: [], //团队资金
      projectNameOne: null,
      projectNameTwo: null,
      transferNum: null,
      transferShow: false,
      options: [],
      projectList: [],
      markShow: null, //身份信息

      explainChecked: true, //7日内托管数据
    };
  },
  computed: {},
  watch: {
    transferCurrency() {
      if (this.transferShow) {
        console.log(this.TemaBond);
        console.log(this.transferCurrency);
        if (this.transferCurrency == "USDT") {
          this.currencyNumber = this.TemaBond.usdtBond;
        } else {
          this.currencyNumber = this.TemaBond.vtnBond;
        }
      }
    },
  },
  methods: {
    //7日内提示文案
    open() {
      
      this.$alert(
        this.$t('m.开启后，当用户托管不足7日就退出时，app将修正累计投入、收益及人数等数据'),
        {
          confirmButtonText: this.$t('m.确定'),
          callback: (action) => {},
        }
      );
    },
    // 划转弹窗
    transferCapital() {
      
      if (this.markShow == "超级管理员") {
        this.$router.push({ path: "/" });
        this.$store.state.NoviceActive = "3";
      } else {
        this.$message.error(this.$t('m.请联系超级管理员操作！'));
      }
    },
    // 转换按钮
    transSortChange() {
      this.transferShow = !this.transferShow;
      if (this.transferShow) {
        this.currencyNumber = this.TemaBond.usdtBond;
        this.accOne = [{ ...this.TemaBond, projectName: "团队账户" }];
        this.projectNameOne = "团队账户";
        this.accTwo = this.projectList;
        this.projectNameTwo = this.projectList[0].projectName;
        this.options = ["USDT", "VTN"];
        this.transferCurrency = this.options[0];
      } else {
        console.log(this.projectList);
        this.accTwo = [{ ...this.TemaBond, projectName: "团队账户" }];
        this.projectNameTwo = "团队账户";
        this.accOne = this.projectList;
        this.projectNameOne = this.projectList[0].projectName;
        this.options = ["VTN"];
        this.transferCurrency = this.options[0];

        this.currencyNumber = this.projectList[0].settled;
      }
    },
    // 划转确定按钮
    transferConfirm() {
      console.log(1);
      if (!this.transferNum) {
        this.$message.error(this.$t('m.划转数量不能为空'));
        return;
      } else if (Number(this.transferNum) <= 0) {
        this.$message.error(this.$t('m.划转数量不能小于0'));
        return;
      } else if (Number(this.transferNum) > Number(this.currencyNumber)) {
        this.$message.error(this.$t('m.划转数量不能超出可划转数'));
        return;
      }

      let payload = {
        token: sessionStorage.getItem("token"),
        teamId: this.defaultTeam.teamId,
        teamProjectId: this.projectInfo.teamProjectId,
        type: this.transferShow ? "toProject" : "toTeam",
        coinType: this.transferCurrency,
        amount: this.transferNum,
      };

      console.log(payload);

      bondisTransfer(payload).then((res) => {
        if (res.status == 200) {
          console.log(res.data);
          this.$message.success(this.$t('m.划转成功'));
          this.selectProjectListBond();
          this.transfer = false;
        } else {
          this.$message.warning(res.msg);
        }
      });
    },
    // 团队保证金
    selectTemaBond() {
      let payload = {
        token: sessionStorage.getItem("token"),
        teamId: this.defaultTeam.teamId,
      };
      selectTemaBond(payload).then((res) => {
        if (res.status == 200) {
          this.TemaBond = res.data.TemaBond;
          console.log(res.data, 111111111111111111);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 项目保证金列表
    selectProjectListBond() {
      let payload = {
        token: sessionStorage.getItem("token"),
        teamId: this.defaultTeam.teamId,
      };
      selectProjectListBond(payload).then((res) => {
        if (res.status == 200) {
          let projectList = res.data.projectList;
          let showProjectList = [];
          projectList.forEach((e) => {
            if (e.projectName == this.project.projectName) {
              if (e.settled.indexOf(".") > -1) {
                e.settled = e.settled.slice(0, e.settled.indexOf(".") + 5);
              }
              showProjectList.push(e);
            }
          });
          this.projectList = showProjectList;
          console.log(this.projectList);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    //////*************划转结束********************** */
    // 操作提示
    projectTips() {
      this.$alert(
        "<div>"
        + this.$t('m.项目可见：可见则显示在量化托管列表；不可见则不在量化托管列表显示，但可通过测试口令访问')
        + "</div> <div>"
        + this.$t('m.入金操作：开放入金时，用户可以将资金投入项目，暂停入金时，无法投入资金')
        + "</div>",
        "",
        {
          dangerouslyUseHTMLString: true,
        }
      );
    },

    // 赔付时间
    compensationTimeTips() {
      this.$alert(
        "<div>"
        + this.$t('m.生效时间之前退出的用户将不会获得赔偿')
        + "</div> <div>"
        + this.$t('m.生效实际不得大于30天')
        + "</div>",
        "",
        {
          dangerouslyUseHTMLString: true,
        }
      );
    },

    // 入金切换
    goldChange(v) {
      this.selectGold = true;
      console.log(v);
    },

    // 修改入金
    goldConfirm() {
      this.copyGoldOpen = JSON.parse(JSON.stringify(this.goldOpen));
      let payload = {
        teamProjectId: this.projectInfo.teamProjectId,
        token: sessionStorage.getItem("token"),
        type: this.goldOpen == 1 ? "restoreGold" : "suspendGold",
      };
      console.log(payload.type);
      advanceSetting(payload).then((res) => {
        if (res.status == 200) {
          this.$message.success(this.$t('m.设置成功'));
          this.getProject();
          this.updataGold = false;
          this.selectGold = false;
        } else {
          this.$message.error(res.msg);
        }
      });
      console.log(this.goldOpen, this.copyGoldOpen);
    },
    // 入金取消修改
    goldCancel() {
      this.goldOpen = JSON.parse(JSON.stringify(this.copyGoldOpen));
      console.log(this.goldOpen, this.copyGoldOpen);

      this.updataGold = false;
      this.selectGold = false;
    },

    // 可见切换
    visibleChange(v) {
      this.selectVisible = true;
      // this.visibility = v;
      console.log(v);
    },

    // 修改可见
    visibleConfirm() {
      this.copyVisibility = JSON.parse(JSON.stringify(this.visibility));
      let payload = {
        teamProjectId: this.projectInfo.teamProjectId,
        token: sessionStorage.getItem("token"),
        type: this.copyVisibility == "1" ? "visable" : "unVisable",
      };
      console.log(payload.type);
      advanceSetting(payload).then((res) => {
        if (res.status == 200) {
          this.$message.success(this.$t('m.设置成功'));
          this.getProject();
          this.updateVisible = false;
          this.selectVisible = false;
        } else {
          this.$message.error(res.msg);
        }
      });
      console.log(this.goldOpen, this.copyGoldOpen);
    },
    // 可见取消修改
    visibleCancel() {
      this.visibility = JSON.parse(JSON.stringify(this.copyVisibility));
      console.log(this.goldOpen, this.copyGoldOpen);

      this.updateVisible = false;
      this.selectVisible = false;
    },

    //查询项目信息
    getProject() {
      let payload = {
        teamProjectId: this.projectInfo.teamProjectId,
        token: sessionStorage.getItem("token"),
      };
      getProject(payload).then((res) => {
        if (res.status == 200) {

          let list = res.data.project;
          if (list.settled.indexOf(".") > -1) {
            let settled = list.settled * 10000 + "";
            list.settled =
              (settled.indexOf(".") > -1
                ? settled.slice(0, settled.indexOf(".") + 1)
                : settled) / 10000;
          }

          this.project = list;


          this.mark = list.mark;
          this.projectIntroduction = JSON.parse(
            JSON.stringify(list.projectIntroduct)
          );
          this.projectName = JSON.parse(JSON.stringify(list.projectName));
          this.content_draft = list.content_draft;
          this.advanceSetting = JSON.parse(list.advanceSetting);
          let newCurr = JSON.parse(list.tradePair);

          let exchange = this.advanceSetting.exchangeLimit;
          this.exchange = [];
          for (let key of exchange) {
            this.exchange.push(key.exchangeName);
          }

          this.tradePair = JSON.parse(res.data.project.tradePair);

          
          this.symbol = this.advanceSetting.symbol;
          if (newCurr) {
            if (newCurr.indexOf(this.symbol) > 0) {
              newCurr.splice(newCurr.indexOf(this.symbol), 1);
            }
          }
          this.pairTags = newCurr;

          //   项目介绍名称状态
          this.contentStatus =
            list.contentStatus === "notAdopt"
              ? "未审核"
              : list.contentStatus === "review"
              ? "审核中"
              : list.contentStatus === "adopt"
              ? "已审核"
              : "";
          //项目状态
          this.showStatus =
            list.showStatus === "notOnline"
              ? "未上线"
              : list.showStatus === "review"
              ? "审核中"
              : list.showStatus === "online"
              ? "已上线"
              : "";

          this.projiectStatus =
            list.status === "gold"
              ? "开放入金"
              : list.status === "suspendGold"
              ? "暂停入金"
              : list.status === "settlement"
              ? "结算中"
              : list.status === "settled"
              ? "结算完成"
              : "";
          if (this.showStatus == "未上线") {
            this.exchange.push("添加 + ");
          }
          ///入金

          this.goldOpen = list.status == "gold" ? "1" : "2";
          this.copyGoldOpen = list.status == "gold" ? "1" : "2";

          this.visibility = list.visibility == "yes" ? "1" : "2";
          this.copyVisibility = list.visibility == "yes" ? "1" : "2";

          let exchangeLimit = JSON.parse(list.advanceSetting).exchangeLimit;
          let exchangeIds = [];
          for (let key of exchangeLimit) {
            exchangeIds.push(key.exchangeId);
          }
          this.getProjectPairById(exchangeIds);

          let spotOrContract = this.advanceSetting.spotOrContract.join("&");
          this.transactionType =
            spotOrContract == "spot" ? "币币" : "币币&USDT本位永续合约";
            

          this.getExchangeList();

          this.max = this.advanceSetting.max
            ? this.advanceSetting.max
            : JSON.parse(JSON.stringify(this.advanceSetting.maxInvest));
          this.min = this.advanceSetting.min
            ? this.advanceSetting.min
            : JSON.parse(JSON.stringify(this.advanceSetting.minInvest));
          this.max = this.advanceSetting.max
            ? this.advanceSetting.max
            : JSON.parse(JSON.stringify(this.advanceSetting.maxInvest));
          this.min = this.advanceSetting.min
            ? this.advanceSetting.min
            : JSON.parse(JSON.stringify(this.advanceSetting.minInvest));
          this.max = this.advanceSetting.max
            ? this.advanceSetting.max
            : JSON.parse(JSON.stringify(res.data.project_limin.maxInvest));
          this.min = this.advanceSetting.min
            ? this.advanceSetting.min
            : JSON.parse(JSON.stringify(res.data.project_limin.minInvest));

          this.minInvest = res.data.project_limin.minInvest;
          this.maxInvest = res.data.project_limin.maxInvest;

          this.profitLayer = this.advanceSetting.profitLayer;
          this.riskIndemnityComment = this.advanceSetting.riskIndemnityComment;
          this.explainChecked =
            this.advanceSetting.isSevenDays == 1 ? true : false;

          this.profitRatio = Number(
            JSON.parse(JSON.stringify(this.profitLayer.range[0].lowerLimit))
          ); //盈利比例
          this.profitSharingRatio = Number(
            JSON.parse(JSON.stringify(this.profitLayer.range[0].proportion))
          ); //盈利分成比例
          this.shareCeiling = Number(
            JSON.parse(JSON.stringify(this.profitLayer.maxLimit))
          ); //分成上限
          this.minBond = Number(
            JSON.parse(JSON.stringify(this.profitLayer.minimumAvailableMargin))
          ); //最小保证金

          if (this.riskIndemnityComment) {
            this.riskIndemnityCommentShow = true;
            this.lossRatio = Number(
              JSON.parse(
                JSON.stringify(this.riskIndemnityComment.range[0].lowerLimit)
              )
            ); //亏损比例
            this.IndemnityRatio = Number(
              JSON.parse(
                JSON.stringify(
                  this.riskIndemnityComment.range[0].commentProportion
                )
              )
            ); //赔付比例
            this.compensationTime = Number(
              JSON.parse(JSON.stringify(this.riskIndemnityComment.effectTime))
            ); //赔付生效时间
          } else {
            this.lossRatio = 0;
            this.IndemnityRatio = 0;
            this.compensationTime = 0;
          }
        }
      });
    },

    // 交易类型切换
    transactionTypeCheck(v) {
    
      this.tradePairList = this.transactionType == '币币' ?  ['USDT', 'KRW'] : ['USDT'];
      this.symbol = this.transactionType == '币币' ? this.symbol : 'USDT';
      this.getExchangeList();
    },

    // 查询交易所
    getExchangeList() {

      var supportType = this.transactionType == '币币' ? 'spot' :'contract_usdt';
      let payload = {
        supportType,
        token: sessionStorage.getItem("token"),
      };
      getExchangeList(payload).then((res) => {
        if (res.status == 200) {
          
          var datas = res.data.exchangeList;
          let exchangeList = [];
          datas.forEach((e) => {

            if (this.symbol == 'KRW' && e.exchangeName == 'Bithumb') {
                exchangeList.push(e);
            } else if (this.symbol != 'KRW' && e.exchangeName != 'Bithumb') {
              exchangeList.push(e);
            }
          });
          this.exchangeList = exchangeList;
          
          var exchange = [];
          for (let i = 0; i < this.exchangeList.length; i++) {

            const map = this.exchangeList[i];
            for (let j = 0; j < this.exchange.length; j++) {

              const item = this.exchange[j];
              if(map.exchangeName == item) {
                exchange.push(item);
              }
            }
          }

          if(!exchange.length) {
            const map = this.exchangeList[0];
            exchange.push(map.exchangeName);
          }
          exchange.push('添加 + ');

          this.exchange = exchange;

          this.exchangeChange();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 保留至少一个交易所
    exchangeRemove(v) {
      if (this.exchange.length == 0) {
        this.exchange.push(v);
        this.$message.warning(this.$t('m.至少存在一个交易所'));
      }
    },
    // 保留至少一个交易所
    exchangeChange(v) {

      this.exchange.splice(this.exchange.indexOf("添加 + "), 1);
      let exchangeIds = [];
      for (let key of this.exchangeList) {
        for (let item of this.exchange) {
          if (item == key.exchangeName) {
            exchangeIds.push(key.exchangeId);
          }
        }
      }
      console.log(this.exchangeList)
      console.log(this.exchange)
      console.log(exchangeIds)
      exchangeIds.length && this.getProjectPairById(exchangeIds);
      this.exchange.push("添加 + ");
    },
    // 查询交易对
    getProjectPairById(id) {
      let payload = {
        token: sessionStorage.getItem("token"),
        teamProjectId: this.projectInfo.teamProjectId,
        exchangeIds: id.join(","),
      };
      getProjectPairById(payload).then((res) => {
        if (res.status == 200) {

          let list = JSON.parse(JSON.stringify(res.data.coinList));

          list.indexOf("USDT") > -1 ? list.splice(list.indexOf("USDT"), 1) : "";

          list.sort();

          this.pairList = list;
          let projectPair = [];
          let oldList = this.pairTags
            ? JSON.parse(JSON.stringify(this.pairTags))
            : [];
          for (let key of list) {
            for (let item of oldList) {
              if (key == item) {
                projectPair.push(key);
              }
            }
          }
          this.pairTags = projectPair;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    

    //托管币种变化
    symbolChange(v) {
      this.getExchangeList();
    },

    // 交易对变化
    pairChange(v) {
      console.log(v);
      console.log(this.pairTags);
    },

    // 投入资金范围校验
    capitalRangCheck(v) {
      let min = Number(this.minInvest);
      let max = Number(this.maxInvest);
      console.log(v, min, max);
      if (v < min) {
        // this.$message.error("投入资金限制范围不能小于最小范围");
        this.investmentLimit = false;
        return;
      } else if (v > max) {
        // this.$message.error("投入资金限制范围不能大于最大范围");
        this.investmentLimit = false;
        return;
      } else if (this.max && Number(this.max) < this.min) {
        // this.$message.error("投入资金限制最大范围不能小于最小范围");
        this.investmentLimit = false;
        return;
      } else {
        this.investmentLimit = true;
      }

      console.log(v, this.maxInvest, this.minInvest);
    },

    // 比例输入校验
    proportionalCheck(v) {
      if (Number(v) < 0) {
        this.$message.error(this.$t('m.输入比例不能为负'));
        this.proporCheck = false;
        return;
      } else if (Number(v) > 100) {
        this.$message.error(this.$t('m.输入比例不能大于100%'));
        this.proporCheck = false;
        return;
      } else {
        this.proporCheck = true;
      }
      console.log(v);
    },

    //保存按钮
    updateBtn() {
      if (this.markShow !== "管理员") {
        if (this.pairTags.indexOf(this.symbol) > -1) {
          this.pairTags.splice(this.pairTags.indexOf(this.symbol), 1);
        }
        if (!this.pairTags || this.pairTags.length == 0) {
          this.$alert(
            this.$t('m.币对不能为空，请先选择币种'), 
          this.$t('m.币种不能为空'), {
            confirmButtonText: this.$t('m.知道啦'),
          });
          return;
        }
        this.pairTags.push(this.symbol);
      }

      if (!this.detailsOperationAuthority) {
        // this.$message.error("不是模板创建人，无权操作");
        this.$alert(
          this.$t('m.您没有权限使用该功能，敬请谅解！'), 
          this.$t('m.额，好像没有权限'), {
          confirmButtonText: this.$t('m.知道啦'),
        });
        return;
      }
      this.testBtn();
    },

    //保存基本信息
    testBtn() {
      // if (!this.detailsOperationAuthority) {
      //   this.$alert("您没有权限使用该功能，敬请谅解！", "额，好像没有权限", {
      //     confirmButtonText: "知道啦",
      //   });
      //   return;
      // }
      if (this.projectNav === "") {
        this.$message.error(this.$t('m.项目介绍不能为空！'));
        return;
      }
      let payload = {
        teamId: this.defaultTeam.teamId,
        token: sessionStorage.getItem("token"),
        projectName: this.projectName,
        projectIntroduct: this.projectIntroduction,
        teamProjectId: this.project.teamProjectId,
      };
      addProject(payload).then((res) => {
        if (res.status == 200) {
          if (
            this.showStatus == "未上线" &&
            this.projiectStatus !== "结算完成" &&
            this.markShow !== "管理员"
          ) {
            this.advancedSettingsSave();
          } else {
            this.$message.success(this.$t('m.修改成功'));
            this.getProject();
          }
          //   this.$emit("getProject");
          // this.getProject();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 高级设置保存/上线
    advancedSettingsSave(v) {
      let type = "save";
      if (v) {
        type = v;
      }
      // 交易类型转换
      let transactionType = this.transactionType.split("&");
      let newList = [];
      for (let key of transactionType) {
        if (key == "币币") {
          key = "spot";
        } else if (key == "USDT本位永续合约") {
          key = "contract_usdt";
        }
        newList.push(key);
      }

      // 交易所转换
      let exchangeList = this.exchangeList;
      let selectExchange = this.exchange;

      let newExchangeList = [];
      for (let i = 0; i < selectExchange.length; i++) {
        for (let j = 0; j < exchangeList.length; j++) {
          if (selectExchange[i] == exchangeList[j].exchangeName) {
            newExchangeList.push(exchangeList[j]);
          }
        }
      }

      if (Number(this.shareCeiling) < 0) {
        this.$message.error(this.$t('m.分成上限不能为负'));
        return;
      }
      if (Number(this.minBond) < 0) {
        this.$message.error(this.$t('m.最小可用保证金要求不能为负'));
        return;
      }
      console.log(this.investmentLimit);

      if (!this.investmentLimit) {
        this.$message.error(this.$t('m.投入范围限制不符合，请重新修改'));
        this.investmentLimit = true;
        return;
      }
      if (!this.proporCheck) {
        this.$message.error(this.$t('m.盈利分成比例不符合，请重新修改'));
        return;
      }
      if (v == "online") {
        if (this.tradePair) {
        } else {
          return this.$alert(
            this.$t('m.币对不能为空，请先选择币种'), 
            this.$t('m.币种不能为空'), {
            confirmButtonText: this.$t('m.知道啦'),
          });
        }
      }
      if (this.pairTags.indexOf(this.symbol) > -1) {
      } else {
        this.pairTags.push(this.symbol);
      }
      let payload = {
        teamProjectId: this.projectInfo.teamProjectId,
        token: sessionStorage.getItem("token"),
        type: type,
        tradePair: this.pairTags,
        advanceSetting: {
          exchangeLimit: newExchangeList,
          maxInvest: this.maxInvest,
          minInvest: this.minInvest,
          max: this.max,
          min: this.min,
          profitLayer: {
            maxLimit: this.shareCeiling,
            minimumAvailableMargin: this.minBond,
            range: [
              {
                lowerLimit: this.profitRatio,
                proportion: this.profitSharingRatio,
              },
            ],
          },
          riskIndemnityComment: null,
          spotOrContract: newList,
          symbol: this.symbol,
        },
      };
      if (this.riskIndemnityCommentShow) {
        if (Number(this.compensationTime) > 30) {
          this.$message.error(this.$t('m.赔付承诺生效时间不能大于30天'));
          return;
        }
        if (Number(this.compensationTime) < 0) {
          this.$message.error(this.$t('m.赔付承诺生效时间不能小于0天'));
          return;
        }
        if (Number(this.lossRatio) < 0) {
          this.$message.error(this.$t('m.亏损比例不能小于0%'));
          return;
        }
        if (Number(this.lossRatio) > 100) {
          this.$message.error(this.$t('m.亏损比例不能大于100%'));
          return;
        }
        if (Number(this.IndemnityRatio) < 0) {
          this.$message.error(this.$t('m.赔付比例不能小于0%'));
          return;
        }
        if (Number(this.IndemnityRatio) > 100) {
          this.$message.error(this.$t('m.赔付比例不能大于100%'));
          return;
        }

        let riskIndemnityComment = {
          effectTime: this.compensationTime,
          range: [
            {
              lowerLimit: this.lossRatio,
              commentProportion: this.IndemnityRatio,
            },
          ],
        };
        payload.advanceSetting.riskIndemnityComment = riskIndemnityComment;
      }

      payload.advanceSetting.isSevenDays = this.explainChecked ? 1 : 0;

      advanceSetting(payload).then((res) => {
        if (res.status == 200) {
          console.log(res.data);
          this.$message.success(this.$t('m.操作成功'));
          this.getProject();
        } else {
          this.$message.error(res.msg);
          this.pairTags.splice(this.pairTags.indexOf(this.symbol), 1);
        }
      });
    },
    // type 选择
    setType(v) {
      console.log(v);
      let payload = {
        teamProjectId: this.projectInfo.teamProjectId,
        token: sessionStorage.getItem("token"),
        type: v,
      };

      // 测试口令
      if (v == "copyPwd") {
        let projectParam = {
          weexUrl: "follw/UTrustInfo",
          param: { teamProjectId: this.projectInfo.teamProjectId },
          pageType: "open",
        };
        payload = {
          projectParam: JSON.stringify(projectParam),
          key: "trust" + this.projectInfo.teamProjectId,
          token: sessionStorage.getItem("token"),
          type: "copyPwd",
          teamProjectId: this.projectInfo.teamProjectId,
        };
        console.log(payload);
        advanceSetting(payload).then((res) => {
          if (res.status == 200) {
            console.log(res.data);
            if (v == "copyPwd") {
              this.testPassword =
                "[V口令]" +
                res.data.command +
                "#打开Vtrading，跟着量化大V一起飞";
              this.dialogVisible = true;
            }
          } else {
            this.$message.error(res.msg);
          }
        });
      } else if (v == "offline") {
        this.$confirm(
          this.$t('m.确认结算后项目将从app端移除，所有账户将被服务器接管，依次终止交易后自动兑换成托管币种，并在下一结算期结算保证金。'),
          this.$t('m.下线结算'),
          {
            confirmButtonText: this.$t('m.确定'),
            cancelButtonText: this.$t('m.取消'),
          }
        )
          .then(() => {
            console.log(payload);
            advanceSetting(payload).then((res) => {
              if (res.status == 200) {
                console.log(res.data);
                this.$message.success(this.$t('m.操作成功'));
                this.getProject();
              } else {
                this.$message.error(res.msg);
              }
            });
          })
          .catch(() => {
            console.log("取消");
          });
      } else if (v == "withdraw") {
        advanceSetting(payload).then((res) => {
          if (res.status == 200) {
            console.log(res.data);
            this.$message.success(this.$t('m.操作成功'));
            this.getProject();
          } else {
            this.$message.error(res.msg);
          }
        });
      } else if (v == "contentOnline") {
        if (this.markShow == "管理员") {
          this.testBtn();
        } else {
          advanceSetting(payload).then((res) => {
            if (res.status == 200) {
              console.log(res.data);
              this.$message.success(this.$t('m.操作成功'));
              this.getProject();
            } else {
              this.$message.error(res.msg);
            }
          });
        }
      } else if (v == "reset") {
        advanceSetting(payload).then((res) => {
          if (res.status == 200) {
            console.log(res.data);
            this.$message.success(this.$t('m.操作成功'));
            this.getProject();
          } else {
            this.$message.error(res.msg);
          }
        });
      }
    },
    // 复制口令
    copyTestPass() {
      var Url2 = document.getElementById("testPass");
      Url2.select(); // 选择对象
      document.execCommand("Copy");
      this.$message.success(this.$t('m.V口令已复制，去粘贴给好友吧'));
      this.dialogVisible = false;
    },
    // 删除项目
    deteleProject() {
      this.$confirm(
        this.$t('m.删除项目操作一经确认，不可恢复，请谨慎操作。'),
        this.$t('m.删除项目'),
        {
          confirmButtonText: this.$t('m.确定'),
          cancelButtonText: this.$t('m.取消'),
          type: "warning",
        }
      )
        .then(() => {
          let payload = {
            teamProjectId: this.project.teamProjectId,
            token: sessionStorage.getItem("token"),
          };
          console.log(payload);
          deleteProject(payload).then((res) => {
            if (res.status == 200) {
              this.$message.success(this.$t('m.操作成功'));
              this.$router.push("/");
            } else {
              this.$message.error(res.msg);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: this.$t('m.已取消删除'),
          });
        });
    },
  },
  created() {
    let detailsOperationAuthority = sessionStorage.getItem(
      "detailsOperationAuthority"
    );
    this.detailsOperationAuthority = JSON.parse(detailsOperationAuthority);
    let role = JSON.parse(sessionStorage.getItem("role"));
    this.markShow = role.mark;
    console.log(role);
  },
  mounted() {
    let projectInfo = sessionStorage.getItem("project");
    this.projectInfo = JSON.parse(projectInfo);
    let defaultTeam = sessionStorage.getItem("defaultTeam");
    this.defaultTeam = JSON.parse(defaultTeam);
    let project = JSON.parse(sessionStorage.getItem("project"));
    this.project = project;
    this.selectTemaBond();
    this.selectProjectListBond();
    this.getProject();
  },
  beforeCreate() {},
  beforeMount() {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {},
  activated() {},
};
</script>
<style lang='scss' scoped>
//@import url(); 引入公共css类
.projectSettings {
  min-width: 1600px;
  .top {
    padding: 20px 20px 0 20px;
    background: #fff;
    border-radius: 8px;
    margin-bottom: 20px;
    .project-operation {
      font-size: 16px;
      color: #102859;
      img {
        width: 12px;
        height: 12px;
        margin-left: 10px;
        cursor: pointer;
      }
    }
    .project-status {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 10px;
      padding-bottom: 20px;
      //   height: 54px;
      border-bottom: 1px solid #e6e9ee;
      .project-status-left {
        display: flex;
        align-items: center;

        .radius {
          width: 6px;
          height: 6px;
          border-radius: 50%;
          background: #55698a;
          margin-left: 20px;
          margin-right: 5px;
        }
        .transferBtn {
          display: flex;
          font-size: 12px;
          padding: 2px 8px;
          margin-left: 10px;
          border: 1px solid rgba(33, 116, 255, 0.3);
          color: #2174ff;
          cursor: pointer;
        }
        .yellow {
          background: #ff9600;
        }
        .red {
          background: #e12525;
        }
        .blur {
          background: #2174ff;
        }
        .yellowText {
          color: #ff9600;
        }
        .redText {
          color: #e12525;
        }
        .blurText {
          color: #2174ff;
        }
        .mark {
          display: inline-block;
          margin-left: 8px;
        }
      }
      .project-status-right {
        display: flex;
        align-items: center;
        span {
          display: flex;
          justify-content: center;
          align-items: center;
          min-width: 108px;
          height: 34px;
          padding: 0 10px;
          color: #fff;
          border-radius: 4px;
          margin-right: 20px;
          cursor: pointer;
        }

        .xiaxian {
          border: 1px solid #e12525;
          color: #e12525;
        }
        .ceshi {
          color: #2174ff;
          border: 1px solid #2174ff;
        }
        .update {
          background: #2174ff;
          color: #2174ff;
          border-radius: 4px;
          color: #fff;
        }
        .revoke {
          background: #758db5;
          border-radius: 4px;
          color: #fff;
        }
      }
    }
    .project-type-set {
      display: flex;
      align-items: center;
      padding: 20px 0;
      font-size: 16px;
      color: #102859;
      .deposit-operation {
        display: flex;
        align-items: center;
        .status {
          margin-left: 20px;
          margin-right: 10px;
        }
      }
      .project-visible {
        display: flex;
        align-items: center;
        margin-left: 70px;
        .status {
          color: #758db5;
          margin-left: 20px;
          margin-right: 10px;
        }
      }
      .update {
        padding: 1px 10px;
        font-size: 12px;
        color: #2174ff;
        border-radius: 2px;
        border: 1px solid rgba(33, 116, 255, 0.3);
        cursor: pointer;
      }
      .confirm {
        margin-right: 10px;
      }
    }
  }
  .bottom {
    background: #fff;
    border-radius: 8px;
    display: flex;
    min-height: 635px;
    .cont-left {
      width: 30%;
      padding: 20px;
      font-size: 16px;
      color: #102859;
      border-right: 1px solid #e6e9ee;
      .title {
        display: flex;
        align-items: center;
        border-bottom: 1px solid #e6e9ee;
        padding-bottom: 20px;
        .text {
          font-size: 12px;
          color: #102859;
          margin: 0 10px;
        }
        .draft {
          padding: 1px 10px;
          font-size: 12px;
          color: #2174ff;
          border-radius: 10px;
          border: 1px solid rgba(33, 116, 255, 0.3);
        }
      }
      .project-name {
        margin: 20px 0 30px 0;
        .name {
          margin-bottom: 10px;
        }
        .el-input {
          width: 100%;
        }
      }
      .project-introduction {
        .name {
          margin-bottom: 10px;
        }
        .el-input {
          width: 100%;
        }
      }
      .btn-warp {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 36px;
        div {
          width: 72px;
          height: 34px;
          text-align: center;
          line-height: 34px;
          background: #2174ff;
          border-radius: 4px;
          color: #fff;
        }
        .btn {
          cursor: pointer;
        }
      }
    }
    .cont-right {
      width: 70%;
      padding: 20px;
      .title {
        display: flex;
        align-items: center;
        border-bottom: 1px solid #e6e9ee;
        padding-bottom: 20px;
        font-size: 16px;
        color: #102859;
        .red {
          font-size: 12px;
          color: #e12525;
          margin-left: 10px;
        }
        .tips {
          font-size: 12px;
          color: #102859;
        }
      }
      .type-set {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #102859;
        margin: 20px 0 30px 0;
        .set-box {
          display: flex;
          align-items: center;
          width: 30%;
          .label {
            width: 95px;
          }
          .text {
            width: 75%;
            height: 42px;
            display: flex;
            align-items: center;
            border: 1px solid #e6e9ee;
            font-size: 14px;
            color: #102859;
            border-radius: 5px;
            padding-left: 15px;
          }
          .el-select {
            width: 75%;
          }
        }
        .currency {
          width: 26%;
        }
      }
      .capital-exchange {
        display: flex;
        justify-content: space-between;
        .capital-exchange-left {
          width: 45%;
          .left-title {
            margin-bottom: 10px;
            :last-child {
              font-size: 12px;
              color: #55698a;
              margin-left: 10px;
            }
          }
          .left-box {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .line {
              width: 32px;

              border: 1px solid #e6e9ee;
            }
            .el-input {
              width: 45%;
            }
          }
        }
        .capital-exchange-right {
          width: 45%;
          .right-title {
            margin-bottom: 10px;
          }
          .el-select {
            width: 100%;
          }
        }
      }
      .transaction-currency {
        margin-top: 35px;
        .transaction-currency-title {
          font-size: 16px;
          color: #102859;
          margin-bottom: 10px;
          :last-child {
            font-size: 12px;
            margin-left: 10px;
          }
        }
        .mandatory {
          display: flex;
          align-items: center;
          height: 40px;
          margin: 10px 0;
          .mandatory-title {
          }
          .mandatory-box {
            display: flex;
            justify-content: center;
            align-items: center;

            span {
              width: 80px;
              height: 30px;
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 4px;
              cursor: pointer;
              border: 1px solid #2174ff;
              color: #2174ff;
              margin-right: 10px;
            }
          }
        }
        .optional {
          display: flex;
          align-items: center;
          //   .el-select{
          //       width: 80%;
          //   }
        }
        // .transaction-currency-box {
        //   min-height: 90px;
        //   border-radius: 4px;
        //   border: 1px solid #e6e9ee;
        //   padding: 10px 0 0 10px;
        //   display: flex;
        //   flex-wrap: wrap;
        //   .curr-box {
        //     width: 85px;
        //     height: 30px;
        //     display: flex;
        //     justify-content: space-around;
        //     align-items: center;
        //     background: rgba(33, 116, 255, 0.07);
        //     border-radius: 4px;
        //     border: 1px solid #2174ff;
        //     color: #2174ff;
        //     margin-right: 10px;
        //     :last-child {
        //       cursor: pointer;
        //     }
        //   }
        // }
      }
      .commission {
        margin-top: 30px;
        i {
          font-style: normal;
        }
        ::v-deep.el-input__suffix {
          line-height: 40px;
        }
        .commission-title {
          font-size: 16px;
          color: #102859;
          margin-bottom: 10px;
        }
        .commission-warp {
          display: flex;
          //   justify-content: space-between;
          .commission-warp-left {
            width: 50%;
            margin-right: 5%;
            .profit-sharing {
              font-size: 16px;
              color: #102859;
              margin-right: 10px;
            }
            .required {
              color: #55698a;
            }
            .price-ratio {
              display: flex;
              justify-content: space-between;
              align-items: center;
              color: #102859;
              margin-top: 10px;
              .el-input {
                width: 25%;
              }
            }
            .share-box {
              margin-top: 20px;
              font-size: 16px;
              color: #102859;
              display: flex;
              .share-box-title {
                margin-bottom: 10px;
              }
              .share-boxItem {
                flex: 1;
              }
            }
          }
          .commission-warp-right {
            width: 45%;
            color: #102859;
            .profit-sharing {
              font-size: 16px;
              color: #102859;
              margin-right: 10px;
            }
            .required {
              color: #55698a;
            }
            .promisePay {
              display: flex;
              justify-content: space-between;
              align-items: center;
              color: #102859;
              margin-top: 10px;
              .el-input {
                width: 25%;
              }
            }
            .share-box {
              margin-top: 20px;
              .share-box-title {
                font-size: 16px;
                color: #102859;
                margin-bottom: 10px;
              }
              img {
                width: 14px;
                height: 14px;
                margin-left: 4px;
                cursor: pointer;
              }
            }
          }
        }
      }
      .btn-warp {
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
        padding-right: 5%;
        span {
          width: 72px;
          height: 34px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #fff;
          background: #2174ff;
          border-radius: 4px;
          cursor: pointer;
        }
        .btn-explain {
          width: 370px;
          display: flex;
          align-items: center;
          div {
            margin: 0 5px;
          }
          img {
            width: 14px;
            height: 14px;
            cursor: pointer;
          }
        }
      }
    }
  }
}
// .capital-exchange-right {
//   /deep/.el-icon-arrow-up:before {
//     content: "+";
//     font-weight: 700;
//     font-size: 20px;
//   }
//   /deep/.el-select .el-input .el-select__caret {
//     transform: none;
//   }
// }
.capitealChangeOption {
  /deep/.el-select__tags :first-child {
    .el-tag--light:last-child {
      border: 0;
      color: #2174ff;
      font-size: 14px;
      background: #fff;
      cursor: pointer;
      .el-icon-close {
        display: none;
        color: #909399;
        border: 1px solid #909399;
      }
    }
  }
}
// 划转
.transfer-box {
  width: 100%;
  .sel {
    display: flex;
    align-items: center;
    width: 100%;
    .left {
      width: -webkit-calc(100% - 30px);
      width: -moz-calc(100% - 30px);
      width: calc(100% - 30px);
      .left-box {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin: 10px 0;
        .title {
          margin-right: 20px;
        }
        img {
          margin: 0;
        }
      }
    }
    .right {
      img {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        cursor: pointer;
      }
    }
  }
  .curr,
  .tran-num {
    margin-top: 30px;
    .title {
      display: inline-block;
      margin-bottom: 10px;
    }
    .unit {
      display: flex;
      align-items: center;
      height: 40px;

      .all {
        border-left: 2px solid #758db5;
        display: flex;
        align-items: center;
        height: 20px;
        color: #2174ff;
        margin-left: 6px;
        padding-left: 6px;
        cursor: pointer;
      }
    }
    .available {
      margin: 8px 0 10px 0;
      color: #55698a;
      font-size: 12px;
    }
    .tips {
      color: #55698a;
      font-size: 12px;
      margin: 0;
    }
  }
}
</style>