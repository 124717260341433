<template>
  <div class="projectMember">
    <div class="content-Project-members">
      <div>
        <div class="add-members">
          <div class="add-title">{{ projectName }}({{$t('m.项目')}})</div>
          <div class="add-item">
            <div>共{{ memberList.length }}{{$t('m.名项目成员')}}</div>
            <div style="display: flex; align-items: center;font-size: 12px; margin: 0 5px 0 8px;">
              {{$t('m.成员权限')}}
              <span
                class="headerVipItem"
                @click="jurisdictionTips = true">?</span>
            </div>
          </div>
        </div>
        <div class="add-img">
          <img
            @click="getUnSelectTeamMember"
            src="../../assets/icon/编组 2@2x.png"
            alt=""
          />
        </div>
      </div>
      <div class="members-title">
        <span class="width-setting">{{$t('m.成员')}}</span>
        <span class="width-setting">{{$t('m.备注')}}</span>
        <span class="width-setting">{{$t('m.账号')}}</span>
        <div class="jurisdiction width-setting">
          <span>{{$t('m.权限')}}</span>
        </div>
      </div>
      <div class="members-box" v-for="(item, idx) in memberList" :key="idx">
        <div class="width-setting">
          <img :src="item.avatar" alt="" v-if="item.avatar" />
          <img src="../../assets/image/img.png" alt="" v-else />
          <span style="margin-top: 2px">{{ item.nickName }}</span>
          <span
            v-if="item.isDefault === 1"
            style="display: flex; align-items: center; margin-left: 5px"
            >({{$t('m.默认')}})
            <el-tooltip
              class="item"
              effect="light"
              :content="tipsOne"
              placement="top-start"
            >
              <span style="margin-top: 2px" class="headerVipItem">?</span>
            </el-tooltip>
          </span>
        </div>
        <span class="width-setting">{{ item.userMark }}</span>
        <span class="width-setting">{{ item.phoneUnique }}</span>
        <span
          v-if="item.mark == $t('m.超级管理员')"
          class="members-jurisdiction width-setting"
          >{{ item.mark }}</span
        >
        <div v-else class="members-jurisdiction width-setting">
          <!-- <span v-if="item.isDefault == 1">默认操作员</span> -->
          <span>{{ item.mark }}</span>
          <el-dropdown trigger="click" placement="bottom-start">
            <span class="el-dropdown-link">
              <i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <!-- <el-dropdown-item>权限管理</el-dropdown-item> -->
              <el-dropdown-item
                @click.native="settingOperator(item.userId)"
                v-if="item.isDefault != 1"
                >{{$t('m.设置为默认操作员')}}</el-dropdown-item
              >
              <!-- <el-dropdown-item
                @click.native="updateMemberRole(item.userId)"
                v-else
                >设置为操作员</el-dropdown-item
              > -->
              <el-dropdown-item @click.native="updateMemberRole(item.userId)"
                >{{$t('m.设置为管理员')}}</el-dropdown-item
              >
              <el-dropdown-item @click.native="deleteProjectMember(item.userId)"
                >{{$t('m.移出项目')}}</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>
    <!-- 邀请成员 -->
    <template v-if="dialogVisible">
      <el-dialog
        :title="$t('m.邀请成员')"
        :visible.sync="dialogVisible"
        width="30%"
        :before-close="handleClose"
      >
        <div class="dialog-content">
          <div class="title">
            <span>{{$t('m.成员')}}</span>
            <span>{{$t('m.账号')}}</span>
            <span>{{$t('m.选择')}}</span>
          </div>
          <div class="content" v-for="(item, idx) in notMemberList" :key="idx">
            <div>
              <img :src="item.avatar" alt="" v-if="item.avatar" />
              <img src="../../assets/image/img.png" alt="" v-else />
              <span class="members-name">{{ item.nickName }}</span>
              <!-- <span>(超级管理员)</span> -->
            </div>
            <span class="members-phone">{{ item.phoneUnique }}</span>
            <el-checkbox
              @change="change(item)"
              :checked="item.type == true"
              class="members-check"
              :key="new Date().getTime()"
            ></el-checkbox>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">{{$t('m.取 消')}}</el-button>
          <el-button type="primary" @click="addProjectMember">{{$t('m.保 存')}}</el-button>
        </span>
      </el-dialog>
    </template>
    <!-- 权限解释 -->
    <el-dialog
      :title="$t('m.成员权限')"
      center
      :visible.sync="jurisdictionTips"
      width="570px"
      top="260px"
    >
      <div class="jurisdiction">
        <div>
          <span class="title">{{$t('m.超级管理员')}}</span>
          <span>({{$t('m.团队唯一')}})</span>
        </div>
        <div style="border-bottom: 1px solid #e6e7ed" class="box">
          <span>{{$t('m.创建/删除项目')}}</span>
          <span>{{$t('m.管理团队成员')}}</span>
          <span>{{$t('m.兼容管理员')}}</span>
        </div>
        <div>
          <span class="title">{{$t('m.管理员')}}</span>
          <span>({{$t('m.项目唯一')}})</span>
        </div>
        <div style="border-bottom: 1px solid #e6e7ed" class="box">
          <span>{{$t('m.管理项目')}}</span>
          <span>{{$t('m.分流用户')}}</span>
          <span>{{$t('m.兼容操作员')}}</span>
        </div>
        <div>
          <span class="title">{{$t('m.操作员')}}</span>
        </div>
        <div class="box" style="margin: 0">
          <span>{{$t('m.配置自动交易')}}</span>
          <span>{{$t('m.批量操作')}}</span>
        </div>
      </div>
      <div class="btn" @click="jurisdictionTips = false">{{$t('m.知道啦')}}</div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getProjectMember,
  getUnSelectTeamMember,
  addProjectMember,
  settingOperator,
  deleteProjectMember,
  updateMemberRole,
} from "../../request/api";
export default {
  components: {},
  data() {
    return {
      dialogVisible: false,
      projectInfo: null, //项目信息
      defaultTeam: null, //团队信息
      memberList: [], //成员列表
      notMemberList: [], //未加入成员
      jurisdictionTips: false, //权限解释
      projectName:
        JSON.parse(sessionStorage.getItem("project")).projectName || "",
      tipsOne: this.$t('m.入金用户将自动分配给默认操作员'),
    };
  },
  computed: {},
  watch: {},
  methods: {
    handleClose(done) {
      done();
    },
    // 查询项目成员
    getProjectMember() {
      let payload = {
        token: sessionStorage.getItem("token"),
        teamProjectId: JSON.parse(sessionStorage.getItem("project"))
          .teamProjectId,
      };
      getProjectMember(payload).then((res) => {
        if (res.status == 200) {
          this.memberList = res.data.memberList;
          console.log(res.data);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 查询未选成员
    getUnSelectTeamMember() {
      this.dialogVisible = true;
      let payload = {
        teamId: this.defaultTeam.teamId,
        token: sessionStorage.getItem("token"),
        teamProjectId: this.projectInfo.teamProjectId,
      };
      getUnSelectTeamMember(payload).then((res) => {
        if (res.status == 200) {
          this.notMemberList = res.data.memberList;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 选中
    change(item) {
      item.type = !item.type ? true : false;
      console.log(item);
    },
    // 添加成员
    addProjectMember() {
      let list = this.notMemberList;
      let newList = list.filter(function (item) {
        return item.type;
      });
      let userIds = [];
      for (let i = 0; i < newList.length; i++) {
        userIds.push(newList[i].userId);
      }
      console.log(newList, userIds);
      let payload = {
        teamProjectId: this.projectInfo.teamProjectId,
        token: sessionStorage.getItem("token"),
        userIds: userIds,
      };
      addProjectMember(payload).then((res) => {
        if (res.status == 200) {
          console.log(res.data);
          this.getProjectMember();
          this.dialogVisible = false;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 设置默认操作员
    settingOperator(id) {
      let payload = {
        teamProjectId: this.projectInfo.teamProjectId,
        token: sessionStorage.getItem("token"),
        userId: id,
      };
      settingOperator(payload).then((res) => {
        if (res.status == 200) {
          this.$message.success(this.$t('m.设置成功'));
          this.getProjectMember();
          console.log(res.data);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 修改成员角色
    updateMemberRole(id) {
      console.log(this.teamProjectInfo);
      let payload = {
        token: sessionStorage.getItem("token"),
        roleName: "MANAGE",
        byUserId: id,
        teamProjectId: this.projectInfo.teamProjectId,
      };
      updateMemberRole(payload).then((res) => {
        if (res.status == 200) {
          this.$message.success(this.$t('m.设置成功'));
          this.getProjectMember();
          console.log(res.data);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 移除项目成员
    deleteProjectMember(id) {
      this.$confirm(
        this.$t('m.退出项目后，该成员所属的用户将被分配给项目管理员'),
        this.$t('m.退出项目'),
        {
          confirmButtonText: this.$t('m.确定'),
          cancelButtonText: this.$t('m.取消'),
          closeOnClickModal: false,
        }
      )
        .then(() => {
          console.log(id);
          let payload = {
            teamProjectId: this.projectInfo.teamProjectId,
            token: sessionStorage.getItem("token"),
            userId: id,
          };
          deleteProjectMember(payload).then((res) => {
            if (res.status == 200) {
              this.$message.success(this.$t('m.移出成功'));
              this.getProjectMember();
              console.log(res.data);
            } else {
              this.$message.error(res.msg);
            }
          });
        })
        .catch(() => {});
    },
  },
  created() {},
  mounted() {
    let projectInfo = JSON.parse(sessionStorage.getItem("project"));
    this.projectInfo = projectInfo;
    let defaultTeam = sessionStorage.getItem("defaultTeam");
    this.defaultTeam = JSON.parse(defaultTeam);
    console.log(this.projectInfo, this.defaultTeam);
    this.getProjectMember();
  },
  beforeCreate() {},
  beforeMount() {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {},
  activated() {},
};
</script>
<style lang='scss' scoped>
.projectMember {
  background: #fff;
  margin-top: 20px;
  height: 800px;
  //   项目成员
  .content-Project-members {
    width: 100%;
    .add-members {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      color: #313237;
      height: 66px;
      background: #f0f7ff;
      font-size: 14px;
      padding: 20px 30px;
      .add-item {
        display: flex;
        font-size: 14px;
        margin-top: 16px;
        color: #5e5f64;
      }
    }
    .add-img {
      position: relative;
      display: flex;
      justify-content: flex-end;
      width: 100%;
      height: 30px;
      img {
        position: relative;
        width: 64px;
        height: 64px;
        right: 50px;
        top: -30px;
        cursor: pointer;
      }
    }
    .width-setting {
      width: 250px;
      .mark {
        margin-left: 10px;
        font-size: 12px;
        color: #999;
      }
    }
    .members-title {
      height: 40px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 14px;
      color: #666;
      border-bottom: 1px solid #f5f5f5;
      padding: 0 20px;
      .jurisdiction {
        text-align: right;
      }
      .members-jurisdiction {
        margin-left: 8px;
      }
    }
    .members-box:hover {
      background: #e5e5e5;
    }
    .members-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 60px;
      font-size: 14px;
      color: #666;
      padding: 0 20px;
      div {
        display: flex;
        align-items: center;
        img {
          width: 40px;
          height: 40px;
          border-radius: 50px;
          background: #999;
          margin-right: 8px;
        }
      }
      .members-jurisdiction {
        display: flex;
        justify-content: flex-end;
        cursor: pointer;
        span {
          margin-right: 8px;
        }
      }
      ::v-deep.el-dropdown-link {
        margin-right: 0px !important;
      }
    }
  }
  .dialog-content {
    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 10px;
      margin-bottom: 10px;
      border-bottom: 1px solid #f5f5f5;
      :nth-child(1) {
        width: 40%;
      }
      span {
        width: 25%;
      }
      :last-child {
        text-align: right;
      }
    }
    .content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 14px;
      color: #333;
      margin-bottom: 20px;
      div {
        width: 40%;
        display: flex;
        align-items: center;
        img {
          width: 40px;
          height: 40px;
          border-radius: 50%;
        }
        .members-name {
          margin: 0 8px;
        }
        :last-child {
          color: #999999;
          font-size: 12px;
        }
      }
      .members-phone,
      .members-check {
        width: 25%;
      }
      .members-check {
        text-align: right;
      }
    }
  }
}
.headerVipItem {
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #c1c3cb;
  border-radius: 8px;
  font-size: 12px;
  color: #fff;
  margin: 0 0 0 4px;
  cursor: pointer;
}
.jurisdiction {
  color: #999;
  font-size: 12px;
  line-height: 20px;
  .title {
    color: #333;
    margin-right: 5px;
  }
  .box {
    padding: 3px 0 18px;
    margin: 0 0 20px;
  }
  span {
    margin-right: 10px;
  }
}
.btn {
  color: #007aff;
  display: flex;
  font-size: 14px;
  justify-content: flex-end;
  margin-right: 10px;
  cursor: pointer;
}
</style>