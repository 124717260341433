<template>
  <!-- 订单链消息 -->
  <div class="strategyMessage">
    <el-container>

      <el-header>
        <div class="header-warp">
          <i class="el-icon-arrow-left" @click="goBack"></i>
          <span>{{$t('m.订单链消息')}}</span>
          <span></span>
        </div>
      </el-header>

      <el-container>
        <!-- main -->
        <el-main>
          <div class="content" v-if="messageList.length != 0">
            <div class="content-item">

              <div class="log-box" v-for="(item, idx) in messageList" :key="idx">

                <div class="log-item">
                  <div>{{ item.createTime }}</div>
                  <div>[{{ publicCode }}]</div>
                  <div>[{{ item.transactionPair }}]</div>

                  <div>[{{ item.log.tradeType == 0 ? $t('m.卖出') : $t('m.买入')}}]</div>


              
                  <div v-if="item.log.price==0 && item.log.tradeType == 0">[{{$t('m.价格：') + $t('市价卖出')}}]</div>
                  <div v-if="item.log.price==0">[{{$t('m.价格：') + $t('m.市价买入')}}]</div>
                  <div v-else>[{{$t('m.价格：') + item.log.price}}]</div>
   
        
                  <div>[{{$t('m.数量' + '：' + item.log.amount) 
                    +  item.transactionType == "contract_usdt" ? $t('m.张') : item.log.counterCoin}}]</div>

                  <div :style="{ color: item.log.states == '1' ? 'red' : '' }">[{{getStatus(item.log)}}]</div>
                </div>

                <div v-if="item.log.error" style="margin-bottom: 18px; color: #606266">
                  {{ item.log.error }}
                </div>
              </div>
            </div>

            <div class="pagination-warp">
              <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :page-sizes="[10, 20, 30, 40, 50]"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total"
              >
              </el-pagination>
            </div>
          </div>
          <div v-else class="defaultPage">
            <img src="../../assets/image/img_01@2x (1).png" alt="" />
            <span>{{$t('m.暂无数据')}}</span>
          </div>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import moment from "moment";
import { findByIdOrderLog } from "../../request/api";
export default {
  components: {},
  data() {
    return {
      moment,
      templateId: null, //模板ID
      project: null,
      logList: [],
      pageNo: 1,
      pageSize: 10,
      total: 0,
      orderChainId: null,
      messageList: [],
    };
  },
  computed: {},
  watch: {},
  methods: {
    
    //获取委托状态
    getStatus(log) {

      var str;
      if(log.status == 0 && log.states == 0) {
        str = '委托成功';
      } else if(log.status == 0 && log.states != 0) {
        str = '委托失败';
      } else if(log.status != 0 && log.states == 0) {
        str = '买入成功';
      } else if(log.status != 0 && log.states != 0) {
        str = '买入失败';
      }

      return this.$t('m.'+str);
    },

    // 返回上一页
    goBack() {
      this.$router.back(-1);
    },

    // 查询订单链消息
    findByIdOrderLog() {
      let payload = {
        token: sessionStorage.getItem("token"),
        orderChainId: this.orderChainId,
        pageNo: this.pageNo,
        pageSize: this.pageSize,
      };

      findByIdOrderLog(payload).then((res) => {

        if (res.status == 200) {
          
          if (res.data.length != 0) {
            let orderLogList = res.data.orderLogList;
            orderLogList.forEach((e) => {
              e.log = JSON.parse(e.log);
              e.createTime = this.timestampToTime(e.createTime);
              if (e.log.counterCoin == "btc") {
                e.log.amount = e.log.amount.slice(
                  0,
                  e.log.amount.indexOf(".") + 7
                );
              } else {
                e.log.amount = e.log.amount.slice(
                  0,
                  e.log.amount.indexOf(".") + 5
                );
              }
            });
            this.messageList = orderLogList;
            this.total = orderLogList.length;
          }
        } else {
          this.$message.error(res.msg);
        }
      });
    },

    handleSizeChange(val) {
      this.pageSize = val;
      this.findByIdOrderLog();
    },

    handleCurrentChange(val) {
      this.pageNo = val;
      this.findByIdOrderLog();
    },
    
    //日期换算
    timestampToTime(timestamp) {
      var date = new Date(timestamp); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
      var Y = date.getFullYear() + "-";
      var M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      var D =
        date.getDate() < 10
          ? "0" + date.getDate() + " "
          : +date.getDate() + " ";
      var h =
        date.getHours() < 10
          ? "0" + date.getHours() + ":"
          : date.getHours() + ":";
      var m =
        date.getMinutes() < 10
          ? "0" + date.getMinutes() + ":"
          : date.getMinutes() + ":";
      var s =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      return Y + M + D + h + m + s;
    },
  },
  created() {},
  mounted() {},
  beforeCreate() {},
  beforeMount() {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {},
  activated() {
    this.orderChainId = this.$route.query.orderChainId;
    this.publicCode = this.$route.query.exchangeName;
    this.findByIdOrderLog();
  },
  deactivated() {
    this.$destroy();
  },
};
</script>
<style lang='scss' scoped>
.strategyMessage {
  width: 100%;
  height: 100%;
  .el-container {
    height: 100%;
    background: #eff2f5;
  }
  .el-header {
    background-color: #fff;
    color: #333;
    text-align: center;
    line-height: 60px;
    margin-bottom: 20px;
    .header-warp {
      display: flex;
      justify-content: space-between;
      align-items: center;
      i {
        cursor: pointer;
      }
    }
  }
  .el-main {
    color: #333;
    padding: 0 10%;
    font-size: 14px;
    .content {
      background: #fff;
      border-radius: 8px;
      padding: 20px;
      .log-box {
        color: #666;
        font-size: 14px;
        line-height: 30px;
        border-bottom: 1px solid #eaeaec;
        span {
          display: inline-block;
          margin-right: 10px;
          padding: 10px 0;
        }
      }
      .pagination-warp {
        display: flex;
        justify-content: flex-end;
        margin: 20px 0;
      }
    }
    .defaultPage {
      height: 500px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: #fff;
      span {
        margin-top: 10px;
        color: #999;
        font-size: 12px;
      }
    }
  }
}
.log-item {
  display: flex;
  align-items: center;
  margin-right: 10px;
  padding: 20px 0;
}
</style>