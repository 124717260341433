<template>
  <div class="navItem">
    <div class="nav">
      <div class="topItem">
        <div class="topData">
          <template v-for="(item, index) in Tab">
            <div
              :class="index == tableActive ? 'brdStyle' : ''"
              class="topDataItem"
              @click="tableDataBtn(index)"
              :key="index">
              {{ $t('m.'+item) }}
            </div>
          </template>
        </div>
        <div
          class="tableStyle"
          style="border: 1px solid #e6e9ee; padding-bottom: 20px">
          <div class="topColumn">
            <SearchBox
              class="searchStyle"
              @btnSubmit="btnSubmit"
              @btnBack="btnBack"
              :searchData="searchData"
              ref="childSearch"/>
            <div class="btnBox">
              <div @click="stopBatch">{{$t('m.批量修改')}}</div>
              <div @click="stopSubmit">{{$t('m.终止订单链')}}</div>
            </div>
          </div>
          <DetailsData
            @selectRow="selectRow"
            :modify="modify"
            :data="tableData"
            :rowStyleData="rowStyleData"
            :statesShow="statesShow"
            :label="orderData"
            @modifyBtn="modifyBtn"
            class="detailsData"
          />
        </div>
      </div>
      <div class="paginStyle">
        <div></div>
        <div v-if="listNumber >= 10" class="paginCenter">
          <span>{{$t('m.共计订单&(1)条').replace('&(1)', listNumber)}}</span>
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="currentPage"
            :page-size="10"
            background
            layout="prev, pager, next, jumper"
            :total="listNumber"
          >
          </el-pagination>
        </div>
        <div>{{$t('m.已选择订单&(1)条').replace('&(1)', selectRowList ? selectRowList.length : 0)}}</div>
      </div>
    </div>
    <div class="content">
      <div v-if="!showIcon" class="bottom">
        <span class="bottom-text">{{$t('m.操作日志')}}</span>
        <div @click="openLog" class="bottom-icon">
          <img
            class="bottom-img"
            src="../../assets/icon/more_top.png"
            alt=""
          />
        </div>
        <div class="more-btn" @click="toLogDetail">
          {{$t('m.更多')}}</div>
      </div>
      <div class="selectBtn" v-if="showIcon">
        <div class="bottom2">
          <span class="bottom-text">{{$t('m.操作日志')}}</span>
          <div @click="backLog" class="bottom-icon">
            <img
              class="bottom-img"
              src="../../assets/icon/more_bottom.png"
              alt=""
            />
          </div>
          <div class="more-btn" @click="toLogDetail">{{$t('m.更多')}}</div>
        </div>
        <div class="navSelect">
          <div v-if="orderLogList.length == 0">{{$t('m.暂无记录')}}</div>
          <div class="log-box" v-for="(item, idx) in orderLogList" :key="idx">
            <div class="log-item">
              <div>{{ item.createTime }}</div>
              <div>
                [{{ item.remarkCode ? item.remarkCode : item.publicCode }}]
              </div>
              <div>[{{ item.transactionPair }}]</div>
              <div>[{{ item.log.tradeType == 0 ? $t('m.卖出') : $t('m.买入') }}]</div>
              <div>[{{$t('m.价格')}}：{{ item.log.price }}]</div>
              <div>
                [{{$t('m.数量')}}:{{ item.log.amount
                }}{{
                  item.transactionType == "contract_usdt"
                    ? $t('m.张')
                    : item.log.counterCoin
                }}]
              </div>


              <div :style="{ color: item.log.states == '1' ? 'red' : '' }">
                {{getState(item.log)}}
              </div>
            </div>
            <div
              v-if="item.log.error"
              style="margin-bottom: 18px; color: #606266">
              {{ item.log.error }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <el-dialog
      title="订单链"
      :visible.sync="dialogVisible"
      width="70%"
      :before-close="handleClose"
    >
      <div>
        <OrderChain
          ref="child"
          :transactionType="transactionType"
          :controlsPrice="controlsPrice"
        />
      </div>
      <span v-if="!btnSubmitShow" slot="footer" class="dialog-footer">
        <el-button
          @click="
            dialogVisible = false;
            batchModify = false;
          ">
          {{$t('m.取 消')}}</el-button>
        <el-button type="primary" @click="updateOrderChain()">
          {{$t('m.确 定')}}</el-button>
      </span>
      <span v-else slot="footer" class="dialog-footer">
        <el-button
          @click="
            dialogVisible = false;
            batchModify = false;">
          {{$t('m.关 闭')}}</el-button>
      </span>
    </el-dialog>

    <el-dialog
      title="终止订单"
      :visible.sync="activeShow"
      width="400px"
      center
      :before-close="handleClose"
      :close-on-click-modal="false">
      <div class="dialog-nav">
        <div class="dialog-top">{{$t('m.确认终止后订单将自动撤消未成交的委托单。')}}</div>
      </div>
      <div slot="footer">
        <div class="dialog-footer">
          <div class="footerBack" @click="activeShow = false">
            {{$t('m.取消')}}</div>
          <div class="footerBtn" @click="activeBtn">
            {{$t('m.确定')}}</div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import DetailsData from "../../components/detailsData.vue";
import SearchBox from "../../components/searchBox.vue";
import OrderChain from "../../components/orderChain.vue";
import {
  queryOrderChainList,
  findByIdOrderChain,
  updateOrderChain,
  userManagementExchangelist,
  batchUpdateOrderChain,
  batchOrderChainEnd,
  queryOrderLog,
} from "../../request/api";

export default {
  components: { DetailsData, OrderChain, SearchBox },
  data() {
    return {
      dialogVisible: false,
      Tab: ["币币", "合约"],
      // 表格数据
      tableData: [],
      // 搜索数据
      searchTable: [],
      //搜索框数据
      searchData: [
        {
          name: "标签",
          filterable: true,
          type: "input",
        },
        {
          name: "交易所",
          data: [this.$t('m.全部')],
        },
        {
          name: "账户",
          type: "input",
        },
        {
          name: "订单链状态",
          data: [this.$t('m.执行中'), this.$t('m.已完成')],
        },
        // {
        //   name: "总投入",
        //   type: "inputTwo",
        // },
      ],
      //表格样式
      rowStyleData: [
        {
          columnIndex: 6,
          style: [
            {
              state: "执行中",
              style: "color:#2174FF",
            },
            {
              state: "已完成",
              style: "color:#102859",
            },
            {
              state: "订单错误",
              style: "color:#E12525",
            },
          ],
        },
        {
          columnIndex: 7,
          style: [
            {
              state: "买入",
              style: "color:#E12525",
            },
            {
              state: "卖出",
              style: "color:#26B34B",
            },
            {
              state: "开多",
              style: "color:#E12525",
            },
            {
              state: "平空",
              style: "color:#E12525",
            },
            {
              state: "开空",
              style: "color:#26B34B",
            },
            {
              state: "平多",
              style: "color:#26B34B",
            },
          ],
        },
        {
          columnIndex: 3,
          style: [
            {
              state: "true3",
              style: "color:#E12525",
            },
            {
              state: "zero3",
              style: "color:#102859",
            },
            {
              state: "false3",
              style: "color:#26B34B",
            },
          ],
        },
      ],
      // 表头数据
      orderData: [
        {
          title: "标签",
          nav: "label",
        },
        {
          title: "账户",
          nav: "publicPeopleName",
        },
        {
          title: "初始投入",
          nav: "investNum",
        },
        {
          title: "订单收益",
          nav: "profit",
        },
        {
          title: "执行时长",
          nav: "createTime",
        },
        {
          title: "订单链",
          nav: "order",
        },
        {
          title: "当前订单状态",
          nav: "states",
        },
        {
          title: "当前方向",
          nav: "orderDirection",
        },
      ],
      orderDataList: [
        [
          {
            title: "标签",
            nav: "label",
          },
          {
            title: "账户",
            nav: "publicPeopleName",
          },
          {
            title: "初始投入",
            nav: "investNum",
          },
          {
            title: "订单收益",
            nav: "profit",
          },
          {
            title: "执行时长",
            nav: "createTime",
          },
          {
            title: "订单链",
            nav: "order",
          },
          {
            title: "当前订单状态",
            nav: "states",
          },
          {
            title: "当前方向",
            nav: "orderDirection",
          },
        ],
        [
          {
            title: "标签",
            nav: "label",
          },
          {
            title: "账户",
            nav: "publicPeopleName",
          },
          {
            title: "初始投入",
            nav: "investNum",
          },
          {
            title: "订单收益",
            nav: "profit",
          },
          {
            title: "执行时长",
            nav: "createTime",
          },
          {
            title: "订单链",
            nav: "order",
          },
          {
            title: "当前订单状态",
            nav: "states",
          },
        ],
      ],
      project: null,
      tableActive: 0, //table切换
      showIcon: false, //操作日志
      currentPage: 1,
      orderLogList: [],
      modify: true, //修改按钮
      strategyType: 1,

      orderList: null, //订单链详情
      orderChainId: null, //当前订单Id
      selectRowList: [], //选中数据
      batchModify: false, //批量修改
      exchangeLimit: null, //交易所列表
      activeShow: false, //二次确认弹框
      listNumber: null, //列表数量
      detailsOperationAuthority: null,
      statesShow: false,

      transactionType: "spot",
      controlsPrice: null,
      btnSubmitShow: false, //订单链查看按钮
    };
  },
  methods: {

    //获取状态
    getState(log) {

      var str;
      if(log.status == 0 && log.states == 0) {
        str = '委托成功'
      } else if(log.status == 0 && log.states != 0) {
        str = '委托失败'
      } 
      
      else if(log.tradeType == 0 && log.states == 0) {
        str = '卖出成功'
      } else if(log.tradeType == 0 && log.states != 0) {
        str = '卖出失败'
      } 
      
      else if(log.states == 0) {
        str = '买入成功'
      } else if(log.states != 0) {
        str = '买入失败'
      }
      
      return this.$t('m.'+str);
    },

    //币币合约切换按钮
    tableDataBtn(e) {
      //去掉合约订单链收益  原因:算不准
      this.tableActive = e;
      this.$refs.childSearch.btnBack();
      //获取日记信息
      let payloadData = {
        token: sessionStorage.getItem("token"),
        teamProjectId: this.project.teamProjectId,
        transactionType: this.tableActive == 0 ? "spot" : "contract_usdt",
        pageNo: 1,
        pageSize: 5,
      };
      this.queryOrderLog(payloadData);
      if (e == 1 && this.chainStyle == false) {
        this.rowStyleData.forEach((item) => {
          item.columnIndex = item.columnIndex - 1;
        });
        this.chainStyle = true;
      }
      if (e == 0 && this.chainStyle == true) {
        this.rowStyleData.forEach((item) => {
          item.columnIndex = item.columnIndex + 1;
        });
        this.chainStyle = false;
      }
      sessionStorage.setItem(
        "tableActive",
        this.tableActive == 0 ? "spot" : "contract_usdt"
      );
    },
    handleSizeChange() {
      console.log(2);
    },
    //分页
    handleCurrentChange(e) {
      console.log(e);
      this.currentPage = e;
      let payload = {
        token: sessionStorage.getItem("token"),
        teamProjectId: this.project.teamProjectId,
        pageSize: 10,
        pageNo: e,
        states: "EXECUTE",
        transactionType: this.tableActive == 0 ? "spot" : "contract_usdt",
        roleUserId: this.$route.query.userId ? this.$route.query.userId : "",
        ...this.searchTable,
      };

      if (sessionStorage.getItem("searchData")) {
        let searchData = JSON.parse(sessionStorage.getItem("searchData"));
        console.log(this.exchangeLimit);
        payload.exchangeId =
          searchData[1] == this.$t('m.全部')
            ? ""
            : searchData[1] == "Huobi"
            ? 3
            : searchData[1] == "Binance"
            ? 4
            : 29;
        payload.states =
          searchData[3] == this.$t('m.执行中')
            ? "EXECUTE"
            : searchData[3] == this.$t('m.已完成')
            ? "COMPLETED"
            : "";
        payload.publicPeopleName = searchData[2];
        payload.label = searchData[0];
        if (searchData[3] == this.$t('m.已完成')) {
          this.orderData = this.orderDataList[1];
        } else {
          this.orderData = this.orderDataList[0];
        }
      }
      sessionStorage.setItem("pageNo", JSON.stringify(e));
      this.queryOrderChainList(payload);
    },
    typeSelest() {
      console.log(1);
    },
    //查询按钮
    btnSubmit(data) {
      let payload = {
        token: sessionStorage.getItem("token"),
        teamProjectId: this.project.teamProjectId,
        pageSize: 10,
        pageNo: 1,
        transactionType: this.tableActive == 0 ? "spot" : "contract_usdt",
      };
      let orderData;
      if (data[3] == "已完成") {
        orderData = this.orderDataList[1];
        this.statesShow = true;
      } else {
        orderData = this.orderDataList[0];
        this.statesShow = false;
      }
      if (this.tableActive == 1) {
        orderData.forEach((e, index) => {
          if (e.title == "订单收益") {
            return orderData.splice(index, 1);
          }
        });
      } else {
        console.log(orderData);
        let orderTrue;
        orderData.forEach((e, index) => {
          if (e.title == "订单收益") {
            return (orderTrue = true);
          }
          if (index == orderData.length - 1 && !orderTrue) {
            return orderData.splice(3, 0, {
              title: "订单收益",
              nav: "profit",
            });
          }
        });
      }
      this.orderData = orderData;
      if (
        data[0] !== "" ||
        data[1] !== "全部" ||
        data[2] !== "" ||
        data[3] !== "执行中"
      ) {
        this.searchTable = {
          states:
            data[3] == "执行中"
              ? "EXECUTE"
              : data[3] == "已完成"
              ? "COMPLETED"
              : "",
          publicPeopleName: data[2],
          label: data[0],
        };
        this.exchangeLimit.forEach((e) => {
          if (e.exchangeName == data[1]) {
            this.searchTable.exchangeId = e.exchangeId;
          }
        });
      } else {
        payload.states = "EXECUTE";
        this.searchTable = [];
      }
      payload = {
        ...payload,
        ...this.searchTable,
        roleUserId: this.$route.query.userId ? this.$route.query.userId : "",
      };
      this.queryOrderChainList(payload);
      this.currentPage = 1;
      sessionStorage.setItem("searchData", JSON.stringify(data));
    },
    //重置按钮
    btnBack() {
      console.log("重置按钮1111111111111");
      sessionStorage.removeItem("searchData");

      let orderData = this.orderDataList[0];
      if (this.tableActive == 1) {
        orderData.forEach((e, index) => {
          if (e.title == "订单收益") {
            return orderData.splice(index, 1);
          }
        });
      } else {
        let orderTrue;
        orderData.forEach((e, index) => {
          if (e.title == "订单收益") {
            return (orderTrue = true);
          }
          if (index == orderData.length - 1 && !orderTrue) {
            return orderData.splice(3, 0, {
              title: "订单收益",
              nav: "profit",
            });
          }
        });
      }
      this.orderData = orderData;
      console.log(this.orderData);
      let payload = {
        token: sessionStorage.getItem("token"),
        // token: "5b7b6f6711ab7a6efe1494bf9062248b",
        teamProjectId: this.project.teamProjectId,
        states: "EXECUTE",
        pageSize: 10,
        pageNo: 1,
        transactionType: this.tableActive == 0 ? "spot" : "contract_usdt",
        roleUserId: this.$route.query.userId ? this.$route.query.userId : "",
      };
      console.log(payload);

      this.searchTable = [];
      this.queryOrderChainList(payload);
    },
    //终止订单链按钮
    stopSubmit() {
      if (!this.detailsOperationAuthority) {
        // this.$message.error("不是模板创建人，无权操作");
        this.$alert("您没有权限使用该功能，敬请谅解！", "额，好像没有权限", {
          confirmButtonText: "知道啦",
        });
        return;
      }
      if (this.selectRowList.length !== 0) {
        this.activeShow = true;
      } else {
        return this.$message.warning("请先勾选实例");
      }
    },
    //终止确认按钮
    activeBtn() {
      let orderChainIdList = "";
      this.selectRowList.forEach((e, index) => {
        if (index == 0) {
          orderChainIdList += e.orderChainId;
        } else {
          orderChainIdList += "," + e.orderChainId;
        }
      });
      let payload = {
        token: sessionStorage.getItem("token"),
        orderChainStr: orderChainIdList,
        transactionType: this.tableActive == 0 ? "spot" : "contract_usdt",
      };

      batchOrderChainEnd(payload).then((res) => {
        if (res.status == 200) {
          this.activeShow = false;
          this.currentPage = 1;
          let payload = {
            token: sessionStorage.getItem("token"),
            teamProjectId: this.project.teamProjectId,
            pageSize: 10,
            states: "EXECUTE",
            pageNo: 1,
            transactionType: this.tableActive == 0 ? "spot" : "contract_usdt",
            roleUserId: this.$route.query.userId
              ? this.$route.query.userId
              : "",
            ...this.searchTable,
          };
          this.queryOrderChainList(payload);
        }
      });
    },
    handleClose(done) {
      this.dialogVisible = false;
      this.activeShow = false;
      // this.$refs.child.destroyModul()
    },
    //获取选中数据
    selectRow(e) {
      this.selectRowList = e;
    },
    //批量修改
    stopBatch() {
      if (!this.detailsOperationAuthority) {
        // this.$message.error("不是模板创建人，无权操作");
        this.$alert("您没有权限使用该功能，敬请谅解！", "额，好像没有权限", {
          confirmButtonText: "知道啦",
        });
        return;
      }
      console.log(this.selectRowList);
      let selectRowList = this.selectRowList ? this.selectRowList : [];
      if (selectRowList.length == 1) {
        this.orderChainId = selectRowList.orderChainId;
        this.dialogVisible = true;
        let payload = {
          token: sessionStorage.getItem("token"),
          orderChainId: this.orderChainId,
        };
        this.findByIdOrderChain(payload);
      } else if (selectRowList.length == 0) {
        return this.$message.warning("请选择实例");
      } else {
        let orderChainIdList = [];
        for (let i = 0; i < selectRowList.length; i++) {
          orderChainIdList.push(selectRowList[i].label);
        }
        orderChainIdList = new Set(orderChainIdList);
        if (orderChainIdList.size == 1) {
          let payload = {
            token: sessionStorage.getItem("token"),
            orderChainId: selectRowList[0].orderChainId,
          };
          this.dialogVisible = true;
          this.orderChainId = selectRowList[0].orderChainId;
          this.batchModify = true;
          this.findByIdOrderChain(payload);
        } else {
          return this.$message.error("只能选择同一种标签下的实例");
        }
      }
    },
    //修改按钮
    modifyBtn(e) {
      this.orderChainId = e.orderChainId;
      this.dialogVisible = true;
      let payload = {
        token: sessionStorage.getItem("token"),
        orderChainId: e.orderChainId,
      };
      console.log(e);
      if (e.states == "已完成") {
        this.btnSubmitShow = true;
      } else {
        this.btnSubmitShow = false;
      }
      if (this.$refs.child) {
        this.$refs.child.orderIndex = 0;
      }
      this.findByIdOrderChain(payload);
    },

    // 查询订单链详情
    findByIdOrderChain(payload) {
      findByIdOrderChain(payload).then((res) => {
        if (res.status == 200) {
          console.log("查询的订单链详情", res.data.intelligencOrderChain);

          let orderType = res.data.intelligencOrderChain.transactionType;
          console.log(orderType);

          if (orderType == "spot") {
            this.transactionType = "spot";
            this.orderList = JSON.parse(
              JSON.stringify(res.data.intelligencOrderChain)
            );

            let list = res.data.intelligencOrderChain.orderList;

            console.log(list);
            console.log(JSON.parse(list[0].displayConfig));
            let newList = [];
            for (let key of list) {
              console.log(key);
              let item = JSON.parse(key.displayConfig);
              console.log(key.executeStates, item);
              item.executeStates = key.executeStates;

              if (key.actionIndex) {
                item.actionIndex = key.actionIndex;
                // 判断是否有关闭的动作
                for (let i = 0; i < item.actionList.length; i++) {
                  console.log(item.actionList[i]);
                  if (!item.actionList[i].isCheck) {
                    // console.log('111111111111111',i)
                    item.actionIndex = Number(item.actionIndex) + 1 + "";
                  }
                  // console.log(item.actionList[i].isCheck)
                }
              }

              console.log(item);
              newList.push(item);
            }

            newList.reverse();

            this.$refs.child.orderList = newList;
            this.$refs.child.singleDirection = newList[0].singleDirection;
            this.$refs.child.investmentType = newList[0].investmentType;
            this.$refs.child.actionIdx = 0;
            this.$refs.child.showPair =
              newList[0].pair.counterCoin + "/" + newList[0].pair.baseCoin;
            this.$refs.child.investment = newList[0].investment;
            this.$refs.child.createdActionList = newList[0].actionList;
            this.$refs.child.inputCurrency = newList[0].inputCurrency;
            this.$refs.child.actionParams = JSON.parse(
              newList[0].actionList[0].params
            );

            console.log(newList);

            if (this.batchModify) {
              this.$refs.child.executeStates = true;
            } else {
              this.$refs.child.executeStates = false;
            }
            setTimeout(() => {
              this.$refs.child.$refs.child.percentage =
                newList[0].actionList[0].percentage;
              // this.$refs.child.$refs.child.executeStates=list[0].executeStates
            }, 300);
            this.$refs.child.isUpdate = true;
            this.$refs.child.detailIsFalse = false;

            console.log(newList);
            console.log(1, this.$refs.child.orderList);
          } else {
            this.transactionType = "contract_usdt";
            this.orderList = JSON.parse(
              JSON.stringify(res.data.intelligencOrderChain)
            );

            let list = res.data.intelligencOrderChain.orderList;

            this.controlsPrice = Number(list[list.length - 1].price);

            console.log(list);
            let newList = [];

            for (let key of list) {
              let item = JSON.parse(key.displayConfig);
              console.log(key.executeStates, item, key);
              item.executeStates = key.executeStates;
              item.actionIndex = key.actionIndex;
              newList.push(item);
            }

            newList.reverse();

            console.log(newList);

            // // 查询合约交易对
            let payload = {
              teamProjectId: this.project.teamProjectId,
              exchangeId: res.data.intelligencOrderChain.exchangeId,
              token: sessionStorage.getItem("token"),
            };
            this.$refs.child.queryOrderChainContractPair(payload, "update");

            // // 查询合约杠杆
            let params = {
              exchangeId: res.data.intelligencOrderChain.exchangeId,
              token: sessionStorage.getItem("token"),
            };

            this.$refs.child.findByIdLeverMultiple(params);

            console.log();

            this.$refs.child.orderList = newList;
            this.$refs.child.contractPair = newList[0].contractPair;
            this.$refs.child.lever = newList[0].leverRate;
            this.$refs.child.investmentType = newList[0].investmentType;
            this.$refs.child.proportionMax = newList[0].proportionMax;
            this.$refs.child.proportionMin = newList[0].proportionMin;

            this.$refs.child.investment = newList[0].investment;
            this.$refs.child.createdActionList = newList[0].actionList;

            this.$refs.child.actionParams = JSON.parse(
              newList[0].actionList[0].params
            );
            this.$refs.child.singleDirection = newList[0].singleDirection;
            console.log(newList);

            if (this.batchModify) {
              this.$refs.child.executeStates = true;
            } else {
              this.$refs.child.executeStates = false;
            }
            setTimeout(() => {
              this.$refs.child.$refs.child.percentage =
                newList[0].actionList[0].percentage;
              // this.$refs.child.$refs.child.executeStates=list[0].executeStates
            }, 300);
            this.$refs.child.isUpdate = true;
            this.$refs.child.detailIsFalse = false;

            console.log(newList);
            console.log(1, this.$refs.child.orderList);
          }
        }
      });
    },

    // 修改订单链
    updateOrderChain() {
      this.$refs.child.firstOrderAction();
      let orderIndex = this.$refs.child.orderIndex; //当前订单索引
      let actionIdx = this.$refs.child.actionIdx; //当前动作索引

      let orderList = this.$refs.child.orderList; //当前订单链

      console.log(orderList);

      let orderChainList = JSON.parse(JSON.stringify(this.orderList)); //原订单链

      let controls = this.$refs.child.$refs.child.childParams; //当前订单动作参数
      let links = this.$refs.child.$refs.child.linksParams;
      let percentage = this.$refs.child.$refs.child.percentage; //当前订单动作下单资金比例
      let payload = {
        controls,
        links,
      };
      orderList[orderIndex].actionList[actionIdx].params =
        JSON.stringify(payload);

      orderList[orderIndex].actionList[actionIdx].percentage = percentage;

      let newOrderList = JSON.parse(
        JSON.stringify(orderChainList.orderList.reverse())
      );

      let num = newOrderList.length - orderList.length;
      newOrderList.splice(orderList.length, num);
      console.log(newOrderList);

      let params = {
        token: sessionStorage.getItem("token"),
        transactionType: this.tableActive == 0 ? "spot" : "contract_usdt",
        orderChainId: this.orderChainId,
        orderChain: [],
      };

      for (let i = 0; i < newOrderList.length; i++) {
        let item = null; //新的订单集
        item = JSON.parse(JSON.stringify(newOrderList[i]));
        item.displayConfig = JSON.parse(item.displayConfig);
        item.executeConfig = JSON.parse(item.executeConfig);
        item.executeConfig.actions = [];

        // console.log(JSON.parse(item.displayConfig))
        // console.log(JSON.parse(item.executeConfig))
        console.log(orderList[i]);
        console.log(item);

        let actionList = orderList[i].actionList;
        for (let z = 0; z < actionList.length; z++) {
          item.displayConfig.actionList[z] = actionList[z];
          let actionIsCheck = false;
          for (let info of actionList) {
            console.log(info);
            // 动作是否开启
            if (info.isCheck) {
              actionIsCheck = true;
              let actionsItem = {
                name: info.name,
                investRate: info.percentage,
              };

              // 添加动作参数
              let list = JSON.parse(info.params).controls;
              let params = [];
              for (let cont of list) {
                console.log(cont);

                if (
                  cont.build != false &&
                  cont.controltype != "multi-switch" &&
                  cont.controltype != "signal-input"
                ) {
                  let val = {};
                  val.name = cont.name;
                  val.value = cont.value ? cont.value : null;
                  val.valueType = cont.valueType;
                  params.push(val);
                } else if (cont.controltype == "multi-switch") {
                  let val = {};
                  val.name = cont.name;
                  val.value = cont.proposed;
                  val.valueType = cont.valueType;
                  params.push(val);
                  console.log(cont);
                } else if (cont.controltype == "signal-input") {
                  console.log(cont);
                  let val = {};
                  val.name = cont.name;
                  val.valueType = cont.valueType;
                  val.value = {
                    signalId: cont.signal.signalId,
                    attrName: cont.signalAttribute.name,
                    opt: cont.signalOpt,
                    value: cont.value,
                  };
                  params.push(val);
                }
              }
              if (params.length > 0) {
                actionsItem.params = params;
              }
              console.log(actionsItem);
              item.executeConfig.actions.push(actionsItem);
            }
          }

          if (!actionIsCheck) {
            console.log(newOrderList[i]);
            this.$message.error(
              "订单" + newOrderList[i].orderSort + "至少开启一个动作,请重新修改"
            );
            return;
          }
        }

        console.log(item);
        params.orderChain.push(item);
      }

      for (let key of params.orderChain) {
        key.displayConfig = JSON.stringify(key.displayConfig);
        key.executeConfig = JSON.stringify(key.executeConfig);
      }

      params.orderChain = params.orderChain.reverse();

      console.log("参数", params);

      if (this.batchModify) {
        console.log(this.selectRowList);
        let orderChain = "";
        this.selectRowList.forEach((e, index) => {
          if (index == 0) {
            orderChain = e.orderChainId;
          } else {
            orderChain += "," + e.orderChainId;
          }
        });

        let payload = {
          token: sessionStorage.getItem("token"),
          orderChainIdStr: orderChain,
          orderChain: params.orderChain,
          transactionType: this.tableActive == 0 ? "spot" : "contract_usdt",
        };

        batchUpdateOrderChain(payload).then((res) => {
          if (res.status == 200) {
            this.batchModify = false;
            this.dialogVisible = false;
            this.$refs.child.executeStates = false;
            this.orderChainId = null;
            this.$message.success("操作成功");
          } else {
            this.$message.error("res.msg");
          }
        });
      } else {
        updateOrderChain(params).then((res) => {
          if (res.status == 200) {
            this.dialogVisible = false;
            this.$refs.child.executeStates = false;
            this.orderChainId = null;
            this.batchModify = false;
            this.$message.success("操作成功");
          } else {
            this.$message.error("res.msg");
          }
        });
      }
    },

    //获取页面信息
    queryOrderChainList(payload) {
      queryOrderChainList(payload).then((res) => {
        if (res.status == 200) {
          let orderChainList = res.data.orderChainList;
          this.listNumber = res.data.total;
          console.log(res.data);
          orderChainList.forEach((e) => {
            let orderList = "";

            //判断订单链的长度

            if (e.states == "COMPLETED") {
              if (e.orderList.length > 3) {
                orderList =
                  e.orderList[e.orderList.length - 1].investUnit +
                  "-" +
                  e.orderList[e.orderList.length - 2].investUnit +
                  "-" +
                  e.orderList[e.orderList.length - 3].investUnit +
                  "...";
              } else {
                if (e.orderList.length == 3) {
                  orderList =
                    e.orderList[2].investUnit +
                    "-" +
                    e.orderList[1].investUnit +
                    "-" +
                    e.orderList[0].investUnit;
                } else if (e.orderList.length == 2) {
                  console.log(e.orderList[0].transactionPair);
                  let transactionPair;
                  if (e.orderList[0].transactionPair.indexOf("/") > 0) {
                    transactionPair = e.orderList[0].transactionPair.split("/");
                  } else if (e.orderList[0].transactionPair.indexOf("-") > 0) {
                    transactionPair = e.orderList[0].transactionPair.split("-");
                  } else if (
                    e.orderList[0].transactionPair.indexOf("SWAP") > 0
                  ) {
                    transactionPair = e.orderList[0].transactionPair.split("-");
                  } else {
                    let investUnitLength = e.orderList[0].investUnit.length;
                    if (
                      e.orderList[0].transactionPair.indexOf(
                        e.orderList[0].investUnit
                      ) > 0
                    ) {
                      transactionPair = [
                        e.orderList[0].transactionPair.slice(
                          e.orderList[0].transactionPair.length -
                            investUnitLength +
                            1
                        ),
                        e.orderList[0].investUnit,
                      ];
                    } else {
                      transactionPair = [
                        e.orderList[0].investUnit,
                        e.orderList[0].transactionPair.slice(investUnitLength),
                      ];
                    }

                    if (e.orderList[0].investUnit == transactionPair[0]) {
                      orderList = transactionPair[0] + "-" + transactionPair[1];
                    } else {
                      console.log(transactionPair);
                      orderList = transactionPair[1] + "-" + transactionPair[0];
                      console.log(orderList);
                    }
                  }
                  if (e.orderList[0].investUnit == transactionPair[0]) {
                    transactionPair =
                      transactionPair[0] + "-" + transactionPair[1];
                  } else {
                    transactionPair =
                      transactionPair[1] + "-" + transactionPair[0];
                  }
                  orderList = e.orderList[1].investUnit + "-" + transactionPair;
                } else if (e.orderList.length == 1) {
                  let transactionPair;
                  if (this.tableActive == 1) {
                    if (e.orderList[0].transactionPair.indexOf("-SWAP") > 0) {
                      orderList =
                        e.orderList[0].transactionPair.split("-SWAP")[0];
                    } else {
                      orderList = e.orderList[0].transactionPair;
                    }
                  } else {
                    if (e.orderList[0].transactionPair.indexOf("/") > 0) {
                      transactionPair =
                        e.orderList[0].transactionPair.split("/");
                    } else if (
                      e.orderList[0].transactionPair.indexOf("-") > 0
                    ) {
                      transactionPair =
                        e.orderList[0].transactionPair.split("-");
                    } else if (
                      e.orderList[0].transactionPair.indexOf("SWAP") > 0
                    ) {
                      transactionPair =
                        e.orderList[0].transactionPair.split("-");
                    } else {
                      let investUnitLength = e.orderList[0].investUnit.length;
                      if (
                        e.orderList[0].transactionPair.indexOf(
                          e.orderList[0].investUnit
                        ) > 0
                      ) {
                        transactionPair = [
                          e.orderList[0].transactionPair.slice(
                            e.orderList[0].transactionPair.length -
                              investUnitLength +
                              1
                          ),
                          e.orderList[0].investUnit,
                        ];
                      } else {
                        transactionPair = [
                          e.orderList[0].investUnit,
                          e.orderList[0].transactionPair.slice(
                            investUnitLength
                          ),
                        ];
                      }
                    }

                    if (e.orderList[0].investUnit == transactionPair[0]) {
                      orderList = transactionPair[0] + "-" + transactionPair[1];
                    } else {
                      console.log(transactionPair);
                      orderList = transactionPair[1] + "-" + transactionPair[0];
                      console.log(orderList);
                    }

                    if (e.orderList[0].investUnit == transactionPair[0]) {
                      transactionPair =
                        transactionPair[0] + "-" + transactionPair[1];
                    } else {
                      transactionPair =
                        transactionPair[1] + "-" + transactionPair[0];
                    }
                    orderList = transactionPair;
                  }
                }
              }
            } else {
              if (e.orderList.length > 3) {
                for (let i = e.orderList.length - 1; i >= 0; i--) {
                  console.log(e.orderList[i]);
                  if (e.orderList[i].executeStates == "EXECUTE") {
                    console.log(i);
                    //判断省略号的方向
                    if (i == 0) {
                      orderList =
                        "..." +
                        e.orderList[2].investUnit +
                        "-" +
                        e.orderList[1].investUnit +
                        "-<span style='color:red'>" +
                        e.orderList[0].investUnit +
                        "</span>";
                      console.log(orderList);
                    } else if (i == 1) {
                      orderList =
                        "..." +
                        e.orderList[i - 1].investUnit +
                        "-<span style='color:red'>" +
                        e.orderList[i].investUnit +
                        "</span>-" +
                        e.orderList[i + 1].investUnit;
                    } else if (i == e.orderList.length - 2) {
                      orderList =
                        e.orderList[i + 1].investUnit +
                        "-<span style='color:red'>" +
                        e.orderList[i].investUnit +
                        "</span>-" +
                        e.orderList[i - 1].investUnit +
                        "...";
                    } else if (i == e.orderList.length - 1) {
                      orderList =
                        "<span style='color:red'>" +
                        e.orderList[i].investUnit +
                        "</span>-" +
                        e.orderList[i - 1].investUnit +
                        "-" +
                        e.orderList[i - 2].investUnit +
                        "...";
                    } else {
                      orderList =
                        "..." +
                        e.orderList[i + 1].investUnit +
                        "-<span style='color:red'>" +
                        e.orderList[i].investUnit +
                        "</span>-" +
                        e.orderList[i - 1].investUnit +
                        "...";
                    }
                  }
                }
              } else {
                if (e.orderList.length == 3) {
                  for (let i = 0; i < e.orderList.length; i++) {
                    //在执行中的链上加样式
                    if (e.orderList[i].executeStates == "EXECUTE") {
                      orderList =
                        "<span style='color:red'>" +
                        e.orderList[i].investUnit +
                        "</span>-" +
                        orderList;
                    } else {
                      orderList = e.orderList[i].investUnit + "-" + orderList;
                    }
                  }
                } else {
                  if (e.orderList.length == 1 && this.tableActive == 1) {
                    // console.log(e.transactionPair, transactionPair);
                    if (e.orderList[0].transactionPair.indexOf("-SWAP") > 0) {
                      orderList =
                        e.orderList[0].transactionPair.split("-SWAP")[0];
                    } else if (
                      e.orderList[0].transactionPair.indexOf("-") == -1
                    ) {
                      let investUnitLength = e.orderList[0].investUnit.length;
                      let transactionPair = [];
                      if (
                        e.orderList[0].transactionPair.slice(
                          0,
                          e.orderList[0].investUnit.length
                        ) == e.orderList[0].investUnit
                      ) {
                        transactionPair = [
                          e.orderList[0].transactionPair.slice(
                            e.orderList[0].transactionPair.length,
                            investUnitLength
                          ),
                          e.orderList[0].investUnit,
                        ];
                      } else {
                        if (
                          e.orderList[0].orderDirection == "open_many" ||
                          e.orderList[0].orderDirection == "flat_empty"
                        ) {
                          transactionPair = [
                            e.orderList[0].investUnit,
                            e.orderList[0].transactionPair.slice(
                              0,
                              investUnitLength - 1
                            ),
                          ];
                        } else {
                          transactionPair = [
                            e.orderList[0].transactionPair.slice(
                              0,
                              investUnitLength - 1
                            ),
                            e.orderList[0].investUnit,
                          ];
                        }
                      }

                      if (e.orderList[0].investUnit == transactionPair[0]) {
                        orderList =
                          transactionPair[0] + "-" + transactionPair[1];
                      } else {
                        console.log(transactionPair);
                        orderList =
                          transactionPair[1] + "-" + transactionPair[0];
                        console.log(orderList);
                      }
                    } else {
                      orderList = e.orderList[0].transactionPair;
                    }
                    orderList =
                      "<span style='color:red'>" +
                      orderList.split("-")[0] +
                      "</span>-" +
                      orderList.split("-")[1];
                  } else {
                    for (let i = 0; i < e.orderList.length; i++) {
                      //在执行中的链上加样式
                      if (e.orderList[i].executeStates == "EXECUTE") {
                        if (i == 0) {
                          let transactionPair;
                          if (e.orderList[0].transactionPair.indexOf("/") > 0) {
                            transactionPair =
                              e.orderList[0].transactionPair.split("/");
                          } else if (
                            e.orderList[0].transactionPair.indexOf("-") > 0
                          ) {
                            transactionPair =
                              e.orderList[0].transactionPair.split("-");
                          } else if (
                            e.orderList[0].transactionPair.indexOf("SWAP") > 0
                          ) {
                            transactionPair =
                              e.orderList[0].transactionPair.split("-");
                          } else {
                            let investUnitLength =
                              e.orderList[0].investUnit.length;
                            if (
                              e.orderList[0].transactionPair.indexOf(
                                e.orderList[0].investUnit
                              ) > 0
                            ) {
                              transactionPair = [
                                e.orderList[0].transactionPair.slice(
                                  e.orderList[0].transactionPair.length -
                                    investUnitLength +
                                    1
                                ),
                                e.orderList[0].investUnit,
                              ];
                            } else {
                              transactionPair = [
                                e.orderList[0].investUnit,
                                e.orderList[0].transactionPair.slice(
                                  investUnitLength
                                ),
                              ];
                            }

                            if (
                              e.orderList[0].investUnit == transactionPair[0]
                            ) {
                              orderList =
                                transactionPair[0] + "-" + transactionPair[1];
                            } else {
                              console.log(transactionPair);
                              orderList =
                                transactionPair[1] + "-" + transactionPair[0];
                              console.log(orderList);
                            }
                          }
                          if (e.orderList[0].investUnit == transactionPair[0]) {
                            orderList =
                              "<span style='color:red'>" +
                              transactionPair[0] +
                              "</span>-" +
                              transactionPair[1];
                          } else {
                            orderList =
                              "<span style='color:red'>" +
                              transactionPair[1] +
                              "</span>-" +
                              transactionPair[0];
                          }
                        } else {
                          orderList =
                            "<span style='color:red'>" +
                            e.orderList[i].investUnit +
                            "</span>-" +
                            orderList;
                        }
                      } else {
                        if (i == 0) {
                          console.log(e.orderList[0].transactionPair);
                          let transactionPair;
                          if (e.orderList[0].transactionPair.indexOf("/") > 0) {
                            transactionPair =
                              e.orderList[0].transactionPair.split("/");
                          } else if (
                            e.orderList[0].transactionPair.indexOf("-") > 0
                          ) {
                            transactionPair =
                              e.orderList[0].transactionPair.split("-");
                          } else if (
                            e.orderList[0].transactionPair.indexOf("SWAP") > 0
                          ) {
                            transactionPair =
                              e.orderList[0].transactionPair.split("-");
                          } else {
                            let investUnitLength =
                              e.orderList[0].investUnit.length;
                            if (
                              e.orderList[0].transactionPair.indexOf(
                                e.orderList[0].investUnit
                              ) > 0
                            ) {
                              transactionPair = [
                                e.orderList[0].transactionPair.slice(
                                  e.orderList[0].transactionPair.length -
                                    investUnitLength +
                                    1
                                ),
                                e.orderList[0].investUnit,
                              ];
                            } else {
                              transactionPair = [
                                e.orderList[0].investUnit,
                                e.orderList[0].transactionPair.slice(
                                  investUnitLength
                                ),
                              ];
                            }
                          }
                          if (e.orderList[0].investUnit == transactionPair[0]) {
                            orderList =
                              transactionPair[0] + "-" + transactionPair[1];
                          } else {
                            console.log(transactionPair);
                            orderList =
                              transactionPair[1] + "-" + transactionPair[0];
                            console.log(orderList);
                          }
                        } else {
                          console.log(orderList, 111111);
                          orderList =
                            e.orderList[i].investUnit + "-" + orderList;
                        }
                      }
                    }
                  }
                }
              }
            }

            //去除首尾的 '-'

            console.log(orderList);
            orderList = orderList.replace(/^(\s|-)+|(\s|-)+$/g, "");
            //在末尾加上订单的数量
            e.order = orderList + " (" + e.orderList.length + "单)";
            //给的 style 样式写在修改的数据前面
            e.state = [];
            if (e.orderList.length !== 0) {
              e.state.push(
                Number(e.totalIncomeNum) == 0
                  ? "zero3"
                  : Number(e.totalIncomeNum) -
                      Number(e.orderList[e.orderList.length - 1].investNum) >
                    0
                  ? "true3"
                  : Number(e.totalIncomeNum) -
                      Number(e.orderList[e.orderList.length - 1].investNum) <
                    0
                  ? "false3"
                  : "zero3"
              );
            }
            e.state.push(
              e.error
                ? "订单错误"
                : e.states == "EXECUTE"
                ? "执行中"
                : e.states == "COMPLETED"
                ? "已完成"
                : ""
            );

            //渲染到页面
            if (e.states == "COMPLETED") {
              e.createTime = this.date_format(e.endTime - e.createTime);
            } else {
              e.createTime = this.date_format(
                Date.parse(new Date()) - e.createTime
              );
            }
            if (e.orderList.length == 1) {
              e.profit = "--";
            } else {
              if (!e.totalIncomeNum) {
                e.profit = 0;
              } else {
                // investUnit 是初始投入币种
                let profit =
                  e.totalIncomeNum -
                  e.orderList[e.orderList.length - 1].investNum;
                profit = Number(profit).toFixed(10);
                if (profit == 0) {
                  e.profit =
                    0 + " " + e.orderList[e.orderList.length - 1].investUnit;
                } else {
                  if (
                    e.orderList[e.orderList.length - 1].investUnit !== "BTC"
                  ) {
                    //获取收益百分比
                    let percentNum = (
                      (profit /
                        Number(e.orderList[e.orderList.length - 1].investNum)) *
                      100
                    ).toString();
                    e.profit =
                      Number(
                        profit
                          .toString()
                          .slice(0, profit.toString().indexOf(".") + 5)
                      ) +
                      " " +
                      e.orderList[e.orderList.length - 1].investUnit +
                      " (" +
                      percentNum.slice(0, percentNum.indexOf(".") + 3) +
                      "%)";
                  } else {
                    let percentNum = (
                      (profit /
                        Number(e.orderList[e.orderList.length - 1].investNum)) *
                      100
                    ).toString();
                    e.profit =
                      Number(
                        profit
                          .toString()
                          .slice(0, profit.toString().indexOf(".") + 7)
                      ) +
                      " " +
                      e.orderList[e.orderList.length - 1].investUnit +
                      " (" +
                      percentNum.slice(0, percentNum.indexOf(".") + 3) +
                      "%)";
                  }
                }
              }
            }

            let totalIncomeNum = e.totalIncomeNum;
            if (e.orderList.length !== 0) {
              e.totalIncomeNum =
                e.orderList[e.orderList.length - 1].investNum - totalIncomeNum;

              e.states = e.error
                ? "订单错误"
                : e.states == "EXECUTE"
                ? "执行中"
                : e.states == "COMPLETED"
                ? "已完成"
                : "";
              e.orderList.forEach((item) => {
                if (item.orderSort == 1) {
                  if (e.transactionType == "contract_usdt") {
                    e.investNum = item.investNum + " 张";
                  } else {
                    if (item.investUnit !== "BTC") {
                      item.investNum =
                        item.investNum.slice(
                          0,
                          item.investNum.indexOf(".") + 5
                        ) + " ";
                    } else {
                      item.investNum =
                        item.investNum.slice(
                          0,
                          item.investNum.indexOf(".") + 7
                        ) + " ";
                    }
                    if (Number(item.investNum) == 0) {
                      e.investNum = 0 + " " + item.investUnit;
                    } else {
                      e.investNum = item.investNum + item.investUnit;
                    }
                  }
                }
                if (item.executeStates == "EXECUTE") {
                  e.orderDirection =
                    item.orderDirection == "buy"
                      ? "买入"
                      : item.orderDirection == "sell"
                      ? "卖出"
                      : item.orderDirection == "open_many"
                      ? "开多 " + item.leverRate + "x"
                      : //买
                      item.orderDirection == "open_empty"
                      ? "开空 " + item.leverRate + "x"
                      : item.orderDirection == "flat_many"
                      ? "平多 " + item.leverRate + "x"
                      : item.orderDirection == "flat_empty"
                      ? "平空 " + item.leverRate + "x"
                      : "";
                }
              });
              if (e.orderDirection) {
                e.state.push(e.orderDirection.split(" ")[0]);
              }
            }
          });
          this.tableData = orderChainList;
          console.log(this.tableData);
        }
      });
    },

    //获取交易所列表
    userManagementExchangelist(payload) {
      userManagementExchangelist(payload).then((res) => {
        if (res.status == 200) {
          console.log(res.data.exchangeLimit);
          this.exchangeLimit = res.data.exchangeLimit;
          this.searchData.forEach((e) => {
            if (e.name == "交易所") {
              let exchangeLimit = res.data.exchangeLimit;
              exchangeLimit.forEach((item) => {
                e.data.push(item.exchangeName);
              });
            }
          });
        }
      });
    },

    //获取订单日记
    queryOrderLog(payload) {
      queryOrderLog(payload).then((res) => {
        if (res.status == 200) {
          let orderLogList = res.data.orderLogList;
          orderLogList.forEach((e) => {
            e.createTime = this.timestampToTime(e.createTime);
            e.log = JSON.parse(e.log);
            let price = e.log.price;
            let amount = e.log.amount;
            if (price.indexOf(".") > 0) {
              e.log.price = price.slice(0, price.indexOf(".") + 3);
            }
            if (e.log.counterCoin !== "BTC") {
              e.log.amount = amount.slice(0, amount.indexOf(".") + 7);
            } else {
              e.log.amount = amount.slice(0, amount.indexOf(".") + 5);
            }
            // if (e.log.price.length - e.log.price.indexOf(".") == 2) {
            //   e.log.price += "0";
            //   console.log(e.log.price);
            // }
          });
          this.orderLogList = orderLogList;
          console.log(orderLogList);
        }
        console.log(res);
      });
    },
    toLogDetail() {
      this.$router.push({
        name: "orderChainLog",
        query: {
          projectActive: "2-2",
          teamProjectId: this.project.teamProjectId,
          transactionType: this.tableActive == 0 ? "spot" : "contract_usdt",
        },
      });
      sessionStorage.setItem(
        "tableActive",
        this.tableActive == 0 ? "spot" : "contract_usdt"
      );
    },
    //日记按钮
    openLog() {
      this.showIcon = true;
      let payload = {
        token: sessionStorage.getItem("token"),
        teamProjectId: this.project.teamProjectId,
        transactionType: this.tableActive == 0 ? "spot" : "contract_usdt",
        pageNo: 1,
        pageSize: 5,
      };
      this.queryOrderLog(payload);
    },
    backLog() {
      this.showIcon = false;
    },

    //日期换算
    timestampToTime(timestamp) {
      var date = new Date(timestamp); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
      var Y = date.getFullYear() + "-";
      var M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      var D =
        date.getDate() < 10
          ? "0" + date.getDate() + " "
          : +date.getDate() + " ";
      var h =
        date.getHours() < 10
          ? "0" + date.getHours() + ":"
          : date.getHours() + ":";
      var m =
        date.getMinutes() < 10
          ? "0" + date.getMinutes() + ":"
          : date.getMinutes() + ":";
      var s =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      return Y + M + D + h + m + s;
    },

    //时分秒换算
    date_format(micro_second) {
      var second = Math.floor(micro_second / 1000);
      var day = Math.floor(second / 3600 / 24);
      var hr = Math.floor((second / 3600) % 24);
      var min = Math.floor((second / 60) % 60);
      return day + "d " + hr + "h " + min + "min";
    },
  },

  created() {
    sessionStorage.removeItem("Now");
    let project = JSON.parse(sessionStorage.getItem("project"));
    this.project = project;
    if (sessionStorage.getItem("tableActive")) {
      let transactionType = sessionStorage.getItem("tableActive");
      this.tableActive = transactionType == "spot" ? 0 : 1;
    }
    if (sessionStorage.getItem("pageNo")) {
      this.currentPage = JSON.parse(sessionStorage.getItem("pageNo"));
    }
    console.log(this.tableActive);
    let payloadData = {
      token: sessionStorage.getItem("token"),
      teamProjectId: this.project.teamProjectId,
    };
    this.userManagementExchangelist(payloadData);
    let payload = {
      token: sessionStorage.getItem("token"),
      teamProjectId: this.project.teamProjectId,
      states: "EXECUTE",
      pageSize: 10,
      pageNo: this.currentPage,
      transactionType: this.tableActive == 0 ? "spot" : "contract_usdt",
      roleUserId: this.$route.query.userId ? this.$route.query.userId : "",
    };
    console.log(this.currentPage);

    if (sessionStorage.getItem("searchData")) {
      let searchData = JSON.parse(sessionStorage.getItem("searchData"));
      console.log(this.exchangeLimit);
      payload.exchangeId =
        searchData[1] == this.$t('m.全部')
          ? ""
          : searchData[1] == "Huobi"
          ? 3
          : searchData[1] == "Binance"
          ? 4
          : 29;
      payload.states =
        searchData[3] == this.$t('m.执行中')
          ? "EXECUTE"
          : searchData[3] == this.$t('m.已完成')
          ? "COMPLETED"
          : "";
      payload.publicPeopleName = searchData[2];
      payload.label = searchData[0];
      let orderData;
      if (searchData[3] == this.$t('m.已完成')) {
        orderData = this.orderDataList[1];
      } else {
        orderData = this.orderDataList[0];
      }
      console.log(orderData);
      if (this.tableActive == 1) {
        orderData.forEach((e, index) => {
          if (e.title == "订单收益") {
            return orderData.splice(index, 1);
          }
        });
      } else {
        let orderTrue;
        orderData.forEach((e, index) => {
          if (e.title == "订单收益") {
            return (orderTrue = true);
          }
          if (index == orderData.length - 1 && !orderTrue) {
            return orderData.splice(3, 0, {
              title: "订单收益",
              nav: "profit",
            });
          }
        });
      }
      this.orderData = orderData;
      console.log(this.orderData);
    } else {
      let orderData = this.orderDataList[0];
      if (this.tableActive == 1) {
        this.rowStyleData.forEach((e) => {
          e.columnIndex = e.columnIndex - 1;
        });

        this.chainStyle = true;
        orderData.forEach((e, index) => {
          if (e.title == "订单收益") {
            return orderData.splice(index, 1);
          }
        });
      } else {
        this.chainStyle = false;
        let orderTrue;
        orderData.forEach((e, index) => {
          if (e.title == "订单收益") {
            return (orderTrue = true);
          }
          if (index == orderData.length - 1 && !orderTrue) {
            return orderData.splice(3, 0, {
              title: "订单收益",
              nav: "profit",
            });
          }
        });
      }
      this.orderData = orderData;
    }
    this.queryOrderChainList(payload);

    let detailsOperationAuthority = sessionStorage.getItem(
      "detailsOperationAuthority"
    );
    this.detailsOperationAuthority = JSON.parse(detailsOperationAuthority);
  },
  deactivated() {},
};
</script>

<style lang='scss' scoped>
.navItem {
  position: relative;
  height: 100%;
  .nav {
    width: 1640px;
    border-radius: 8px;
    padding: 10px 20px;
    background: #ffffff;
    height: calc(100% - 80px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .topData {
      display: flex;
      border-bottom: 1px solid #e6e9ee;
      height: 40px;
      font-size: 16px;
      align-items: center;
      margin-bottom: 20px;
      .topDataItem {
        height: 100%;
        line-height: 40px;
        padding: 0 20px;
        cursor: pointer;
        color: #55698a;
      }
      .brdStyle {
        border-bottom: 2px solid #2174ff;
        color: #2174ff;
      }
    }
    .paginStyle {
      display: flex;
      color: #55698a;
      justify-content: space-between;
      align-items: center;
      padding: 20px 100px;
      .paginCenter {
        display: flex;
        align-items: center;
      }
    }
    .tableStyle {
      border: 1px solid #e6e9ee;
      padding-bottom: 20px;
      .topColumn {
        display: flex;
        font-size: 12px;
        align-items: flex-start;
        justify-content: space-between;
        padding: 0 30px;
        background-color: #32333d;
        color: #ffffff;
        /deep/.el-input__inner {
          background-color: #1c1d28 !important;
          border: 1px solid #1c1d28 !important;
        }
        .one {
          display: flex;
          align-items: center;
          margin-right: 30px;
          span {
            white-space: nowrap;
          }
        }
        .searchStyle {
          width: 1400px;
          background-color: #32333d;
          color: #ffffff;
        }
        .btnData {
          display: flex;
          height: 100%;
          .btn-submit {
            margin-right: 10px;
            background-color: #2174ff;
          }
          span {
            width: 60px;
            margin: 5px 0;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            border-radius: 2px;
          }
        }
        .btnBox {
          display: flex;
          height: 36px;
          justify-content: center;
          align-items: center;
          margin-right: 10px;
          div {
            display: flex;
            justify-content: center;
            width: 80px;
            cursor: pointer;
          }
        }
      }
    }
  }
  .content {
    position: absolute;
    bottom: 0;
    width: 1680px;
    z-index: 1;
    .bottom {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      height: 50px;
      border-radius: 8px;
      padding: 0 20px;
      background: #ffffff;
    }

    .selectBtn {
      padding: 0 20px;
      background: #ffffff;
      border-radius: 8px;
      border: 1px solid #e6e9ee;
      .bottom2 {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        height: 50px;
        border-radius: 8px 8px 0 0;
        border-bottom: 1px solid #e6e9ee;
      }
      .navSelect {
        display: flex;
        flex-direction: column;
        .log-box {
          display: flex;
          flex-direction: column;
          color: #666;
          font-size: 14px;
          border-bottom: 1px solid #eaeaec;
          .log-item {
            display: flex;
            align-items: center;
            margin-right: 10px;
            padding: 20px 0;
          }
        }
      }
    }
    .bottom-text {
      color: #102859;
      height: 100%;
      line-height: 50px;
    }
    .bottom-icon {
      width: 60px;
      height: 12px;
      background: #e6e9ee;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      border-radius: 0 0 8px 8px;
      cursor: pointer;
      .bottom-img {
        width: 8px;
        height: 4px;
      }
    }
    .more-btn {
      color: #758db5;
      height: 100%;
      line-height: 50px;
      cursor: pointer;
    }
  }
  /deep/.el-dialog__body {
    padding: 0 30px;
    border-top: 1px solid #f0f0f0;
    border-bottom: 1px solid #f0f0f0;
    .dialog-nav {
      color: #55698a;
      padding: 20px 0 70px;
      font-size: 12px;
      .dialog-top {
        font-size: 16px;
        color: #102859;
        margin-bottom: 10px;
      }
    }
  }
  .dialog-footer {
    display: flex;
    justify-content: flex-end;
    div {
      width: 72px;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      cursor: pointer;
    }
    .footerBack {
      border: 1px solid #c2d9ff;
      color: #2174ff;
    }
    .footerBtn {
      color: #ffffff;
      background: #2174ff;
      border: 1px solid #c2d9ff;
      margin-left: 12px;
    }
  }
}

table th,
table td {
  border-bottom: none !important;
}
.el-input--mini .el-input__inner {
  height: 22px !important;
  line-height: 22px !important;
}
.el-input--mini .el-input__icon {
  line-height: 22px !important;
}
</style>