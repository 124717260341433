<template>
  <div class="box">
    <div class="navItem">
      <div class="nav">
        <div class="topData">
          <template v-for="(item, index) in Tab">
            <div
              :class="index == tableActive ? 'brdStyle' : ''"
              class="topDataItem"
              @click="tableDataBtn(index)"
              :key="index">
              {{ $t('m.'+item) }}
            </div>
          </template>
        </div>
        <div class="tableStyle">
          <div class="topColumn">
            <SearchBox
              class="searchStyle"
              @btnSubmit="btnSubmit"
              @btnBack="btnBack"
              :searchData="searchData"
              ref="childSearch"
            />
          </div>
          <div class="centerColumn" v-if="tableActive == 0">
            <span @click="operationBtn('strategy')">
              {{$t('m.启动策略')}}</span>
            <span @click="operationBtn('orderChain')">
              {{$t('m.创建订单链')}}</span>
            <span style="color: #e12525" @click="activeShowBtn()">
              {{$t('m.强制结算')}}</span>
          </div>
          <DetailsData
            @selectRow="selectRow"
            :data="tableData"
            details="true"
            :news="news"
            :newsRemarks="newsRemarks"
            :rowStyleData="rowStyleData"
            :label="orderData"
            class="detailsData"
            @setActive="setActive"
            @updateMark="updateMark"
          />
        </div>
      </div>
      <div class="paginStyle">
        <div></div>
        <div v-if="listNumber >= 10" class="paginCenter">
          <span>{{$t('m.共计订单&(1)条').replace('&(1)', (listNumber ? listNumber : 0))}}</span>
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="currentPage"
            :page-size="10"
            background
            layout="prev, pager, next, jumper"
            :total="listNumber ? listNumber : 0"
          >
          </el-pagination>
        </div>
          <span>{{$t('m.已选择&(1)条').replace('&(1)', (selectRowList ? selectRowList.length : 0))}}</span>
      </div>
    </div>
    <el-dialog
      :title="$t('m.强制结算')"
      :visible.sync="activeShow"
      width="400px"
      center
      :before-close="handleClose"
      :close-on-click-modal="false">
      <div class="dialog-nav">
        <div class="dialog-top">
          {{$t('m.确认强制结算后账户将被服务器接管，所有持仓自动兑换成托管币种后等待下一结算期结算。')}}
        </div>
        <div style="color: #e12525; margin-top: 10px">
          {{$t('m.请耐心等待，结算完成后您可在历史账户中查看。')}}
        </div>
        <div style="color: #e12525; margin-top: 10px">
          {{$t('m.强制结算会被记录在项目及团队评分系统中，非必要请勿进行此项操作。')}}
        </div>
      </div>
      <div slot="footer">
        <div class="dialog-footer">
          <div class="footerBack" @click="activeShow = false">
            {{$t('m.取消')}}</div>
          <div class="footerBtn" @click="batchForceSettlement">
            {{$t('m.确定')}}</div>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      :title="$t('m.迁移用户')"
      :visible.sync="confromVisible"
      width="30%"
      :before-close="handleClose">
      <el-select
        style="padding: 30px 0"
        v-model="operatorLater"
        :placeholder="$t('m.请选择操作员')">
        <el-option
          v-for="(item, idx) in operatorList"
          :key="idx"
          :label="item.nickName"
          :value="item.userId">
        </el-option>
      </el-select>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancel">
          {{$t('m.取 消')}}</el-button>
        <el-button type="primary" @click="migrationUser">
          {{$t('m.确 定')}}</el-button>
      </span>
    </el-dialog>
    <!-- 修改备注 -->
    <el-dialog
      :title="$t('m.修改备注')"
      :visible.sync="userRemark"
      width="30%"
      :before-close="handleClose">
      <div class="mark-warp">
        <el-input
          v-model="mark"
          :placeholder="$t('m.请输入备注')"
          class="markInp"
        ></el-input>
        <span class="tips">{{$t('m.限6个字符')}}</span>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          @click="userRemark = false; mark = '';">
          {{$t('m.取 消')}}</el-button>
        <el-button type="primary" @click="updateUserRemarkCode">
          {{$t('m.确 定')}}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import DetailsData from "../../components/detailsData.vue";
import SearchBox from "../../components/searchBox.vue";
import OrderChain from "../../components/orderChain.vue";
import {
  batchOrderChainEnd,
  userManagement,
  fundHistoryList,
  userManagementExchangelist,
  migrationUser,
  batchForceSettlement,
  batchSynchSpotApi,
  updateUserRemarkCode,
  getAssetApiList,
  batchSynchAssetApi,
  batchSynchContractApi,
  getContractApiList,
} from "../../request/api";

export default {
  components: { DetailsData, OrderChain, SearchBox },
  data() {
    return {
      dialogVisible: false,
      Tab: ["当前账户", "历史账户"],
      // 表格数据
      tableData: [],
      // 搜索数据
      searchTable: [],
      //搜索框数据
      searchData: [
        {
          name: "交易所",
          data: [this.$t('m.全部')],
          filterable: true,
        },
        {
          name: "托管时间",
          type: "picker",
        },
        {
          name: "总投入",
          type: "inputTwo",
        },
        {
          name: "剩余可用",
          type: "inputTwo",
        },
        // {
        //   name: "总投入",
        //   type: "inputTwo",
        // },
      ],
      //表格样式
      rowStyleData: [],
      rowStyleDataList: [
        [
          {
            columnIndex: 6,
            style: [
              {
                state: "true6",
                style: "color:#E12525",
              },
              {
                state: "zero6",
                style: "color:#102859",
              },
              {
                state: "false6",
                style: "color:#26B34B",
              },
            ],
          },
          {
            columnIndex: 7,
            style: [
              {
                state: "true7",
                style: "color:#E12525",
              },

              {
                state: "zero7",
                style: "color:#102859",
              },
              {
                state: "false7",
                style: "color:#26B34B",
              },
            ],
          },
          {
            columnIndex: 8,
            style: [
              {
                state: "正常",
                style: "color:#2174FF",
              },
              // {
              //   state: "过期",
              //   style: "color:#E12525",
              // },
              {
                state: "结算中",
                style: "color:#FF9600",
              },
            ],
          },
        ],
        [
          {
            columnIndex: 5,
            style: [
              {
                state: "true5",
                style: "color:#E12525",
              },
              {
                state: "zero5",
                style: "color:#102859",
              },
              {
                state: "false5",
                style: "color:#26B34B",
              },
            ],
          },
          {
            columnIndex: 6,
            style: [
              {
                state: "true6",
                style: "color:#E12525",
              },
              {
                state: "zero6",
                style: "color:#102859",
              },
              {
                state: "false6",
                style: "color:#26B34B",
              },
            ],
          },
        ],
      ],
      // 表头数据
      orderData: [],
      orderDataList: [
        [
          {
            title: "托管时间",
            nav: "createTime",
          },
          {
            title: "执行时长",
            nav: "newTime",
          },
          {
            title: "账户",
            nav: "publicCode",
          },
          {
            title: "总投入",
            nav: "investCoinNum",
          },
          {
            title: "仓位",
            nav: "alreadyDevoted",
            width: 120,
          },
          {
            title: "剩余可用",
            nav: "availableNum",
          },
          {
            title: "总收益",
            nav: "sumFloatEarnings",
          },
          {
            title: "总年化",
            nav: "sumYearIncome",
            width: 100,
          },
          {
            title: "API状态",
            nav: "isExpire",
          },
          {
            title: "操作员",
            nav: "nickName",
            width: 100,
          },
        ],
        [
          {
            title: "托管时间",
            nav: "createTime",
          },
          {
            title: "执行时长",
            nav: "newTime",
          },
          {
            title: "退出时间",
            nav: "endTime",
          },
          {
            title: "账户",
            nav: "publicCode",
          },
          {
            title: "总投入",
            nav: "investCoinNum",
          },
          {
            title: "总收益",
            nav: "sumFloatEarnings",
          },
          {
            title: "总年化",
            nav: "sumYearIncome",
          },
          {
            title: "操作员",
            nav: "nickName",
          },
        ],
      ],
      project: null,
      tableActive: 0, //table切换
      currentPage: 1,
      showData: [1, 2, 3],
      modify: true, //修改按钮
      strategyType: 1,

      orderList: null, //订单链详情
      orderChainId: null, //当前订单Id
      selectRowList: [], //选中数据
      batchModify: false, //批量修改
      exchangeLimit: null, //交易所列表
      operatorList: null, //操作员列表
      activeShow: false, //二次确认弹框
      confromVisible: false,
      operatorLater: null,
      multipleSelection: [],
      listNumber: 0, //列表总数量
      news: false,
      newsRemarks: true,
      mark: null, //备注
      userRemark: false, //备注显隐
    };
  },
  watch: {
    mark() {
      if (this.mark.length >= 5) {
        this.mark = this.mark.slice(0, 6);
      }
    },
  },
  methods: {
    //更改用户备注
    updateUserRemarkCode() {
      console.log(this.scopedRow);
      let payload = {
        token: sessionStorage.getItem("token"),
        publicCode: this.scopedRow.publicCode,
        remarkCode: this.mark
          ? this.scopedRow.exchangeName + "-" + this.mark
          : "",
      };
      updateUserRemarkCode(payload).then((res) => {
        console.log(res);
        let payload = {
          token: sessionStorage.getItem("token"),
          teamProjectId: this.project.teamProjectId,
          roleUserId: this.roleUserId,
          pageNo: 1,
          pageSize: 10,
          ...this.searchTable,
        };
        this.userManagement(payload);
        this.userRemark = false;
      });
    },
    // 修改备注
    updateMark(row) {
      console.log(row);
      this.scopedRow = row;
      this.mark = row.remarkCode
        ? row.remarkCode.slice(
            row.remarkCode.indexOf("-") + 1,
            row.remarkCode.length
          )
        : "";
      this.userRemark = true;
    },
    tableDataBtn(e) {
      this.tableActive = e;
      this.orderData = this.orderDataList[e];
      this.rowStyleData = this.rowStyleDataList[e];
      // let payload = {
      //   token: sessionStorage.getItem("token"),
      //   teamProjectId: this.project.teamProjectId,
      //   roleUserId: this.roleUserId,
      // };
      this.searchTable = [];

      this.$refs.childSearch.btnBack();
      if (e == 0) {
        this.news = false;
        this.newsRemarks = true;
        sessionStorage.setItem("Now", "true");
        this.batchSynchAssetApi();
        this.getAssetApiList();
        this.batchSynchContractApi();
        this.getContractApiList();
        //   this.userManagement(payload);
      } else {
        this.news = true;
        this.newsRemarks = false;
        sessionStorage.setItem("Now", "false");
        //   this.fundHistoryList(payload);
      }
    },
    handleSizeChange() {
      console.log(2);
    },
    handleCurrentChange(e) {
      console.log("点击分页111111111");
      let payload = {
        token: sessionStorage.getItem("token"),
        teamProjectId: this.project.teamProjectId,
        roleUserId: this.roleUserId,
        pageNo: e,
        pageSize: 10,
        ...this.searchTable,
      };
      if (this.tableActive == 0) {
        this.userManagement(payload);
      } else {
        this.fundHistoryList(payload);
      }
    },
    typeSelest() {
      console.log(1);
    },
    //查询按钮
    btnSubmit(data) {
      if (
        data[0] !== this.$t('m.全部') ||
        data[1] ||
        data[2][0] !== "" ||
        data[2][1] !== "" ||
        data[3][0] !== "" ||
        data[3][1] !== ""
      ) {
        this.searchTable = {
          availableNumEnd: data[3][1] == "" ? 0 : Number(data[4][1]),
          availableNumStrat: data[3][0] == "" ? 0 : Number(data[4][0]),
          exchangeName: data[0] == this.$t('m.全部') ? null : data[0],
          investCoinNumEnd: data[2][1] == "" ? 0 : Number(data[2][1]),
          investCoinNumStart: data[2][0] == "" ? 0 : Number(data[2][0]),
        };
        if (data[1][0] !== "") {
          this.searchTable.timeStart = new Date(data[1][0]).getTime();
          this.searchTable.timeEnd = new Date(data[1][1]).getTime();
        }
      } else {
        this.searchTable = [];
      }
      let payload = {
        token: sessionStorage.getItem("token"),
        teamProjectId: this.project.teamProjectId,
        roleUserId: this.roleUserId,
        pageNo: 1,
        pageSize: 10,
        ...this.searchTable,
      };
      sessionStorage.setItem("searchData", JSON.stringify(data));
      if (this.tableActive == 0) {
        this.userManagement(payload);
      } else {
        this.fundHistoryList(payload);
      }
    },
    //重置按钮
    btnBack() {
      console.log("重置按钮1111111111111");
      sessionStorage.removeItem("searchData");
      let payload = {
        token: sessionStorage.getItem("token"),
        teamProjectId: this.project.teamProjectId,
        roleUserId: this.roleUserId,
      };
      this.searchTable = [];
      if (this.tableActive == 0) {
        this.userManagement(payload);
      } else {
        this.fundHistoryList(payload);
      }
    },
    //终止确认按钮
    activeBtn() {
      let orderChainIdList = "";
      this.selectRowList.forEach((e, index) => {
        if (index == 0) {
          orderChainIdList += e.orderChainId;
        } else {
          orderChainIdList += "," + e.orderChainId;
        }
      });
      let payload = {
        token: sessionStorage.getItem("token"),
        orderChainStr: orderChainIdList,
      };
      batchOrderChainEnd(payload).then((res) => {
        if (res.status == 200) {
          this.activeShow = false;
          this.btnSubmit();
        }
      });
    },
    handleClose(done) {
      this.mark = null;
      this.operatorLater = null;
      done();
    },
    //获取选中数据
    selectRow(e) {
      this.selectRowList = e;
    },
    // 操作系列
    operationBtn(val) {
      if (!this.detailsOperationAuthority) {
        // this.$message.error("不是模板创建人，无权操作");
        this.$alert("您没有权限使用该功能，敬请谅解！", "额，好像没有权限", {
          confirmButtonText: "知道啦",
        });
        return;
      }
      if (this.selectRowList.length !== 0) {
        let stopingNumber = 0;
        this.selectRowList.forEach((e) => {
          if (e.states == "STOPING") {
            stopingNumber++;
          }
        });
        let list = this.selectRowList;
        let exchangeNumber = 0;
        for (let i = 0; i < list.length; i++) {
          for (let j = i + 1; j < list.length; j++) {
            if (list[j].exchangeName != list[i].exchangeName) {
              exchangeNumber++;
            }
          }
        }
        if (stopingNumber > 0) {
          this.$message.warning("请选择未结算的账户进行操作");
          return;
        } else if (exchangeNumber > 0) {
          this.$message.warning("请选择相同交易所账户进行操作");
          return;
        }

        let selectRowList = this.selectRowList;
        switch (val) {
          case "strategy": //启动策略
            console.log("策略");
            console.log(selectRowList);

            sessionStorage.setItem("startAcc", JSON.stringify(selectRowList));
            this.setActive("1-1");

            break;
          case "orderChain": //创建交易链
            console.log("交易链");
            sessionStorage.setItem("startAcc", JSON.stringify(selectRowList));
            this.setActive("2-1");
            break;
          default:
            break;
        }
      } else {
        return this.$message.warning("请选择账户");
      }
    },
    //迁移确认按钮
    migrationUser() {
      let list = this.selectRowList;
      if (list.length == 0) {
        this.$message.error("请先选择用户");
        return;
      } else if (!this.operatorLater) {
        this.$message.error("请选择要迁移的操作员");
        return;
      }
      let idStr = [];
      for (let i = 0; i < list.length; i++) {
        idStr.push(list[i].id);
      }
      let payload = {
        token: sessionStorage.getItem("token"),
        teamProjectId: this.project.teamProjectId,
        idStr: idStr.join(","),
        operatorFront: "",
        operatorLater: this.operatorLater,
      };
      migrationUser(payload).then((res) => {
        if (res.status == 200) {
          this.$message.success("迁移成功");
          this.confromVisible = false;
          this.operatorLater = null;
          let payload = {
            token: sessionStorage.getItem("token"),
            teamProjectId: this.project.teamProjectId,
            roleUserId: this.roleUserId,
          };
          this.userManagement(payload);
          console.log(res.data);
        } else {
          this.$message.error(res.msg);
        }
      });
      console.log(payload);
    },
    // 取消迁移
    cancel() {
      this.confromVisible = false;
      this.operatorLater = null;
    },
    //同步资产
    batchSynchSpotApi() {
      let payload = {
        token: sessionStorage.getItem("token"),
      };
      batchSynchSpotApi(payload).then((res) => {
        console.log(res);
        let payload = {
          token: sessionStorage.getItem("token"),
          teamProjectId: this.project.teamProjectId,
          roleUserId: this.roleUserId,
        };
        this.userManagement(payload);
      });
    },
    // 获取当前用户列表
    userManagement(payload) {
      userManagement(payload).then((res) => {
        if (res.status == 200) {
          if (res.data.userList) {
            let userList = res.data.userList;
            this.listNumber = res.data.total;
            userList.forEach((e) => {
              e.newTime = this.date_format(
                Date.parse(new Date()) - e.createTime
              );
              e.createTime = this.timestampToTime(e.createTime);
              e.isExpire = e.isExpire == false ? "正常" : "过期";
              e.isExpire = e.states == "STOPING" ? "结算中" : e.isExpire;
              e.state = [];
              e.state.push(
                e.sumFloatEarnings > 0
                  ? "true6"
                  : e.sumFloatEarnings < 0
                  ? "false6"
                  : "zero6",
                e.sumYearIncome > 0
                  ? "true7"
                  : e.sumYearIncome < 0
                  ? "false7"
                  : "zero7",
                e.isExpire
              );
              let alreadyDevoted;
              console.log(this.contractTable);
              console.log(this.contractAssetTable);
              console.log(e.exchangeName);
              if (e.exchangeName == "OkexV5") {
                if (
                  this.contractAssetTable &&
                  this.contractAssetTable.length !== 0
                ) {
                  this.contractAssetTable.forEach((item) => {
                    if (e.apiId == item.apiId && item.coinType == "USDT") {
                      let investCoinNum =
                        Number(e.investCoinNum) + Number(e.sumFloatEarnings);
                      console.log(e);
                      alreadyDevoted = (
                        (Number(e.lock) / investCoinNum) *
                        100
                      ).toString();
                    }
                  });
                  console.log(alreadyDevoted);
                } else {
                  let investCoinNum =
                    Number(e.investCoinNum) + Number(e.sumFloatEarnings);
                  alreadyDevoted = (
                    (Number(e.lock) / investCoinNum) *
                    100
                  ).toString();
                }
              } else if (
                e.exchangeName !== "OkexV5" &&
                this.contractTable.length !== 0
              ) {
                let investCoinNum =
                  Number(e.investCoinNum) + Number(e.sumFloatEarnings);
                alreadyDevoted = (
                  ((investCoinNum - Number(e.availableNum) - Number(e.lock)) /
                    investCoinNum) *
                  100
                ).toString();

                if (Number(e.investCoinNum) + Number(e.sumFloatEarnings) == 0) {
                  alreadyDevoted = "0";
                }

                console.log(alreadyDevoted);
              }
              console.log(alreadyDevoted);
              if (alreadyDevoted && alreadyDevoted.indexOf(".") > 0) {
                e.alreadyDevoted = alreadyDevoted.slice(
                  0,
                  alreadyDevoted.indexOf(".") + 3
                );
              } else {
                e.alreadyDevoted = alreadyDevoted;
              }
              if (Number(e.alreadyDevoted) <= 0) {
                e.alreadyDevoted = 0;
              } else {
                e.alreadyDevoted += "%";
              }

              e.sumYearIncome =
                e.sumYearIncome
                  .toString()
                  .slice(0, e.sumYearIncome.indexOf(".") + 3) + "%";
              let sumFloatEarnings = (
                (Number(e.sumFloatEarnings) / Number(e.investCoinNum)) *
                100
              ).toString();
              console.log(sumFloatEarnings);
              if (Number(e.sumFloatEarnings) !== 0) {
                if (sumFloatEarnings.indexOf(".") > 0) {
                  e.sumFloatEarnings =
                    Number(e.sumFloatEarnings) +
                    "(" +
                    sumFloatEarnings.slice(
                      0,
                      sumFloatEarnings.indexOf(".") + 3
                    ) +
                    "%" +
                    ")";
                } else {
                  e.sumFloatEarnings =
                    Number(e.sumFloatEarnings) +
                    "(" +
                    sumFloatEarnings +
                    "%" +
                    ")";
                }
              }
              if (e.availableNum !== null) {
                if (e.investCoin !== "BTC") {
                  e.availableNum = Number(e.availableNum)
                    .toFixed(18)
                    .replace(/\.0+$/, "")
                    .replace(/(\.\d+[1-9])0+$/, "$1");
                  e.availableNum = Number(
                    e.availableNum
                      .toString()
                      .slice(0, e.availableNum.toString().indexOf(".") + 5)
                  );
                } else {
                  e.availableNum = Number(e.availableNum)
                    .toFixed(18)
                    .replace(/\.0+$/, "")
                    .replace(/(\.\d+[1-9])0+$/, "$1");
                  e.availableNum = Number(
                    e.availableNum
                      .toString()
                      .slice(0, e.availableNum.toString().indexOf(".") + 7)
                  );
                }
              } else {
                e.availableNum = 0;
              }

              this.orderData.forEach((item) => {
                if (
                  item.title == "总投入" ||
                  item.title == "总收益" ||
                  item.title == "剩余可用"
                ) {
                  item.title += "(" + e.investCoin + ")";
                }
              });

              if (e.isExpire == "结算中") {
                e.alreadyDevoted = "--";
                e.availableNum = "--";
              }
            });
            console.log(userList);

            this.tableData = userList;
          } else {
            this.tableData = null;
          }
        }
      });
    },
    // v5资金账户
    getAssetApiList() {
      let payload = {
        token: sessionStorage.getItem("token"),
        coinType: "USDT",
      };
      getAssetApiList(payload).then((res) => {
        if (res.status == 200) {
          console.log(res);
          let assetApiList = res.data.assetApiList;
          this.contractAssetTable = assetApiList;
        } else {
          this.contractAssetTable = [];
        }
      });
    },
    //同步v5资金账户
    batchSynchAssetApi() {
      let payload = {
        token: sessionStorage.getItem("token"),
      };
      batchSynchAssetApi(payload).then((res) => {});
    },
    //获取合约账户
    getContractApiList() {
      let payload = {
        token: sessionStorage.getItem("token"),
        teamProjectId: this.project.teamProjectId,
        accountMode: "cross",
        exchangeName: "Binance",
        userId: this.userId,
      };
      getContractApiList(payload).then((res) => {
        if (res.status == 200) {
          console.log(res);
          let contractList = res.data.contractList;
          this.contractTable = contractList;
        } else {
          this.contractTable = [];
        }
      });
    },
    //同步合约账户
    batchSynchContractApi() {
      let payload = {
        token: sessionStorage.getItem("token"),
      };
      batchSynchContractApi(payload).then((res) => {});
    },

    // 查询历史入金用户列表
    fundHistoryList(payload) {
      fundHistoryList(payload).then((res) => {
        if (res.status == 200) {
          if (res.data.historyUserList) {
            let historyUserList = res.data.historyUserList;
            historyUserList.forEach((e) => {
              e.newTime = this.date_format(e.endTime - e.createTime);
              e.createTime = this.timestampToTime(e.createTime);
              e.endTime = this.timestampToTime(e.endTime);

              e.state = [];
              e.state.push(
                e.sumFloatEarnings > 0
                  ? "true5"
                  : e.sumFloatEarnings < 0
                  ? "false5"
                  : "zero5",
                e.sumYearIncome > 0
                  ? "true6"
                  : e.sumYearIncome < 0
                  ? "false6"
                  : "zero6"
              );
              e.sumYearIncome =
                e.sumYearIncome
                  .toString()
                  .slice(0, e.sumYearIncome.indexOf(".") + 3) + "%";
              let sumFloatEarnings = (
                (Number(e.sumFloatEarnings) / Number(e.investCoinNum)) *
                100
              ).toString();
              if (Number(e.sumFloatEarnings) !== 0) {
                e.sumFloatEarnings =
                  e.sumFloatEarnings +
                  "(" +
                  sumFloatEarnings.slice(0, sumFloatEarnings.indexOf(".") + 3) +
                  "%" +
                  ")";
              }
              console.log(this.orderData);
              this.orderData.forEach((item) => {
                if (item.title == "总投入" || item.title == "总收益") {
                  item.title += "(" + e.investCoin + ")";
                }
              });
            });
            this.tableData = historyUserList;
            console.log("历史用户列表", res.data.historyUserList);
          } else {
            this.tableData = [];
          }
        }
      });
    },
    // 查询交易所列表
    getExchangeList() {
      let payload = {
        token: sessionStorage.getItem("token"),
        teamProjectId: this.project.teamProjectId,
      };
      userManagementExchangelist(payload).then((res) => {
        if (res.status == 200) {
          this.exchangeLimit = res.data.exchangeLimit;
          this.searchData.forEach((e) => {
            if (e.name == "交易所") {
              let exchangeLimit = res.data.exchangeLimit;
              exchangeLimit.forEach((item) => {
                e.data.push(item.exchangeName);
              });
            }
          });
          console.log("交易所列表", res.data);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    activeShowBtn() {
      if (!this.detailsOperationAuthority) {
        // this.$message.error("不是模板创建人，无权操作");
        this.$alert("您没有权限使用该功能，敬请谅解！", "额，好像没有权限", {
          confirmButtonText: "知道啦",
        });
        return;
      }
      if (this.selectRowList.length == 0) {
        return this.$message.warning("请选择实例");
      }
      this.activeShow = true;
    },
    //强制结算
    batchForceSettlement() {
      let peopleIds;
      this.selectRowList.forEach((e, index) => {
        if (index == 0) {
          peopleIds = e.id.toString();
        } else {
          peopleIds += "," + e.id.toString();
        }
      });
      let payload = {
        token: sessionStorage.getItem("token"),
        peopleIds: peopleIds,
      };
      batchForceSettlement(payload).then((res) => {
        console.log(res);
        if (res.status == 200) {
          if (res.data.errorMap) {
            let errorMap = res.data.errorMap;
            for (let i in errorMap) {
              return this.$message.error(errorMap[i]);
            }
          }
          this.activeShow = false;
          let payload = {
            token: sessionStorage.getItem("token"),
            teamProjectId: this.project.teamProjectId,
            roleUserId: this.roleUserId,
          };
          this.userManagement(payload);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    //日期换算
    timestampToTime(timestamp) {
      var date = new Date(timestamp); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
      var Y = date.getFullYear() + "-";
      var M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      var D =
        date.getDate() < 10
          ? "0" + date.getDate() + " "
          : +date.getDate() + " ";
      var h =
        date.getHours() < 10
          ? "0" + date.getHours() + ":"
          : date.getHours() + ":";
      var m =
        date.getMinutes() < 10
          ? "0" + date.getMinutes() + ":"
          : date.getMinutes() + ":";
      var s =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      return Y + M + D + h + m + s;
    },
    //时分秒换算
    date_format(micro_second) {
      var second = Math.floor(micro_second / 1000);
      var day = Math.floor(second / 3600 / 24);
      var hr = Math.floor((second / 3600) % 24);
      var min = Math.floor((second / 60) % 60);
      return day + "d " + hr + "h " + min + "min";
    },

    // 传递页面active
    setActive(v) {
      console.log(22222, v);
      this.$emit("childFn", v);
    },
  },
  created() {
    this.orderData = this.orderDataList[0];
    this.rowStyleData = this.rowStyleDataList[0];
    let account = sessionStorage.getItem("account");
    this.account = JSON.parse(account);
    let project = sessionStorage.getItem("project");
    this.project = JSON.parse(project);
    let roleUserId = this.$route.query.userId;
    // this.roleUserId = roleUserId ? roleUserId : null;
    if (roleUserId) {
      this.roleUserId = roleUserId;
    } else {
      this.roleUserId = sessionStorage.getItem("userId");
    }

    this.batchSynchAssetApi();
    this.getAssetApiList();
    this.batchSynchContractApi();
    this.getContractApiList();

    let payload = {
      token: sessionStorage.getItem("token"),
      teamProjectId: this.project.teamProjectId,
      roleUserId: this.roleUserId,
    };
    if (sessionStorage.getItem("searchData")) {
      let searchData = JSON.parse(sessionStorage.getItem("searchData"));
      console.log(searchData);
      payload.availableNumEnd =
        searchData[3][1] == "" ? 0 : Number(searchData[4][1]);
      payload.availableNumStrat =
        searchData[3][0] == "" ? 0 : Number(searchData[4][0]);
      payload.exchangeName = searchData[0] == this.$t('m.全部') ? null : searchData[0];
      payload.investCoinNumEnd =
        searchData[2][1] == "" ? 0 : Number(searchData[2][1]);
      payload.investCoinNumStart =
        searchData[2][0] == "" ? 0 : Number(searchData[2][0]);
      payload.timeStart = new Date(searchData[1][0]).getTime();
      payload.timeEnd = new Date(searchData[1][1]).getTime();
    }

    setTimeout(() => {
      if (sessionStorage.getItem("Now")) {
        this.firstNow = false;
        let now = sessionStorage.getItem("Now");
        console.log(now);
        if (now == "true") {
          this.tableActive = 0;
          this.news = false;
          this.newsRemarks = true;
          this.userManagement(payload);
        } else {
          this.tableActive = 1;
          this.newsRemarks = false;
          this.news = true;
          this.fundHistoryList(payload);
        }
        this.orderData = this.orderDataList[this.tableActive];
        this.rowStyleData = this.rowStyleDataList[this.tableActive];
      } else {
        sessionStorage.setItem("Now", "true");
        this.tableActive = 0;
        this.userManagement(payload);
      }
    }, 1000);

    this.getExchangeList();
    let detailsOperationAuthority = sessionStorage.getItem(
      "detailsOperationAuthority"
    );
    this.detailsOperationAuthority = JSON.parse(detailsOperationAuthority);
  },

  mounted() {},
};
</script>

<style lang='scss' scoped>
.box {
  width: 100%;
  height: 100%;
}
.navItem {
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #ffffff;
  width: 1680px;
  min-height: 600px;
  .nav {
    border-radius: 8px;
    padding: 10px 20px;
    .topData {
      display: flex;
      border-bottom: 1px solid #e6e9ee;
      height: 40px;
      font-size: 16px;
      align-items: center;
      margin-bottom: 20px;
      .topDataItem {
        height: 100%;
        line-height: 40px;
        padding: 0 20px;
        cursor: pointer;
        color: #55698a;
      }
      .brdStyle {
        border-bottom: 2px solid #2174ff;
        color: #2174ff;
      }
    }

    .tableStyle {
      border: 1px solid #e6e9ee;
      padding-bottom: 20px;
      .topColumn {
        display: flex;
        font-size: 12px;
        align-items: flex-start;
        justify-content: space-between;
        padding: 0 30px;
        background-color: #32333d;
        color: #ffffff;
        /deep/.el-input__inner {
          background-color: #1c1d28 !important;
          border: 1px solid #1c1d28 !important;
        }
        .one {
          display: flex;
          align-items: center;
          margin-right: 30px;
          span {
            white-space: nowrap;
          }
        }
        .searchStyle {
          background-color: #32333d;
          color: #ffffff;
        }
        .btnData {
          display: flex;
          height: 100%;
          .btn-submit {
            margin-right: 10px;
            background-color: #2174ff;
          }
          span {
            width: 60px;
            margin: 5px 0;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            border-radius: 2px;
          }
        }
      }
      .centerColumn {
        display: flex;
        justify-content: flex-end;
        padding: 10px 40px 10px 0;
        color: #2174ff;
        font-size: 12px;
        span {
          margin-left: 20px;
          cursor: pointer;
        }
        .centerColumnItem {
          display: flex;
          align-items: center;
          height: 16px;
          .headerIcon {
            margin: 1px 3px 0 0;
          }
        }
      }
    }
  }
  .paginStyle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #55698a;
    padding: 20px 100px 40px;
    .paginCenter {
      display: flex;
      align-items: center;
    }
  }
}
/deep/.el-dialog__body {
  padding: 0 30px;
  border-top: 1px solid #f0f0f0;
  border-bottom: 1px solid #f0f0f0;
  .dialog-nav {
    color: #55698a;
    padding: 20px 0 30px;
    font-size: 12px;
    .dialog-top {
      font-size: 16px;
      color: #102859;
    }
  }
}
.dialog-footer {
  display: flex;
  justify-content: flex-end;
  div {
    width: 72px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    cursor: pointer;
  }
  .footerBack {
    border: 1px solid #c2d9ff;
    color: #2174ff;
  }
  .footerBtn {
    color: #ffffff;
    background: #2174ff;
    border: 1px solid #c2d9ff;
    margin-left: 12px;
  }
}
.mark-warp {
  .markInp {
    border: none;
    margin-top: 20px;
    ::v-deep.el-input__inner {
      border: 0px solid #dcdfe6 !important;
      border-bottom: 1px solid #dcdfe6 !important;
      border-radius: 0 !important;
      padding-left: 0px !important;
    }
  }
  .tips {
    display: inline-block;
    margin: 6px 0 50px 4px;
    font-size: 12px;
    color: #999;
  }
}

table th,
table td {
  border-bottom: none !important;
}
.el-input--mini .el-input__inner {
  height: 22px !important;
  line-height: 22px !important;
}
.el-input--mini .el-input__icon {
  line-height: 22px !important;
}
</style>