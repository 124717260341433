<template>
  <!-- 操作日志 -->
  <div class="operationLog">
    <el-container>
      <el-header>
        <div class="header-warp">
          <i class="el-icon-arrow-left" @click="goBack"></i>
          <span>{{$t('m.操作日志')}}</span>
          <span></span>
        </div>
      </el-header>
      <!-- main -->
      <el-main>
        <div class="content">
          <div v-if="logList.length == 0">{{$t('m.暂无记录')}}</div>
          <div v-else class="content-item">
            <div class="log-box" v-for="(item, idx) in logList" :key="idx">
              <div class="log-item">
                <div>{{ item.createTime }}</div>
                <div>[{{ item.publicCode }}]</div>
                <div>[{{ item.transactionPair }}]</div>

                <div>[{{ item.log.tradeType == 0 ? $t('m.卖出') : $t('m.买入') }}]</div>

                <div>[{{$t('m.价格') + '：' + item.log.price}}]</div>
                <div>[{{$t('m.数量') + '：' + item.log.amount + ' ' + (item.transactionType == "contract_usdt" ? $t('m.张') : item.log.counterCoin)}}]</div>
                <div :style="{ color: item.log.states == '1' ? 'red' : '' }">[{{getStatus(item.log)}}]</div>
              
              </div>
              <div v-if="item.log.error" style="margin-bottom: 18px; color: #606266">
                {{ item.log.error }}
              </div>
            </div>
          </div>

          <div class="pagination-warp">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              background
              :page-sizes="[10, 20, 30, 40, 50]"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total">
            </el-pagination>
          </div>
        </div>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import moment from "moment";
import { queryOrderLog } from "../../request/api";
export default {
  components: {},
  data() {
    return {
      moment,
      availableList: [], //列表数据
      multipleSelection: [],
      templateId: null, //模板ID
      project: null,
      logList: [],
      pageNo: 1,
      pageSize: 10,
      total: 0,
    };
  },
  computed: {},
  watch: {},
  methods: {

    //获取委托状态
    getStatus(log) {

      var str;
      if(log.status == 0 && log.states == 0) {
        str = '委托成功';
      } else if(log.status == 0 && log.states != 0) {
        str = '委托失败';
      } else if(log.status != 0 && log.tradeType == 0) {
        str = '委托卖出';
      } else if(log.status != 0 && log.tradeType != 0) {
        str = '委托买入';
      }

      return this.$t('m.'+str);
    },

    // 返回上一页
    goBack() {
      this.$router.back(-1);
    },

    handleSelectionChange(val) {
      this.multipleSelection = val;
    },

    //获取订单日记
    queryOrderLog() {
      let payload = {
        token: sessionStorage.getItem("token"),
        teamProjectId: this.teamProjectId,
        transactionType: this.transactionType,
        pageNo: this.pageNo,
        pageSize: this.pageSize,
      };
      queryOrderLog(payload).then((res) => {
        if (res.status == 200) {
          let orderLogList = res.data.orderLogList;
          orderLogList.forEach((e) => {
            e.createTime = this.timestampToTime(e.createTime);
            e.log = JSON.parse(e.log);
            let price = e.log.price;
            let amount = e.log.amount;
            if (e.log.price.indexOf(".") > 0) {
              e.log.price = price.slice(0, price.indexOf(".") + 3);
              if (e.log.price.length - e.log.price.indexOf(".") == 2) {
                e.log.price += "0";
              }
            }
            if (e.log.amount.indexOf(".") > 0) {
              e.log.amount = amount.slice(0, amount.indexOf(".") + 7);
            }
          });
          this.total = res.data.total;
          this.logList = orderLogList;
          console.log(orderLogList);
        }
      });
    },

    handleSizeChange(val) {
      this.pageSize = val;
      this.queryOrderLog();
    },

    handleCurrentChange(val) {
      this.pageNo = val;
      this.queryOrderLog();
    },

    //日期换算
    timestampToTime(timestamp) {
      var date = new Date(timestamp); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
      var Y = date.getFullYear() + "-";
      var M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      var D =
        date.getDate() < 10
          ? "0" + date.getDate() + " "
          : +date.getDate() + " ";
      var h =
        date.getHours() < 10
          ? "0" + date.getHours() + ":"
          : date.getHours() + ":";
      var m =
        date.getMinutes() < 10
          ? "0" + date.getMinutes() + ":"
          : date.getMinutes() + ":";
      var s =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      return Y + M + D + h + m + s;
    },
  },
  created() {},
  mounted() {
    // this.templateId = this.$route.query.teamProjectId;
    // console.log(this.templateId);
    // let project = sessionStorage.getItem("project");
    // this.project = JSON.parse(project);
    // this.getOperateLog();
    // console.log(this.templateId);
  },
  beforeCreate() {},
  beforeMount() {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {},
  activated() {
    this.teamProjectId = this.$route.query.teamProjectId;
    console.log(this.$route.query.transactionType);
    this.transactionType = this.$route.query.transactionType;
    let project = sessionStorage.getItem("project");
    this.project = JSON.parse(project);

    sessionStorage.setItem("projectActive", this.$route.query.projectActive);
    this.queryOrderLog();
  },
};
</script>
<style lang='scss' scoped>
.operationLog {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .el-container {
    background: #eff2f5;
    height: 100%;
  }
  .el-header {
    background-color: #fff;
    color: #333;
    text-align: center;
    line-height: 60px;
    margin-bottom: 20px;
    .header-warp {
      display: flex;
      justify-content: space-between;
      align-items: center;
      i {
        cursor: pointer;
      }
    }
  }
  .el-main {
    color: #333;
    padding: 0 10%;
    font-size: 14px;
    padding-bottom: 20px;
    .content {
      background: #fff;
      border-radius: 8px;
      padding: 20px;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      .content-item {
        height: 750px;
        display: flex;
        flex-direction: column;
        overflow-y: scroll;
        .log-box {
          display: flex;
          flex-direction: column;
          color: #666;
          font-size: 14px;
          border-bottom: 1px solid #eaeaec;
          .log-item {
            display: flex;
            align-items: center;
            margin-right: 10px;
            padding: 20px 0;
          }
        }
      }
      .pagination-warp {
        display: flex;
        justify-content: center;
        margin: 20px 0;
      }
    }
  }
}
</style>