<template>
  <div class="strategy">
    <div class="create-policy-template" v-if="detailsOperationAuthority">
      <div class="template-btn" @click="createPolicyTemplate">
        {{'+ '+$t('m.创建策略模版')}} 
      </div>
      <div class="sort-btn" @click="getTemplateSortList()">
        {{$t('m.模板排序')}}</div>
    </div>

    <el-tabs
      type="border-card"
      v-model="activeName"
      @tab-click="tabsHandleClick">
      <template v-for="(item, idx) in templateList">
        <el-tab-pane :name="idx + ''" :key="idx">
          <span slot="label">
            <span>{{ item.templateName + " (" + item.total + ")" }}</span>
            <span class="lable-error" v-if="item.isException == 1"></span>
          </span>
          
          <!-- 内容 -->
          <div class="content">
            <div class="content-top">
              <div class="content-top-operation" v-if="currentTemplate">
                <div class="one" v-if="currentTemplate">
                  <span>{{
                    $t('m.'+currentTemplate.strategyName) +
                    " · " +
                    currentTemplate.exchangeName +
                    " " +
                    (currentTemplate.templatePattern == 0
                      ? $t('m.自定义')
                      : $t('m.多币对')) +
                    " " +
                    (currentTemplate.symbolCount
                      ? currentTemplate.symbolCount
                      : "")
                  }}</span>
                  <span class="addAcc" @click="addAccount">
                    {{'+ '+$t('m.添加账户')}}</span>
                </div>

                <!-- 模板操作按钮 -->
                <div class="operation-btn">
                  <span @click="operationBtn('batchTermination')">
                    {{$t('m.终止')}}</span>
                  <span @click="operationBtn('suspend')">
                    {{$t('m.暂停')}}</span>
                  <span @click="operationBtn('restart')">
                    {{$t('m.重启')}}</span>
                  <span @click="operationBtn('increaseShow')">
                    {{$t('m.加仓')}}</span>
                  <span @click="modifyParam()">
                    {{$t('m.参数')}}</span>
                  <span
                    @click="operationBtn('addCurrencyPair')"
                    v-if="currentTemplate.templatePattern == 1">
                    {{$t('m.增加子币对')}}</span>
                  <span
                    @click="operationBtn('detailPair')"
                    v-if="currentTemplate.templatePattern == 1">
                    {{$t('m.删除子币对')}}</span>
                </div>

                <!-- 模板修改按钮 -->
                <div class="templateOperation-warp">
                  <span @click="ModifyTemplate()">
                    {{$t('m.修改模板总投入')}}</span>
                  <span @click="operationBtn('templateNameModify')">
                    {{$t('m.模板重命名')}}</span>
                  <span @click="deleteStrategyTemplate" style="color: #f56c6c">
                    {{$t('m.移除策略模版')}}</span>
                </div>

                <!-- 自动交易 -->
                <div class="operation-switch">
                  <span class="operation-switch--title">
                    {{$t('m.自动交易')}}</span>
                  <el-tooltip
                    class="item"
                    effect="light"
                    :content="$t('m.开启后新分配到的账户将自动启动此策略')"
                    placement="bottom">
                    <img
                      src="../../assets/icon/but_popovres备份@2x.png"
                      alt=""
                    />
                  </el-tooltip>
                  <el-switch
                    v-model="automatic"
                    inactive-text=""
                    @change="updateTemplateTradeSwitch">
                  </el-switch>
                </div>
              </div>

              <div class="operation-search">
                <div class="one">
                  <span>
                    {{$t('m.策略：')}}</span>
                  <el-select
                    v-model="strategyType"
                    :placeholder="$t('m.请选择')"
                    @change="typeSelest">
                    <el-option
                      v-for="item in options"
                      :key="item.value"
                      :label="$t('m.'+item.label)" 
                      :value="item.value">
                    </el-option>
                  </el-select>
                </div>
              </div>
            </div>
            
            <div class="content-bottom">
              <div class="content-bottom-title">
                <span>
                  {{$t('m.当前总投入(USDT)：') + detailsList.totalInvestCoinNum
                }}</span>
                <span>
                  {{$t('m.总收益(USDT)：')
                  + Math.floor(Number(detailsList.totalRealEarns) * 10000) / 10000
                  }}
                </span>
                <span>
                  {{$t('m.总收益率：')
                  + detailsList.totalRealEarnsRate + "%"
                  }}
                </span>
              </div>
              <div>
                <el-table
                  ref="multipleTable"
                  :data="strategyList"
                  tooltip-effect="dark"
                  style="width: 100%"
                  border>
                  <el-table-column
                    :label="$t('m.时间')"
                    prop="startTime"
                    width="200"
                    sortable>
                    <template slot-scope="scope">{{
                      moment(scope.row.startTime).format("YYYY-MM-DD HH:mm:ss")
                    }}</template>
                  </el-table-column>
                  <el-table-column prop="strategyName" 
                  :label="$t('m.实例')" width="160">
                    <template slot-scope="scope">
                      {{
                        $t('m.'+scope.row.strategyName) + " (" + scope.row.code + " )"
                      }}
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="investCoinNum"
                    :label="$t('m.总投入(USDT)')"
                    width="140"
                    sortable>
                    <template slot-scope="scope">
                      {{
                        Math.floor(Number(scope.row.investCoinNum) * 10000) /
                        10000
                      }}
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="realEarnings"
                    :label="$t('m.总收益(USDT)')"
                    width="140"
                    sortable>
                    <template slot-scope="scope">
                      {{
                        Math.floor(Number(scope.row.realEarnings) * 10000) /
                        10000
                      }}
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="earnings"
                    :label="$t('m.实现收益 (USDT)')"
                    width="160"
                    sortable>
                    <template slot-scope="scope">
                      {{
                        Math.floor(Number(scope.row.earnings) * 10000) / 10000
                      }}
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="floatEarnings"
                    :label="$t('m.浮动收益(USDT)')"
                    width="160"
                    sortable>
                    <template slot-scope="scope">
                      {{
                        Math.floor(Number(scope.row.floatEarnings) * 10000) /
                        10000
                      }}
                    </template>
                  </el-table-column>

                  <el-table-column
                    prop="realYearIncome"
                    :label="$t('m.总年化/实现年化')+' (%)'"
                    width="150">
                    <template slot-scope="scope">
                      {{
                        (scope.row.realYearIncome
                          ? scope.row.realYearIncome
                          : "0") +
                        " / " +
                        (scope.row.yearIncome ? scope.row.yearIncome : "0")
                      }}
                    </template>
                  </el-table-column>

                  <el-table-column
                    prop="finishBuyCount"
                    :label="$t('m.交易数（买入）（卖出）')"
                    width="180">
                    <template slot-scope="scope">
                      {{
                        (scope.row.finishBuyCount
                          ? scope.row.finishBuyCount
                          : "0") +
                        " / " +
                        (scope.row.finishSellCount
                          ? scope.row.finishSellCount
                          : "0")
                      }}
                    </template>
                  </el-table-column>

                  <el-table-column width="100" prop="address" :label="$t('m.状态')">
                    <template slot-scope="scope">
                      <span v-if="scope.row.states == 'EXECUTE'">
                        {{$t('m.执行中')}}</span>
                      <span v-if="scope.row.states == 'SUSPEND'">
                        {{$t('m.已暂停')}}</span>
                      <span v-if="scope.row.states == 'STARTING'">
                        {{$t('m.启动中')}}</span>
                      <span v-if="scope.row.states == 'RESTARTING'">
                        {{$t('m.重启中')}}</span>
                      <span v-if="scope.row.states == 'STOPING'">
                        {{$t('m.终止中')}}</span>
                      <span v-if="scope.row.states == 'STARTERROR'">
                        {{$t('m.启动错误')}}</span>
                    </template>
                  </el-table-column>

                  <el-table-column :label="$t('m.操作')" min-width="160">
                    <template slot-scope="scope">
                      <div class="deposit-operation">
                        <span @click="operationBtn('termination', scope.row)">
                          {{$t('m.终止')}}</span>
                        <span
                          class="detail"
                          @click="operationBtn('details', scope.row)">
                          {{$t('m.详情')}}</span>
                        <span
                          @click="operationBtn('message', scope.row)"
                          class="message">
                          <span>{{$t('m.消息')}}</span>
                          <span
                            class="message-error"
                            v-if="scope.row.errorInfo"></span>
                        </span>
                      </div>
                    </template>
                  </el-table-column>
                </el-table>
                <div class="pagination-warp" v-if="total > 10">
                  <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="1"
                    :page-sizes="[10, 50, 100, 500]"
                    :page-size="10"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="total">
                  </el-pagination>
                </div>
              </div>
            </div>
          </div>
        </el-tab-pane>
      </template>
    </el-tabs>

    <!-- 日志 -->
    <div class="log" v-if="templateList">
      <div class="log-title">
        <span>
          <span>{{$t('m.日志')}}</span>
          <!-- <span class="error-info" v-if="logError == 1"></span> -->
        </span>
        <span class="more" @click="toLogDetail()">
          <span>{{$t('m.更多')}}</span>
          <span class="error-info" v-if="logError == 1"></span>
          <span>>></span>
        </span>
      </div>
      <template v-for="(item, idx) in logList">
        <div
          class="log-box"
          :key="idx"
          :style="{ color: item.msg ? '#F56C6C' : '#666' }">
          <span>{{
            moment(item.createTime).format("YYYY-MM-DD HH:mm:ss")
          }}</span>
          <span>{{ "[" + item.publicCode + "]" }}</span>
          <span>{{ "[" + item.templateName + "]" }}</span>
          <span>{{ "[" + $t('m.'+item.strategyName) + "]" }}</span>
          <span>{{ "[" + item.content + "]" }}</span>
          <span v-if="item.msg">{{ "[" + item.msg + "]" }}</span>
          <span v-if="!item.msg">[{{$t('m.操作成功')}}]</span>
        </div>
      </template>
      <div v-if="logList.length == 0" class="logdefault">
        {{$t('m.暂无日志')}}</div>
    </div>

    <!-- 缺省页 -->
    <div class="defaultPage" v-if="!templateList">
      <!-- <img src="../../assets/image/img_01@2x (1).png" alt="" />
      <span>暂无数据</span> -->
      <img class="img1" src="../../assets/image/img_002@2x.png" alt="" />
      <span class="create-template" @click="createPolicyTemplate()">
        {{$t('m.策略模板还是空的，快去创建策略模板吧～')}}</span>
      <img class="img2" src="../../assets/image/img_001@2x.png" alt="" />
    </div>

    <div v-if="!templateList" class="no-log">
      <span>{{$t('m.操作日志')}}</span>
      <span>{{$t('m.更多>>')}}</span>
    </div>

    <!-- 终止 -->
    <el-dialog
      title="终止"
      :visible.sync="terminationShow"
      width="20%"
      :before-close="handleClose"
      :close-on-click-modal="false">
      <div class="termination">
        <div class="title">
          {{$t('m.终止确认')}}</div>
        <div class="text">
          {{$t('m.请确认终止现有策略实例&(1)个，确认终止后，策略将自动撤销未成交的委托单，并兑换成投入币种。')
          .replace('&(1)', listNumber)}}
        </div>
        <div class="tips">
          {{$t('m.此过程将持续几分钟，请耐心等待！您可以查看其他页面，但不可进行操作，以免数据出错。')}}
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="terminationShow = false">
          {{$t('m.取 消')}}</el-button>
        <el-button type="primary" @click="batchStrategyEnd">
          {{$t('m.确 定')}}</el-button>
      </span>
    </el-dialog>

    <!-- 暂停 -->
    <el-dialog
      :title="$t('m.暂停')"
      :visible.sync="suspendShow"
      width="20%"
      :before-close="handleClose"
      :close-on-click-modal="false">
      <div class="suspend">
        <div class="title">
          {{$t('m.暂停确认')}}</div>
        <div class="text">
          {{$t('m.请确认暂停现有策略实例&(1)个，确认暂停后，策略将自动挂起，不再执行新的委托，未成交的委托单继续等待成交，数据不再更新。请确认终止现有策略实例')
          .replace('&(1)', number)}}
        </div>
        <div class="tips">
          {{$t('m.此过程将持续几分钟，请耐心等待！您可以查看其他页面，但不可进行操作，以免数据出错。')}}
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="suspendShow = false">
          {{$t('m.取 消')}}</el-button>
        <el-button type="primary" @click="confirmSuspend">
          {{$t('m.确 定')}}</el-button>
      </span>
    </el-dialog>

    <!-- 加仓 -->
    <el-dialog
      :title="$t('m.加仓')"
      :visible.sync="increaseShow"
      width="20%"
      :before-close="handleClose"
      :close-on-click-modal="false">
      <div class="increase" v-if="limitMap">
        <div class="text">
          <el-tabs v-model="increaseActive" @tab-click="handleClick">
            <el-tab-pane :label="$t('m.可用资金比例')" name="1">
              <div class="box">
                <span class="box-title">
                  {{$t('m.可用资金百分比')}}</span>
                <el-input
                  v-model="proportion"
                  placeholder="请输入内容"
                  type="number">
                  <template slot="append">%</template>
                </el-input>
              </div>
              <div class="box">
                <span class="box-title">
                  {{$t('m.加仓投入上限')}}</span>
                <el-input v-model="max" :placeholder="$t('m.请输入内容')" type="number">
                  <template slot="append">USDT</template>
                </el-input>
              </div>
              <div class="box">
                <span class="box-title">
                  {{$t('m.加仓投入下限')}}</span>
                <el-input v-model="min" :placeholder="$t('m.请输入内容')" type="number">
                  <template slot="append">USDT</template>
                </el-input>
              </div>
            </el-tab-pane>
            <el-tab-pane :label="$t('m.固定投入金额')" name="2">
              <div class="box">
                <span class="box-title">
                  {{$t('m.加仓金额')}}</span>
                <el-input
                  v-model="fixedInvestment"
                  :placeholder="$t('m.请输入内容')"
                  type="number">
                  <template slot="append">USDT</template>
                </el-input>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
        <div class="tips">
          <div>{{$t('m.满足比例，但低于下限不投；')}}</div>
          <div>{{$t('m.满足比例，但高于上限的按上限投；')}}</div>
          <div>{{$t('m.比例投入或固定投入需满足策略本身投入限制，')}}</div>
          <div>{{$t('m.高于策略限制上限按策略上限投。')}}</div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="increaseShow = false">
          {{$t('m.取 消')}}</el-button>
        <el-button type="primary" @click="scaleIn">
          {{$t('m.确 定')}}</el-button>
      </span>
    </el-dialog>

    <!-- 修改参数 -->
    <el-dialog
      :title="$t('m.参数配置')"
      :visible.sync="modifyParametersShow"
      width="25%"
      :before-close="handleClose"
      :close-on-click-modal="false">
      <div class="modifyParameters">
        <template v-for="(item, idx) in reserves">
          <div
            class="box"
            :key="idx"
            v-if="
              item.visiable == '1' ||
              strategyId == 'WL20190402171936974x' ||
              strategyId == 'CL20181115155643599x'
            ">
           
            <template v-if="item.name != '定投周期' && item.name != '定投类型'">
              <div class="parameter-box-label">
                <span>{{ item.name }}</span>
                <span v-if="item.valueMin || item.start">{{
                  $t('m.范围：') +
                  (item.valueMin != "" && item.valueMin && item.valueMin != 0
                    ? item.valueMin
                    : item.start) +
                  "~" +
                  (item.valueMax != "" && item.valueMax && item.valueMax != 0
                    ? item.valueMax
                    : item.end)
                }}</span>
              </div>
              <div class="parameter-box-text">
                <input
                  type="number"
                  :placeholder="$t('m.请输入内容')"
                  v-model="item.value"
                  @blur="inputCheck($event, item, idx)"
                />
              </div>
            </template>
            <template v-if="item.name == '定投周期'">
              <div class="parameter-box-label">
                <span>{{ item.name }}</span>
                <span v-if="item.valueMin || item.start">{{
                  $t('m.范围：') +
                  (item.valueMin != "" && item.valueMin
                    ? item.valueMin
                    : item.start) +
                  "~" +
                  (item.valueMax != "" && item.valueMax
                    ? item.valueMax
                    : item.end)
                }}</span>
              </div>
              <div class="parameter-box-text">
                <div class="block">
                  <el-cascader
                    v-model="fixedTime"
                    :options="times"
                    @change="handleChange"
                  ></el-cascader>
                </div>
              </div>
            </template>
          </div>
        </template>
      </div>
      <span slot="footer" class="dialog-footer">
        <div class="btn" @click="confirmUpdateParam">
          {{$t('m.修改参数')}}</div>
      </span>
    </el-dialog>

    <!-- 增加币对 -->
    <template v-if="addCurrencyPairShow">
      <el-dialog
        :title="$t('m.增加子币对')"
        :visible.sync="addCurrencyPairShow"
        width="20%"
        :before-close="handleClose"
        :close-on-click-modal="false">
        <div class="addCurrencyPair">

          <div class="box" v-for="(item, idx) in pairList" :key="'pairList'+idx">
            <div class="checkbox-warp">
              <el-checkbox
                :checked="item.type"
                :key="idx"
                @change="pairChange(item)">
                <div class="box-text">
                  <span>{{ item.counterCoin }}</span>
                  <span>{{ "/" + item.baseCoin }}</span>
                </div>
              </el-checkbox>
            </div>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="addCurrencyPairShow = false">
            {{$t('m.取 消')}}</el-button>
          <el-button type="primary" @click="confirmAddCurrencyPair">
            {{$t('m.确 定')}}</el-button>
        </span>
      </el-dialog>
    </template>

    <!-- 删除子币对 -->
    <template v-if="detailPairShow">
      <el-dialog
        :title="$t('m.删除子币对')"
        :visible.sync="detailPairShow"
        width="20%"
        :before-close="handleClose"
        :close-on-click-modal="false">
        <div class="addCurrencyPair">
          <!-- <div class="title">
            请谨慎增加币对，避免操作失败致使实例，被大量 移至未分组
          </div> -->
          <div class="box" v-for="(item, idx) in detailPairList" :key="'detailPairList'+idx">
            <div class="checkbox-warp">
              <el-checkbox
                :checked="item.type"
                :key="idx"
                @change="pairChange(item)">
                <div class="box-text">
                  <span>{{ item.counterCoin }}</span>
                  <span>{{ "/" + item.baseCoin }}</span>
                </div>
              </el-checkbox>
            </div>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="detailPairShow = false">
            {{$t('m.取 消')}}</el-button>
          <el-button type="primary" @click="confirmDetailPair()">
            {{$t('m.确 定')}}</el-button>
        </span>
      </el-dialog>
    </template>

    <!-- 模板重命名 -->
    <el-dialog
      :title="$t('m.模板重命名')"
      :visible.sync="updateTemplateNameShow"
      width="30%"
      :before-close="handleClose"
      :close-on-click-modal="false">

      <el-input
        v-model="templateNameCopy"
        type="text"
        :placeholder="$t('m.请输入内容')">
      </el-input>

      <span slot="footer" class="dialog-footer">
        <el-button @click="updateTemplateNameShow = false">
          {{$t('m.取 消')}}</el-button>
        <el-button type="primary" @click="modifyName">
          {{$t('m.确 定')}}</el-button>
      </span>
    </el-dialog>

    <!-- 修改模板总投入 -->
    <el-dialog
      :title="$t('m.修改模板投入配置')"
      :visible.sync="ModifyTemplateInvestmentShow"
      width="20%"
      :before-close="handleClose"
      :close-on-click-modal="false">
      <div class="increase" v-if="limitMap && investConfig">
        <div class="title">
          <span>
            {{$t('m.配置投入')}}</span>
          <span>
            {{$t('m.范围值：') + limitMap.minInvest + "~" + limitMap.maxInvest + "USDT"
          }}</span>
        </div>
        <div class="text">
          <el-tabs v-model="templateActive">
            <el-tab-pane :label="$t('m.可用资金比例')" name="1">
              <div class="box">
                <span class="box-title">
                  {{$t('m.可用资金百分比')}}</span>
                <el-input
                  v-model="investConfig.proportionInvest.proportion"
                  :placeholder="$t('m.请输入内容')">
                  <template slot="append">%</template>
                </el-input>
              </div>

              <div class="box">
                <span class="box-title">{{$t('m.投入上限')}}</span>
                <el-input
                  v-model="investConfig.proportionInvest.maxInvest"
                  :placeholder="$t('m.请输入内容')">
                  <template slot="append">USDT</template>
                </el-input>
              </div>

              <div class="box">
                <span class="box-title">{{$t('m.投入下限')}}</span>
                <el-input
                  v-model="investConfig.proportionInvest.minInvest"
                  :placeholder="$t('m.请输入内容')">
                  <template slot="append">USDT</template>
                </el-input>
              </div>
            </el-tab-pane>
            <el-tab-pane :label="$t('m.固定投入金额')" name="2">
              <div class="box">
                <span class="box-title">{{$t('m.定投金额')}}</span>
                <el-input
                  v-model="investConfig.fixInvest"
                  :placeholder="$t('m.请输入内容')">
                  <template slot="append">USDT</template>
                </el-input>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
        <div class="tips">
          <div>{{$t('m.修改模版总投入不影响已投入的策略')}}</div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="ModifyTemplateInvestmentShow = false">
          {{$t('m.取 消')}}</el-button>
        <el-button type="primary" @click="confirmModifyTemplate()">
          {{$t('m.确 定')}}</el-button>
      </span>
    </el-dialog>

    <!-- 模板排序列表 -->
    <el-dialog
      :title="$t('m.模板排序')"
      :visible.sync="dragSort"
      width="30%"
      :before-close="handleClose"
      :close-on-click-modal="false">
      <div class="templateSort">
        <p class="title">
          {{$t('m.新分配的用户将依据排序依次启动符合模板要求且开启自动交易的策略')}}
        </p>
        <div class="templateSort-content">
          <div class="content-title">
            <span>{{$t('m.模板名称')}}</span>
            <span>{{$t('m.自动交易')}}</span>
            <span>{{$t('m.排序')}}</span>
          </div>

          <ul>
            <li
              v-for="(item, index) in sortTemplateList"
              :key="index"
              v-dragging="{
                list: sortTemplateList,
                item: item,
                group: 'templateId',
              }">
              <div class="content-box">
                <span>{{ item.templateName }}</span>
                <el-switch v-model="item.tradeSwitch" active-color="#007AFF">
                </el-switch>
                <img src="../../assets/icon/sort.png" alt="" />
              </div>
            </li>
          </ul>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dragSort = false">
          {{$t('m.取 消')}}</el-button>
        <el-button type="primary" @click="sortConfirm()">
          {{$t('m.确 定')}}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  getTemplateList,
  getStrategyPageByTemplateList,
  updateTemplateName,
  updateTemplateTradeSwitch,
  deleteStrategyTemplate,
  strategyEnd,
  getOperateLog,
  batchSuspend,
  batchRestart,
  batchUpdateParam,
  getTemplateMessage,
  batchStrategyEnd,
  getLimitConfig,
  batchAddStore,
  getPairPage,
  batchManyAddSymbol,
  updateTemplateInvestCoinnNum,
  getPairPageById,
  deletePair,
  getTemplateSortList,
  updateTemplateSort,
} from "../../request/api";
import moment, { duration } from "moment";
import { times } from "../../assets/js/time";
export default {
  components: {},
  data() {
    return {
      moment,
      activeName: "0",
      automatic: null, //自动交易开关
      options: [
        {
          value: "全部",
          label: "全部",
        },
        {
          value: "正常状态",
          label: "正常状态",
        },
        {
          value: "异常状态",
          label: "异常状态",
        },
      ],
      strategyType: "全部", //策略状态
      input: "",

      terminationShow: false, //终止
      suspendShow: false, //暂停
      increaseShow: false, //加仓
      increaseActive: "1", //加仓页
      templateActive: "1", //修改模板投入
      modifyParametersShow: false, //修改参数
      addCurrencyPairShow: false, //增加币对
      checked: false,
      project: null,
      templateList: null, //策略模板列表
      detailsList: [], // 模板详情
      strategyList: [],
      strategyListCopy: [],
      updateTemplateNameShow: false, //模板重命名显隐
      templateNameCopy: null,

      currentTemplate: null, //当前选中模板

      logList: [], //日志列表

      number: 0, //策略数

      reserves: null,
      reservesCopy: null,
      exchangeId: null,
      pairs: [],
      limitMap: null,
      fixedInvestment: null, //固定加仓
      proportion: null, //百分比
      max: null,
      min: null,
      pairList: [], //交易对列表
      ModifyTemplateInvestmentShow: false, //修该模板投入
      investConfig: null,

      detailsOperationAuthority: null, //操作权限
      userId: null,

      paramsType: true, //参数校验
      parameterCheckList: [], //校验补充

      detailPairShow: false,
      detailPairList: null,
      strategyId: null,

      times: times,
      fixedTime: [],

      dragSort: false,
      sortTemplateList: [], //排序模板列表
      total: 0,
      current: 1,
      pageNo: 1,
      pageSize: 10,
      logError: null,
    };
  },
  computed: {},
  watch: {
    // automatic(New, old) {
    //   if (New==true) {
    //     this.updateTemplateTradeSwitch(1);
    //     console.log(New);
    //   } else if(New==false){
    //     this.updateTemplateTradeSwitch(0);
    //     console.log(New);
    //   }
    // },
  },
  methods: {
    tabsHandleClick(tab, event) {
      this.currentTemplate = JSON.parse(
        JSON.stringify(this.templateList[Number(this.activeName)])
      );
      this.templateNameCopy = JSON.parse(
        JSON.stringify(this.currentTemplate.templateName)
      );
      this.automatic = this.currentTemplate.tradeSwitch == 1 ? true : false;
      console.log(this.automatic, this.currentTemplate);
      this.getStrategyPageByTemplateList(
        this.templateList[Number(this.activeName)].templateId
      );
    },
    handleClick(tab, event) {
      console.log(this.increaseActive);
    },
    handleClose(done) {
      this.reserves = JSON.parse(JSON.stringify(this.reservesCopy));
      done();
    },
    // 添加账户
    addAccount() {
      console.log(this.currentTemplate.templateId);
      if (!this.detailsOperationAuthority) {
        // this.$message.error("不是模板创建人，无权操作");
        this.$alert(
          this.$t('m.加您没有权限使用该功能，敬请谅解！'), 
          this.$t('m.额，好像没有权限'), {
          confirmButtonText: this.$t('m.知道啦'),
        });
        return;
      }
      this.$router.push({
        path: "addAccount",
        query: { templateId: this.currentTemplate.templateId },
      });
    },
    // 创建策略模板
    createPolicyTemplate() {
      if (!this.detailsOperationAuthority) {
        // this.$message.error("不是模板创建人，无权操作");
        this.$alert(
          this.$t('m.加您没有权限使用该功能，敬请谅解！'), 
          this.$t('m.额，好像没有权限'), {
          confirmButtonText: this.$t('m.知道啦'),
        });
        return;
      }
      this.$router.push({ path: "createPolicyTemplate" });
    },
    // 操作系列
    operationBtn(val, item) {
      if (!this.detailsOperationAuthority) {
        // this.$message.error("不是模板创建人，无权操作");
        this.$alert(
          this.$t('m.加您没有权限使用该功能，敬请谅解！'), 
          this.$t('m.额，好像没有权限'), {
          confirmButtonText: this.$t('m.知道啦'),
        });
        return;
      }
      console.log(item);
      switch (val) {
        case "details": //详情
          this.$router.push({
            path: "strategyDetails",
            query: { executeId: item.executeId },
          });
          break;
        case "detailPair": //删除子币对
          this.detailPairList = this.currentTemplate.pairs;
          this.detailPairShow = true;
          console.log(this.currentTemplate.pairs);
          break;
        case "batchTermination": //批量终止
          if (this.number > 0) {
            this.terminationShow = true;
          } else {
            this.$message({
              message: this.$t('m.该模板下没有执行实例'),
              type: "warning",
            });
          }
          break;
        case "termination": //单个终止
          console.log(item.executeId);
          this.$confirm(this.$t('m.确认终止该策略？'))
            .then((_) => {
              this.confirmTermination(item.executeId);
            })
            .catch((_) => {});
          break;
        case "suspend": //暂停
          this.suspendShow = true;
          break;
        case "restart":
          this.confirmRestart(); //重启
          // this.suspendShow = true;
          break;
        case "increaseShow": //加仓
          this.getLimitConfig();
          this.increaseShow = true;
          break;
        case "addCurrencyPair": //增加币对
          this.getPairPage();
          break;
        case "message": //消息
          this.$router.push({
            path: "strategyMessage",
            query: { executeId: item.executeId },
          });
        case "templateNameModify": //修改模板名字
          this.updateTemplateNameShow = true;
          break;
        default:
          break;
      }
    },

    // 查询策略模板列表
    getTemplateList() {
      if (!this.userId) {
        return;
      }
      let payload = {
        userId: this.userId,
        token: sessionStorage.getItem("token"),
        teamProjectId: this.project.teamProjectId,
      };
      getTemplateList(payload).then((res) => {
        if (res.status == 200) {
          
          if (res.data.templateList) {
            this.templateList = res.data.templateList;
            if (this.currentTemplate) {
              for (let i = 0; i < this.templateList.length; i++) {
                if (
                  this.currentTemplate.templateId ==
                  this.templateList[i].templateId
                ) {
                  this.currentTemplate = this.templateList[i];
                }
              }
            } else {
              this.currentTemplate = res.data.templateList[0];
            }
            //
            this.automatic =
              this.currentTemplate.tradeSwitch == 1 ? true : false;
            console.log(this.automatic);
            this.templateNameCopy = JSON.parse(
              JSON.stringify(this.currentTemplate.templateName)
            );
            this.getStrategyPageByTemplateList(this.currentTemplate.templateId);
            console.log(this.currentTemplate);
          } else {
            this.templateList = null;
          }
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 查询模板详情
    getStrategyPageByTemplateList(id) {
      let payload = {
        token: sessionStorage.getItem("token"),
        teamProjectId: this.project.teamProjectId,
        templateId: id,
        errorInfo: false,
        pageNo: this.pageNo,
        pageSize: this.pageSize,
      };
      getStrategyPageByTemplateList(payload).then((res) => {
        if (res.status == 200) {
          
          this.detailsList = res.data;
          this.total = res.data.total;
          if (res.data.strategyList) {
            let list = this.detailsList.strategyList;
            for (let item of list) {
              item.floatEarnings = Number(item.floatEarnings);
              item.earnings = Number(item.earnings);
              item.realEarnings = Number(item.realEarnings);
              item.investCoinNum = Number(item.investCoinNum);
            }
            this.strategyList = JSON.parse(JSON.stringify(list));
            this.strategyListCopy = JSON.parse(JSON.stringify(list));
            this.number = res.data.strategyList.length
              ? res.data.strategyList.length
              : 0;
            if (this.currentTemplate.templatePattern == 1) {
              this.pairs = JSON.parse(res.data.strategyList[0].config).pairs;
            }
          } else {
            this.strategyList = [];
            this.number = 0;
          }
          this.getOperateLog(id);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 查询操作日志
    getOperateLog(id) {
      let payload = {
        token: sessionStorage.getItem("token"),
        templateId: id,
        pageNo: 1,
        pageSize: 3,
      };
      getOperateLog(payload).then((res) => {
        if (res.status == 200) {
          
          this.logError = res.data.isException;
          this.logList = res.data.logList;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 跳详情
    toLogDetail() {
      this.$router.push({
        path: "operationLog",
        query: { templateId: this.currentTemplate.templateId },
      });
    },
    // 修改模板名字
    modifyName() {
      // updateTemplateNameShow
      let payload = {
        token: sessionStorage.getItem("token"),
        templateId: this.currentTemplate.templateId,
        templateName: this.templateNameCopy,
      };
      updateTemplateName(payload).then((res) => {
        if (res.status == 200) {
          console.log(res.data);
          this.$message.success(this.$t('m.修改成功'));
          this.getTemplateList();
          this.updateTemplateNameShow = false;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 自动交易开关
    updateTemplateTradeSwitch(type) {
      if (!this.detailsOperationAuthority) {
        // this.$message.error("不是模板创建人，无权操作");
        this.$alert(
          this.$t('m.加您没有权限使用该功能，敬请谅解！'), 
          this.$t('m.额，好像没有权限'), {
          confirmButtonText: this.$t('m.知道啦'),
        });
        this.automatic = !type;
        return;
      }
      let payload = {
        token: sessionStorage.getItem("token"),
        templateId: this.currentTemplate.templateId,
        tradeSwitch: type ? 1 : 0,
      };
      updateTemplateTradeSwitch(payload).then((res) => {
        if (res.status == 200) {
          console.log(res.data);
          this.$message.success(this.$t('m.修改成功'));
          // this.updateTemplateNameShow = false;
          this.getTemplateList();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 移除策略模板
    deleteStrategyTemplate(id) {
      if (!this.detailsOperationAuthority) {
        // this.$message.error("不是模板创建人，无权操作");
        this.$alert(
          this.$t('m.加您没有权限使用该功能，敬请谅解！'), 
          this.$t('m.额，好像没有权限'), {
          confirmButtonText: this.$t('m.知道啦'),
        });
        return;
      }
      this.$confirm("确认移除模板？")
        .then((_) => {
          let payload = {
            token: sessionStorage.getItem("token"),
            templateId: this.currentTemplate.templateId,
          };
          console.log(payload);
          // done();
          deleteStrategyTemplate(payload).then((res) => {
            if (res.status == 200) {
              console.log(res.data);
              this.activeName = "0";
              this.$message.success(this.$t('m.操作成功'));
              this.currentTemplate = null;
              this.getTemplateList();
            } else {
              this.$message.error(res.msg);
            }
          });
        })
        .catch((_) => {});
    },
    // 单个终止
    confirmTermination(id) {
      let payload = {
        executeId: id,
        token: sessionStorage.getItem("token"),
      };
      console.log(payload);
      strategyEnd(payload).then((res) => {
        if (res.status == 200) {
          console.log(res.data);
          this.$message.success(this.$t('m.终止成功'));
          this.getTemplateList();
          // this.getStrategyPageByTemplateList(this.currentTemplate.templateId);
        } else {
          this.$message.error(res.msg);
        }
      });
    },

    // 批量暂停
    confirmSuspend() {
      if (this.number == 0) {
        this.$message.error(this.$t('m.当前模板没有执行策略'));
        return;
      }
      let payload = {
        token: sessionStorage.getItem("token"),
        teamProjectId: this.project.teamProjectId,
        templateId: this.currentTemplate.templateId,
      };
      batchSuspend(payload).then((res) => {
        if (res.status == 200) {
          console.log(res.data);
          this.$message.success(this.$t('m.暂停成功'));
          this.suspendShow = false;
          this.getStrategyPageByTemplateList(this.currentTemplate.templateId);
        } else {
          this.$message.error(res.msg);
        }
      });
    },

    // 批量重启
    confirmRestart() {
      if (this.number == 0) {
        this.$message.error(this.$t('m.当前模板没有执行策略'));
        return;
      }
      let payload = {
        token: sessionStorage.getItem("token"),
        teamProjectId: this.project.teamProjectId,
        templateId: this.currentTemplate.templateId,
      };
      batchRestart(payload).then((res) => {
        if (res.status == 200) {
          console.log(res.data);
          this.$message.success(this.$t('m.重启成功'));
          this.suspendShow = false;
          this.getStrategyPageByTemplateList(this.currentTemplate.templateId);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 修改参数弹窗
    modifyParam() {
      // if (this.number == 0) {
      //   this.$message.error("当前模板没有执行策略");
      //   return;
      // }
      if (!this.detailsOperationAuthority) {
        // this.$message.error("不是模板创建人，无权操作");
        this.$alert(
          this.$t('m.加您没有权限使用该功能，敬请谅解！'), 
          this.$t('m.额，好像没有权限'), {
          confirmButtonText: this.$t('m.知道啦'),
        });
        return;
      }
      this.modifyParametersShow = true;
      this.getTemplateMessage();
    },
    // 定投参数
    handleChange(value) {
      this.fixedTime = value;
    },
    // 输入校验
    inputCheck(e, item, index) {
      console.log(item, index);
      // if (this.strategyId == "CL20181115155643599x") {
      //   return;
      // }
      console.log(this.strategyId);
      let value = item.value;
      let min =
        item.valueMin && (item.valueMin != 0 || item.valueMax != 0)
          ? item.valueMin
          : item.start;
      let max =
        item.valueMax && (item.valueMin != 0 || item.valueMax != 0)
          ? item.valueMax
          : item.end;
      console.log(item, item.value, max, min);
      console.log(value === "");
      if (value === "") {
        this.$message.error(this.$t('m.参数不能为空'));
        e.target.style.border = "1px solid #F23A4C";
        if (this.parameterCheckList.indexOf(item.name) > -1) {
          return;
        } else {
          this.parameterCheckList.push(item.name);
        }
        return;
      } else if (min != "" && Number(value) < min) {
        this.$message.error(this.$t('m.参数不能小于最低范围'));
        e.target.style.border = "1px solid #F23A4C";
        console.log(4);
        if (this.parameterCheckList.indexOf(item.name) > -1) {
          return;
        } else {
          this.parameterCheckList.push(item.name);
        }
        return;
      } else if (max != "" && Number(value) > max) {
        this.$message.error(this.$t('m.参数不能大于最大范围'));
        e.target.style.border = "1px solid #F23A4C";
        console.log(5);
        if (this.parameterCheckList.indexOf(item.name) > -1) {
          return;
        } else {
          this.parameterCheckList.push(item.name);
        }
        return;
      } else if (
        (item.name == "价格上限" || item.name == "价格下限") &&
        Number(value) < 0
      ) {
        this.$message.error(this.$t('m.价格上限/价格下限不能小于0'));
        e.target.style.border = "1px solid #F23A4C";
        console.log(5);
        if (this.parameterCheckList.indexOf(item.name) > -1) {
          return;
        } else {
          this.parameterCheckList.push(item.name);
        }
        return;
      } else {
        e.target.style.border = "1px solid #DCDFE6";
        console.log(6);
        // this.params[index].value = value;
        if (this.parameterCheckList.indexOf(item.name) > -1) {
          this.parameterCheckList.splice(
            this.parameterCheckList.indexOf(item.name),
            1
          );
        }
      }
    },
    // 参数二次校验
    secondaryCheck(item) {
      for (let key in item) {
        let min =
          item[key].valueMin &&
          (item[key].valueMin != 0 || item[key].valueMax != 0)
            ? item[key].valueMin
            : item[key].start;
        let max =
          item[key].valueMax &&
          (item[key].valueMin != 0 || item[key].valueMax != 0)
            ? item[key].valueMax
            : item[key].end;
        let value = item[key].value;

        if (item[key].visiable == "1" && value === "") {
          console.log(item[key], value);
          if (this.parameterCheckList.indexOf(item.name) > -1) {
            return;
          } else {
            this.parameterCheckList.push(item.name);
          }
          return;
        } else if (
          item[key].visiable == "1" &&
          min != "" &&
          Number(value) < min
        ) {
          console.log(item[key]);
          if (this.parameterCheckList.indexOf(item.name) > -1) {
            return;
          } else {
            this.parameterCheckList.push(item.name);
          }
          return;
        } else if (
          item[key].visiable == "1" &&
          max != "" &&
          Number(value) > max
        ) {
          console.log(item[key]);
          if (this.parameterCheckList.indexOf(item.name) > -1) {
            return;
          } else {
            this.parameterCheckList.push(item.name);
          }
          return;
        } else if (
          (item[key].name == "价格上限" || item[key].name == "价格下限") &&
          Number(value) < 0
        ) {
          console.log(item[key]);
          if (this.parameterCheckList.indexOf(item.name) > -1) {
            return;
          } else {
            this.parameterCheckList.push(item.name);
          }
          return;
        } else {
          if (this.parameterCheckList.indexOf(item.name) > -1) {
            this.parameterCheckList.splice(
              this.parameterCheckList.indexOf(item.name),
              1
            );
          }
        }
      }
      if (this.strategyId == "CL20181115155643599x") {
        console.log(Number(item[0].value), Number(item[1].value));
        if (Number(item[0].value) > Number(item[1].value)) {
          this.$message.error(this.$t('m.买入价格不能低于卖出价格'));
          status = false;
          return;
        }
      }

    },
    // 批量修改参数
    confirmUpdateParam() {
      this.secondaryCheck(this.reserves);
      if (this.parameterCheckList.length !== 0) {
        this.$message.error(this.$t('m.参数不正确，请重新修改'));
        return;
      } else if (
        this.strategyId == "CL20181115155003478x" ||
        this.strategyId == "WL202007311426363421x"
      ) {
        let item = this.reserves;
        var min = null;
        var max = null;
        console.log(item);
        for (let key of item) {
          console.log(key);
          if (key.name == "价格上限") {
            max = key.value;
          } else if (key.name == "价格下限") {
            min = key.value;
          }
        }
        console.log(max, min);
        if (Number(max) < min) {
          this.$message.error(this.$t('m.价格上限不能低于价格下限'));
          if (this.parameterCheckList.indexOf(item.name) > -1) {
            return;
          } else {
            this.parameterCheckList.push(item.name);
          }
          return;
        }
      } else if (this.strategyId == "WL20190402171936974x") {
        this.reserves[0].value = this.fixedTime[1];
        this.reserves[1].value = this.fixedTime[0];
      }
      let payload = {
        teamProjectId: this.project.teamProjectId,
        token: sessionStorage.getItem("token"),
        templateId: this.currentTemplate.templateId,
        reserves: JSON.stringify(this.reserves),
      };
      console.log(this.reserves);
      console.log(payload);
      batchUpdateParam(payload).then((res) => {
        if (res.status == 200) {
          console.log(res.data);
          this.$message.success(this.$t('m.修改参数成功'));
          this.modifyParametersShow = false;
          this.getStrategyPageByTemplateList(this.currentTemplate.templateId);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 模板详情数据
    getTemplateMessage() {
      let payload = {
        token: sessionStorage.getItem("token"),
        templateId: this.currentTemplate.templateId,
      };
      getTemplateMessage(payload).then((res) => {
        if (res.status == 200) {
          this.reserves = JSON.parse(JSON.stringify(res.data.reservesList));
          this.reservesCopy = JSON.parse(JSON.stringify(res.data.reservesList));
          this.strategyId = res.data.strategyId;
          
          if (this.strategyId == "WL20190402171936974x") {
            for (let key in this.reserves) {
              if (this.reserves[key].name == "定投周期") {
                this.fixedTime[1] = this.reserves[key].value;
              } else if (this.reserves[key].name == "定投类型") {
                this.fixedTime[0] = this.reserves[key].value;
              }
              console.log(key);
            }
            console.log(this.fixedTime);
          }
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 批量终止
    batchStrategyEnd() {
      let payload = {
        token: sessionStorage.getItem("token"),
        teamProjectId: this.project.teamProjectId,
        templateId: this.currentTemplate.templateId,
      };
      batchStrategyEnd(payload).then((res) => {
        if (res.status == 200) {
          this.$message.success(this.$t('m.终止成功'));
          this.terminationShow = false;
          this.getTemplateList();
          // this.getStrategyPageByTemplateList(this.currentTemplate.templateId);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 查询加仓范围
    getLimitConfig() {
      let payload = {
        token: sessionStorage.getItem("token"),
      };
      getLimitConfig(payload).then((res) => {
        if (res.status == 200) {
          console.log(res.data);
          this.limitMap = res.data.limitMap;
          this.min = JSON.parse(JSON.stringify(this.limitMap.minInvest));
          this.max = JSON.parse(JSON.stringify(this.limitMap.maxInvest));
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 加仓
    scaleIn() {
      let payload = {
        token: sessionStorage.getItem("token"),
        teamProjectId: this.project.teamProjectId,
        templateId: this.currentTemplate.templateId,
      };
      if (this.increaseActive == "1") {
        if (!this.proportion || this.proportion == "") {
          this.$message.error(this.$t('m.投入比例不能为空'));
          return;
        } else if (this.proportion <= 0) {
          this.$message.error(this.$t('m.投入比例不能小于等于0%'));
          return;
        } else if (this.proportion > 100) {
          this.$message.error(this.$t('m.投入比例不能大于100%'));
          return;
        } else if (this.min < Number(this.limitMap.minInvest)) {
          this.$message.error(this.$t('m.加仓投入下限不能小于最小投入'));
          return;
        } else if (this.max > Number(this.limitMap.maxInvest)) {
          this.$message.error(this.$t('m.加仓投入上限限不能大于最大投入'));
          return;
        } else if (this.min > Number(this.max)) {
          this.$message.error(this.$t('m.加仓投入下限不能大于加仓投入上限'));
          return;
        }
        let investConfig = {
          proportionInvest: {
            proportion: this.proportion,
            maxInvest: this.max,
            minInvest: this.min,
          },
        };
        payload.investConfig = { ...investConfig };
      } else {
        if (!this.fixedInvestment || this.fixedInvestment == "") {
          this.$message.error(this.$t('m.投入金额不能为空'));
          return;
        } else if (this.fixedInvestment < 0) {
          this.$message.error(this.$t('m.投入金额不能小于0'));
          return;
        }
        let investConfig = {
          fixInvest: this.fixedInvestment,
        };
        payload.investConfig = { ...investConfig };
      }
      console.log(payload);

      batchAddStore(payload).then((res) => {
        if (res.status == 200) {
          console.log(res.data);
          this.$message.success(this.$t('m.操作成功'));
          this.getStrategyPageByTemplateList(this.currentTemplate.templateId);
          if (this.increaseActive == "1") {
            this.proportion = null;
            this.max = null;
            this.min = null;
          } else {
            this.fixedInvestment = null;
          }
          this.increaseShow = false;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 查询交易对
    getPairPage() {
      let payload = {
        token: sessionStorage.getItem("token"),
        teamProjectId: this.project.teamProjectId,
        exchangeId: this.currentTemplate.exchangeId,
      };
      getPairPageById(payload).then((res) => {
        if (res.status == 200) {
          console.log(res.data);
          let list = res.data.pairList;
          let pair = this.currentTemplate.pairs;
          if (pair.length != 0) {
            for (let i = 0; i < list.length; i++) {
              for (let j = 0; j < pair.length; j++) {
                if (
                  list[i].baseCoin == pair[j].baseCoin &&
                  list[i].counterCoin == pair[j].counterCoin
                ) {
                  list[i].type = true;
                }
              }
            }
            let newList = list.filter(function (item) {
              return item.type != true;
            });
            this.pairList = newList;
          } else {
            this.pairList = list;
          }

          this.addCurrencyPairShow = true;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 币对选中
    pairChange(item) {
      if (!item.type) {
        item.type = true;
      } else {
        item.type = false;
      }
      console.log(item);
    },

    // 增加币对
    confirmAddCurrencyPair() {
      let list = this.pairList;
      let pairs = [];
      for (let i = 0; i < list.length; i++) {
        if (list[i].type == true) {
          let pair = {};
          pair.baseCoin = list[i].baseCoin;
          pair.counterCoin = list[i].counterCoin;
          pairs.push(pair);
        }
      }
      if (pairs.length == 0) {
        this.$message.error(this.$t('m.没有选择币对'));
        return;
      }
      let payload = {
        token: sessionStorage.getItem("token"),
        teamProjectId: this.project.teamProjectId,
        templateId: this.currentTemplate.templateId,
        pairs: JSON.stringify(pairs),
      };

      batchManyAddSymbol(payload).then((res) => {
        if (res.status == 200) {
          console.log(res.data);
          this.$message.success(this.$t('m.增加币对成功'));
          this.getTemplateList();
          this.addCurrencyPairShow = false;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 修改模板投入范围
    ModifyTemplate() {
      if (!this.detailsOperationAuthority) {
        // this.$message.error("不是模板创建人，无权操作");
        this.$alert(
          this.$t('m.加您没有权限使用该功能，敬请谅解！'), 
          this.$t('m.额，好像没有权限'), {
          confirmButtonText: this.$t('m.知道啦'),
        });
        return;
      }
      this.getLimitConfig();
      let config = JSON.parse(this.currentTemplate.investConfig);
      this.investConfig = config;
      this.ModifyTemplateInvestmentShow = true;
      console.log(this.investConfig);
    },
    //确定修改
    confirmModifyTemplate() {
      console.log(this.investConfig, this.templateActive);
      if (this.templateActive == "1") {
        this.investConfig.fixInvest = null;
        let proportion = this.investConfig.proportionInvest.proportion;
        let min = this.investConfig.proportionInvest.minInvest;
        let max = this.investConfig.proportionInvest.maxInvest;
        if (proportion == null || proportion == "") {
          this.$message.error(this.$t('m.请输入投入比例'));
          return;
        } else if (proportion <= 0) {
          this.$message.error(this.$t('m.投入比例不能小于等于0%'));
          return;
        } else if (proportion > 100) {
          this.$message.error(this.$t('m.投入比例不能大于100%'));
          return;
        } else if (min < Number(this.limitMap.minInvest)) {
          this.$message.error(this.$t('m.加仓投入下限不能小于最小投入'));
          return;
        } else if (max > Number(this.limitMap.maxInvest)) {
          this.$message.error(this.$t('m.加仓投入上限限不能大于最大投入'));
          return;
        } else if (min > Number(max)) {
          this.$message.error(this.$t('m.加仓投入下限不能大于加仓投入上限'));
          return;
        }
      } else {
        this.investConfig.proportionInvest.proportion = null;
        let fixInvest = this.investConfig.fixInvest;
        if (fixInvest == null) {
          this.$message.error(this.$t('m.请输入投入金额'));
          return;
        } else if (fixInvest < Number(this.limitMap.minInvest)) {
          this.$message.error(this.$t('m.投入金额不能小于最小投入'));
          return;
        } else if (fixInvest > Number(this.limitMap.maxInvest)) {
          this.$message.error(this.$t('m.投入金额不能大于最大投入'));
          return;
        }
      }
      let payload = {
        token: sessionStorage.getItem("token"),
        templateId: this.currentTemplate.templateId,
        investConfig: this.investConfig,
      };

      updateTemplateInvestCoinnNum(payload).then((res) => {
        if (res.status == 200) {
          console.log(res.data);
          this.$message.success(this.$t('m.修改成功'));
          this.ModifyTemplateInvestmentShow = false;
          this.getTemplateList();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 策略状态筛选
    typeSelest(v) {
      console.log(v);
      let list = JSON.parse(JSON.stringify(this.strategyListCopy));
      let newList = [];
      switch (v) {
        case "全部":
          console.log(1);
          this.strategyList = JSON.parse(JSON.stringify(this.strategyListCopy));
          break;
        case "正常状态":
          console.log(2);
          for (let i = 0; i < list.length; i++) {
            if (list[i].errorInfo == false) {
              newList.push(list[i]);
            }
          }
          this.strategyList = newList;
          break;
        case "异常状态":
          console.log(3);
          for (let i = 0; i < list.length; i++) {
            if (list[i].errorInfo == true) {
              newList.push(list[i]);
            }
          }
          this.strategyList = newList;
          break;
        default:
          break;
      }
      console.log(this.strategyList);
    },

    // 删除币对
    confirmDetailPair() {
      let list = this.detailPairList;
      let pairs = [];
      for (let i = 0; i < list.length; i++) {
        if (list[i].type) {
          let item = {};
          item.baseCoin = list[i].baseCoin;
          item.counterCoin = list[i].counterCoin;
          pairs.push(item);
        }
      }
      if (pairs.length == 0) {
        this.$message.error(this.$t('m.请选择交易对'));
        return;
      } else if (pairs.length == this.detailPairList.length) {
        this.$message.error(this.$t('m.至少保留一个交易对'));
        return;
      }
      let payload = {
        token: sessionStorage.getItem("token"),
        templateId: this.currentTemplate.templateId,
        pairs: JSON.stringify(pairs),
      };
      deletePair(payload).then((res) => {
        if (res.status == 200) {
          console.log(res.data);
          this.$message.success(this.$t('m.操作成功'));
          this.detailPairShow = false;
          this.getTemplateList();
        } else {
          this.$message.error(res.msg);
        }
      });
    },

    // 获取排序模板
    getTemplateSortList() {
      let payload = {
        userId: this.userId,
        token: sessionStorage.getItem("token"),
        teamProjectId: this.project.teamProjectId,
      };
      getTemplateSortList(payload).then((res) => {
        if (res.status == 200) {
          
          let sortTemplateList = res.data.templateList;
          for (let key of sortTemplateList) {
            key.tradeSwitch = JSON.parse(key.tradeSwitch);
          }
          this.sortTemplateList = sortTemplateList;
          console.log(this.sortTemplateList);
          this.dragSort = true;
        }
      });
    },

    // 修改模板排序
    sortConfirm() {
      let list = this.sortTemplateList;
      let payload = {
        token: sessionStorage.getItem("token"),
        templateSortList: [],
      };
      for (var i = 0; i < list.length; i++) {
        let obj = {};
        obj.templateId = list[i].templateId;
        obj.tradeSwitch = (list[i].tradeSwitch ? 1 : 0) + "";
        obj.sort = i + 1;
        payload.templateSortList.push(obj);
      }

      console.log(this.sortTemplateList);
      console.log(payload);
      updateTemplateSort(payload).then((res) => {
        if (res.status == 200) {
          this.dragSort = false;
          this.currentTemplate = null;
          this.getTemplateList();
          this.activeName = "0";
          // this.getStrategyPageByTemplateList(this.currentTemplate.templateId)
          console.log(res.data);
        }
      });
    },
    // 分页
    handleSizeChange(val) {
      this.pageSize = val;
      this.getStrategyPageByTemplateList(this.currentTemplate.templateId);
    },
    handleCurrentChange(val) {
      this.pageNo = val;
      this.getStrategyPageByTemplateList(this.currentTemplate.templateId);
    },
  },
  created() {},
  mounted() {
    this.$dragging.$on("dragged", (value) => {
      //排序的时候不需要做处理，所以空着就行
      console.log(value);
      console.log(this.aa);
    });

    let project = sessionStorage.getItem("project");
    let account = JSON.parse(sessionStorage.getItem("account"));
    this.project = JSON.parse(project);
    let detailsOperationAuthority = sessionStorage.getItem(
      "detailsOperationAuthority"
    );
    this.detailsOperationAuthority = JSON.parse(detailsOperationAuthority);
    console.log(this.detailsOperationAuthority);
    let userId = this.$route.query.userId;
    if (userId) {
      this.userId = userId;
      this.getTemplateList();
    } else {
      setTimeout(() => {
        this.userId = sessionStorage.getItem("userId");
        this.userId == account.userId
          ? (this.detailsOperationAuthority = true)
          : (this.detailsOperationAuthority = false);
        this.getTemplateList();
      }, 300);
    }
  },
  beforeCreate() {},
  beforeMount() {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {},
  activated() {},
  deactivated() {
    sessionStorage.setItem("active", 3);
  },
};
</script>




<style lang='scss' scoped>
.strategy {
  // background: #fff;
  position: relative;
  .create-policy-template {
    position: absolute;
    top: 7px;
    right: 20px;
    color: #fff;
    border-radius: 4px;
    cursor: pointer;
    z-index: 2000;
    display: flex;
    align-items: center;
    // height: 39px;
    .template-btn {
      width: 150px;
      height: 30px;
      text-align: center;
      line-height: 30px;
      border-radius: 4px;
      background: #007aff;
    }
    .sort-btn {
      width: 80px;
      height: 30px;
      text-align: center;
      line-height: 30px;
      margin: 0 32px 0 16px;
      border-radius: 4px;
      background: #007aff;
    }
  }
  ::v-deep.el-tabs__content {
    padding: 20px !important;
  }
  ::v-deep.el-tabs__header {
    width: 79%;
    padding: 0 20px;
    background: #fff;
  }
  .lable-error {
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #f56c6c;
    margin-bottom: 6px;
  }

  .content {
    padding: 0 20px;
    color: #333;
    font-size: 14px;
    min-height: 500px;
    // overflow: scroll;
    .content-top {
      .content-top-operation {
        display: flex;
        justify-content: space-between;
        align-items: center;
        // padding: 0 40px;
        div {
          display: flex;
          align-items: center;
          width: 33%;
        }
        .one {
          // width: 30%;
        }
        .addAcc {
          margin-left: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #fff;
          background: #007aff;
          border-radius: 5px;
          padding: 5px 10px;
          cursor: pointer;
        }
        .operation-btn {
          // width: 20%;
          color: #007aff;
          font-size: 12px;
          span {
            margin-right: 20px;
            cursor: pointer;
          }
        }
        .templateOperation-warp {
          // width: 20%;
          display: flex;
          color: #007aff;
          font-size: 12px;

          span {
            margin-right: 20px;
            cursor: pointer;
          }
        }
        .operation-switch {
          width: 200px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          // flex-direction: column;
          .el-switch {
            // width: 100%;
            display: flex;
            justify-content: space-between;
            // margin-bottom: 6px;
            font-size: 14px;
            color: #000;
          }
          .operation-switch--title {
            width: 60px;
            color: #000000;
          }
          img {
            width: 15px;
            height: 15px;
            border-radius: 50%;
            margin-left: 2px;
            margin-right: 40px;
            cursor: pointer;
          }
        }
      }
      .operation-search {
        margin: 25px 0 40px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        // padding: 0 40px;
        ::v-deep.el-input__inner {
          height: 30px;
          line-height: 30px;
        }
        ::v-deep.el-input__icon {
          line-height: 30px;
        }
        .one {
          width: 35%;
        }
        .templateOperation {
          width: 35%;
          font-size: 12px;
          // margin-left: 200px;
          // span {
          //   margin-right: 20px;
          //   color: #007aff;
          //   cursor: pointer;
          // }
        }
        .last {
          width: 25%;
        }
      }
    }
    .content-bottom {
      .content-bottom-title {
        padding-right: 20px;
        font-size: 12px;
        color: #999;
        margin-bottom: 10px;
        span {
          margin-right: 40px;
        }
      }
      .pagination-warp {
        width: 100%;
        text-align: right;
        margin-top: 20px;
      }
      .deposit-operation {
        // display: flex;
        // flex-wrap: wrap;
        span {
          // width: 50px;
          font-size: 12px;
          color: #007aff;
          cursor: pointer;
        }

        .detail {
          margin: 0 20px;
        }
        .message {
          display: inline-block;
          .message-error {
            width: 8px;
            height: 8px;
            border-radius: 50%;
            display: inline-block;
            background: #f56c6c;
            margin-bottom: 6px;
          }
        }
      }
      .box-pagination {
        margin-top: 30px;
        text-align: right;
      }
    }
  }

  // 日志
  .log {
    padding: 30px;
    background: #fff;
    margin-top: 20px;
    .log-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 14px;
      color: #333;
      .error-info {
        width: 8px;
        height: 8px;
        display: inline-block;
        border-radius: 50%;
        background: #f56c6c;
        margin-bottom: 6px;
      }
      .more {
        color: #007aff;
        font-size: 12px;
        cursor: pointer;
        .error-info {
          width: 8px;
          height: 8px;
          display: inline-block;
          border-radius: 50%;
          background: #f56c6c;
          margin-bottom: 6px;
        }
      }
    }
    .log-box {
      color: #666;
      font-size: 14px;
      line-height: 20px;
      span {
        display: inline-block;
        margin-right: 10px;
        padding: 10px 0;
      }
    }
    .logdefault {
      font-size: 14px;
      color: #999;
      text-align: center;
      // margin-top: 20px;
    }
  }

  // 缺省页
  .defaultPage {
    width: 100%;
    height: 600px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #313237;
    font-size: 14px;
    background: #fff;
    margin-top: 10px;
    border-radius: 8px;
    .img1 {
      width: 228px;
      height: 160px;
      margin-bottom: 10px;
    }
    .create-template {
      color: #007aff;
      cursor: pointer;
    }
    .img2 {
      width: 1356px;
      height: 237px;
      margin-top: 60px;
    }
  }
  .no-log {
    margin-top: 16px;
    height: 80px;
    display: flex;
    justify-content: space-between;
    border-radius: 8px;
    background: #fff;
    padding: 20px 20px 0 20px;
    :last-child {
      color: #007aff;
    }
  }
  // 终止/暂停弹窗
  .termination,
  .suspend {
    font-size: 12px;
    margin: 0 24px;
    color: #333;
    line-height: 20px;
    .title {
      font-size: 14px;
      text-align: center;
      font-weight: 600;
    }
    .text {
      font-size: 12px;
      margin: 20px 0;
    }
    .tips {
      color: #999;
    }
  }
  // 加仓
  .increase {
    .title {
      font-size: 14px;
      color: #333;
      :last-child {
        font-size: 12px;
        color: #999;
        margin-left: 5px;
      }
    }
    .text {
      .box {
        margin-bottom: 10px;
        .box-title {
          display: inline-block;
          font-size: 10px;
          color: #666;
          margin-bottom: 4px;
        }
      }
    }
    .tips {
      font-size: 12px;
      color: #999;
      line-height: 18px;
      margin-top: 10px;
    }
  }
  // 修改参数
  .modifyParameters {
    // .box {
    //   margin-bottom: 10px;
    //   .box-title {
    //     display: inline-block;
    //     font-size: 14px;
    //     color: #333;
    //     margin-bottom: 4px;
    //     :last-child {
    //       font-size: 12px;
    //       color: #666;
    //       margin-left: 4px;
    //     }
    //   }

    // }
    .box {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      .parameter-box-label {
        width: 25%;
        display: flex;
        flex-direction: column;
        :nth-child(2) {
          color: #999;
        }
      }
      .block {
        height: 30px;
        line-height: 30px;
      }
      .parameter-box-text {
        width: 75%;
        .el-cascader {
          width: 100%;
        }
        input {
          width: 100%;
          height: 30px;
          line-height: 30px;
          border-radius: 4px;
          border: 1px solid #dcdfe6;
          text-indent: 10px;
          color: #666;
          // outline-color:#409EFF;
          // outline-width:0.5px;
          outline: none;
        }
      }
    }
  }
  // 修改参数确认
  .btn {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #007aff;
    color: #fff;
    font-size: 16px;
    border-radius: 4px;
    cursor: pointer;
  }

  // 增加币对
  .addCurrencyPair {
    height: 400px;
    overflow: scroll;
    .title {
      font-size: 12px;
      color: #999;
      margin-bottom: 10px;
    }
    .checkbox-warp {
      margin-bottom: 10px;
      padding: 5px;
      .box {
        .box-text {
          margin-left: 30px;
          font-size: 14px;
          color: #666;
          :last-child {
            font-size: 12px;
            color: #999;
          }
        }
      }
    }

    .checkbox-warp:hover {
      background: #d5d5d5;
      border-radius: 4px;
    }
  }
  //模板排序
  .templateSort {
    padding: 0 20px 20px 20px;
    // ::v-deep.el-dialog__body{
    //   padding-top:10px !important;
    // }
    .title {
      text-align: center;
      margin-bottom: 30px;
      color: #8f9096;
    }
    .templateSort-content {
      .content-title {
        display: flex;
        justify-content: space-between;
        padding-bottom: 10px;
        border-bottom: 1px solid #dcdde3;
        color: #8f9096;
        :first-child {
          width: 50%;
        }
      }
      .content-box {
        display: flex;
        justify-content: space-between;
        padding: 10px 0;
        color: #313237;
        cursor: pointer;
        :first-child {
          width: 50%;
        }
        img {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
}
</style>