<template>
  <div class="SignalInfo">
    <el-container v-if="data">
      <el-header height="70px">
        <i class="el-icon-back" @click="backClick"></i>
        <span>{{ data.signalName }}</span>
      </el-header>

      <div class="bg">
        <div class="container">
          <div class="info">
            <div class="column">
              <span class="title">{{ $t('m.创建者') }}</span>
              <span>{{ data.nickName }}</span>
            </div>
            <div class="column">
              <span class="title">{{ $t('m.平台') }}</span>
              <span>{{ data.platName }}</span>
            </div>
            <div class="column">
              <span class="title">{{ $t('m.有效期') }}</span>
              <span>{{ data.expireTime }}</span>
            </div>
            <div class="all"></div>

            <span class="delect" @click="delectClick">{{ $t('m.删除信号') }}</span>
          </div>

          <div class="line"></div>
          <el-tabs v-model="tab" @tab-click="tabClick">
            <el-tab-pane
              v-for="(item, idx) in tabs"
              :key="'tab' + idx"
              :label="item"
              :name="item"
            ></el-tab-pane>
          </el-tabs>

          <template v-if="tab == $t('m.基本信息')">
            <div class="attrs">
              <div
                class="column"
                v-for="(item, idx) in data.paramBody"
                :key="'attr' + idx"
              >
                <div class="row">
                  <span>{{ $t('m.属性') + ' ' + (idx + 1) }}</span>
                  <div class="width10"></div>
                  <span class="title">{{ $t('m.注释') + " - " + item.note }}</span>
                </div>
                <div class="row">
                  <span>{{ item.name }}</span>
                  <img
                    class="icon-arrow"
                    src="@/assets/icon/jiantou.png"
                    alt=""
                  />
                  <span>{{ item.label }}</span>
                  <div class="line"></div>
                  <span>{{ item.value }}</span>
                </div>
              </div>
            </div>

            <div class="url">
              <span>{{ "Webhook URL" }}</span>
              <div class="row">
                <el-input v-model="data.webhookUrl" :disabled="true"></el-input>
                <span class="copy" @click="copyClick(data.webhookUrl)">
                  {{$t('m.复制')}}</span>
              </div>
            </div>

            <div class="message">
              <span>{{ "Message" }}</span>
              <div class="row">
                <el-input
                  v-model="data.message"
                  type="textarea"
                  :autosize="{ minRows: 6, maxRows: 1000 }"
                  :disabled="true"
                  resize="none"
                ></el-input>
                <span class="copy" @click="copyClick(data.message)">
                  {{$t('m.复制')}}</span>
              </div>
            </div>
          </template>
          <div class="recode" v-else>
            <el-table :data="datas">
              <el-table-column
                prop="id"
                label="#"
                width="180"
              ></el-table-column>
              <el-table-column prop="createTime" :label="$t('m.接收时间')" width="180">
                <template slot-scope="scope">
                  {{
                    moment(scope.row.createTime).format("YYYY-MM-DD HH:mm:ss")
                  }}
                </template>
              </el-table-column>
              <el-table-column
                prop="content"
                :label="$t('m.消息内容')"
              ></el-table-column>
            </el-table>
          </div>

          <div v-if="tab != $t('m.基本信息')" class="pagination-warp">
            <el-pagination
              @current-change="pageClick"
              :current-page="page"
              :page-size="20"
              layout="total, prev, pager, next, jumper"
              :total="totalNum"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </el-container>
  </div>
</template>

<script>
import moment from "moment";
import { signalDetail, signalRecode, deleteSignal } from "../../request/api";
export default {
  components: {},

  data() {
    return {
      moment,
      signalId: "", //信号Id

      tabs: [this.$t('m.基本信息'), this.$t('m.信号记录')],
      tab: this.$t('m.基本信息'),

      data: undefined, //属性

      page: 1, //当前页码
      totalNum: 0, //总数
      datas: [], //记录
    };
  },

  computed: {},

  watch: {},

  mounted() {
    this.signalId = this.$route.query.signalId;
    this.httpSignalDetail();
  },

  methods: {
    initData(data) {
      data.paramBody = JSON.parse(data.paramBody);
      return data;
    },

    /************************ https*/
    //信号列表
    httpSignalDetail() {
      var param = {
        token: sessionStorage.getItem("token"),
        signalId: this.signalId,
      };
      signalDetail(param).then((data) => {
        if (data.status == 200) {
          console.log(data);
          data = data.data;
          this.data = this.initData(data);
        } else {
          this.$message.error(data.msg);
        }
      });
    },

    //信号记录
    httpSignalRecode() {
      var param = {
        token: sessionStorage.getItem("token"),
        signalId: this.signalId,
        pageNo: this.page,
      };
      signalRecode(param).then((res) => {
        if (res.status == 200) {
          console.log(res.data);

          this.totalNum = res.data.totalNum;
          this.datas = res.data.recodeList;
        } else {
          this.$message.error(res.msg);
        }
      });
    },

    //删除信号
    httpDeleteSignal() {
      var param = {
        token: sessionStorage.getItem("token"),
        signalId: this.signalId,
      };
      deleteSignal(param).then((data) => {
        if (data.status == 200) {
          console.log(JSON.stringify(data));
          this.$message({
            showClose: true,
            message: this.$t('m.删除成功'),
            type: "success",
          });
          this.$router.back(-1);
        } else {
          this.$message.error(data.msg);
        }
      });
    },

    /************************ 点击*/
    //返回上一页
    backClick() {
      sessionStorage.setItem("projectActive", "6");
      this.$router.push({ path: "project" });
    },

    //菜单点击
    tabClick() {
      if (this.tab == this.$t('m.基本信息') && this.data == undefined) {
        this.httpSignalDetail();
      }
      if (this.tab == this.$t('m.信号记录') && this.datas.length == 0) {
        this.httpSignalRecode();
      }
    },

    //复制
    copyClick(text) {
      var textarea = document.createElement("input"); //创建input对象
      var currentFocus = document.activeElement; //当前获得焦点的元素
      document.body.appendChild(textarea); //添加元素
      textarea.value = text;
      textarea.focus();
      if (textarea.setSelectionRange) {
        textarea.setSelectionRange(0, textarea.value.length); //获取光标起始位置到结束位置
      } else {
        textarea.select();
      }
      var flag;
      try {
        flag = document.execCommand("copy"); //执行复制
      } catch (eo) {
        flag = false;
      }
      document.body.removeChild(textarea); //删除元素
      currentFocus.focus();
      this.$message.success(flag ? this.$t('m.复制成功') : this.$t('m.复制失败'));
    },

    //删除
    delectClick() {
      this.$confirm($t('m.确认删除此信号？'))
        .then((_) => {
          this.httpDeleteSignal();
        })
        .catch((_) => {});
    },

    //页码切换
    pageClick(val) {
      this.page = val;
      this.httpSignalRecode();
    },
  },
  deactivated() {
    this.$destroy();
  },
};
</script>

<style lang='scss' scoped>
.SignalInfo * {
  box-sizing: border-box;
}
.bg * {
  display: flex;
}

.SignalInfo {
  width: 100%;
  height: 100%;
  .el-container {
    height: 100%;
    font-size: 16px;
    font-weight: 500;
    color: #102859;
    .el-header {
      position: fixed;
      display: flex;
      align-items: center;
      width: 100%;
      background-color: #32333d;
      padding-left: 60px;
      color: #ffffff;
      z-index: 10;
      .el-icon-back {
        cursor: pointer;
        margin-right: 10px;
      }
    }

    .el-tabs {
      display: block;
      height: 40px;
      margin-top: -10px;
      ::v-deep.el-tabs__item {
        padding: 0;
        min-width: 90px;
        color: #55698a;
        text-align: center;
      }
      ::v-deep.el-tabs__item.is-active {
        color: #2174ff;
      }
      ::v-deep.el-tabs__nav-wrap::after {
        background-color: #e6e9ee;
      }
      ::v-deep.el-tabs__active-bar {
        background-color: #2174ff;
      }
    }

    .bg {
      flex: 1;
      min-width: 1200px;
      padding: 90px 0 20px 0;
      background-color: #f7f7f7;
      .container {
        flex-direction: column;
        height: 100%;
        max-width: 1400px;
        border-radius: 8px;
        padding: 40px 40px 10px 40px;
        margin: 0 auto;
        background-color: #ffffff;

        .info {
          align-items: center;
          .column {
            flex-direction: column;
            width: 300px;
            .title {
              font-weight: 400;
              color: #54698a;
            }
          }
          .all {
            flex: 1;
          }
          .delect {
            cursor: pointer;
            align-items: center;
            height: 34px;
            border-radius: 4px;
            border: 1px solid #e12525;
            padding: 0 20px 0 20px;
            font-size: 14px;
            color: #e12525;
          }
          .el-button {
            cursor: pointer;
            align-items: center;
            height: 34px;
            border-radius: 4px;
            border: 1px solid #e12525;
            padding: 0 20px 0 20px;
            font-size: 14px;
            color: #e12525;
          }
        }
        .line {
          height: 1px;
          background-color: #e6e9ee;
          margin-top: 30px;
          margin-bottom: 30px;
        }

        .attrs {
          flex-direction: column;
          margin-bottom: 10px;
          margin-top: 20px;
          .title {
            font-size: 14px;
            font-weight: 400;
            color: #54698a;
          }
          .column {
            flex-direction: column;
            margin-bottom: 20px;
          }
          .row {
            align-items: center;
          }
          .icon-arrow {
            width: 24px;
            height: 10px;
            margin-left: 10px;
            margin-right: 10px;
            // background-color: #e12525;
          }
          .line {
            height: 10px;
            width: 1px;
            background-color: #e6e9ee;
            margin-left: 10px;
            margin-right: 10px;
          }
          .width10 {
            width: 10px;
          }
        }

        .url {
          flex-direction: column;
          margin-bottom: 30px;
          .row {
            align-items: center;
            margin-top: 10px;
            .el-input {
              width: 654px;
              border-radius: 4px;
              border: none;
              ::v-deep.el-input__inner {
                height: 42px;
                border: none;
                background-color: #f7f7f7;
                color: #102859;
                padding: 0 10px;
              }
            }
          }
        }

        .message {
          flex-direction: column;
          margin-bottom: 30px;
          .row {
            align-items: center;
            margin-top: 10px;
            .el-textarea {
              width: 654px;
              border-radius: 4px;
              border: none;
              ::v-deep.el-textarea__inner {
                background-color: #f7f7f7;
                color: #102859;
                border: none;
                padding: 10px;
              }
            }
            .width1200 {
              width: 1200px;
            }
          }
        }

        .recode {
          flex-direction: column;
          max-height: 625px;

          // overflow-y: scroll;
          background-color: #e12525;
          ::v-deep.el-table {
            // height: 500px;
            display: block;
            overflow: scroll;
          }
        }

        .copy {
          cursor: pointer;
          color: #2174ff;
          margin-left: 20px;
        }

        .pagination-warp {
          display: flex;
          justify-content: center;
          position: fixed;
          left: 33%;
          bottom: 30px;
        }
      }
    }
  }
}
</style>