<template>
  <div class="RunInfo">
    <el-container>

      <el-header height="70px">
        <i class="el-icon-back" @click="backClick"></i>
        <span v-if="executeDetail">{{
          $t('m.'+executeDetail.strategyName) + "-" + executeDetail.code
        }}</span>
      </el-header>

      <div class="bg">
        <div class="container" v-if="executeDetail">
          <div class="header">
            <div class="lables">
              <span class="lable">{{
                $t('m.'+executeDetail.strategyName) + "  ·" +
                (strategyType == "spot" ? $t('m.币币') : $t('m.合约'))}}</span>

              <span
                class="lable"
                v-if="executeDetail.executeType != 'FATHER'">
                {{
                  executeDetail.exchangeName +
                  " - " +
                  tradePairs.counterCoin +
                  "/" +
                  tradePairs.baseCoin
                }}</span>
              <span
                class="lable"
                v-if="executeDetail.executeType == 'FATHER'">
                {{ executeDetail.exchangeName + "·" + $t('m.多币対')}}</span>

              <span
                class="lable"
                v-if="strategyType != 'spot' && tradePairs.investType == '1'">
                {{ $t('m.做多') + "-" + tradePairs.leverRate + "X  " }}</span>

              <span
                class="lable"
                v-if="strategyType != 'spot' && tradePairs.investType == '0'">
                {{ $t('m.做空') + "-" + tradePairs.leverRate + "X  " }}</span>

              <span
                class="lable"
                v-if="strategyType != 'spot' && tradePairs.investType == '2'">
                {{ $t('m.双向') + "-" + tradePairs.leverRate + "X  " }}</span>

              <span class="lable">
                {{$t('m.投入币种：') + tradePairs.investCoin}}</span>
            </div>

            <div class="buts">
              <div
                class="but"
                @click="copyStrategy()">
                {{$t('m.复制交易')}}</div>

              <div class="line"></div>
              <div
                class="but"
                @click="endClick(executeId)"
                v-if="executeDetail.states == 'EXECUTE'">
                {{$t('m.终止')}}</div>

              <div
                class="but"
                @click="stopClick(executeId)"
                v-if="
                  executeDetail.states == 'EXECUTE' && strategyType == 'spot'">
                {{$t('m.暂停')}}</div>

              <div
                class="but"
                @click="restartClcik(executeId)"
                v-if="
                  executeDetail.states == 'SUSPEND' && strategyType == 'spot'">
                {{$t('m.重启')}}</div>

              <div class="but" @click="addClick('加仓')">
                {{$t('m.加仓') }}</div>

              <div
                class="but"
                @click="addClick('减仓')"
                v-if="strategyType != 'spot'">
                {{$t('m.减仓') }}</div>

              <div class="line"></div>
              <div
                class="but"
                @click="addSubCurrencyPair(executeId)"
                v-if="executeDetail.executeType == 'FATHER'">
                {{$t('m.增加子币对')}}</div>

              <div class="but" @click="noticeClick(executeId)">
                {{$t('m.消息')}}</div>

              <div class="but" @click="modifyParametersShow = true">
                {{$t('m.参数')}} </div>

              <div class="line"></div>
              <div class="but" @click="orderClcik(executeId)">
                {{$t('m.委托订单')}}</div>

              <div class="but" @click="tradeClick(executeId)">
                {{$t('m.成交记录')}}</div>
            </div>
          </div>

          <!-- 策略信息 -->
          <div class="line"></div>
          <div class="info">
            <span>{{$t('m.策略信息：')}}</span>

            <div class="datas1">
              <div class="data">
                <span class="title">
                  {{$t('m.启动时间')}}</span>
                <span>{{moment(executeDetail.startTime).format("YYYY-MM-DD HH:mm:ss")}}</span>
              </div>

              <div class="data">
                <span class="title">
                  {{$t('m.执行时长')}}</span>

                <span>{{formatDuring(executeDetail.startTime, executeDetail.endTime)}}</span>
              </div>

              <div class="data">
                <span class="title">
                  {{$t('m.终止时间')}}</span>
                <span>{{
                  executeDetail.endTime
                    ? moment(executeDetail.endTime).format("YYYY-MM-DD HH:mm:ss") 
                    : "--"}}</span>
              </div>

              <div class="data">
                <span class="title">
                  {{ $t('m.账户')}}</span>
                <span>{{
                  executeDetail.remarkCode
                    ? executeDetail.remarkCode : executeDetail.publicCode ? executeDetail.publicCode
                    : "--"}}</span>
              </div>

              <div class="data">
                <span class="title">
                  {{$t('m.总投入')+"(" + tradePairs.investCoin + ")"}}</span>
                <span>{{ tradePairs.investCoinnNum }}</span>
              </div>

              <div class="data">
                <span class="title">
                  {{$t('m.总收益')+"(" + tradePairs.investCoin + ")"}}</span>

                <span
                  :class="{
                    red: Number(executeDetail.realEarnings) > 0, 
                    green: Number(executeDetail.realEarnings) < 0}">
                  {{Math.floor(Number(executeDetail.realEarnings) * 10000) / 10000}}</span>
              </div>

              <div class="data">
                <span class="title">
                  {{$t('m.浮动收益')+"(" + tradePairs.investCoin + ")"}}</span>

                <span
                  :class="{
                    red: Number(executeDetail.floatEarnings) > 0,
                    green: Number(executeDetail.floatEarnings) < 0}">
                  {{Math.floor(Number(executeDetail.floatEarnings) * 10000) / 10000}}</span>
              </div>

              <div class="data">
                <span class="title">
                  {{$t('m.总年化')}}</span>
                <span
                  :class="{
                    red: Number(executeDetail.realYearIncome) > 0,
                    green: Number(executeDetail.realYearIncome) < 0}">
                  {{ executeDetail.realYearIncome + " %" }}</span>
              </div>

              <div class="data">
                <span class="title" v-if="strategyType == 'spot'">
                  {{$t('m.交易数 / 买入 / 卖出')}}</span>

                <span class="title" v-if="strategyType != 'spot'">
                  {{$t('m.交易数 / 开仓 / 平仓')}}</span>

                <span>{{
                  ((executeDetail.finishBuyCount ? Number(executeDetail.finishBuyCount) : 0) +

                   (executeDetail.finishSellCount? Number(executeDetail.finishSellCount) : 0) > 0 
                   ? (executeDetail.finishBuyCount ? Number(executeDetail.finishBuyCount) : 0)  + 
                     (executeDetail.finishSellCount ? Number(executeDetail.finishSellCount) : 0)
                   : "--") +

                  " / " +
                  (executeDetail.finishBuyCount ? executeDetail.finishBuyCount : "--") +
                  " / " +
                  (executeDetail.finishSellCount ? executeDetail.finishSellCount : "--")}}</span>

              </div>
              <div class="data">
                <span class="title">
                  {{ $t('m.状态')}}</span>

                <span class="text-lan" v-if="executeDetail.states == 'EXECUTE'">
                  {{$t('m.执行中')}}</span>

                <span class="text-lan" v-if="executeDetail.states == 'SUSPEND'">
                  {{$t('m.已暂停')}}</span>

                <span class="text-lan" v-if="executeDetail.states == 'STARTING'">
                  {{$t('m.启动中')}}</span>

                <span class="text-lan" v-if="executeDetail.states == 'STOPING'">
                  {{$t('m.终止中')}}</span>

                <span
                  class="text-lan"
                  v-if="executeDetail.states == 'restarting'">
                  {{$t('m.重启中')}}</span>
              </div>
            </div>
          </div>

          <!-- 单币对 -->
          <div class="line"></div>

          <div
            class="info"
            v-if="
              singleExecute &&
              singleExecute.length != 0 &&
              strategyType == 'spot'">
            <span>{{$t('m.持仓：')}}</span>

            <div class="datas2">
              <div class="position-title">
                <div class="warp">
                  <span>{{$t('m.币种')}}</span>
                  <span>{{$t('m.数量')}}</span>
                  <span>{{$t('m.浮动收益')+" " + tradePairs.investCoin }}</span>
                  <el-tooltip
                    class="tooltipItem"
                    effect="dark"
                    :content="$t('m.已包含手续费成本')"
                    placement="top">
                    <span
                      style="border-bottom: 1px dashed #333; cursor: pointer">
                      {{$t('m.开仓均价')+ tradePairs.baseCoin }}</span>
                  </el-tooltip>
                </div>
              </div>

              <div class="position-box" v-for="(item, idx) in singleExecute" :key="'singleExecute'+idx">
                <div class="left">
                  <span>{{ item.symbol }}</span>

                  <span>{{ Number(item.amount) }}</span>
                  <span
                    :class="{
                      red: Number(item.floatEarnings) > 0,
                      green: Number(item.floatEarnings) < 0,
                    }">
                    {{ item.floatEarnings ? item.floatEarnings : "--" }}</span>
                  <span>{{ item.costPrice ? item.costPrice : "--" }}</span>
                </div>
              </div>
            </div>
          </div>

          <!-- 合约持仓 -->
          <div class="info" v-if="strategyType != 'spot'">
            <span>{{$t('m.合约信息：')}}</span>
            <div class="datas2">
              <div class="position-title">
                <div class="warp">
                  <span>{{$t('m.账户权益')+" (" + tradePairs.investCoin + " )" }}</span>
                  <span>{{$t('m.强平价')+" (" + tradePairs.investCoin + " )" }}</span>
                  <span>{{$t('m.担保资产率')}}</span>
                  <span>{{$t('m.开仓阈值')}}</span>
                </div>
              </div>

              <div class="position-box">
                <div class="left">
                  <span>{{
                    Math.floor(Number(executeDetail.marginBalance) * 10000) /
                    10000
                  }}</span>
                  <span>{{
                    executeDetail.liquidationPrice
                      ? executeDetail.liquidationPrice
                      : "--"
                  }}</span>
                  <span>{{
                    executeDetail.riskRate
                      ? Math.floor(Number(executeDetail.riskRate) * 10000) /
                          100 +
                        "%"
                      : "--"
                  }}</span>
                  <span>{{ tradePairs.openThreshold * 100 + "%" }}</span>
                </div>
              </div>
            </div>

            <div
              class="line"
              style="margin-top: 30px; margin-bottom: 30px"></div>

            <span>{{$t('m.持仓：')}}</span>
            <div class="datas2">
              <div class="position-title">
                <div class="warp">
                  <span>{{$t('m.方向')}}</span>
                  <span>{{$t('m.持仓量(张)')}}</span>
                  <span>{{$t('m.浮动收益')+ tradePairs.investCoin }}</span>
                  <span>{{$t('m.开仓均价')+ tradePairs.baseCoin }}</span>
                </div>
              </div>

              <div class="position-box">
                <div class="left">
                  <span>{{$t('m.做多')}}</span>
                  <span>{{
                    position.buyPosition.volume
                      ? Math.floor(Number(position.buyPosition.volume) * 10000) / 10000 
                      : "--"}}</span>

                  <span
                    :class="{
                      red: Number(position.buyPosition.profit) > 0,
                      green: Number(position.buyPosition.profit) < 0}">
                      {{position.buyPosition.profit
                        ? Math.floor(Number(position.buyPosition.profit) * 10000) / 10000
                        : "--"}}</span>

                  <span>{{
                    position.buyPosition.costOpen
                      ? Math.floor(Number(position.buyPosition.costOpen) * 10000) / 10000
                      : "--"}}</span>
                </div>
              </div>
              <div class="position-box">
                <div class="left">
                  <span>{{$t('m.做空')}}</span>
                  <span>{{
                    position.sellPosition.volume
                      ? Math.floor(Number(position.sellPosition.volume) * 10000) / 10000
                      : "--"}}</span>

                  <span
                    :class="{
                      red: Number(position.sellPosition.profit) > 0,
                      green: Number(position.sellPosition.profit) < 0}">
                      {{position.sellPosition.profit
                        ? Math.floor( Number(position.sellPosition.profit) * 10000) / 10000
                        : "--"}}</span>

                  <span>{{
                    position.sellPosition.costOpen
                      ? Math.floor(Number(position.sellPosition.costOpen) * 10000) / 10000
                      : "--"}}</span>
                </div>
              </div>
            </div>
          </div>

          <!-- 多币对 -->
          <div class="info" v-if="sonExecuteList && sonExecuteList.length != 0">
            <span>{{$t('m.持仓：')}}</span>

            <div class="datas2">
              <div class="position-title">
                <div class="warp">
                  <span>{{$t('m.币种')}}</span>
                  <span>{{$t('m.数量')}}</span>
                  <span>{{$t('m.浮动收益')+ tradePairs.investCoin }}</span>
                  <span>{{$t('m.开仓均价')+ tradePairs.baseCoin }}</span>
                  <span>{{$t('m.交易数/买入/卖出')}}</span>
                </div>
                <div class="warp">
                  <span class="right">
                    {{$t('m.操作')}}</span>
                </div>
              </div>

              <div class="position-box" v-for="(item, idx) in sonExecuteList" :key="'sonExecuteList'+idx">
                <div class="left">
                  <span>{{ item.counterCoin }}</span>

                  <span>{{
                    item.counterCoinTotal.indexOf(".") > -1
                      ? item.counterCoinTotal.slice(0,item.counterCoinTotal.indexOf(".") + 5)
                      : item.counterCoinTotal}}</span>

                  <span
                    :class="{
                      red: Number(item.floatEarnings) > 0,
                      green: Number(item.floatEarnings) < 0}">
                      {{ item.floatEarnings ? item.floatEarnings : "--" }}</span>

                  <span>{{
                    item.costPrice
                      ? Math.floor(Number(item.costPrice) * 10000) / 10000
                      : "--"}}</span>

                  <span>{{
                    ((item.buyCount ? Number(item.buyCount) : 0) +

                     (item.sellCount ? Number(item.sellCount) : 0) > 0
                      ? (item.buyCount ? Number(item.buyCount) : 0) + 
                        (item.sellCount ? Number(item.sellCount) : 0)
                      : "--") +

                      " / " +
                     (item.buyCount ? item.buyCount : "--") +
                      " / " +
                     (item.sellCount ? item.sellCount : "--")}}</span>
                </div>

                <div class="right">
                  <span @click="endClick(item.executeId)">
                    {{$t('m.终止')}}</span>

                  <span
                    @click="stopClick(item.executeId)"
                    v-if="item.states == 'EXECUTE'">
                    {{$t('m.暂停')}}</span>

                  <span
                    @click="restartClcik(item.executeId)"
                    v-if="item.states == 'SUSPEND'">
                    {{$t('m.启动')}}</span>

                  <span @click="noticeClick(item.executeId)">
                    {{$t('m.消息')}}</span>
                  <span @click="childModifyParameters(item)">
                    {{$t('m.参数')}}</span>
                  <span @click="orderClcik(item.executeId)">
                    {{$t('m.委托订单')}}</span>
                  <span @click="tradeClick(item.executeId)">
                    {{$t('m.成交记录')}}</span>
                </div>
              </div>
            </div>
          </div>

          <div></div>

          <!-- 历史 -->
          <div class="line"></div>
          <div class="info" v-if="historyList && historyList.length != 0">
            <span>{{$t('m.历史记录：')}}</span>

            <div class="datas2">
              <div class="position-title">
                <div class="warp">
                  <span>{{$t('m.币种')}}</span>
                  <span>{{$t('m.交易数/买入/卖出')}}</span>
                </div>
                <div class="warp">
                  <span class="right">
                    {{$t('m.操作')}}</span>
                </div>
              </div>

              <div class="position-box" v-for="(item, idx) in historyList" :key="'historyList' + idx">
                <div class="left">
                  <span>
                    {{item.counterCoin + "/" + tradePairs.investCoin}}</span>

                  <span>{{
                    ((item.finishOpenCount ? Number(item.finishOpenCount) : 0) +

                      (item.finishCloseCount ? Number(item.finishCloseCount) : 0) > 0 
                        ? (item.finishOpenCount ? Number(item.finishOpenCount) : 0) +
                          (item.finishCloseCount ? Number(item.finishCloseCount) : 0)
                      : "--") +

                      " / " +

                      (item.finishOpenCount ? Number(item.finishOpenCount) : "--") +

                      " / " +
                      (item.finishCloseCount ? Number(item.finishCloseCount) : "--")}}</span>
                      
                </div>
                <div class="right">
                  <span @click="noticeClick(item.executeId)">
                    {{$t('m.消息')}}</span>
                  <span @click="childModifyParameters(item, true)">
                    {{$t('m.参数')}}</span>
                  <span @click="tradeClick(item.executeId)">
                    {{$t('m.成交记录')}}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="line" v-if="historyList && historyList.length != 0"></div>
        </div>
      </div>
    </el-container>

    <!-- 加仓 -->
    <el-dialog
      :title="positionType"
      :visible.sync="increaseShow"
      width="20%"
      :close-on-click-modal="false"
      :before-close="handleClose">
      <div class="increase" v-if="limitMap">
        <div class="text">
          <div class="box">
            <span class="box-title" v-if="positionType == '加仓'">
              {{$t('m.加仓金额')}}</span>

            <span class="box-title" v-if="positionType == '减仓'">
              {{$t('m.减仓金额')}}</span>

            <el-input
              v-model="fixedInvestment"
              :placeholder="$t('m.请输入内容')"
              type="number">
              <i class="iconStyle" slot="suffix">{{ tradePairs.investCoin }}</i>
            </el-input>

            <span class="available">
              {{$t('m.可用: ')+ available + " " + tradePairs.investCoin}}</span>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="scaleInCancel()">
          {{$t('m.取 消')}}</el-button>
        <el-button type="primary" @click="scaleIn(executeId)">
          {{$t('m.确 定')}}</el-button>
      </span>
    </el-dialog>

    <!-- 修改参数 -->
    <template v-if="modifyParametersShow">
      <el-dialog
        :title="$t('m.修改参数')"
        :visible.sync="modifyParametersShow"
        width="40%"
        :before-close="handleClose"
        :close-on-click-modal="false">
        <div class="modifyParameters">
          <p class="otherSetting-title">
            {{$t('m.参数设置')}}</p>
            
          <template
            v-if="
              strategyId != 'WL20210512112808681' &&
              strategyId != 'WL20190402171936974x' &&
              strategyId != 'WL202007311426363425x' &&
              strategyId != 'WL202007311426363424x' &&
              strategyId != 'WL202X0512112808681' &&
              strategyId != 'WL20210624182847743'
            ">
            <template v-for="(item, idx) in reserves">
              <div :key="'reserves1'+idx"
                class="parameter-box"
                v-if="item.visiable == '1' || strategyId == 'CL20181115155643599x'">

                <div class="left">
                  <span class="reserves-name">{{ item.name }}</span>
                  <img
                    class="jieshi-tips"
                    src="../../assets/icon/jieshi.png"
                    alt=""
                    @click="reservesTips(item.comment)"
                    v-if="
                      item.name != '单格投入' &&
                      strategyId == 'CL20181115155003478x'
                    "/>

                  <span
                    style="margin-left: 6px"
                    v-if="
                      item.name == '单格投入' &&
                      strategyId == 'CL20181115155003478x'"></span>

                  <!-- 不计算范围 -->
                  <span
                    v-if="
                      ((item.valueMin == '' && item.valueMax == '') ||
                        (item.valueMin == '0' && item.valueMax == '0')) &&
                      item.name != '价格上限' &&
                      item.name != '价格下限'">
                    {{
                      $t('m.范围')+
                      " " +
                      (item.valueMin &&
                      (item.valueMin != 0 || item.valueMax != 0)
                        ? item.valueMin
                        : item.start) +
                      "~" +
                      (item.valueMax &&
                      (item.valueMin != 0 || item.valueMax != 0)
                        ? item.valueMax
                        : item.end)
                    }}</span>

                  <!-- 计算范围 -->
                  <span
                    v-if="
                      item.valueMin != '' &&
                      item.valueMax != '' &&
                      item.valueMin != '0' &&
                      item.valueMax != '0' &&
                      item.name != '价格上限' &&
                      item.name != '价格下限'">
                    {{
                      $t('m.范围')+
                      calculation(
                        tradePairs.investCoin,
                        tradePairs,
                        item.valueMin &&
                          (item.valueMin != "" || item.valueMax != "")
                          ? item.valueMin
                          : item.start
                      ) +
                      "~" +
                      calculation(
                        tradePairs.investCoin,
                        tradePairs,
                        item.valueMax &&
                          (item.valueMin != 0 || item.valueMax != 0)
                          ? item.valueMax
                          : item.end
                      )
                    }}</span>
                </div>

                <div class="right">
                  <el-input
                    :placeholder="$t('m.请输入内容')"
                    v-model="item.value"
                    @blur="parameterVerification($event, item)"
                    :disabled="isOldParams">
                    <i
                      class="iconStyle"
                      slot="suffix"
                      v-if="
                        item.valueMin != 0 ||
                        item.valueMax != 0 ||
                        item.name == '价格上限' ||
                        item.name == '价格下限'
                      ">{{ tradePairs.investCoin }}</i>

                    <i
                      class="iconStyle"
                      slot="suffix"
                      v-if="item.valueType == 'percent'">%</i >
                  </el-input>
                </div>
              </div>
            </template>
          </template>

          <!-- 恒量定投 -->
          <template v-if="strategyId == 'WL20190402171936974x'">
            <template v-for="(item, idx) in reserves">
              <!-- 定投周期 -->
              <div class="parameter-box" v-if="item.name == '定投周期'" :key="'reserves2'+idx">
                <div class="left">
                  <span class="reserves-name">{{ item.name }}</span>
                  <img
                    class="jieshi-tips"
                    src="../../assets/icon/jieshi.png"
                    alt=""
                    @click="reservesTips(item.comment)"
                  />
                </div>
                <div class="right">
                  <el-cascader
                    v-model="fixedTime"
                    :options="times"
                    @change="handleChange"
                    :disabled="isOldParams"></el-cascader>
                </div>
              </div>

              <!-- 单笔金额 -->
              <div class="parameter-box" v-if="item.name == '单笔金额'" :key="'reserves2'+idx">
                <div class="left">
                  <span class="reserves-name">{{ item.name }}</span>
                  <img
                    class="jieshi-tips"
                    src="../../assets/icon/jieshi.png"
                    alt=""
                    @click="reservesTips(item.comment)"/>

                  <span v-if="item.valueMin || item.start">{{
                    $t('m.范围')+
                    " " +
                    (item.valueMin && (item.valueMin != 0 || item.valueMax != 0)
                      ? item.valueMin
                      : item.start) +
                    "~" +
                    (item.valueMax && (item.valueMin != 0 || item.valueMax != 0)
                      ? item.valueMax
                      : item.end)
                  }}</span>
                </div>
                <div class="right">
                  <el-input
                    :placeholder="$t('m.请输入内容')"
                    v-model="item.value"
                    @blur="parameterVerification($event, item)"
                    :disabled="isOldParams">
                    <i
                      class="iconStyle"
                      slot="suffix"
                      v-if="item.valueMin != 0 || item.valueMax != 0"
                      >{{ tradePairs.investCoin }}</i>
                    <i
                      class="iconStyle"
                      slot="suffix"
                      v-if="item.valueType == 'percent'" >%</i>
                  </el-input>
                </div>
              </div>
            </template>
          </template>

          <!-- 海风马丁  WL20210512112808681 -->
          <template
            v-if="strategyId == 'WL20210512112808681' || strategyId == 'WL202X0512112808681'">
            <template v-for="(item, idx) in reserves">
              <!-- 补仓金额模式 -->
              <template>
                <div :key="'reserves3'+idx"
                  class="parameter-box"
                  v-if="item.visiable == '1' && item.name == '补仓金额模式'">
                  <div class="special-box">
                    <span class="special-box-title">{{ item.name }}</span>
                    
                    <div class="special-box-cont">
                      <span :class="{ on: replenishment }" 
                        @click="replenishment = true">
                        {{$t('m.倍数补仓')}}</span>
                      
                      <span
                        :class="{ on: !replenishment }"
                        @click="replenishment = false">
                        {{$t('m.增量补仓')}}</span>
                    </div>
                  </div>
                </div>

                <div :key="'reserves3'+idx"
                  class="parameter-box"
                  v-if="
                    (replenishment &&
                      (item.name == '补仓金额倍数' ||
                        item.name == '倍投起始单' ||
                        item.name == '最大做单数')) ||
                    (!replenishment &&
                      (item.name == '补仓金额增量' ||
                        item.name == '最大做单数'))">

                  <div class="left">
                    <span class="reserves-name">{{ item.name }}</span>
                    <img
                      class="jieshi-tips"
                      src="../../assets/icon/jieshi.png"
                      alt=""
                      @click="reservesTips(item.comment)" />
                    <span v-if="item.valueMin || item.start">{{
                      $t('m.范围')+
                      " " +
                      (item.valueMin &&
                      (item.valueMin != 0 || item.valueMax != 0)
                        ? item.valueMin
                        : item.start) +
                      "~" +
                      (item.valueMax &&
                      (item.valueMin != 0 || item.valueMax != 0)
                        ? item.valueMax
                        : item.end)}}</span>
                  </div>

                  <div class="right">
                    <el-input
                      :placeholder="$t('m.请输入内容')"
                      v-model="item.value"
                      @blur="parameterVerification($event, item)"
                      :disabled="isOldParams">
                      <i
                        class="iconStyle"
                        slot="suffix"
                        v-if="item.valueMin != 0 || item.valueMax != 0"
                        >{{ tradePairs.investCoin }}</i>
                      <i
                        class="iconStyle"
                        slot="suffix"
                        v-if="item.valueType == 'percent'">%</i>
                    </el-input>
                  </div>
                </div>
              </template>

              <!-- 补仓间隔模式 -->
              <template>
                <div :key="'reserves3'+idx"
                  class="parameter-box"
                  v-if="item.visiable == '1' && item.name == '补仓间隔模式'">
                  <div class="special-box">
                    <span
                      class="special-box-title">{{ item.name }}</span>
                      
                    <div class="special-box-cont">
                      <span
                        :class="{ on: intervalMode }"
                        @click="intervalMode = true">
                        {{$t('m.固定间隔')}}</span>
                        
                        <span
                          :class="{ on: !intervalMode }"
                          @click="intervalMode = false">
                          {{$t('m.自定义间隔')}}</span>
                    </div>
                  </div>
                </div>

                <div :key="'reserves3'+idx"
                  class="parameter-box"
                  v-if="
                    (intervalMode &&
                      (item.id == 'reserve_38' || item.id == 'reserve_39')) ||
                    (!intervalMode &&
                      (item.id == 'reserve_40' ||
                        item.name.search('replenishment') != -1 ||
                        item.name.search('次补仓比例') != -1))">

                  <div class="left">
                    <span class="reserves-name">{{ item.name }}</span>
                    <img
                      class="jieshi-tips"
                      src="../../assets/icon/jieshi.png"
                      alt=""
                      @click="reservesTips(item.comment)"/>

                    <span v-if="item.valueMin || item.start">{{
                      $t('m.范围')+
                      " " +
                      (item.valueMin &&
                      (item.valueMin != 0 || item.valueMax != 0)
                        ? item.valueMin
                        : item.start) +
                      "~" +
                      (item.valueMax &&
                      (item.valueMin != 0 || item.valueMax != 0)
                        ? item.valueMax
                        : item.end)
                    }}</span>
                  </div>
                  <div class="right">
                    <el-input
                      :placeholder="$t('m.请输入内容')"
                      v-model="item.value"
                      @blur="parameterVerification($event, item)"
                      :disabled="isOldParams">
                      <i
                        class="iconStyle"
                        slot="suffix"
                        v-if="item.valueMin != 0 || item.valueMax != 0">
                        {{ tradePairs.investCoin }}</i>
                      <i
                        class="iconStyle"
                        slot="suffix"
                        v-if="item.valueType == 'percent'">%</i>
                    </el-input>
                  </div>
                </div>
              </template>

              <!-- 其他参数 -->
              <template>
                <div :key="'reserves3'+idx"
                  class="parameter-box"
                  v-if="
                    item.id != 'reserve_38' &&
                    item.id != 'reserve_37' &&
                    item.id != 'reserve_39' &&
                    item.id != 'reserve_7' &&
                    item.id != 'reserve_6' &&
                    item.id != 'reserve_9' &&
                    item.id != 'reserve_10' &&
                    item.id != 'reserve_40' &&
                    item.id != 'reserve_35' &&
                    item.id != 'reserve_52' &&
                    item.id != 'reserve_53' &&
                    item.id != 'reserve_54' &&
                    item.name.search('replenishment') == -1 &&
                    item.name.search('次补仓比例') == -1">
                  <div class="left">
                    <span class="reserves-name">{{ item.name }}</span>
                    <img
                      class="jieshi-tips"
                      src="../../assets/icon/jieshi.png"
                      alt=""
                      @click="reservesTips(item.comment)"
                    />
                    <span
                      v-if="
                        (item.valueMin || item.start) &&
                        item.name != '价格上限' &&
                        item.name != '价格下限'">{{
                        $t('m.范围') +
                        " " +
                        (item.valueMin &&
                        (item.valueMin != 0 || item.valueMax != 0)
                          ? item.valueMin
                          : item.start) +
                        "~" +
                        (item.valueMax &&
                        (item.valueMin != 0 || item.valueMax != 0)
                          ? item.valueMax
                          : item.end)
                      }}</span>
                  </div>
                  <div class="right">
                    <el-input
                      :placeholder="$t('m.请输入内容')"
                      v-model="item.value"
                      @blur="parameterVerification($event, item)"
                      :disabled="isOldParams">
                      <i
                        class="iconStyle"
                        slot="suffix"
                        v-if="item.valueMin != 0 || item.valueMax != 0">
                        {{ tradePairs.investCoin }}</i>
                      <i
                        class="iconStyle"
                        slot="suffix"
                        v-if="item.valueType == 'percent'">%</i>
                    </el-input>
                  </div>
                </div>
              </template>

              <!-- 信号参数 -->
              <template v-if="strategyId == 'WL202X0512112808681'">
                <div :key="'reserves3'+idx"
                  class="parameter-box"
                  v-if="
                    item.id == 'reserve_52' ||
                    item.id == 'reserve_53' ||
                    item.id == 'reserve_54'
                  ">
                  <div class="left">
                    <span class="reserves-name">
                      <span>{{ item.name }}</span>
                    </span>
                  </div>

                  <div class="right">
                    <div>
                      <el-select
                        v-model="item.signalName"
                        :placeholder="$t('m.请选择')"
                        @change="signalChange($event, item)">
                        <el-option
                          v-for="(val, idx) in signalList"
                          :key="idx"
                          :label="val.signalName"
                          :value="val">
                        </el-option>
                      </el-select>
                    </div>

                    <div class="signal-params">
                      <div class="one">
                        <el-select
                          v-model="item.paramBodyLabel"
                          :placeholder="$t('m.请选择')"
                          @change="paramBodyValue($event, item)">
                          <template v-if="item.paramBody">
                            <el-option
                              v-for="(e, idx) in item.paramBody"
                              :key="idx"
                              :label="e.label"
                              :value="e">
                            </el-option>
                          </template>
                        </el-select>
                      </div>

                      <div class="two">
                        <el-select
                          v-model="item.ops"
                          :placeholder="$t('m.请选择')"
                          @change="opsChange($event, item)">
                          <el-option
                            v-for="(ops, idx) in SymbolList"
                            :key="idx"
                            :label="ops.value"
                            :value="ops.value">
                          </el-option>
                        </el-select>
                      </div>

                      <div class="three">
                        <el-input
                          v-model="item.signalValue"
                          :placeholder="$t('m.阈值')"
                          @change="signalValueChange($event, item)"
                        ></el-input>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </template>
          </template>

          <!-- 合约策略 -->
          <template
            v-if="
              strategyId == 'WL202007311426363425x' ||
              strategyId == 'WL202007311426363424x' ||
              strategyId == 'WL20210624182847743'
            ">
            <template v-for="(item, idx) in reserves">
              <!-- 合约网格 等比/等差 -->
              <template
                v-if="
                  (gridDistribution && item.name == '网格间隔(等比)') ||
                  (!gridDistribution && item.name == '网格间隔(等差)')
                ">
                <div :key="'reserves4'+idx" class="parameter-box">
                  
                  <div class="special-box">
                    <span class="special-box-title">
                      {{$t('m.网格分布')}}</span>
                    <div class="special-box-cont">
                      <span
                        :class="{ on: gridDistribution }"
                        @click="gridDistribution = true">
                        {{$t('m.等比网格')}}</span>

                      <span
                        :class="{ on: !gridDistribution }"
                        @click="gridDistribution = false">
                        {{$t('m.等差网格')}}</span>
                    </div>
                  </div>
                </div>

                <div :key="'reserves4'+idx" class="parameter-box" v-if="item.visiable == '1'">
                  <div class="left">
                    <span class="reserves-name">
                      <span>{{ item.name }}</span>
                    </span>

                    <!-- 不计算范围 -->
                    <span
                      v-if="
                        (item.valueMin == '' && item.valueMax == '') ||
                        (item.valueMin == '0' && item.valueMax == '0')
                      ">{{
                        $t('m.范围')+
                        " " +
                        (item.valueMin &&
                        (item.valueMin != 0 || item.valueMax != 0)
                          ? item.valueMin
                          : item.start) +
                        "~" +
                        (item.valueMax &&
                        (item.valueMin != 0 || item.valueMax != 0)
                          ? item.valueMax
                          : item.end)
                      }}</span>

                    <!-- 计算范围 -->
                    <span
                      v-if="
                        item.valueMin != '' &&
                        item.valueMax != '' &&
                        item.valueMin != '0' &&
                        item.valueMax != '0'
                        ">{{
                        $t('m.范围')+
                        calculation(
                          tradePairs.investCoin,
                          tradePairs,
                          item.valueMin &&
                            (item.valueMin != "" || item.valueMax != "")
                            ? item.valueMin
                            : item.start
                        ) +
                        "~" +
                        calculation(
                          tradePairs.investCoin,
                          tradePairs,
                          item.valueMax &&
                            (item.valueMin != 0 || item.valueMax != 0)
                            ? item.valueMax
                            : item.end
                        )
                      }}</span>
                  </div>
                  <div class="right">
                    <el-input
                      :placeholder="$t('m.请输入内容')"
                      v-model="item.value"
                      @blur="parameterVerification($event, item)">
                      <i
                        class="iconStyle"
                        slot="suffix"
                        v-if="item.valueMin != 0 || item.valueMax != 0"
                        >{{ tradePairs.investCoin }}</i>
                      <i
                        class="iconStyle"
                        slot="suffix"
                        v-if="item.valueType == 'percent'">%</i>
                    </el-input>
                  </div>
                </div>
              </template>

              <template
                v-if="
                  item.name != '价格上限' &&
                  item.name != '价格下限' &&
                  item.name != '最大做多单数' &&
                  item.name != '最大做空单数' &&
                  item.name != '网格间隔(等差)' &&
                  item.name != '网格间隔(等比)' &&
                  item.name !== '补仓金额模式' &&
                  item.name !== '补仓下单模式' &&
                  item.name !== '止盈平仓模式' &&
                  item.name !== '止盈下单模式'
                ">
                <div :key="'reserves4'+idx" class="parameter-box" v-if="item.visiable == '1'">
                  <div class="left">
                    <span class="reserves-name">
                      <span class="reserves-name">{{ item.name }}</span>
                      <img
                        class="jieshi-tips"
                        src="../../assets/icon/jieshi.png"
                        alt=""
                        @click="reservesTips(item.comment)"
                      />
                    </span>

                    <!-- 不计算范围 -->
                    <span
                      v-if="
                        (item.valueMin == '' && item.valueMax == '') ||
                        (item.valueMin == '0' && item.valueMax == '0')
                      ">{{
                        $t('m.范围')+
                        " " +
                        (item.valueMin &&
                        (item.valueMin != 0 || item.valueMax != 0)
                          ? item.valueMin
                          : item.start) +
                        "~" +
                        (item.valueMax &&
                        (item.valueMin != 0 || item.valueMax != 0)
                          ? item.valueMax
                          : item.end)
                      }}</span>

                    <!-- 计算范围 -->
                    <span
                      v-if="
                        item.valueMin != '' &&
                        item.valueMax != '' &&
                        item.valueMin != '0' &&
                        item.valueMax != '0'
                      ">{{
                        $t('m.范围')+
                        calculation(
                          tradePairs.investCoin,
                          tradePairs,
                          item.valueMin &&
                            (item.valueMin != "" || item.valueMax != "")
                            ? item.valueMin
                            : item.start
                        ) +
                        "~" +
                        calculation(
                          tradePairs.investCoin,
                          tradePairs,
                          item.valueMax &&
                            (item.valueMin != 0 || item.valueMax != 0)
                            ? item.valueMax
                            : item.end
                        )
                      }}</span>
                  </div>

                  <div class="right">
                    <el-input
                      :placeholder="$t('m.请输入内容')"
                      v-model="item.value"
                      @blur="parameterVerification($event, item)">
                      <i
                        class="iconStyle"
                        slot="suffix"
                        v-if="item.valueMin != 0 || item.valueMax != 0"
                        >{{ tradePairs.investCoin }}</i>
                      <i
                        class="iconStyle"
                        slot="suffix"
                        v-if="item.valueType == 'percent'">%</i>
                    </el-input>
                  </div>
                </div>
              </template>

              <template
                v-if="
                  (item.name == '最大做多单数' && contractType == 1) ||
                  (item.name == '最大做空单数' && contractType == 0) ||
                  (contractType == 2 &&
                    (item.name == '最大做空单数' ||
                      item.name == '最大做多单数'))">
                <div :key="'reserves4'+idx" class="parameter-box" v-if="item.visiable == '1'">
                  <div class="left">
                    <span class="reserves-name">{{ item.name }}</span>
                    <img
                      class="jieshi-tips"
                      src="../../assets/icon/jieshi.png"
                      alt=""
                      @click="reservesTips(item.comment)" />

                    <!-- 不计算范围 -->
                    <span
                      v-if="
                        (item.valueMin == '' && item.valueMax == '') ||
                        (item.valueMin == '0' && item.valueMax == '0')">{{
                        $t('m.范围')+
                        " " +
                        (item.valueMin &&
                        (item.valueMin != 0 || item.valueMax != 0)
                          ? item.valueMin
                          : item.start) +
                        "~" +
                        (item.valueMax &&
                        (item.valueMin != 0 || item.valueMax != 0)
                          ? item.valueMax
                          : item.end)
                      }}</span>

                    <!-- 计算范围 -->
                    <span
                      v-if="
                        item.valueMin != '' &&
                        item.valueMax != '' &&
                        item.valueMin != '0' &&
                        item.valueMax != '0'
                      ">{{
                        $t('m.范围')+
                        calculation(
                          tradePairs.investCoin,
                          tradePairs,
                          item.valueMin &&
                            (item.valueMin != "" || item.valueMax != "")
                            ? item.valueMin
                            : item.start
                        ) +
                        "~" +
                        calculation(
                          tradePairs.investCoin,
                          tradePairs,
                          item.valueMax &&
                            (item.valueMin != 0 || item.valueMax != 0)
                            ? item.valueMax
                            : item.end
                        )}}</span>
                  </div>

                  <div class="right">
                    <el-input
                      :placeholder="$t('m.请输入内容')"
                      v-model="item.value"
                      @blur="parameterVerification($event, item)">
                      <i
                        class="iconStyle"
                        slot="suffix"
                        v-if="item.valueMin != 0 || item.valueMax != 0"
                        >{{ tradePairs.investCoin }}</i>
                      <i
                        class="iconStyle"
                        slot="suffix"
                        v-if="item.valueType == 'percent'">%</i>
                    </el-input>
                  </div>
                </div>
              </template>

              <template v-if="item.name == '价格上限' || item.name == '价格下限'">
                <div :key="'reserves4'+idx" class="parameter-box">
                  <div class="left">
                    <span class="reserves-name">{{ item.name }}</span>
                    <img
                      class="jieshi-tips"
                      src="../../assets/icon/jieshi.png"
                      alt=""
                      @click="reservesTips(item.comment)"
                    />
                  </div>
                  <div class="right">
                    <el-input
                      :placeholder="$t('m.请输入内容')"
                      v-model="item.value"
                      @blur="parameterVerification($event, item)">
                      <i class="iconStyle" slot="suffix">
                        {{$t('m.选填')}}</i>
                    </el-input>
                  </div>
                </div>
              </template>

              <!-- SMT合约 -->
              <template v-if="strategyId == 'WL20210624182847743'">
                <div :key="'reserves4'+idx"
                  class="parameter-box-smt"
                  v-if="item.name == '补仓金额模式'">

                  <div class="right">
                    <div class="special-box-cont">
                      <span
                        :class="{ on: replenishmentbutionOne }"
                        @click="replenishmentbutionSelect(true, '倍数补仓')">
                        {{$t('m.倍数补仓')}}</span>
                        
                        <span
                        :class="{ on: !replenishmentbutionOne }"
                        @click="replenishmentbutionSelect(false, '增量补仓')">
                        {{$t('m.增量补仓')}}</span>
                    </div>
                  </div>
                </div>
                <div :key="'reserves4'+idx"
                  class="parameter-box-smt"
                  v-if="item.name == '补仓下单模式'">
                  <div class="right">
                    <div class="special-box-cont">
                      <span
                        :class="{ on: replenishmentbutionTwo }"
                        @click="replenishmentbutionSelect(true, '追踪补仓')">
                        {{$t('m.追踪补仓')}}</span>
                        <span
                        :class="{ on: !replenishmentbutionTwo }"
                        @click="replenishmentbutionSelect(false, '预埋单补仓')">
                        {{$t('m.预埋单补仓')}}</span>
                    </div>
                  </div>
                </div>

                <div :key="'reserves4'+idx"
                  class="parameter-box-smt"
                  v-if="item.name == '止盈平仓模式'">

                  <div class="right">
                    <div class="special-box-cont">
                      <span
                        :class="{ on: replenishmentbutionThree }"
                        @click="replenishmentbutionSelect(true, '一次性止盈')">
                        {{$t('m.一次性止盈')}}</span>
                        
                        <span
                        :class="{ on: !replenishmentbutionThree }"
                        @click="replenishmentbutionSelect(false, '逐单止盈')">
                        {{$t('m.逐单止盈')}}</span>
                    </div>
                  </div>
                </div>
                <div :key="'reserves4'+idx"
                  class="parameter-box-smt"
                  v-if="item.name == '止盈下单模式'">
                  <div class="right">
                    <div class="special-box-cont">
                      <span
                        :class="{ on: replenishmentbutionFour }"
                        @click="replenishmentbutionSelect(true, '追踪止盈')">
                        {{$t('m.追踪止盈')}}</span>
                        
                        <span
                        :class="{ on: !replenishmentbutionFour }"
                        @click="replenishmentbutionSelect(false, '预埋单止盈')">
                        {{$t('m.预埋单止盈')}}</span>
                    </div>
                  </div>
                </div>
              </template>
            </template>

            <!-- 开仓阈值 -->
            <div class="parameter-box">
              <div class="left">
                <span class="reserves-name">
                  <span>{{$t('m.策略开仓阈值')}}</span>
                </span>
              </div>
              <div class="right">
                <el-input
                  :placeholder="$t('m.请输入内容')"
                  v-model="openingThreshold"
                  @change="openingThresholdChange">
                  <i class="iconStyle" slot="suffix">%</i>
                </el-input>
              </div>
            </div>
          </template>

          <p class="otherSetting-title">
            {{$t('m.其他设置')}}</p>

          <div class="otherSetting">
            <div class="parameter-box">
              <div class="title">
                <span>{{$t('m.总收益止盈')}}</span>
                <img
                  class="jieshi-tips"
                  src="../../assets/icon/jieshi.png"
                  alt=""
                  @click="SP()"
                />
              </div>
              <div class="right">
                <el-input
                  :placeholder="$t('m.选填')"
                  v-model="stopProfit"
                  @blur="otherSettingCheck('止盈', stopProfit)"
                  :disabled="isOldParams">
                  <i class="iconStyle" slot="suffix">%</i>
                </el-input>
              </div>
            </div>

            <div class="parameter-box">
              <div class="title">
                <span>{{$t('m.总亏损止损')}}</span>
                <img
                  class="jieshi-tips"
                  src="../../assets/icon/jieshi.png"
                  alt=""
                  @click="SL()" />
              </div>
              <div class="right">
                <el-input
                  :placeholder="$t('m.选填')"
                  v-model="stopLoss"
                  :disabled="isOldParams"
                  @blur="otherSettingCheck('止损', stopLoss)">
                  <i class="iconStyle" slot="suffix">%</i>
                </el-input>
              </div>
            </div>

            <div class="parameter-box">
              <div class="title">
                <span>{{$t('m.定时自动终止')}}</span>
                <img
                  class="jieshi-tips"
                  src="../../assets/icon/jieshi.png"
                  alt=""
                  @click="timeTips()" />
              </div>
              <div class="right">
                <el-date-picker
                  v-model="stopTime"
                  class="date-picker"
                  type="datetime"
                  :placeholder="$t('m.选填')"
                  :picker-options="pickerOptions"
                  :clearable="false"
                  :disabled="isOldParams">
                </el-date-picker>
              </div>
            </div>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <div class="btn" @click="modifyParam" v-if="!isOldParams">
            {{$t('m.修改参数')}}</div>
        </span>
      </el-dialog>
    </template>

    <!-- 委托订单 -->
    <el-dialog
      :title="$t('m.委托订单')"
      :visible.sync="commissionOrderShow"
      width="30%"
      :before-close="handleClose">

      <div class="commissionOrder" v-if="buy.length != 0 || sell.length != 0">
        <div class="content" v-if="strategyType == 'spot'">
          <div class="sell">
            <div class="title">
              <span>{{$t('m.买入')+" (" + buy.length + " )" }}</span>
              <span>{{$t('m.价格')}}</span>
            </div>
            <template v-for="(item, idx) in buy">
              <div class="box" :key="idx">
                <span>{{ item.amount }}</span>
                <span class="red">{{ item.price }}</span>
              </div>
            </template>
          </div>

          <div class="buy">
            <div class="title">
              <span>{{$t('m.卖出')+" (" + sell.length + " )" }}</span>
              <span>{{$t('m.价格')}}</span>
            </div>
            <template v-for="(item, idx) in sell">
              <div class="box" :key="idx">
                <span>{{ item.amount }}</span>
                <span class="green">{{ item.price }}</span>
              </div>
            </template>
          </div>
        </div>

        <div class="content" v-if="strategyType != 'spot'">
          <div class="sell">
            <div class="title">
              <span>{{$t('m.买入（张）')}}</span>
              <span>{{$t('m.价格（USDT）')}}</span>
            </div>
            <template v-if="buy.length != 0">
              <template v-for="(item, idx) in buy">
                <div class="box" :key="idx">
                  <span v-if="item.tradeOffset == 1 && item.tradeType == 1">
                    {{$t('m.开多')+" (" + item.amount + ")"}}</span>

                  <span v-if="item.tradeOffset == 0 && item.tradeType == 1">
                    {{$t('m.平空')+" (" + item.amount + ")"}}</span>
                  <span class="red">{{ item.price }}</span>
                </div>
              </template>
            </template>
          </div>

          <div class="buy">
            <div class="title">
              <span>{{$t('m.卖出（张）')}}</span>
              <span>{{$t('m.价格（USDT）')}}</span>
            </div>

            <template v-if="sell.length != 0">
              <template v-for="(item, idx) in sell">
                <div class="box" :key="idx">
                  <span v-if="item.tradeOffset == 0 && item.tradeType == 0">
                    {{$t('m.平多')+" (" + item.amount + ")"}}</span>

                  <span v-if="item.tradeOffset == 1 && item.tradeType == 0">
                    {{$t('m.开空')+" (" + item.amount + ")"}}</span>
                  <span class="green">{{ item.price }}</span>
                </div>
              </template>
            </template>
          </div>
        </div>
      </div>
      <div v-else class="default">
        <img src="../../assets/image/img_01@2x (1).png" alt="" />
        <span>{{$t('m.暂无委托订单')}}</span>
      </div>
    </el-dialog>

    <!-- 成交记录 -->
    <el-dialog
      :title="$t('m.成交记录')"
      :visible.sync="transactionRecordShow"
      width="30%"
      :before-close="handleClose">
      <div class="transaction-record" v-if="orderList.length != 0">
        <div class="content">
          <div class="title">
            <span>{{$t('m.操作')}}</span>

            <span>{{$t('m.成交价')+"( " + orderList[0].baseCoin + " )" }}</span>

            <span v-if="strategyType != 'spot'">
              {{$t('m.数量 (张)')}}</span>

            <span v-if="strategyType == 'spot'">
              {{$t('m.数量')+" (" + orderList[0].counterCoin + " )"}}</span>

            <span>{{$t('m.全部')+"( " + orderList[0].baseCoin + " )" }}</span>
          </div>

          <template v-for="(item, idx) in orderList">
            <div class="box" :key="idx">
              <div>
                <span
                  :class="item.tradeType == '1' ? 'red' : 'green'"
                  v-if="strategyType == 'spot'">
                  {{ item.tradeType == "1" ? $t('m.买入'): $t('m.卖出')}}</span>

                <span
                  :class="item.tradeType == '1' ? 'red' : 'green'"
                  v-if="strategyType != 'spot'">
                  {{
                    item.tradeType == "1" && item.tradeOffset == "1"
                    ? $t('m.开多')
                    : item.tradeType == "1" && item.tradeOffset == "0" 
                      ? $t('m.平空')
                      : item.tradeType == "0" && item.tradeOffset == "0"
                        ? $t('m.平多')
                        : $t('m.开空')
                  }}</span>

                <!-- <span v-if="item.tradeType == '0'" :class="item.tradeType == '1'?'red':'green'">卖出</span> -->
                <span>{{moment(item.finishTime).format("MM/DD HH:mm:ss")}}</span>
              </div>
              <div :class="item.tradeType == '1' ? 'red' : 'green'">
                {{ Math.floor(Number(item.dealPrice) * 10000) / 10000 }}
              </div>
              <div>
                {{ Math.floor(Number(item.dealAmount) * 10000) / 10000 }}
              </div>
              <div>
                {{ Math.floor(Number(item.usdtVolume) * 10000) / 10000 }}
              </div>
            </div>
          </template>
        </div>
      </div>
      <div v-else class="default">
        <img src="../../assets/image/img_01@2x (1).png" alt="" />
        <span>{{$t('m.暂无成交记录')}}</span>
      </div>
    </el-dialog>

    <!-- 增加币对 -->
    <template v-if="addCurrencyPairShow">
      <el-dialog
        :title="$t('m.增加子币对')"
        :visible.sync="addCurrencyPairShow"
        width="20%"
        :before-close="handleClose"
        :close-on-click-modal="false">
        <div class="addCurrencyPair" v-if="pairList.length > 0">

          <div class="box" v-for="(item, idx) in pairList" :key="'pairList'+idx">
            <div class="checkbox-warp">
              <el-checkbox
                :checked="item.type"
                :key="idx"
                @change="pairChange(item)">
                <div class="box-text">
                  <span>{{ item.counterCoin }}</span>
                  <span>{{ "/" + item.baseCoin }}</span>
                </div>
              </el-checkbox>
            </div>
          </div>
        </div>
        <div v-else class="default">
          <img src="../../assets/image/img_01@2x (1).png" alt="" />
          <span>{{$t('m.暂无可加币对')}}</span>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="addCurrencyPairShow = false">
            {{$t('m.取 消')}}</el-button>
          <el-button type="primary" @click="confirmAddCurrencyPair">
            {{$t('m.确 定')}}</el-button>
        </span>
      </el-dialog>
    </template>

    <!-- 终止 -->
    <el-dialog
      :title="$t('m.终止')"
      :visible.sync="terminationShow"
      width="20%"
      :before-close="handleClose"
      :close-on-click-modal="false">
      <div class="termination">
        <div class="title">{{$t('m.终止确认')}}</div>

        <div class="text">
          {{$t('m.确定终止后，策略将自动撤销未完成的委托单，并兑换成投入币种。')}}</div>
        <div class="tips">
          {{$t('m.此过程将持续几分钟，请耐心等待！您可以查看其他页面，但不可进行操作，以免数据出错。')}}</div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="terminationShow = false">
          {{$t('m.取 消')}}</el-button>
        <el-button type="primary" @click="batchStrategyEnd()">
          {{$t('m.确 定')}}</el-button>
      </span>
    </el-dialog>
  </div>
</template>



<script>
import {
  executeDetail,
  restart,
  suspend,
  strategyEnd,
  getLimitConfig,
  queryFinishOrderList,
  getEntrustOrders,
  updateParam,
  addStore,
  getPairPageById,
  manyAddSymbol,
  contractUpdateParam,
  positionAdjustment,
  queryOperatorUserList,
  querySignalList,
  getAssetApiList,
  getContractApiList,
  getSpotApiList,
  batchSynchAssetApi,
  batchSynchSpotApi,
  batchSynchContractApi,
} from "../../request/api";
import { times } from "../../assets/js/time";
import moment from "moment";
export default {
  components: {},
  data() {
    return {
      moment,
      project: null,
      accInfo: null,
      executeId: null,
      copyExecuteId: null,
      executeDetail: null, //策略详情
      config: null, //策略配置
      tradePairs: null,
      singleExecute: [], //持仓数据

      sonExecuteList: [], //子币对列表

      historyList: [], //历史记录

      // **********加仓*************//
      increaseShow: false, //加仓
      increaseActive: "1", //加仓页
      proportion: null, //加仓百分比
      max: null, //加仓投入上限
      min: null, //加仓投入下限
      fixedInvestment: null, //加仓金额
      limitMap: null, //加仓范围

      available: null, // 加仓可用

      //   ****成员记录******//
      transactionRecordShow: false,
      orderList: [],

      //   *****委托订单******//
      commissionOrderShow: false,
      buy: [],
      sell: [],

      //   ******参数*****//
      modifyParametersShow: false,
      reserves: [],
      reservesCopy: [],
      strategyId: null,

      parameterCheck: true, //参数校验
      otherCheck: true,

      replenishment: true, //海风补仓模式
      intervalMode: true, //海风间隔模式

      fixedTime: [], //恒量定投周期
      copyFixedTime: [], //备份
      times: times,

      // *********合约*************//
      contractTypeShow: false, //合约模式显示
      contractType: 1, //当前合约做多/空
      gridDistribution: true, //网格分布
      openingThreshold: null, //合约开仓阈值

      stopProfit: null,
      stopLoss: null,
      stopTime: null,

      // ******自动终止时间日期禁用*****//
      pickerOptions: {
        disabledDate: (time) => {
          return time.getTime() < new Date().getTime();
        },
      },

      // ********增加子币对******//
      addCurrencyPairShow: false,
      pairList: [],

      strategyType: "spot",

      positionType: "加仓", //加减仓类型

      position: null, //合约持仓

      // 终止
      terminationShow: false,
      stopId: null,

      isOldParams: false,
      signalList: [], //信号列表
      SymbolList: [
        { value: "=" },
        { value: ">" },
        { value: "<" },
        { value: "≥" },
        { value: "≦" },
      ],
      parameterCheckList: [], //参数校验补充

      //SMT合约
      replenishmentbutionOne: true,
      replenishmentbutionTwo: true,
      replenishmentbutionThree: true,
      replenishmentbutionFour: true,


      strategyData:null , //策略数据
    };
  },

  computed: {},

  watch: {},

  methods: {

    // 复制交易
    copyStrategy(){
      console.log(this.strategyData)
      let data=this.strategyData
      let config=JSON.parse(data.executeDetail.config)
      
      console.log(config)


      let copyStrategy={}

      copyStrategy.reserves=config.reserves
      copyStrategy.tradePairs=config.tradePairs[0]
      copyStrategy.stopLoss=config.stopLoss
      copyStrategy.stopProfit=config.stopProfit
      copyStrategy.stopTime=config.stopTime

      copyStrategy.strategyName=data.executeDetail.strategyName
      copyStrategy.strategyId=data.executeDetail.strategyId

      copyStrategy.templatePattern=data.executeDetail.executeType=="FATHER"?1:0

      let TemplateMapData=JSON.parse(sessionStorage.getItem('strategyTemplateMap'))
      // console.log(JSON.parse(TemplateMapData.strategyTemplateMap.investConfig))

      copyStrategy.investConfig=JSON.parse(TemplateMapData.strategyTemplateMap.investConfig)
      if(copyStrategy.templatePattern==1){
        // 多币对
        let pairs=[]
        for(let key of data.sonExecuteList){
          let item={}
          item.baseCoin=key.baseCoin
          item.counterCoin=key.counterCoin
          pairs.push(item)
        }
        copyStrategy.pairs=pairs
      }

      sessionStorage.setItem('copyStrategy',JSON.stringify(copyStrategy))
      sessionStorage.setItem('projectActive','1-1')
      this.$router.push({path:'Project'})
      console.log(copyStrategy)
    },


    /************************ 点击*/

    //返回上一页
    backClick() {
      sessionStorage.setItem("projectActive", "1-2");
      this.$router.back(-1);
    },

    // 参数解释文案
    reservesTips(v) {
      this.$alert("<div style='color:#102859'>" + v + "</div>", "", {
        confirmButtonText: this.$t('m.确定'),
        dangerouslyUseHTMLString: true,
      });
    },

    // 止盈
    SP() {
      if (this.strategyType == "spot") {
        this.$alert(
          "<div style='color:#102859'>"+
          this.$t('m.止盈根据总收益率计算，策略暂停操作优先级高于止盈止损及定时终止，即暂停状态下此类操作不生效。触发止盈后自动终止策略，系统根据你的选择决定是否执行撤单兑换操作。')+
          "</div>",
          this.$t('m.总收益止盈'),
          {
            confirmButtonText: this.$t('m.确定'),
            dangerouslyUseHTMLString: true,
          }
        );
      } else {
        this.$alert(
          "<div style='color:#102859'>"+
          this.$t('m.止盈根据总收益率计算，触发止盈后系统自动撤单平仓后终止策略。')+
          "</div>",
          this.$t('m.总收益止盈'),
          {
            confirmButtonText: this.$t('m.确定'),
            dangerouslyUseHTMLString: true,
          }
        );
      }
    },
    // 止损
    SL() {
      if (this.strategyType == "spot") {
        this.$alert(
          "<div style='color:#102859'>"+
          this.$t('m.止损根据总收益率计算，策略暂停操作优先级高于止盈止损及定时终止，即暂停状态下此类操作不生效。触发止损后自动终止策略，系统根据你的选择决定是否执行撤单兑换操作。')+
          "</div>",
          this.$t('m.总收益止损'),
          {
            confirmButtonText: this.$t('m.确定'),
            dangerouslyUseHTMLString: true,
          }
        );
      } else {
        this.$alert(
          "<div style='color:#102859'>"+
          this.$t('m.止损根据总收益率计算，触发止损后系统自动撤单平仓后终止策略。')+
          "</div>",
          this.$t('m.总收益止损'),
          {
            confirmButtonText: this.$t('m.确定'),
            dangerouslyUseHTMLString: true,
          }
        );
      }
    },

    // 定时终止
    timeTips() {
      if (this.strategyType == "spot") {
        this.$alert(
          "<div style='color:#102859'>"+
          this.$t('m.策略暂停操作优先级高于止盈止损及定时终止，即暂停状态下此类操作不生效。定时终止可能会有几分钟的偏差，触发后自动终止策略，系统根据你的选择决定是否执行撤单兑换操作。')+
          "</div>",
          this.$t('m.定时自动终止'),
          {
            confirmButtonText: this.$t('m.确定'),
            dangerouslyUseHTMLString: true,
          }
        );
      } else {
        this.$alert(
          "<div style='color:#102859'>"+
          this.$t('m.定时终止可能会有几分钟的偏差，触发后系统自动撤单平仓后终止策略。')+
          "</div>",
          this.$t('m.定时自动终止'),
          {
            confirmButtonText: this.$t('m.确定'),
            dangerouslyUseHTMLString: true,
          }
        );
      }
    },

    // 重置参数
    handleClose() {
      this.transactionRecordShow = false;
      this.commissionOrderShow = false;
      this.modifyParametersShow = false;
      this.addCurrencyPairShow = false;
      this.increaseShow = false;
      this.reserves = JSON.parse(JSON.stringify(this.reservesCopy));
      this.fixedTime = JSON.parse(JSON.stringify(this.copyFixedTime));
      this.tradePairs = JSON.parse(JSON.stringify(this.copyTradePairs));
      this.fixedInvestment = null;
      this.buy = [];
      this.sell = [];
      this.orderList = [];
      this.executeId = JSON.parse(JSON.stringify(this.accInfo.executeId));
      this.pairList = [];
      this.isOldParams = false;
    },

    // 获取策略详情
    ExecuteDetail(id) {
      this.topData = [];
      let payload = {
        token: sessionStorage.getItem("token"),
        executeId: id ? id : this.executeId,
        // executeId: this.executeId,
      };
      executeDetail(payload).then((res) => {
        if (res.status == 200) {
          console.log("策略详情", res.data);

          this.strategyData=res.data

          this.executeDetail = res.data.executeDetail;
          this.config = JSON.parse(this.executeDetail.config);
          console.log(this.config);
          console.log(this.config.tradePairs[0]);
          this.tradePairs = this.config.tradePairs[0];

          this.strategyType =
            this.tradePairs.type != "contract_usdt" ? "spot" : "contract_usdt";
          this.openingThreshold = this.tradePairs.openThreshold * 100;
          this.contractType = this.tradePairs.investType;
          console.log(this.contractType);
          this.copyTradePairs = JSON.parse(
            JSON.stringify(this.config.tradePairs[0])
          );
          let singleExecute = res.data.singleExecute;
          console.log(singleExecute);
          if (singleExecute) {
            singleExecute.forEach((e) => {
              if (e.costPrice) {
                e.costPrice = e.costPrice.slice(
                  0,
                  e.costPrice.indexOf(".") + 5
                );
              }
              e.amount = Number(e.amount)
                .toFixed(18)
                .replace(/\.0+$/, "")
                .replace(/(\.\d+[1-9])0+$/, "$1");
              if (e.symbol == "btc") {
                e.amount = e.amount.slice(0, e.amount.indexOf(".") + 7);
              } else {
                e.amount = e.amount.slice(0, e.amount.indexOf(".") + 5);
              }
              console.log(e.amount);
            });
          }
          console.log(singleExecute);
          this.singleExecute = singleExecute;
          this.sonExecuteList = res.data.sonExecuteList;
          this.reserves = JSON.parse(JSON.stringify(this.config.reserves));
          console.log(this.reserves);
          this.reservesCopy = JSON.parse(JSON.stringify(this.config.reserves));
          this.strategyId = this.executeDetail.strategyId;
          this.stopProfit = this.config.stopProfit
            ? Number(this.config.stopProfit) * 100
            : null;
          this.stopLoss = this.config.stopLoss
            ? Number(this.config.stopLoss) * -1 * 100
            : null;
          this.stopTime = this.config.stopTime
            ? Number(this.config.stopTime)
            : null;

          this.position = this.executeDetail.position;
          console.log("参数", this.reserves);

          // 历史记录
          this.historyList = res.data.historyList;

          if (this.strategyId == "WL20190402171936974x") {
            for (let key of this.reserves) {
              if (key.name == "定投类型") {
                this.fixedTime[0] = key.value;
                this.copyFixedTime[0] = key.value;
              } else if (key.name == "定投周期") {
                this.fixedTime[1] = key.value;
                this.copyFixedTime[1] = key.value;
              }
            }
          }
          if (
            this.strategyId == "WL20210512112808681" ||
            this.strategyId == "WL202X0512112808681"
          ) {
            for (let key of this.reserves) {
              if (key.name == "补仓金额模式") {
                this.replenishment = key.value == 0 ? true : false;
              } else if (key.name == "补仓间隔模式") {
                this.intervalMode = key.value == 0 ? true : false;
              }
            }
          }
          if (this.strategyId == "WL20210624182847743") {
            for (let key of this.reserves) {
              if (key.name == "补仓下单模式") {
                console.log(key);
                this.replenishmentbutionTwo = key.value == 0 ? true : false;
              } else if (key.name == "止盈平仓模式") {
                this.replenishmentbutionThree = key.value == 0 ? true : false;
              } else if (key.name == "止盈下单模式") {
                this.replenishmentbutionFour = key.value == 0 ? true : false;
              } else if (key.name == "补仓金额模式") {
                this.replenishmentbutionOne = key.value == 0 ? true : false;
              }
            }
          }

          if (this.strategyId == "WL202007311426363424x") {
            for (let key of this.reserves) {
              if (key.name == "网格间隔(等比)" && key.value != 0) {
                this.gridDistribution = true;
              } else if (key.name == "网格间隔(等差)" && key.value != 0) {
                this.gridDistribution = false;
              }
            }
          }
          console.log(this.reserves);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 计算执行时间
    formatDuring(start, end) {
      let New = new Date().getTime();
      let mss = null;
      if (end) {
        mss = end - start;
      } else {
        mss = New - start;
      }

      var day = parseInt(mss / (1000 * 24 * 60 * 60));
      // var hours = parseInt(mss / (1000 * 60 * 60));
      var hours = parseInt((mss % (1000 * 24 * 60 * 60)) / (1000 * 60 * 60));
      var minutes = parseInt((mss % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = parseInt((mss % (1000 * 60)) / 1000);
      // var str = day + "天" + hours + "时" + minutes + "分";
      var str = day + "天" + hours + "时" + minutes + "分";
      return str;
    },
    // 兑U计算
    calculation(currency, pairMap, val) {
      if (!pairMap) {
        return;
      }
      var number = null;
      if (currency == pairMap.baseCoin) {
        number = val / (Number(pairMap.usdPrice) / Number(pairMap.last));
      } else {
        number = Number(val) / Number(pairMap.usdPrice);
      }
      return Math.floor(number * 1000000) / 1000000;
    },

    //终止
    endClick(id) {
      this.stopId = id;
      this.terminationShow = true;
      return;
      this.$confirm("确认终止该策略？")
        .then((_) => {
          let payload = {
            executeId: id,
            token: sessionStorage.getItem("token"),
          };
          strategyEnd(payload).then((res) => {
            if (res.status == 200) {
              this.$message.success(this.$t('m.操作成功'));
              this.ExecuteDetail();
            } else {
              this.$message.error(res.msg);
            }
          });
        })
        .catch((_) => {});
    },

    // 确定终止
    batchStrategyEnd() {
      let payload = {
        executeId: this.stopId,
        token: sessionStorage.getItem("token"),
      };
      strategyEnd(payload).then((res) => {
        if (res.status == 200) {
          this.$message.success(this.$t('m.操作成功'));
          if (this.executeDetail.executeType == "FATHER") {
            if (this.sonExecuteList.length > 1) {
              this.ExecuteDetail();
            } else {
              this.$router.back(-1);
            }
          } else {
            this.$router.back(-1);
          }

          this.terminationShow = false;
        } else {
          this.$message.error(res.msg);
        }
      });
    },

    //暂停
    stopClick(id) {
      this.$confirm("确认暂停该策略？")
        .then((_) => {
          let payload = {
            executeId: id,
            token: sessionStorage.getItem("token"),
          };
          suspend(payload).then((res) => {
            if (res.status == 200) {
              this.$message.success(this.$t('m.暂停成功'));
              this.ExecuteDetail();
            } else {
              this.$message.error(res.msg);
            }
          });
        })
        .catch((_) => {});
    },

    // 重启
    restartClcik(id) {
      this.$confirm("确认重启该策略？")
        .then((_) => {
          let payload = {
            executeId: id,
            token: sessionStorage.getItem("token"),
          };
          restart(payload).then((res) => {
            if (res.status == 200) {
              this.$message.success(this.$t('m.重启成功'));
              this.ExecuteDetail();
            } else {
              this.$message.error(res.msg);
            }
          });
        })
        .catch((_) => {});
    },
    // 查询加仓范围
    getLimitConfig() {
      let payload = {
        token: sessionStorage.getItem("token"),
      };
      getLimitConfig(payload).then((res) => {
        if (res.status == 200) {
          console.log(res.data);
          this.limitMap = res.data.limitMap;
          this.min = JSON.parse(JSON.stringify(this.limitMap.minInvest));
          this.max = JSON.parse(JSON.stringify(this.limitMap.maxInvest));
        } else {
          this.$message.error(res.msg);
        }
      });
    },

    //加仓
    async addClick(v) {
      this.positionType = v;
      this.batchSynchSpotApiList();
      if (v == "加仓") {
        if (
          this.executeDetail.strategyId == "WL20210624182847743" ||
          this.executeDetail.strategyId == "WL202007311426363425x" ||
          this.executeDetail.strategyId == "WL202007311426363424x"
        ) {
          if (this.executeDetail.exchangeName.toUpperCase() == "OKEXV5") {
            this.getAssetApiList();
          } else {
            this.getSpotApiList();
          }
        } else {
          this.getSpotApiList();
        }
      } else {
        if (
          this.executeDetail.strategyId == "WL20210624182847743" ||
          this.executeDetail.strategyId == "WL202007311426363425x" ||
          this.executeDetail.strategyId == "WL202007311426363424x"
        ) {
          this.getContractApiList();
        } else {
          this.available = this.tradePairs.investCoinnNum;
        }
      }

      this.getLimitConfig();
      this.increaseShow = true;
    },
    // 取消加仓
    scaleInCancel() {
      this.fixedInvestment = null;
      this.increaseShow = false;
    },
    // 加仓
    scaleIn(id) {
      if (!this.fixedInvestment || this.fixedInvestment == "") {
        this.$message.error(this.$t('m.投入金额不能为空'));
        return;
      } else if (this.fixedInvestment < 0) {
        this.$message.error(this.$t('m.投入金额不能小于0'));
        return;
      } else if (this.fixedInvestment > this.available) {
        this.$message.error(this.$t('m.可用余额不足'));
        return;
      }

      if (this.strategyType == "spot") {
        let payload = {
          token: sessionStorage.getItem("token"),
          investCoinNum: this.fixedInvestment,
          executeId: id,
        };
        payload.type = this.positionType == "加仓" ? "add" : "subtract";
        console.log(payload);
        addStore(payload).then((res) => {
          if (res.status == 200) {
            console.log(res.data);
            this.$message.success(this.$t('m.操作成功'));
            this.fixedInvestment = null;
            this.increaseShow = false;
            this.ExecuteDetail();
            this.batchSynchSpotApiList();
          } else {
            this.$message.error(res.msg);
          }
        });
      } else {
        let payload = {
          token: sessionStorage.getItem("token"),
          executeId: this.executeId,
          amount: this.fixedInvestment,
        };
        payload.type = this.positionType == "加仓" ? "add" : "subtract";
        console.log(payload);
        positionAdjustment(payload).then((res) => {
          if (res.status == 200) {
            console.log(res.data);
            this.$message.success(this.$t('m.操作成功'));
            this.fixedInvestment = null;
            this.increaseShow = false;
            this.ExecuteDetail();
            this.batchSynchSpotApiList();
          } else {
            this.$message.error(res.msg);
          }
        });
      }
    },

    //消息
    noticeClick(id) {
      this.$router.push({ path: "strategyMessage", query: { executeId: id } });
    },

    // 参数校验
    parameterVerification(e, item) {
      let value = Number(item.value);
      let currency = this.tradePairs.investCoin;
      let pairMap = this.tradePairs;
      let min = null;
      let max = null;

      if (item.valueMin && item.valueMin != "0") {
        min = this.calculation(currency, pairMap, Number(item.valueMin));
      } else {
        min = Number(item.start);
      }

      if (item.valueMax && item.valueMax != "0") {
        max = this.calculation(currency, pairMap, Number(item.valueMax));
      } else {
        max = Number(item.end);
      }

      if (!value && value != 0) {
        this.$message.error(this.$t('m.参数不能为空'));
        e.target.style.border = "1px solid #F23A4C";
        if (this.parameterCheckList.indexOf(item.name) > -1) {
          return;
        } else {
          if (this.parameterCheckList.indexOf(item.name) > -1) {
            return;
          } else {
            this.parameterCheckList.push(item.name);
          }
        }
        return;
      } else if (
        min > value &&
        item.name != "价格上限" &&
        item.name != "价格下限" &&
        item.name != "网格间隔(等差)"
      ) {
        console.log(value, min, item);
        this.$message.error(this.$t('m.参数不能小于最小范围'));
        e.target.style.border = "1px solid #F23A4C";
        if (this.parameterCheckList.indexOf(item.name) > -1) {
          return;
        } else {
          if (this.parameterCheckList.indexOf(item.name) > -1) {
            return;
          } else {
            this.parameterCheckList.push(item.name);
          }
        }
        return;
      } else if (
        max < value &&
        item.name != "价格上限" &&
        item.name != "价格下限" &&
        item.name != "网格间隔(等差)"
      ) {
        console.log(value, max);
        this.$message.error(this.$t('m.参数不能大于最大范围'));
        e.target.style.border = "1px solid #F23A4C";
        if (this.parameterCheckList.indexOf(item.name) > -1) {
          return;
        } else {
          if (this.parameterCheckList.indexOf(item.name) > -1) {
            return;
          } else {
            this.parameterCheckList.push(item.name);
          }
        }
        return;
      } else if (item.name == "网格间隔(等差)" && item.value == 0) {
        this.$message.error(this.$t('m.网格间隔(等差)不能为0'));
        e.target.style.border = "1px solid #F23A4C";
        if (this.parameterCheckList.indexOf(item.name) > -1) {
          return;
        } else {
          if (this.parameterCheckList.indexOf(item.name) > -1) {
            return;
          } else {
            this.parameterCheckList.push(item.name);
          }
        }
      } else if (item.name == "价格上限" && item.value < 0) {
        this.$message.error(this.$t('m.价格上限不能小于0'));
        if (this.parameterCheckList.indexOf(item.name) > -1) {
          return;
        } else {
          if (this.parameterCheckList.indexOf(item.name) > -1) {
            return;
          } else {
            this.parameterCheckList.push(item.name);
          }
        }
        e.target.style.border = "1px solid #F23A4C";
        return;
      } else if (item.name == "价格下限" && item.value < 0) {
        this.$message.error(this.$t('m.价格下限不能小于0'));
        if (this.parameterCheckList.indexOf(item.name) > -1) {
          return;
        } else {
          if (this.parameterCheckList.indexOf(item.name) > -1) {
            return;
          } else {
            this.parameterCheckList.push(item.name);
          }
        }
        e.target.style.border = "1px solid #F23A4C";
        return;
      } else {
        if (this.parameterCheckList.indexOf(item.name) > -1) {
          this.parameterCheckList.splice(
            this.parameterCheckList.indexOf(item.name),
            1
          );
        }
        e.target.style.border = "1px solid #C0C4CC";
      }
      console.log(item.value);
      console.log(item);
    },

    // 参数二次校验
    secondaryVerification() {
      let reserves = this.reserves;
      let isOk = null;
      for (let key of reserves) {
        if (key.name == "单格投入" && key.value == 0) {
          if (this.parameterCheckList.indexOf(key.name) > -1) {
            return;
          } else {
            if (this.parameterCheckList.indexOf(key.name) > -1) {
              return;
            } else {
              this.parameterCheckList.push(key.name);
            }
          }
          this.$message.error(this.$t('m.单格投入不正确，请检查修改'));
          isOk = false;
        } else if (
          key.name == "首单投入" &&
          (key.value == "" || key.value == 0)
        ) {
          if (this.parameterCheckList.indexOf(key.name) > -1) {
            return;
          } else {
            if (this.parameterCheckList.indexOf(key.name) > -1) {
              return;
            } else {
              this.parameterCheckList.push(key.name);
            }
          }
          this.$message.error(this.$t('m.首单投入不正确，请检查修改'));
          isOk = false;
        } else if (
          key.name == "首单金额" &&
          (key.value == "" || key.value == 0)
        ) {
          if (this.parameterCheckList.indexOf(key.name) > -1) {
            return;
          } else {
            if (this.parameterCheckList.indexOf(key.name) > -1) {
              return;
            } else {
              this.parameterCheckList.push(key.name);
            }
          }
          this.$message.error(this.$t('m.首单金额不正确，请检查修改'));
          isOk = false;
        } else if (
          key.name == "单笔金额" &&
          (key.value == "" || key.value == 0)
        ) {
          if (this.parameterCheckList.indexOf(key.name) > -1) {
            return;
          } else {
            if (this.parameterCheckList.indexOf(key.name) > -1) {
              return;
            } else {
              this.parameterCheckList.push(key.name);
            }
          }
          this.$message.error(this.$t('m.单笔金额不正确，请检查修改'));
          isOk = false;
        } else if (
          key.name == "买入价格" &&
          (key.value == "" || key.value == 0)
        ) {
          if (this.parameterCheckList.indexOf(key.name) > -1) {
            return;
          } else {
            if (this.parameterCheckList.indexOf(key.name) > -1) {
              return;
            } else {
              this.parameterCheckList.push(key.name);
            }
          }
          this.$message.error(this.$t('m.买入价格不正确，请检查修改'));
          isOk = false;
        } else if (
          key.name == "卖出价格" &&
          (key.value == "" || key.value == 0)
        ) {
          if (this.parameterCheckList.indexOf(key.name) > -1) {
            return;
          } else {
            if (this.parameterCheckList.indexOf(key.name) > -1) {
              return;
            } else {
              this.parameterCheckList.push(key.name);
            }
          }
          this.$message.error(this.$t('m.卖出价格不正确，请检查修改'));
          isOk = false;
        } else {
          isOk = true;
        }
      }
      return isOk;
    },
    // 定投参数
    handleChange(value) {
      this.fixedTime = value;
    },
    // 止盈/止损校验
    otherSettingCheck(type, v) {
      console.log(type);
      if (type == "止盈" && Number(v) < 0) {
        this.$message.warning("止盈比例不能小于0");
        this.otherCheck = false;
      } else if (type == "止损" && Number(v) < 0) {
        this.$message.warning("止损比例不能小于0");
        this.otherCheck = false;
      } else if (type == "止损" && Number(v) > 100) {
        this.$message.warning("止损比例不能大于100");
        this.otherCheck = false;
      } else {
        this.otherCheck = true;
      }
    },

    // 策略阈值校验
    openingThresholdChange(v) {
      console.log(v);
      if (v < 0) {
        this.$message.error(this.$t('m.策略开仓阈值不能小于0'));
        if (this.parameterCheckList.indexOf(item.name) > -1) {
          return;
        } else {
          if (this.parameterCheckList.indexOf(item.name) > -1) {
            return;
          } else {
            this.parameterCheckList.push(item.name);
          }
        }
        return;
      } else if (!v) {
        this.$message.error(this.$t('m.策略开仓阈值不能为空'));
        if (this.parameterCheckList.indexOf(item.name) > -1) {
          return;
        } else {
          if (this.parameterCheckList.indexOf(item.name) > -1) {
            return;
          } else {
            this.parameterCheckList.push(item.name);
          }
        }
      } else {
        if (this.parameterCheckList.indexOf(item.name) > -1) {
          this.parameterCheckList.splice(
            this.parameterCheckList.indexOf(item.name),
            1
          );
        }
      }
    },

    // 修改参数
    modifyParam() {
      this.secondaryVerification();
      if (this.parameterCheckList.length !== 0) {
        this.$message.error(this.$t('m.参数配置不正确，请重新修改'));
        return;
      }
      if (!this.otherCheck) {
        this.$message.error(this.$t('m.其他设置参数不正确，请重新修改'));
        return;
      }
      if (this.strategyId == "WL202X0512112808681") {
        for (let key of this.reserves) {
          // if (
          //   (key.id == "reserve_52" ||
          //     key.id == "reserve_53" ||
          //     key.id == "reserve_54") &&
          //   !key.signalValue
          // ) {
          //   this.$message.error(key.name + "阈值不能为空");
          //   return;
          // } else if (
          //   (key.id == "reserve_52" ||
          //     key.id == "reserve_53" ||
          //     key.id == "reserve_54") &&
          //   Number(key.signalValue) <= 0
          // ) {
          //   this.$message.error(key.name + "阈值不能小于0");
          //   return;
          // }
          if (
            key.id == "reserve_52" ||
            key.id == "reserve_53" ||
            key.id == "reserve_54"
          ) {
            let item = {};
            item.signalId = key.signal.signalId;
            item.value = Number(key.signalValue);
            item.attrName = key.paramBodyValue;
            switch (key.ops) {
              case "=":
                item.opt = "eq";
                break;
              case ">":
                item.opt = "gt";
                break;
              case "<":
                item.opt = "lt";
                break;
              case "≦":
                item.opt = "le";
                break;
              case "≥":
                item.opt = "ge";
                break;
              default:
                break;
            }

            key.value = JSON.stringify(item);

            // "value": "{\"opt\":\"eq\",\"signalId\":\"d8aa6c8b8b874e31bd201e9f5510ac43\",\"value\":\"2\",\"attrName\":\"long\"}"
          }
        }
        console.log(this.reserves);
      }

      if (this.strategyId == "WL20190402171936974x") {
        for (let key of this.reserves) {
          if (key.name == "定投类型") {
            key.value = this.fixedTime[0];
          } else if (key.name == "定投周期") {
            key.value = this.fixedTime[1];
          }
        }
        console.log(this.reserves);
      }
      if (
        this.strategyId == "WL20210512112808681" ||
        this.strategyId == "WL202X0512112808681"
      ) {
        for (let key of this.reserves) {
          if (key.name == "补仓金额模式") {
            key.value = this.replenishment ? 0 : 1;
          } else if (key.name == "补仓间隔模式") {
            key.value = this.intervalMode ? 0 : 1;
          }
        }
      }
      if (this.strategyId == "WL20210624182847743") {
        this.reserves.forEach((key) => {
          if (key.name == "补仓下单模式") {
            console.log(key);
            key.value = this.replenishmentbutionTwo ? 0 : 1;
          } else if (key.name == "止盈平仓模式") {
            key.value = this.replenishmentbutionThree ? 0 : 1;
          } else if (key.name == "止盈下单模式") {
            key.value = this.replenishmentbutionFour ? 0 : 1;
          } else if (key.name == "补仓金额模式") {
            key.value = this.replenishmentbutionOne ? 0 : 1;
          }
        });
      }

      if (this.strategyId == "WL202007311426363424x") {
        let list = JSON.parse(JSON.stringify(this.reserves));
        for (let key of list) {
          if (key.id == "reserve_12" && this.gridDistribution) {
            key.value = 0;
          } else if (key.id == "reserve_11" && !this.gridDistribution) {
            key.value = 0;
          }
        }
        this.reserves = list;
      }

      let payload = {
        token: sessionStorage.getItem("token"),
        executeId: this.executeId,
        reserves: JSON.stringify(this.reserves),
        stopProfit: this.stopProfit ? Number(this.stopProfit) / 100 + "" : "",
        stopLoss: this.stopLoss ? (Number(this.stopLoss) / 100) * -1 : "",
        stopTime: this.stopTime ? new Date(this.stopTime).getTime() : "",
      };

      console.log(payload);

      // 合约
      if (
        this.strategyId == "WL202007311426363425x" ||
        this.strategyId == "WL202007311426363424x" ||
        this.strategyId == "WL20210624182847743"
      ) {
        (payload.openThreshold = this.openingThreshold / 100),
          contractUpdateParam(payload).then((res) => {
            if (res.status == 200) {
              console.log(res.data);
              this.$message.success(this.$t('m.修改参数成功'));
              this.modifyParametersShow = false;
              this.ExecuteDetail(this.accInfo.executeId);
            } else {
              this.$message.error(res.msg);
            }
          });
      } else {
        updateParam(payload).then((res) => {
          if (res.status == 200) {
            console.log(res.data);
            this.$message.success(this.$t('m.修改参数成功'));
            this.modifyParametersShow = false;
            this.ExecuteDetail(this.accInfo.executeId);
          } else {
            this.$message.error(res.msg);
          }
        });
      }
    },

    //子币对修改参数
    childModifyParameters(item, type) {
      console.log(item);
      console.log(JSON.parse(item.config));
      this.isOldParams = type;
      let config = JSON.parse(item.config);

      this.executeId = item.executeId;

      this.tradePairs = config.tradePairs[0];

      this.reserves = JSON.parse(JSON.stringify(config.reserves));

      this.stopProfit = config.stopProfit
        ? Number(config.stopProfit) * 100
        : null;
      this.stopLoss = config.stopLoss
        ? Number(config.stopLoss) * -1 * 100
        : null;
      this.stopTime = config.stopTime ? Number(config.stopTime) : null;
      console.log("参数", this.reserves);

      if (this.strategyId == "WL20190402171936974x") {
        for (let key of this.reserves) {
          if (key.name == "定投类型") {
            this.fixedTime[0] = key.value;
            this.copyFixedTime[0] = key.value;
          } else if (key.name == "定投周期") {
            this.fixedTime[1] = key.value;
            this.copyFixedTime[1] = key.value;
          }
        }
      }
      if (
        this.strategyId == "WL20210512112808681" ||
        this.strategyId == "WL202X0512112808681"
      ) {
        for (let key of this.reserves) {
          if (key.name == "补仓金额模式") {
            this.replenishment = key.value == 0 ? true : false;
          } else if (key.name == "补仓间隔模式") {
            this.intervalMode = key.value == 0 ? true : false;
          }
        }
      }

      this.modifyParametersShow = true;
    },

    // 查询委托订单
    orderClcik(id) {
      let payload = {
        token: sessionStorage.getItem("token"),
        executeId: id,
      };
      getEntrustOrders(payload).then((res) => {
        if (res.status == 200) {
          console.log("委托订单", res.data);
          this.commissionOrderShow = true;
          if (this.strategyType == "spot") {
            this.sell = res.data.order.unfinish_sell_orders
              ? JSON.parse(res.data.order.unfinish_sell_orders).orders
              : [];
            this.buy = res.data.order.unfinish_buy_orders
              ? JSON.parse(res.data.order.unfinish_buy_orders).orders
              : [];
          } else {
            let orderList = JSON.parse(res.data.order.unfinish_orders).orders;
            console.log(orderList);
            var BuyOrder = [];
            var SellOrder = [];
            for (var i = 0; i < orderList.length; i++) {
              // 0 多 ，1空
              if (
                orderList[i].tradeOffset == 0 &&
                orderList[i].tradeType == 1
              ) {
                //  平空，
                BuyOrder.push(orderList[i]);
                console.log(1);
              } else if (
                orderList[i].tradeOffset == 0 &&
                orderList[i].tradeType == 0
              ) {
                //平多
                SellOrder.push(orderList[i]);
                console.log(2);
              } else if (
                orderList[i].tradeOffset == 1 &&
                orderList[i].tradeType == 1
              ) {
                // 开多
                BuyOrder.push(orderList[i]);
                console.log(3);
              } else if (
                orderList[i].tradeOffset == 1 &&
                orderList[i].tradeType == 0
              ) {
                //开空
                SellOrder.push(orderList[i]);
                console.log(4);
              }
            }

            if (SellOrder.length > 1) {
              SellOrder.sort(function (a, b) {
                return Number(a.price) - Number(b.price);
              });
            }
            if (BuyOrder.length > 1) {
              BuyOrder.sort(function (a, b) {
                return Number(b.price) - Number(a.price);
              });
            }
            this.buy = BuyOrder;
            this.sell = SellOrder;
          }
          console.log("卖", this.sell);
          console.log("买", this.buy);
          console.log(this.strategyType);
        } else {
          this.$message.error(res.msg);
        }
      });
    },

    // 查询成交记录
    tradeClick(id) {
      let payload = {
        token: sessionStorage.getItem("token"),
        executeId: id,
        exchangeName: this.tradePairs.exchangeName,
      };

      queryFinishOrderList(payload).then((res) => {
        if (res.status == 200) {
          console.log("成交记录", res.data);
          this.orderList = res.data.orderList;
          this.transactionRecordShow = true;
        } else {
          this.$message.error(res.msg);
        }
      });
    },

    // 查询交易对
    getPairPage() {
      let payload = {
        token: sessionStorage.getItem("token"),
        teamProjectId: this.project.teamProjectId,
        exchangeId: this.currentTemplate.exchangeId,
      };
      getPairPageById(payload).then((res) => {
        if (res.status == 200) {
          console.log(res.data);

          let pairList = res.data.pairList;
          let coinList = res.data.coinList;

          let list = [];

          for (let i = 0; i < pairList.length; i++) {
            for (let j = 0; j < coinList.length; j++) {
              if (
                pairList[i].counterCoin == coinList[j] &&
                pairList[i].baseCoin == this.tradePairs.investCoin
              ) {
                list.push(pairList[i]);
              }
            }
          }
          console.log(list);

          let pair = [];

          for (let item of this.sonExecuteList) {
            let box = {};
            box.baseCoin = item.baseCoin;
            box.counterCoin = item.counterCoin;
            pair.push(box);
          }

          console.log(pair);
          if (pair.length != 0) {
            for (let i = 0; i < list.length; i++) {
              for (let j = 0; j < pair.length; j++) {
                console.log();
                if (
                  list[i].baseCoin.toLocaleUpperCase() ==
                    pair[j].baseCoin.toLocaleUpperCase() &&
                  list[i].counterCoin.toLocaleUpperCase() ==
                    pair[j].counterCoin.toLocaleUpperCase()
                ) {
                  list[i].type = true;
                }
              }
            }
            let newList = list.filter(function (item) {
              return item.type != true;
            });
            this.pairList = newList;
          } else {
            this.pairList = list;
          }

          this.addCurrencyPairShow = true;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 增加子币对
    addSubCurrencyPair(id) {
      this.getPairPage();
    },
    // 币对选中
    pairChange(item) {
      if (!item.type) {
        item.type = true;
      } else {
        item.type = false;
      }
      console.log(item);
    },
    // 增加子币对
    confirmAddCurrencyPair() {
      let list = this.pairList;
      let pairs = [];
      for (let i = 0; i < list.length; i++) {
        if (list[i].type == true) {
          let pair = {};
          pair.baseCoin = list[i].baseCoin;
          pair.counterCoin = list[i].counterCoin;
          pairs.push(pair);
        }
      }
      if (pairs.length == 0) {
        this.$message.error(this.$t('m.没有选择币对'));
        return;
      }
      // for(let item of this.sonExecuteList){
      //   let box={}
      //   box.baseCoin=item.baseCoin
      //   box.counterCoin=item.counterCoin
      //   pairs.push(box);
      // }
      console.log(pairs);

      let payload = {
        token: sessionStorage.getItem("token"),
        pairs: JSON.stringify(pairs),
        executeId: this.executeId,
      };
      console.log(payload);

      manyAddSymbol(payload).then((res) => {
        if (res.status == 200) {
          console.log(res.data);
          this.$message.success(this.$t('m.增加币对成功'));
          this.ExecuteDetail();
          this.addCurrencyPairShow = false;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 子币对配置
    subCurrencyPairConfiguration() {},
    //查询信号列表
    querySignalList() {
      let payload = {
        token: sessionStorage.getItem("token"),
        teamId: JSON.parse(sessionStorage.getItem("defaultTeam")).teamId,
        isTotal: true,
        // this.project.teamProjectId
      };
      querySignalList(payload).then((res) => {
        if (res.status == 200) {
          console.log("信号列表", res.data.signalList);
          this.signalList = res.data.signalList;
          console.log(this.reserves);
        }
      });
    },

    // 信号选择
    signalChange(v, item) {
      console.log(v, item);
      item.signalName = v.signalName;
      item.signal = v;
      item.paramBody = JSON.parse(v.paramBody);
      item.paramBodyValue = item.paramBody[0].name;
      item.paramBodyLabel = item.paramBody[0].label;
      item.ops = "=";
      this.$forceUpdate();
    },

    // 信号参数选择
    paramBodyValue(v, item) {
      console.log(v);
      // item.paramBody = [v];
      item.paramBodyValue = v.name;
      item.paramBodyLabel = v.label;
      this.$forceUpdate();
      console.log(item);
    },

    // 符号选择
    opsChange(v, item) {
      item.ops = v;
      console.log(v, item);
      this.$forceUpdate();
    },
    // 信号阈值
    signalValueChange(v, item) {
      item.signalValue = v;
      this.$forceUpdate();
      console.log(item);
    },

    //SMT合约
    replenishmentbutionSelect(v, item) {
      if (item == "一次性止盈" || item == "逐单止盈") {
        this.replenishmentbutionThree = !this.replenishmentbutionThree;
      }
      if (v) {
        for (let e of this.reserves) {
          if (item == "倍数补仓") {
            if (e.name == "补仓金额增量") {
              e.visiable = 0;
            }
            if (e.name == "补仓金额倍数" || e.name == "倍投起始单") {
              e.visiable = 1;
            }
            this.replenishmentbutionOne = true;
          } else if (item == "追踪补仓") {
            if (e.name == "补仓反弹比例") {
              e.visiable = 1;
            }
            this.replenishmentbutionTwo = true;
          } else if (item == "追踪止盈") {
            if (e.name == "止盈回撤比例") {
              e.visiable = 1;
            }
            this.replenishmentbutionFour = true;
          }
        }
      } else {
        for (let e of this.reserves) {
          if (item == "增量补仓") {
            if (e.name == "补仓金额增量") {
              e.visiable = 1;
            }
            if (e.name == "补仓金额倍数" || e.name == "倍投起始单") {
              e.visiable = 0;
            }

            this.replenishmentbutionOne = false;
          } else if (item == "预埋单补仓") {
            if (e.name == "补仓反弹比例") {
              e.visiable = 0;
            }
            this.replenishmentbutionTwo = false;
          } else if (item == "预埋单止盈") {
            if (e.name == "止盈回撤比例") {
              e.visiable = 0;
            }
            this.replenishmentbutionFour = false;
          }
        }
      }
    },

    //v5资产账户
    getAssetApiList() {
      let payload = {
        token: sessionStorage.getItem("token"),
        apiId: this.executeDetail.apiId,
        pageSize: 999,
        pageNo: 1,
        apiName: this.executeDetail.apiName,
      };
      getAssetApiList(payload).then((res) => {
        if (res.status == 200) {
          console.log(res);
          if (res.data.assetApiList.length == 0) {
            this.available = 0;
          } else {
            let assetApiList = res.data.assetApiList;
            let contractTable = 0;
            assetApiList.forEach((e) => {
              if (e.coinType == "USDT") {
                contractTable = e.available;
              }
            });
            this.available =
              contractTable == 0
                ? 0
                : Math.floor(contractTable * 10000) / 10000;
          }
        }
      });
    },
    //币币账户
    getSpotApiList() {
      let payload = {
        token: sessionStorage.getItem("token"),
        teamProjectId: this.project.teamProjectId,
        exchangeName: this.executeDetail.exchangeName,
        userId: this.executeDetail.userId,
        pageSize: 999,
        pageNo: 1,
        apiName: this.executeDetail.apiName,
        investCoin: this.tradePairs.investCoin,
      };
      getSpotApiList(payload).then((res) => {
        if (res.data.spotApiList.length == 0) {
          this.available = 0;
        } else {
          let spotApiList = res.data.spotApiList;
          let contractTable = 0;
          spotApiList.forEach((e) => {
            if (e.coinType == "USDT") {
              contractTable = e.available;
            }
          });
          this.available =
            contractTable == 0 ? 0 : Math.floor(contractTable * 10000) / 10000;
        }
      });
    },
    // 合约账户
    getContractApiList() {
      let payload = {
        token: sessionStorage.getItem("token"),
        exchangeName: this.executeDetail.exchangeName,
        teamProjectId: this.project.teamProjectId,
        accountMode:
          this.executeDetail.exchangeName.toUpperCase() == "HUOBI"
            ? "isolated"
            : "cross",
        userId: this.userId,
        pageSize: 999,
        pageNo: 1,
        apiName: this.executeDetail.apiName,
        contractId: this.tradePairs.contractId,
      };
      getContractApiList(payload).then((res) => {
        if (res.data.contractList.length == 0) {
          this.available = 0;
        } else {
          let contractList = res.data.contractList;
          let contractTable = 0;
          contractList.forEach((e) => {
            if (e.investCoin == "USDT") {
              contractTable = e.available;
            }
          });

          this.available =
            contractTable == 0 ? 0 : Math.floor(contractTable * 10000) / 10000;
          console.log(this.available, contractTable);
        }
      });
    },
    //同步资产
    batchSynchSpotApiList() {
      let payload = {
        token: sessionStorage.getItem("token"),
      };
      if (this.executeDetail.exchangeName.toUpperCase() == "OKEXV5") {
        if (
          this.executeDetail.strategyId == "WL20210624182847743" ||
          this.executeDetail.strategyId == "WL202007311426363425x" ||
          this.executeDetail.strategyId == "WL202007311426363424x"
        ) {
          batchSynchAssetApi(payload).then((res) => {});
        } else {
          batchSynchSpotApi(payload).then((res) => {});
        }
      } else {
        if (
          this.executeDetail.strategyId == "WL20210624182847743" ||
          this.executeDetail.strategyId == "WL202007311426363425x" ||
          this.executeDetail.strategyId == "WL202007311426363424x"
        ) {
          if (this.positionType == "加仓") {
            batchSynchSpotApi(payload).then((res) => {});
          } else {
            batchSynchContractApi(payload).then((res) => {});
          }
        } else {
          if (this.positionType == "加仓") {
            batchSynchContractApi(payload).then((res) => {});
          } else {
            batchSynchSpotApi(payload).then((res) => {});
          }
        }
      }
    },
  },

  mounted() {},
  activated() {
    let project = sessionStorage.getItem("project");
    this.project = JSON.parse(project);
    let accInfo = JSON.parse(sessionStorage.getItem("accInfo"));
    let currentTemplate = JSON.parse(sessionStorage.getItem("currentTemplate"));
    this.currentTemplate = currentTemplate;
    this.accInfo = accInfo;
    this.executeId = JSON.parse(JSON.stringify(this.accInfo.executeId));
    console.log(this.accInfo);
    this.ExecuteDetail();
    this.querySignalList();
  },
  deactivated() {
    this.$destroy();
  },
};
</script>



<style lang='scss' scoped>
.RunInfo {
  width: 100%;
  height: 100%;

  .el-container {
    height: 100%;
    font-size: 16px;
    font-weight: 500;
    color: #102859;

    .el-header {
      width: 100%;
      position: fixed;
      display: flex;
      align-items: center;
      background-color: #32333d;
      color: #ffffff;
      .el-icon-back {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 70px;
        margin-left: 30px;
      }
    }

    .bg {
      height: 100%;
      min-width: 1200px;
      padding: 90px 0 20px 0;
      background-color: #f7f7f7;

      .container {
        min-height: 80vh;
        max-width: 1400px;
        border-radius: 8px;
        margin: 0 auto;
        padding: 10px 40px 30px 40px;
        background-color: #ffffff;

        .header {
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: 42px;
          .lables {
            display: flex;
            .lable {
              margin-right: 20px;
            }
          }
          .buts {
            height: 100%;
            display: flex;
            align-items: center;
            margin-right: -10px;
            .but {
              height: 100%;
              cursor: pointer;
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 0 10px 0 10px;
              font-size: 12px;
              color: #55698a;
            }
            .line {
              width: 1px;
              height: 15px;
              background-color: #55698a;
              margin: 0 10px 0 10px;
            }
          }
        }

        .line {
          height: 1px;
          background-color: #e6e9ee;
        }

        .info {
          margin: 30px 0 30px 0;
          .datas1 {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            .data {
              display: flex;
              flex-direction: column;
              font-size: 12px;
            }
          }
          .datas2 {
            .position-title {
              margin-top: 30px;
              display: flex;
              justify-content: space-between;
              align-items: center;
              .warp {
                display: flex;
                span {
                  font-size: 12px;
                  min-width: 150px;
                }
                :last-child {
                  min-width: 85px;
                }
                .right {
                  text-align: right;
                }
              }
            }
            .position-box {
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-top: 20px;
              .left {
                display: flex;
                span {
                  font-size: 12px;
                  min-width: 150px;
                }
              }
              .right {
                display: flex;

                span {
                  font-size: 12px;
                  min-width: 66px;
                  border-radius: 2px;
                  color: #2174ff;
                  border: 1px solid rgba(33, 116, 255, 0.3);
                  margin-left: 10px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  cursor: pointer;
                }
              }
            }
          }
          .title {
            font-size: 14px;
            font-weight: 400;
            color: #54698a;
            margin-top: 20px;
            margin-bottom: 10px;
          }
          .text-lv {
            color: #26b34b;
          }
          .text-hong {
            color: #e12525;
          }
          .text-lan {
            color: #2174ff;
          }
          .mtop30 {
            margin-top: 30px;
          }
        }
      }
    }
  }

  .green {
    color: #26b34b;
  }
  .red {
    color: #e12525;
  }
  // 加仓
  .increase {
    .title {
      font-size: 14px;
      color: #333;
      :last-child {
        font-size: 12px;
        color: #999;
        margin-left: 5px;
      }
    }
    .text {
      .box {
        margin-bottom: 10px;
        .box-title {
          display: inline-block;
          font-size: 10px;
          color: #666;
          margin-bottom: 4px;
        }
        .el-input--suffix {
          line-height: 40px;
        }
        .available {
          display: inline-block;
          font-size: 12px;
          margin-top: 10px;
          margin-left: 4px;
        }
      }
    }
    .tips {
      font-size: 12px;
      color: #999;
      line-height: 18px;
      margin-top: 10px;
    }
  }
  // 成交记录
  .transaction-record {
    height: 450px;
    overflow: scroll;
    font-size: 14px;
    color: #999;
    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      span {
        width: 25%;
      }
    }
    .box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 10px 0;
      font-size: 12px;
      div {
        display: flex;
        flex-direction: column;
        width: 25%;
      }
      .red {
        color: #ff4141;
      }
      .green {
        color: #00ad88;
      }
    }
  }
  .default {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  // 委托订单
  .commissionOrder {
    .content {
      display: flex;
      justify-content: space-around;
      min-height: 300px;
      .sell,
      .buy {
        display: flex;
        flex-direction: column;
        width: 40%;
        .title,
        .box {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        .box {
          margin: 10px 0;
        }
        .red {
          color: #ff4141;
        }
        .green {
          color: #00ad88;
        }
      }
    }
  }
  // 修改参数
  .modifyParameters {
    height: 500px;
    overflow: scroll;
    padding-right: 20px;
    padding-bottom: 20px;
    // .box {
    //   margin-bottom: 10px;
    //   .box-title {
    //     display: inline-block;
    //     font-size: 14px;
    //     color: #333;
    //     margin-bottom: 4px;
    //     :last-child {
    //       font-size: 12px;
    //       color: #666;
    //       margin-left: 4px;
    //     }
    //   }
    //   ::v-deep.el-cascader {
    //     width: 100%;
    //   }
    // }
    .parameter-box {
      margin-bottom: 10px;
      .left {
        margin-bottom: 10px;
        .jieshi-tips {
          width: 12px;
          height: 12px;
          cursor: pointer;
          margin: 0 6px;
        }
        img {
          margin: 0 6px;
        }
      }
      .right {
        .el-input--suffix {
          line-height: 40px;
        }
        .el-select {
          width: 100%;
        }
        .signal-params {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 20px;

          .one {
            width: 35%;
          }
          .two {
            width: 30%;
          }
          .three {
            width: 30%;
          }
        }
      }
    }
    //SMT
    .parameter-box-smt {
      display: flex;
      // align-items: center;
      padding: 0 0 20px 0;
      .left {
        display: flex;
        flex-direction: column;
        width: 120px;
        .reserves-name {
          display: flex;
          align-items: center;
          color: #102859 !important;
          font-size: 14px !important;
          margin-bottom: 4px;
          img {
            width: 12px;
            height: 12px;
            cursor: pointer;
            margin-left: 10px;
          }
        }
        :last-child {
          color: #55698a;
          font-size: 12px;
        }
      }
      .right {
        flex: 1;
        .el-input {
          height: 40px;
        }
        .el-cascader {
          width: 100%;
        }
        ::v-deep.el-input__inner {
          height: 40px;
          line-height: 40px;
        }
        ::v-deep.el-input__suffix {
          line-height: 40px;
        }
        .el-select {
          width: 100%;
        }
        .signal-params {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 20px;

          .one {
            width: 45%;
          }
          .two {
            width: 20%;
            /deep/.el-icon-arrow-up:before {
              content: "";
            }
            /deep/.el-input__inner {
              display: flex;
              align-items: center;
              padding-right: 10px;
            }
          }
          .three {
            width: 35%;
            /deep/.el-input__inner {
              padding-right: 10px;
            }
          }
        }
      }
      // 海风特殊参数样式
      .special-box {
        width: 100%;
        .special-box-title {
          color: #102859;
          font-size: 16px;
        }
        .special-box-cont {
          width: 100%;
          height: 40px;
          display: flex;
          justify-content: center;
          align-items: center;

          margin-top: 10px;
          span {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 50%;
            height: 40px;
            background: #f7f7f7;
            color: #102859;
            cursor: pointer;
            border-radius: 4px;
          }
          .on {
            background: #2174ff;
            color: #fff;
          }
        }
      }
      .special-box-cont {
        width: 100%;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;

        margin-top: 10px;
        span {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 50%;
          height: 40px;
          background: #f7f7f7;
          color: #102859;
          cursor: pointer;
          border-radius: 4px;
        }
        .on {
          background: #2174ff;
          color: #fff;
        }
      }
    }
    .otherSetting-title {
      color: #2174ff;
      margin-bottom: 10px;
    }
    // 海风特殊参数样式
    .special-box {
      width: 100%;
      .special-box-title {
        color: #102859;
      }
      .special-box-cont {
        width: 100%;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;

        margin-top: 10px;
        span {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 50%;
          height: 40px;
          background: #f7f7f7;
          color: #102859;
          cursor: pointer;
          border-radius: 4px;
        }
        .on {
          background: #2174ff;
          color: #fff;
        }
      }
    }
    .otherSetting {
      .parameter-box {
        .title {
          margin-bottom: 10px;
          .jieshi-tips {
            width: 12px;
            height: 12px;
            cursor: pointer;
            margin: 0 6px;
          }
        }
      }
    }
    .el-date-editor {
      width: 100%;
    }
  }
  // 修改参数确认
  .btn {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #007aff;
    color: #fff;
    font-size: 16px;
    border-radius: 4px;
    cursor: pointer;
  }
  // 增加币对
  .addCurrencyPair {
    height: 400px;
    overflow: scroll;
    .title {
      font-size: 12px;
      color: #999;
      margin-bottom: 10px;
    }
    .checkbox-warp {
      margin-bottom: 10px;
      padding: 5px;
      .box {
        .box-text {
          margin-left: 30px;
          font-size: 14px;
          color: #666;
          :last-child {
            font-size: 12px;
            color: #999;
          }
        }
      }
    }

    .checkbox-warp:hover {
      background: #d5d5d5;
      border-radius: 4px;
    }
  }

  // 终止/暂停弹窗
  .termination,
  .suspend {
    font-size: 12px;
    margin: 0 24px;
    color: #333;
    line-height: 20px;
    .title {
      font-size: 14px;
      text-align: center;
      font-weight: 600;
    }
    .text {
      font-size: 12px;
      margin: 20px 0;
    }
    .tips {
      color: #999;
    }
  }
}
</style>