<template>
  <div class="createOrderChain">
    <!-- 头部搜索 -->
    <div class="top-select">

      <div class="select-label">
        <span>{{$t('m.订单标签：')}}</span>
        <el-input v-model="orderLabel">
          <i slot="suffix">
            <img
              class="icon-suiji"
              src="../../assets/icon/suiji.png"
              alt=""
              @click="queryOrderLabel()" />
          </i>
        </el-input>
      </div>

      <div class="select-exchange">
        <span>{{$t('m.交易所：')}}</span>
        <el-select
          v-model="exchangeName"
          filterable
          :placeholder="$t('m.请选择')"
          @change="checkExchange"
          :disabled="startAcc.length != 0">
          <el-option
            v-for="item in exchangeList"
            :key="item.exchangeId"
            :label="item.exchangeName"
            :value="item.exchangeId">
          </el-option>
        </el-select>
      </div>

      <div class="select-type">
        <span>{{$t('m.交易类型：')}}</span>
        <el-select v-model="transactionType" filterable @change="typeChange">
          <el-option
            v-for="item in typeList"
            :key="item.value"
            :label="$t('m.'+item.label)" 
            :value="item.value">
          </el-option>
        </el-select>
      </div>
    </div>

    <!-- 创建内容 -->
    <div class="mian-cont">
      <!--  -->
      <div class="create">
        <OrderChain
          ref="child"
          @childTrigger="childTrigger"
          @queryOperatorUserList="queryOperatorUserList"
          :transactionType="transactionType"
          :exchangeName="exchangeName"
          @leverTypeChange="leverTypeChange"
          :controlsPrice="controlsPrice"
        />
      </div>

      <!-- 账户 -->
      <div class="account">
        <div class="account-title">
          <span>*</span>
          <span>{{$t('m.下单账户')}}</span>
        </div>

        <!---->
        <div
          class="acc-warp"
          :class="[accList.length > 5 ? 'overflowBox' : '']">
          <template v-if="transactionType == 'spot'">
            <template v-for="(item, idx) in accList">
              <div class="acc-info" :key="idx">
                <span class="acc-name">
                  <span>{{
                    item.remarkCode ? item.remarkCode : item.publicCode
                  }}</span>
                  <el-checkbox
                    v-model="item.isCheck"
                    @change="accChange"
                    :disabled="item.show"
                    :key="new Date().getTime() + idx">
                  </el-checkbox>
                </span>
                <span>{{
                  Number(item.available) +
                  " " +
                  $refs.child.orderList[0].inputCurrency
                }}</span>
              </div>
            </template>
          </template>

          <!-- 合约 -->
          <template v-if="transactionType == 'contract_usdt'">
            <div v-for="(item, idx) in accList" class="acc-box" :key="'accList'+idx">
              <div
                class="acc-info"
                :key="idx"
                v-if="investmentType == 固定投入 && singleDirection == 0">
                <span class="acc-name">
                  <span>{{ item.publicCode }}</span>
                  <el-checkbox
                    v-model="item.isCheck"
                    @change="accChange"
                    :disabled="contractSize(Number(item.available)) < investment">
                  </el-checkbox>
                </span>
                <span>
                  {{$t('m.可开&(1)张').replace('&(1)', contractSize(Number(item.available)))}}
                </span>
              </div>

              <div
                class="acc-info"
                :key="idx"
                v-if="investmentType != '固定投入' && singleDirection == 0">
                <span class="acc-name">
                  <span>{{ item.publicCode }}</span>
                  <el-checkbox
                    v-model="item.isCheck"
                    @change="accChange"
                    :disabled="
                      !investment ||
                      investment == '' ||
                      (contractSize(Number(item.available)) < proportionMin &&
                        proportionMin > 0) ||
                      (contractSize(Number(item.available)) > proportionMax &&
                        proportionMax != 0 &&
                        contractSize(Number(item.available)) > proportionMax &&
                        proportionMax != '')"
                    :key="new Date().getTime() + idx"></el-checkbox>
                </span>
                <span>
                  {{$t('m.可开&(1)张').replace('&(1)', contractSize(Number(item.available)))}}
                </span>
              </div>

              <div
                class="acc-info"
                :key="idx"
                v-if="investmentType == '固定投入' && singleDirection == 1">
                <span class="acc-name">
                  <span>{{ item.publicCode }}</span>
                  <el-checkbox
                    v-model="item.isCheck"
                    @change="accChange"
                    :disabled="
                      !item.buy ||
                      Math.floor(Number(item.buy) * 100) / 100 < investment
                    "
                    :key="new Date().getTime() + idx"
                  ></el-checkbox>
                </span>
                <span>
                  {{$t('m.可平&(1)张').replace('&(1)', (item.buy ? Math.floor((Number(item.buy) * 100) / 100) : "0"))}}
                </span>
              </div>
              <div
                class="acc-info"
                :key="idx"
                v-if="investmentType == '固定投入' && singleDirection == 2">
                <span class="acc-name">
                  <span>{{ item.publicCode }}</span>
                  <el-checkbox
                    v-model="item.isCheck"
                    @change="accChange"
                    :disabled="
                      !item.sell ||
                      Math.floor(Number(item.sell) * 100) / 100 < investment
                    "
                    :key="new Date().getTime() + idx">
                  </el-checkbox>
                </span>
                <span>
                  {{$t('m.可平&(1)张').replace('&(1)', (item.sell ? Math.floor(Number(item.sell) * 100) / 100 : "0"))}}
                </span>
              </div>

              <div class="acc-boxBtn" @click="transferBtn(item)">{{$t('m.划转')}}</div>
            </div>
          </template>
        </div>

        <!-- 空 -->
        <div class="empty" v-if="accList && accList.length == 0">
          <img src="../../assets/image/no-acc.png" alt="" />
          <span>{{$t('m.暂无可用账户')}}</span>
        </div>
        <!-- 启动 -->
        <div class="btn-warp" :class="{ on: IsStart }" @click="orderStart()">
          {{$t('m.启动订单链')}}
        </div>
      </div>
    </div>

    <!-- 划转 -->
    <el-dialog
      :title="$t('m.划转')"
      :visible.sync="transfer"
      width="400px"
      center
      :before-close="handleClose"
      :close-on-click-modal="false">
      <div class="dialog-nav">
        <div class="dialog-box">
          <div>
            <div class="dialog-top">
              {{$t('m.从')+'：'}}
              <el-select
                size="medium"
                v-model="topValue"
                @change="topValueChange"
                :placeholder="$t('m.请选择')">
                <el-option
                  v-for="(item, index) in topOptions"
                  :key="index"
                  :value="item.name">
                </el-option>
              </el-select>
            </div>

            <div class="dialog-top">
              {{$t('m.到')+'：'}}
              <el-select
                size="medium"
                v-model="twoValue"
                @change="twoValueChange"
                :placeholder="$t('m.请选择')">
                <el-option
                  v-for="(item, index) in twoOptions"
                  :key="index"
                  :value="item.name">
                </el-option>
              </el-select>
            </div>
          </div>
          <img
            @click="transformationBtn()"
            class="dialog-img"
            src="../../assets/icon/zhuanhuan-big.png"
            alt=""/>
        </div>
        <div class="dialog-currency" style="margin-bottom: 15px">
          <span>{{$t('m.币种')}}</span>
          <el-select size="medium" v-model="currencyValue" 
          :placeholder="$t('m.请选择')">
            <el-option
              v-for="(item, index) in currencyList"
              :key="index"
              :label="item"
              :value="item">
            </el-option>
          </el-select>
        </div>
        <div class="dialog-currency">
          <span>{{$t('m.划转数量')}}</span>
          <el-input
            v-model="inputOptions"
            type="number"
            :placeholder="$t('m.请输入内容')"
            :class="inputStyle ? 'inputStyleItem' : ''"
            @focus="inputNumberFocus($event)"
            @blur="inputNumber($event)">
            <template slot="append">USDT |
              <span
                style="cursor: pointer; color: #2174ff"
                @click="inputOptions = usdtAvailable">
                {{$t('m.全部')}}
              </span>
            </template>
          </el-input>
        </div>
        <span style="margin-top: 10px">
          {{$t('m.可用划转')}} {{ usdtAvailable + " " + currencyValue }}
        </span>
      </div>
      <div slot="footer">
        <div class="dialog-footer">
          <div
            class="footerBack"
            @click="
              transfer = false;
              inputOptions = '';
              currencyValue = 'USDT';">
            {{$t('m.取消')}}
          </div>
          <div class="footerBtn" @click="transferDetermine()">确定</div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { duration } from "moment";
import OrderChain from "../../components/orderChain.vue";
import {
  queryOrderLabel,
  queryProjectData,
  queryOperatorUserList,
  queryFeeAndAccuracy,
  batchCreateOrderChain,
  queryOrderChainList,
  findByapiIdCoin,
  findFundSpot,
  findFundAssets,
  getProject,
  transfer,
  findContractId,
  findContract,
} from "../../request/api";
export default {
  components: {
    OrderChain,
  },
  data() {
    return {
      project: null, //项目信息
      orderLabel: null, //  随机标签

      exchangeList: null, //交易所数据
      pairList: null, //交易对数据
      typeList: null, //交易类型数据
      transactionType: "spot", //交易类型
      exchangeName: null, //选中交易所
      exchangeId: null,
      accList: [], //资金账户
      copyAccList: [], //备份账户数据

      investmentType: null, //投入类型
      investmentNum: null, //投入资金/比例

      IsStart: false, // 是否启动订单链

      selectAccList: null, //选中的账户

      startAcc: [], //传递的账户

      contractLast: null, //合约行情价
      contrSize: null, //合约size
      controlsPrice: null,
      singleDirection: 0, // 下单方向
      investmentType: "固定投入", //投入方式
      investment: 0, //比例投入资金
      lever: 3,
      proportionMin: 0,
      proportionMax: 0,
      labelStyleShow: false, //标签为空时

      //*** */    划转按钮
      transferShow: false, //划转按钮
      transfer: false, //划转显隐
      inputOptions: null,
      currencyValue: null,
      usdtAvailable: null,
      inputStyle: null,
      twoOptions: [],
      twoValue: null,
      currencyList: [],
      topValue: null,
      topOptions: [],
      transferCoin: null, //币种
    };
  },
  computed: {},
  watch: {
    exchange(New, Old) {
      console.log(1);
      // this.$refs.child.recoveryOrderList();
    },

    // 监听投入
    investmentNum(New, old) {
      console.log(this.selectAccList);
      this.selectAccList = [];
      this.calculationAcc();
    },
    startAcc(New, old) {
      console.log(this.$refs.child.startAcc);
      this.$refs.child.startAcc = this.startAcc;
      console.log(this.$refs.child.startAcc);
    },
    accList() {
      let number = 0;
      if (this.$refs.child.controlsPrice) {
        this.accList.forEach((e) => {
          if (
            +e.available <
            this.$refs.child.investment * this.$refs.child.controlsPrice
          ) {
            number++;
          }
        });
      } else {
        this.accList.forEach((e) => {
          if (+e.available < this.$refs.child.investment) {
            number++;
          }
        });
      }

      // if (number == this.accList.length) {
      //   this.IsStart = false;
      // } else {
      //   this.IsStart = true;
      // }
    },
    currencyValue() {
      if (this.transformation == false) {
        let payload = {
          token: sessionStorage.getItem("token"),
          apiId: this.spotByApp.apiId,
          coin: this.currencyValue,
        };
        this.findFundSpot(payload);
      } else {
        let payload = {
          token: sessionStorage.getItem("token"),
          apiId: this.spotByApp.apiId,
          coin: this.currencyValue,
        };
        this.findFundAssets(payload);
      }
    },
  },

  methods: {
    //划转按钮
    transferBtn(e) {
      this.transfer = true;
      this.transformation = true;
      this.spotByApp = e;
      this.currencyValue = "USDT";
      console.log(e);
      if (e.exchangeName == "OkexV5") {
        this.topValue = "统一账户";
        this.topOptions = [{ name: "统一账户" }];
        this.twoValue = "资金账户";
        this.twoOptions = [{ name: "资金账户" }];
        this.currencyList = this.tradePair;
        this.transformation = false;
        setTimeout(() => {
          let payload = {
            token: sessionStorage.getItem("token"),
            apiId: this.spotByApp.apiId,
            coin: this.currencyValue,
          };
          this.findFundSpot(payload);
        }, 300);
      } else {
        if (this.exchangeName == "Huobi") {
          let payload = {
            token: sessionStorage.getItem("token"),
            teamProjectId: this.project.teamProjectId,
            exchangeName: this.exchangeName,
          };
          this.findContractId(payload);
        } else {
          this.topOptions = [{ name: "USDT 币币账户" }];
          this.topValue = "USDT 币币账户";
          this.twoOptions = [{ name: "USDT 合约全仓" }];
          this.twoValue = "USDT 合约全仓";
        }
        this.currencyList = ["USDT"];
        setTimeout(() => {
          let payload = {
            token: sessionStorage.getItem("token"),
            apiId: this.spotByApp.apiId,
            coin: "USDT",
          };
          this.findFundSpot(payload);
        }, 300);
      }
    },
    //获取合约可划转账户
    findFundSpot(payload) {
      findFundSpot(payload).then((res) => {
        console.log(res);
        let data = res.data;
        console.log(data);
        let available = data.available;
        if (Number(available) == 0) {
          available = 0;
        } else {
          available = available.slice(0, available.indexOf(".") + 5);
        }
        this.usdtAvailable = available;
      });
    },
    //获取可用币对合约
    findContractId(payload) {
      findContractId(payload).then((res) => {
        let data = res.data;
        let contractIdList = [];
        data.forEach((e) => {
          contractIdList.push({ name: "USDT 永续合约 " + e.contractId });
        });
        let value1 = this.twoValue;
        let value2 = this.topValue;
        if (this.transformation) {
          this.twoOptions = contractIdList;
          contractIdList.forEach((e) => {
            if (e.name.indexOf(this.transferCoin) > 0) {
              this.twoValue = e.name;
            }
          });
          this.topOptions = [{ name: "USDT 币币账户" }];
          this.topValue = "USDT 币币账户";
        } else {
          this.topOptions = contractIdList;
          contractIdList.forEach((e) => {
            if (e.name == value1) {
              this.topValue = e.name;
            }
          });
          // this.spotByApp.show = false;
          this.twoOptions = [{ name: "USDT 币币账户" }];
          this.twoValue = "USDT 币币账户";
        }
      });
    },
    twoValueChange() {
      console.log(111111);
    },
    topValueChange(e) {
      console.log(e);
      if (!this.transformation) {
        let payload = {
          token: sessionStorage.getItem("token"),
          apiId: this.spotByApp.apiId,
          exchangeName: this.spotByApp.exchangeName,
          contractId: e.split(" ")[2],
        };
        this.findContract(payload);
      }
    },
    //获取币币同步全部资产
    findContract(payload) {
      findContract(payload).then((res) => {
        if (res.status == 200) {
          if (res.data) {
            let data = res.data;
            if (Number(data.available) == 0) {
              data.available = 0;
            } else {
              data.available = Number(data.available)
                .toString()
                .slice(0, Number(data.available).toString().indexOf(".") + 5);
            }
            this.usdtAvailable = data.available;
            this.contractData = data;
          } else {
            this.usdtAvailable = 0;
          }
        }
      });
    },
    handleClose(done) {
      done();
    },
    //转换按钮
    transformationBtn() {
      this.transformation = !this.transformation;
      if (this.exchangeName == "OkexV5") {
        if (this.transformation == false) {
          this.topValue = "统一账户";
          this.topOptions = [{ name: "统一账户" }];
          this.twoValue = "资金账户";
          this.twoOptions = [{ name: "资金账户" }];
          this.currencyList = this.tradePair;
          setTimeout(() => {
            let payload = {
              token: sessionStorage.getItem("token"),
              apiId: this.spotByApp.apiId,
              coin: this.currencyValue,
            };
            this.findFundSpot(payload);
          }, 300);
        } else {
          this.twoValue = "统一账户";
          this.twoOptions = [{ name: "统一账户" }];
          this.topValue = "资金账户";
          this.topOptions = [{ name: "资金账户" }];
          let payload = {
            token: sessionStorage.getItem("token"),
            apiId: this.spotByApp.apiId,
            coin: this.currencyValue,
          };
          this.findFundAssets(payload);
        }
      } else {
        if (this.exchangeName == "Huobi") {
          let payload = {
            token: sessionStorage.getItem("token"),
            teamProjectId: this.project.teamProjectId,
            exchangeName: this.exchangeName,
          };
          this.findContractId(payload);
        } else {
          if (this.transformation) {
            this.twoOptions = [{ name: "USDT 合约全仓" }];
            this.twoValue = "USDT 合约全仓";
            this.topOptions = [{ name: "USDT 币币账户" }];
            this.topValue = "USDT 币币账户";
          } else {
            this.topOptions = [{ name: "USDT 合约全仓" }];
            this.topValue = "USDT 合约全仓";
            this.twoOptions = [{ name: "USDT 币币账户" }];
            this.twoValue = "USDT 币币账户";
          }
        }
        setTimeout(() => {
          if (!this.transformation) {
            let payload = {
              token: sessionStorage.getItem("token"),
              apiId: this.spotByApp.apiId,
              exchangeName: this.spotByApp.exchangeName,
            };
            if (this.exchangeName == "Huobi") {
              payload.contractId = this.topValue.split(" ")[2];
            } else {
              payload.contractId = this.topValue.split(" ")[0];
            }
            this.findContract(payload);
          } else {
            let payload = {
              token: sessionStorage.getItem("token"),
              apiId: this.spotByApp.apiId,
              coin: "USDT",
            };
            this.findFundSpot(payload);
          }
        }, 300);
      }
    },
    //获取项目可用币种
    getProject() {
      let payload = {
        teamProjectId: this.project.teamProjectId,
        token: sessionStorage.getItem("token"),
      };
      getProject(payload).then((res) => {
        
        let tradePair = JSON.parse(res.data.project.tradePair);
        this.tradePair = tradePair;
      });
    },

    findFundAssets(payload) {
      findFundAssets(payload).then((res) => {
        if (res.status == 200) {
          console.log(res, 1111111111);
          this.usdtAvailable = res.data.available;
        }
      });
    },
    //划转接口
    transferDetermine() {
      if (Number(this.inputOptions) <= 0) {
        return this.$message.warning("划转不能小于0");
      } else if (Number(this.inputOptions) > this.usdtAvailable) {
        return this.$message.warning("划转不能大于可用划转金额");
      }
      let payload = {};
      if (this.transformation) {
        payload = {
          token: sessionStorage.getItem("token"),
          paramStr: {
            from: "spot",
            to: "linear-swap",
            "margin-account":
              this.exchangeName == "Huobi"
                ? this.twoValue.split(" ")[2]
                : this.twoValue.split(" ")[0],
          },
        };
      } else {
        payload = {
          token: sessionStorage.getItem("token"),
          paramStr: {
            from: "linear-swap",
            to: "spot",
            "margin-account":
              this.exchangeName == "Huobi"
                ? this.topValue.split(" ")[2]
                : this.topValue.split(" ")[0],
          },
        };
      }
      console.log(this.exchangeName);
      if (this.exchangeName == "OkexV5") {
        payload.paramStr = {
          ...payload.paramStr,
          "margin-account": this.currencyValue,
        };
      }
      payload.paramStr.currency = this.currencyValue;
      payload.paramStr.amount = this.inputOptions;
      payload.apiId = this.spotByApp.apiId;
      console.log(payload);
      transfer(payload).then((res) => {
        if (res.status == 200) {
          this.transfer = false;
          this.inputOptions = "";
          this.pageNo = 1;
          this.batchShow = true;
          this.$message.success("操作成功");
          this.$emit("batchSynchSpotApi", true);
          console.log(this.transferCoin);
          setTimeout(() => {
            this.batchShow = false;
            this.queryOperatorUserList(this.transferCoin);
          }, 3000);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    //输入框校验
    inputNumber(e) {
      console.log(this.inputOptions, this.usdtAvailable);
      if (Number(this.inputOptions) <= 0) {
        this.$message.error("划转不能小于0");
        e.target.style.border = "1px solid red";
        this.inputVerification = false;
      } else if (Number(this.inputOptions) > this.usdtAvailable) {
        this.$message.error("划转不能大于可用划转金额");
        e.target.style.border = "1px solid red";
        this.inputVerification = false;
      } else {
        e.target.style.border = "1px solid #DCDFE6";
        this.inputVerification = true;
      }
    },
    inputNumberFocus(e) {
      e.target.style.border = "1px solid #DCDFE6";
    },

    /////////////////////***************///////划转按钮结束

    // 合约杠杆/方向切换传参
    leverTypeChange(
      lever,
      singleDirection,
      investmentType,
      investment,
      proportionMin,
      proportionMax
    ) {
      this.lever = lever;
      this.investmentType = investmentType;

      this.investment = Number(investment);

      this.proportionMin = proportionMin ? Number(proportionMin) : 0;
      this.proportionMax = proportionMax ? Number(proportionMax) : 0;

      if (singleDirection == "开多" || singleDirection == "开空") {
        this.singleDirection = 0;
      } else if (singleDirection == "平多") {
        this.singleDirection = 1;
      } else if (singleDirection == "平空") {
        this.singleDirection = 2;
      }
      console.log(
        lever,
        singleDirection,
        this.singleDirection,
        investmentType,
        investment,
        proportionMin,
        proportionMax
      );
      console.log(this.proportionMin, this.proportionMax);
    },

    contractSize(num) {
      let val = 0;
      console.log(this.lever, num);
      console.log(this.controlsPrice);
      if (this.transactionType != "spot" && num > 0) {
        if (this.investmentType == "固定投入") {
          val = Math.floor((num * this.lever) / this.controlsPrice);
        } else {
          if (this.investment && this.investment > 0) {
            val = Math.floor(
              (((num * this.investment) / 100) * this.lever) /
                this.controlsPrice
            );
          } else {
            // val = 0;
            val = Math.floor((num * this.lever) / this.controlsPrice);
          }
        }
      }
      return val;
    },

    // 交易类型切换
    typeChange(v) {
      console.log(v);

      if (v == "contract_usdt") {
        this.$refs.child.singleDirection = "开多";
        // 查询合约交易对
        let payload = {
          teamProjectId: this.project.teamProjectId,
          exchangeId: this.exchangeId,
          token: sessionStorage.getItem("token"),
        };
        this.$refs.child.queryOrderChainContractPair(payload);

        // 查询合约杠杆
        let params = {
          exchangeId: this.exchangeId,
          token: sessionStorage.getItem("token"),
        };

        this.$refs.child.findByIdLeverMultiple(params);
      } else {
        this.$refs.child.singleDirection = "买入";
        this.$refs.child.queryPair(this.exchangeList[0].exchangeId);
      }

      this.$refs.child.actionIdx = 0;
      this.$refs.child.orderIndex = 0;
      // this.$store.commit("setOrderChainType", v);
      // console.log(v, this.$store.state.orderChainType);
    },

    // 获取随机标签
    async queryOrderLabel() {
      let payload = {
        token: sessionStorage.getItem("token"),
      };
      queryOrderLabel(payload).then((res) => {
        if (res.status == 200) {
          this.orderLabel = res.data.orderLabel
            ? res.data.orderLabel.label
            : null;
        }
      });
    },

    // 查询交易所/交易对
    async queryProjectData() {
      let payload = {
        token: sessionStorage.getItem("token"),
        teamProjectId: this.project.teamProjectId,
      };
      queryProjectData(payload).then((res) => {
        if (res.status == 200) {
          console.log(res);
          console.log("交易所", JSON.parse(res.data.resultMap.exchangeLimit));
          console.log("交易对", JSON.parse(res.data.resultMap.tradePair));
          console.log("类型", JSON.parse(res.data.resultMap.spotOrContract));
          let list = JSON.parse(res.data.resultMap.spotOrContract);
          let newList = [];
          for (let key of list) {
            let item = {};
            if (key == "spot") {
              item.value = "spot";
              item.label = "币币";
            } else if (key == "contract_usdt") {
              item.value = "contract_usdt";
              item.label = "合约-USDT永续";
            }
            newList.push(item);
          }
          this.exchangeList = JSON.parse(res.data.resultMap.exchangeLimit);
          console.log(this.exchangeList);

          if (this.startAcc.length != 0) {
            for (let key of this.exchangeList) {
              if (key.exchangeName == this.startAcc[0].exchangeName) {
                this.exchangeName = key.exchangeName; //默认交易所
                this.exchangeId = key.exchangeId; //默认交易所
              }
            }
          } else {
            this.exchangeName = this.exchangeList[0].exchangeName; //默认交易所
            this.exchangeId = this.exchangeList[0].exchangeId; //默认交易所
          }

          this.pairList = JSON.parse(res.data.resultMap.tradePair);

          this.typeList = newList;

          this.$refs.child.queryPair(this.exchangeList[0].exchangeId);
        }
      });
    },

    // 切换交易所
    checkExchange(v) {
      console.log(v);
      this.$refs.child.orderList = [];
      let list = this.exchangeList;
      for (let key of list) {
        if (key.exchangeId == v) {
          this.exchangeId = key.exchangeId;
          this.exchangeName = key.exchangeName;
        }
      }
      if (this.transactionType == "spot") {
        this.$refs.child.queryPair(this.exchangeId);
      } else {
        let payload = {
          teamProjectId: this.project.teamProjectId,
          exchangeId: this.exchangeId,
          token: sessionStorage.getItem("token"),
        };
        this.$refs.child.queryOrderChainContractPair(payload);
      }

      let newlist = JSON.parse(JSON.stringify(this.copyAccList));
      let accList = [];
      for (let key of newlist) {
        if (key.exchangeName == this.exchangeName) {
          accList.push(key);
        }
      }
      this.accList = accList;
      this.selectAccList = [];
      this.IsStart = false;

      if (this.transactionType == "spot") {
        this.$refs.child.singleDirection = "买入";
      } else {
        this.$refs.child.singleDirection = "开多";
      }

      console.log(this.$refs.child.orderList);
    },

    //查询账户
    async queryOperatorUserList(investCoin) {
      console.log(investCoin);
      this.transferCoin = investCoin;

      console.log(investCoin, this.transactionType);

      let payload = {
        token: sessionStorage.getItem("token"),
        exchangeName: this.exchangeName,
        // investCoin: investCoin,
        teamProjectId: this.project.teamProjectId,
        transactionType: this.transactionType,
      };

      if (this.transactionType == "spot") {
        payload.investCoin = investCoin;
      } else {
        payload.contractId = investCoin;
        payload.counterCoin = this.$refs.child.contPair.counterCoin;
        console.log(this.$refs.child.contPair);
      }

      queryOperatorUserList(payload).then((res) => {
        if (res.status == 200) {
          console.log("账户", res.data.operatorUserList);

          if (res.data.last) {
            this.contractLast = Number(res.data.last);
            this.contrSize = Number(res.data.contractSize);
            this.controlsPrice = Number(res.data.price);
          }

          if (res.data.operatorUserList) {
            this.copyAccList = JSON.parse(
              JSON.stringify(res.data.operatorUserList)
            );

            let newlist = JSON.parse(JSON.stringify(this.copyAccList));
            let accList = [];
            for (let key of newlist) {
              if (key.exchangeName == this.exchangeName) {
                accList.push(key);
              }
            }
            this.accList = accList;

            if (this.startAcc.length !== 0) {
              let list = this.startAcc;
              let apiId = [];
              for (let key of list) {
                apiId.push(key.apiId);
              }
              console.log(apiId);
              let newApiList = [];
              for (let i = 0; i < accList.length; i++) {
                for (let key of apiId) {
                  if (accList[i].apiId == key) {
                    newApiList.push(accList[i]);
                  }
                }
              }
              console.log(newApiList);
              this.accList = newApiList;
            }

            console.log(this.accList);
          } else {
            this.accList = [];
          }

          console.log("合约行情价", this.contractLast);
        }
      });
      // }

      // else {
      //   let list = this.startAcc;

      //   let apiId = [];
      //   for (let key of list) {
      //     apiId.push(key.apiId);
      //   }
      //   let payload = {
      //     token: sessionStorage.getItem("token"),
      //     investCoin: investCoin,
      //     apiId: apiId.join(","),
      //   };

      //   findByapiIdCoin(payload).then((res) => {
      //     if (res.status == 200) {
      //       console.log("账户", res.data);

      //       this.copyAccList = JSON.parse(JSON.stringify(res.data.map));
      //       console.log(this.copyAccList);
      //       let newlist = JSON.parse(JSON.stringify(this.copyAccList));
      //       let accList = [];
      //       for (let key of newlist) {
      //         if (key.exchangeName == this.exchangeName) {
      //           accList.push(key);
      //         }
      //       }
      //       this.accList = accList;
      //     }
      //   });
      // }
    },

    // 选择账户触发
    accChange(v) {
      let list = this.accList;
      let newList = [];
      for (let key of list) {
        if (key.isCheck) {
          newList.push(key);
        }
      }
      newList.length > 0 ? (this.IsStart = true) : (this.IsStart = false);
      this.selectAccList = newList;
    },

    // 子组件传入投入类型和金额以及是否有错误
    childTrigger(type, val) {
      this.investmentType = type;
      this.investmentNum = val;
      console.log(type, val);
    },

    // 计算可用账户
    calculationAcc() {
      // let list = JSON.parse(JSON.stringify(this.copyAccList));
      let num = this.investmentNum;
      // let newList = [];
      if (this.investmentType == "固定投入") {
        //修改输入内容后不取消选中
        let lists = JSON.parse(JSON.stringify(this.accList));
        lists.forEach((e) => {
          if (Number(e.available) >= num) {
            e.show = false;
          }
          if (Number(e.available) < num) {
            e.show = true;
          }
        });
        console.log(lists);
        this.accList = JSON.parse(JSON.stringify(lists));
        this.selectAccList = JSON.parse(JSON.stringify(lists));
        // for (let key of list) {
        //   if (Number(key.available) >= num) {
        //     key.show = false;
        //     newList.push(key);
        //   }
        // }
        // for (let key of list) {
        //   if (Number(key.available) < num) {
        //     key.show = true;
        //     newList.push(key);
        //   }
        // }
        // this.accList = newList;
      } else {
        // 比例投入
        console.log(1);
        this.accList = JSON.parse(JSON.stringify(this.copyAccList));
      }
    },

    // // 查询交易所/币对精度
    async queryFeeAndAccuracy(exchangeId, pairId) {
      let payload = {
        exchangeId: exchangeId,
        pairId: pairId,
      };

      queryFeeAndAccuracy(payload).then((res) => {
        if (res.status == 200) {
          console.log(res.data);
          this.pairData = res.data;
        }
      });
    },

    // 启动订单链
    async orderStart() {
      if (!this.investmentNum) {
        this.$message({
          message: "投入不能为0或空",
          type: "warning",
        });
        return;
      } else if (!this.IsStart) {
        this.$message({
          message: "请先选择账户",
          type: "warning",
        });
        return;
      } else if (this.investmentNum < 0) {
        this.$message({
          message: "投入资金不能小于0",
          type: "warning",
        });
        return;
      } else if (!this.orderLabel) {
        this.$message.error("订单标签不能为空");
        return;
      }

      let payload = {
        token: sessionStorage.getItem("token"),
        teamProjectId: this.project.teamProjectId,
        label: this.orderLabel,
        exchangeName: this.exchangeName,
        transactionType: this.transactionType,

        // 'spot':'contract_usdt',
      };

      console.log(payload);

      // 拿交易所ID
      let exchangeList = this.exchangeList;
      for (let key of exchangeList) {
        if (this.exchangeName == key.exchangeName) {
          payload.exchangeId = key.exchangeId;
        }
      }

      //订单链配置  orderChain

      // 第一个订单参数动作保存
      this.$refs.child.firstOrderAction();
      let orderList = this.$refs.child.orderList;
      console.log(orderList);
      let paramsCheck = [];

      // 动作检测
      for (let key of orderList) {
        console.log(orderList);

        paramsCheck.push({ name: key.name });
        // 动作列表检测
        if (key.actionList == 0) {
          this.$message.error(key.name + "动作列表为空，请重新修改");
          return;
        }

        let next = false;
        for (let item of key.actionList) {
          if (item.percentage == "") {
            this.$message.error(
              key.name + "-" + item.label + "动作-" + "下单资金占比不能为空"
            );
            return;
          } else if (Number(item.percentage) > 100) {
            this.$message.error(
              key.name + "-" + item.label + "动作-" + "下单资金占比不能大于100%"
            );
            return;
          } else if (Number(item.percentage) < 1) {
            this.$message.error(
              key.name + "-" + item.label + "动作-" + "下单资金占比不能低于1%"
            );
            return;
          }

          if (item.isCheck) {
            next = true;
          }
          for (let cont of JSON.parse(item.params).controls) {
            console.log(cont);
            if (
              !cont.value &&
              !cont.disabled &&
              cont.controltype != "multi-switch" &&
              !cont.visiable != "false" &&
              cont.controltype != "signal-input" &&
              cont.visiable != "false"
            ) {
              this.$message.error(
                key.name +
                  "-" +
                  item.label +
                  "动作-" +
                  cont.label +
                  "-参数不能为空，请重新修改"
              );
              return;
            }
            //  else if (
            //   cont.min &&
            //   cont.max &&
            //   Number(cont.max) < Number(cont.value)
            // ) {
            //   this.$message.error(
            //     key.name +
            //       "-" +
            //       item.label +
            //       "动作" +
            //       "参数不能大于最大范围，请重新修改"
            //   );
            //   return;
            // }
            else if (
              cont.min &&
              cont.max &&
              Number(cont.min) > Number(cont.value)
            ) {
              this.$message.error(
                key.name +
                  "-" +
                  item.label +
                  "动作" +
                  "参数不能小于最小范围，请重新修改"
              );
              return;
              // 信号单
            } else if (cont.controltype == "signal-input" && !cont.signal) {
              this.$message.error(
                key.name + "-" + item.label + "动作" + "请选择触发信号"
              );
              value;
              return;
            } else if (
              cont.controltype == "signal-input" &&
              !cont.signalAttribute
            ) {
              this.$message.error(
                key.name + "-" + item.label + "动作" + "请选择信号属性"
              );
              return;
            } else if (cont.controltype == "signal-input" && !cont.value) {
              this.$message.error(
                key.name + "-" + item.label + "动作" + "阈值不能为空"
              );
              return;
            }
          }
        }
        // 动作开启检测
        if (!next) {
          this.$message.error(key.name + "至少需要开启一个动作，请重新修改");
          return;
        }
      }

      let orderChain = [];

      for (let key of orderList) {
        console.log("key", key);
        let item = {};

        item.displayConfig = JSON.stringify(key);

        if (this.transactionType == "spot") {
          item.transactionPair = key.pair.counterCoin + "/" + key.pair.baseCoin;
          item.orderDirection = key.singleDirection == "买入" ? "buy" : "sell";
          item.pairId = key.pair.id;
        } else {
          item.transactionPair = key.contractPair;
          item.pairId = key.contractPair;
          switch (key.singleDirection) {
            case "开多":
              item.orderDirection = "open_many";
              break;
            case "开空":
              item.orderDirection = "open_empty";
              break;
            case "平多":
              item.orderDirection = "flat_many";
              break;
            case "平空":
              item.orderDirection = "flat_empty";
              break;
            default:
              break;
          }
          // item.orderDirection=
        }

        item.investType = key.investmentType == "比例投入" ? 2 : 1;
        item.investNum = key.investment ? key.investment : ""; //投入金额
        // item.investNum = key.investment ? key.investment : ""; //投入金额
        item.investUnit = key.inputCurrency; //投入币种
        item.orderSort = key.sort;
        item.leverRate = key.leverRate;
        item.orderType = "1";

        //传唐成的数据
        let executeConfig = {};
        let tradePair = {};
        tradePair.type = this.transactionType; //spot->现货, contract_usdt->U本位永续合约
        tradePair.exchangeName = this.exchangeName;
        tradePair.investCoin = key.inputCurrency; //投入币种

        console.log(this.transactionType, key);
        if (this.transactionType == "spot") {
          let payload = {
            token: sessionStorage.getItem("token"),
            exchangeId: this.exchangeId,
            pairId: key.pair.id,
          };
          console.log(payload);

          var res = await queryFeeAndAccuracy(payload);
          console.log(res);
          let exchangeFee = res.data.exchangeFee;
          let pairAccuracy = res.data.pairAccuracy;

          // 现货信息
          tradePair.buyFee = exchangeFee.buyFee; // 买入手续费
          tradePair.soldFee = exchangeFee.soldFee; // 卖出手续费
          tradePair.counterCoin = pairAccuracy.counterCoin; // 交易币种
          tradePair.counterCoinDecimal = pairAccuracy.counterCoinDecimal; // 交易币种精度
          tradePair.baseCoin = pairAccuracy.baseCoin; // 计价币种
          tradePair.baseCoinDecimal = pairAccuracy.baseCoinDecimal; // 计价币种精度
          if (key.inputCurrency == key.pair.baseCoin) {
            tradePair.baseCoinAmount = key.investment
              ? key.investment + ""
              : "";
            tradePair.counterCoinAmount = "0";
          } else {
            tradePair.baseCoinAmount = "0";
            tradePair.counterCoinAmount = key.investment
              ? key.investment + ""
              : "";
          }
        } else {
          // 合约信息
          tradePair.contractId = key.contractPair;

          tradePair.size = key.investment;
          tradePair.leverRate = key.leverRate; // 杠杆倍数

          if (key.singleDirection == "开多") {
            tradePair.tradeOffset = "1"; // 多空方向， 0-做空  1-做多
            tradePair.tradeType = "1"; //0 卖 ,1 买
          } else if (key.singleDirection == "开空") {
            tradePair.tradeOffset = "1";
            tradePair.tradeType = "0"; //0 卖 ,1 买
          } else if (key.singleDirection == "平多") {
            tradePair.tradeOffset = "0";
            tradePair.tradeType = "0"; //0 卖 ,1 买
          } else if (key.singleDirection == "平空") {
            tradePair.tradeOffset = "0";
            tradePair.tradeType = "1"; //0 卖 ,1 买
          }
        }
        executeConfig.tradePair = tradePair;
        // 下单动作集合
        executeConfig.actions = [];
        for (let info of key.actionList) {
          console.log(info);
          // 动作是否开启
          if (info.isCheck) {
            let actionsItem = {
              name: info.name,
              investRate: info.percentage,
            };

            // 添加动作参数
            let list = JSON.parse(info.params).controls;
            let params = [];
            for (let cont of list) {
              console.log(cont);

              if (
                cont.build != false &&
                cont.controltype != "multi-switch" &&
                cont.controltype != "signal-input"
              ) {
                let val = {};
                val.name = cont.name;
                val.value = cont.value ? cont.value : null;
                val.valueType = cont.valueType;
                params.push(val);
              } else if (cont.controltype == "multi-switch") {
                let val = {};
                val.name = cont.name;
                val.value = cont.proposed;
                val.valueType = cont.valueType;
                params.push(val);
                console.log(cont);
              } else if (cont.controltype == "signal-input") {
                console.log(cont);
                let val = {};
                val.name = cont.name;
                val.valueType = cont.valueType;
                val.value = {
                  signalId: cont.signal.signalId,
                  attrName: cont.signalAttribute.name,
                  opt: cont.signalOpt,
                  value: cont.value,
                };

                switch (cont.signalOpt) {
                  case "=":
                    val.value.opt = "eq";
                    break;
                  default:
                    break;
                }

                params.push(val);
              }
            }
            if (params.length > 0) {
              actionsItem.params = params;
            }
            executeConfig.actions.push(actionsItem);
          }
        }
        console.log("executeConfig", executeConfig);
        item.executeConfig = JSON.stringify(executeConfig);
        // item.executeConfig = executeConfig
        orderChain.push(item);
      }
      if (this.transactionType == "spot") {
        payload.investCoin = orderChain[0].investUnit;
      }
      orderChain.reverse();

      console.log("orderChain", orderChain);

      payload.orderChain = orderChain;

      // 拿账户数据
      // 投入校验

      let selectAccList = this.selectAccList;
      console.log(this.selectAccList);
      if (
        this.transactionType == "spot" &&
        orderChain[orderChain.length - 1].investType == "1"
      ) {
        for (let i = 0; i < selectAccList.length; i++) {
          if (
            Number(selectAccList[i].available) <
            orderChain[orderChain.length - 1].investNum
          ) {
            selectAccList.splice(i, 1);
          }
        }
      } else if (
        this.transactionType != "spot" &&
        orderChain[orderChain.length - 1].orderDirection == "open_many"
      ) {
        for (let i = 0; i < selectAccList.length; i++) {
          if (
            Math.floor(
              (Number(selectAccList[i].available) * Number(this.lever)) /
                Number(this.controlsPrice)
            ) < orderChain[orderChain.length - 1].investNum
          ) {
            selectAccList.splice(i, 1);
          }
        }
      } else if (
        this.transactionType != "spot" &&
        orderChain[orderChain.length - 1].orderDirection == "flat_many"
      ) {
        for (let i = 0; i < selectAccList.length; i++) {
          if (
            selectAccList[i].buy &&
            Number(selectAccList[i].buy) <
              orderChain[orderChain.length - 1].investNum
          ) {
            selectAccList.splice(i, 1);
          }
        }
      } else if (
        this.transactionType != "spot" &&
        orderChain[orderChain.length - 1].orderDirection == "flat_empty"
      ) {
        for (let i = 0; i < selectAccList.length; i++) {
          if (
            selectAccList[i].sell &&
            Number(selectAccList[i].sell) <
              orderChain[orderChain.length - 1].investNum
          ) {
            selectAccList.splice(i, 1);
          }
        }
      }

      if (selectAccList.length == 0) {
        this.$message.error("没有符合条件下单用户");
        return;
      }

      let userStr = [];
      for (let key of selectAccList) {
        let accItem = {};
        accItem.publicPeopleId = key.id;
        accItem.userId = key.userId;
        accItem.publicPeopleName = key.publicCode;
        accItem.apiId = key.apiId;
        accItem.remarkCode = key.remarkCode;

        if (this.transactionType != "spot") {
          if (orderChain[orderChain.length - 1].investType == "2") {
            accItem.proportionalInvestNum = this.contractSize(key.available);
          }
        } else {
          if (orderChain[orderChain.length - 1].investType == "2") {
            accItem.proportionalInvestNum =
              Math.floor(
                Number(
                  (orderChain[orderChain.length - 1].investNum *
                    Number(key.available)) /
                    100
                ) * 1000000
              ) / 1000000;
          }
        }
        userStr.push(accItem);
      }
      console.log(userStr);

      payload.userStr = JSON.stringify(userStr);

      if (payload.transactionType != "spot") {
        payload.transactionPair = orderChain[orderChain.length - 1].pairId;
      }
      console.log(payload);
      console.log(JSON.parse(payload.orderChain[0].displayConfig));

      batchCreateOrderChain(payload).then((res) => {
        if (res.status == 200) {
          if (res.data.errorMap) {
            let errorMap = res.data.errorMap;
            for (let i in errorMap) {
              return this.$message.error(errorMap[i]);
            }
          }
          console.log(res.data);
          this.$message.success("操作成功");
          sessionStorage.setItem("projectActive", "2-2");
          sessionStorage.setItem("tableActive", this.transactionType);
          this.$emit("childFn", "2-2");
        } else {
          this.$message.error(res.msg);
        }
      });
    },

    queryOrderChainList(payload) {
      queryOrderChainList(payload).then((res) => {
        if (res.status == 200) {
          console.log("````````````````````", res.data);
        }
      });
    },
  },
  created() {
    let startAcc = JSON.parse(sessionStorage.getItem("startAcc"));
    let project = JSON.parse(sessionStorage.getItem("project"));
    this.project = project;

    if (startAcc) {
      console.log(startAcc);

      this.startAcc = startAcc;

      if (startAcc[0].contract) {
        this.transactionType = "contract_usdt";
      }
    }
  },
  mounted() {
    this.queryOrderLabel();

    this.queryProjectData();

    let payload = {
      token: sessionStorage.getItem("token"),
      teamProjectId: this.project.teamProjectId,
      pageSize: 10,
      pageNo: 1,
    };
    this.queryOrderChainList(payload);
  },
  beforeCreate() {},
  beforeMount() {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {
    sessionStorage.removeItem("startAcc");
    this.$destroy();
  },
  activated() {},
};
</script>
<style lang='scss' scoped>
//@import url(); 引入公共css类
.createOrderChain {
  // width: 100vw;
  // 头部搜索
  .top-select {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background: #fff;
    border-radius: 8px;
    .select-label,
    .select-exchange,
    .select-type {
      width: 30%;
      height: 42px;
      display: flex;
      align-items: center;
      .el-input,
      .el-select {
        width: 80%;
      }
      .el-input {
        ::v-deep.el-input__suffix {
          top: 12px;
          right: 10px;
          cursor: pointer;
        }
      }
      .icon-suiji {
        width: 10px;
        height: 10px;
        cursor: pointer;
      }
    }
    .labelStyle {
      border: 1px solid red;
      border-radius: 5px;
    }
  }

  //   内容
  .mian-cont {
    width: 100%;
    min-width: 1190px;
    margin-top: 10px;
    display: flex;
    color: #102859;

    .create {
      display: flex;
      flex: 1;
      width: 80%;
    }

    // 右  账户
    .account {
      background: #ffffff;
      box-shadow: 0px 11px 22px 0px rgba(109, 147, 176, 0.08);
      border-radius: 8px;
      margin-left: 10px;
      padding: 20px;
      position: relative;
      width: 20%;
      .account-title {
        width: 100%;
        font-size: 16px;
        color: #102859;
        padding-bottom: 20px;
        border-bottom: 1px solid #e6e9ee;
        :first-child {
          color: #e12525;
        }
      }

      .acc-warp {
        margin-top: 20px;
        .acc-box {
          display: flex;
        }
        .acc-info {
          flex: 1;
          display: flex;
          flex-direction: column;
          height: 62px;
          border: 1px solid #e6e9ee;
          justify-content: space-between;
          padding: 10px;
          color: #102859;
          font-size: 12px;
          margin-bottom: 20px;
          .acc-name {
            font-size: 14px;
            display: flex;
            justify-content: space-between;
          }
          .acc-box {
            display: flex;
          }
        }

        .acc-boxBtn {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0 10px;
          color: #2174ff;
          cursor: pointer;
          margin-bottom: 20px;
        }
      }

      .btn-warp {
        // width: 390px;
        width: 90%;
        height: 34px;
        line-height: 34px;
        text-align: center;
        background: #e6e9ee;
        border-radius: 4px;
        font-size: 14px;
        color: #55698a;
        cursor: pointer;
        position: absolute;
        left: 20px;
        bottom: 20px;
      }
      .on {
        background: #2174ff;
        color: #fff;
      }
      .empty {
        width: 100%;
        text-align: center;
        font-size: 14px;
        color: #758db5;
        margin-top: 160px;
        display: flex;
        flex-direction: column;
        align-items: center;
        img {
          width: 185px;
          height: 165px;
          margin-bottom: 20px;
        }
      }
    }
  }
}
/deep/.el-checkbox__inner {
  border-radius: 100%;
}

.dialog-nav {
  color: #55698a;
  padding: 20px 0 30px;
  font-size: 12px;
  .dialog-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .dialog-img {
      width: 36px;
      height: 36px;
      cursor: pointer;
      margin: 0 20px 20px 0;
    }
    .dialog-top {
      font-size: 16px;
      margin-bottom: 20px;
      color: #102859;
    }
  }
  .dialog-currency {
    display: flex;
    flex-direction: column;
    font-size: 16px;
    span {
      margin-bottom: 10px;
    }
  }
}
.dialog-footer {
  display: flex;
  justify-content: flex-end;
  div {
    width: 72px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    cursor: pointer;
  }
  .footerBack {
    border: 1px solid #c2d9ff;
    color: #2174ff;
  }
  .footerBtn {
    color: #ffffff;
    background: #2174ff;
    border: 1px solid #c2d9ff;
    margin-left: 12px;
  }
}
.overflowBox {
  height: 590px;
  overflow-y: scroll;
}
</style>