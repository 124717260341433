<template>
  <div class="projectInformation">
    <div class="content-Project-Info">
      <div class="project-type" v-if="projectInfo">
        <span>{{$t('m.项目信息')}}</span>
        <span class="type-under-review" v-if="projectInfo.status == 'notOnline'">
          {{$t('m.未上线')}}</span>
        <span class="type-under-review" v-if="projectInfo.status == 'review'">
          {{$t('m.审核中')}}</span>
        <span class="type-under-review" v-if="projectInfo.status == 'launched'">
          {{$t('m.已上线')}}</span>
        <span
          class="type-under-review"
          v-if="projectInfo.status == 'reviewFail'">
          {{$t('m.审核失败')}}</span>
        <span
          class="type-under-review"
          v-if="projectInfo.status == 'settlement'">
          {{$t('m.结算中')}}</span>
        <span class="type-under-review" v-if="projectInfo.status == 'offlined'">
          {{$t('m.已下线')}}</span>
        <span
          class="type-under-review"
          v-if="projectInfo.status == 'suspendGold'">
          {{$t('m.暂停入金')}}</span>
      </div>
      <div class="project-name">
        <div>
          <span>{{$t('m.项目名称')}}</span>
          <span class="title-remark">{{$t('m.限6个字符')}}</span>
        </div>
        <el-input v-model="projectName" :placeholder="$t('m.请输入项目名称')"></el-input>
      </div>
      <div class="project-brief-introduction">
        <div>
          <span>
            <span>{{$t('m.项目简介')}}</span>
            <span class="tips">({{$t('m.项目简介内容在上架时需提交审核')}})</span>
          </span>
          <span class="title-remark">{{$t('m.限20个字符')}}</span>
        </div>
        <el-input
          type="textarea"
          :autosize="{ minRows: 4, maxRows: 6 }"
          :placeholder="$t('m.请输入项目简介')"
          v-model="projectIntroduct"
          resize="none"
          :maxlength="20"
        >
        </el-input>
      </div>
      <div class="btn-warp">
        <span class="btn" @click="modifyProjectInfo">{{$t('m.提 交')}}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { addProject } from "../../request/api";
export default {
  components: {},
  data() {
    return {
      projectName: null, // 项目名称
      projectIntroduct: null, //项目简介
      projectInfo: null,
      defaultTeam: null,
    };
  },
  computed: {},
  watch: {},
  methods: {
    // 修改项目信息
    modifyProjectInfo() {
      if (!this.projectName) {
        this.$message.error(this.$t('m.项目名称不能为空'));
        return;
      }
      let payload = {
        teamId: this.defaultTeam.teamId,
        token: sessionStorage.getItem("token"),
        projectName: this.projectName,
        projectIntroduct: this.projectIntroduct,
        teamProjectId: this.projectInfo.teamProjectId,
      };
      addProject(payload).then((res) => {
        if (res.status == 200) {
          this.$message.success(this.$t('m.修改成功'));
          this.$emit("getProject");
          console.log(res.data);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
  created() {},
  mounted() {
    let defaultTeam = sessionStorage.getItem("defaultTeam");
    this.defaultTeam = JSON.parse(defaultTeam);
    let projectInfo = JSON.parse(sessionStorage.getItem("projectInfo"));
    if (projectInfo) {
      // let projectInfo = JSON.parse(sessionStorage.getItem("projectInfo"));
      this.projectInfo = projectInfo;
      this.projectName = projectInfo.projectName;
      this.projectIntroduct = projectInfo.projectIntroduct;
    } else {
      setTimeout(() => {
        let projectInfo = JSON.parse(sessionStorage.getItem("projectInfo"));
        this.projectInfo = projectInfo;
        this.projectName = projectInfo.projectName;
        this.projectIntroduct = projectInfo.projectIntroduct;
      }, 1000);
    }
  },
  beforeCreate() {},
  beforeMount() {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {},
  activated() {},
};
</script>
<style lang='scss' scoped>
.projectInformation {
  // 项目信息
  .content-Project-Info {
    padding-right: 20%;
    .project-type {
      display: flex;
      align-items: center;
      font-size: 14px;
      .type-under-review {
        width: 80px;
        height: 20px;
        font-size: 12px;
        line-height: 20px;
        text-align: center;
        background: #007aff;
        border-radius: 11px;
        color: #fff;
        margin: 0 20px;
      }
      .cancel-audit {
        font-size: 12px;
        color: #007aff;
      }
    }
    .project-name,
    .project-brief-introduction {
      font-size: 14px;
      margin-top: 30px;
      div {
        display: flex;
        justify-content: space-between;
        margin-top: 8px;
        .title-remark {
          font-size: 12px;
          color: #999;
        }
        .tips {
          font-size: 12px;
          color: #999;
          margin-left: 8px;
        }
      }
    }
    .btn-warp {
      display: flex;
      justify-content: flex-end;
      margin-top: 60px;
      .btn {
        color: #fff;
        background: #007aff;
        font-size: 14px;
        padding: 5px 20px;
        border-radius: 5px;
        cursor: pointer;
      }
    }
  }
}
</style>