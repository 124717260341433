<template>

  <!-- 高级设置 -->
  <div class="advancedSetting">
    <div class="nav">
      <div class="navItem">
        <div class="setting">
          <div class="setting-title">
            <span>{{$t('m.配置设置')}}</span>
            <span class="title-remarks">({{$t('m.正式上线后不可修改')}})</span>
          </div>
          <div class="account">
            <div class="account-box">
              <div>
                <span>{{$t('m.账户')}}</span>
                <span class="title-remarks">({{$t('m.暂时只支持币币')}})</span>
              </div>
              <div class="account-inp">
                <el-input
                  v-model="account"
                  :placeholder="$t('m.请输入内容')"
                  :readonly="true"
                ></el-input>
              </div>
            </div>
            <div class="account-box">
              <div>
                <span>{{$t('m.投入币种限制')}}</span>
                <span class="title-remarks">({{$t('m.暂时只支持USDT投入')}})</span>
              </div>
              <div class="account-inp">
                <el-input
                  v-model="symbol"
                  :placeholder="$t('m.请输入内容')"
                  :readonly="true"
                ></el-input>
              </div>
            </div>
            <div class="account-box">
              <div>
                <span>{{$t('m.投入资金限制')}}</span>
                <span class="title-remarks">{{$t('m.范围')}}：1000 ~ 200000</span>
              </div>
              <div
                class="account-inp"
                style="display: flex; justify-content: center"
              >
                <el-input
                  v-model="min"
                  :placeholder="$t('m.请输入内容')"
                  :readonly="
                    showStatus === $t('m.未上线') && status !== $t('m.结算中')
                      ? false
                      : true
                  "
                ></el-input>
                <span class="account-icon"> - </span>
                <el-input
                  v-model="max"
                  :placeholder="$t('m.请输入内容')"
                  :readonly="
                    showStatus === $t('m.未上线') && status !== $t('m.结算中')
                      ? false
                      : true
                  "
                ></el-input>
              </div>
            </div>
            <div class="account-box">
              <div>{{$t('m.交易所限制')}}</div>
              <div class="account-inp">
                <el-select
                  v-model="ExchangeRestrictions"
                  value-key="exchangeName"
                  :placeholder="$t('m.请选择')"
                  style="width: 100%"
                  filterable
                  multiple
                  @remove-tag="changeTag"
                  @change="exchange"
                  :disabled="
                    showStatus === $t('m.未上线') && status !== $t('m.结算中')
                      ? false
                      : true
                  "
                >
                  <el-option
                    v-for="item in exchangeList"
                    :key="item.exchangeId"
                    :label="item.exchangeName"
                    :value="item.exchangeId"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
            <div class="account-box">
              <div>
                <span>{{$t('m.交易对限制')}}</span>
                <span class="title-remarks"
                  >({{$t('m.最多支持20个币对，项目上线后不可修改，请谨慎选择')}})</span
                >
              </div>
              <div class="account-inp">
                <el-select
                  v-model="pairTags"
                  value-key="counterCoin"
                  :placeholder="$t('m.请选择')"
                  filterable
                  multiple
                  style="width: 100%"
                  :multiple-limit="multipleLimit"
                  @remove-tag="pairTag"
                  @change="pairchange"
                  :disabled="
                    showStatus === $t('m.未上线') && status !== $t('m.结算中')
                      ? false
                      : true
                  "
                >
                  <el-option
                    v-for="(item, idx) in pairList"
                    :key="idx"
                    :label="item.counterCoin + '/' + item.baseCoin"
                    :value="item.counterCoin"
                    style="width: 100%"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
          </div>
        </div>
        <span style="border-left: 1px solid #dcdde3; margin: 0 50px"></span>
        <div class="price">
          <p>{{$t('m.佣金形式')}}</p>
          <div class="price-box">
            <div>
              <div>
                <el-checkbox
                  style="margin: 20px 0 15px; color: #313237"
                  v-model="profitSharing"
                  disabled
                  >{{$t('m.盈利分成(必填)')}}</el-checkbox
                >
              </div>
              <div class="price-ratio">

                <span>{{$t('m.用户 盈利')}} &gt</span>
                <el-input
                  v-model="profitRatio"
                  :placeholder="$t('m.请输入')"
                  type="number"
                  :disabled="
                    showStatus === $t('m.未上线') && status !== $t('m.结算中')
                      ? false
                      : true
                  "
                  style="width: 70px; margin: 0 3px"
                  :readonly="showStatus == 'online'"
                  class="no-number">
                  <i class="iconStyle" slot="suffix">%</i>
                </el-input>
                <span>{{$t('m.的部分，要求分成比例')}}：</span>

                <el-input
                  v-model="profitSharingRatio"
                  :placeholder="$t('m.请输入')"
                  type="number"
                  :disabled="
                    showStatus === $t('m.未上线') && status !== $t('m.结算中')
                      ? false
                      : true
                  "
                  style="width: 70px"
                  :readonly="showStatus == 'online'"
                >
                  <i class="iconStyle" slot="suffix">%</i>
                </el-input>
              </div>
            </div>
            <div class="share-margin">
              <div style="margin-top: 24px">
                <p style="margin-bottom: 8px">{{$t('m.每次分成上限')}}</p>
                <el-input
                  :placeholder="$t('m.请输入')"
                  v-model="shareCeiling"
                  type="number"
                  :disabled="
                    showStatus === $t('m.未上线') && status !== $t('m.结算中')
                      ? false
                      : true
                  "
                  :readonly="showStatus == 'online'"
                >
                  <template slot="append">VTN</template>
                </el-input>
              </div>
              <div style="margin-top: 24px">
                <p style="margin-bottom: 8px">{{$t('m.最小可用保证金要求')}}</p>
                <el-input
                  :placeholder="$t('m.请输入')"
                  v-model="minBond"
                  type="number"
                  :disabled="
                    showStatus === $t('m.未上线') && status !== $t('m.结算中')
                      ? false
                      : true
                  "
                  :readonly="showStatus == 'online'"
                >
                  <template slot="append">VTN</template>
                </el-input>
              </div>
            </div>
            <div class="promise">
              <div style="margin: 40px 0 20px">
                <el-checkbox
                  v-model="riskIndemnityCommentShow"
                  :disabled="
                    showStatus === $t('m.未上线') && status !== $t('m.结算中')
                      ? false
                      : true
                  "
                  >{{$t('m.风险赔付承诺（选填）')}}</el-checkbox
                >
              </div>
              <div class="promisePay">

                <span>{{$t('m.当用户 亏损')}} &gt</span>
                <el-input
                  v-model="lossRatio"
                  type="number"
                  style="width: 70px; margin: 0 3px"
                  :readonly="
                    showStatus === $t('m.未上线') && status !== $t('m.结算中')
                      ? false
                      : true
                  "
                >
                  <i class="iconStyle" slot="suffix">%</i>
                </el-input>
                <span>{{$t('m.的部分，承诺赔偿比例')}}：</span>
                <el-input
                  v-model="IndemnityRatio"
                  type="number"
                  style="width: 70px; margin: 0 3px"
                  :readonly="
                    showStatus === $t('m.未上线') && status !== $t('m.结算中')
                      ? false
                      : true
                  "
                >
                  <i class="iconStyle" slot="suffix">%</i>
                </el-input>
              </div>
              <div class="promisePay-time">
                <div class="title">
                  <span>{{$t('m.赔付承诺生效时间')}}</span>
                  <el-tooltip
                    class="item"
                    effect="light"
                    :content="tipsThree"
                    placement="top-start"
                  >
                    <img
                      style="
                        width: 15px;
                        height: 15px;
                        margin-left: 5px;
                        cursor: pointer;
                      "
                      src="../../assets/icon/but_popovres备份@2x.png"
                      alt=""
                    />
                  </el-tooltip>
                </div>
                <el-input
                  v-model="compensationTime"
                  :placeholder="$t('m.请输入时间')"
                  @change="inputChange($event)"
                  type="number"
                  :readonly="
                    showStatus === $t('m.未上线') && status !== $t('m.结算中')
                      ? false
                      : true
                  "
                >
                  <template slot="append">{{$t('m.天')}}</template>
                </el-input>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="showStatus === $t('m.未上线') && status !== $t('m.结算中')" class="navBtn">
        <span @click="navBtn">{{$t('m.保 存')}}</span>
      </div>
    </div>
    <div class="operation">
      <div class="operation-item">
        <div class="operation-item-title">
          <span style="font-size: 18px">{{$t('m.基本信息')}}</span>
          <span
            style="
              font-size: 14px;
              border: 1px solid #007aff;
              border-radius: 16px;
              margin: 0 5px;
              padding: 1px 11px;
              background: #dfeeff;
            "
            >{{ contentStatus }}</span
          >
          <span class="title-remarks">({{$t('m.申请上线时将对内容进行审核')}})</span>
        </div>
        <div class="project">
          <span>{{$t('m.项目名称')}}</span>
          <input type="text" v-model="projectName" />
        </div>
        <div class="project">
          <span style="margin-bottom: 8px">
            <span>{{$t('m.项目简介')}}</span>
            <span class="title-remarks">
              ({{$t('m.项目简介内容在上架时需提交审核')}})
            </span>
          </span>
          <el-input
            type="textarea"
            :rows="4"
            v-model="projectNav"
            :maxlength="500"
            resize="none"
          />
        </div>
        <span class="dialogBtn"><span @click="testBtn">{{$t('m.提 交')}}</span></span>
      </div>
      <div class="operation-state">
        <div class="operation-state-title">
          <div style="margin-right: 8px; color: ">{{$t('m.项目操作')}}</div>
          <el-tooltip
            class="item"
            effect="light"
            offset="200"
            placement="top-start"
          >
            <div style="width: 400px" slot="content">
              {{ tipsOne }}<br />{{ tipsTwo }}
            </div>
            <img
              style="
                width: 15px;
                height: 15px;
                margin: 3px 0 0 5px;
                cursor: pointer;
              "
              src="../../assets/icon/but_popovres备份@2x.png"
              alt=""
            />
          </el-tooltip>
        </div>
        <!-- 项目状态 -->
        <div class="operationStyle">
          <span>{{$t('m.项目状态')}}:</span>
          <span style="color: #456efb; margin-left: 10px">{{
            showStatus ? showStatus : ""
          }}</span>
        </div>
        <!-- 入金状态 -->
        <div class="projectShow operationStyle">
          <!-- <el-switch v-model="inGold" @change="goldChange"> </el-switch> -->
          <span>{{$t('m.项目阶段')}}:</span>

          <span style="color: #fc9839; margin-left: 10px" class="tips">{{
            status ? status : ""
          }}</span>
        </div>
        <!-- 入金操作 -->
        <div class="operationStyle" v-if="projectInfo.status != 'settlement'">
          <span style="margin-right: 20px">{{$t('m.入金操作')}}:</span>
          <el-radio @change="goldChange" v-model="priceData" label="restoreGold"
            >{{$t('m.开放入金')}}</el-radio
          >
          <el-radio @change="goldChange" v-model="priceData" label="suspendGold"
            >{{$t('m.暂停入金')}}</el-radio
          >
        </div>
        <!-- 项目可见 -->
        <div class="projectShow operationStyle">
          <span style="margin-right: 20px">{{$t('m.项目可见')}}:</span>
          <el-radio
            v-model="projectShow"
            @change="projectShowChange"
            label="visable"
            >{{$t('m.可见')}}</el-radio
          >
          <el-radio
            v-model="projectShow"
            @change="projectShowChange"
            label="unVisable"
            >{{$t('m.不可见')}}</el-radio
          >
        </div>
        <div class="operation-content">
          <!-- 未上线 -->
          <template>
            <el-button type="success" plain @click="setType('copyPwd')"
              >{{$t('m.测试V口令')}}</el-button
            >
          </template>

          <!-- 上线中 -->
          <template v-if="showStatus == $t('m.已上线')">
            <el-button @click="setType('offline')" type="danger"
              >{{$t('m.结算')}}</el-button
            >
          </template>

          <!-- 下线中 -->
          <template v-if="status !== $t('m.结算中') && showStatus == $t('m.未上线')">
            <el-button type="primary" @click="AdvanceSetting('online')"
              >{{$t('m.申请上线')}}</el-button
            >
          </template>
        </div>
      </div>
    </div>
    <el-dialog :title="$t('m.测试V口令')" :visible.sync="dialogVisible" width="30%">
      <el-input
        v-model="testPassword"
        :readonly="true"
        id="testPass"
      ></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="copyTestPass()">{{$t('m.复 制')}}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  getExchangeList,
  advanceSetting,
  getProject,
  getProjectPairById,
  addProjectPair,
  addProject,
  getTradePair,
} from "../../request/api";
export default {
  components: {},
  data() {
    return {
      ExchangeRestrictions: null, //交易所限制
      exchangeList: null, //交易所列表

      aa: [
        { exchangeId: "1", exchangeName: "a" },
        { exchangeId: "2", exchangeName: "b" },
      ],

      tags: [],
      pairTags: [],
      account: this.$t('m.币币'),
      symbol: "USDT", //投入币种
      profitSharing: true, //赢利分成显示
      profitLayer: [], //分成

      riskIndemnityComment: null, //赔付比例
      projectInfo: null, //项目信息
      defaultTeam: null, //团队信息
      advanceSetting: [], //高级设置
      type: null, //底部操作type
      min: null,
      max: null,
      testPass: null, //测试口令

      profitLayer: null, //盈利分成
      riskIndemnityComment: null, //亏损map

      profitRatio: null, //盈利比例
      profitSharingRatio: null, //盈利分成比例
      shareCeiling: null, //分成上限
      minBond: null, //最小保证金

      riskIndemnityCommentShow: false, //赔付比例选择
      lossRatio: 0, //亏损比例
      IndemnityRatio: 0, //赔付比例
      compensationTime: 0, //赔付生效时间
      testPassword: null, //测试口令
      dialogVisible: false,
      projectShow: null, //项目可见
      tipsOne: this.$t('m.项目可见：可见则展示在量化托管列表；不可见则不在量化托管列表展示，但可通过测试V口令访问'),
      tipsTwo: this.$t('m.入金操作：开放入金时，用户可以将资金投入项目，暂停入金时，无法投入资金'),
      tipsThree: this.$t('m.生效时间之前退出的将不会获得赔偿'),

      projectNav: null, //项目详情
      projectName: null, //项目名称
      pairList: null, //交易对数据
      pair: [], //交易对保存数据
      priceData: null, //入金操作
      project: null, //项目状态列表
      status: null, //入金状态
      showStatus: null, //项目状态
      ExchangeRestrictionsList: [], //交易所存储
      multipleLimit: 20, //交易对最大限制
      contentStatus: null, //基本信息状态
      pairOption: null, //交易对限制
      exchangeOption: null, //交易所限制
    };
  },
  computed: {},
  watch: {},
  methods: {
    // 查询交易所
    getExchangeList() {
      let payload = {
        token: sessionStorage.getItem("token"),
      };
      getExchangeList(payload).then((res) => {
        if (res.status == 200) {
          this.exchangeList = res.data.exchangeList;
          console.log(this.exchangeList);
          // console.log("交易所列表", res.data);
        } else {
          this.$message.error(res.msg);
        }
      });
    },

    // 交易所限制添加
    exchangeRestrictionsAdd() {
      let name = this.ExchangeRestrictions;
      let list = this.exchangeList;
      let newlist = this.tags;

      // for (let i = 0; i < newlist.length; i++) {
      //   if (name == newlist[i].exchangeId) {
      //     this.$message.warning("已经存在所选交易所");
      //     return;
      //   }
      // }
      for (let i = 0; i < list.length; i++) {
        if (name == list[i].exchangeId) {
          this.tags.push(list[i]);
          console.log(this.ExchangeRestrictions, this.tags);
          return;
        }
      }
    },
    // type 选择
    setType(v) {
      console.log(v);
      let payload = {
        teamProjectId: this.projectInfo.teamProjectId,
        token: sessionStorage.getItem("token"),
        type: v,
      };

      // 测试口令
      if (v == "copyPwd") {
        let projectParam = {
          weexUrl: "follw/UTrustInfo",
          param: { teamProjectId: this.projectInfo.teamProjectId },
          pageType: "open",
        };
        payload = {
          projectParam: JSON.stringify(projectParam),
          key: "trust" + this.projectInfo.teamProjectId,
          token: sessionStorage.getItem("token"),
          type: "copyPwd",
          teamProjectId: this.projectInfo.teamProjectId,
        };
        console.log(payload);
        advanceSetting(payload).then((res) => {
          if (res.status == 200) {
            console.log(res.data);
            if (v == "copyPwd") {
              this.testPassword =
                this.$t('m.[V口令]') +
                res.data.command +
                this.$t('m.#打开Vtrading，跟着量化大V一起飞');
              this.dialogVisible = true;
            }
          } else {
            this.$message.error(res.msg);
          }
        });
      } else if (v == "offline") {
        this.$confirm(
          this.$t('m.确认结算后，项目将开始结算清盘，并从量化托管列表移除'),
          this.$t('m.结算'),
          {
            confirmButtonText: this.$t('m.确定'),
            cancelButtonText: this.$t('m.取消'),
          }
        )
          .then(() => {
            console.log(payload);
            advanceSetting(payload).then((res) => {
              if (res.status == 200) {
                console.log(res.data);
                this.$message.success(this.$t('m.操作成功'));
                this.$emit("getProject");
                this.getProject();
                this.info();
              } else {
                this.$message.error(res.msg);
              }
            });
          })
          .catch(() => {
            console.log("取消");
          });
      }
    },
    // 复制口令
    copyTestPass() {
      var Url2 = document.getElementById("testPass");
      Url2.select(); // 选择对象
      document.execCommand("Copy");
      this.$message.success(this.$t('m.V口令已复制，去粘贴给好友吧'));
      this.dialogVisible = false;
    },
    // 高级设置
    AdvanceSetting(val) {
      console.log(val);
      if (val) {
        this.type = val;
      }
      if (Number(this.min) < 1000) {
        this.$message.error(this.$t('m.最小投入资金不能低于最小范围'));
        return;
      } else if (Number(this.max) > 200000) {
        this.$message.error(this.$t('m.最大投入资金不能大于最大范围'));
        return;
      } else if (Number(this.min) > this.max) {
        this.$message.error(this.$t('m.最小投入资金不能大于最大投入资金'));
        return;
      } else if (Number(this.profitRatio) < 0) {
        this.$message.error(this.$t('m.盈利比例不能为负'));
        return;
      } else if (Number(this.profitSharingRatio) < 0) {
        this.$message.error(this.$t('m.盈利分成比例不能为负'));
        return;
      } else if (Number(this.profitSharingRatio) > 50) {
        this.$message.error(this.$t('m.盈利分成比例不能超过50%'));
        return;
      } else if (Number(this.shareCeiling) < 0) {
        this.$message.error(this.$t('m.盈利分成上限不能为负'));
        return;
      } else if (Number(this.minBond) < 0) {
        this.$message.error(this.$t('m.最小保证金不能为负'));
        return;
      } else if (
        this.riskIndemnityCommentShow &&
        Number(this.IndemnityRatio) < 0
      ) {
        this.$message.error(this.$t('m.亏损赔付不能为负'));
        return;
      } else if (this.riskIndemnityCommentShow && Number(this.lossRatio) < 0) {
        this.$message.error(this.$t('m.亏损比例不能为负'));
        return;
      } else if (
        this.riskIndemnityCommentShow &&
        Number(this.compensationTime) < 0
      ) {
        this.$message.error(this.$t('m.赔付生效时间不能小于0'));
        return;
      }
      this.ExchangeRestrictionsList = [];
      let name = this.ExchangeRestrictions;
      let list = this.exchangeList;
      for (let item = 0; item < name.length; item++) {
        for (let i = 0; i < list.length; i++) {
          if (name[item] == list[i].exchangeId) {
            this.ExchangeRestrictionsList.push(list[i]);
          }
        }
      }
      console.log(this.ExchangeRestrictionsList, this.ExchangeRestrictions);
      let payload = {
        teamProjectId: this.projectInfo.teamProjectId,
        token: sessionStorage.getItem("token"),
        type: this.type,

        advanceSetting: {
          exchangeLimit: this.ExchangeRestrictionsList,
          maxInvest: this.max,
          minInvest: this.min,

          profitLayer: {
            maxLimit: this.shareCeiling,
            minimumAvailableMargin: this.minBond,
            range: [
              {
                lowerLimit: this.profitRatio,
                proportion: this.profitSharingRatio,
              },
            ],
          },
          riskIndemnityComment: null,
          spotOrContract: this.advanceSetting.spotOrContract,
          symbol: this.advanceSetting.symbol,
        },
      };
      if (this.riskIndemnityCommentShow) {
        if (Number(this.compensationTime) > 30) {
          this.$message.error(this.$t('m.赔付承诺生效时间不能大于30天'));
          return;
        }
        let riskIndemnityComment = {
          effectTime: this.compensationTime,
          range: [
            {
              lowerLimit: this.lossRatio,
              commentProportion: this.IndemnityRatio,
            },
          ],
        };
        payload.advanceSetting.riskIndemnityComment = riskIndemnityComment;
      }
      console.log(payload);

      advanceSetting(payload).then((res) => {
        if (res.status == 200) {
          this.$message.success(this.$t('m.设置成功'));
          this.$emit("getProject");
          this.getProject();
          this.info();
        } else {
          this.$message.error(res.msg);
        }
      });
    },

    info() {
      console.log(this.projectInfo)
      setTimeout(() => {
        let projectInfo = JSON.parse(sessionStorage.getItem("projectInfo"));
        // let projectInfo=this.projectInfo
        console.log(projectInfo)
        this.projectInfo = projectInfo; //项目信息
        console.log(projectInfo);
        this.type = projectInfo.status; //项目状态
        this.advanceSetting = JSON.parse(projectInfo.advanceSetting); //高级设置
        this.profitLayer = this.advanceSetting.profitLayer; //盈利
        this.riskIndemnityComment = this.advanceSetting.riskIndemnityComment; //亏损
        this.riskIndemnityComment
          ? (this.riskIndemnityCommentShow = true)
          : (this.riskIndemnityCommentShow = false);
        let tags = this.advanceSetting.exchangeLimit; //投入范围
        this.ExchangeRestrictions = [];
        for (let i = 0; i < tags.length; i++) {
          this.ExchangeRestrictions.push(Number(tags[i].exchangeId));
        }
        console.log(this.ExchangeRestrictions);
        this.min = this.advanceSetting.minInvest;
        this.max = this.advanceSetting.maxInvest;
        this.projectInfo.visibility == "no"
          ? (this.projectShow = "unVisable")
          : (this.projectShow = "visable");
        if (this.projectInfo.status == "gold") {
          this.priceData = "restoreGold";
        } else if (this.projectInfo.status == "suspendGold") {
          this.priceData = "suspendGold";
        }

        this.profitRatio = JSON.parse(
          JSON.stringify(this.profitLayer.range[0].lowerLimit)
        ); //盈利比例
        this.profitSharingRatio = JSON.parse(
          JSON.stringify(this.profitLayer.range[0].proportion)
        ); //盈利分成比例
        this.shareCeiling = JSON.parse(
          JSON.stringify(this.profitLayer.maxLimit)
        ); //分成上限
        this.minBond = JSON.parse(
          JSON.stringify(this.profitLayer.minimumAvailableMargin)
        ); //最小保证金

        if (this.riskIndemnityComment) {
          this.lossRatio = JSON.parse(
            JSON.stringify(this.riskIndemnityComment.range[0].lowerLimit)
          ); //亏损比例
          this.IndemnityRatio = JSON.parse(
            JSON.stringify(this.riskIndemnityComment.range[0].commentProportion)
          ); //赔付比例
          this.compensationTime = JSON.parse(
            JSON.stringify(this.riskIndemnityComment.effectTime)
          ); //赔付生效时间
        } else {
          this.lossRatio = 0;
          this.IndemnityRatio = 0;
          this.compensationTime = 0;
        }
      }, 1000);
    },

    // 入金swicth
    goldChange(v) {
      let payload = {
        teamProjectId: this.projectInfo.teamProjectId,
        token: sessionStorage.getItem("token"),
        type: v,
      };
      console.log(payload.type);
      advanceSetting(payload).then((res) => {
        if (res.status == 200) {
          this.$message.success(this.$t('m.设置成功'));
          this.$emit("getProject");
          this.getProject();
          this.info();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 项目可见
    projectShowChange(v) {
      let payload = {
        teamProjectId: this.projectInfo.teamProjectId,
        token: sessionStorage.getItem("token"),
        type: v,
      };
      console.log(payload.type);
      advanceSetting(payload).then((res) => {
        if (res.status == 200) {
          this.$message.success(this.$t('m.设置成功'));
          this.$emit("getProject");
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    inputChange(v) {
      if (v && Number(v) < 0) {
        this.$message.error(this.$t('m.输入值不能为负数'));
        // e.target.style.border = "1px solid #F23A4C";
      }
      console.log(v);
    },
    //查询项目信息
    getProject() {
      let payload = {
        teamProjectId: this.projectInfo.teamProjectId,
        token: sessionStorage.getItem("token"),
      };
      getProject(payload).then((res) => {
        if (res.status == 200) {
          sessionStorage.setItem(
            "projectInfo",
            JSON.stringify(res.data.project)
          );
          if (
            JSON.parse(
              JSON.parse(sessionStorage.getItem("projectInfo")).tradePair
            )
          ) {
            let pairTags = JSON.parse(
              JSON.parse(sessionStorage.getItem("projectInfo")).tradePair
            );
            pairTags.forEach((e) => {
              this.pairTags.push(e.counterCoin);
            });
            this.pairTags = [...new Set(this.pairTags)];
            if (this.pairTags.length === 1) {
              this.pairOption = this.pairTags;
            }
          }
          this.project = res.data.project;
          this.exchangeLimit = JSON.parse(
            this.project.advanceSetting
          ).exchangeLimit;
          if (this.exchangeLimit.length === 1) {
            this.exchangeOption = this.exchangeLimit[0].exchangeId;
          }
          this.projectName = this.project.projectName;
          this.projectNav = this.project.projectIntroduct;
          //项目状态
          this.showStatus =
            this.projectInfo.showStatus === "notOnline"
              ? this.$t('m.未上线')
              : this.projectInfo.showStatus === "review"
              ? this.$t('m.审核中')
              : this.projectInfo.showStatus === "online"
              ? this.$t('m.已上线')
              : "";
          this.status =
            this.projectInfo.status === "gold"
              ? this.$t('m.开放入金')
              : this.projectInfo.status === "suspendGold"
              ? this.$t('m.暂停入金')
              : this.projectInfo.status === "settlement"
              ? this.$t('m.结算中')
              : this.projectInfo.status === "settled"
              ? this.$t('m.结算完成')
              : "";
          this.priceData =
            this.projectInfo.status === "gold"
              ? "restoreGold"
              : this.projectInfo.status === "suspendGold"
              ? "suspendGold"
              : "";
          this.projectInfoShow =
            this.projectInfo.visibility === "yes"
              ? "visable"
              : this.projectInfo.visibility === "no"
              ? "unVisable"
              : "";
          this.contentStatus =
            this.projectInfo.contentStatus === "notAdopt"
              ? this.$t('m.未审核')
              : this.projectInfo.contentStatus === "review"
              ? this.$t('m.审核中')
              : this.projectInfo.contentStatus === "adopt"
              ? this.$t('m.已审核')
              : "";
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 查询交易对
    getProjectPairById() {
      let payload = {
        token: sessionStorage.getItem("token"),
        teamProjectId: this.projectInfo.teamProjectId,
      };
      getProjectPairById(payload).then((res) => {
        if (res.status == 200) {
          this.pairList = res.data.pairList;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 保存基本内容
    navBtn() {
      this.confirm();
      this.AdvanceSetting("save");
    },
    // 交易对存储
    confirm() {
      this.pair = [];
      let name = this.pairTags;
      let listTags = this.pairList;
      for (let item = 0; item < name.length; item++) {
        for (let i = 0; i < listTags.length; i++) {
          if (name[item] == listTags[i].counterCoin) {
            this.pair.push(listTags[i]);
          }
        }
      }
      console.log(this.pair);
      //在保存的时候只保留需要的数据
      let list = this.pair;
      let pairs = [];
      for (let i = 0; i < list.length; i++) {
        let item = {};
        item.baseCoin = list[i].baseCoin;
        item.counterCoin = list[i].counterCoin;
        item.pairId = list[i].pairId;
        pairs.push(item);
      }
      let payload = {
        token: sessionStorage.getItem("token"),
        teamProjectId: this.projectInfo.teamProjectId,
        pairs: pairs,
      };
      addProjectPair(payload).then((res) => {
        if (res.status == 200) {
          this.$emit("getProject");
          this.getTradePair();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 交易所
    changeTag(e) {
      if (this.ExchangeRestrictions.length === 0) {
        this.ExchangeRestrictions.push(e);
        this.$message.error(this.$t('m.最少选择一个交易所'));
      }
    },
    //基本信息
    testBtn() {
      if (this.projectNav === "") {
        this.$message.error(this.$t('m.项目介绍不能为空！'));
        return;
      }
      let payload = {
        teamId: this.defaultTeam.teamId,
        token: sessionStorage.getItem("token"),
        projectName: this.projectName,
        projectIntroduct: this.projectNav,
        teamProjectId: this.project.teamProjectId,
      };
      addProject(payload).then((res) => {
        if (res.status == 200) {
          this.$message.success(this.$t('m.修改成功'));
          this.$emit("getProject");
          this.getProject();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 交易对
    pairTag(item) {
      if (this.pairTags.length === 0) {
        this.$message.error(this.$t('m.最少选择一个交易对'));
        this.pairTags.push(item);
      }
    },
    getTradePair() {
      let payload = {
        token: sessionStorage.getItem("token"),
        teamProjectId: this.project.teamProjectId,
      };
      getTradePair(payload).then((res) => {
        if (res.status == 200) {
          let pair = res.data.tradePair;
          this.pair = JSON.parse(pair);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    pairchange() {
      if (this.pairTags.length === 1) {
        this.pairOption = this.pairTags;
      }
      if (this.pairTags.length === 0) {
        this.pairTags = this.pairOption;
        this.$message.error(this.$t('m.最少选择一个交易对'));
      }
    },
    exchange() {
      if (this.ExchangeRestrictions.length === 1) {
        this.exchangeOption = this.ExchangeRestrictions[0];
      }
      if (this.ExchangeRestrictions.length === 0) {
        this.ExchangeRestrictions.push(Number(this.exchangeOption));
        this.$message.error(this.$t('m.最少选择一个交易所'));
      }
    },
  },
  created() {
    let projectInfo = sessionStorage.getItem("project");
    this.projectInfo = JSON.parse(projectInfo);
    // this.showStatus = this.projectInfo.showStatus;
    // this.showStatus =
    //   this.showStatus === "notOnline"
    //     ? "未上线"
    //     : this.showStatus === "review"
    //     ? "审核中"
    //     : this.showStatus === "online"
    //     ? "已上线"
    //     : "";
    let defaultTeam = sessionStorage.getItem("defaultTeam");
    this.defaultTeam = JSON.parse(defaultTeam);
    this.info();
  },
  mounted() {
    this.getProject();
    this.getExchangeList();

    this.getProjectPairById();
  },
  beforeCreate() {},
  beforeMount() {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {},
  activated() {},
};
</script>
<style lang='scss' scoped>
.advancedSetting {
  display: flex;
  margin-top: 20px;
  .nav {
    display: flex;
    flex-direction: column;
    padding: 32px 40px;
    width: 1020px;
    height: 745px;
    margin-right: 20px;
    background: #fff;
    .navItem {
      display: flex;
      flex: 1;
      .setting {
        width: 432px;
        .account {
          .account-box {
            margin-top: 24px;
          }
          .account-inp {
            margin-top: 8px;
            display: flex;
            .account-icon {
              margin: 8px;
              font-size: 18px;
              color: #dcdde3;
              width: 16px;
              height: 2px;
            }
          }
        }
      }
      .price {
        width: 500px;
        .price-box {
          .share-ratio {
            display: flex;
          }
          .price-ratio {
            display: flex;
            align-items: center;
            font-size: 14px;
            color: #666666;
            padding-left: 22px;
            /deep/.el-input__inner {
              height: 30px;
              width: 70px;
            }
          }
        }
        .share-margin {
          padding-left: 22px;
        }
        .promise {
          .promisePay {
            padding-left: 22px;

            font-size: 14px;
            color: #666666;
            /deep/.el-input__inner {
              height: 30px;
              width: 70px;
            }
          }
          .promisePay-time {
            .title {
              margin: 20px 0 8px;
              display: flex;
              align-items: center;
            }
          }
        }
      }
    }
    .navBtn {
      display: flex;
      justify-content: flex-end;
      span {
        width: 94px;
        height: 40px;
        font-size: 14px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        background: #007aff;
        border-radius: 5px;
        cursor: pointer;
        margin-top: 32px;
      }
    }
  }
  .operation {
    color: #313237;
    margin: 0 24px 0 14px;
    .operation-item {
      margin-bottom: 16px;
      padding: 32px 40px 20px;
      background: #fff;
      .project {
        margin-top: 24px;
        display: flex;
        flex-direction: column;
        input {
          border: 1px solid #dcdde3;
          border-radius: 5px;
          padding: 10px 16px;
          margin-top: 8px;
        }
        span {
          .title-remarks {
            margin-top: 8px;
            height: 112px;
          }
        }
      }
      .dialogBtn {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: flex-end;
        font-size: 14px;
        margin-top: 20px;
        span {
          width: 70px;
          height: 33px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #fff;
          background: #007aff;
          border-radius: 5px;
          cursor: pointer;
        }
      }
    }

    .operation-state {
      background: #fff;
      padding: 32px 30px 32px 40px;
      .operation-state-title {
        display: flex;
        align-items: center;
      }
      .operationStyle {
        display: flex;
        align-items: center;
        font-size: 14px;
        margin-top: 16px;
        /deep/.el-radio {
          display: flex;
          align-items: center;
        }
        /deep/.el-radio__label {
          padding-left: 4px;
        }
      }
      .operation-content {
        display: flex;
        justify-content: flex-end;
        margin-top: 40px;
      }
    }
  }
}
.title-remarks {
  color: #8f9096;
  font-size: 12px;
  margin-left: 5px;
}
/deep/.el-checkbox__label {
  color: #313237 !important;
}
.iconStyle {
  position: relative;
  color: #313237;
  top: 7px;
  left: -12px;
}
/deep/.el-input--suffix .el-input__inner {
  padding: 0 !important;
  padding-left: 5px !important;
}
/deep/input::-webkit-input-placeholder {
  /* placeholder字体大小 */
  font-size: 12px;
}
</style>