<template>
  <!-- 子币对操作 -->
  <div class="currencyPairOperation">
    <el-container>
      <el-header>
        <div class="header-warp">
          <i class="el-icon-arrow-left" @click="goBack"></i>
          <span>{{$t('m.子币对操作')}}</span>
          <span></span>
        </div>
      </el-header>
      <el-container>
        <!-- main -->
        <el-main>
          <div class="content">
            <el-table
              ref="multipleTable"
              :data="sonStrategyList"
              tooltip-effect="dark"
              style="width: 100%"
            >
              <el-table-column prop="countCoin" label="子币对" width="120">
                <template slot-scope="scope">
                  <span v-if="isDetails">{{
                    scope.row.counterCoin.toUpperCase()
                  }}</span>
                  <span v-else>{{ scope.row.countCoin }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="price" label="价格" width="200">
                <template slot-scope="scope">
                  <span v-if="isDetails">{{
                    Math.floor(Number(scope.row.price) * 10000) / 10000
                  }}</span>
                  <span v-else>{{
                    Math.floor(Number(scope.row.price.last) * 10000) / 10000
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="states" label="状态" width="200">
                <template slot-scope="scope">
                  <span v-if="scope.row.states == 'EXECUTE'">{{$t('m.执行中')}}</span>
                  <span v-if="scope.row.states == 'SUSPEND'">{{$t('m.暂停中')}}</span>
                  <span v-if="scope.row.states == 'STARTING'">{{$t('m.启动中')}}</span>
                  <span v-if="scope.row.states == 'RESTARTING'">{{$t('m.重启中')}}</span>
                  <span v-if="scope.row.states == 'STOPING'">{{$t('m.终止中')}}</span>
                  <span v-if="scope.row.states == 'STARTERROR'">{{$t('m.启动错误')}}</span>
                </template>
              </el-table-column>

              <el-table-column :label="$t('m.操作')">
                <template slot-scope="scope">
                  <div class="deposit-operation">
                    <span @click="strategyEnd(scope.row.executeId)">{{$t('m.终止')}}</span>
                    <span
                      v-if="scope.row.states == 'EXECUTE'"
                      @click="suspend(scope.row.executeId)"
                      >{{$t('m.暂停')}}</span
                    >
                    <span
                      v-if="scope.row.states == 'SUSPEND'"
                      @click="restart(scope.row.executeId)"
                      >{{$t('m.重启')}}</span
                    >
                    <span @click="modify(scope.row)">{{$t('m.参数')}}</span>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-main>
      </el-container>
    </el-container>
    <!-- 修改参数 -->
    <template v-if="modifyParametersShow">
      <el-dialog
        :title="$t('m.修改参数')"
        :visible.sync="modifyParametersShow"
        width="20%"
        :close-on-click-modal="false"
      >
        <div class="modifyParameters">
          <template v-for="(item, idx) in reserves">
            <div class="box" :key="idx" v-if="item.visiable == '1'">
              <span class="box-title">
                <span>{{ item.name }}</span>
                <span v-if="item.valueMin || item.start">{{
                  $t('m.范围') 
                  + "：" +
                  (item.valueMin != "" ? item.valueMin : item.start) +
                  "~" +
                  (item.valueMax != "" ? item.valueMax : item.end)
                }}</span>
              </span>
              <el-input
                v-model="item.value"
                :placeholder="$t('m.请输入内容')"
                @blur="inpCheck($event, item, idx)"
              >
                <template slot="append">USDT</template>
              </el-input>
            </div>
          </template>
        </div>
        <span slot="footer" class="dialog-footer">
          <div class="btn" @click="modifyParam">{{$t('m.修改参数')}}</div>
        </span>
      </el-dialog>
    </template>
  </div>
</template>

<script>
import {
  getSonStrategyListByTemplateId,
  strategyEnd,
  restart,
  suspend,
  updateParam,
  executeDetail,
} from "../../request/api";
export default {
  components: {},
  data() {
    return {
      templateId: null,
      sonStrategyList: [],
      modifyParametersShow: false,
      reserves: null,
      reservesCopy: null,
      paramsType: true,
      isDetails: false,
    };
  },
  computed: {},
  watch: {},
  methods: {
    // 返回上一页
    goBack() {
      this.$router.back(-1);
    },
    // 查询子币对列表
    getSonStrategyListByTemplateId() {
      let payload = {
        token: sessionStorage.getItem("token"),
        templateId: this.templateId,
      };
      getSonStrategyListByTemplateId(payload).then((res) => {
        if (res.status == 200) {
          if (res.data.sonStrategyList) {
            this.sonStrategyList = res.data.sonStrategyList;
          }
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 暂停
    suspend(id) {
      this.$confirm(this.$t('m.确认暂停该策略？'))
        .then((_) => {
          let payload = {
            executeId: id,
            token: sessionStorage.getItem("token"),
          };
          suspend(payload).then((res) => {
            if (res.status == 200) {
              this.$message.success(this.$t('m.暂停成功'));
              if (this.isDetails) {
                this.getExecuteDetail(this.$route.query.executeId);
              } else {
                this.getSonStrategyListByTemplateId();
              }
            } else {
              this.$message.error(res.msg);
            }
          });
        })
        .catch((_) => {});
    },
    // 重启
    restart(id) {
      this.$confirm(this.$t('m.确认重启该策略？'))
        .then((_) => {
          let payload = {
            executeId: id,
            token: sessionStorage.getItem("token"),
          };
          restart(payload).then((res) => {
            if (res.status == 200) {
              this.$message.success(this.$t('m.重启成功'));
              if (this.isDetails) {
                this.getExecuteDetail(this.$route.query.executeId);
              } else {
                this.getSonStrategyListByTemplateId();
              }
            } else {
              this.$message.error(res.msg);
            }
          });
        })
        .catch((_) => {});
    },
    // 终止
    strategyEnd(id) {
      this.$confirm(this.$t('m.确认终止该策略？'))
        .then((_) => {
          let payload = {
            executeId: id,
            token: sessionStorage.getItem("token"),
          };
          strategyEnd(payload).then((res) => {
            if (res.status == 200) {
              this.$message.success(this.$t('m.终止成功'));
              if (this.isDetails) {
                this.getExecuteDetail(this.$route.query.executeId);
              } else {
                this.getSonStrategyListByTemplateId();
              }
            } else {
              this.$message.error(res.msg);
            }
          });
        })
        .catch((_) => {});
    },
    // 修改参数弹窗
    modify(row) {
      console.log(row);
      this.executeId = row.executeId;
      if (this.isDetails) {
        console.log(JSON.parse(row.config).reserves);
        let reserves = JSON.parse(row.config).reserves;
        this.reserves = JSON.parse(JSON.stringify(reserves));
        this.reservesCopy = JSON.parse(JSON.stringify(reserves));
        this.modifyParametersShow = true;
      } else {
        let payload = {
          executeId: this.executeId,
          token: sessionStorage.getItem("token"),
        };
        executeDetail(payload).then((res) => {
          if (res.status == 200) {
            console.log(res.data);
            let reserves = res.data.executeDetail.config.reserves;
            this.reserves = JSON.parse(JSON.stringify(reserves));
            this.reservesCopy = JSON.parse(JSON.stringify(reserves));
            this.modifyParametersShow = true;
          } else {
            this.$message.error(res.msg);
          }
        });
      }
    },
    // 参数校验
    inpCheck(e, item, index) {
      
      let value = item.value;
      let min =
        item.valueMin && item.valueMin != 0 ? item.valueMin : item.start;
      let max = item.valueMax && item.valueMax != 0 ? item.valueMax : item.end;

      if (value === "") {
        this.$message.error(this.$t('m.参数不能为空'));
        e.currentTarget.parentElement.style.border = "1px solid #F23A4C";
        e.currentTarget.parentElement.style.borderRadius = "4px";
        this.paramsType = false;
        return;
      } else if (min != "" && Number(value) < min) {
        this.$message.error(this.$t('m.参数不能小于最低范围'));
        e.currentTarget.parentElement.style.border = "1px solid #F23A4C";
        e.currentTarget.parentElement.style.borderRadius = "4px";
        console.log(4);
        this.paramsType = false;
        return;
      } else if (max != "" && Number(value) > max) {
        this.$message.error(this.$t('m.参数不能大于最大范围'));
        e.currentTarget.parentElement.style.border = "1px solid #F23A4C";
        e.currentTarget.parentElement.style.borderRadius = "4px";
        console.log(5);
        this.paramsType = false;
        return;
      } else {
        e.currentTarget.parentElement.style.border = "none";
        console.log(6);
        this.paramsType = true;
      }
    },
    // 修改参数
    modifyParam() {
      if (!this.paramsType) {
        this.$message.error(this.$t('m.参数配置不正确，请重新修改'));
        return;
      }
      let payload = {
        token: sessionStorage.getItem("token"),
        executeId: this.executeId,
        reserves: JSON.stringify(this.reserves),
      };
      updateParam(payload).then((res) => {
        if (res.status == 200) {
          this.$message.success(this.$t('m.修改成功'));
          this.executeId = null;
          if (this.isDetails) {
            this.getExecuteDetail(this.$route.query.executeId);
          }
          this.modifyParametersShow = false;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 查询策略详情
    getExecuteDetail(id) {
      let payload = {
        executeId: id,
        token: sessionStorage.getItem("token"),
      };
      executeDetail(payload).then((res) => {
        if (res.status == 200) {
          this.sonStrategyList = res.data.sonExecuteList;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
  created() {},
  mounted() {},
  beforeCreate() {},
  beforeMount() {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {},
  activated() {
    if (this.$route.query.isDetails) {
      this.isDetails = true;
      this.getExecuteDetail(this.$route.query.executeId);
    } else {
      this.templateId = this.$route.query.templateId;
      this.getSonStrategyListByTemplateId();
    }
  },
  deactivated() {
    this.$destroy();
  },
};
</script>
<style lang='scss' scoped>
.currencyPairOperation {
  width: 100%;
  height: 100%;
  .el-container {
    height: 100%;
    background: #eff2f5;
  }
  .el-header {
    background-color: #fff;
    color: #333;
    text-align: center;
    line-height: 60px;
    // margin-bottom: 20px;
    .header-warp {
      display: flex;
      justify-content: space-between;
      align-items: center;
      i {
        cursor: pointer;
      }
    }
  }

  .el-main {
    // background-color: #fff;
    color: #333;
    margin: 0px 20px 20px 20px;
    padding: 20px 10%;
    font-size: 14px;
    .content {
      padding: 10px 20px;
      background: #fff;
      .deposit-operation {
        span {
          font-size: 12px;
          color: #007aff;
          cursor: pointer;
        }
        :nth-child(2) {
          margin: 0 30px;
        }
      }
    }
  }
  // 修改参数
  .modifyParameters {
    .box {
      margin-bottom: 10px;
      .box-title {
        display: inline-block;
        font-size: 14px;
        color: #333;
        margin-bottom: 4px;
        :last-child {
          font-size: 12px;
          color: #666;
          margin-left: 4px;
        }
      }
    }
  }
  // 修改参数确认
  .btn {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #007aff;
    color: #fff;
    font-size: 16px;
    border-radius: 4px;
    cursor: pointer;
  }
}
</style>