<!-- 创建团队 -->
<template>
  <div class="firstLogin">
    <div class="top">
      <!-- 第一步 -->
      <template v-if="active == 1">
        <div class="icon">
          <div class="num iconOn">1</div>
          <div>---------------------------------------------------------</div>
          <div class="num">2</div>
          <div>-------------------------------------------------</div>
          <div class="num">3</div>
        </div>

        <div class="text">
          <span class="textOn">{{$t('m.创建团队名称')}}</span>
          <span>{{$t('m.选择服务对象')}}</span>
          <span>{{$t('m.完成')}}</span>
        </div>
      </template>

      <!-- 第二步 -->
      <template v-if="active == 2">
        <div class="icon">
          <div class="num iconOn">1</div>
          <div class="line">
            ---------------------------------------------------------
          </div>
          <div class="num iconOn">2</div>
          <div>-------------------------------------------------</div>
          <div class="num">3</div>
        </div>

        <div class="text">
          <span class="textOn">{{$t('m.创建团队名称')}}</span>
          <span class="textOn">{{$t('m.创建团队名称')}}</span>
          <span>{{$t('m.完成')}}</span>
        </div>
      </template>

      <!-- 第三步 -->
      <template v-if="active == 3 || active == 4">
        <div class="icon">
          <div class="num iconOn">1</div>
          <div class="line">
            ---------------------------------------------------------
          </div>
          <div class="num iconOn">2</div>
          <div class="line">
            -------------------------------------------------
          </div>
          <div class="num iconOn">3</div>
        </div>

        <div class="text">
          <span class="textOn">{{$t('m.创建团队名称')}}</span>
          <span class="textOn">{{$t('m.选择服务对象')}}</span>
          <span class="textOn">{{$t('m.完成')}}</span>
        </div>
      </template>
    </div>

    <div class="cont">
      <div class="title" v-if="active == 1">{{$t('m.团队名称')}}</div>
      <div class="title" v-if="active == 2 || active == 3">{{$t('m.选择您的团队服务对象')}}</div>
      <div class="title" v-if="active == 4">{{$t('m.绑定平台标识')}}（appId）</div>

      <div class="inp" v-if="active == 1">
        <el-input
          v-model="teamName"
          :placeholder="$t('m.请输入您的团队名称,限8个字符')"
        ></el-input>
      </div>

      <div class="sel" v-if="active == 2 || active == 3">
        <div class="box" :class="{ 'box-on': isApp }" @click="checkPlat('App')">
          <div class="check">
            <el-checkbox
              v-model="isApp"
              @change="checkPlat('App')"
            ></el-checkbox>
          </div>
          <span class="name">{{$t('m.Vtrading APP用户')}}</span>
          <span>{{$t('m.由Vtrading提供结算解决方案')}}</span>
        </div>

        <div class="box" :class="{ 'box-on': isH5 }" @click="checkPlat('H5')">
          <div class="check">
            <el-checkbox v-model="isH5" @change="checkPlat('H5')"></el-checkbox>
          </div>
          <span class="name">{{$t('m.嵌入式系统平台用户')}}</span>
          <span>{{$t('m.由平台提供结算解决方案')}}</span>
        </div>
      </div>

      <div class="inp" v-if="active == 4">
        <el-input v-model="appId" placeholder="请输入appId"></el-input>
        <div class="text">{{$t('m.您可以联系平台方获取标识')}}</div>
        <span class="red" v-if="noAppId">*{{$t('m.未查询到平台，请联系平台方确认')}}!</span>
      </div>

      <div class="btn-warp">
        <span class="btn" v-if="active == 1" @click="create()">{{$t('m.创建')}}</span>
        <span class="btn" v-if="active == 3 && isApp" @click="createTeam()"
          >{{$t('m.完成')}}</span
        >
        <span class="btn" v-if="active == 2 && isH5" @click="active = 4"
          >{{$t('m.下一步')}}</span
        >
        <span class="btn" v-if="active == 4" @click="createTeam()">{{$t('m.完成')}}</span>
      </div>
      <div class="previous-step" v-if="active == 4" @click="previous()">
       {{$t('m.上一步')}}
      </div>
    </div>
  </div>
</template>

<script>
import { creationTeam, isExistenceTeamName } from "../request/api";
export default {
  components: {},
  data() {
    return {
      teamName: null,
      active: 1,
      isApp: true,
      isH5: false,
      appId: null,
      noAppId: false,
    };
  },
  computed: {},
  watch: {},
  methods: {
    checkPlat(v) {
      console.log(v);
      if (v == "App") {
        this.isApp = true;
        this.isH5 = false;
        this.active = 3;
      } else {
        this.isApp = false;
        this.isH5 = true;
        this.active = 2;
      }
    },

    // 创建
    create() {
      console.log(this.teamName);
      if (this.teamName) {
        this.teamName = this.teamName.trim();
      }
      if (!this.teamName) {
        this.$message.error(this.$t('m.请先输入团队名称'));
        return;
      } else if (this.teamName.length > 8) {
        console.log(this.teamName);
        if (!this.teamName) {
          this.$message.error(this.$t('m.请先输入团队名称'));
          return;
        }
        this.$message.error(this.$t('m.团队名称限8个字符'));
        return;
      }
      let payload = {
        token: sessionStorage.getItem("token"),
        teamName: this.teamName,
      };
      this.isExistenceTeamName(payload);
    },
    //验证团队名称
    isExistenceTeamName(payload) {
      isExistenceTeamName(payload).then((res) => {
        console.log();
        if (res.data.isExistenceTeamName > 0) {
          this.$message.error(this.$t('m.团队名称已存在，不能重名！'));
        } else {
          this.active = 3;
        }
      });
    },
    // 上一步
    previous() {
      this.active = 2;
      this.appId = null;
    },

    // 创建团队
    createTeam() {
      let payload = {
        token: sessionStorage.getItem("token"),
        teamName: this.teamName,
      };
      if (this.active == 4) {
        if (this.appId) {
          this.appId = this.appId.trim();
        }
        if (!this.appId) {
          this.$message.error(this.$t('m.请输入appId'));
          return;
        }
        payload.platType = this.appId;
      } else if (this.active == 3) {
        payload.platType = "";
      }
      console.log(payload);

      creationTeam(payload).then((res) => {
        if (res.status == 200) {
          console.log(res.data);
          this.noAppId = false;
          this.$message.success(this.$t('m.创建成功'));
          this.$router.push("/");
          sessionStorage.setItem("queryNumber", this.teamName);
          this.$store.commit("setNoviceActive", "2");
        } else {
          this.$message.error(res.msg);
          this.noAppId = true;
        }
      });
    },
  },
  created() {},
  mounted() {},
  beforeCreate() {},
  beforeMount() {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {},
  activated() {},
  deactivated() {
    this.$destroy();
  },
};
</script>
<style lang='scss' scoped>
//@import url(); 引入公共css类
.firstLogin {
  width: 100vw;
  height: 100vh;
  background: linear-gradient(360deg, #071634 0%, #202aa0 100%);
  display: flex;
  flex-direction: column;
  align-items: center;
  .top {
    width: 900px;
    height: 240px;
    padding: 60px 86px 60px 86px;
    font-size: 16px;
    box-sizing: border-box;
    .icon {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #3256ff;
      padding-left: 30px;
      margin-bottom: 14px;
      .num {
        width: 38px;
        height: 42px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #212ba1;
        font-size: 26px;
        font-weight: 600;
        background: url("../assets/image/tuoyua01.png");
      }
      .iconOn {
        background: url("../assets/image/tuoyuan.png");
      }
      .line {
        color: #00e2ff;
      }
    }
    .text {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #3256ff;
      .textOn {
        color: #00e2ff;
      }
    }
  }
  .cont {
    width: 900px;
    height: 540px;
    background: #fff;
    box-shadow: 0px 1px 6px 0px rgba(19, 57, 119, 0.06);
    border-radius: 20px;
    .title {
      color: #102859;
      font-size: 16px;
      height: 74px;
      border-bottom: 1px solid #e6e9ee;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .inp {
      margin-top: 110px;
      margin-bottom: 137px;
      padding: 0 150px;
      .text {
        font-size: 12px;
        color: #9faabd;
        margin: 8px 0;
      }
      .red {
        color: #ff3a3a;
        font-size: 12px;
      }
    }

    .sel {
      margin-top: 110px;
      margin-bottom: 137px;
      padding: 0 150px;
      display: flex;
      justify-content: center;
      .box {
        width: 220px;
        height: 106px;
        background: rgba(33, 116, 255, 0.2);
        border-radius: 4px;
        opacity: 0.2;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 12px;
        color: #55698a;
        padding-top: 10px;
        margin-right: 32px;
        cursor: pointer;
        .check {
          width: 100%;
          padding-right: 20px;
          text-align: right;
        }
        .name {
          margin: 4px 0 10px 0;
          font-size: 16px;
          color: #2174ff;
        }
      }
      .box-on {
        background: rgba(33, 116, 255, 0.1);
        border: 1px solid #2174ff;
        opacity: 1;
        .name {
          color: #102859;
        }
      }
    }

    .btn-warp {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      .btn {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 186px;
        height: 44px;
        background: linear-gradient(270deg, #00d1ff 0%, #2174ff 100%);
        box-shadow: 0px 4px 20px 0px rgba(176, 215, 253, 0.6);
        border-radius: 22px;
        color: #fff;
        font-size: 16px;
        letter-spacing: 1px;
        cursor: pointer;
      }
    }
    .previous-step {
      width: 100%;
      text-align: center;
      color: #9faabd;
      font-size: 14px;
      margin-top: 20px;
      cursor: pointer;
    }
  }
}
</style>