<template>
  <div class="parameterComponent">
    <template v-if="childParams">
      <div v-for="(item, idx) in childParams" :key="idx">
        <!-- 数字输入框 -->
        <div
          class="params"
          v-if="item.controltype == 'number-input' && item.visiable != 'false'">
          <div class="title">
            <div class="title-left">
              <span class="red" v-if="item.require">*</span>
              <span>{{ item.label }}</span>

              <el-tooltip
                class="item"
                effect="light"
                :content="item.content"
                placement="top-start"
                v-if="item.content">
                <img
                  class="icon-jieshi"
                  src="../assets/icon/jieshi.png"
                  alt=""
                />
              </el-tooltip>
            </div>
          </div>

          <div class="params-box">
            <el-input
              :placeholder="item.placeHolder"
              type="number"
              v-model="item.value"
              :disabled="item.disabled"
              :readonly="!executeStates">
            </el-input>
          </div>
        </div>

        <!-- 数字货币输入框 -->
        <div
          class="params"
          v-if="item.controltype == 'coin-input' && item.visiable != 'false'">
          <div class="title">
            <div class="title-left">
              <span class="red" v-if="item.require">*</span>
              <span>{{ item.label }}</span>

              <el-tooltip
                class="item"
                effect="light"
                :content="item.content"
                placement="top-start"
                v-if="item.content">
                <img
                  class="icon-jieshi"
                  src="../assets/icon/jieshi.png"
                  alt=""
                />
              </el-tooltip>
            </div>
          </div>

          <div class="params-box">
            <el-input
              :placeholder="item.placeHolder"
              type="number"
              v-model="number"
              :disabled="item.disabled"
              :readonly="!executeStates">
              <template slot="append">{{ current }}</template>
            </el-input>
          </div>
        </div>

        <!-- 价格输入框 -->
        <div
          class="params"
          v-if="item.controltype == 'price-input' && item.visiable != 'false'">
          <div class="title">
            <div class="title-left">
              <span class="red" v-if="item.require">*</span>
              <span>{{ item.label }}</span>

              <el-tooltip
                class="item"
                effect="light"
                :content="item.content"
                placement="top-start"
                v-if="item.content">
                <img
                  class="icon-jieshi"
                  src="../assets/icon/jieshi.png"
                  alt=""
                />
              </el-tooltip>
            </div>
          </div>

          <div class="params-box">
            <el-input
              :placeholder="item.placeHolder"
              type="number"
              v-model="item.value"
              :disabled="item.disabled"
              :readonly="!executeStates">
              <template slot="append">{{ current }}</template>
            </el-input>
          </div>
        </div>

        <!-- 下拉选择框 -->
        <div
          class="params"
          v-if="item.controltype == 'select' && item.visiable != 'false'">
          <div class="title">
            <div class="title-left">
              <span>{{$t('m.下拉框')}}</span>
            </div>
          </div>

          <div class="params-box">
            <el-select
              v-model="item.value"
              :placeholder="$t('m.请选择')"
              :disabled="!executeStates">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="$t('m.'+item.label)"
                :value="item.value">
              </el-option>
            </el-select>
          </div>
        </div>

        <!-- 布尔控件 -->
        <div
          class="params"
          v-if="item.controltype == 'select' && item.visiable != 'false'">
          <div class="title">
            <div class="title-left">
              <span class="red">*</span>
              <span>{{$t('m.开关控件')}}</span>
              <el-tooltip
                class="item"
                effect="light"
                :content="item.content"
                placement="top-start">
                <img
                  class="icon-jieshi"
                  src="../assets/icon/jieshi.png"
                  alt=""
                />
              </el-tooltip>
            </div>
            <div class="title-right">
              <span>{{$t('m.名称')}}：</span>
              <el-switch
                v-model="Switch"
                active-color="#2174ff"
                inactive-color="#758DB5"
                :width="36">
              </el-switch>
            </div>
          </div>

          <div class="params-box">
            <div class="params-box">
              <el-input
                :placeholder="item.placeHolder"
                type="number"
                v-model="switchValue">
                <template slot="append">%</template>
              </el-input>
              <span class="red">{{$t('m.输入值不在有效范围内')}}</span>
            </div>
          </div>
        </div>

        <!-- 多控开关 -->
        <div
          class="params"
          v-if="item.controltype == 'multi-switch' && item.visiable != 'false'">
          <div class="title">
            <div class="title-left">
              <span>{{ item.label }}</span>
              <el-tooltip
                class="item"
                effect="light"
                :content="item.content"
                placement="top-start"
                v-if="item.content">
                <img
                  class="icon-jieshi"
                  src="../assets/icon/jieshi.png"
                  alt=""
                />
              </el-tooltip>
            </div>
          </div>

          <div class="params-box">
            <div class="params-box">
              <div class="radio-warp">
                <span
                  v-for="(val, idx) in item.opts"
                  :key="idx"
                  @click="multiSwitch($event, val, idx, item)"
                  :class="{ on: val.value == item.proposed }">
                  {{ val.label }}</span>
              </div>
            </div>
          </div>
        </div>

        <!-- 时间日期 -->
        <div
          class="params"
          v-if="item.controltype == 'datetime' && item.visiable != 'false'">
          <div class="title">
            <div class="title-left">
              <span>{{$t('m.时间日期选择')}}</span>
              <el-tooltip
                class="item"
                effect="dark"
                content=""
                placement="top-start">
                <img
                  class="icon-jieshi"
                  src="../assets/icon/jieshi.png"
                  alt=""
                />
              </el-tooltip>
            </div>
          </div>

          <div class="params-box">
            <el-date-picker
              v-model="dateTime"
              type="datetime"
              :placeholder="$t('m.选择日期时间')"
              :clearable="false"
              :picker-options="pickerOptions">
            </el-date-picker>
          </div>
        </div>

        <!-- 时长选择框 -->
        <div
          class="params"
          v-if="item.controltype == 'duration' && item.visiable != 'false'">
          <div class="title">
            <div class="title-left">
              <span>{{$t('m.时长选择框')}}</span>
              <el-tooltip
                class="item"
                effect="dark"
                :content="item.content"
                placement="top-start">
                <img
                  class="icon-jieshi"
                  src="../assets/icon/jieshi.png"
                  alt=""
                />
              </el-tooltip>
            </div>
          </div>

          <div class="params-box">
            <div class="duration">
              <el-input
                :placeholder="$t('m.请输入时间')"
                type="number"
                v-model="durationDay">
                <template slot="append">{{$t('m.天')}}</template>
              </el-input>
              <el-time-picker
                v-model="duration"
                :picker-options="{
                  selectableRange: '00:00:00 - 23:59:59',
                }"
                :placeholder="$t('m.任意时间点')">
              </el-time-picker>
            </div>
          </div>
        </div>

        <!-- 周期选择 -->
        <div
          class="params"
          v-if="item.controltype == 'period' && item.visiable != 'false'">
          <div class="title">
            <div class="title-left">
              <span>{{$t('m.周期选择')}}</span>
              <el-tooltip
                class="item"
                effect="dark"
                content=""
                placement="top-start">
                <img
                  class="icon-jieshi"
                  src="../assets/icon/jieshi.png"
                  alt=""
                />
              </el-tooltip>
            </div>
          </div>

          <div class="params-box">
            <el-cascader
              v-model="cycle"
              :options="cycleList"
              @change="handleChange">
            </el-cascader>
          </div>
        </div>

        <!-- 信号动作 -->
        <div class="params" v-if="item.controltype == 'signal-input'">
          <div class="title">
            <div class="title-left">
              <span class="red">*</span>
              <span>{{ item.label }}</span>
            </div>
          </div>
          <div class="params-box">
            <el-select
              v-model="item.signalName"
              :placeholder="$t('m.请选择')"
              @change="signalChange"
              :disabled="!executeStates">
              <el-option
                v-for="cont in signalList"
                :key="cont.signalId"
                :label="cont.signalName"
                :value="cont.signalName">
              </el-option>
            </el-select>
          </div>

          <div class="warp">
            <div class="params-box left">
              <div class="title">{{$t('m.属性')}}</div>
              <el-select
                v-model="item.signalAttributeName"
                :placeholder="$t('m.请选择')"
                @change="signalParamsChange"
                :disabled="!executeStates">
                <el-option
                  v-for="cont in item.signalParamList"
                  :key="cont.label"
                  :label="cont.label"
                  :value="cont">
                </el-option>
              </el-select>
            </div>

            <div class="params-box center">
              <div class="title"></div>
              <el-select
                v-model="symbol"
                :placeholder="$t('m.请选择')"
                @change="symbolChange"
                :disabled="!executeStates">
                <el-option
                  v-for="cont in SymbolList"
                  :key="cont.value"
                  :label="cont.value"
                  :value="cont.value">
                </el-option>
              </el-select>
            </div>

            <div class="params-box right">
              <div class="title">{{$t('m.阈值')}}</div>
              <el-input
                v-model="item.value"
                :placeholder="$t('m.请输入内容')"
                @change="signalValueChange"
                :disabled="!executeStates">
              </el-input>
            </div>
          </div>
        </div>
      </div>
    </template>

    <!-- 下单资金占比 -->
    <div class="params">
      <div class="title">
        <div class="title-left">
          <span class="red">*</span>
          <span>{{$t('m.下单资金占比')}}</span>
          <span class="rang">{{ $t('m.范围')+"：" + "1%" + "~" + "100%" }}</span>
          <img
            class="icon-jieshi"
            src="../assets/icon/jieshi.png"
            alt=""
            @click="proportionFunds()"
          />
        </div>
      </div>

      <div class="params-box">
        <el-input
        :placeholder="$t('m.请输入百分比')"
          type="number"
          v-model="percentage"
          :readonly="!executeStates">
          <template slot="append">%</template>
        </el-input>
        <span class="red" v-if="percentage > 100 || percentage < 1">
          {{$t('m.输入值不在有效范围内')}}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { cycleTimes } from "../assets/js/cycleTime";
import { querySignalList } from "../request/api";
export default {
  components: {},
  props: {
    childData: Array,
    links: Array,
    current: String,
    executeStates: Boolean,
    actionParams: Object,
  },
  data() {
    return {
      cycle: "", //周期
      //   周期数据
      cycleList: cycleTimes,
      durationDay: null, //时长天
      duration: null, //时长 时分秒
      dateTime: null, //日期

      Switch: true, //开关
      switchValue: null, //开关值

      select: null,
      // 下拉数据
      options: [
        {
          value: "选项1",
          label: "黄金糕",
        },
        {
          value: "选项2",
          label: "双皮奶",
        },
        {
          value: "选项3",
          label: "蚵仔煎",
        },
        {
          value: "选项4",
          label: "龙须面",
        },
        {
          value: "选项5",
          label: "北京烤鸭",
        },
      ],
      value: "",
      selectIdx: 0, //多选高亮
      percentage: 100, //百分比
      childParams: null,
      linksParams: null,
      SymbolList: [
        { value: "=" },
        { value: ">" },
        { value: "<" },
        { value: "≥" },
        { value: "≦" },
      ],

      team: null,
      project: null,
      signalList: [], //信号列表
      signal: null, //选择的 信号
      signalParam: [], //信号属性列表
      signalAttribute: null, //选择的属性
      symbol: "=", //匹配符号
      signalValue: null, //输入的值
    };
  },
  computed: {},
  watch: {
    actionParams(New, old) {
      console.log(New);
      this.childParams = JSON.parse(JSON.stringify(New.controls));
      this.linksParams = JSON.parse(JSON.stringify(New.links));
      this.linksChange();
      for (let key of this.childParams) {
        if (key.controltype == "signal-input") {
          this.signal = key.signal ? key.signal : null;
          this.signalParam = key.signalParamList ? key.signalParamList : null;
          this.signalAttribute = key.selectSignalParams
            ? key.selectSignalParams
            : null;
          this.signalValue = key.value ? key.value : null;

          if (key.signalOpt) {
            switch (key.signalOpt) {
              case "eq":
                this.symbol = "=";
                break;
              case "gt":
                this.symbol = ">";
                break;
              case "lt":
                this.symbol = "<";
                break;
              case "le":
                this.symbol = "≦";
                break;
              case "ge":
                this.symbol = "≥";
                break;
              default:
                break;
            }
          } else {
            this.symbol = "=";
          }
        }
      }

      console.log(this.executeStates);
    },
  },
  methods: {
    //多控选择选中
    multiSwitch(e, val, idx, item) {
      if (!this.executeStates) {
        return;
      }
      console.log(val, idx, item);
      item.proposed = val.value;

      this.linksChange();
    },

    handleChange(value) {
      console.log(value);
    },
    // 资金占比解释文案
    proportionFunds() {
      this.$alert(
        "<div style='color:#102859'><p>*下单资金占比 处在订单链首个订单时，以订单设置的投入为全部资金，每个动作可按比例配置投入，默认100%；</p><div>处在订单链次级订单时，以上一订单完成后所得持仓为全部资金，每个动作可按比例配置投入，默认100%</div><div>当订单类型为合约时数量自动取整：其中开仓向下取整；平仓向上取整。</div><div></div>",
        "下单资金占比",
        {
          confirmButtonText: "确定",
          dangerouslyUseHTMLString: true,
        }
      );
    },

    //links联动
    linksChange() {
      console.log(this.childParams);
      console.log(this.linksParams);
      let links = this.linksParams;
      let params = JSON.parse(JSON.stringify(this.childParams));
      if (links && links.length > 0) {
        let newParama = [];
        for (let key of links) {
          for (let item of params) {
            if (item.name == key.name) {
              let rules = key.rules;
              for (let cont of rules) {
                let symbol = cont.opt;
                if (symbol == "eq") {
                  // console.log(item.proposed, cont.value);
                  if (item.proposed == cont.value) {
                    for (let tr of cont.true) {
                      for (let para of params) {
                        if (para.name == tr.name) {
                          let newPara = {};
                          newPara = {
                            ...JSON.parse(JSON.stringify(para)),
                            ...JSON.parse(JSON.stringify(tr.attr)),
                          };
                          newParama.push(newPara);
                        }
                      }
                    }
                  } else {
                    // 不等为false时的变化
                    for (let tr of cont.false) {
                      for (let para of params) {
                        if (para.name == tr.name) {
                          let newPara = {};
                          newPara = {
                            ...JSON.parse(JSON.stringify(para)),
                            ...JSON.parse(JSON.stringify(tr.attr)),
                          };
                          newParama.push(newPara);
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        // console.log(this.childParams);
        // console.log(newParama);
        let childList = JSON.parse(JSON.stringify(this.childParams));
        for (let i = 0; i < childList.length; i++) {
          for (let j = 0; j < newParama.length; j++) {
            if (childList[i].name == newParama[j].name) {
              childList[i].visiable = newParama[j].visiable + "";
            }
          }
        }

        console.log(childList);
        this.childParams = JSON.parse(JSON.stringify(childList));
      }
    },

    //查询信号列表
    querySignalList() {
      let payload = {
        token: sessionStorage.getItem("token"),
        teamId: this.team.teamId,
        isTotal: true,
        // this.project.teamProjectId
      };
      querySignalList(payload).then((res) => {
        if (res.status == 200) {
          console.log("信号列表", res.data.signalList);
          this.signalList = res.data.signalList;
        }
      });
    },

    // 信号选择
    signalChange(v) {
      console.log(v);
      console.log(this.childParams);

      // for (let key of this.childParams) {
      //   if (key.controltype == "signal-input") {
      //     key.signalParamList = null;
      //     key.signalAttribute=null
      //     key.signalName=null
      //   }
      // }


      let list = this.signalList;
      let signal=null
      for (let key of list) {
        if (v == key.signalName) {
          signal=key
          this.signalParam = JSON.parse(key.paramBody);
        }
      }

      for (let key of this.childParams) {
        if (key.controltype == "signal-input") {
          key.signal = signal;
          key.signalOpt = "=";
          key.signalParamList = JSON.parse(signal.paramBody);
          key.signalAttribute=JSON.parse(signal.paramBody)[0]
          key.signalAttributeName=JSON.parse(signal.paramBody)[0].label
        }
      }
      console.log(this.childParams);
      return;
      this.signalParam = JSON.parse(v.paramBody);
      console.log(this.signalParam);

      for (let key of this.childParams) {
        if (key.controltype == "signal-input") {
          key.signal = v;
          key.signalOpt = "=";
          key.signalParamList = JSON.parse(v.paramBody);
        }
      }

      console.log(this.childParams);
    },
    // 信号属性选择
    signalParamsChange(v) {
      console.log(v)
      this.signalAttribute = v;
      console.log(this.signalAttribute);

      for (let key of this.childParams) {
        if (key.controltype == "signal-input") {
          key.signalAttribute = v;
          key.signalAttributeName=v.label
        }
      }
      console.log(this.childParams);
      this.$forceUpdate()
    },

    // 信号匹配符选择
    symbolChange(v) {
      console.log(v);
      //  opt: 比较符，eq(等于)、lt(小于)、gt(大于)、le(小于等于)、ge(大于等于)  ≥ ≦

      let symbol = null;
      switch (v) {
        case "=":
          symbol = "eq";
          break;
        case ">":
          symbol = "gt";
          break;
        case "<":
          symbol = "lt";
          break;
        case "≦":
          symbol = "le";
          break;
        case "≥":
          symbol = "ge";
          break;
        default:
          break;
      }

      for (let key of this.childParams) {
        if (key.controltype == "signal-input") {
          key.signalOpt = symbol;
        }
      }

      console.log(this.childParams);
    },

    signalValueChange(v) {
      for (let key of this.childParams) {
        if (key.controltype == "signal-input") {
          key.value = v;
        }
      }
      console.log(this.childParams);
    },

    isSignal() {
      let list = this.childData;
      for (let key of list) {
        if (key.controltype == "signal-input") {
          this.querySignalList();
        }
      }
    },

    updatedParams() {
      let list = JSON.parse(JSON.stringify(this.childParams));
      for (let key of list) {
        if (key.controltype == "signal-input") {
          if (key.signalOpt) {
            switch (key.signalOpt) {
              case "eq":
                this.symbol = "=";
                break;
              case "gt":
                this.symbol = ">";
                break;
              case "lt":
                this.symbol = "<";
                break;
              case "le":
                this.symbol = "≦";
                break;
              case "ge":
                this.symbol = "≥";
                break;
              default:
                break;
            }
          } else {
            this.symbol = "=";
          }

          // if (key.selectSignalParams) {
          //   this.signalAttribute = key.selectSignalParams;
          // }
          // if (key.signal) {
          //   this.signalParam = JSON.parse(key.signal.paramBody);
          // }
        }
      }
    },
  },
  created() {},
  mounted() {
    let project = JSON.parse(sessionStorage.getItem("project"));
    this.project = project;
    let team = JSON.parse(sessionStorage.getItem("defaultTeam"));
    this.team = team;

    console.log("actionParams", this.actionParams);
    console.log("状态", this.executeStates);

    this.childParams = JSON.parse(JSON.stringify(this.actionParams.controls));
    this.linksParams = JSON.parse(JSON.stringify(this.actionParams.links));

    console.log(this.childParams, this.linksParams);

    this.linksChange();
    this.querySignalList();
    this.updatedParams();
  },
  beforeCreate() {},
  beforeMount() {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {},
  activated() {},
};
</script>
<style lang='scss' scoped>
//@import url(); 引入公共css类

.parameterComponent {
  .params {
    .title {
      margin-bottom: 10px;
      font-size: 16px;
      color: #102859;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .title-left {
        display: flex;
        align-items: center;
        .red {
          color: #e12525;
        }
        .icon-jieshi {
          width: 14px;
          height: 14px;
          cursor: pointer;
          margin-left: 6px;
        }
        .rang {
          color: #55698a;
          font-size: 12px;
          margin-left: 10px;
        }
      }
      .title-right {
        font-size: 12px;
        display: flex;
        align-items: center;
      }
    }
    .params-box {
      margin-bottom: 20px;
      .red {
        display: inline-block;
        color: #e12525;
        font-size: 12px;
        margin-top: 6px;
      }
      .el-select,
      .el-date-editor,
      .el-cascader {
        width: 100%;
      }

      .radio-warp {
        display: flex;
        align-items: center;
        span {
          flex: 1;
          color: #102859;
          height: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 4px;
          cursor: pointer;
          background: #f7f7f7;
        }
        .on {
          background: #2174ff;
          color: #fff;
        }
      }

      //   时长选择
      .duration {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .el-input,
        .el-date-editor {
          width: 40%;
        }
      }
    }
    .warp {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .left,
      .center,
      .right {
        width: 30%;
        .el-select,
        .el-input {
          width: 100%;
        }
        .title {
          height: 21px;
          margin-bottom: 10px;
        }
      }
    }
  }
}
</style>