<template>
  <div class="login">

    <div class="login-warp">
      <div class="login-box">

        

        <div class="login-small-box">
          <span class="title">{{$t('m.扫码登录')}}</span>
          <div class="code-warp">
            
            <div class="qr-code">
              <div id="qrCode" ref="qrCodeDiv" class="code"></div>
            </div>

            <div class="validity">
              <span>{{$t('m.二维码有效期')}} </span>
              <span class="num">{{ num }}</span>
              <span> s</span>
            </div>

            <div class="qr-code-invalid" v-if="!codeShow">
              <div class="warp" @click="updateCode">
                <img src="../assets/icon/shuaxin.png" alt="" />
                <span class="tips">{{$t('m.刷新二维码')}}</span>
              </div>
            </div>
          </div>

          <div class="tips">
            <span>{{$t('m.打开 VtradingAPP 扫一扫登录')}}</span>
          </div>
        </div>
      </div>
    </div>

    <!-- 未绑GA -->

    <!--已绑GA验证 -->
    <el-dialog :visible.sync="gaShow" width="20%" :close-on-click-modal="false">
      <div>
        <div class="gaTitle">
          <p>{{$t('m.安全验证')}}</p>
          <span>{{$t('m.为了您的账户安全，请完成以下验证操作')}}</span>
        </div>
        <span style="display: inline-block; font-size: 14px; margin-bottom: 8px"
          >{{$t('m.谷歌验证码')}}</span
        >
        <el-input v-model="gaCode"></el-input>
        <span class="tips">{{$t('m.请输入6位谷歌验证码')}}</span>
      </div>
      <span slot="footer" class="dialog-footer">
        <div class="btn-warp">
          <!-- <el-button type="primary" @click="migrationUser">确 定</el-button> -->
          <!-- <span @click="GAbtn()">提 交</span> -->
          <el-button type="primary" class="btn" @click="GAbtn()"
            >{{$t('m.提 交')}}</el-button
          >
          <!-- <el-button type="info" disabled v-else class="btn">提 交</el-button> -->
        </div>
      </span>
    </el-dialog>

    <!-- 未绑GA 提示 -->
    <el-dialog
      title=""
      :visible.sync="noGa"
      width="23%"
      :before-close="handleClose"
      :show-close="false"
    >
      <div class="noGa">
        <div class="title">
          <img src="../assets/icon/icon@2x (3).png" alt="" />
          <span>{{$t('m.安全验证未绑定')}}</span>
        </div>
        <div class="text">
          {{$t('m.为了确保账户安全，请先前往APP端-安全中心绑定谷歌验证器。')}}
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="noGa = false">{{$t('m.知道啦')}}</el-button>
      </span>
    </el-dialog>
  </div>
</template>




<script>
import Vuex from "vuex";
const $ = window.$;
import QRCode from "qrcodejs2";
import { getTemporaryToken, login, checkGACode } from "../request/api";
export default {
  components: {},
  data() {
    return {
      num: 0, //二维码倒计时
      codeShow: true,
      qrcode: null,
      temporaryToken: null,
      gaShow: false,
      gaCode: null,
      token: null,
      account: null,
      teamId: null,
      noGa: false,
    };
  },
  computed: {},
  watch: {},
  methods: {
    Updated() {
      getTemporaryToken().then((res) => {
        console.log(res);
        if (res.status == 200) {
          this.temporaryToken = res.data.temporaryToken;
          sessionStorage.setItem("temporaryToken", this.temporaryToken);
          this.num = 90;
          this.codeShow = true;
          if (this.qrcode) {
            this.qrcode.makeCode(JSON.stringify(res.data));
            this.qrcode._el.title = "";
          } else {
            this.qrcode = new QRCode(this.$refs.qrCodeDiv, {
              text: JSON.stringify(res.data),
              width: 160,
              height: 160,
              colorDark: "#333333", //二维码颜色
              colorLight: "#ffffff", //二维码背景色
              correctLevel: QRCode.CorrectLevel.L, //容错率，L/M/H
            });
            this.qrcode._el.title = "";
          }
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    countDown() {
      var down = () => {
        if (this.num > 0) {
          this.num--;
          this.login();
          setTimeout(down, 1000);
        } else {
          // this.expired = true;
          // clearTimeout(this.loopHandler);
          this.codeShow = false;
        }
      };
      setTimeout(down, 1000);
    },
    // 刷新二维码
    updateCode() {
      this.token = null;
      this.account = null;
      this.temporaryToken = null;
      this.Updated();
      this.countDown();
    },

    login() {
      if (!this.temporaryToken) {
        return;
      }
      let payload = {
        temporaryToken: this.temporaryToken,
        teamId: this.teamId,
      };

      login(payload).then((res) => {
        if (res.status == 200) {
          if (res.data.state == true) {
            this.num = 0;
            this.token = res.data.token;
            this.account = res.data.account;
            this.gaShow = res.data.GA_binding;
            this.noGa = !res.data.GA_binding;
            console.log(this.noGa, this.gaShow);
          }
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    GAbtn() {
      console.log(this.token);
      console.log(this.account);
      if (!this.gaCode) {
        this.$message.error(this.$t('m.请输入GA'));
        return;
      }
      let payload = {
        token: this.token,
        gaCode: this.gaCode,
        phone: this.account.phone ? this.account.phone : this.account.email,
      };
      checkGACode(payload).then((res) => {
        if (res.status == 200) {
          sessionStorage.setItem("account", JSON.stringify(this.account));
          sessionStorage.setItem("token", this.token);
          this.gaShow = false;

          let url = window.location.href;
          var params = url.split("?")[1];
          if (params) {
            this.$message.success(this.$t('m.登录成功'));
            this.$router.push({ path: "/" });
          } else {
            if (res.data.isNotTeam) {
              this.$message.success(this.$t('m.登录成功'));
              this.$router.push({ path: "/" });
            } else {
              this.$router.push({ path: "firstLogin" });
            }
            console.log(res.data);
          }
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    handleClose(done) {
      this.$confirm(this.$t('m.确认关闭？'))
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
  },
  created() {},
  mounted() {},
  beforeCreate() {},
  beforeMount() {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {},
  activated() {
    this.$store.commit('setIsTeam',false)
    sessionStorage.clear();
    this.Updated();
    this.countDown();
    let url = window.location.href;
    if (url.split("?")[1]) {
      var params = url.split("?")[1].split("&");
      var obj = {};
      params.map((v) => (obj[v.split("=")[0]] = v.split("=")[1]));
      if (obj.teamId) {
        this.teamId = obj.teamId;
      }
    }
  },
  deactivated() {
    this.$destroy();
  },
};
</script>




<style lang='scss' scoped>
.login {
  width: 100%;
  height: 100%;
  .bottom {
    width: 100%;
    height: 15%;
    // padding:0 15% ;
    img {
      width: 180px;
      height: 40px;
      margin-top: 3%;
      margin-left: 15%;
    }
  }
  .content {
    width: 100%;
    height: 70%;
    background: url("../assets/image/bg@2x.png") no-repeat;
    background-size: 100% 100%;
    .content-box {
      display: flex;
      justify-content: space-between;
      padding: 0 15%;
      height: 100%;
      .title {
        margin-top: 10%;
        font-size: 36px;
        color: #fff;
      }
      .con-login {
        // display: flex;
        // flex-direction: column;
        // justify-content: space-around;
        width: 320px;
        background: #fff;
        margin: 9%;
        border-radius: 10px;
        .login-title {
          margin: 20px 0 0 20px;
        }

        .code-warp {
          position: relative;
          .qr-code {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin: 15px 0;
            .code {
              width: 160px;
              height: 160px;
              border: 1px solid #d5d5d5;
              padding: 10px;
              border-radius: 4px;
            }
            .validity {
              margin-top: 10px;
              font-size: 14px;
              .num {
                margin: 0 5px;
                color: red;
              }
            }
          }

          .qr-code-invalid {
            width: 100%;
            // margin: 30px 0 30px 0;
            display: flex;
            justify-content: center;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 100;
            .warp {
              width: 185px;
              height: 185px;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              background: url("../assets/image/img@2x (1).png") no-repeat;
              background-size: 100% 100%;
              .tips {
                color: #fff;
                font-size: 12px;
              }
              .btn {
                font-size: 14px;
                padding: 5px 25px;
                color: #fff;
                background: #2780f9;
                border-radius: 4px;
              }
            }
          }
        }
        .tips {
          font-size: 12px;
          display: flex;
          justify-content: center;
          align-items: center;
          // margin: 0 0 50px 0;
          .app {
            color: #2684ff;
            margin: 0 5px;
          }
        }
      }
    }
  }

  .login-warp {
    // width: 1920px;
    width: 100vw;
    height: 100vh;
    background: url("../assets/image/denglu2.jpg") no-repeat center;
    // background-size: 100vw 100vh;
    position: relative;
    .login-box {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      right: 300px;
    }
    .login-small-box {
      width: 234px;
      height: 261px;
      background: url("../assets/image/erweima.png") no-repeat;
      background-size: 246px 261px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding-right: 12px;
      .title {
        font-size: 26px;
        color: #00e2ff;
        letter-spacing: 5px;
        margin-top: 73px;
        margin-right: 4px;
      }
      .code-warp {
        position: relative;
        margin-top: 22px;
        .qr-code {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 180px;
          height: 180px;
          background: #fff;
          margin: -9px 12px 0 0;
          border-radius: 8px;
          .code {
            width: 160px;
            height: 160px;
            // padding: 10px;
            border-radius: 4px;
          }
        }
        .validity {
          margin-top: 40px;
          font-size: 14px;
          color: #bce1ff;
          text-align: center;
          .num {
            // margin: 0 5px;
            color: #00e2ff;
          }
        }
        .qr-code-invalid {
          // width: 100%;
          // margin: 30px 0 30px 0;
          display: flex;
          justify-content: center;
          position: absolute;
          top: -9px;
          left: 0;
          z-index: 100;
          .warp {
            width: 180px;
            height: 180px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background: url("../assets/image/img@2x (1).png") no-repeat;
            background-size: 100% 100%;
            cursor: pointer;
            .tips {
              color: #fff;
              font-size: 12px;
              // margin-bottom: 20px;
            }
            .btn {
              font-size: 14px;
              padding: 5px 25px;
              color: #fff;
              background: #2174ff;
              border-radius: 4px;
              cursor: pointer;
            }
          }
        }
      }
      .tips {
        font-size: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        // margin: 0 0 50px 0;
        color: #bce1ff;
        .app {
          color: #2684ff;
          margin: 0 5px;
        }
      }
    }
  }

  // ga 弹窗
  .gaTitle {
    font-size: 18px;
    color: #333;
    text-align: center;
    margin-bottom: 20px;
    span {
      color: #8f9096;
      font-size: 10px;
      margin-top: 8px;
      display: inline-block;
    }
  }
  .btn-warp {
    display: flex;
    justify-content: center;
    padding-bottom: 10px;
    .btn {
      width: 100%;
      // height: 40px;
      // text-align: center;
      // line-height: 40px;
      // background: #409eff;
      // color: #fff;
      // border-radius: 4px;
      // cursor: pointer;
    }
  }
  .tips {
    display: inline-block;
    margin: 8px 0 0 6px;
    font-size: 12px;
    color: #999;
  }
}
.noGa {
  // padding-left: 10px;
  .title {
    font-size: 20px;
    color: #313237;
    display: flex;
    align-items: center;
    img {
      margin-right: 8px;
      width: 18px;
      height: 18px;
    }
  }
  .text {
    margin-top: 16px;
    font-size: 14px;
    color: #5e5f64;
  }
}
::v-deep .el-dialog__body {
  padding-top: 1px;
}
::v-deep .el-dialog__footer {
  padding-bottom: 30px;
}
</style>