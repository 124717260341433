<template>
  <div class="TeamSetup">
    <el-container>
      <el-header>
        <div class="header-warp" v-if="defaultTeam">
          <i class="el-icon-back" @click="goBack"></i>

          <div class="name">
            <span>Hey！</span>
            <div>
              <span>{{$t('m.这里是')}} </span>
              <span class="blue">{{ defaultTeam.teamName }}</span>
            </div>
          </div>

          <span @click="deleteTeamBtn" class="dismiss">
            {{$t('m.解散团队')}}</span>
        </div>
      </el-header>

      <div class="content">
        <el-container>
          <!-- aside -->
          <el-aside style="padding-right: 24px" width="303px">
            <el-menu
              background-color="#fff"
              text-color="#333"
              active-text-color="#007aff">
              <span class="invite" @click="getInvitationUrl">
                <img src="../assets/icon/xinzeng-bai.png" alt="" />
                <span>{{$t('m.邀请成员')}}</span>
                <!-- <span class="invite-icon">+</span>邀请成员 -->
              </span>
              <span class="team">
                <el-dropdown-item
                  :class="active === 1 ? 'select' : ''"
                  class="teamAll"
                  @click.native="memberBtn(1)">
                  <span class="li-title">
                    <img src="../assets/icon/quanbu.png" alt="" />
                    <span>{{$t('m.全部成员')}}</span>
                  </span>
                  <span>{{ findTeamMember }}</span>
                </el-dropdown-item>
                <el-dropdown-item
                  :class="active === 2 ? 'select' : ''"
                  class="teamOut"
                  @click.native="memberBtn(2)">
                  <span class="li-title">
                    <img src="../assets/icon/weijiaru.png" alt="" />
                    <span>{{$t('m.未加入项目成员')}}</span>
                  </span>
                  <span>{{ NoProjectMember }}</span>
                </el-dropdown-item>
              </span>
              <span class="main">
                <div class="main-title">
                  <img src="../assets/icon/xiangmu.png" alt="" />
                  <span>{{$t('m.项目组')}}</span>
                </div>
                <div
                  class="main-nav"
                  v-for="(item, index) in projectTeamList"
                  :key="index">
                  <div
                    class="main-nav-item"
                    :class="index === projectNum ? 'select' : ''"
                    @click="projectBtn(index)">
                    {{ item.projectName }}
                  </div>
                </div>
              </span>
            </el-menu>
          </el-aside>
          <!-- main -->
          <el-main>
            <AllMembers @member="memberData" :active="active" ref="AllMember" />
            <TeamIntroduction v-if="active == 3" />
          </el-main>
        </el-container>
      </div>
    </el-container>
    <!-- 邀请成员 -->
    <el-dialog
      :title="$t('m.邀请成员')"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose">
      <div class="InviteMembers">
        <div class="title">
          <span>{{$t('m.通过链接邀请')}}</span>
          <span>{{$t('m.链接有效期1小时')}}</span>
        </div>
        <el-input
          :placeholder="$t('m.请输入内容')"
          v-model="invitationUrl"
          :readonly="true"
          id="url">
          <template slot="append">
            <!-- 复制链接 -->
            <el-button type="primary" @click="copyUrl(invitationUrl)">
              {{$t('m.复制链接')}}</el-button>
          </template>
        </el-input>
        <!-- <span>{{ invitationUrl }}</span> -->
      </div>

      <!-- <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false"
          >确 定</el-button
        >
      </span> -->
    </el-dialog>
    <!-- 解散团队 -->
    <el-dialog
      :title="$t('m.解散团队')"
      class="deleteTeam"
      :visible.sync="deleteTeam"
      width="460px"
      :close-on-click-modal="false"
      top="260px">
      <span style="color: #5e5f64; font-size: 14px">
      {{$t('m.解散团队操作一经确认，不可恢复呦')}}</span>
      <input
        type="text"
        :placeholder="$t('m.请输入安全验证码(谷歌验证码)')"
        style="
          border: 0;
          border-bottom: 2px solid #e6e7ed;
          font-size: 12px;
          width: 421px;
          margin-top: 10px;
          padding: 5px 0;
        " v-model="deleteTeamData" />
      <span slot="footer" class="dialog-footer">
        <el-button
          style="padding: 0"
          class="dialogBtn"
          @click="deleteTeam = false">
          {{$t('m.取 消')}}</el-button>
        <el-button
          class="dialogBtn"
          style="background: #f56c6c; border: 0; padding: 0"
          type="primary"
          @click="deleteTeamConfirm">
          {{$t('m.确 定')}}</el-button>
      </span>
    </el-dialog>
    <!-- 解散团队但是无权限 -->
    <el-dialog
      :title="$t('m.抱歉，无法解散团队')"
      :visible.sync="deleteTeamVip"
      width="30%"
      :close-on-click-modal="false"
      top="270px">
      <span style="font-size: 14px">
        {{$t('m.您的团队下存在项目未删除，无法进行操作。')}}</span>
      <span slot="footer" class="dialog-footer btn">
        <span @click="deleteTeamVip = false">
          {{$t('m.知道啦')}}</span>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import AllMembers from "./teamSetup/allMembers";
import TeamIntroduction from "./teamSetup/teamIntroduction";

import {
  findTeamProjectCount,
  getInvitationUrl,
  updateTeamName,
  findNoProjectMember,
  findTeamMemberList,
  shiftOutTeam,
  dissolutionTeam,
  checkGACode,
  selectTemaBond,
} from "../request/api";
export default {
  components: {
    AllMembers,
    TeamIntroduction,
  },
  data() {
    return {
      active: 1,
      dialogVisible: false, //邀请成员显隐
      projectTeamList: [], //项目组列表
      invitationUrl: null, //邀请链接
      defaultTeam: null,
      modifyName: false, //修改名称
      teamName: null, //团队名称
      projectNum: null, //项目组索引
      findTeamMember: null, //成员数量
      NoProjectMember: null, //未加入成员数量
      teamProjectId: null, //当前项目id
      teamMemberList: null, //成员数据
      deleteTeam: false, //解散团队
      deleteTeamData: null, //谷歌验证码
      deleteTeamVip: false, //解散团队没权限
      TemaBond: null, //团队保证金
    };
  },
  computed: {},
  watch: {},
  methods: {
    // 返回上一页
    goBack() {
      this.$store.commit("setNoviceActive", "2");
      this.$router.back(-1);
    },
    selectActive(num) {
      this.active = 1;
      setTimeout(() => {
        this.$refs.AllMember.selsectList(num);
      }, 300);
    },
    // 查询团队下的项目
    findTeamProjectCount() {
      let payload = {
        token: sessionStorage.getItem("token"),
        teamId: this.defaultTeam.teamId,
      };
      findTeamProjectCount(payload).then((res) => {
        if (res.status == 200) {
          shiftOutTeam;
          this.projectTeamList = res.data.projectCount;
        }
      });
    },
    // 获取邀请链接
    getInvitationUrl() {
      console.log(this.$route.query.userId == "false");
      if (this.$route.query.userId == "false") {
        return this.$alert(
          this.$t('m.您没有权限使用该功能，敬请谅解！'),
          this.$t('m.额，好像没有权限'),
          {
            confirmButtonText: this.$t('m.知道啦'),
          }
        );
      }
      this.dialogVisible = true;
      let payload = {
        token: sessionStorage.getItem("token"),
        teamId: this.defaultTeam.teamId,
      };
      console.log(this.defaultTeam.teamId);
      getInvitationUrl(payload).then((res) => {
        if (res.status == 200) {
          this.invitationUrl = res.data.url;
        }
      });
    },
    // 关闭弹窗
    handleClose(done) {
      this.invitationUrl = null;
      this.teamName = this.defaultTeam.teamName;
      console.log(this.teamName);
      done();
    },
    // 取消修改名称
    modifyHandleClose() {
      this.modifyName = false;
      this.teamName = this.defaultTeam.teamName;
    },
    // 修改团队名称
    updateTeamName() {
      if (this.teamName.length > 6) {
        this.$message.error(this.$t('m.团队名称不能超过6个字符'));
        return;
      }
      let payload = {
        token: sessionStorage.getItem("token"),
        teamId: this.defaultTeam.teamId,
        teamName: this.teamName,
      };
      updateTeamName(payload).then((res) => {
        if (res.status == 200) {
          this.$message.success(this.$t('m.修改成功'));
          this.defaultTeam.teamName = this.teamName;
          sessionStorage.setItem(
            "defaultTeam",
            JSON.stringify(this.defaultTeam)
          );
          console.log(res.data);
          this.modifyName = false;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 复制链接
    copyUrl(url) {
      console.log(url);
      var Url2 = document.getElementById("url");
      Url2.select(); // 选择对象
      document.execCommand("Copy");
      this.$message.success(this.$t('m.复制成功'));
      this.dialogVisible = false;
    },
    // 项目组按钮
    projectBtn(index) {
      this.projectNum = index;
      this.active = this.projectTeamList[index].teamProjectId;
    },
    // 全部成员按钮
    memberBtn(index) {
      this.projectNum = null;
      this.active = index;
      if (index === 1) {
        this.findTeamMemberList();
      } else if (index === 2) {
        this.findNoProjectMember();
      }
    },
    // 查询全部成员
    findTeamMemberList(payload) {
      payload = {
        token: sessionStorage.getItem("token"),
        teamId: this.defaultTeam.teamId,
      };
      findTeamMemberList(payload).then((res) => {
        if (res.status == 200) {
          console.log(res);
          this.teamMemberList = res.data.teamMemberList;
          this.findTeamMember = res.data.teamMemberList.length;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 查询未加入成员
    findNoProjectMember(payload) {
      payload = {
        token: sessionStorage.getItem("token"),
        teamId: this.defaultTeam.teamId,
      };
      findNoProjectMember(payload).then((res) => {
        if (res.status == 200) {
          this.teamMemberList = res.data.noProjectMemberList;
          this.NoProjectMember = res.data.noProjectMemberList.length;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 解散团队
    dissolutionTeam(payload) {
      payload = {
        token: sessionStorage.getItem("token"),
        teamId: this.defaultTeam.teamId,
      };
      dissolutionTeam(payload).then((res) => {
        if (res.status == 200) {
          this.deleteTeamData = null;
          let list = JSON.parse(sessionStorage.getItem("MessageTemaList"));

          for (let i = 0; i < list.length; i++) {
            if (list[i].teamId == this.defaultTeam.teamId) {
              list.splice(i, 1);
            }
          }

          sessionStorage.setItem("MessageTemaList", JSON.stringify(list));
          sessionStorage.setItem("defaultTeam", JSON.stringify(list[0]));

          this.$message.success(this.$t('m.解散团队成功'));
          this.$router.push({ path: "/" });
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 解散团队按钮
    deleteTeamBtn() {
      console.log(this.TemaBond);
      if (this.TemaBond.usdtBond > 0 || this.TemaBond.vtnBond > 0) {
        return this.$message.warning(this.$t('m.请先提取团队保证金，再尝试解散团队'));
      }
      if (this.$route.query.userId == "false") {
        return this.$alert(
          this.$t('m.您没有权限使用该功能，敬请谅解！'),
          this.$t('m.额，好像没有权限'),
          {
            confirmButtonText: this.$t('m.知道啦'),
          }
        );
      }
      if (this.projectTeamList.length !== 0) {
        this.deleteTeamVip = true;
      } else {
        this.deleteTeam = true;
      }
    },
    // 确认解散团队按钮
    deleteTeamConfirm() {
      if (!this.deleteTeamData) {
        this.$message.error(this.$t('m.请输入GA'));
        return;
      }
      let payload = {
        token: sessionStorage.getItem("token"),
        gaCode: this.deleteTeamData,
        phone: this.account.phone ? this.account.phone : this.account.email,
      };
      checkGACode(payload).then((res) => {
        if (res.status == 200) {
          this.dissolutionTeam();
        } else {
          this.$message.error(res.msg);
          this.deleteTeamData = "";
        }
      });
      this.deleteTeam = false;
    },
    // 获取团队保证金
    selectTemaBond() {
      let payload = {
        token: sessionStorage.getItem("token"),
        teamId: this.defaultTeam.teamId,
      };
      selectTemaBond(payload).then((res) => {
        if (res.status == 200) {
          // this.$message.success("操作成功");
          this.TemaBond = res.data.TemaBond;

          console.log(res.data);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    memberData() {
      this.findNoProjectMember();
      this.findTeamMemberList();
    },
  },
  created() {
    let defaultTeam = sessionStorage.getItem("defaultTeam");
    this.defaultTeam = JSON.parse(defaultTeam);
    this.account = JSON.parse(sessionStorage.getItem("account"));
    this.teamName = this.defaultTeam.teamName;
    this.findTeamProjectCount();
    this.findNoProjectMember();
    this.findTeamMemberList();
    this.memberBtn(1);
  },
  mounted() {
    this.selectTemaBond();
  },
  beforeCreate() {},
  beforeMount() {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {},
  activated() {},
  deactivated() {
    this.$destroy();
  },
};
</script>
<style lang='scss' scoped>
.TeamSetup {
  width: 100%;
  height: 100%;
  background: #eff2f5;
  .content {
    padding: 20px 10%;
    height: 100%;
    background-color: #fff;
    color: #5e5f64;
    .invite {
      display: flex;
      align-items: center;
      font-size: 16px;
      color: #fff;
      border-radius: 6px;
      background: #2174ff;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 180px;
      height: 40px;
      cursor: pointer;
      margin-bottom: 20px;
      img {
        width: 14px;
        height: 14px;
        margin-right: 6px;
        margin-top: 4px;
      }
      // .invite-icon {
      //   width: 22px;
      //   height: 22px;
      //   background: #007aff;
      //   color: #fff;
      //   display: flex;
      //   justify-content: center;
      //   align-items: center;
      //   font-size: 26px;
      //   border-radius: 11px;
      //   margin-right: 8px;
      // }
    }
    .li-title {
      display: flex;
      align-items: center;
      line-height: 20px;
      img {
        margin-right: 8px;
      }
    }
    .team {
      display: flex;
      flex-direction: column;
      padding: 7px 0;
      border-top: 1px solid #e6e7ed;
      border-bottom: 1px solid #e6e7ed;
      .teamAll {
        height: 26px;
        padding: 12px 56px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
      }
      .teamOut {
        height: 26px;
        padding: 12px 56px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
      }
    }
    .main {
      .main-title {
        color: #8f9096;
        padding: 20px 0 8px 56px;
        display: flex;
        align-items: center;
        img {
          margin-right: 8px;
        }
      }
      .main-nav {
        .main-nav-item {
          padding: 12px 0 12px 90px;
          cursor: pointer;
        }
      }
    }
  }
  .el-container {
    height: 100%;
    // background: #eff2f5;
  }
  .el-header {
    height: 120px !important;
    text-align: center;
    // line-height: 140px;
    background: linear-gradient(270deg, #e4f6fd 0%, #f5fafe 100%);
    box-shadow: 0px 1px 6px 0px rgba(19, 57, 119, 0.06);
    color: #102859;
    .header-warp {
      height: 120px;
      display: flex;
      justify-content: space-between;
      // align-items: center;
      padding: 32px 50px;
      box-sizing: border-box;
      i {
        width: 24%;
        cursor: pointer;
        text-align: left;
      }
      .name {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        flex: 1;
        margin-top: 14px;
        :last-child {
          margin-top: 6px;
        }
        .blue {
          margin-left: 4px;
          color: #2174ff;
        }
      }
      .dismiss {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 100px;
        height: 30px;
        border-radius: 4px;
        border: 1px solid #2174ff;
        font-size: 16px;
        color: #2174ff;
        cursor: pointer;
        font-size: 16px;
        margin-top: 30px;
        padding: 0 10px;
      }
    }
  }

  .el-aside {
    background-color: #fff;
    color: #333;
    height: 100%;
    border-right: solid 1px #e6e6e6;
    .el-menu {
      border-right: 0px;
    }
  }

  .el-main {
    color: #333;
    margin: 0px 20px 20px 20px;
    font-size: 14px;
  }
  .InviteMembers {
    .title {
      font-size: 12px;
      color: #333;
      margin-bottom: 15px;
      :last-child {
        font-size: 10px;
        color: #999;
        margin-left: 5px;
      }
    }
    ::v-deep.el-input-group__append {
      background: #409eff;
      color: #fff;
      cursor: pointer;
    }
  }
  .tips {
    display: inline-block;
    margin: 8px 0 0 6px;
    font-size: 12px;
    color: #999;
  }
}
.select {
  background: #f5f6fa;
}

.dialog-footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.btn {
  display: flex;
  font-size: 14px;
  justify-content: flex-end;
  span {
    width: 70px;
    height: 33px;
    background: #66b1ff;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    cursor: pointer;
  }
}
.el-dialog__body {
  padding-top: 0;
}
</style>