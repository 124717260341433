<template>
  <div class="expressTransaction">

    <div class="content-warp">
      <!-- 中间内容 -->
      <div class="content">

        <!-- 账户 -->
        <div class="acc-warp">
          <span class="title">
            {{$t('m.账户')}}</span>
          <div class="select-exchange">
            <span>{{$t('m.交易所')}}：</span>
            <el-select
              v-model="exchangeName"
              filterable
              :placeholder="$t('m.请选择')"
              @change="checkExchange">
              <el-option
                v-for="item in exchangeList"
                :key="item.exchangeId"
                :label="item.exchangeName"
                :value="item.exchangeName">
              </el-option>
            </el-select>
          </div>

          <div
            class="select-type"
            style="
              margin: 15px 0;
              border-bottom: 1px solid #e6e9ee;
              padding-bottom: 15px;">
            <span>{{$t('m.交易类型')}}：</span>
            <el-select
              v-model="transactionType"
              filterable
              @change="typeChange">
              <el-option
                v-for="item in typeList"
                :key="item.value"
                :label="$t('m.'+item.label)" 
                :value="item.value">
              </el-option>
            </el-select>
          </div>

          <div
            v-if="accList"
            class="acc-warp-box"
            :class="[accList.length > 3 ? 'scrollStyle' : '']">
            <div
              v-for="(item, idx) in accList"
              :key="idx"
              class="acc-warp-cont">
              <div
                class="acc"
                :class="{ check: item.isCheck }"
                @click="accChange(item)">
                <div class="acc-box">
                  <span class="acc-name">{{
                    item.remarkCode ? item.remarkCode : item.publicCode
                  }}</span>

                  <div class="acc-ava" v-if="transactionType == 'spot'">
                    <span v-if="pair && pair.baseCoin != 'BTC'">{{
                      $t('m.可用')+" " +
                      Math.floor(Number(item.available) * 10000) / 10000 +
                      " " +
                      (pair ? pair.baseCoin : "--")
                    }}</span>

                    <span v-if="pair && pair.counterCoin != 'BTC'">{{
                      $t('m.可用')+" " +
                      Math.floor(Number(item.counterCoinNum) * 10000) / 10000 +
                      " " +
                      (pair ? pair.counterCoin : "--")
                    }}</span>

                    <span v-if="pair && pair.baseCoin == 'BTC'">{{
                      $t('m.可用')+" " +
                      Math.floor(Number(item.available) * 1000000) / 1000000 +
                      " " +
                      (pair ? pair.baseCoin : "--")
                    }}</span>
                    
                    <span v-if="pair && pair.counterCoin == 'BTC'">{{
                      $t('m.可用')+" " +
                      Math.floor(Number(item.counterCoinNum) * 1000000) /
                        1000000 +
                      " " +
                      (pair ? pair.counterCoin : "--")
                    }}</span>
                  </div>

                  <div class="acc-ava" v-else>
                    <span>{{
                      $t('m.可用')+" " +
                      item.available +
                      " USDT≈" +
                      (item.openSize ? item.openSize : "0") +
                      " " + $t('m.张')
                    }}</span>
                    <span>
                      <span>
                        <span style="color: #ff3a3a">
                          {{$t('m.多仓')}}</span>
                        <span>{{
                          $t('m.可平&(1)张').replace('&(1)', (item.buy ? Number(item.buy) : "0"))
                        }}</span>
                      </span>
                      |
                      <span>
                        <span style="color: #26b34b">空仓</span>
                        <span>{{
                          $t('m.可平&(1)张').replace('&(1)', (item.sell ? Number(item.sell) : "0"))
                        }}</span>
                      </span>
                    </span>
                    <span></span>
                  </div>
                </div>

                <div style="height: 100px; padding-top: 30px">
                  <el-checkbox
                    :key="new Date().getTime() + idx"
                    :checked="item.isCheck"></el-checkbox>
                </div>
              </div>

              <span
                v-if="transactionType != 'spot'"
                class="transfer"
                @click="transferBtn(item)">
                {{$t('m.划转')}}</span>
            </div>
          </div>

          <div class="default" v-else>
            <img src="../../assets/image/zanwuzhanghu.png" alt="" />
            <span>{{$t('m.暂无可用账户')}}</span>
          </div>

          <div class="acc-selectAll">
            <div>
              <el-checkbox
                :disabled="!accList"
                @change="accSelectAllBtn()"
                v-model="accSelectAll">
                {{$t('m.选择全部账户')}}</el-checkbox>
            </div>

            <div @click="selectAllShowBtn()">
              {{$t('m.展开全部账户')}}
              <img
                style="height: 14px; margin-left: 5px"
                src="../../assets/icon/home_right_more.png"
                alt=""/>
            </div>
          </div>

          <!-- 参数设置 提示框 -->
          <div class="params-tips" v-if="tipsShow && tipsNum == 3">
            <div class="triangle"></div>
            <div class="top">
              <img
                src="../../assets/icon/shanchu-bai.png"
                alt=""
                @click="tipsComplete()"/>
            </div>
            <h3>{{$t('m.参数设置')}}</h3>
            <p>{{$t('m.在此处选择委托方式并设置参数。')}}</p>
            <p>
              {{$t('m.*选择比例投入时可设置上下限限制，账户可用低于下限时不下单；高于上限的按上限下单。')}}
            </p>
            <div class="op-clk">
              <span class="next" @click="tipsNum = 4">
                {{$t('m.下一步')}}</span>
              <span class="previous-step" @click="tipsNum = 2">
                {{$t('m.上一步')}}</span>
            </div>
          </div>
        </div>

        <!-- 参数/动作 -->
        <div class="action-warp">
          <div class="label">
            <span v-if="transactionType == 'spot'">
              {{$t('m.币币交易')}}</span>
            <span v-if="pair && transactionType == 'spot'">{{
              (pair ? pair.counterCoin : "--") +
              "/" +
              (pair ? pair.baseCoin : "--")
            }}</span>

            <div
              class="contractPositionTType-warp"
              v-if="pair && transactionType != 'spot'">
              <el-tabs
                v-model="contractPositionTType"
                @tab-click="contractPositionTTypeChange">
                <el-tab-pane
                  class="tabPane"
                  :label="$t('m.开仓')"
                  :name="$t('m.开仓')">
                </el-tab-pane>
                <el-tab-pane
                  class="tabPane"
                  :label="$t('m.平仓')"
                  :name="$t('m.平仓')">
                </el-tab-pane>
              </el-tabs>
              <span>{{
                (pair ? pair.counterCoin : "--") +
                "/" +
                (pair ? pair.baseCoin : "--")
              }}</span>
            </div>
          </div>

          <div class="order-type">
            <div class="type-content-warp">
              <div class="type-content">
                <div @click="orderTypeSelect(0)">
                  <span
                    class="box"
                    :class="[orderType == 0 ? 'boxOn' : 'boxTwo']">
                    {{$t('m.市价')}}</span>
                </div>
                <div @click="orderTypeSelect(1)">
                  <span
                    class="box"
                    :class="[orderType == 1 ? 'boxOn' : 'boxTwo']">
                    {{$t('m.限价')}}</span>
                </div>
                <div @click="orderTypeSelect(2)">
                  <span
                    class="box"
                    :class="[orderType == 2 ? 'boxOn' : 'boxTwo']">
                    {{$t('m.条件单')}}</span>
                </div>
              </div>

              <div class="conte-size-lever" v-if="transactionType != 'spot'">
                <span
                  style="color: #102859; margin-right: 10px"
                  v-if="contractPositionTType == '开仓'">
                  {{$t('m.杠杆倍数')}}：</span>
                <el-select
                  v-model="lever"
                  :placeholder="$t('m.请选择')"
                  style="min-width: 75px"
                  v-if="contractPositionTType == '开仓'"
                  @change="leverChange">
                  <el-option
                    v-for="item in leverList"
                    :key="item"
                    :label="item + ' X'"
                    :value="item">
                  </el-option>
                </el-select>
              </div>
            </div>
          </div>

          <!-- 动作价格/投入 -->
          <div class="price-investment-warp" v-if="pair">
            <!-- 买单 -->
            <div v-if="orderType == 0 || orderType == 1" class="left">
              <div class="action-params">
                <span class="title">
                  {{$t('m.价格')}}</span>
                <el-input
                  v-model="actionPrice"
                  v-if="orderType == 0"
                  :placeholder="$t('m.以taker单立即成交')"
                  disabled>
                </el-input>
                <el-input
                  v-model="buyActionPrice"
                  v-if="orderType == 1"
                  :placeholder="$t('m.请输入价格')"
                  type="number"
                  @blur="buyActionPriceChange">
                  <i slot="suffix">{{ pair ? pair.baseCoin : "USDT" }}</i>
                </el-input>
              </div>

              <div class="action-investment">
                <div class="title">
                  <span>{{$t('m.投入')}}</span>
                  <img
                    src="../../assets/icon/jieshi.png"
                    alt=""
                    @click="inputInterpretation()"
                  />
                </div>
                <div class="investment-content">
                  <el-select
                    v-model="buyInvestmentType"
                    :placeholder="$t('m.请选择')"
                    @change="buyNumType">
                    <el-option
                      v-for="item in investmentTypeList"
                      :key="item.value"
                      :label="$t('m.'+item.label)" 
                      :value="item.value">
                    </el-option>
                  </el-select>

                  <el-input
                    v-model="buyNum"
                    :placeholder="$t('m.请输入')"
                    @blur="buyNumChange()"
                    type="number">
                    <!-- 现货 -->
                    <i
                      slot="suffix"
                      v-if="
                        buyInvestmentType == '固定投入' &&
                        transactionType == 'spot'">
                      {{ pair ? pair.baseCoin : "--" }}</i>
                    <i
                      slot="suffix"
                      v-if="
                        buyInvestmentType == '比例投入' &&
                        transactionType == 'spot'">%</i>
                    <!-- 合约 -->
                    <i
                      slot="suffix"
                      v-if="
                        buyInvestmentType == '固定投入' &&
                        transactionType != 'spot'">
                      {{$t('m.张')}}</i>
                    <i
                      slot="suffix"
                      v-if="
                        buyInvestmentType == '比例投入' &&
                        transactionType != 'spot'">%</i>
                  </el-input>
                </div>
              </div>

              <div class="u-l-limits" v-if="buyInvestmentType == '比例投入'">
                <div class="title">
                  <span>{{$t('m.资金上下限')}}</span>
                </div>
                <div class="investment-content">
                  <el-input
                    class="first"
                    v-model="buyMax"
                    type="number"
                    :placeholder="$t('m.上限选填')"
                    @blur="buyMaxChange()">
                    <i slot="suffix" v-if="transactionType == 'spot'">{{
                      pair ? pair.baseCoin : "--"
                    }}</i>
                    <!-- 合约 -->
                    <i
                      slot="suffix"
                      v-if="
                        sellInvestmentType == '比例投入' &&
                        transactionType != 'spot'
                      ">
                      {{$t('m.张')}}</i>
                  </el-input>

                  <el-input
                    v-model="buyMin"
                    type="number"
                    :placeholder="$t('m.下限选填')"
                    @blur="buyMinChange()">
                    <i slot="suffix" v-if="transactionType == 'spot'">{{
                      pair ? pair.baseCoin : "--"
                    }}</i>
                    <!-- 合约 -->
                    <i
                      slot="suffix"
                      v-if="
                        sellInvestmentType == '比例投入' &&
                        transactionType != 'spot'">
                      {{$t('m.张')}}</i>
                  </el-input>
                </div>
              </div>

              <div class="buyBtn" @click="placeOrder('buy')">
                {{$t('m.买入')}}</div>
              <div
                class="buyBtn"
                @click="placeOrder('buy')"
                v-if="
                  transactionType != 'spot' && contractPositionTType == '开仓'">
                {{$t('m.买入开多')}}
              </div>
              <div
                class="buyBtn"
                @click="placeOrder('buy')"
                v-if="
                  transactionType != 'spot' && contractPositionTType == '平仓'">
                {{$t('m.买入平空')}}
              </div>
            </div>
            <div class="center" v-if="orderType == 0 || orderType == 1"></div>

            <!-- 卖单 -->
            <div v-if="orderType == 0 || orderType == 1" class="right">
              <div class="action-params">
                <span class="title">
                  {{$t('m.价格')}}</span>
                <el-input
                  v-model="actionPrice"
                  v-if="orderType == 0"
                  :placeholder="$t('m.以taker单立即成交')"
                  disabled>
                </el-input>
                <el-input
                  v-model="sellActionPrice"
                  v-if="orderType == 1"
                  :placeholder="$t('m.请输入价格')"
                  type="number"
                  @blur="sellActionPriceChange">
                  <i slot="suffix" v-if="transactionType == 'spot'">{{
                    pair ? pair.baseCoin : "--"
                  }}</i>
                  <i slot="suffix" v-if="transactionType != 'spot'">{{
                    pair ? pair.baseCoin : "--"
                  }}</i>
                </el-input>
              </div>

              <div class="action-investment">
                <div class="title">
                  <span>{{$t('m.投入')}}</span>
                  <img
                    src="../../assets/icon/jieshi.png"
                    alt=""
                    @click="inputInterpretation()"
                  />
                </div>
                <div class="investment-content">
                  <el-select
                    v-model="sellInvestmentType"
                    :placeholder="$t('m.请选择')"
                    @change="sellNumType">
                    <template>
                      <el-option
                        v-for="item in investmentTypeList"
                        :key="item.value"
                        :label="$t('m.'+item.label)" 
                        :value="item.value">
                      </el-option>
                    </template>
                  </el-select>

                  <el-input
                    v-model="sellNum"
                    type="number"
                    :placeholder="$t('m.请输入')"
                    @blur="sellNumChange()">
                    <!-- 现货 -->
                    <i
                      slot="suffix"
                      v-if="
                        sellInvestmentType == '固定投入' &&
                        transactionType == 'spot'">
                      {{ pair ? pair.counterCoin : "USDT" }}</i>
                    <i
                      slot="suffix"
                      v-if="
                        sellInvestmentType == '比例投入' &&
                        transactionType == 'spot'">%</i>

                    <!-- 合约 -->
                    <i
                      slot="suffix"
                      v-if="
                        sellInvestmentType == '固定投入' &&
                        transactionType != 'spot'">
                      {{$t('m.张')}}</i>
                    <i
                      slot="suffix"
                      v-if="
                        sellInvestmentType == '比例投入' &&
                        transactionType != 'spot'">%</i>
                  </el-input>
                </div>
              </div>

              <div class="u-l-limits" v-if="sellInvestmentType == '比例投入'">
                <div class="title">
                  <span>{{$t('m.资金上下限')}}</span>
                  <!-- <img src="../../assets/icon/jieshi.png" alt="" /> -->
                </div>
                <div class="investment-content">
                  <el-input
                    class="first"
                    v-model="sellMax"
                    type="number"
                    :placeholder="$t('m.上限选填')"
                    @blur="sellMaxChange()">
                    <i slot="suffix" v-if="transactionType == 'spot'">{{
                      pair ? pair.counterCoin : "USDT"
                    }}</i>
                    <!-- 合约 -->
                    <i
                      slot="suffix"
                      v-if="
                        sellInvestmentType == '比例投入' &&
                        transactionType != 'spot'">
                      {{$t('m.张')}}</i>
                  </el-input>

                  <el-input
                    v-model="sellMin"
                    :placeholder="$t('m.下限选填')"
                    type="number"
                    @blur="sellMinChange()">
                    <i slot="suffix" v-if="transactionType == 'spot'">{{
                      pair ? pair.counterCoin : "USDT"
                    }}</i>
                    <!-- 合约 -->
                    <i
                      slot="suffix"
                      v-if="
                        sellInvestmentType == '比例投入' &&
                        transactionType != 'spot'
                      ">
                      {{$t('m.张')}}</i>
                  </el-input>
                </div>
              </div>
              <div
                class="sellBtn"
                @click="placeOrder('sell')"
                v-if="transactionType == 'spot'">
                {{$t('m.卖出')}}
              </div>
              <div
                class="sellBtn"
                @click="placeOrder('sell')"
                v-if="
                  transactionType != 'spot' && contractPositionTType == '开仓'
                ">
                {{$t('m.卖出开空')}}
              </div>
              <div
                class="sellBtn"
                @click="placeOrder('sell')"
                v-if="
                  transactionType != 'spot' && contractPositionTType == '平仓'
                ">
                {{$t('m.卖出平多')}}
              </div>
            </div>
            <!-- 条件单 -->
            <div v-if="orderType == 2" style="display: flex">
              <div class="conditionBox">
                <div
                  class="action-condition"
                  :class="[
                    buyInvestmentType == '比例投入' ? 'scrollStyle' : '',
                  ]">
                  <div class="condition-direction">
                    <div class="title">
                      {{$t('m.触发方向')}}</div>
                    <div style="display: flex">
                      <span
                        class="condition-box"
                        :class="[breach == 0 ? 'lanBox' : 'huiBox']">
                        {{$t('m.向上突破')}}</span>
                      <span
                        class="condition-box"
                        :class="[breach == 1 ? 'lanBox' : 'huiBox']"
                        @click="onBreachBtn(1)">
                        {{$t('m.向下突破')}}</span>
                    </div>
                  </div>

                  <div class="condition-price">
                    <div class="title">
                      {{$t('m.触发价格')}}</div>
                    <div>
                      <el-input
                        v-model="buyActionTriggerPrice"
                        :placeholder="$t('m.请输入价格')"
                        type="number"
                        @blur="buyActionTriggerChange()">
                        <i slot="suffix">{{ pair ? pair.baseCoin : "--" }}</i>
                      </el-input>
                    </div>
                  </div>

                  <div class="condition-priceType">
                    <div class="title">
                      {{$t('m.价格类型')}}</div>
                    
                    <div style="display: flex">
                      <span
                        class="condition-box"
                        :class="[breachType == 0 ? 'lanBox' : 'huiBox']"
                        @click="breachTypeBtn(0)">
                        {{$t('m.市价')}}</span>
                      <span
                        class="condition-box"
                        :class="[breachType == 1 ? 'lanBox' : 'huiBox']"
                        @click="breachTypeBtn(1)">
                        {{$t('m.限价')}}</span>
                    </div>
                  </div>

                  <div class="condition-priceType">
                    <div class="title">
                      {{$t('m.价格')}}</div>
                    <div>
                      <el-input
                        v-model="actionPrice"
                        v-if="breachType == 0"
                        :placeholder="$t('m.以taker单立即成交')"
                        disabled>
                      </el-input>
                      <el-input
                        v-model="buyActionPurchasePrice"
                        @blur="buyActionPurchaseChange()"
                        v-if="breachType == 1"
                        type="number"
                        :placeholder="$t('m.请输入价格')">
                        <i slot="suffix">{{ pair ? pair.baseCoin : "--" }}</i>
                      </el-input>
                    </div>
                  </div>

                  <div class="action-investment">
                    <div class="title">
                      <span>{{$t('m.投入')}}</span>
                      <img
                        src="../../assets/icon/jieshi.png"
                        alt=""
                        @click="inputInterpretation()"
                      />
                    </div>
                    <div class="investment-content">
                      <el-select
                        v-model="buyInvestmentType"
                        :placeholder="$t('m.请选择')"
                        @change="sellNumType">
                        <template>
                          <el-option
                            v-for="item in investmentTypeList"
                            :key="item.value"
                            :label="$t('m.'+item.label)" 
                            :value="item.value">
                          </el-option>
                        </template>
                      </el-select>
                      <el-input
                        v-model="buyNum"
                        type="number"
                        :placeholder="$t('m.请输入')"
                        @blur="buyNumChange()">
                        <i
                          slot="suffix"
                          v-if="
                            buyInvestmentType == '固定投入' &&
                            transactionType != 'spot'">
                          {{$t('m.张')}}</i>
                        <i
                          slot="suffix"
                          v-if="
                            buyInvestmentType == '固定投入' &&
                            transactionType == 'spot'">USDT</i>
                        <i slot="suffix" v-if="buyInvestmentType == '比例投入'">%</i>
                      </el-input>
                    </div>
                    <div
                      class="u-l-limits"
                      v-if="buyInvestmentType == '比例投入'">
                      <div class="title">
                        <span>{{$t('m.资金上下限')}}</span>
                      </div>
                      <div class="investment-content">
                        <el-input
                          type="number"
                          class="first"
                          style="margin: 0"
                          v-model="buyMax"
                          :placeholder="$t('m.上限选填')"
                          @blur="sellMaxChange()">
                          <i
                            slot="suffix"
                            v-if="buyInvestmentType == '比例投入'">
                            {{$t('m.张')}}</i>
                        </el-input>
                        <el-input
                          v-model="buyMin"
                          type="number"
                          :placeholder="$t('m.下限选填')"
                          @blur="sellMinChange()">
                          <i
                            slot="suffix"
                            v-if="buyInvestmentType == '比例投入'">
                            {{$t('m.张')}}</i>
                        </el-input>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="action-conditionBtn"
                  @click="placeOrder('buy')"
                  style="background: #ff3a3a">
                  {{
                    transactionType == "spot"
                      ? $t('m.买入')
                      : contractPositionTType == "平仓"
                        ? $t('m.买入平空')
                        : $t('m.买入开多')
                  }}
                </div>
              </div>
              <div class="conditionBox-center"></div>
              <div class="conditionBox">
                <div
                  class="action-condition"
                  :class="[
                    sellInvestmentType == '比例投入' ? 'scrollStyle' : '',
                  ]">
                  <div class="condition-direction">
                    <div class="title">
                      {{$t('m.触发方向')}}</div>
                    <div style="display: flex">
                      <span
                        class="condition-box"
                        :class="[breachTwo == 0 ? 'lanBox' : 'huiBox']"
                        @click="onBreachBtnTwo(0)">
                        {{$t('m.向上突破')}}</span>
                      <span
                        class="condition-box"
                        :class="[breachTwo == 1 ? 'lanBox' : 'huiBox']"
                        @click="onBreachBtnTwo(1)">
                        {{$t('m.向下突破')}}</span>
                    </div>
                  </div>
                  <div class="condition-price">
                    <div class="title">
                      {{$t('m.触发价格')}}</div>
                    <div>
                      <el-input
                        v-model="buyActionTriggerPriceTwo"
                        :placeholder="$t('m.请输入价格')"
                        type="number"
                        @blur="sellActionTriggerChange()">
                        <i slot="suffix">{{ pair ? pair.baseCoin : "--" }}</i>
                      </el-input>
                    </div>
                  </div>
                  <div class="condition-priceType">
                    <div class="title">
                      {{$t('m.价格类型')}}</div>
                    <div style="display: flex">
                      <span
                        class="condition-box"
                        :class="[breachTypeTwo == 0 ? 'lanBox' : 'huiBox']"
                        @click="breachTypeTwoBtn(0)">
                        {{$t('m.市价')}}</span>
                      <span
                        class="condition-box"
                        :class="[breachTypeTwo == 1 ? 'lanBox' : 'huiBox']"
                        @click="breachTypeTwoBtn(1)">
                        {{$t('m.限价')}}</span>
                    </div>
                  </div>

                  <div class="condition-priceType">
                    <div class="title">
                      {{$t('m.价格')}}</div>
                    <div>
                      <el-input
                        v-model="actionPrice"
                        v-if="breachTypeTwo == 0"
                        :placeholder="$t('m.以taker单立即成交')"
                        disabled>
                      </el-input>
                      <el-input
                        v-model="buyActionPurchasePriceTwo"
                        v-if="breachTypeTwo == 1"
                        :placeholder="$t('m.请输入价格')"
                        @blur="sellActionPurchaseChange()"
                        type="number">
                        <i slot="suffix">{{ pair ? pair.baseCoin : "--" }}</i>
                      </el-input>
                    </div>
                  </div>

                  <div class="action-investment">
                    <div class="title">
                      <span>{{$t('m.投入')}}</span>
                      <img
                        src="../../assets/icon/jieshi.png"
                        alt=""
                        @click="inputInterpretation()"
                      />
                    </div>
                    <div class="investment-content">
                      <el-select
                        v-model="sellInvestmentType"
                        :placeholder="$t('m.请选择')"
                        @change="sellNumType">
                        <template>
                          <el-option
                            v-for="item in investmentTypeList"
                            :key="item.value"
                            :label="$t('m.'+item.label)" 
                            :value="item.value">
                          </el-option>
                        </template>
                      </el-select>
                      <el-input
                        v-model="sellNum"
                        type="number"
                        :placeholder="$t('m.请输入')"
                        @blur="sellNumChange()">
                        <i
                          slot="suffix"
                          v-if="
                            sellInvestmentType == '固定投入' &&
                            transactionType !== 'spot'">
                            {{$t('m.张')}}</i>

                        <i
                          slot="suffix"
                          v-if="
                            buyInvestmentType == '固定投入' &&
                            transactionType == 'spot'">
                            {{ pair ? pair.counterCoin : "--" }}</i>
                        <i slot="suffix" v-if="sellInvestmentType == '比例投入'">%</i>
                      </el-input>
                    </div>
                    <div
                      class="u-l-limits"
                      v-if="sellInvestmentType == '比例投入'">
                      <div class="title">
                        <span>{{$t('m.资金上下限')}}</span>
                      </div>
                      <div class="investment-content">
                        <el-input
                          class="first"
                          style="margin: 0"
                          v-model="sellMax"
                          :placeholder="$t('m.上限选填')"
                          type="number"
                          @blur="sellMaxChange()">
                          <i
                            slot="suffix"
                            v-if="sellInvestmentType == '比例投入'">
                            {{$t('m.张')}}</i>
                        </el-input>
                        <el-input
                          v-model="sellMin"
                          :placeholder="$t('m.下限选填')"
                          type="number"
                          @blur="sellMinChange()">
                          <i
                            slot="suffix"
                            v-if="sellInvestmentType == '比例投入'">
                            {{$t('m.张')}}</i>
                        </el-input>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="action-conditionBtn"
                  @click="placeOrder('sell')"
                  style="background: #26b34b">
                  {{
                    transactionType == "spot"
                      ? $t('m.卖出')
                      : contractPositionTType == "平仓"
                        ? $t('m.卖出平多')
                        : $t('m.加')
                  }}
                </div>
              </div>
            </div>
          </div>

          <!-- 账户 提示框 -->
          <div class="acc-tips" v-if="tipsShow && tipsNum == 1">
            <div class="triangle"></div>
            <div class="left">
              <h3>{{$t('m.账户')}}</h3>
              <p>
                {{$t('m.此处可选择交易所与交易类型，目前支持币币与U本位永续合约交易。')}}
              </p>
              <p>
                {{$t('m.支持在多个交易所同时下单，下单时后台会自动过滤不支持的账户。')}}
              </p>
              <span class="next" @click="tipsNum = 2">
                {{$t('m.下一步')}}</span>
            </div>
            <img
              src="../../assets/image/image_01@2x.png"
              alt=""
              class="center"
            />
            <img
              src="../../assets/icon/shanchu-bai.png"
              alt=""
              class="right"
              @click="tipsComplete()"
            />
          </div>

          <!-- 交易对 提示框 -->
          <div class="pair-tips" v-if="tipsShow && tipsNum == 2">
            <div class="triangle"></div>
            <div class="left">
              <h3>{{$t('m.交易对')}}</h3>
              <p>
                {{$t('m.可在此处快速选择交易对，当选择多个交易所API时，行情价只显示其中一家价格，不影响实际下单。')}}
              </p>
              <div class="op-clk">
                <span class="next" @click="tipsNum = 3">
                  {{$t('m.下一步')}}</span>
                <span class="previous-step" @click="tipsNum = 1">
                  {{$t('m.上一步')}}</span>
              </div>
            </div>

            <img
              src="../../assets/image/image_02@2x.png"
              alt=""
              class="center"
            />
            <img
              src="../../assets/icon/shanchu-bai.png"
              alt=""
              class="right"
              @click="tipsComplete()"
            />
          </div>
        </div>

        <!-- 交易对 -->
        <div class="pair-warp">

          <div class="pair-warp-pair pairStyle">
            <div class="pair-warp-pairTitle" @click="pairShow = !pairShow">
              <div>
                {{
                  (pair ? pair.counterCoin : "--") +
                  "/" +
                  (pair ? pair.baseCoin : "--")
                }}
              </div>
              <div class="pairTitle-box">
                <div class="pairTitle-center"></div>
                <img
                  style="width: 10px"
                  src="../../assets/icon/more_bottom.png"
                  alt=""
                  v-if="!pairShow"
                />
                <img
                  style="width: 10px"
                  src="../../assets/icon/more_top.png"
                  alt=""
                  v-if="pairShow"
                />
              </div>
            </div>
            <div
              class="pairBox"
              :class="[pairCoinList.length > 17 ? 'scrollStyle' : '']"
              v-if="pairShow">
              <div class="pairItem"
                v-for="(item, idx) in pairCoinList"
                :key="'pairCoinList'+idx"
                @click="pairSelect(idx, item)">
                <div style="width: 120px">
                  {{ item.counterCoin + "/" + item.baseCoin }}
                </div>
                <span v-if="clientWidth > 1400">{{ Number(item.last) }}</span>
                <span
                  :style="
                    item.increase > 0
                      ? 'color:#FF3A3A'
                      : item.increase < 0
                      ? 'color:#26B34B'
                      : ''"
                  v-if="clientWidth > 1800">
                  {{ item.increase + "%" }}
                </span>
              </div>
            </div>

            <div
              class="pair-handicap"
              :style="transactionType == 'spot' ? '' : 'height:330px'"
              v-if="!pairShow">
              <div
                class="pair-handicap-bigBox"
                :style="transactionType == 'spot' ? '' : 'height:330px'">
                <div
                  class="pair-handicap-Box"
                  style="color: #9faabd; padding: 8px 0 6px; font-size: 12px">
                  <div>
                    {{ $t('m.价格')+"(" + (pair ? pair.baseCoin : "USDT") + ")" }}
                  </div>
                  <div>
                    {{ $t('m.数量')+"(" + (pair ? pair.counterCoin : "BTC") + ")" }}
                  </div>
                  <div v-if="clientWidth > 1900">
                    {{ $t('m.累计')+"(" + (pair ? pair.counterCoin : "BTC") + ")" }}
                  </div>
                </div>
                
                <div class="pair-handicap-item">
                  <template v-for="(item, index) in pairHandicap">
                    <div :key="'pairHandicap'+index"
                      style="display: flex; align-items: center"
                      v-if="item.transactionTypeCopyShow">
                      <span style="font-size: 20px; margin: 3px 10px 3px 0">
                        {{ pair ? Number(pair.last) : 0 }}
                      </span>
                      <span
                        style="margin-bottom: 3px"
                        :style="
                          pair
                          ? pair.increase > 0
                            ? 'color:#FF3A3A'
                            : pair.increase < 0
                              ? 'color:#26B34B'
                              : 'color: FF3A3A'
                          : 'color: FF3A3A'
                        ">
                        {{ (pair ? pair.increase : 0) + "%" }}
                      </span>
                    </div>
                    <div :key="'pairHandicap'+index" v-else class="pair-handicap-Box">
                      <div
                        :style="
                          item.priceShow == true
                            ? 'color:#26B34B'
                            : 'color:#FF3A3A'">
                        {{ item.price }}
                      </div>
                      <div>{{ item.qty }}</div>
                      <div v-if="clientWidth > 1900">
                        {{ item.cumulative }}
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>

          <div
            class="size-warp"
            :style="clientWidth > 1600 ? 'height:100px' : ''"
            v-if="transactionType != 'spot'">
            
            <div v-for="(item, idx) in dataList" :key="idx">
              <span class="size-name" v-if="item.exchangeName == 'binance'">{{
                item.exchangeName + "："
              }}</span>
              <span v-if="item.exchangeName == 'binance'">{{
                '1 '+$t('m.张')+" = " +
                Number(item.contractSize) +
                " " +
                item.counterCoin +
                " ≈ " +
                Number(item.price) +
                " USDT"
              }}</span>

              <span class="size-name" v-if="item.exchangeName == 'huobi'">{{
                item.exchangeName + "："
              }}</span>
              <span v-if="item.exchangeName == 'huobi'">{{
                '1 '+$t('m.张')+" = " +
                Number(item.contractSize) +
                " " +
                item.counterCoin +
                " ≈ " +
                Number(item.price) +
                " USDT"
              }}</span>

              <span class="size-name" v-if="item.exchangeName == 'okexv5'">{{
                item.exchangeName + "："
              }}</span>
              <span v-if="item.exchangeName == 'okexv5'">{{
                '1 '+$t('m.张')+" = " +
                Number(item.contractSize) +
                " " +
                item.counterCoin +
                " ≈ " +
                Number(item.price) +
                " USDT"
              }}</span>
            </div>
          </div>
        </div>
      </div>

      <!-- 底部持仓/委托 -->
      <div class="bottom-select">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane :label="$t('m.持仓')" name="1">
            <div class="select-warp">
              <div class="left">
                <div class="first">
                  <span class="lable">
                    {{$t('m.账户')}}：</span>
                  <el-input
                    v-model="btmSelectInp"
                    :placeholder="$t('m.请输入账户')">
                  </el-input>
                  <div class="first-sub" @click="blurAccId">
                    {{$t('m.查询')}}</div>
                  <div class="first-show" @click="removeAccId">
                    {{$t('m.重置')}}</div>
                </div>

                <div class="last">
                  <div class="contractShow">
                    <el-tooltip
                      class="item"
                      effect="dark"
                      content="开启后，价值小于10 USDT的币种将会被隐藏。"
                      placement="top">
                      <span class="brdTipsStyle">
                        {{$t('m.隐藏小额币种')}}</span>
                    </el-tooltip>
                    <el-switch
                      v-model="showOrderSmallCoin"
                      inactive-color="#eaecf0"
                      active-color="#2174FF">
                    </el-switch>
                  </div>
                </div>
              </div>
              <div class="right">
                <span v-if="transactionType == 'spot'" @click="revoke('兑换')">
                  {{$t('m.快速兑换 USDT')}}
                </span>
                <span
                  v-if="transactionType == 'contract_usdt'"
                  @click="revoke('平仓')">
                  {{$t('m.快速平仓')}}</span>
              </div>
            </div>
            <div>
              <template>
                <el-table
                  ref="multipleTable2"
                  :data="positionList"
                  tooltip-effect="dark"
                  fit
                  style="width: 100%">
                  <!-- @selection-change="handleSelectionChange" -->
                  <el-table-column :label="$t('m.账户')" prop="apiName" width="150">
                    <template slot-scope="scope">
                      <span>{{
                        scope.row.remarkCode
                          ? scope.row.remarkCode
                          : scope.row.apiName
                          ? scope.row.apiName
                          : scope.row.publicCode
                      }}</span>
                    </template>
                  </el-table-column>

                  <el-table-column
                    prop="coinType"
                    :label="$t('m.币种')"
                    width="100"
                    v-if="transactionType == 'spot'">
                    <template slot-scope="scope">{{
                      scope.row.coinType
                    }}</template>
                  </el-table-column>

                  <el-table-column
                    prop="available"
                    :label="$t('m.可用')"
                    v-if="transactionType == 'spot'">
                    <template slot-scope="scope">
                      <span v-if="scope.row.coinType == 'BTC'">{{
                        Math.floor(scope.row.available * 1000000) / 1000000
                      }}</span>
                      <span v-else>{{
                        Math.floor(scope.row.available * 10000) / 10000
                      }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="smartAvailable"
                    :label="$t('m.冻结')"
                    v-if="transactionType == 'spot'">
                    <template slot-scope="scope">{{
                      Math.floor(Number(scope.row.smartAvailable) * 10000) /
                      10000
                    }}</template>
                  </el-table-column>
                  <el-table-column
                    prop="coinValue"
                    :label="$t('m.折合 USDT 估值')"
                    v-if="transactionType == 'spot'">
                    <template slot-scope="scope">{{
                      Math.floor(Number(scope.row.coinValue) * 10000) / 10000
                    }}</template>
                  </el-table-column>
                  <el-table-column
                    prop="coinCost"
                    :label="$t('m.折合 USDT 成本')"
                    v-if="transactionType == 'spot'">
                    <template slot-scope="scope">{{
                      Math.floor(Number(scope.row.coinCost) * 10000) / 10000
                    }}</template>
                  </el-table-column>
                  <el-table-column
                    prop="coinIncome"
                    :label="$t('m.折合 USDT 收益')"
                    v-if="transactionType == 'spot'">
                    <template slot-scope="scope">{{
                      Math.floor(Number(scope.row.coinIncome) * 10000) / 10000
                    }}</template>
                  </el-table-column>


                  <el-table-column
                    :label="$t('m.品种')"
                    prop="contractId"
                    v-if="transactionType != 'spot'"
                    width="100">
                    <template>{{
                      (pair ? pair.counterCoin : "--") +
                      "/" +
                      (pair ? pair.baseCoin : "--")
                    }}</template>
                  </el-table-column>

                  <el-table-column
                    :label="$t('m.方向')"
                    prop="apiName"
                    v-if="transactionType != 'spot'"
                    min-width="60">

                    <template slot-scope="scope">
                      <span v-if="scope.row.direction == 'buy'">{{
                        $t('m.做多')+" " + scope.row.leverRate + "X"
                      }}</span>
                      <span v-if="scope.row.direction == 'sell'">{{
                        $t('m.做空')+" " + scope.row.leverRate + "X"
                      }}</span>
                    </template>
                  </el-table-column>

                  <el-table-column
                    :label="$t('m.持仓量(张)')"
                    prop="volumeNum"
                    v-if="transactionType != 'spot'"
                    min-width="80">
                    <template>
                      <el-tooltip
                        class="headerStyle"
                        effect="dark"
                        content="显示包含合约策略的持仓。"
                        placement="top">
                        <div>
                          <div class="brdTipsStyle">
                            {{$t('m.持仓量(张)')}}</div>
                        </div>
                      </el-tooltip>
                    </template>
                    <template slot-scope="scope">{{
                      Math.floor(Number(scope.row.volumeNum) * 10000) / 10000
                    }}</template>
                  </el-table-column>

                  <el-table-column
                    :label="$t('m.可平量(张)')"
                    prop="availableNum"
                    v-if="transactionType != 'spot'"
                    min-width="80">
                    <template slot-scope="scope">{{
                      Math.floor(Number(scope.row.availableNum) * 10000) / 10000
                    }}</template>
                  </el-table-column>

                  <el-table-column
                    :label="$t('m.开仓均价USDT')"
                    prop="costOpen"
                    v-if="transactionType != 'spot'"
                    min-width="100">
                    <template slot-scope="scope">{{
                      Math.floor(Number(scope.row.costOpen) * 10000) / 10000
                    }}</template>
                  </el-table-column>
                  <el-table-column
                    :label="$t('m.收益率')"
                    prop="profitRate"
                    v-if="transactionType != 'spot'"
                    min-width="70">
                    <template slot-scope="scope">
                      <span
                        v-if="Number(scope.row.profitRate) > 0"
                        class="red">
                        {{
                          "+" +
                          Math.floor(Number(scope.row.profitRate) * 10000) /
                            100 +
                          "%"
                        }}</span>
                      <span v-if="Number(scope.row.profitRate) == 0">
                        {{ Number(scope.row.profitRate) }}
                      </span>
                      <span
                        v-if="Number(scope.row.profitRate) < 0"
                        class="green">
                        {{
                          Math.floor(Number(scope.row.profitRate) * 10000) /
                            100 +
                          "%"
                        }}</span>
                      <span v-if="!scope.row.profitRate">--</span>
                    </template>
                  </el-table-column>
                  <el-table-column
                    :label="$t('m.收益USDT')"
                    prop="profit"
                    v-if="transactionType != 'spot'"
                    min-width="100">
                    <template slot-scope="scope">
                      <span v-if="Number(scope.row.profit) > 0" class="red">{{
                        "+" +
                        Math.floor(Number(scope.row.profit) * 10000) / 10000
                      }}</span>
                      <span v-if="Number(scope.row.profit) == 0">
                        {{ Number(scope.row.profit) }}
                      </span>
                      <span v-if="Number(scope.row.profit) < 0" class="green">{{
                        Math.floor(Number(scope.row.profit) * 10000) / 10000
                      }}</span>
                      <span v-if="!scope.row.profit">--</span>
                    </template>
                  </el-table-column>

                  <el-table-column
                    :label="$t('m.强平价USDT')"
                    prop="liquidationPrice"
                    v-if="transactionType != 'spot'"
                    min-width="90">
                    <template slot-scope="scope">
                      <span v-if="scope.row.liquidationPrice == 0">--</span>
                      <span v-if="scope.row.liquidationPrice != 0">{{
                        Math.floor(Number(scope.row.liquidationPrice) * 10000) /
                        10000
                      }}</span>
                    </template>
                  </el-table-column>

                  <el-table-column
                    :label="$t('m.担保资产率')"
                    prop="riskRate"
                    v-if="transactionType != 'spot'"
                    min-width="80">
                    <template slot-scope="scope">{{
                      Math.floor(Number(scope.row.riskRate) * 10000) / 100 + "%"
                    }}</template>
                  </el-table-column>

                  <el-table-column prop="" 
                    :label="$t('m.操作')" 
                    width="140">
                    <template slot="header">
                      <div style="text-align: right">
                        {{$t('m.操作')}}</div>
                    </template>

                    <template slot-scope="scope">
                      <div style="display: flex; justify-content: flex-end">
                        <span
                          class="btn-color"
                          v-if="transactionType != 'spot'"
                          @click="scopeBtn('surplus', scope.row)">
                          {{$t('m.止盈')}}</span>

                        <span
                          class="btn-color"
                          style="margin: 0 10px"
                          v-if="transactionType != 'spot'"
                          @click="scopeBtn('loss', scope.row)">
                          {{$t('m.止损')}}</span>

                        <span
                          class="btn-color"
                          @click="revoke('兑换', scope.row)"
                          v-if="
                            scope.row.coinType != 'USDT' &&
                            transactionType == 'spot'
                          ">
                          {{$t('m.快速兑换 USDT')}}</span>

                        <span
                          class="btn-color"
                          @click="revoke('平仓', scope.row)"
                          v-if="transactionType != 'spot'">
                          {{$t('m.快速平仓')}}</span>
                      </div>
                    </template>
                  </el-table-column>

                  <!-- qqq -->
                  <el-table-column width="30">
                    <template slot="header">
                      <!-- :checked="!!posistionCheckAll" -->
                      <el-checkbox
                        :indeterminate="posistionCheckAll"
                        v-model="posistionCheckAllSelect"
                        @change="handleSelectAllItem($event)" >
                      </el-checkbox>
                    </template>
                    <template slot-scope="scope">
                      <el-checkbox
                        v-model="scope.row.checked"
                        @change="
                          handleSelectItem($event, scope.row, scope.$index)
                        ">
                      </el-checkbox>
                    </template>
                  </el-table-column>
                </el-table>
              </template>
            </div>
          </el-tab-pane>

          <el-tab-pane :label="$t('m.当前委托')" name="2">
            <div class="select-warp">
              <div class="left">
                <div class="first">
                  <span class="lable">
                    {{$t('m.账户')}}：</span>
                  <el-input
                    v-model="btmSelectInp"
                    :placeholder="$t('m.请输入账户')">
                  </el-input>
                  <div class="first-sub" @click="blurAccId">
                    {{$t('m.查询')}}</div>
                  <div class="first-show" @click="removeAccId">
                    {{$t('m.重置')}}</div>
                </div>
                <div class="last">
                </div>
              </div>
              <div class="right">
                <span @click="revoke('撤销')">
                  {{$t('m.全部撤销')}}</span>
              </div>
            </div>

            <el-table
              ref="multipleTable"
              :data="currentEntrustList"
              tooltip-effect="dark"
              style="width: 100%">
              <el-table-column :label="$t('m.账户')" width="160">
                <template slot-scope="scope">{{
                  scope.row.remarkCode
                    ? scope.row.remarkCode
                    : scope.row.publicPeopleName
                }}</template>
              </el-table-column>

              <el-table-column prop="available" :label="$t('m.时间')" width="150">
                <template slot="header">
                  <div class="screenBox" @click="screenBtn('时间')">
                    <span>{{$t('m.时间')}}</span>
                    <div class="screenBox-img">
                      <img
                        v-if="screenTime !== 2"
                        src="../../assets/icon/top.png"
                        alt=""
                      />
                      <img
                        v-if="screenTime == 2"
                        src="../../assets/icon/top-lan.png"
                        alt=""
                      />
                      <img
                        v-if="screenTime !== 1"
                        src="../../assets/icon/bottom.png"
                        alt=""
                      />
                      <img
                        v-if="screenTime == 1"
                        src="../../assets/icon/bottom-lan.png"
                        alt=""
                      />
                    </div>
                  </div>
                </template>
                <template slot-scope="scope">
                  {{
                    moment(scope.row.createTime).format("YYYY-MM-DD HH:mm:ss")
                  }}
                </template>
              </el-table-column>

              <el-table-column
                prop="smartAvailable"
                :label="$t('m.品种')"
                :width="transactionType != 'spot' ? 150 : 110">
                <template slot-scope="scope">
                  {{
                    (pair ? pair.counterCoin : "--") +
                    "-" +
                    (pair ? pair.baseCoin : "--") +
                    " " +
                    (scope.row.leverRate ? scope.row.leverRate + "x" : "")
                  }}
                </template>
              </el-table-column>

              <el-table-column prop="orderDirection" :label="$t('m.方向')" :width="70">
                <template slot="header">
                  <div class="screenBox" @click="screenBtn('方向')">
                    <span>{{$t('m.方向')}}</span>
                    <div class="screenBox-img">
                      <img
                        v-if="screenDirection !== 2"
                        src="../../assets/icon/top.png"
                        alt=""
                      />
                      <img
                        v-if="screenDirection == 2"
                        src="../../assets/icon/top-lan.png"
                        alt=""
                      />
                      <img
                        v-if="screenDirection !== 1"
                        src="../../assets/icon/bottom.png"
                        alt=""
                      />
                      <img
                        v-if="screenDirection == 1"
                        src="../../assets/icon/bottom-lan.png"
                        alt=""
                      />
                    </div>
                  </div>
                </template>
                <template slot-scope="scope">
                  <span
                    style="color: #e12525"
                    v-if="scope.row.orderDirection == 'buy'">
                    {{$t('m.买入')}}</span>
                  <span
                    style="color: #26b34b"
                    v-if="scope.row.orderDirection == 'sell'">
                    {{$t('m.卖出')}}</span>
                  <span
                    style="color: #e12525"
                    v-if="scope.row.orderDirection == 'open_many'">
                    {{$t('m.开多')}}</span>
                  <span
                    style="color: #26b34b"
                    v-if="scope.row.orderDirection == 'open_empty'">
                    {{$t('m.开空')}}</span>
                  <span
                    style="color: #26b34b"
                    v-if="scope.row.orderDirection == 'flat_many'">
                    {{$t('m.平多')}}</span>
                  <span
                    style="color: #e12525"
                    v-if="scope.row.orderDirection == 'flat_empty'">
                    {{$t('m.平空')}}</span>
                </template>
              </el-table-column>

              <el-table-column prop="smartAvailable" :label="$t('m.下单方式')">
                <template slot="header">
                  <div class="screenBox" @click="screenBtn('下单方式')">
                    <span>{{$t('m.下单方式')}}</span>
                    <div class="screenBox-img">
                      <img
                        v-if="screenMode !== 2"
                        src="../../assets/icon/top.png"
                        alt=""
                      />
                      <img
                        v-if="screenMode == 2"
                        src="../../assets/icon/top-lan.png"
                        alt=""
                      />
                      <img
                        v-if="screenMode !== 1"
                        src="../../assets/icon/bottom.png"
                        alt=""
                      />
                      <img
                        v-if="screenMode == 1"
                        src="../../assets/icon/bottom-lan.png"
                        alt=""
                      />
                    </div>
                  </div>
                </template>
                <template slot-scope="scope">
                  {{
                    JSON.parse(scope.row.executeConfig).actions[0].name ==
                    "plan-order"
                      ? scope.row.type == 0
                        ? $t('m.止盈')
                        : scope.row.type == 1
                        ? $t('m.止损')
                        : $t('m.条件委托')
                      : scope.row.priceType == 1
                      ? $t('m.限价委托')
                      : $t('m.加')
                  }}
                </template>
              </el-table-column>

              <el-table-column prop="smartAvailable" :label="$t('m.价格')">
                <template slot-scope="scope">
                  {{
                    JSON.parse(scope.row.executeConfig).actions[0].name ==
                    "plan-order"
                      ? scope.row.priceType == 0
                        ? "--"
                        : scope.row.priceType == 1
                        ? scope.row.price
                        : "--"
                      : scope.row.price
                  }}
                </template>
              </el-table-column>

              <el-table-column
                prop="smartAvailable"
                :label="$t('m.数量 | 金额')"
                v-if="transactionType == 'spot'">

                <template slot-scope="scope">
                  {{
                    scope.row.investNum +
                    " " +
                    JSON.parse(scope.row.executeConfig).tradePair.investCoin
                  }}
                </template>
              </el-table-column>

              <el-table-column
                prop="smartAvailable"
                :label="$t('m.数量')"
                v-if="transactionType != 'spot'"
              >
                <template slot="header">
                  <div class="screenBox" @click="screenBtn('数量')">
                    <span>{{$t('m.数量')}}</span>
                    <div class="screenBox-img">
                      <img
                        v-if="screenNumber !== 2"
                        src="../../assets/icon/top.png"
                        alt=""
                      />
                      <img
                        v-if="screenNumber == 2"
                        src="../../assets/icon/top-lan.png"
                        alt=""
                      />
                      <img
                        v-if="screenNumber !== 1"
                        src="../../assets/icon/bottom.png"
                        alt=""
                      />
                      <img
                        v-if="screenNumber == 1"
                        src="../../assets/icon/bottom-lan.png"
                        alt=""
                      />
                    </div>
                  </div>
                </template>
                <template slot-scope="scope">
                  {{ scope.row.investNum + " "+$t('m.张') }}
                </template>
              </el-table-column>
              <el-table-column prop="smartAvailable" :label="$t('m.触发条件')">
                <template slot-scope="scope">
                  {{
                    (scope.row.priceType == 1 || scope.row.priceType == 2) &&
                    JSON.parse(scope.row.executeConfig).actions[0].name !==
                      "plan-order"
                      ? "--"
                      : $t('m.价格') +
                        (scope.row.triggerType == 0
                          ? " ≥ "
                          : scope.row.triggerType == 1
                          ? " ≤ "
                          : " =" ) +
                        scope.row.triggerPrice
                  }}
                </template>
              </el-table-column>

              <el-table-column prop="smartAvailable" :label="$t('m.状态')">
                <template slot="header">
                  <div class="screenBox" @click="screenBtn('状态')">
                    <span>{{$t('m.状态')}}</span>
                    <div class="screenBox-img">
                      <img
                        v-if="screenState !== 2"
                        src="../../assets/icon/top.png"
                        alt=""
                      />
                      <img
                        v-if="screenState == 2"
                        src="../../assets/icon/top-lan.png"
                        alt=""
                      />
                      <img
                        v-if="screenState !== 1"
                        src="../../assets/icon/bottom.png"
                        alt=""
                      />
                      <img
                        v-if="screenState == 1"
                        src="../../assets/icon/bottom-lan.png"
                        alt=""
                      />
                    </div>
                  </div>
                </template>
                <template slot-scope="scope">
                  <span>{{
                    scope.row.priceType == 1 &&
                    JSON.parse(scope.row.executeConfig).actions[0].name !==
                      "plan-order"
                      ? $t('m.等待成交')
                      : scope.row.states == "WAIT_TRIGGER"
                      ? $t('m.等待触发')
                      : scope.row.states == "WAIT_DEAL"
                      ? $t('m.等待成交')
                      : scope.row.states == "ALREADY_DEAL"
                      ? $t('m.已成交')
                      : $t('m.订单异常')
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="" :label="$t('m.操作')" width="50">
                <template slot="header">
                  <div style="text-align: right">
                    {{$t('m.操作')}}</div>
                </template>
                <template slot-scope="scope">
                  <div class="chexiao">
                    <span class="btn-color" @click="revoke('撤销', scope.row)">
                      {{$t('m.撤销')}}</span>
                  </div>
                </template>
              </el-table-column>
              <!-- <el-table-column type="selection" width="55"></el-table-column> -->
              <el-table-column width="30">
                <template slot="header">
                  <!-- :checked="!!posistionCheckAll" -->
                  <el-checkbox
                    :indeterminate="posistionCheckAll"
                    v-model="posistionCheckAllSelect"
                    :disabled="
                      !currentEntrustList || currentEntrustList.length == 0
                    "
                    @change="handleSelectAllItem($event)"
                  ></el-checkbox>
                </template>
                <template slot-scope="scope">
                  <el-checkbox
                    v-model="scope.row.checked"
                    @change="handleSelectItem($event, scope.row, scope.$index)"
                  ></el-checkbox>
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>
          <el-tab-pane :label="$t('m.历史委托')" name="3">
            <div class="select-warp">
              <div class="left">
                <div class="first">
                  <span class="lable">
                    {{$t('m.账户')}}：</span>
                  <el-input
                    v-model="btmSelectInp"
                    :placeholder="$t('m.请输入账户')"
                  ></el-input>
                  <div class="first-sub" @click="blurAccId">
                    {{$t('m.查询')}}</div>
                  <div class="first-show" @click="removeAccId">
                    {{$t('m.重置')}}</div>
                </div>
                <div class="last"></div>
              </div>
              <div class="right"></div>
            </div>
            <el-table
              ref="multipleTable"
              :data="historyEntrustList"
              tooltip-effect="dark"
              style="width: 100%">
              <el-table-column :label="$t('m.账户')" width="160">
                <template slot-scope="scope">{{
                  scope.row.remarkCode
                    ? scope.row.remarkCode
                    : scope.row.publicPeopleName
                }}</template>
              </el-table-column>

              <el-table-column prop="available" :label="$t('m.时间')" width="150">
                <template slot-scope="scope">
                  {{
                    moment(scope.row.createTime).format("YYYY-MM-DD HH:mm:ss")
                  }}
                </template>
              </el-table-column>

              <el-table-column prop="smartAvailable" :label="$t('m.品种')" :width="150">
                <template slot-scope="scope">
                  {{
                    (pair ? pair.counterCoin : "--") +
                    "-" +
                    (pair ? pair.baseCoin : "--") +
                    " " +
                    (scope.row.leverRate ? scope.row.leverRate + "x" : "") +
                    (scope.row.orderDirection == "buy"
                      ? $t('m.买入')
                      : scope.row.orderDirection == "sell"
                      ? $t('m.卖出')
                      : scope.row.orderDirection == "open_many"
                      ? $t('m.开多')
                      : scope.row.orderDirection == "open_empty"
                      ? $t('m.开空')
                      : scope.row.orderDirection == "flat_many"
                      ? $t('m.平多')
                      : scope.row.orderDirection == "flat_empty"
                      ? $t('m.平空')
                      : "")
                  }}
                </template>
              </el-table-column>

              <el-table-column prop="smartAvailable" :label="$t('m.下单方式')">
                <template slot-scope="scope">
                  {{
                    JSON.parse(scope.row.executeConfig).actions[0].name ==
                    "plan-order"
                      ? scope.row.type == 0
                        ? $t('m.止盈')
                        : scope.row.type == 1
                        ? $t('m.止损')
                        : $t('m.条件委托')
                      : scope.row.priceType == 1
                      ? $t('m.限价委托')
                      : $t('m.市价委托')
                  }}
                </template>
              </el-table-column>

              <el-table-column prop="smartAvailable" :label="$t('m.价格')">
                <template slot-scope="scope">
                  {{
                    JSON.parse(scope.row.executeConfig).actions[0].name ==
                    "plan-order"
                      ? scope.row.priceType == 0
                        ? "--"
                        : scope.row.priceType == 1
                        ? scope.row.price
                        : "--"
                      : scope.row.price
                  }}
                </template>
              </el-table-column>

              <el-table-column
                prop="smartAvailable"
                :label="$t('m.数量 | 金额')"
                v-if="transactionType == 'spot'">
                <template slot-scope="scope">
                  {{
                    scope.row.investNum +
                    " " +
                    JSON.parse(scope.row.executeConfig).tradePair.investCoin
                  }}
                </template>
              </el-table-column>

              <el-table-column
                prop="smartAvailable"
                :label="$t('m.数量')"
                v-if="transactionType != 'spot'">
                <template slot-scope="scope">
                  {{ scope.row.investNum + " "+$t('m.张') }}
                </template>
              </el-table-column>
              <el-table-column prop="smartAvailable" :label="$t('m.触发条件')">
                <template slot-scope="scope">
                  {{
                    (scope.row.priceType == 1 || scope.row.priceType == 2) &&
                    JSON.parse(scope.row.executeConfig).actions[0].name !==
                      "plan-order"
                      ? "--"
                      : $t('m.价格') +
                        (scope.row.triggerType == 0
                          ? "≥"
                          : scope.row.triggerType == 1
                          ? "≤"
                          : "=") +
                        scope.row.triggerPrice
                  }}
                </template>
              </el-table-column>

              <el-table-column prop="smartAvailable" :label="$t('m.状态')">
                <template slot-scope="scope">
                  <span>{{
                    scope.row.states == "WAIT_DEAL"
                      ? $t('m.已撤销')
                      : scope.row.states == "ALREADY_DEAL"
                      ? $t('m.已成交')
                      : $t('m.订单异常')
                  }}</span>
                </template>
              </el-table-column>
            </el-table>
            <div class="bottom-explain">
              {{$t('m.此列表只提供7日内历史委托数据')}}</div>
          </el-tab-pane>
        </el-tabs>

        <!-- 持仓 提示框 -->
        <div class="position-tips" v-if="tipsShow && tipsNum == 4">
          <div class="triangle"></div>
          <div class="top">
            <img
              src="../../assets/icon/shanchu-bai.png"
              alt=""
              @click="tipsComplete()"
            />
          </div>
          <h3>
            {{$t('m.持仓 - 当前委托')}}</h3>
          <p>
            {{$t('m.在此处查看持仓及委托数据。')}}</p>
          <div class="op-clk">
            <span class="next" @click="tipsNum = 5">
              {{$t('m.下一步')}}</span>
            <span class="previous-step" @click="tipsNum = 3">
              {{$t('m.上一步')}}</span>
          </div>
        </div>

        <!-- 持仓操作 提示框 -->
        <div
          class="position-op-tips"
          v-if="tipsShow && (tipsNum == 5 || tipsNum == 6)">
          <div class="triangle"></div>
          <div class="left">
            <h3 v-if="tipsNum == 5">
              {{$t('m.币币持仓')}}</h3>
            <p v-if="tipsNum == 5">
              {{$t('m.币币持仓支持快速兑换成USDT；')}}</p>
            <h3 v-if="tipsNum == 6">
              {{$t('m.合约持仓')}}</h3>
            <p v-if="tipsNum == 6">
              {{$t('m.合约持仓支持快速平仓和止盈止损操作。')}}</p>
            <div class="op-clk">
              <!-- <span class="next" @click="tipsComplete()" v-if="tipsNum=6">完成</span> -->

              <span class="next" @click="tipsNum = 6" v-if="tipsNum == 5">
                {{$t('m.下一步')}}</span>
              <span
                class="previous-step"
                @click="tipsNum = 4"
                v-if="tipsNum == 5">
                {{$t('m.上一步')}}</span>

              <span class="next" @click="tipsComplete()" v-if="tipsNum == 6">
                {{$t('m.完成')}}</span>

              <span
                class="previous-step"
                @click="tipsNum = 5"
                v-if="tipsNum == 6">
                {{$t('m.上一步')}}</span>
            </div>
          </div>

          <img
            src="../../assets/image/image_03@2x.png"
            alt=""
            class="center"
            v-if="tipsNum == 5"
          />
          <img
            src="../../assets/image/image_04@2x.png"
            alt=""
            class="center"
            v-if="tipsNum == 6"
          />

          <img
            src="../../assets/icon/shanchu-bai.png"
            alt=""
            class="right"
            @click="tipsComplete()"
          />
        </div>
      </div>
    </div>

    <!-- 划转 -->
    <el-dialog
      :title="$t('m.划转')"
      :visible.sync="transfer"
      width="400px"
      center
      :before-close="handleClose"
      :close-on-click-modal="false">
      <div class="dialog-nav">
        <div class="dialog-box">
          <div>
            <div class="dialog-top">
              {{$t('m.从')}}：
              <el-select
                size="medium"
                v-model="topValue"
                @change="topValueChange"
                :placeholder="$t('m.请选择')">
                <el-option
                  v-for="(item, index) in topOptions"
                  :key="index"
                  :value="item.name">
                </el-option>
              </el-select>
            </div>

            <div class="dialog-top">
              {{$t('m.到')}}：
              <el-select
                size="medium"
                v-model="twoValue"
                @change="twoValueChange"
                :placeholder="$t('m.请选择')">
                <el-option
                  v-for="(item, index) in twoOptions"
                  :key="index"
                  :value="item.name">
                </el-option>
              </el-select>
            </div>
          </div>
          <img
            @click="transformationBtn()"
            class="dialog-img"
            src="../../assets/icon/zhuanhuan-big.png"
            alt=""
          />
        </div>
        <div class="dialog-currency" style="margin-bottom: 15px">
          <span>{{$t('m.币种')}}</span>
          <el-select size="medium" v-model="currencyValue" :placeholder="$t('m.请选择')">
            <el-option
              v-for="(item, index) in currencyList"
              :key="index"
              :label="item"
              :value="item">
            </el-option>
          </el-select>
        </div>
        <div class="dialog-currency">
          <span>{{$t('m.划转数量')}}</span>
          <el-input
            v-model="inputOptions"
            type="number"
            placeholder="请输入数量"
            :class="inputStyle ? 'inputStyleItem' : ''"
            @focus="inputNumberFocus($event)"
            @blur="inputNumber($event)">
            <template slot="append"
              >USDT |
              <span
                style="cursor: pointer; color: #2174ff"
                @click="inputOptions = usdtAvailable">
                {{$t('m.全部')}}</span>
            </template>
          </el-input>
        </div>
        <span style="margin-top: 10px">
          {{$t('m.可用划转')}} {{ usdtAvailable + " " + currencyValue }}
        </span>
      </div>
      <div slot="footer">
        <div class="dialog-footer">
          <div
            class="footerBack"
            @click="
              transfer = false;
              inputOptions = '';
              currencyValue = 'USDT';
            ">
            {{$t('m.取消')}}
          </div>
          <div class="footerBtn" @click="transferDetermine()">
            {{$t('m.确定')}}</div>
        </div>
      </div>
    </el-dialog>

    <!-- 平仓/兑换二次确认 -->
    <el-dialog
      :title="secondaryTitle"
      :visible.sync="secondary"
      width="30%"
      :before-close="handleClose">
      <span>{{ secondaryText }}</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="secondary = false">
          {{$t('m.取 消')}}</el-button>
        <el-button type="primary" @click="secondaryConfirm()">
          {{$t('m.确 定')}}</el-button>
      </span>
    </el-dialog>

    <!-- 单个平仓/兑换弹框 -->
    <el-dialog
      :title="secondaryTitle"
      :visible.sync="secondaryShow"
      width="30%"
      :before-close="handleClose">
      <div class="secondaryShowStyle">
        <div style="margin-bottom: 10px">
          {{$t('m.数量')}}</div>
        <el-input
          v-model="scopedNumModel"
          :placeholder="scopedNum"
          :disabled="scopedShow"
          type="number"
          class="styleBorderColor"
          @blur="scopedNumModelBlur($event)">
          <i slot="suffix" v-if="transactionType != 'spot'">
            {{$t('m.张')}}</i>
          <i slot="suffix" v-if="transactionType == 'spot'">{{
            scopedData ? scopedData.coinType : "USDT"
          }}</i>
        </el-input>
        <div class="investment-block">
          <el-slider
            :show-tooltip="scopedSlider !== 0"
            @change="scopedClick()"
            v-model="scopedSlider"
            :step="1">
          </el-slider>
        </div>

        <div
          style="
            display: flex;
            justify-content: space-between;
            margin-bottom: 5px;
          ">
          <span>0%</span>
          <span>100%</span>
        </div>

        <div class="action-btnList">
          <template v-for="(item, index) in actionShowList">
            <!-- 123123 -->
            <div :key="'actionShowList'+index"
              :class="[item == actionBtnIndex ? 'action-item' : '']"
              :style="index == actionShowList.length - 1 ? 'margin:0' : ''"
              @click="actionBtnClick(item)">
              {{ item + "%" }}
            </div>
          </template>
        </div>

        <!-- <div style="color: #ff3a3a; margin: 10px 0">
          *如有委托冻结，系统将先撤单再平仓。
        </div> -->
      </div>

      <span slot="footer">
        <div style="display: flex; justify-content: space-between">
          <!-- border-top: 1px solid #f0f0f0;
            padding-top: 10px; -->
          <div style="display: flex; align-items: center">
            {{
              $t('m.可平')+"：" +
              (scopedData ? scopedData.availableNum : 0) +
              (scopedData
                ? transactionType == "spot"
                  ? scopedData.coinType
                  : $t('m.张')
                : "USDT")
            }}
          </div>
          <div class="dialog-footer">
            <div
              class="footerBack"
              @click="
                secondaryShow = false;
                scopedSlider = 0;
                scopedNumModel = null;
                actionBtnIndex = null;
              ">
              {{$t('m.取消')}}
            </div>
            <div class="footerBtn" @click="secondaryConfirm()">
              {{$t('m.确定')}}</div>
          </div>
        </div>
      </span>
    </el-dialog>

    <!-- 下单弹出框 -->
    <template v-for="(item, index) in openList">
      <div :key="'openList'+index" class="open" v-if="item.show">
        <div class="open-title" @click="openShow(index)">
          <span>X</span>
        </div>
        <div class="open-nav">
          {{
            (item.remarkCode ? item.remarkCode : item.publicPeopleName) +
            ' '+$t('m.下单失败')+"：" +
            item.error
          }}
        </div>
      </div>
    </template>

    <!-- 止盈止损 -->
    <el-dialog
      :title="scopeType == 'surplus' ? $t('m.止盈') : $t('m.止损')"
      :visible.sync="scopeShow"
      width="400px"
      center
      :before-close="handleClose"
      :close-on-click-modal="false">
      <div class="conditionBoxScoped">
        <div class="action-condition">
          <div class="condition-price">
            <div class="title">
              {{$t('m.触发价格')}}</div>
            <div>
              <el-input
                v-model="scopeTriggerPrice"
                :placeholder="scopedHolder"
                type="number"
                @blur="scopeTriggerPriceChange($event)">
                <i slot="suffix">{{ pair ? pair.baseCoin : "--" }}</i>
              </el-input>
            </div>
          </div>
          <div class="condition-priceType">
            <div class="title">
              {{$t('m.价格类型')}}</div>
            <div style="display: flex">
              <span
                class="condition-box"
                :class="[breachScope == 0 ? 'lanBox' : 'huiBox']"
                @click="breachScope = 0">
                {{$t('m.市价')}}</span>
              <span
                class="condition-box"
                :class="[breachScope == 1 ? 'lanBox' : 'huiBox']"
                @click="breachScope = 1">
                {{$t('m.限价')}}</span>
            </div>
          </div>
          <div class="condition-priceType">
            <div class="title">
              {{$t('m.价格')}}</div>
            <div>
              <el-input
                v-model="actionPrice"
                v-if="breachScope == 0"
                :placeholder="$t('m.以taker单立即成交')"
                disabled>
              </el-input>
              <el-input
                v-model="scopedNumberPrice"
                v-if="breachScope == 1"
                :placeholder="$t('m.请输入价格')"
                type="number">
                <i slot="suffix">{{ pair ? pair.baseCoin : "--" }}</i>
              </el-input>
            </div>
          </div>
          <div class="action-investment">
            <div class="title">
              <span>{{$t('m.数量')}} </span>
            </div>
            <div class="investment-content">
              <el-input
                v-model="scopedNumModel"
                :placeholder="scopedNum"
                :disabled="scopedShow"
                type="number"
                class="styleBorderColor"
                @blur="scopedNumModelBlur($event)">
                <i slot="suffix">
                  {{$t('m.张')}}</i>
              </el-input>
              <div class="investment-block">
                <el-slider
                  @change="scopedClick()"
                  v-model="scopedSlider"
                  :step="1">
                </el-slider>
              </div>
            </div>
          </div>

          <div class="action-btnList">
            <template v-for="(item, index) in actionShowList">
              <div :key="'actionShowList'+index"
                :class="[item == actionBtnIndex ? 'action-item' : '']"
                :style="index == actionShowList.length - 1 ? 'margin:0' : ''"
                @click="actionBtnClick(item)">
                {{ item + "%" }}
              </div>
            </template>
          </div>
        </div>
      </div>

      <div slot="footer">
        <div style="display: flex; justify-content: space-between">
          <!-- border-top: 1px solid #f0f0f0;
            padding-top: 10px; -->
          <div style="display: flex; align-items: center">
            {{
              $t('m.可平')+"：" +
              (this.scopedData ? this.scopedData.availableNum : 0) +
              $t('m.张')
            }}
          </div>
          <div class="dialog-footer">
            <div
              class="footerBack"
              @click="
                scopeShow = false;
                scopedSlider = 0;
                scopedNumModel = null;
                actionBtnIndex = 0;
              ">
              {{$t('m.取消')}}
            </div>
            <div class="footerBtn" @click="placeOrderScoped()">
              {{$t('m.确定')}}</div>
          </div>
        </div>
      </div>
    </el-dialog>

    <!-- 展示全部账户 -->
    <el-dialog
      :title="$t('m.账户')"
      :visible.sync="selectAllShow"
      width="1200px"
      top="10vh"
      :before-close="handleClose">
      <!-- //123456 -->
      <div class="dialog-selectAll">
        <div class="select-exchange">
          <span>{{$t('m.交易所')}}：</span>
          <el-select
            v-model="exchangeNameShow"
            filterable
            :placeholder="$t('m.请选择')"
            @change="checkExchange">
            <el-option
              v-for="item in exchangeList"
              :key="item.exchangeId"
              :label="item.exchangeName"
              :value="item.exchangeName">
            </el-option>
          </el-select>
        </div>

        <div class="select-type">
          <span>{{$t('m.交易类型')}}：</span>
          <el-select
            v-model="transactionTypeShow"
            filterable
            @change="typeChange">
            <el-option
              v-for="item in typeList"
              :key="item.value"
              :label="$t('m.'+item.label)" 
              :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div>
          <el-checkbox
            :disabled="!selectAllShowList"
            @change="accSelectAllShowBtn()"
            v-model="accSelectAllShow">
            {{$t('m.选择全部账户')}}</el-checkbox>
        </div>
      </div>

      <div
        style="height: 500px"
        :class="[
          selectAllShowList && selectAllShowList.length > 15
            ? 'scrollStyle'
            : '',
        ]">
        <div class="dialog-boxShow" v-if="selectAllShowList">
          <div
            v-for="(item, idx) in selectAllShowList"
            :key="idx"
            class="dialog-warp-cont">
            <div
              @click="accShowChange(item)"
              class="dialog-warp-box"
              :class="{ check: item.isCheck }">
              <div class="warp-cont-price">
                <span
                  style="color: #14263d"
                  :class="{ 'dialog-name': item.isCheck }">
                  {{
                    item.remarkCode ? item.remarkCode : item.publicCode
                  }}</span>
                <div v-if="transactionTypeShow == 'spot'">
                  <div v-if="pair && pair.baseCoin != 'BTC'">
                    {{
                     $t('m.可用') +" " +
                      Math.floor(Number(item.available) * 10000) / 10000 +
                      " " +
                      (pair ? pair.baseCoin : "--")
                    }}
                  </div>
                  <div v-if="pair && pair.counterCoin != 'BTC'">
                    {{
                      $t('m.可用') + " " +
                      Math.floor(Number(item.counterCoinNum) * 10000) / 10000 +
                      " " +
                      (pair ? pair.counterCoin : "--")
                    }}
                  </div>
                  <div v-if="pair && pair.baseCoin == 'BTC'">
                    {{
                      $t('m.可用')+" " +
                      Math.floor(Number(item.available) * 1000000) / 1000000 +
                      " " +
                      (pair ? pair.baseCoin : "--")
                    }}
                  </div>
                  <div v-if="pair && pair.counterCoin == 'BTC'">
                    {{
                      $t('m.可用')+" " +
                      Math.floor(Number(item.counterCoinNum) * 1000000) /
                        1000000 +
                      " " +
                      (pair ? pair.counterCoin : "--")
                    }}
                  </div>
                </div>
                <div v-else>
                  <div>
                    {{
                     $t('m.可用') +" " +
                      item.available +
                      " USDT≈" +
                      (item.openSize ? item.openSize : "0") +
                      $t('m.张')+" "
                    }}
                  </div>
                  <div>
                    <span>
                      <span style="color: #ff3a3a">
                        {{$t('m.多仓')}}</span>
                      <span>{{
                       $t('m.可平')+ " " + (item.buy ? Number(item.buy) : "0") + " 张"
                      }}</span>
                    </span>
                    |
                    <span>
                      <span style="color: #26b34b">
                        {{$t('m.空仓')}}</span>
                      <span>{{
                        $t('m.可平')+" " + (item.sell ? Number(item.sell) : "0") + " "+$t('m.张')
                      }}</span>
                    </span>
                  </div>
                  <span></span>
                </div>
              </div>
              <div class="warp-cont-btn">
                <el-checkbox
                  :key="new Date().getTime() + idx"
                  :checked="item.isCheck"
                ></el-checkbox>
              </div>
            </div>
            <span
              v-if="transactionTypeShow != 'spot'"
              class="transfer"
              @click="transferBtn(item)">
              {{$t('m.划转')}}</span>
          </div>
        </div>
        <div class="dialog-boxShow" v-else>
          <div class="default">
            <img src="../../assets/image/zanwuzhanghu.png" alt="" />
            <span>{{$t('m.暂无可用账户')}}</span>
          </div>
        </div>
      </div>

      <span slot="footer">
        <div
          class="dialog-footer"
          style="border-top: 1px solid #e6e9ee; padding-top: 10px">
          <div class="footerBack" @click="selectAllHide()">
            {{$t('m.取消')}}</div>
          <div class="footerBtn" @click="selectAllShowDetermine()">
            {{$t('m.确定')}}</div>
        </div>
      </span>
    </el-dialog>
  </div>
</template>







<script>
import {
  queryOrderLabel,
  queryProjectData,
  getSpotApiList,
  queryOperatorUserList,
  getPairPageById,
  queryOrderActionDetails,
  queryFeeAndAccuracy,
  batchCreateOrderChain,
  queryOrderChainContractPair,
  findByIdLeverMultiple,
  currentEntrust,
  getCurrentPublicUser,
  quickSwap,
  batchOrderChainEnd,
  quickFlatPosition,
  transfer,
  querySpotByPairId,
  querySpotContractData,
  batchQuickTransaction,
  batchGetContractApiList,
  findFundSpot,
  findFundAssets,
  getProject,
  findContractId,
  findContract,
  querySignalList,
  signalDetail,
  queryErrorMessage,
  quickUserAndData,
  historyEntrust,
} from "../../request/api";
import moment from "moment";
import TeamSetting from "../projectSetup/teamSetting.vue";
export default {
  components: { TeamSetting },
  data() {
    return {
      account: null,
      moment,
      project: null,
      orderLabel: null,
      exchangeList: null, //交易所列表
      exchangeName: null,
      exchangeId: null,
      typeList: null, // 交易类型列表
      transactionType: "spot", //交易类型

      // 中间
      checked: true,
      accList: null, // 账户列表
      oldAccList: [], //备份账户列表

      pairList: null, //交易对列表
      allPairList: null, // 所有未拼接币对

      pair: null, //交易对
      orderType: 0, //下单方式  0限价，1市价

      contractPositionTType: "开仓",
      leverList: [1, 2, 3, 5, 10], //合约杠杆列表
      lever: "3", //合约杠杆

      orderActionDetails: null, //动作详情
      orderAction: null, //当前动作
      actionPrice: null, //市价动作价格

      buyActionPrice: null, //买入限价价格
      sellActionPrice: null, //卖出限价价格

      investmentTypeList: [
        { label: "固定投入", value: "固定投入" },
        { label: "比例投入", value: "比例投入" },
      ],
      buyInvestmentType: "固定投入", //买入投入方式
      sellInvestmentType: "固定投入", //卖出投入方式
      buyNum: null, //买入数量
      buyMin: null,
      buyMax: null,

      sellNum: null, //卖出数量
      sellMin: null,
      sellMax: null,

      //   底部

      activeName: "1",

      btmSelectInp: "", //底部筛选输入框

      multipleSelection: [],

      positionList: null, //持仓列表
      posistionCheckAll: false, // 持仓列表半选中状态, false未选中 true半选中状态

      posistionCheckAllSelect: false, // 持仓列表全选状态,true选中,false未选中
      totle: 0,

      poAccList: null, //持仓账户筛选列表
      selectAccId: this.$t('m.全部'), //筛选账户Id

      currentEntrustList: null, //当前委托列表

      // 当前委托入金账户名列表
      currentPublicUserList: null,

      transferShow: false, //划转
      accOne: "币币账户",
      accTwo: 2,

      transferNum: null, //划转数量
      rotatableNumber: 0, //可划转数
      spotAcc: null, //现货划转账户
      contAcc: null, //合约划转账户

      setTime: "",
      operatorUserShow: false, //轮询判断
      checkAccList: [], //上次选中账户

      dataList: [], //交易所合约张数价格合集

      secondaryShow: false, //单个平仓弹框

      secondary: false, // 二次确认弹窗
      secondaryType: null,
      secondaryItem: null,
      secondaryTitle: null,
      secondaryText: null,
      currencyIndex: 0,
      pairCoinList: [],
      currencyCoinList: ["USDT", "BTC", "ETH"],
      newPairListNow: [],

      //*** */    划转按钮
      transferShow: false, //划转按钮
      transfer: false, //划转显隐
      inputOptions: null,
      currencyValue: null,
      usdtAvailable: null,
      inputStyle: null,
      twoOptions: [],
      twoValue: null,
      currencyList: [],
      topValue: null,
      topOptions: [],
      showOrderSmallCoin: sessionStorage.getItem("showOrderSmallCoin")
        ? JSON.parse(sessionStorage.getItem("showOrderSmallCoin"))
        : false, //隐藏小额币种
      openList: [], //下单状态
      //***********///////////////////////////////条件单
      breach: 0, //买入突破方向
      breachTwo: 0, //卖出突破方向
      breachType: 0, //买入价格类型
      breachTypeTwo: 0, //卖出价格类型
      buyActionTriggerPrice: null, //买入触发价格
      buyActionTriggerPriceTwo: null, //卖出触发价格
      buyActionPurchasePrice: null, //买入价格
      buyActionPurchasePriceTwo: null, //卖出价格

      //弹出框
      scopeShow: false, //止盈止损显隐
      scopeType: null, //title
      breachScope: 0,
      scopeInvestmentType: "固定投入",
      scopeTriggerPrice: null,
      scopedData: null,
      scopedNum: null,
      scopedSlider: null,
      scopedData: null,
      scopedNum: null,
      scopedShow: false,
      scopedNumModel: null,
      scopedNumberPrice: null,
      scopedHolder: null,
      scopedPriceShow: false,
      pointNumber: [],

      //当前委托列表排序
      screenTime: 1,
      screenDirection: 0,
      screenMode: 0,
      screenNumber: 0,
      screenState: 0,

      actionShowList: [10, 25, 50, 75, 100],
      actionBtnIndex: 0,
      accSelectAll: false,

      clientWidth: null, //页面宽度
      pairShow: false, //币种选择显隐
      pairHandicap: [], //盘口数据
      historyEntrustList: null, //历史委托数据

      selectAllShow: false, //展示全部账户
      selectAllShowList: [], //全部账户数据备份
      accSelectAllShow: false, //全部账户全选
      typeListShow: null,
      transactionTypeShow: "spot",
      exchangeNameShow: null,

      // 提示

      tipsShow: false,
      tipsNum: 1,

      socket: null,
      socketShow: true, //盘口轮询
    };
  },
  computed: {},
  watch: {
    selectAccId(New, old) {
      if (New != this.$t('m.全部')) {
        this.getSpotApiList(New);
      } else {
        this.getSpotApiList();
      }
    },
    currencyIndex() {
      let pairCoinList = [];
      if (this.pairList) {
        this.pairList.forEach((e) => {
          if (e.baseCoin == this.currencyCoinList[this.currencyIndex]) {
            pairCoinList.push(e);
          }
        });
      } else {
        this.newPairListNow.forEach((e) => {
          if (e.baseCoin == this.currencyCoinList[this.currencyIndex]) {
            pairCoinList.push(e);
          }
        });
      }
      this.pairCoinList = pairCoinList;
    },
    showOrderSmallCoin() {
      sessionStorage.setItem("showOrderSmallCoin", this.showOrderSmallCoin);
      if (this.transactionType == "contract_usdt") {
        this.batchGetContractApiList();
      } else {
        this.getSpotApiList();
      }
    },
    //滑块
    scopedSlider() {
      this.scopedNumModel = null;
      if (this.scopedSlider == 0) {
        this.scopedNum = 0;
      } else {
        if (this.transactionType !== "spot") {
          this.scopedNum =
            this.scopedSlider +
            "%( ≈ " +
            Math.floor(
              (this.scopedData.availableNum * this.scopedSlider) / 100
            ) +
            " )";
        } else {
          let availableNum = (
            Math.ceil(
              this.scopedData.availableNum * this.scopedSlider * 100000
            ) / 10000000
          ).toString();
          //向下取整
          if (availableNum.indexOf(".") > -1) {
            if (this.scopedData.coinType == "BTC") {
              // availableNum = (
              //   Math.ceil(availableNum * 1000000) / 1000000
              // ).toString();
              availableNum = Number(
                availableNum.slice(0, availableNum.indexOf(".") + 7)
              );
            } else {
              // availableNum = (
              //   Math.ceil(availableNum * 10000) / 10000
              // ).toString();
              availableNum = Number(
                availableNum.slice(0, availableNum.indexOf(".") + 5)
              );
            }
          }
          this.scopedNum = this.scopedSlider + "%( ≈ " + availableNum + " )";
        }
      }
    },
    //滑块输入框
    scopedNumModel() {
      if (
        this.scopedNumModel &&
        this.scopedNumModel.toString().indexOf(".") > -1
      ) {
        if (this.transactionType !== "spot") {
          this.scopedNumModel = this.scopedNumModel
            .toString()
            .slice(0, this.scopedNumModel.indexOf("."));
        } else {
          if (this.scopedData.coinType == "BTC") {
            this.scopedNumModel = this.scopedNumModel
              .toString()
              .slice(0, this.scopedNumModel.indexOf(".") + 7);
          } else {
            this.scopedNumModel = this.scopedNumModel
              .toString()
              .slice(0, this.scopedNumModel.indexOf(".") + 5);
          }
        }
      }

      if (!this.scopedNumModel) {
        $(".styleBorderColor .el-input__inner")[0].style.border =
          "1px solid #C0C4CC";
      }
    },

    // retainParams: {
    //   handler(New, old) {
    //     sessionStorage.setItem('retainParams',JSON.stringify(New))
    //   },
    //   immediate: true,
    //   deep: true
    // },
    //时间排序
    screenTime() {
      this.screenTimeBtn();
    },
    //方向排序
    screenDirection() {
      this.screenDirectionBtn();
    },
    //下单方式排序
    screenMode() {
      this.screenModeBtn();
    },
    //数量排序
    screenNumber() {
      this.screenNumberBtn();
    },
    //状态排序
    screenState() {
      this.screenStateBtn();
    },
  },
  methods: {
    selectAllHide() {
      this.selectAllShow = false;
      this.selectAllShowList = [];
      sessionStorage.removeItem("selectAllShowList");
    },
    //展开账户确定按钮
    selectAllShowDetermine() {
      this.selectAllShow = false;
      this.accList = JSON.parse(JSON.stringify(this.selectAllShowList));
      this.transactionType = JSON.parse(
        JSON.stringify(this.transactionTypeShow)
      );
      let accList = [];
      if (this.accList && this.accList.length > 0) {
        this.accList.forEach((e) => {
          if (e.isCheck == true) {
            accList.push(e);
          }
        });
      }
      sessionStorage.setItem("checkAccList", JSON.stringify(accList));
      this.exchangeName = JSON.parse(JSON.stringify(this.exchangeNameShow));
      this.pair = JSON.parse(JSON.stringify(this.pairShowList));
      this.exchangeId = JSON.parse(JSON.stringify(this.exchangeIdShow));
      this.positionList = [];
      this.currentEntrustList = [];
      this.historyEntrustList = [];
    },
    //展开全部按钮
    selectAllShowBtn() {
      //123456
      this.selectAllShow = true;
      this.selectAllShowList = JSON.parse(JSON.stringify(this.accList));
      this.transactionTypeShow = JSON.parse(
        JSON.stringify(this.transactionType)
      );
      this.exchangeNameShow = JSON.parse(JSON.stringify(this.exchangeName));
      this.pairShowList = JSON.parse(JSON.stringify(this.pair));
      this.exchangeIdShow = JSON.parse(JSON.stringify(this.exchangeId));
      this.accSelectAllShow = JSON.parse(JSON.stringify(this.accSelectAll));
    },
    //账户全选
    accSelectAllBtn() {
      this.accList.forEach((e) => {
        e.isCheck = this.accSelectAll;
      });
      if (this.accSelectAll) {
        sessionStorage.setItem("checkAccList", JSON.stringify(this.accList));
      } else {
        sessionStorage.removeItem("checkAccList");
      }
    },
    //展开账户全选
    accSelectAllShowBtn() {
      this.selectAllShowList.forEach((e) => {
        e.isCheck = this.accSelectAllShow;
      });
    },
    //点击进度条
    scopedClick() {
      this.actionBtnIndex = null;
    },
    //止盈止损进度按钮
    actionBtnClick(index) {
      this.actionBtnIndex = index;
      this.scopedSlider = index;
    },
    //止盈止损进度输入框
    scopedNumModelBlur(e) {
      if (this.scopedNumModel > this.scopedData.availableNum) {
        this.actionBtnIndex = null;
        e.target.style.border = "1px solid #F23A4C";
        this.$message.error(this.$t('m.投入不能大于投入范围'));
      } else if (this.scopedNumModel == null) {
        e.target.style.border = "1px solid #C0C4CC";
      } else if (this.scopedNumModel <= 0) {
        this.actionBtnIndex = null;
        e.target.style.border = "1px solid #F23A4C";
        this.$message.error(this.$t('m.投入不能小于0'));
      } else {
        this.actionBtnIndex = null;
        this.scopedSlider = 0;
        this.scopedSlider = Math.ceil(
          (this.scopedNumModel / this.scopedData.availableNum) * 100
        );
        e.target.style.border = "1px solid #C0C4CC";
      }
    },
    // tips结束
    tipsComplete() {
      this.tipsNum = 1;
      this.tipsShow = false;
      localStorage.setItem("expressTransactionTips", true);
    },

    //当前委托列表排序
    screenBtn(name) {
      if (name == "时间") {
        this.screenTime++;
        this.screenDirection = 0;
        this.screenMode = 0;
        this.screenNumber = 0;
        this.screenState = 0;
        if (this.screenTime > 2) {
          this.screenTime = 1;
        }
      } else if (name == "方向") {
        this.screenTime = 0;
        this.screenMode = 0;
        this.screenNumber = 0;
        this.screenState = 0;
        this.screenDirection++;
        if (this.screenDirection > 2) {
          this.screenDirection = 0;
          this.screenTime = 1;
        }
      } else if (name == "下单方式") {
        this.screenTime = 0;
        this.screenDirection = 0;
        this.screenNumber = 0;
        this.screenState = 0;
        this.screenMode++;
        if (this.screenMode > 2) {
          this.screenMode = 0;
          this.screenTime = 1;
        }
      } else if (name == "数量") {
        this.screenTime = 0;
        this.screenDirection = 0;
        this.screenMode = 0;
        this.screenState = 0;
        this.screenNumber++;
        if (this.screenNumber > 2) {
          this.screenNumber = 0;
          this.screenTime = 1;
        }
      } else if (name == "状态") {
        this.screenTime = 0;
        this.screenDirection = 0;
        this.screenMode = 0;
        this.screenNumber = 0;
        this.screenState++;
        if (this.screenState > 2) {
          this.screenState = 0;
          this.screenTime = 1;
        }
      }
    },
    //状态排序事件
    screenStateBtn() {
      let WAIT_TRIGGERList = [];
      let WAIT_DEALList = [];
      let ALREADY_DEALList = [];
      let REEORList = [];
      this.backupsCurrentScreenList.forEach((e) => {
        if (e.states == "WAIT_TRIGGER") {
          WAIT_TRIGGERList.push(e);
        } else if (e.states == "WAIT_DEAL") {
          WAIT_DEALList.push(e);
        } else if (e.states == "ALREADY_DEAL") {
          ALREADY_DEALList.push(e);
        } else {
          REEORList.push(e);
        }
      });
      if (this.screenState == 1) {
        this.currentEntrustList = [
          ...WAIT_TRIGGERList,
          ...WAIT_DEALList,
          ...ALREADY_DEALList,
          ...REEORList,
        ];
      } else if (this.screenState == 2) {
        this.currentEntrustList = [
          ...REEORList,
          ...ALREADY_DEALList,
          ...WAIT_DEALList,
          ...WAIT_TRIGGERList,
        ];
      }
    },
    //数量排序事件
    screenNumberBtn() {
      let buyList = [];
      let sellList = [];
      let numberList = [];
      this.backupsCurrentScreenList.forEach((e) => {
        if (e.orderDirection == "buy") {
          buyList.push(e);
        } else if (e.orderDirection == "sell") {
          sellList.push(e);
        } else {
          numberList.push(e);
        }
      });
      if (this.screenNumber == 2) {
        for (let i = 0; i < buyList.length; i++) {
          for (let item = 0; item < buyList.length; item++) {
            if (buyList[i].investNum < buyList[item].investNum) {
              let number = buyList[item];
              buyList[item] = buyList[i];
              buyList[i] = number;
              item--;
            }
          }
        }
        for (let i = 0; i < sellList.length; i++) {
          for (let item = 0; item < sellList.length; item++) {
            if (sellList[i].investNum < sellList[item].investNum) {
              let number = sellList[item];
              sellList[item] = sellList[i];
              sellList[i] = number;
              item--;
            }
          }
        }
        for (let i = 0; i < numberList.length; i++) {
          for (let item = 0; item < numberList.length; item++) {
            if (numberList[i].investNum < numberList[item].investNum) {
              let number = numberList[item];
              numberList[item] = numberList[i];
              numberList[i] = number;
              item--;
            }
          }
        }
      } else if (this.screenNumber == 1) {
        for (let i = 0; i < buyList.length; i++) {
          for (let item = 0; item < buyList.length; item++) {
            if (buyList[i].investNum > buyList[item].investNum) {
              let number = buyList[item];
              buyList[item] = buyList[i];
              buyList[i] = number;
              item--;
            }
          }
        }
        for (let i = 0; i < sellList.length; i++) {
          for (let item = 0; item < sellList.length; item++) {
            if (sellList[i].investNum > sellList[item].investNum) {
              let number = sellList[item];
              sellList[item] = sellList[i];
              sellList[i] = number;
              item--;
            }
          }
        }
        for (let i = 0; i < numberList.length; i++) {
          for (let item = 0; item < numberList.length; item++) {
            if (numberList[i].investNum > numberList[item].investNum) {
              let number = numberList[item];
              numberList[item] = numberList[i];
              numberList[i] = number;
              item--;
            }
          }
        }
      }
      this.currentEntrustList = [...buyList, ...sellList, ...numberList];
    },
    //下单方式排序
    screenModeBtn() {
      let conditionList = [];
      let stopSurplusList = [];
      let stopLossList = [];
      let fixedPriceList = [];
      let marketPriceList = [];
      this.backupsCurrentScreenList.forEach((e) => {
        if (JSON.parse(e.executeConfig).actions[0].name == "plan-order") {
          if (e.type == 0) {
            stopSurplusList.push(e);
          } else if (e.type == 1) {
            stopLossList.push(e);
          } else {
            conditionList.push(e);
          }
        } else {
          if (e.priceType == 1) {
            fixedPriceList.push(e);
          } else {
            marketPriceList.push(e);
          }
        }
      });
      if (this.screenMode == 1) {
        this.currentEntrustList = [
          ...conditionList,
          ...stopSurplusList,
          ...stopLossList,
          ...fixedPriceList,
          ...marketPriceList,
        ];
      } else if (this.screenMode == 2) {
        this.currentEntrustList = [
          ...marketPriceList,
          ...fixedPriceList,
          ...stopLossList,
          ...stopSurplusList,
          ...conditionList,
        ];
      }
    },
    //方向排序
    screenDirectionBtn() {
      let buyList = [];
      let sellList = [];
      let open_many = [];
      let open_empty = [];
      let flat_many = [];
      let flat_empty = [];
      this.backupsCurrentScreenList.forEach((e) => {
        if (e.orderDirection == "buy") {
          buyList.push(e);
        } else if (e.orderDirection == "sell") {
          sellList.push(e);
        } else if (e.orderDirection == "open_many") {
          open_many.push(e);
        } else if (e.orderDirection == "open_empty") {
          open_empty.push(e);
        } else if (e.orderDirection == "flat_many") {
          flat_many.push(e);
        } else if (e.orderDirection == "flat_empty") {
          flat_empty.push(e);
        }
      });

      if (this.screenDirection == 1) {
        this.currentEntrustList = [
          ...buyList,
          ...sellList,
          ...open_many,
          ...open_empty,
          ...flat_many,
          ...flat_empty,
        ];
      } else if (this.screenDirection == 2) {
        this.currentEntrustList = [
          ...flat_empty,
          ...flat_many,
          ...open_empty,
          ...open_many,
          ...sellList,
          ...buyList,
        ];
      }
    },
    //时间排序
    screenTimeBtn() {
      if (this.screenTime == 1) {
        // for (let i = 0; i < this.currentEntrustList.length; i++) {
        //   for (
        //     let item = i + 1;
        //     item < this.currentEntrustList.length;
        //     item++
        //   ) {
        //     if (
        //       this.currentEntrustList[i].createTime >
        //       this.currentEntrustList[item].createTime
        //     ) {
        //       let number = this.currentEntrustList[item];
        //       this.currentEntrustList[item] = this.currentEntrustList[i];
        //       this.currentEntrustList[i] = number;
        //     }
        //   }
        // }
        let currentEntrustList = [];
        this.backupsCurrentScreenList.forEach((e) => {
          currentEntrustList.push(e);
        });
        this.currentEntrustList = currentEntrustList;
      } else if (this.screenTime == 2) {
        let currentEntrustList = [];
        this.backupsCurrentScreenList.forEach((e) => {
          currentEntrustList.unshift(e);
        });
        this.currentEntrustList = currentEntrustList;
        // for (let i = 0; i < this.currentEntrustList.length; i++) {
        //   for (
        //     let item = i + 1;
        //     item < this.currentEntrustList.length;
        //     item++
        //   ) {
        //     if (
        //       this.currentEntrustList[i].createTime <
        //       this.currentEntrustList[item].createTime
        //     ) {
        //       let number = this.currentEntrustList[item];
        //       this.currentEntrustList[item] = this.currentEntrustList[i];
        //       this.currentEntrustList[i] = number;
        //     }
        //   }
        // }
      }
    },
    /////***************/////排序事件结束////// */

    // 合约开仓平仓切换
    contractPositionTTypeChange() {
      sessionStorage.setItem(
        "contractPositionTType",
        this.contractPositionTType
      );
    },

    // 买入监听事件
    buyNumChange() {
      if (
        this.transactionType !== "spot" &&
        this.buyInvestmentType == "固定投入"
      ) {
        if (this.buyNum && this.buyNum.indexOf(".") > -1) {
          this.buyNum = this.buyNum.split(".")[0];
        }
      } else if (
        this.transactionType == "spot" &&
        this.buyInvestmentType == "固定投入"
      ) {
        if (
          this.buyNum &&
          this.buyNum.indexOf(".") > -1 &&
          this.buyInvestmentType == "固定投入"
        ) {
          let counterCoinList = [];
          this.dataList.forEach((e) => {
            if (this.pointNumber.length == 0) {
              counterCoinList.push(+e.baseCoinDecimal);
            } else {
              this.pointNumber.forEach((item) => {
                if (
                  item.exchangeName.toUpperCase() ==
                  e.exchangeName.toUpperCase()
                ) {
                  counterCoinList.push(+e.baseCoinDecimal);
                }
              });
            }
          });
          counterCoinList.sort((a, b) => {
            return a - b;
          });
          this.buyNum = this.buyNum.slice(
            0,
            this.buyNum.indexOf(".") + counterCoinList[0] + 1
          );
        }
      }
      if (this.buyNum) {
        sessionStorage.setItem("buyNum", this.buyNum);
      }
    },
    // 买入投入方向切换监听
    buyNumType() {
      if (this.buyInvestmentType == "固定投入") {
        this.buyNumChange();
      }
      if (this.buyInvestmentType) {
        sessionStorage.setItem("buyInvestmentType", this.buyInvestmentType);
      }
    },
    // 买入限价监听
    buyActionPriceChange() {
      if (this.buyActionPrice && this.buyActionPrice.indexOf(".") > -1) {
        if (this.transactionType == "spot") {
          let counterCoinList = [];
          this.dataList.forEach((e) => {
            if (this.pointNumber.length == 0) {
              counterCoinList.push(+e.baseCoinDecimal);
            } else {
              this.pointNumber.forEach((item) => {
                if (
                  item.exchangeName.toUpperCase() ==
                  e.exchangeName.toUpperCase()
                ) {
                  counterCoinList.push(+e.baseCoinDecimal);
                }
              });
            }
          });
          counterCoinList.sort((a, b) => {
            return a - b;
          });
          this.buyActionPrice = this.buyActionPrice.slice(
            0,
            this.buyActionPrice.indexOf(".") + counterCoinList[0] + 1
          );
        } else {
          this.buyActionPrice = this.buyActionPrice.slice(
            0,
            this.buyActionPrice.indexOf(".") + 3
          );
        }
      }
    },
    // 卖出监听事件
    sellNumChange() {
      if (
        this.transactionType !== "spot" &&
        this.sellInvestmentType == "固定投入"
      ) {
        if (this.sellNum && this.sellNum.indexOf(".") > -1) {
          this.sellNum = this.sellNum.split(".")[0];
        }
      } else if (
        this.transactionType == "spot" &&
        this.sellInvestmentType == "固定投入"
      ) {
        if (
          this.sellNum &&
          this.sellNum.indexOf(".") > -1 &&
          this.sellInvestmentType == "固定投入"
        ) {
          let counterCoinList = [];
          this.dataList.forEach((e) => {
            if (this.pointNumber.length == 0) {
              counterCoinList.push(+e.counterCoinDecimal);
            } else {
              this.pointNumber.forEach((item) => {
                if (
                  item.exchangeName.toUpperCase() ==
                  e.exchangeName.toUpperCase()
                ) {
                  counterCoinList.push(+e.counterCoinDecimal);
                }
              });
            }
          });
          counterCoinList.sort((a, b) => {
            return a - b;
          });
          this.sellNum = this.sellNum.slice(
            0,
            this.sellNum.indexOf(".") + counterCoinList[0] + 1
          );
        }
      }
      if (this.sellNum) {
        sessionStorage.setItem("sellNum", this.sellNum);
      }
    },
    // 卖出投入方向切换监听
    sellNumType() {
      if (this.sellInvestmentType == "固定投入") {
        this.sellNumChange();
      }
      sessionStorage.setItem("sellInvestmentType", this.sellInvestmentType);
    },
    // 卖出限价监听
    sellActionPriceChange() {
      if (this.sellActionPrice && this.sellActionPrice.indexOf(".") > -1) {
        if (this.transactionType == "spot") {
          let counterCoinList = [];
          this.dataList.forEach((e) => {
            if (this.pointNumber.length == 0) {
              counterCoinList.push(+e.baseCoinDecimal);
            } else {
              this.pointNumber.forEach((item) => {
                if (
                  item.exchangeName.toUpperCase() ==
                  e.exchangeName.toUpperCase()
                ) {
                  counterCoinList.push(+e.baseCoinDecimal);
                }
              });
            }
          });
          counterCoinList.sort((a, b) => {
            return a - b;
          });
          this.sellActionPrice = this.sellActionPrice.slice(
            0,
            this.sellActionPrice.indexOf(".") + counterCoinList[0] + 1
          );
        } else {
          this.sellActionPrice = this.sellActionPrice.slice(
            0,
            this.sellActionPrice.indexOf(".") + 3
          );
        }
      }
    },
    // 比例投入买入下限监听
    buyMinChange() {
      sessionStorage.setItem("buyMin", this.buyMin);
    },
    // 比例投入买入上限监听
    buyMaxChange() {
      sessionStorage.setItem("buyMax", this.buyMax);
    },
    // 比例投入卖出下限监听
    sellMinChange() {
      sessionStorage.setItem("sellMin", this.sellMin);
    },
    // 比例投入卖出上限监听
    sellMaxChange() {
      sessionStorage.setItem("sellMax", this.sellMax);
    },
    //条件单买入单价格监听
    buyActionTriggerChange() {
      if (
        this.buyActionTriggerPrice &&
        this.buyActionTriggerPrice.indexOf(".") > -1
      ) {
        if (this.transactionType == "spot") {
          let counterCoinList = [];
          this.dataList.forEach((e) => {
            if (this.pointNumber.length == 0) {
              counterCoinList.push(+e.baseCoinDecimal);
            } else {
              this.pointNumber.forEach((item) => {
                if (
                  item.exchangeName.toUpperCase() ==
                  e.exchangeName.toUpperCase()
                ) {
                  counterCoinList.push(+e.baseCoinDecimal);
                }
              });
            }
          });
          counterCoinList.sort((a, b) => {
            return a - b;
          });
          this.buyActionTriggerPrice = this.buyActionTriggerPrice.slice(
            0,
            this.buyActionTriggerPrice.indexOf(".") + counterCoinList[0] + 1
          );
        } else {
          this.buyActionTriggerPrice = this.buyActionTriggerPrice.slice(
            0,
            this.buyActionTriggerPrice.indexOf(".") + 3
          );
        }
      }
      if (this.buyActionTriggerPrice) {
        sessionStorage.setItem(
          "buyActionTriggerPrice",
          this.buyActionTriggerPrice
        );
      }
    },
    //条件单卖出单价格监听
    sellActionTriggerChange() {
      if (
        this.buyActionTriggerPriceTwo &&
        this.buyActionTriggerPriceTwo.indexOf(".") > -1
      ) {
        if (this.transactionType == "spot") {
          let counterCoinList = [];
          this.dataList.forEach((e) => {
            if (this.pointNumber.length == 0) {
              counterCoinList.push(+e.baseCoinDecimal);
            } else {
              this.pointNumber.forEach((item) => {
                if (
                  item.exchangeName.toUpperCase() ==
                  e.exchangeName.toUpperCase()
                ) {
                  counterCoinList.push(+e.baseCoinDecimal);
                }
              });
            }
          });
          counterCoinList.sort((a, b) => {
            return a - b;
          });
          this.buyActionTriggerPriceTwo = this.buyActionTriggerPriceTwo.slice(
            0,
            this.buyActionTriggerPriceTwo.indexOf(".") + counterCoinList[0] + 1
          );
        } else {
          this.buyActionTriggerPriceTwo = this.buyActionTriggerPriceTwo.slice(
            0,
            this.buyActionTriggerPriceTwo.indexOf(".") + 3
          );
        }
      }
      if (this.buyActionTriggerPriceTwo) {
        sessionStorage.setItem(
          "buyActionTriggerPriceTwo",
          this.buyActionTriggerPriceTwo
        );
      }
    },
    //条件单买入限价价格
    buyActionPurchaseChange() {
      if (
        this.buyActionPurchasePrice &&
        this.buyActionPurchasePrice.indexOf(".") > -1
      ) {
        if (this.transactionType == "spot") {
          let counterCoinList = [];
          this.dataList.forEach((e) => {
            if (this.pointNumber.length == 0) {
              counterCoinList.push(+e.baseCoinDecimal);
            } else {
              this.pointNumber.forEach((item) => {
                if (
                  item.exchangeName.toUpperCase() ==
                  e.exchangeName.toUpperCase()
                ) {
                  counterCoinList.push(+e.baseCoinDecimal);
                }
              });
            }
          });
          counterCoinList.sort((a, b) => {
            return a - b;
          });
          this.buyActionPurchasePrice = this.buyActionPurchasePrice.slice(
            0,
            this.buyActionPurchasePrice.indexOf(".") + counterCoinList[0] + 1
          );
        } else {
          this.buyActionPurchasePrice = this.buyActionPurchasePrice.slice(
            0,
            this.buyActionPurchasePrice.indexOf(".") + 3
          );
        }
      }
      if (this.buyActionPurchasePrice) {
        sessionStorage.setItem(
          "buyActionPurchasePrice",
          this.buyActionPurchasePrice
        );
      }
    },
    //条件单卖出限价价格
    sellActionPurchaseChange() {
      if (
        this.buyActionPurchasePriceTwo &&
        this.buyActionPurchasePriceTwo.indexOf(".") > -1
      ) {
        if (this.transactionType == "spot") {
          let counterCoinList = [];
          this.dataList.forEach((e) => {
            if (this.pointNumber.length == 0) {
              counterCoinList.push(+e.baseCoinDecimal);
            } else {
              this.pointNumber.forEach((item) => {
                if (
                  item.exchangeName.toUpperCase() ==
                  e.exchangeName.toUpperCase()
                ) {
                  counterCoinList.push(+e.baseCoinDecimal);
                }
              });
            }
          });
          counterCoinList.sort((a, b) => {
            return a - b;
          });
          this.buyActionPurchasePriceTwo = this.buyActionPurchasePriceTwo.slice(
            0,
            this.buyActionPurchasePriceTwo.indexOf(".") + counterCoinList[0] + 1
          );
        } else {
          this.buyActionPurchasePriceTwo = this.buyActionPurchasePriceTwo.slice(
            0,
            this.buyActionPurchasePriceTwo.indexOf(".") + 3
          );
        }
      }
      if (this.buyActionPurchasePriceTwo) {
        sessionStorage.setItem(
          "buyActionPurchasePriceTwo",
          this.buyActionPurchasePriceTwo
        );
      }
    },
    //条件单买入突破方向
    onBreachBtn(e) {
      this.breach = e;
      sessionStorage.setItem("breach", e);
    },
    //条件单卖出突破方向
    onBreachBtnTwo(e) {
      this.breachTwo = e;
      sessionStorage.setItem("breachTwo", e);
    },
    //条件单买入价格类型
    breachTypeBtn(e) {
      this.breachType = e;
      sessionStorage.setItem("breachType", e);
    },
    //条件单卖出价格类型
    breachTypeTwoBtn(e) {
      this.breachTypeTwo = e;
      sessionStorage.setItem("breachTypeTwo", e);
    },

    // 投入解释
    inputInterpretation() {
      this.$alert(
        "<div style='color:#102859'><p>"+
        this.$t('m.1、设置固定投入时，不满足金额或数量的账户不下单；')+
        "</p><p>"+
        this.$t('m.2、设置比例投入，账户满足比例时，低于下限的不下单；高于上限的按上限下单。')+
        "</p></div>",
        this.$t('m.投入'),
        {
          confirmButtonText: this.$t('m.确定'),
          dangerouslyUseHTMLString: true,
        }
      );
    },

    // 底部输入框筛选
    blurAccId() {
      sessionStorage.setItem("btmSelectInp", this.btmSelectInp);
      if (this.activeName == "1") {
        if (this.transactionType == "spot") {
          this.getSpotApiList();
        } else {
          this.batchGetContractApiList();
        }
      } else if (this.activeName == "2") {
        this.currentEntrust();
      } else {
        this.historyEntrust();
      }
    },

    //重置
    removeAccId() {
      this.btmSelectInp = null;
      if (this.activeName == "1") {
        if (this.transactionType == "spot") {
          this.getSpotApiList();
        } else {
          this.batchGetContractApiList();
        }
      } else if (this.activeName == "2") {
        this.currentEntrust();
      } else {
        this.historyEntrust();
      }
    },

    // 获取随机标签
    queryOrderLabel() {
      let payload = {
        token: sessionStorage.getItem("token"),
      };
      queryOrderLabel(payload).then((res) => {
        if (res.status == 200) {
          this.orderLabel = res.data.orderLabel
            ? res.data.orderLabel.label
            : null;
        }
      });
    },

    // 标签改变
    orderLabelChange(v) {},

    // 查询交易所/交易类型
    async queryProjectData() {
      let payload = {
        token: sessionStorage.getItem("token"),
        teamProjectId: this.project.teamProjectId,
      };
      queryProjectData(payload).then((res) => {
        if (res.status == 200) {
          let list = JSON.parse(res.data.resultMap.spotOrContract);
          let newList = [];
          for (let key of list) {
            let item = {};
            if (key == "spot") {
              item.value = "spot";
              item.label = "币币";
            } else if (key == "contract_usdt") {
              item.value = "contract_usdt";
              item.label = "合约-USDT永续";
            }
            newList.push(item);
          }
          this.typeList = newList;
          let exchangeLimit = JSON.parse(res.data.resultMap.exchangeLimit);
          exchangeLimit.unshift({ exchangeName: this.$t('m.全部'), exchangeId: 0 });
          this.exchangeList = exchangeLimit;

          let exchangeName = sessionStorage.getItem("exchangeName");
          if (exchangeName) {
            this.exchangeId = sessionStorage.getItem("exchangeId");
            this.exchangeName = sessionStorage.getItem("exchangeName");
          } else {
            this.exchangeName = this.exchangeList[0].exchangeName; //默认交易所
            this.exchangeId = this.exchangeList[0].exchangeId; //默认交易所
            sessionStorage.setItem(
              "exchangeId",
              this.exchangeList[0].exchangeId
            );
            sessionStorage.setItem(
              "exchangeName",
              this.exchangeList[0].exchangeName
            );
          }
          if (this.transactionType == "spot") {
            this.queryPair(() => {
              this.queryOperatorUserList();
              this.quickUserAndData();
            });
          } else {
            this.queryOrderChainContractPair(() => {
              this.queryOperatorUserList();
              this.quickUserAndData();
            });
          }
        }
      });
    },

    // 交易类型切换
    typeChange(v) {
      this.transactionTypeShow = v;
      if (v == "spot") {
        this.queryPair(() => {
          this.queryOperatorUserList();
        });
      } else {
        if (!this.selectAllShow) {
          this.sellInvestmentType = "固定投入";
        }
        this.queryOrderChainContractPair(() => {
          this.queryOperatorUserList();
        });
        // this.findByIdLeverMultiple();
      }

      if (this.selectAllShow) {
        return;
      }

      sessionStorage.setItem("transactionType", v);
      this.accList = null;

      // this.socketClick();
      sessionStorage.removeItem("pair");
      sessionStorage.removeItem("MessageTemaList");

      this.posistionCheckAllSelect = false;
      this.posistionCheckAll = false;

      if (this.activeName == "1") {
        this.positionList = [];
        v == "spot" ? this.getSpotApiList() : this.batchGetContractApiList();
      } else if (this.activeName == "2") {
        this.currentEntrustList = [];
        this.currentEntrust();
      } else if (this.activeName == "3") {
        this.historyEntrust();
      }

      this.input = "";
      this.multipleSelection = [];
      this.buyNumChange();
      this.buyActionPriceChange();
      this.sellNumChange();
      this.sellActionPriceChange();
      this.buyActionTriggerChange();
      this.sellActionTriggerChange();
      this.buyActionPurchaseChange();
      this.sellActionPurchaseChange();
    },

    // 交易所切换
    checkExchange(v) {
      if (this.selectAllShow) {
        for (let key of this.exchangeList) {
          if (v == key.exchangeName) {
            this.exchangeIdShow = key.exchangeId;
          }
        }
        this.accSelectAllShow = false;
        this.selectAllShowList = null;
        sessionStorage.removeItem("selectAllShowList");
        if (this.transactionTypeShow == "spot") {
          this.queryPair(() => {
            this.queryOperatorUserList();
          });
        } else {
          this.queryOrderChainContractPair(() => {
            this.queryOperatorUserList();
          });
          // this.findByIdLeverMultiple();
        }
        return;
      }
      this.accList = null;
      this.accSelectAll = false;
      for (let key of this.exchangeList) {
        if (v == key.exchangeName) {
          this.exchangeId = key.exchangeId;
        }
      }
      if (this.transactionType == "spot") {
        this.queryPair(() => {
          this.queryOperatorUserList();
        });
      } else {
        this.queryOrderChainContractPair(() => {
          this.queryOperatorUserList();
        });
        // this.findByIdLeverMultiple();
      }

      if (this.activeName == "1") {
        v == "spot" ? this.getSpotApiList() : this.batchGetContractApiList();
      } else if (this.activeName == "2") {
        this.currentEntrust();
      } else if (this.activeName == "3") {
        this.historyEntrust();
      }
      this.checkAccList = [];
      sessionStorage.setItem("exchangeName", v);
      sessionStorage.setItem("exchangeId", this.exchangeId);
      sessionStorage.removeItem("pair");
      sessionStorage.removeItem("checkAccList");
    },

    // 查询账户列表
    queryOperatorUserList() {
      let payload = {
        token: sessionStorage.getItem("token"),
        exchangeName: this.exchangeName == this.$t('m.全部') ? "" : this.exchangeName,
        teamProjectId: this.project.teamProjectId,
        transactionType:
          this.transactionType == "spot" ? "spot" : "contract_usdt",
      };
      if (this.selectAllShow) {
        payload.transactionType =
          this.transactionTypeShow == "spot" ? "spot" : "contract_usdt";
        payload.exchangeName =
          this.exchangeNameShow == this.$t('m.全部') ? "" : this.exchangeNameShow;
        if (this.transactionTypeShow == "spot") {
          payload.investCoin =
            this.pairShowList.baseCoin + "," + this.pairShowList.counterCoin; //现货
          if (!payload.investCoin) {
            return;
          }
        } else {
          payload.contractId = this.pairShowList.contractId; //合约
          payload.counterCoin = this.pairShowList.counterCoin;
          if (!payload.contractId) {
            return;
          }
        }
      } else {
        if (this.transactionType == "spot") {
          payload.investCoin = this.pair.baseCoin + "," + this.pair.counterCoin; //现货
          if (!payload.investCoin) {
            return;
          }
        } else {
          payload.contractId = this.pair ? this.pair.contractId : "--"; //合约
          payload.counterCoin = this.pair ? this.pair.counterCoin : "--";
          if (!payload.contractId) {
            return;
          }
        }
      }

      queryOperatorUserList(payload).then(async (res) => {
        if (res.status == 200) {
          let list = res.data.operatorUserList;
          if (!list || list.length == 0) {
            if (this.selectAllShow) {
              this.selectAllShowList = null;
            } else {
              this.accList = null;
            }
            return;
          }

          let newList = [];
          let transactionType = this.transactionType;
          if (this.selectAllShow) {
            transactionType = this.transactionTypeShow;
          }

          if (list && list.length > 0 && transactionType == "spot") {
            for (let i = 0; i < list.length; i++) {
              if (list.length == 1) {
                let item = null;
                item = list[i];
                item.counterCoin = this.pair.counterCoin;
                item.counterCoinNum = 0;
                newList.push(item);
              } else {
                for (let j = i + 1; j < list.length; j++) {
                  if (list[i].apiId == list[j].apiId) {
                    let item = null;
                    if (list[i].coinType == this.pair.baseCoin) {
                      item = list[i];
                      item.counterCoin = list[j].coinType;
                      item.counterCoinNum = list[j].available;
                    } else {
                      item = list[j];
                      item.counterCoin = list[i].coinType;
                      item.counterCoinNum = list[i].available;
                    }
                    // list[i].counterCoin = list[j].coinType;
                    // list[i].counterCoinNum = list[j].available;
                    // list.splice(j);
                    newList.push(item);
                  } else if (i == list.length) {
                    let item = null;
                    item = list[i];
                    item.counterCoin = this.pair.counterCoin;
                    item.counterCoinNum = 0;
                    newList.push(item);
                  }
                }
              }
            }

            let oldList = JSON.parse(JSON.stringify(this.accList));
            if (oldList && oldList.length > 0) {
              for (let i = 0; i < oldList.length; i++) {
                for (let j = 0; j < newList.length; j++) {
                  if (
                    newList[j].apiId == oldList[i].apiId &&
                    oldList[i].isCheck
                  ) {
                    newList[j].isCheck = true;
                  }
                }
              }
            } else {
              newList.forEach((e) => {
                e.checked = false;
              });
            }
          } else {
            // 合约
            let oldList = res.data.operatorUserList;
            // 买入张数计算
            let dataList = this.dataList;
            for (let key of oldList) {
              for (let i = 0; i < dataList.length; i++) {
                if (
                  key.exchangeName.toLocaleLowerCase() ==
                  dataList[i].exchangeName.toLocaleLowerCase()
                ) {
                  key.openSize = Math.floor(
                    (Number(key.available) / Number(dataList[i].price)) *
                      this.lever
                  );
                  newList.push(key);
                }
              }
            }

            let oneList = JSON.parse(JSON.stringify(this.accList));

            if (oneList && oneList.length > 0) {
              for (let i = 0; i < oneList.length; i++) {
                for (let j = 0; j < newList.length; j++) {
                  if (
                    newList[j].apiId == oneList[i].apiId &&
                    oneList[i].isCheck
                  ) {
                    newList[j].isCheck = true;
                  }
                }
              }
            }
          }
          let nList = newList;
          if (this.selectAllShow) {
            if (sessionStorage.getItem("selectAllShowList")) {
              let oList = JSON.parse(
                sessionStorage.getItem("selectAllShowList")
              );
              let number = 0;
              if (oList && oList.length > 0) {
                for (let i = 0; i < nList.length; i++) {
                  nList[i].isCheck = false;
                  for (let j = 0; j < oList.length; j++) {
                    if (nList[i].apiId === oList[j].apiId) {
                      number++;
                      nList[i].isCheck = true;
                    }
                  }
                }
                if (number == nList.length) {
                  this.accSelectAllShow = true;
                } else {
                  this.accSelectAllShow = false;
                }
              }
            } else {
              let number = 0;
              for (let i = 0; i < nList.length; i++) {
                if (nList[i].isCheck) {
                  number++;
                }
              }
              if (number == nList.length) {
                this.accSelectAllShow = true;
              } else {
                this.accSelectAllShow = false;
              }
            }
          } else {
            let oList = this.checkAccList;
            if (oList && oList.length > 0) {
              for (let i = 0; i < nList.length; i++) {
                for (let j = 0; j < oList.length; j++) {
                  if (nList[i].apiId == oList[j].apiId) {
                    nList[i].isCheck = true;
                  }
                }
              }
            }
          }

          let currentList = await this.currentEntrust();
          if (currentList.length !== 0) {
            for (let item = 0; item < nList.length; item++) {
              for (let i = 0; i < currentList.length; i++) {
                // flat_empty平空  flat_many平多
                if (
                  nList[item].exchangeName == "Binance" &&
                  nList[item].publicCode == currentList[i].publicPeopleName &&
                  (JSON.parse(currentList[i].executeConfig).actions[0].name !==
                  "plan-order"
                    ? currentList[i].priceType == 1
                    : nList[item].states !== "WAIT_TRIGGER")
                ) {
                  if (currentList[i].orderDirection == "flat_empty") {
                    nList[item].sell =
                      nList[item].sell - Number(currentList[i].investNum);
                  } else if (currentList[i].orderDirection == "flat_many") {
                    nList[item].buy =
                      nList[item].buy - Number(currentList[i].investNum);
                  }
                }
                nList[item].sell = nList[item].sell >= 0 ? nList[item].sell : 0;
                nList[item].buy = nList[item].buy >= 0 ? nList[item].buy : 0;
              }
            }
          }
          if (this.selectAllShow) {
            this.selectAllShowList = JSON.parse(JSON.stringify(nList));
          } else {
            this.accList = JSON.parse(JSON.stringify(nList));
            this.pointNumber = JSON.parse(JSON.stringify(nList));
          }

          // setTimeout(() => {
          //   if (this.operatorUserShow && !this.currentEntrustShow) {
          //     if (this.transactionType == "spot" && this.activeName == "1") {
          //       this.currentEntrustShow = true;
          //       this.getSpotApiList();
          //     } else if (
          //       this.transactionType != "spot" &&
          //       this.activeName == "1"
          //     ) {
          //       this.currentEntrustShow = true;
          //       this.batchGetContractApiList();
          //     } else if (this.activeName == "2") {
          //       this.currentEntrustShow = true;
          //       this.currentEntrust();
          //     }
          //   } else if (!this.operatorUserShow) {
          //     this.operatorUserShow = true;
          //   }
          // }, 100);
        }
        // else {
        //   this.$message.error(res.msg);
        // }
      });
    },

    // 账户选中
    accChange(v) {
      if (v.isCheck) {
        v.isCheck = false;
      } else {
        v.isCheck = true;
      }
      this.$forceUpdate();
      let list = [];
      let isCheckNumber = 0;
      for (let key of this.accList) {
        if (key.isCheck) {
          list.push(key);
          isCheckNumber++;
        }
      }
      if (isCheckNumber == this.accList.length) {
        this.accSelectAll = true;
      } else {
        this.accSelectAll = false;
      }
      sessionStorage.setItem("checkAccList", JSON.stringify(list));
      this.pointNumber = list;
      this.buyNumChange();
      this.buyActionPriceChange();
      this.sellNumChange();
      this.sellActionPriceChange();
      this.buyActionTriggerChange();
      this.sellActionTriggerChange();
      this.buyActionPurchaseChange();
      this.sellActionPurchaseChange();
    },
    //弹框账户选中
    accShowChange(v) {
      if (v.isCheck) {
        v.isCheck = false;
      } else {
        v.isCheck = true;
      }
      this.$forceUpdate();
      let list = [];
      let isCheckNumber = 0;
      for (let key of this.selectAllShowList) {
        if (key.isCheck) {
          list.push(key);
          isCheckNumber++;
        }
      }
      sessionStorage.setItem("selectAllShowList", JSON.stringify(list));
      if (isCheckNumber == this.selectAllShowList.length) {
        this.accSelectAllShow = true;
      } else {
        this.accSelectAllShow = false;
      }
    },

    // 划转弹窗
    // async transferStart(item) {
    //   this.contAcc = item;
    //   let payload = {
    //     token: sessionStorage.getItem("token"),
    //     exchangeName: item.exchangeName,
    //     teamProjectId: this.project.teamProjectId,
    //     transactionType: "spot",
    //     investCoin: this.pair.baseCoin + "," + this.pair.counterCoin,
    //   };
    //   var res = await queryOperatorUserList(payload);
    //   let list = res.data.operatorUserList;
    //   for (let key of list) {
    //     if (key.apiId == item.apiId && key.coinType == this.pair.baseCoin) {
    //       this.spotAcc = key;
    //       this.rotatableNumber =
    //         Math.floor(Number(this.spotAcc.available) * 10000) / 10000;
    //     }
    //   }
    //   //  rotatableNumber
    //   this.transferShow = true;
    // },

    // 划转切换
    // transSortChange() {
    //   let a = JSON.parse(JSON.stringify(this.accOne));
    //   this.accOne = JSON.parse(JSON.stringify(this.accTwo));
    //   this.accTwo = JSON.parse(JSON.stringify(a));
    //   if (this.accOne == "币币账户") {
    //     this.rotatableNumber =
    //       Math.floor(Number(this.spotAcc.available) * 10000) / 10000;
    //   } else {
    //     this.rotatableNumber =
    //       Math.floor(Number(this.contAcc.available) * 10000) / 10000;
    //   }
    // },

    // 取消划转
    // transferCancel() {
    //   this.spotAcc = null;
    //   this.contAcc = null;
    //   this.transferNum = null;
    //   this.rotatableNumber = null;
    //   this.transferShow = false;
    //   this.accOne = "币币账户";
    //   this.accTwo =
    //     this.pair.counterCoin + "-" + this.pair.baseCoin + " 永续合约";
    // },

    // 确定划转
    // transferConfirm() {
    //   if (!this.transferNum) {
    //     this.$message.error(this.$t('m.户')"划转数不能为空");
    //     return;
    //   } else if (Number(this.transferNum) <= 0) {
    //     this.$message.error(this.$t('m.户')"划转数必须大于0");
    //     return;
    //   } else if (Number(this.transferNum) > this.rotatableNumber) {
    //     this.$message.error(this.$t('m.户')"划转数不能大于可划转数");
    //     return;
    //   }

    //   let payload = {
    //     token: sessionStorage.getItem("token"),
    //     apiId: this.spotAcc.apiId,
    //     paramStr: {
    //       amount: this.transferNum,
    //       currency: this.pair.baseCoin,
    //       from: this.accOne == "币币账户" ? "spot" : "linear-swap",
    //       to: this.accOne == "币币账户" ? "linear-swap" : "spot",
    //     },
    //   };

    //   if (this.exchangeName == "Huobi") {
    //     let val = {
    //       "margin-account": this.pair.counterCoin + "-" + this.pair.baseCoin,
    //     };
    //     payload.paramStr = { ...payload.paramStr, ...val };
    //   } else if (this.exchangeName == "OKexV5") {
    //     payload.paramStr.currency = this.pair.counterCoin;
    //     let val = { "margin-account": this.pair.counterCoin };
    //     payload.paramStr = { ...payload.paramStr, ...val };
    //   } else if (this.exchangeName == "Binance") {
    //     let val = { "margin-account": "USDT" };
    //     payload.paramStr = { ...payload.paramStr, ...val };
    //   }
    //   // else if(this.)

    //   transfer(payload).then((res) => {
    //     if (res.status == 200) {
    //       this.$message.success(this.$t('m.账户')"划转成功");
    //       this.accOne = "币币账户";
    //       this.accTwo =
    //         this.pair.counterCoin + "-" + this.pair.baseCoin + " 永续合约";
    //       this.queryOperatorUserList();
    //       this.transferCancel();
    //     } else {
    //       this.$message.error(res.msg);
    //     }
    //   });
    // },
    transferBtn(e) {
      this.transfer = true;
      this.transformation = true;
      this.spotByApp = e;
      this.currencyValue = "USDT";
      if (e.exchangeName == "OkexV5") {
        this.topValue = "统一账户";
        this.topOptions = [{ name: "统一账户" }];
        this.twoValue = "资金账户";
        this.twoOptions = [{ name: "资金账户" }];
        this.currencyList = this.tradePair;
        this.transformation = false;
        setTimeout(() => {
          let payload = {
            token: sessionStorage.getItem("token"),
            apiId: this.spotByApp.apiId,
            coin: this.currencyValue,
          };
          this.findFundSpot(payload);
        }, 300);
      } else {
        if (this.spotByApp.exchangeName == "Huobi") {
          let payload = {
            token: sessionStorage.getItem("token"),
            teamProjectId: this.project.teamProjectId,
            exchangeName: this.spotByApp.exchangeName,
          };
          this.findContractId(payload);
        } else {
          this.topOptions = [{ name: "USDT 币币账户" }];
          this.topValue = "USDT 币币账户";
          this.twoOptions = [{ name: "USDT 合约全仓" }];
          this.twoValue = "USDT 合约全仓";
        }
        this.currencyList = ["USDT"];
        setTimeout(() => {
          let payload = {
            token: sessionStorage.getItem("token"),
            apiId: this.spotByApp.apiId,
            coin: "USDT",
          };
          this.findFundSpot(payload);
        }, 300);
      }
    },
    //获取合约可划转账户
    findFundSpot(payload) {
      findFundSpot(payload).then((res) => {
        let data = res.data;
        let available = data.available;
        if (Number(available) == 0) {
          available = 0;
        } else {
          available = available.slice(0, available.indexOf(".") + 5);
        }
        this.usdtAvailable = available;
      });
    },
    //获取可用币对合约
    findContractId(payload) {
      findContractId(payload).then((res) => {
        let data = res.data;
        let contractIdList = [];
        data.forEach((e) => {
          contractIdList.push({ name: "USDT 永续合约 " + e.contractId });
        });
        let value1 = this.twoValue;
        let value2 = this.topValue;
        if (this.transformation) {
          this.twoOptions = contractIdList;
          contractIdList.forEach((e) => {
            if (e.name.indexOf(this.pair.counterCoin) > 0) {
              this.twoValue = e.name;
            }
          });
          this.topOptions = [{ name: "USDT 币币账户" }];
          this.topValue = "USDT 币币账户";
        } else {
          this.topOptions = contractIdList;
          contractIdList.forEach((e) => {
            if (e.name == value1) {
              this.topValue = e.name;
            }
          });
          // this.spotByApp.show = false;
          this.twoOptions = [{ name: "USDT 币币账户" }];
          this.twoValue = "USDT 币币账户";
        }
      });
    },
    twoValueChange() {},
    topValueChange(e) {
      if (!this.transformation) {
        let payload = {
          token: sessionStorage.getItem("token"),
          apiId: this.spotByApp.apiId,
          exchangeName: this.spotByApp.exchangeName,
          contractId: e.split(" ")[2],
        };
        this.findContract(payload);
      }
    },
    //获取币币同步全部资产
    findContract(payload) {
      findContract(payload).then((res) => {
        if (res.status == 200) {
          if (res.data) {
            let data = res.data;
            if (Number(data.available) == 0) {
              data.available = 0;
            } else {
              data.available = Number(data.available)
                .toString()
                .slice(0, Number(data.available).toString().indexOf(".") + 5);
            }
            this.usdtAvailable = data.available;
            this.contractData = data;
          } else {
            this.usdtAvailable = 0;
          }
        }
      });
    },
    handleClose(done) {
      this.scopedSlider = 0;
      this.actionBtnIndex = null;
      this.scopedNumModel = null;
      sessionStorage.removeItem("selectAllShowList");
      done();
    },
    //转换按钮
    transformationBtn() {
      this.transformation = !this.transformation;
      if (this.spotByApp.exchangeName == "OkexV5") {
        if (this.transformation == false) {
          this.topValue = "统一账户";
          this.topOptions = [{ name: "统一账户" }];
          this.twoValue = "资金账户";
          this.twoOptions = [{ name: "资金账户" }];
          this.currencyList = this.tradePair;
          setTimeout(() => {
            let payload = {
              token: sessionStorage.getItem("token"),
              apiId: this.spotByApp.apiId,
              coin: this.currencyValue,
            };
            this.findFundSpot(payload);
          }, 300);
        } else {
          this.twoValue = "统一账户";
          this.twoOptions = [{ name: "统一账户" }];
          this.topValue = "资金账户";
          this.topOptions = [{ name: "资金账户" }];
          let payload = {
            token: sessionStorage.getItem("token"),
            apiId: this.spotByApp.apiId,
            coin: this.currencyValue,
          };
          this.findFundAssets(payload);
        }
      } else {
        if (this.spotByApp.exchangeName == "Huobi") {
          let payload = {
            token: sessionStorage.getItem("token"),
            teamProjectId: this.project.teamProjectId,
            exchangeName: this.spotByApp.exchangeName,
          };
          this.findContractId(payload);
        } else {
          if (this.transformation) {
            this.twoOptions = [{ name: "USDT 合约全仓" }];
            this.twoValue = "USDT 合约全仓";
            this.topOptions = [{ name: "USDT 币币账户" }];
            this.topValue = "USDT 币币账户";
          } else {
            this.topOptions = [{ name: "USDT 合约全仓" }];
            this.topValue = "USDT 合约全仓";
            this.twoOptions = [{ name: "USDT 币币账户" }];
            this.twoValue = "USDT 币币账户";
          }
        }
        setTimeout(() => {
          if (!this.transformation) {
            let payload = {
              token: sessionStorage.getItem("token"),
              apiId: this.spotByApp.apiId,
              exchangeName: this.spotByApp.exchangeName,
            };
            if (this.spotByApp.exchangeName == "Huobi") {
              payload.contractId = this.topValue.split(" ")[2];
            } else {
              payload.contractId = this.topValue.split(" ")[0];
            }
            this.findContract(payload);
          } else {
            let payload = {
              token: sessionStorage.getItem("token"),
              apiId: this.spotByApp.apiId,
              coin: "USDT",
            };
            this.findFundSpot(payload);
          }
        }, 300);
      }
    },
    //获取项目可用币种
    getProject() {
      let payload = {
        teamProjectId: this.project.teamProjectId,
        token: sessionStorage.getItem("token"),
      };
      getProject(payload).then((res) => {
        let tradePair = JSON.parse(res.data.project.tradePair);
        this.tradePair = tradePair;
      });
    },

    findFundAssets(payload) {
      findFundAssets(payload).then((res) => {
        if (res.status == 200) {
          this.usdtAvailable = res.data.available;
        }
      });
    },

    //划转接口
    transferDetermine() {
      if (Number(this.inputOptions) <= 0) {
        return this.$message.warning("划转不能小于0");
      } else if (Number(this.inputOptions) > this.usdtAvailable) {
        return this.$message.warning("划转不能大于可用划转金额");
      }
      let payload = {};
      if (this.transformation) {
        payload = {
          token: sessionStorage.getItem("token"),
          paramStr: {
            from: "spot",
            to: "linear-swap",
            "margin-account":
              this.spotByApp.exchangeName == "Huobi"
                ? this.twoValue.split(" ")[2]
                : this.twoValue.split(" ")[0],
          },
        };
      } else {
        payload = {
          token: sessionStorage.getItem("token"),
          paramStr: {
            from: "linear-swap",
            to: "spot",
            "margin-account":
              this.spotByApp.exchangeName == "Huobi"
                ? this.topValue.split(" ")[2]
                : this.topValue.split(" ")[0],
          },
        };
      }
      if (this.spotByApp.exchangeName == "OkexV5") {
        payload.paramStr = {
          ...payload.paramStr,
          "margin-account": this.currencyValue,
        };
      }
      payload.paramStr.currency = this.currencyValue;
      payload.paramStr.amount = this.inputOptions;
      payload.apiId = this.spotByApp.apiId;
      transfer(payload).then((res) => {
        if (res.status == 200) {
          this.transfer = false;
          this.inputOptions = "";
          this.pageNo = 1;
          this.batchShow = true;
          this.$message.success(this.$t('m.操作成功'));
          this.$emit("batchSynchSpotApi", true);
          this.operatorUserShow = false;
          this.queryOperatorUserList();
          setTimeout(() => {
            this.batchShow = false;
          }, 3000);
        } else {
          this.$message.error(res.msg);
        }
      });
    },

    //输入框校验
    inputNumber(e) {
      if (Number(this.inputOptions) <= 0) {
        this.$message.error(this.$t('m.划转不能小于0'));
        e.target.style.border = "1px solid red";
      } else if (Number(this.inputOptions) > this.usdtAvailable) {
        this.$message.error(this.$t('m.划转不能大于可用划转金额'));
        e.target.style.border = "1px solid red";
      } else {
        e.target.style.border = "1px solid #DCDFE6";
      }
    },

    inputNumberFocus(e) {
      e.target.style.border = "1px solid #DCDFE6";
    },

    ////***********划转按钮结束 */

    // 动作切换
    orderTypeSelect(v) {
      this.orderType = v;
      sessionStorage.setItem("orderType", v);
      this.queryOrderActionDetails();
    },

    // 查询动作详情
    queryOrderActionDetails() {
      let payload = {
        name: this.scopeShow
          ? "plan-order"
          : this.orderType == 0
          ? "market-order"
          : this.orderType == 1
          ? "limit-order"
          : "plan-order",
        token: sessionStorage.getItem("token"),
      };
      queryOrderActionDetails(payload).then((res) => {
        if (res.status == 200) {
          this.orderActionDetails = res.data.orderActionDetails;
        }
      });
    },

    // 查询现货交易对
    queryPair(callback) {
      //123456
      let exchangeId = "";
      if (this.selectAllShow) {
        if (this.exchangeIdShow == 0) {
          this.exchangeList.forEach((e, index) => {
            if (e.exchangeId !== 0) {
              exchangeId = exchangeId + "," + e.exchangeId;
            }
          });
          exchangeId = exchangeId.replace(",", );
        }
      } else {
        if (this.exchangeId == 0) {
          this.exchangeList.forEach((e, index) => {
            if (e.exchangeId !== 0) {
              exchangeId = exchangeId + "," + e.exchangeId;
            }
          });
          exchangeId = exchangeId.replace(",", );
        }
      }

      let payload = {
        token: sessionStorage.getItem("token"),
        exchangeId: exchangeId ? exchangeId : this.exchangeId,
        teamProjectId: this.project.teamProjectId,
      };
      if (this.selectAllShow) {
        payload.exchangeId = exchangeId ? exchangeId : this.exchangeIdShow;
      }
      getPairPageById(payload).then((res) => {
        if (res.status == 200) {
          let projectPairList = res.data.coinList;
          if (!projectPairList) {
            projectPairList = ["USDT", "BTC"];
          }
          this.coinList = projectPairList;
          this.allPairList = res.data.pairList;
          let allPairList = res.data.pairList;

          let combinationPairList = [];
          let newPairList = [];

          for (var i = 0; i < projectPairList.length; i++) {
            for (var j = 0; j < projectPairList.length; j++) {
              let item = {};
              item.baseCoin = projectPairList[i];
              item.counterCoin = projectPairList[j];
              combinationPairList.push(item);
            }
          }

          for (var i = 0; i < allPairList.length; i++) {
            for (var j = 0; j < combinationPairList.length; j++) {
              if (
                allPairList[i].baseCoin == combinationPairList[j].baseCoin &&
                allPairList[i].counterCoin == combinationPairList[j].counterCoin
              ) {
                let lastAadIncrease = allPairList[i].lastAadIncrease
                  ? JSON.parse(allPairList[i].lastAadIncrease)
                  : null;
                allPairList[i].increase = lastAadIncrease
                  ? lastAadIncrease.increase
                  : 0;
                allPairList[i].last = lastAadIncrease
                  ? lastAadIncrease.last
                  : 0;
                newPairList.push(allPairList[i]);
              }
            }
          }
          // this.pairList = newPairList;

          if (this.transactionType == "spot") {
            for (let i = 0; i < newPairList.length; i++) {
              if (
                newPairList[i].baseCoin == "USDT" &&
                newPairList[i].counterCoin == "BTC"
              ) {
                this.pair = newPairList[i];
                let item = newPairList[i];
                newPairList.splice(i, 1);
                this.pairList = newPairList;
                this.pairList.unshift(item);
                break;
              } else {
                this.pair = newPairList[0];
              }
            }
          }
          let pairCoinList = [];
          if (this.pairLis) {
            this.pairList.forEach((e) => {
              if (e.baseCoin == this.currencyCoinList[0]) {
                pairCoinList.push(e);
              }
            });
          } else {
            this.newPairListNow = newPairList;
            this.newPairListNow.forEach((e) => {
              if (e.baseCoin == this.currencyCoinList[0]) {
                pairCoinList.push(e);
              }
            });
          }
          if (this.selectAllShow) {
            this.pairCoinListShow = pairCoinList;
            this.querySpotContractData();

            callback && callback();
          } else {
            this.pairCoinList = pairCoinList;
            let pair =
              sessionStorage.getItem("pair") !== "undefined"
                ? JSON.parse(sessionStorage.getItem("pair"))
                : null;
            if (!pair) {
              sessionStorage.setItem("pair", JSON.stringify(this.pair));
            } else {
              this.pair = pair;
            }
            this.querySpotContractData();

            this.getSpotApiList();

            callback && callback();
          }
        }
      });
    },

    // 查询合约交易对
    queryOrderChainContractPair(callback) {
      let exchangeId = "";
      if (this.selectAllShow) {
        if (this.exchangeIdShow == 0) {
          this.exchangeList.forEach((e, index) => {
            if (e.exchangeId !== 0) {
              exchangeId = exchangeId + "," + e.exchangeId;
            }
          });
          exchangeId = exchangeId.replace(",", );
        }
      } else {
        if (this.exchangeId == 0) {
          this.exchangeList.forEach((e, index) => {
            if (e.exchangeId !== 0) {
              exchangeId = exchangeId + "," + e.exchangeId;
            }
          });
          exchangeId = exchangeId.replace(",", );
        }
      }
      let payload = {
        teamProjectId: this.project.teamProjectId,
        exchangeId: exchangeId ? exchangeId : this.exchangeId,
        token: sessionStorage.getItem("token"),
      };
      if (this.selectAllShow) {
        payload.exchangeId = exchangeId ? exchangeId : this.exchangeIdShow;
      }
      queryOrderChainContractPair(payload).then((res) => {
        if (res.status == 200) {
          let projectPairList = res.data.coinList;
          let allPairList = res.data.resultMap;

          let combinationPairList = [];
          let newPairList = [];
          for (var i = 0; i < projectPairList.length; i++) {
            for (var j = 0; j < projectPairList.length; j++) {
              let item = {};
              item.baseCoin = projectPairList[i];
              item.counterCoin = projectPairList[j];
              combinationPairList.push(item);
            }
          }

          for (var i = 0; i < allPairList.length; i++) {
            for (var j = 0; j < combinationPairList.length; j++) {
              if (
                allPairList[i].baseCoin == combinationPairList[j].baseCoin &&
                allPairList[i].counterCoin == combinationPairList[j].counterCoin
              ) {
                allPairList[i].increase = allPairList[i].increase;
                allPairList[i].last = allPairList[i].last;
                newPairList.push(allPairList[i]);
              }
            }
          }
          if (this.selectAllShow) {
            this.pairListsShow = newPairList;
            this.pairCoinListShow = newPairList;
            this.pairShowList = JSON.parse(
              JSON.stringify(this.pairListsShow[0])
            );
            for (let key of this.pairListsShow) {
              if (key.baseCoin == "USDT" && key.counterCoin == "BTC") {
                this.pairShowList = JSON.parse(JSON.stringify(key));
              }
            }
          } else {
            this.pairList = newPairList;
            this.pairCoinList = newPairList;
            this.pair = this.pairList[0];
            for (let key of this.pairList) {
              if (key.baseCoin == "USDT" && key.counterCoin == "BTC") {
                this.pair = key;
              }
            }
          }
        }
        let pair =
          sessionStorage.getItem("pair") !== "undefined"
            ? JSON.parse(sessionStorage.getItem("pair"))
            : null;
        if (!pair) {
          sessionStorage.setItem("pair", JSON.stringify(this.pair));
        } else {
          this.pair = pair;
        }
        if (!this.pair) {
          this.accTwo = "--";
        } else {
          this.accTwo =
            this.pair.counterCoin + "-" + this.pair.baseCoin + " 永续合约";
        }

        this.querySpotContractData();

        callback && callback();
      });
    },

    // 查询合约张数/单价
    querySpotContractData() {
      let payload = {};
      if (!this.pair) {
        return;
      }
      if (this.selectAllShow) {
        payload = {
          token: sessionStorage.getItem("token"),
          baseCoin: this.pairShowList.baseCoin,
          counterCoin: this.pairShowList.counterCoin,
          type: this.transactionTypeShow,
        };
      } else {
        payload = {
          token: sessionStorage.getItem("token"),
          baseCoin: this.pair.baseCoin,
          counterCoin: this.pair.counterCoin,
          type: this.transactionType,
        };
      }
      querySpotContractData(payload).then((res) => {
        if (res.status == 200) {
          let dataList = res.data.dataList;
          this.dataList = dataList;
          this.operatorUserShow = false;
          if (this.selectAllShow) {
            return;
          }
          console.log(this.pair);
          if (
            this.pair.baseCoin == "USDT" &&
            this.pair.counterCoin == "BNT" &&
            this.pair.exchangeName == "Binance"
          ) {
            this.pairHandicap = [{ transactionTypeCopyShow: true }];
            this.socket.close();
            return;
          }
          let exchangeName = sessionStorage.getItem("exchangeName");
          if (exchangeName !== this.$t('m.全部')) {
            this.socketClick();
            return;
          }
          if (this.pair.exchangeName == "Binance") {
            this.socketClick();
            return;
          } else {
            this.exchangeList.forEach((e) => {
              if (
                e.exchangeName == "Binance" &&
                this.pair.counterCoin !== "BNT"
              ) {
                this.pair.exchangeName = "Binance";
                sessionStorage.setItem("pair", JSON.stringify(this.pair));
              } else if (
                e.exchangeName == "OkexV5" &&
                this.pair.exchangeName !== "Binance"
              ) {
                this.pair.exchangeName = "OkexV5";
                sessionStorage.setItem("pair", JSON.stringify(this.pair));
              } else if (
                e.exchangeName == "Huobi" &&
                this.pair.exchangeName !== "OkexV5" &&
                this.pair.exchangeName !== "Binance"
              ) {
                this.pair.exchangeName = "Huobi";
                sessionStorage.setItem("pair", JSON.stringify(this.pair));
              }
            });

            this.socketClick();
          }
        } else {
          this.$message.error(res.msg);
        }
      });
    },

    // // 查询合约杠杆
    // findByIdLeverMultiple() {
    //   let payload = {
    //     exchangeId: this.exchangeId,
    //     token: sessionStorage.getItem("token"),
    //   };
    //   findByIdLeverMultiple(payload).then((res) => {
    //     if (res.status == 200) {
    //       let list = res.data.exchangeMap.leverMultiple;
    //       this.lever = 3;
    //       this.leverList = JSON.parse(list);
    //     }
    //   });
    // },

    // 切换杠杆
    leverChange(v) {
      sessionStorage.setItem("lever", v);
      let dataList = this.dataList;
      for (let i = 0; i < dataList.length; i++) {
        for (let key of this.accList) {
          if (
            key.exchangeName.toLocaleLowerCase() ==
            dataList[i].exchangeName.toLocaleLowerCase()
          ) {
            key.openSize = Math.floor(
              (Number(key.available) * this.lever) / Number(dataList[i].price)
            );
          }
        }
      }
    },

    // 交易对选择
    pairSelect(idx, item) {
      this.positionList = null;
      this.currentEntrustList = null;
      this.posistionCheckAllSelect = false;
      this.pair = item;
      this.pairShow = !this.pairShow;

      this.querySpotContractData();
      if (this.activeName == "1") {
        this.positionList = [];
        if (this.transactionType == "spot") {
          this.getSpotApiList();
        } else {
          this.batchGetContractApiList();
        }
      } else if (this.activeName == "2") {
        this.currentEntrustList = [];
        this.currentEntrust();
      } else if (this.activeName == "3") {
        this.historyEntrust();
      }
      this.queryOperatorUserList();

      sessionStorage.setItem("pair", JSON.stringify(this.pair));
      // this.socketClick();
    },

    // 下单
    async placeOrder(type) {
      // if (this.orderLabel.trim() == "") {
      //   this.$message.error(this.$t('m.户')"标签不能为空");
      //   return;
      // }

      //   限价价格/投入校验
      if (this.orderType == 1 && type == "buy" && !this.buyActionPrice) {
        this.$message.error(this.$t('m.限价买入价格不能为空'));
        return;
      } else if (
        this.orderType == 1 &&
        type == "sell" &&
        !this.sellActionPrice
      ) {
        this.$message.error(this.$t('m.限价卖出价格不能为空'));
        return;
      } else if (type == "buy" && !this.buyNum) {
        this.$message.error(this.$t('m.买单投入不能为空'));
        return;
      } else if (type == "sell" && !this.sellNum) {
        this.$message.error(this.$t('m.卖单投入不能为空'));
        return;
      } else if (type == "buy" && this.buyNum <= 0) {
        this.$message.error(this.$t('m.买单投入不能小于等于0'));
        return;
      } else if (type == "sell" && this.sellNum <= 0) {
        this.$message.error(this.$t('m.卖单投入不能小于等于0'));
        return;
      } else if (
        type == "buy" &&
        this.buyInvestmentType == "比例投入" &&
        this.buyMin &&
        Number(this.buyMin) < 0
      ) {
        this.$message.error(this.$t('m.买单下限不能小于0'));
        return;
      } else if (
        type == "sell" &&
        this.sellInvestmentType == "比例投入" &&
        this.sellMin &&
        Number(this.sellMin) < 0
      ) {
        this.$message.error(this.$t('m.卖单下限不能小于0'));
        return;
      } else if (
        type == "buy" &&
        this.buyInvestmentType == "比例投入" &&
        this.buyMin &&
        this.buyMax &&
        Number(this.buyMin) > Number(this.buyMax)
      ) {
        this.$message.error(this.$t('m.买单资金下限不能大于资金上限'));
        return;
      } else if (
        type == "sell" &&
        this.sellInvestmentType == "比例投入" &&
        this.sellMin &&
        this.sellMax &&
        Number(this.sellMin) > Number(this.sellMax)
      ) {
        this.$message.error(this.$t('m.卖单资金下限不能大于资金上限'));
        return;
      } else if (
        type == "buy" &&
        this.buyInvestmentType == "比例投入" &&
        Number(this.buyNum) > 100
      ) {
        this.$message.error(this.$t('m.买单比例投入不能大于100%'));
        return;
      } else if (
        type == "sell" &&
        this.sellInvestmentType == "比例投入" &&
        Number(this.sellNum) > 100
      ) {
        this.$message.error(this.$t('m.卖单比例投入不能大于100%'));
        return;
      }

      ///////条件单校验
      if (this.orderType == 2 && !this.buyActionTriggerPrice && type == "buy") {
        this.$message.error(this.$t('m.买单触发价格不能为空'));
        return;
      } else if (
        this.orderType == 2 &&
        !this.buyActionTriggerPriceTwo &&
        type == "sell"
      ) {
        this.$message.error(this.$t('m.卖单触发价格不能为空'));
        return;
      } else if (
        this.orderType == 2 &&
        this.buyActionTriggerPrice <= 0 &&
        type == "buy"
      ) {
        this.$message.error(this.$t('m.买单触发价格不能小于0'));
        return;
      } else if (
        this.orderType == 2 &&
        this.buyActionTriggerPriceTwo <= 0 &&
        type == "sell"
      ) {
        this.$message.error(this.$t('m.卖单触发价格不能小于0'));
        return;
      } else if (
        this.orderType == 2 &&
        this.breachType == 1 &&
        !this.buyActionPurchasePrice &&
        type == "buy"
      ) {
        this.$message.error(this.$t('m.买单限价价格不能为空'));
        return;
      } else if (
        this.orderType == 2 &&
        this.breachTypeTwo == 1 &&
        !this.buyActionPurchasePriceTwo &&
        type == "sell"
      ) {
        this.$message.error(this.$t('m.卖单限价价格不能为空'));
        return;
      } else if (
        this.orderType == 2 &&
        this.breachType == 1 &&
        this.buyActionPurchasePrice <= 0 &&
        type == "buy"
      ) {
        this.$message.error(this.$t('m.买单限价价格不能小于0'));
        return;
      } else if (
        this.orderType == 2 &&
        this.breachTypeTwo == 1 &&
        this.buyActionPurchasePriceTwo <= 0 &&
        type == "sell"
      ) {
        this.$message.error(this.$t('m.卖单限价价格不能小于0'));
        return;
      }

      //************账户数据********************
      let selectAccList = [];
      let dataList = this.dataList;
      for (let key of this.accList) {
        if (key.isCheck) {
          selectAccList.push(key);
        }
      }

      //   账户选中校验
      if (selectAccList.length == 0) {
        this.$message.error(this.$t('m.请选择至少一个账户'));
        return;
      }

      //   固定投入校验

      // if (
      //   this.transactionType == "spot" &&
      //   type == "buy" &&
      //   this.buyInvestmentType == "固定投入"
      // ) {
      //   // 现货买单
      //   let list = [];
      //   for (let key of selectAccList) {
      //     if (Number(key.available) >= this.buyNum) {
      //       list.push(key);
      //     }
      //   }
      //   if (list.length == 0) {
      //     this.$message.error(this.$t('m.户')"没有符合条件的下单账户");
      //     return;
      //   } else {
      //     selectAccList = JSON.parse(JSON.stringify(list));
      //   }
      // } else if (
      //   this.transactionType == "spot" &&
      //   type == "sell" &&
      //   this.sellInvestmentType == "固定投入"
      // ) {
      //   // 现货卖单
      //   let list = [];
      //   for (let key of selectAccList) {
      //     if (Number(key.counterCoinNum) >= this.sellNum) {
      //       list.push(key);
      //     }
      //   }
      //   if (list.length == 0) {
      //     this.$message.error(this.$t('m.户')"没有符合条件的下单账户");
      //     return;
      //   } else {
      //     selectAccList = JSON.parse(JSON.stringify(list));
      //   }
      // } else if (
      //   this.transactionType != "spot" &&
      //   type == "buy" &&
      //   this.buyInvestmentType == "固定投入" &&
      //   this.contractPositionTType == "开仓"
      // ) {
      //   // 合约开仓买单
      //   let list = [];
      //   for (let key of selectAccList) {
      //     if (Number(key.openSize) >= this.buyNum) {
      //       list.push(key);
      //     }
      //   }
      //   if (list.length == 0) {
      //     this.$message.error(this.$t('m.户')"没有符合条件的下单账户");
      //     return;
      //   } else {
      //     selectAccList = JSON.parse(JSON.stringify(list));
      //   }
      // } else if (
      //   this.transactionType != "spot" &&
      //   type == "sell" &&
      //   this.sellInvestmentType == "固定投入" &&
      //   this.contractPositionTType == "开仓"
      // ) {
      //   // 合约开仓卖单
      //   let list = [];
      //   for (let key of selectAccList) {
      //     if (Number(key.openSize) >= this.sellNum) {
      //       list.push(key);
      //     }
      //   }
      //   if (list.length == 0) {
      //     this.$message.error(this.$t('m.户')"没有符合条件的下单账户");
      //     return;
      //   } else {
      //     selectAccList = JSON.parse(JSON.stringify(list));
      //   }
      // } else if (
      //   this.transactionType != "spot" &&
      //   type == "buy" &&
      //   this.buyInvestmentType == "固定投入" &&
      //   this.contractPositionTType == "平仓"
      // ) {
      //   // 合约开仓卖单
      //   let list = [];
      //   for (let key of selectAccList) {
      //     if (Number(key.sell) >= this.buyNum) {
      //       list.push(key);
      //     }
      //   }
      //   if (list.length == 0) {
      //     this.$message.error(this.$t('m.户')"没有符合条件的下单账户");
      //     return;
      //   } else {
      //     selectAccList = JSON.parse(JSON.stringify(list));
      //   }
      // } else if (
      //   this.transactionType != "spot" &&
      //   type == "sell" &&
      //   this.sellInvestmentType == "固定投入" &&
      //   this.contractPositionTType == "平仓"
      // ) {
      //   // 合约开仓卖单
      //   let list = [];
      //   for (let key of selectAccList) {
      //     if (Number(key.buy) >= this.sellNum) {
      //       list.push(key);
      //     }
      //   }
      //   if (list.length == 0) {
      //     this.$message.error(this.$t('m.户')"没有符合条件的下单账户");
      //     return;
      //   } else {
      //     selectAccList = JSON.parse(JSON.stringify(list));
      //   }
      // }

      // 买卖单比例投入过滤校验
      // if (
      //   this.transactionType == "spot" &&
      //   type == "buy" &&
      //   this.buyInvestmentType == "比例投入"
      // ) {
      //   // 现货买单
      //   if (this.buyMin) {
      //     let list = [];
      //     for (let i = 0; i < selectAccList.length; i++) {
      //       if (
      //         (Number(selectAccList[i].available) * this.buyNum) / 100 >=
      //         this.buyMin
      //       ) {
      //         list.push(selectAccList[i]);
      //       }
      //     }
      //     if (list.length == 0) {
      //       this.$message.error(this.$t('m.户')"没有符合条件的下单账户");
      //       return;
      //     } else {
      //       selectAccList = JSON.parse(JSON.stringify(list));
      //     }
      //   }
      //   if (this.buyMax) {
      //     let list = [];
      //     for (let i = 0; i < selectAccList.length; i++) {
      //       if (
      //         (Number(selectAccList[i].available) * this.buyNum) / 100 <=
      //         this.buyMax
      //       ) {
      //         list.push(selectAccList[i]);
      //       }
      //     }
      //     if (list.length == 0) {
      //       this.$message.error(this.$t('m.户')"没有符合条件的下单账户");
      //       return;
      //     } else {
      //       selectAccList = JSON.parse(JSON.stringify(list));
      //     }
      //   }
      //   for (let a = 0; a < selectAccList.length; a++) {
      //     if (Number(selectAccList[a].available) <= 0) {
      //       selectAccList.splice(a, 1);
      //     }
      //   }
      // } else if (
      //   this.transactionType == "spot" &&
      //   type == "sell" &&
      //   this.sellInvestmentType == "比例投入"
      //   // 现货卖单
      // ) {
      //   if (this.sellMin) {
      //     let list = [];
      //     for (let i = 0; i < selectAccList.length; i++) {
      //       if (
      //         (Number(selectAccList[i].counterCoinNum) * this.sellNum) / 100 >
      //         this.sellMin
      //       ) {
      //         list.push(selectAccList[i]);
      //       }
      //     }
      //     if (list.length == 0) {
      //       this.$message.error(this.$t('m.户')"没有符合条件的下单账户");
      //       return;
      //     } else {
      //       selectAccList = JSON.parse(JSON.stringify(list));
      //     }
      //   }
      //   if (this.sellMax) {
      //     let list = [];
      //     for (let i = 0; i < selectAccList.length; i++) {
      //       if (
      //         (Number(selectAccList[i].counterCoinNum) * this.sellNum) / 100 <
      //         this.sellMax
      //       ) {
      //         list.push(selectAccList[i]);
      //       }
      //     }
      //     if (list.length == 0) {
      //       this.$message.error(this.$t('m.户')"没有符合条件的下单账户");
      //       return;
      //     } else {
      //       selectAccList = JSON.parse(JSON.stringify(list));
      //     }
      //   }
      //   for (let a = 0; a < selectAccList.length; a++) {
      //     if (Number(selectAccList[a].counterCoinNum) <= 0) {
      //       selectAccList.splice(a, 1);
      //     }
      //   }
      // } else if (
      //   this.transactionType != "spot" &&
      //   type == "buy" &&
      //   this.buyInvestmentType == "比例投入" &&
      //   this.contractPositionTType == "开仓"
      // ) {
      //   // 开仓合约买单
      //   if (this.buyMin) {
      //     let list = [];
      //     for (let i = 0; i < selectAccList.length; i++) {
      //       for (let key of dataList) {
      //         if (
      //           key.exchangeName.toLocaleLowerCase() ==
      //           selectAccList[i].exchangeName.toLocaleLowerCase()
      //         ) {
      //           if (
      //             Math.floor(
      //               (Number(selectAccList[i].available) *
      //                 this.buyNum *
      //                 this.lever) /
      //                 100 /
      //                 Number(key.price)
      //             ) >= this.buyMin
      //           ) {
      //             list.push(selectAccList[i]);
      //           }
      //         }
      //       }
      //     }
      //     if (list.length == 0) {
      //       this.$message.error(this.$t('m.户')"没有符合条件的下单账户");
      //       return;
      //     } else {
      //       selectAccList = JSON.parse(JSON.stringify(list));
      //     }
      //   }
      //   if (this.buyMax) {
      //     let list = [];
      //     for (let i = 0; i < selectAccList.length; i++) {
      //       for (let key of dataList) {
      //         if (
      //           key.exchangeName.toLocaleLowerCase() ==
      //           selectAccList[i].exchangeName.toLocaleLowerCase()
      //         ) {
      //           if (
      //             Math.floor(
      //               (Number(selectAccList[i].available) *
      //                 this.buyNum *
      //                 this.lever) /
      //                 100 /
      //                 Number(key.price)
      //             ) <= this.buyMax
      //           ) {
      //             list.push(selectAccList[i]);
      //           }
      //         }
      //       }
      //     }
      //     if (list.length == 0) {
      //       this.$message.error(this.$t('m.户')"没有符合条件的下单账户");
      //       return;
      //     } else {
      //       selectAccList = JSON.parse(JSON.stringify(list));
      //     }
      //   }
      //   for (let a = 0; a < selectAccList.length; a++) {
      //     if (Number(selectAccList[a].available) <= 0) {
      //       selectAccList.splice(a, 1);
      //     }
      //   }
      // } else if (
      //   this.transactionType == "spot" &&
      //   type == "sell" &&
      //   this.sellInvestmentType == "比例投入" &&
      //   this.contractPositionTType == "开仓"
      //   // 开仓合约卖单
      // ) {
      //   if (this.sellMin) {
      //     let list = [];
      //     for (let i = 0; i < selectAccList.length; i++) {
      //       for (let key of dataList) {
      //         if (
      //           key.exchangeName.toLocaleLowerCase() ==
      //           selectAccList[i].exchangeName.toLocaleLowerCase()
      //         ) {
      //           if (
      //             Math.floor(
      //               (Number(selectAccList[i].available) *
      //                 this.sellNum *
      //                 this.lever) /
      //                 100 /
      //                 Number(key.price)
      //             ) /
      //               100 >
      //             this.sellMin
      //           ) {
      //             list.push(selectAccList[i]);
      //           }
      //         }
      //       }
      //     }
      //     if (list.length == 0) {
      //       this.$message.error(this.$t('m.户')"没有符合条件的下单账户");
      //       return;
      //     } else {
      //       selectAccList = JSON.parse(JSON.stringify(list));
      //     }
      //   }
      //   if (this.sellMax) {
      //     let list = [];
      //     for (let i = 0; i < selectAccList.length; i++) {
      //       for (let key of dataList) {
      //         if (
      //           key.exchangeName.toLocaleLowerCase() ==
      //           selectAccList[i].exchangeName.toLocaleLowerCase()
      //         ) {
      //           if (
      //             Math.floor(
      //               (Number(selectAccList[i].available) *
      //                 this.sellNum *
      //                 this.lever) /
      //                 100 /
      //                 Number(key.price)
      //             ) < this.sellMax
      //           ) {
      //             list.push(selectAccList[i]);
      //           }
      //         }
      //       }
      //     }
      //     if (list.length == 0) {
      //       this.$message.error(this.$t('m.户')"没有符合条件的下单账户");
      //       return;
      //     } else {
      //       selectAccList = JSON.parse(JSON.stringify(list));
      //     }
      //   }
      //   for (let a = 0; a < selectAccList.length; a++) {
      //     if (Number(selectAccList[a].available) <= 0) {
      //       selectAccList.splice(a, 1);
      //     }
      //   }
      // } else if (
      //   this.transactionType != "spot" &&
      //   type == "buy" &&
      //   this.buyInvestmentType == "比例投入" &&
      //   this.contractPositionTType == "平仓"
      // ) {
      //   // 平仓合约买单
      //   if (this.buyMin) {
      //     let list = [];
      //     for (let i = 0; i < selectAccList.length; i++) {
      //       if (
      //         Math.floor((Number(selectAccList[i].sell) * this.buyNum) / 100) >=
      //         this.buyMin
      //       ) {
      //         list.push(selectAccList[i]);
      //       }
      //     }
      //     if (list.length == 0) {
      //       this.$message.error(this.$t('m.户')"没有符合条件的下单账户");
      //       return;
      //     } else {
      //       selectAccList = JSON.parse(JSON.stringify(list));
      //     }
      //   }
      //   if (this.buyMax) {
      //     let list = [];
      //     for (let i = 0; i < selectAccList.length; i++) {
      //       if (
      //         Math.floor((Number(selectAccList[i].sell) * this.buyNum) / 100) <=
      //         this.buyMax
      //       ) {
      //         list.push(selectAccList[i]);
      //       }
      //     }
      //     if (list.length == 0) {
      //       this.$message.error(this.$t('m.户')"没有符合条件的下单账户");
      //       return;
      //     } else {
      //       selectAccList = JSON.parse(JSON.stringify(list));
      //     }
      //   }
      //   for (let a = 0; a < selectAccList.length; a++) {
      //     if (Number(selectAccList[a].sell) <= 0 || !selectAccList[a].sell) {
      //       selectAccList.splice(a, 1);
      //     }
      //   }
      // } else if (
      //   this.transactionType == "spot" &&
      //   type == "sell" &&
      //   this.sellInvestmentType == "比例投入" &&
      //   this.contractPositionTType == "平仓"
      //   // 平仓合约卖单
      // ) {
      //   if (this.sellMin) {
      //     let list = [];
      //     for (let i = 0; i < selectAccList.length; i++) {
      //       if (
      //         Math.floor((Number(selectAccList[i].buy) * this.sellNum) / 100) /
      //           100 >
      //         this.sellMin
      //       ) {
      //         list.push(selectAccList[i]);
      //       }
      //     }
      //     if (list.length == 0) {
      //       this.$message.error(this.$t('m.户')"没有符合条件的下单账户");
      //       return;
      //     } else {
      //       selectAccList = JSON.parse(JSON.stringify(list));
      //     }
      //   }
      //   if (this.sellMax) {
      //     let list = [];
      //     for (let i = 0; i < selectAccList.length; i++) {
      //       if (
      //         Math.floor((Number(selectAccList[i].buy) * this.sellNum) / 100) <
      //         this.sellMax
      //       ) {
      //         list.push(selectAccList[i]);
      //       }
      //     }
      //     if (list.length == 0) {
      //       this.$message.error(this.$t('m.户')"没有符合条件的下单账户");
      //       return;
      //     } else {
      //       selectAccList = JSON.parse(JSON.stringify(list));
      //     }
      //   }
      //   for (let a = 0; a < selectAccList.length; a++) {
      //     if (Number(selectAccList[a].buy) <= 0 || !selectAccList[a].buy) {
      //       selectAccList.splice(a, 1);
      //     }
      //   }
      // }

      let payload = {
        token: sessionStorage.getItem("token"),
        exchangeId: this.exchangeId == 0 ? "" : this.exchangeId,
        exchangeName: this.exchangeName == this.$t('m.全部') ? "" : this.exchangeName,
        teamProjectId: this.project.teamProjectId,
        transactionType: this.transactionType,
        label: this.orderLabel,
      };

      let userStr = [];
      for (let key of selectAccList) {
        let accItem = {};
        accItem.publicPeopleId = key.id;
        accItem.userId = key.userId;
        accItem.publicPeopleName = key.publicCode;
        accItem.apiId = key.apiId;
        accItem.exchangeName = key.exchangeName;
        accItem.remarkCode = key.remarkCode;

        if (this.transactionType != "spot") {
          // 合约

          if (
            type == "buy" &&
            this.buyInvestmentType == "比例投入" &&
            this.contractPositionTType == "开仓"
          ) {
            for (let item of dataList) {
              if (
                item.exchangeName.toLocaleLowerCase() ==
                key.exchangeName.toLocaleLowerCase()
              ) {
                let val =
                  (Math.floor(
                    Number(this.buyNum) * Number(key.available) * 10000
                  ) /
                    1000000) *
                  this.lever;
                accItem.proportionalInvestNum = Math.floor(
                  val / Number(item.price)
                );
              }
            }
          } else if (
            type == "sell" &&
            this.sellInvestmentType == "比例投入" &&
            this.contractPositionTType == "开仓"
          ) {
            for (let item of dataList) {
              if (
                item.exchangeName.toLocaleLowerCase() ==
                key.exchangeName.toLocaleLowerCase()
              ) {
                let val =
                  (Math.floor(
                    Number(this.sellNum) * Number(key.available) * 10000
                  ) /
                    1000000) *
                  this.lever;
                accItem.proportionalInvestNum = Math.floor(
                  val / Number(item.price)
                );
              }
            }
          } else if (
            type == "buy" &&
            this.buyInvestmentType == "比例投入" &&
            this.contractPositionTType == "平仓"
          ) {
            // accItem.proportionalInvestNum =Math.floor(Number(key.sell) * Number(this.buyNum) * 10000) /1000000;
            let val =
              Math.floor(Number(key.sell) * Number(this.buyNum) * 10000) /
              1000000;
            accItem.proportionalInvestNum = val >= 1 ? val : 0;
          } else if (
            type == "sell" &&
            this.sellInvestmentType == "比例投入" &&
            this.contractPositionTType == "平仓"
          ) {
            // accItem.proportionalInvestNum =Math.floor(Number(key.buy) * Number(this.sellNum) * 10000) /1000000;
            let val =
              Math.floor(Number(key.buy) * Number(this.sellNum) * 10000) /
              1000000;
            accItem.proportionalInvestNum = val >= 1 ? val : 0;
          }
        } else {
          // 现货
          if (type == "buy" && this.buyInvestmentType == "比例投入") {
            accItem.proportionalInvestNum =
              Math.floor(Number(this.buyNum) * Number(key.available) * 10000) /
              1000000;
          } else if (type == "sell" && this.sellInvestmentType == "比例投入") {
            accItem.proportionalInvestNum =
              Math.floor(
                Number(this.sellNum) * Number(key.counterCoinNum) * 10000
              ) / 1000000;
          }
        }
        userStr.push(accItem);
      }

      // 合约开仓比例投入张数二次校验
      if (
        this.transactionType != "spot" &&
        ((type == "buy" && this.buyInvestmentType == "比例投入") ||
          (type == "sell" && this.sellInvestmentType == "比例投入"))
      ) {
        for (let i = 0; i < userStr.length; i++) {
          if (userStr[i].proportionalInvestNum < 1) {
            userStr.splice(i, 1);
          }
        }
      }
      if (userStr.length == 0) {
        this.$message.error(this.$t('m.没有符合条件的下单账户'));
        return;
      }
      this.oldAccList = userStr; //备份下单列表

      payload.userStr = JSON.stringify(userStr);

      let orderChain = [];
      let order = {};
      let tradePair = {};
      order.orderType = "3";
      tradePair.type = this.transactionType; //spot->现货, contract_usdt->U本位永续合约
      tradePair.exchangeName =
        this.exchangeName == this.$t('m.全部') ? "" : this.exchangeName;
      tradePair.investCoin =
        type == "buy" ? this.pair.baseCoin : this.pair.counterCoin; //投入币种

      let displayConfig = {
        getCurrency: this.pair.counterCoin,
        inputCurrency: this.pair.baseCoin,
        investmentType: "固定投入",
        leverRate: this.lever,
        name: this.$t('m.订单') + " 1",
        pair: this.pair,
        pairList: this.pairList,
        sort: 1,
      };

      if (this.transactionType == "spot") {
        order.transactionPair =
          this.pair.counterCoin + "/" + this.pair.baseCoin;
        order.pairId = this.pair.id;
        order.orderSort = 1;
        order.orderDirection = type;
        order.investUnit =
          type == "buy" ? this.pair.baseCoin : this.pair.counterCoin;

        if (type == "buy") {
          order.investType = this.buyInvestmentType == "固定投入" ? 1 : 2;
          order.investNum = this.buyNum;
          displayConfig.investment = this.buyNum;
          displayConfig.proportionMax = this.buyMax;
          displayConfig.proportionMin = this.buyMin;
          displayConfig.singleDirection = "买入";
          displayConfig.value =
            "买入(投" +
            (type == "buy" ? this.pair.baseCoin : this.pair.counterCoin) +
            ")" +
            this.pair.counterCoin +
            "/" +
            this.pair.baseCoin;
          tradePair.baseCoinAmount = this.buyNum;
          tradePair.counterCoinAmount = "0";
        } else {
          order.investType = this.sellInvestmentType == "固定投入" ? 1 : 2;
          order.investNum = this.sellNum;
          displayConfig.investment = this.sellNum;
          displayConfig.proportionMax = this.sellMax;
          displayConfig.proportionMin = this.sellMin;
          displayConfig.singleDirection = "卖出";
          displayConfig.value =
            "卖出(投" +
            (type == "buy" ? this.pair.baseCoin : this.pair.counterCoin) +
            ")" +
            this.pair.counterCoin +
            "/" +
            this.pair.baseCoin;
          tradePair.baseCoinAmount = "0";
          tradePair.counterCoinAmount = this.sellNum;
        }

        let payload = {
          token: sessionStorage.getItem("token"),
          exchangeId: this.exchangeId == 0 ? "" : this.exchangeId,
          pairId: this.pair.id,
        };

        if (this.exchangeName != this.$t('m.全部')) {
          let payload = {
            token: sessionStorage.getItem("token"),
            exchangeId: this.exchangeId == 0 ? "3" : this.exchangeId,
            pairId: this.pair.id,
          };

          var res = await queryFeeAndAccuracy(payload);
          let exchangeFee = res.data.exchangeFee;
          let pairAccuracy = res.data.pairAccuracy;

          tradePair.buyFee = exchangeFee.buyFee; // 买入手续费
          tradePair.soldFee = exchangeFee.soldFee; // 卖出手续费

          if (pairAccuracy) {
            tradePair.counterCoin = pairAccuracy.counterCoin; // 交易币种
            tradePair.counterCoinDecimal = pairAccuracy.counterCoinDecimal; // 交易币种精度
            tradePair.baseCoin = pairAccuracy.baseCoin; // 计价币种
            tradePair.baseCoinDecimal = pairAccuracy.baseCoinDecimal; // 计价币种精度
          }
        }
      } else if (this.transactionType != "spot") {
        order.investNum = type == "buy" ? this.buyNum : this.sellNum;
        order.investUnit = "USDT";
        order.leverRate = this.lever;
        order.orderSort = 1;
        order.pairId = this.pair.contractId;
        order.transactionPair = this.pair.contractId;
        payload.transactionPair = this.pair.contractId;

        if (type == "buy") {
          order.investType = this.buyInvestmentType == "固定投入" ? 1 : 2;
          displayConfig.investmentType =
            this.buyInvestmentType == "固定投入" ? 1 : 2;
          displayConfig.proportionMax = this.buyMax;
          displayConfig.proportionMin = this.buyMin;
        } else {
          order.investType = this.sellInvestmentType == "固定投入" ? 1 : 2;
          displayConfig.investmentType =
            this.sellInvestmentType == "固定投入" ? 1 : 2;
          displayConfig.proportionMax = this.sellMax;
          displayConfig.proportionMin = this.sellMin;
        }

        if (type == "buy" && this.contractPositionTType == "开仓") {
          order.orderDirection = "open_many";
          displayConfig.singleDirection = "开多";
          tradePair.tradeOffset = "1"; // 多空方向， 0-做空  1-做多
          tradePair.tradeType = "1"; //0 卖 ,1 买
        } else if (type == "sell" && this.contractPositionTType == "开仓") {
          order.orderDirection = "open_empty";
          displayConfig.singleDirection = "开空";
          tradePair.tradeOffset = "1";
          tradePair.tradeType = "0"; //0 卖 ,1 买
        } else if (type == "buy" && this.contractPositionTType == "平仓") {
          order.orderDirection = "flat_empty";
          displayConfig.singleDirection = "平空";
          tradePair.tradeOffset = "0";
          tradePair.tradeType = "1"; //0 卖 ,1 买
        } else if (type == "sell" && this.contractPositionTType == "平仓") {
          order.orderDirection = "flat_many";
          displayConfig.singleDirection = "平多";
          tradePair.tradeOffset = "0";
          tradePair.tradeType = "0"; //0 卖 ,1 买
        }

        // displayConfig
        displayConfig.value =
          "买入(投" +
          this.pair.baseCoin +
          ")" +
          this.pair.counterCoin +
          "/" +
          this.pair.baseCoin;
        displayConfig.baseCoin = this.pair.baseCoin;
        displayConfig.contractPair =
          this.pair.counterCoin + "-" + this.pair.baseCoin;
        displayConfig.counterCoin = this.pair.counterCoin;
        displayConfig.inputCurrency = this.pair.baseCoin;
        displayConfig.investment = type == "buy" ? this.buyNum : this.sellNum;

        tradePair.contractId = this.pair.contractId;
        tradePair.size = type == "buy" ? this.buyNum : this.sellNum;
        tradePair.leverRate = this.lever;
      }

      // displayConfig 动作
      let actionList = [];
      let action = this.orderActionDetails;
      action.percentage = 100;
      action.isCheck = true;
      if (this.orderType == 1) {
        let params = JSON.parse(action.params);
        if (type == "buy") {
          params.controls[0].value = this.buyActionPrice;
        } else {
          params.controls[0].value = this.sellActionPrice;
        }
        action.params = JSON.stringify(params);
      } else if (this.orderType == 2) {
        let params = JSON.parse(action.params);
        if (type == "buy") {
          params.controls[0].value = this.breach;
          params.controls[1].value = this.buyActionTriggerPrice;
          params.controls[2].value = this.breachType;
          if (params.controls[3].require) {
            params.controls[3].proposed = this.buyActionPurchasePrice;
          } else {
            params.controls[4].proposed = this.buyActionPurchasePrice;
          }
        } else {
          params.controls[0].value = this.breachTwo;
          params.controls[1].value = this.buyActionTriggerPriceTwo;
          params.controls[2].value = this.breachTypeTwo;
          if (params.controls[3].require) {
            params.controls[3].proposed = this.buyActionPurchasePriceTwo;
          } else {
            params.controls[4].proposed = this.buyActionPurchasePriceTwo;
          }
        }
        if (!params.controls[3].require) {
          params.controls.splice(3, 1);
        }

        action.params = JSON.stringify(params);
      }

      actionList.push(action);
      displayConfig.actionList = actionList;

      // executeConfig 动作
      let actions = [];
      if (this.orderType == 0) {
        let val = {
          name: "market-order",
          investRate: 100,
        };
        actions.push(val);
      } else if (this.orderType == 1) {
        let val = {
          investRate: 100,
          name: "limit-order",
          params: [
            {
              name: "price",
              value: type == "buy" ? this.buyActionPrice : this.sellActionPrice,
              valueType: "number",
            },
          ],
        };
        actions.push(val);
      } else {
        let val = {
          investRate: 100,
          name: "plan-order",
          params: [
            {
              name: "triggerType",
              valueType: "number",
              value: type == "buy" ? this.breach : this.breachTwo,
            },
            {
              name: "triggerPrice",
              valueType: "number",
              value:
                type == "buy"
                  ? this.buyActionTriggerPrice
                  : this.buyActionTriggerPriceTwo,
            },
            {
              name: "priceType",
              valueType: "number",
              value: type == "buy" ? this.breachType : this.breachTypeTwo,
            },
            {
              name: "price",
              valueType: "number",
              value:
                type == "buy"
                  ? this.buyActionPurchasePrice
                  : this.buyActionPurchasePriceTwo,
            },
          ],
        };
        actions.push(val);
      }
      let executeConfig = {
        actions: actions,
        tradePair: tradePair,
      };
      order.displayConfig = JSON.stringify(displayConfig);
      order.executeConfig = JSON.stringify(executeConfig);

      orderChain.push(order);

      payload.orderChain = orderChain;
      if (this.transactionType == "spot") {
        payload.investCoin = payload.orderChain[0].investUnit;
      }

      // if (this.exchangeName == this.$t('m.全部')) {
      let item = JSON.parse(JSON.stringify(payload));
      item.orderChain[0].displayConfig = JSON.parse(
        item.orderChain[0].displayConfig
      );
      item.orderChain[0].executeConfig = JSON.parse(
        item.orderChain[0].executeConfig
      );

      let newOrderList = [];
      for (let key of userStr) {
        for (let val of dataList) {
          if (
            key.exchangeName.toLocaleLowerCase() ==
            val.exchangeName.toLocaleLowerCase()
          ) {
            item.exchangeId = val.exchangeId;
            item.exchangeName = val.exchangeName;
            item.orderChain[0].executeConfig.tradePair.exchangeName =
              val.exchangeName;
            item.orderChain[0].displayConfig.pair = val;
            let userStr = [key];
            item.userStr = JSON.stringify(userStr);

            if (this.transactionType == "spot") {
              item.orderChain[0].pairId = val.pairId;

              let payload = {
                token: sessionStorage.getItem("token"),
                exchangeId: val.exchangeId,
                pairId: val.pairId,
              };

              var res = await queryFeeAndAccuracy(payload);
              let exchangeFee = res.data.exchangeFee;
              let pairAccuracy = res.data.pairAccuracy;
              if (pairAccuracy) {
                item.orderChain[0].executeConfig.tradePair.counterCoin =
                  pairAccuracy.counterCoin; // 交易币种
                item.orderChain[0].executeConfig.tradePair.counterCoinDecimal =
                  pairAccuracy.counterCoinDecimal; // 交易币种精度
                item.orderChain[0].executeConfig.tradePair.baseCoin =
                  pairAccuracy.baseCoin; // 计价币种
                item.orderChain[0].executeConfig.tradePair.baseCoinDecimal =
                  pairAccuracy.baseCoinDecimal; // 计价币种精度
              }

              item.orderChain[0].executeConfig.tradePair.buyFee =
                exchangeFee.buyFee; // 买入手续费
              item.orderChain[0].executeConfig.tradePair.soldFee =
                exchangeFee.soldFee; // 卖出手续费

              newOrderList.push(JSON.parse(JSON.stringify(item)));
            } else {
              item.orderChain[0].pairId = val.contractId;
              item.orderChain[0].executeConfig.tradePair.contractId =
                val.contractId;
              item.transactionPair = val.contractId;
              item.orderChain[0].transactionPair = val.contractId;
              newOrderList.push(JSON.parse(JSON.stringify(item)));
            }
          }
        }
      }
      for (let v of newOrderList) {
        v.orderChain[0].displayConfig = JSON.stringify(
          v.orderChain[0].displayConfig
        );
        v.orderChain[0].executeConfig = JSON.stringify(
          v.orderChain[0].executeConfig
        );
      }
      let params = {
        token: sessionStorage.getItem("token"),
        data: JSON.stringify(newOrderList),
      };
      batchQuickTransaction(params).then((res) => {
        if (res.status == 200) {
          // if (res.data.errorMap) {
          //   let errorMap = res.data.errorMap;
          //   for (let i in errorMap) {
          //     return this.$message.error(errorMap[i]);
          //   }
          // }
          this.$message.success(this.$t('m.下单成功'));
          this.operatorUserShow = false;
          this.queryOperatorUserList();

          //获取标签
          this.queryOrderLabel();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    async placeOrderScoped() {
      if (this.scopedPriceShow) {
        this.$message.error(this.$t('m.触发价格输入错误'));
        return;
      }
      if (
        Math.ceil((this.scopedData.availableNum * this.scopedSlider) / 100) == 0
      ) {
        this.$message.error(this.$t('m.下单数量不能为空'));
        return;
      }
      if (!this.scopeTriggerPrice) {
        this.$message.error(this.$t('m.触发价格不能为空'));
        return;
      }
      this.accList.forEach((e) => {
        if (e.publicCode.indexOf(this.scopedData.apiName) > -1) {
          this.scopedData.userId = e.userId;
          this.scopedData.id = e.id;
          this.scopedData.publicCode = e.publicCode;
        }
      });
      this.scopedData.baseCoin = this.pair.baseCoin;
      this.scopedData.counterCoin = this.pair.counterCoin;
      let payload = {
        token: sessionStorage.getItem("token"),
        exchangeId:
          this.scopedData.exchangeName.toUpperCase() == "HUOBI"
            ? 3
            : this.scopedData.exchangeName.toUpperCase() == "OKEXV5"
            ? 29
            : 4,
        exchangeName: this.scopedData.exchangeName,
        teamProjectId: this.project.teamProjectId,
        transactionType: "contract_usdt",
        label: this.orderLabel,
      };

      let userStr = [];
      let accItem = {};
      accItem.publicPeopleId = this.scopedData.id;
      accItem.userId = this.scopedData.userId;
      accItem.publicPeopleName = this.scopedData.publicCode;
      accItem.apiId = this.scopedData.apiId;
      accItem.exchangeName = this.scopedData.exchangeName;
      accItem.remarkCode = this.scopedData.remarkCode;
      accItem.proportionalInvestNum = Math.ceil(
        (this.scopedData.availableNum * this.scopedSlider) / 100
      );
      userStr.push(accItem);
      this.oldAccList = userStr; //备份下单列表

      payload.userStr = JSON.stringify(userStr);

      let orderChain = [];
      let order = {};
      let tradePair = {};
      order.orderType = "3";
      tradePair.type = "contract_usdt"; //spot->现货, contract_usdt->U本位永续合约
      tradePair.exchangeName = this.scopedData.exchangeName;
      tradePair.investCoin = this.scopedData.baseCoin; //投入币种

      let displayConfig = {
        getCurrency: this.scopedData.counterCoin,
        inputCurrency: this.scopedData.baseCoin,
        investmentType: 1,
        leverRate: this.scopedData.leverRate,
        name: this.$t('m.订单') + " 1",
        pair: this.scopedData,
        pairList: this.pairList,
        sort: 1,
      };
      order.investNum = Math.ceil(
        (this.scopedData.availableNum * this.scopedSlider) / 100
      );
      order.investUnit = "USDT";
      order.leverRate = this.scopedData.leverRate;
      order.orderSort = 1;
      order.pairId = this.scopedData.contractId;
      order.transactionPair = this.scopedData.contractId;
      payload.transactionPair = this.scopedData.contractId;

      // displayConfig
      displayConfig.value =
        "买入(投" +
        this.scopedData.baseCoin +
        ")" +
        this.scopedData.counterCoin +
        "/" +
        this.scopedData.baseCoin;
      displayConfig.baseCoin = this.scopedData.baseCoin;
      displayConfig.contractPair =
        this.scopedData.counterCoin + "-" + this.scopedData.baseCoin;
      displayConfig.counterCoin = this.scopedData.counterCoin;
      displayConfig.inputCurrency = this.scopedData.baseCoin;
      displayConfig.investment = Math.ceil(
        (this.scopedData.availableNum * this.scopedSlider) / 100
      );

      tradePair.contractId = this.scopedData.contractId;
      tradePair.size = Math.ceil(
        (this.scopedData.availableNum * this.scopedSlider) / 100
      );
      if (this.scopedData.direction == "buy") {
        order.orderDirection = "flat_many";
        displayConfig.singleDirection = "平多";
        tradePair.tradeOffset = "0";
        tradePair.tradeType = "0"; //0 卖 ,1 买
      } else {
        order.orderDirection = "flat_empty";
        displayConfig.singleDirection = "平空";
        tradePair.tradeOffset = "0";
        tradePair.tradeType = "1";
      }

      tradePair.leverRate = this.scopedData.leverRate;

      // displayConfig 动作
      let actionList = [];
      let action = this.orderActionDetails;

      action.percentage = 100;
      action.isCheck = true;
      let params = JSON.parse(action.params);
      params.controls[0].value = this.scopeType == "surplus" ? 0 : 1;
      params.controls[1].value = this.scopeTriggerPrice;
      params.controls[2].value = this.breachScope;
      if (params.controls[3].require) {
        params.controls[3].proposed = this.scopedNumberPrice;
      } else {
        params.controls[4].proposed = this.scopedNumberPrice;
      }

      if (!params.controls[3].require) {
        params.controls.splice(3, 1);
      }

      action.params = JSON.stringify(params);

      actionList.push(action);
      displayConfig.actionList = actionList;

      // executeConfig 动作
      let actions = [];
      let val = {
        investRate: 100,
        name: "plan-order",
        params: [
          {
            name: "triggerType",
            valueType: "number",
            value:
              this.scopedData.direction == "sell"
                ? this.scopeType == "surplus"
                  ? 1
                  : 0
                : this.scopeType == "surplus"
                ? 0
                : 1,
          },
          {
            name: "triggerPrice",
            valueType: "number",
            value: this.scopeTriggerPrice,
          },
          {
            name: "priceType",
            valueType: "number",
            value: this.breachScope,
          },
          {
            name: "price",
            valueType: "number",
            value: this.scopedNumberPrice,
          },
          {
            name: "type",
            valueType: "number",
            value: this.scopeType == "surplus" ? 0 : 1,
          },
        ],
      };
      actions.push(val);

      let executeConfig = {
        actions: actions,
        tradePair: tradePair,
      };

      order.displayConfig = JSON.stringify(displayConfig);
      order.executeConfig = JSON.stringify(executeConfig);

      orderChain.push(order);
      payload.orderChain = orderChain;
      let data = [];
      data[0] = payload;
      let paramsData = {
        token: sessionStorage.getItem("token"),
        data: JSON.stringify(data),
      };
      batchQuickTransaction(paramsData).then((res) => {
        if (res.status == 200) {
          // if (res.data.errorMap) {
          //   let errorMap = res.data.errorMap;
          //   for (let i in errorMap) {
          //     return this.$message.error(errorMap[i]);
          //   }
          // }
          this.$message.success(this.$t('m.下单成功'));
          this.operatorUserShow = false;
          this.scopeShow = false;
          this.queryOperatorUserList();

          //获取标签
          this.queryOrderLabel();
        } else {
          this.$message.error(res.msg);
        }
      });
    },

    // tabs  切换
    handleClick(tab, event) {
      this.multipleSelection = [];
      this.positionList = [];
      this.currentEntrustList = [];
      this.btmSelectInp = null;
      this.posistionCheckAllSelect = false;
      this.posistionCheckAll = false;
      sessionStorage.removeItem("multipleSelection");
      sessionStorage.setItem("activeName", this.activeName);
      if (this.activeName == "2") {
        this.currentEntrust();
      } else if (this.activeName == "1") {
        if (this.transactionType == "spot") {
          this.getSpotApiList();
        } else {
          this.batchGetContractApiList();
        }
      } else if (this.activeName == "3") {
        this.historyEntrust();
      }
    },

    // table  选中
    handleSelectionChange(val) {
      this.multipleSelection = val;
      sessionStorage.setItem(
        "multipleSelection",
        JSON.stringify(this.multipleSelection)
      );
    },

    // qqq
    handleSelectItem(selected, item, index) {
      if (!this.multipleSelection) {
        this.multipleSelection = [];
      }
      if (this.activeName == "1") {
        this.positionList[index].checked = selected;
        this.positionList = JSON.parse(JSON.stringify(this.positionList));
      } else if (this.activeName == "2") {
        this.currentEntrustList[index].checked = selected;
        this.currentEntrustList = JSON.parse(
          JSON.stringify(this.currentEntrustList)
        );
      }
      if (selected) {
        this.multipleSelection.push(item);
      } else {
        var index = this.indexOfPostionItem(item);
        this.multipleSelection.splice(index, 1);
      }

      sessionStorage.setItem(
        "multipleSelection",
        JSON.stringify(this.multipleSelection)
      );

      this.isPositionSelectAll();

      this.$forceUpdate();
    },

    handleSelectAllItem(selected) {
      this.toggleSelectAll(selected);
      // if (this.posistionCheckAll != 2) {
      //   this.posistionCheckAll = 2;
      //   this.toggleSelectAll(true);
      // } else {
      //   this.posistionCheckAll = 0;
      //   this.toggleSelectAll(false);
      // }
      this.$forceUpdate();
    },

    toggleSelectAll(selectAll) {
      this.posistionCheckAll = false;
      if (this.activeName == "1") {
        this.positionList.forEach((e) => {
          e.checked = selectAll;
        });
        this.positionList = JSON.parse(JSON.stringify(this.positionList));

        if (selectAll) {
          this.multipleSelection = JSON.parse(
            JSON.stringify(this.positionList)
          );
        } else {
          this.multipleSelection = [];
        }
      } else if (this.activeName == "2") {
        this.currentEntrustList.forEach((e) => {
          e.checked = selectAll;
        });
        this.currentEntrustList = JSON.parse(
          JSON.stringify(this.currentEntrustList)
        );

        if (selectAll) {
          this.multipleSelection = JSON.parse(
            JSON.stringify(this.currentEntrustList)
          );
        } else {
          this.multipleSelection = [];
        }
      }

      sessionStorage.setItem(
        "multipleSelection",
        JSON.stringify(this.multipleSelection)
      );
    },

    isPositionSelectAll() {
      var selectCount = 0;
      if (this.activeName == "1") {
        for (let i = 0; i < this.positionList.length; i++) {
          if (this.positionList[i].checked) {
            selectCount++;
          }
        }
        if (selectCount == 0) {
          this.posistionCheckAll = false;
          this.posistionCheckAllSelect = false;
        } else if (selectCount == this.positionList.length) {
          this.posistionCheckAll = false;
          this.posistionCheckAllSelect = true;
        } else {
          this.posistionCheckAll = true;
          this.posistionCheckAllSelect = false;
        }
      } else if (this.activeName == "2") {
        for (let i = 0; i < this.currentEntrustList.length; i++) {
          if (this.currentEntrustList[i].checked) {
            selectCount++;
          }
        }
        if (selectCount == 0) {
          this.posistionCheckAll = false;
          this.posistionCheckAllSelect = false;
        } else if (selectCount == this.currentEntrustList.length) {
          this.posistionCheckAll = false;
          this.posistionCheckAllSelect = true;
        } else {
          this.posistionCheckAll = true;
          this.posistionCheckAllSelect = false;
        }
      }
    },

    indexOfPostionItem(item) {
      let index = -1;
      for (let i = 0; i < this.multipleSelection.length; i++) {
        if (
          this.transactionType !== "spot" &&
          this.multipleSelection[i].contractId == item.contractId &&
          this.multipleSelection[i].exchangeName == item.exchangeName &&
          this.multipleSelection[i].direction == item.direction &&
          this.multipleSelection[i].availableNum == item.availableNum &&
          this.multipleSelection[i].leverRate == item.leverRate &&
          this.multipleSelection[i].apiId == item.apiId &&
          this.multipleSelection[i].createTime == item.createTime &&
          this.multipleSelection[i].publicPeopleName == item.publicPeopleName
        ) {
          index = i;
        } else if (
          this.transactionType == "spot" &&
          this.multipleSelection[i].apiName == item.apiName &&
          this.multipleSelection[i].exchangeName == item.exchangeName &&
          this.multipleSelection[i].apiId == item.apiId &&
          this.multipleSelection[i].createTime == item.createTime &&
          this.multipleSelection[i].publicPeopleName == item.publicPeopleName
        ) {
          index = i;
        }
      }

      return index;
    },

    // 现货持仓
    getSpotApiList() {
      let coinType;
      if (this.pair) {
        coinType = this.pair.baseCoin + "," + this.pair.counterCoin;
      } else if (!this.pair) {
        return;
      }
      let payload = {
        pageNo: 1,
        pageSize: 9999,
        teamProjectId: this.project.teamProjectId,
        token: sessionStorage.getItem("token"),
        coinType: coinType,
        exchangeName: this.exchangeName == this.$t('m.全部') ? "" : this.exchangeName,
      };
      if (this.btmSelectInp) {
        payload.apiName = this.btmSelectInp;
      }

      getSpotApiList(payload).then((res) => {
        if (res.status == 200) {
          let list = res.data.spotApiList;
          let newList = [];
          // this.positionList = res.data.spotApiList;
          this.totle = res.data.totalNum;

          for (let key of list) {
            if (key.coinType != "USDT") {
              if (this.showOrderSmallCoin && key.coinValue < 10) {
              } else {
                newList.push(key);
              }
            }
          }

          let oldList = JSON.parse(sessionStorage.getItem("multipleSelection"));

          if (oldList && oldList.length > 0) {
            for (let i = 0; i < oldList.length; i++) {
              for (let j = 0; j < newList.length; j++) {
                if (
                  oldList[i].exchangeName == newList[j].exchangeName &&
                  oldList[i].coinType == newList[j].coinType &&
                  oldList[i].apiId == newList[j].apiId
                ) {
                  newList[i].checked = true;
                  // setTimeout(() => {
                  //   this.$refs.multipleTable2.toggleRowSelection(
                  //     this.positionList[j]
                  //   );
                  // }, 50);
                }
              }
            }
          } else {
            newList.forEach((e) => {
              e.checked = false;
            });
          }
          this.positionList = JSON.parse(JSON.stringify(newList));

          if (!this.poAccList) {
            let poAccList = [];
            for (let i = 0; i < list.length; i++) {
              for (let j = i + 1; j < list.length; j++) {
                if (list[i].apiName == list[j].apiName) {
                  poAccList.push(list[i]);
                }
              }
            }
            let all = {
              apiName: this.$t('m.全部'),
            };
            this.poAccList = poAccList;
            this.poAccList.unshift(all);
          }
          this.$forceUpdate();
          setTimeout(() => {
            if (this.operatorUserShow && this.currentEntrustShow) {
              // this.pollingMethod();
            }
          }, 1000);
        }
      });
    },

    // 合约持仓
    batchGetContractApiList() {
      if (!this.pair) {
        return;
      }
      let payload = {
        token: sessionStorage.getItem("token"),
        counterCoin: this.pair.counterCoin,
        exchangeName: this.exchangeName == this.$t('m.全部') ? "" : this.exchangeName,
        teamProjectId: this.project.teamProjectId,
      };
      if (this.btmSelectInp) {
        payload.apiName = this.btmSelectInp;
      }
      batchGetContractApiList(payload).then(async (res) => {
        if (res.status == 200) {
          let list = [];
          for (let key of res.data.contractList) {
            if (key.positions && JSON.parse(key.positions).length != 0) {
              list.push(key);
            }
          }

          let nlist = [];
          for (let key of list) {
            if (
              JSON.parse(key.positions) &&
              JSON.parse(key.positions).length != 0
            ) {
              let forList = JSON.parse(key.positions);
              for (let i = 0; i < forList.length; i++) {
                let contractSize;
                for (let item = 0; item < this.dataList.length; item++) {
                  if (
                    this.dataList[item].exchangeName.toLocaleUpperCase() ==
                    key.exchangeName.toLocaleUpperCase()
                  ) {
                    contractSize = this.dataList[item].contractSize;
                  }
                }
                if (
                  this.showOrderSmallCoin &&
                  Number(forList[i].volumeNum) *
                    Number(contractSize) *
                    Number(forList[i].costOpen) <
                    10
                ) {
                } else {
                  let item = {};
                  item.contractId = forList[i].contractId;
                  item.volumeNum = forList[i].volumeNum;
                  item.availableNum = forList[i].availableNum;
                  item.costOpen = forList[i].costOpen;
                  item.riskRate = forList[i].riskRate;
                  item.liquidationPrice = forList[i].liquidationPrice;
                  item.profit = forList[i].profit;
                  item.profitRate = forList[i].profitRate;
                  item.direction = forList[i].direction;
                  item.leverRate = forList[i].leverRate;
                  item.apiName = key.apiName;
                  item.exchangeName = key.exchangeName;
                  item.investCoin = key.investCoin;
                  item.userId = key.userId;
                  item.apiId = key.apiId;
                  item.remarkCode = key.remarkCode;
                  nlist.push(item);
                }
              }
            }
          }
          let currentList = await this.currentEntrust();
          if (currentList && currentList.length !== 0) {
            for (let item = 0; item < nlist.length; item++) {
              for (let i = 0; i < currentList.length; i++) {
                // flat_empty平空  flat_many平多
                if (
                  nlist[item].exchangeName == "Binance" &&
                  currentList[i].publicPeopleName.indexOf(nlist[item].apiName) >
                    -1 &&
                  (JSON.parse(currentList[i].executeConfig).actions[0].name !==
                  "plan-order"
                    ? currentList[i].priceType == 1
                    : nlist[item].states !== "WAIT_TRIGGER")
                ) {
                  if (
                    (currentList[i].orderDirection == "flat_empty" &&
                      nlist[item].direction == "sell") ||
                    (currentList[i].orderDirection == "flat_many" &&
                      nlist[item].direction == "buy")
                  ) {
                    nlist[item].availableNum =
                      Number(nlist[item].availableNum) -
                      Number(currentList[i].investNum);
                  }
                }
              }
              nlist[item].availableNum =
                nlist[item].availableNum >= 0 ? nlist[item].availableNum : 0;
            }
          }

          this.positionList = JSON.parse(JSON.stringify(nlist));
          let oldList = this.multipleSelection;

          if (oldList && oldList.length > 0) {
            for (let i = 0; i < nlist.length; i++) {
              for (let j = 0; j < oldList.length; j++) {
                if (
                  nlist[i].contractId == oldList[j].contractId &&
                  nlist[i].exchangeName == oldList[j].exchangeName &&
                  nlist[i].direction == oldList[j].direction &&
                  nlist[i].availableNum == oldList[j].availableNum &&
                  nlist[i].leverRate == oldList[j].leverRate &&
                  nlist[i].apiId == oldList[j].apiId
                ) {
                  nlist[i].checked = true;
                  // setTimeout(() => {
                  //   this.$refs.multipleTable2.toggleRowSelection(nlist[i]);
                  // }, 50);
                }
              }
            }
          } else {
            nlist.forEach((e) => {
              e.checked = false;
            });
          }
          this.$forceUpdate();
          setTimeout(() => {
            if (this.operatorUserShow && this.currentEntrustShow) {
              // this.pollingMethod();
            }
          }, 1000);
        } else {
        }
      });
    },

    // 当前委托
    async currentEntrust() {
      if (!this.pair) {
        return;
      }
      let payload = {
        token: sessionStorage.getItem("token"),
        transactionType: this.transactionType,
        teamProjectId: this.project.teamProjectId,
        exchangeName: this.exchangeName == this.$t('m.全部') ? "" : this.exchangeName,
      };
      if (this.btmSelectInp) {
        payload.apiName = this.btmSelectInp;
      }

      if (this.transactionType == "spot") {
        payload.transactionPair =
          this.pair.counterCoin + "/" + this.pair.baseCoin;
      } else {
        payload.transactionPair = this.pair.counterCoin;
      }

      return currentEntrust(payload).then((res) => {
        if (res.status == 200) {
          let list = res.data.currentEntrustList;

          for (let key of list) {
            key.investCoin = JSON.parse(key.executeConfig).tradePair.investCoin;
            let params = JSON.parse(key.executeConfig).actions[0].params;
            let actions = JSON.parse(key.executeConfig).actions[0];
            if (params && params.length == 1) {
              if (actions.name == "limit-order") {
                key.price = params[0].value;
                key.triggerType = 3;
                key.priceType = 1;
              }
              key.triggerPrice = "--";
            } else if (params && params.length > 1) {
              params.forEach((e) => {
                if (e.name == "triggerPrice") {
                  key.triggerPrice = e.value;
                }
                if (e.name == "price") {
                  key.price = e.value ? e.value : "--";
                }
                if (e.name == "triggerType") {
                  key.triggerType = e.value;
                }
                if (e.name == "priceType") {
                  key.priceType = e.value;
                  key.priceTypeData = 3;
                }
                if (e.name == "type") {
                  key.type = e.value;
                }
              });
            } else {
              key.triggerPrice = "--";
              key.price = "--";
              key.priceType = 2;
            }
          }
          let oldList = this.multipleSelection;

          if (oldList && oldList.length > 0) {
            for (let i = 0; i < list.length; i++) {
              for (let j = 0; j < oldList.length; j++) {
                if (list[i].orderChainId == oldList[j].orderChainId) {
                  // setTimeout(() => {
                  //   this.$refs.multipleTable.toggleRowSelection(list[i]);
                  // }, 100);
                  list[i].checked = true;
                }
              }
            }
          } else {
            list.forEach((e) => {
              e.checked = false;
            });
          }

          this.currentScreenList = list;
          this.backupsCurrentScreenList = JSON.parse(JSON.stringify(list));
          if (this.activeName == 1) {
          } else if (this.activeName == "2") {
            if (this.screenTime == 0) {
              if (this.screenDirection !== 0) {
                this.screenDirectionBtn();
              } else if (this.screenMode !== 0) {
                this.screenModeBtn();
              } else if (
                this.screenNumber !== 0 &&
                this.transactionType !== "spot"
              ) {
                this.screenNumberBtn();
              } else if (this.screenState !== 0) {
                this.screenStateBtn();
              }
            } else {
              this.screenTimeBtn();
            }
          }

          setTimeout(() => {
            if (this.operatorUserShow && this.currentEntrustShow) {
              // this.pollingMethod();
            }
          }, 1000);
        } else {
          this.$message.error(res.msg);
        }

        return this.currentScreenList;
      });
    },

    // // 查询操作员下账户列表
    getCurrentPublicUser() {
      let payload = {
        token: sessionStorage.getItem("token"),
      };
      getCurrentPublicUser(payload).then((res) => {
        if (res.status == 200) {
          this.currentPublicUserList = res.data.currentPublicUserList;
        }
      });
    },

    // 兑换/撤单二次确认
    secondaryConfirm() {
      this.secondary = false;
      this.secondaryShow = false;
      this.Secondary();
    },

    // 撤销委托
    async revoke(type, item) {
      if (
        (!this.multipleSelection || this.multipleSelection.length == 0) &&
        !item
      ) {
        this.$message.error(this.$t('m.请先选择至少一条需要操作的数据'));
        return;
      }
      this.secondaryType = type;
      this.secondaryItem = item;
      if (type == "兑换") {
        if (item || this.multipleSelection.length == 1) {
          this.secondaryShow = true;
          if (item) {
            item.availableNum = item.available;
            this.scopedData = item;
          } else {
            this.multipleSelection[0].availableNum =
              this.multipleSelection[0].available;
            this.scopedData = this.multipleSelection[0];
          }
        } else {
          this.secondary = true;
          this.secondaryText =
            "确认快速兑换USDT后所选持仓将以take单快速兑换为USDT。";
        }
        this.secondaryTitle = "快速兑换USDT";
      } else if (type == "平仓") {
        if (item || this.multipleSelection.length == 1) {
          this.secondaryShow = true;
          if (item) {
            item.availableNum = item.volumeNum;
            this.scopedData = item;
          } else {
            this.multipleSelection[0].availableNum =
              this.multipleSelection[0].volumeNum;
            this.scopedData = this.multipleSelection[0];
          }
        } else {
          this.secondary = true;
          this.secondaryText = "确认快速平仓后所选持仓将以taker单快速平仓。";
        }
        this.secondaryTitle = "快速平仓";
      } else if (type == "撤销") {
        let payload = {
          token: sessionStorage.getItem("token"),
          orderChainStr: "",
        };
        if (item) {
          payload.orderChainStr = item.orderChainId;
        } else {
          let list = this.multipleSelection;
          for (let i = 0; i < list.length; i++) {
            if (list.length > 1) {
              payload.orderChainStr += "," + list[i].orderChainId;
            } else {
              payload.orderChainStr = list[i].orderChainId;
            }
          }
        }

        batchOrderChainEnd(payload).then((res) => {
          if (res.status == 200) {
            this.$message.success(this.$t('m.操作成功'));
            this.operatorUserShow = false;
            this.queryOperatorUserList();
            this.currentEntrust();
            this.multipleSelection = [];
            this.posistionCheckAllSelect = false;
            this.posistionCheckAll = false;
            sessionStorage.removeItem("multipleSelection");
          }
        });
      }
    },

    async Secondary() {
      if (this.multipleSelection.length == 1 || this.secondaryItem) {
        if (
          Math.floor((this.scopedData.availableNum * this.scopedSlider) / 100) >
          this.scopedData.availableNum
        ) {
          this.$message.error(this.$t('m.投入不能大于投入范围'));
          return;
        } else if (
          Math.floor(
            (this.scopedData.availableNum * this.scopedSlider) / 100
          ) <= 0
        ) {
          this.$message.error(this.$t('m.投入不能小于0'));
          return;
        }
      }

      let item = this.secondaryItem;
      let type = this.secondaryType;

      if (type == "兑换") {
        let swapStr = [];
        let multipleSelection = [];
        if (item) {
          multipleSelection.push(item);
        } else {
          multipleSelection = this.multipleSelection;
        }

        for (let key of multipleSelection) {
          let params = {
            token: sessionStorage.getItem("token"),
            exchangeName: key.exchangeName,
            counterCoin: key.coinType,
            baseCoin: key.investCoin,
          };
          var res = await querySpotByPairId(params);
          if (res.status == 200) {
            let val = {};
            if (this.secondaryItem || this.multipleSelection.length == 1) {
              if (this.transactionType == "spot") {
                let amount = (
                  Math.ceil(
                    this.scopedData.availableNum * this.scopedSlider * 100000
                  ) / 10000000
                ).toString();
                if (amount.indexOf(".") > -1) {
                  if (this.scopedData.coinType == "BTC") {
                    amount = amount.slice(0, amount.indexOf(".") + 7);
                  } else {
                    amount = amount.slice(0, amount.indexOf(".") + 5);
                  }
                }
                val.amount = amount;
              } else {
                val.amount = Math.floor(
                  (this.scopedData.availableNum * this.scopedSlider) / 100
                ).toString();
              }
            }
            val.pairId = res.data.pairMap.pairId + "";
            val.exchangeId = res.data.pairMap.exchangeId + "";
            val.apiId = key.apiId + "";
            val.counterCoinAmount = key.available + "";
            val.userId = key.userId + "";
            swapStr.push(val);
          }
        }

        let payload = {
          token: sessionStorage.getItem("token"),
          swapStr: JSON.stringify(swapStr),
        };
        quickSwap(payload).then((res) => {
          if (res.status == 200) {
            this.$message.success(this.$t('m.操作成功'));
            this.scopedSlider = 0;
            this.scopedNumModel = null;
            this.actionBtnIndex = null;
            if (this.transactionType == "spot") {
              this.getSpotApiList();
              this.multipleSelection = [];
            } else {
              this.batchGetContractApiList();
              this.multipleSelection = [];
            }
          } else {
            this.$message.error(res.msg);
          }
        });
      } else if (type == "平仓") {
        let payload = {
          token: sessionStorage.getItem("token"),
          flatPositionStr: [],
        };

        let list = [];
        if (item) {
          list.push(item);
        } else {
          list = this.multipleSelection;
        }
        for (let key of list) {
          let item = {
            apiId: key.apiId,
            contractId: key.contractId,
            direction: key.direction,
            exchangeName: key.exchangeName,
            investCoin: key.investCoin,
            userId: key.userId,
          };
          if (this.secondaryItem || this.multipleSelection.length == 1) {
            item.amount = Math.floor(
              (this.scopedData.availableNum * this.scopedSlider) / 100
            ).toString();
          }
          payload.flatPositionStr.push(item);
        }

        quickFlatPosition(payload).then((res) => {
          if (res.status == 200) {
            this.$message.success(this.$t('m.操作成功'));
            this.batchGetContractApiList();
            this.operatorUserShow = false;
            this.scopedSlider = 0;
            this.actionBtnIndex = null;
            this.scopedNumModel = null;
            this.queryOperatorUserList();
          } else {
            this.$message.error(res.msg);
          }
        });
      }
      this.secondaryType = null;
      this.secondaryItem = null;
      this.secondaryTitle = null;
      this.secondaryText = null;

      this.multipleSelection = [];
      this.posistionCheckAllSelect = false;
      this.posistionCheckAll = false;
      sessionStorage.removeItem("multipleSelection");
    },

    // 需要轮询的接口
    pollingMethod() {
      this.currentEntrustShow = false;
      this.operatorUserShow = true;
      // this.queryOperatorUserList();
      // this.queryErrorMessage();
    },
    // 需要轮询的接口
    quickUserAndData() {
      try {
        let payload = {
          token: sessionStorage.getItem("token"),
          exchangeName: this.exchangeName == this.$t('m.全部') ? "" : this.exchangeName,
          teamProjectId: this.project.teamProjectId,
          pageSize: 999,
        };
        if (this.selectAllShow) {
          payload.exchangeName =
            this.exchangeNameShow == this.$t('m.全部') ? "" : this.exchangeNameShow;
        }
        // if (this.transactionType == "spot") {
        //   if (this.pair) {
        //     payload.investCoin =
        //       this.pair.baseCoin + "," + this.pair.counterCoin; //现货
        //   }
        //   if (!payload.investCoin) {
        //     return;
        //   }
        // } else {
        //   if (this.pair) {
        //     payload.contractId = this.pair.contractId; //合约
        //     payload.counterCoin = this.pair.counterCoin;
        //   }
        //   if (!payload.contractId) {
        //     return;
        //   }
        // }
        // if (this.transactionType == "spot" && this.activeName == "1") {
        //   if (this.pair) {
        //     payload.coinType = this.pair.baseCoin + "," + this.pair.counterCoin;
        //   }
        //   if (this.btmSelectInp) {
        //     payload.apiName = this.btmSelectInp;
        //   }
        // } else if (this.transactionType != "spot" && this.activeName == "1") {
        //   payload.counterCoin = this.pair.counterCoin;
        //   if (this.btmSelectInp) {
        //     payload.apiName = this.btmSelectInp;
        //   }
        // } else if (this.activeName == "2") {
        //   payload.transactionType = this.transactionType;
        //   if (this.btmSelectInp) {
        //     payload.apiName = this.btmSelectInp;
        //   }
        //   if (this.pair) {
        //     payload.transactionPair = this.pair.counterCoin;
        //   }
        // }
        if (this.pair) {
          payload.investCoin = this.pair.baseCoin + "," + this.pair.counterCoin; //现货
          payload.transactionPair = this.pair.counterCoin;
          payload.counterCoin = this.pair.counterCoin;
          payload.contractId = this.pair.contractId;
          payload.coinType = this.pair.baseCoin + "," + this.pair.counterCoin;
        }
        if (this.btmSelectInp) {
          payload.apiName = this.btmSelectInp;
        }
        if (this.selectAllShow) {
          payload.transactionType = this.transactionTypeShow;
          payload.investCoin =
            this.pairShowList.baseCoin + "," + this.pairShowList.counterCoin; //现货
          payload.transactionPair = this.pairShowList.counterCoin;
          payload.counterCoin = this.pairShowList.counterCoin;
          payload.contractId = this.pairShowList.contractId;
          payload.coinType =
            this.pairShowList.baseCoin + "," + this.pairShowList.counterCoin;
        } else {
          payload.transactionType = this.transactionType;
        }
        quickUserAndData(payload)
          .then((res) => {
            if (res.status == 200) {
              if (this.selectAllShow) {
                let list = res.data.operatorUserList;
                if (list.length !== 0) {
                  let newList = [];
                  if (
                    list &&
                    list.length > 0 &&
                    this.transactionTypeShow == "spot"
                  ) {
                    for (let i = 0; i < list.length; i++) {
                      if (list.length == 1) {
                        let item = null;
                        item = list[i];
                        item.counterCoin = this.pair.counterCoin;
                        item.counterCoinNum = 0;
                        newList.push(item);
                      } else {
                        for (let j = i + 1; j < list.length; j++) {
                          if (list[i].apiId == list[j].apiId) {
                            let item = null;
                            if (list[i].coinType == this.pair.baseCoin) {
                              item = list[i];
                              item.counterCoin = list[j].coinType;
                              item.counterCoinNum = list[j].available;
                            } else {
                              item = list[j];
                              item.counterCoin = list[i].coinType;
                              item.counterCoinNum = list[i].available;
                            }
                            // list[i].counterCoin = list[j].coinType;
                            // list[i].counterCoinNum = list[j].available;
                            // list.splice(j);
                            newList.push(item);
                          } else if (i == list.length) {
                            let item = null;
                            item = list[i];
                            item.counterCoin = this.pair.counterCoin;
                            item.counterCoinNum = 0;
                            newList.push(item);
                          }
                        }
                      }
                    }
                  } else {
                    // 合约
                    let oldList = res.data.operatorUserList;
                    // 买入张数计算
                    let dataList = this.dataList;

                    for (let key of oldList) {
                      for (let i = 0; i < dataList.length; i++) {
                        if (
                          key.exchangeName.toLocaleLowerCase() ==
                          dataList[i].exchangeName.toLocaleLowerCase()
                        ) {
                          key.openSize = Math.floor(
                            (Number(key.available) /
                              Number(dataList[i].price)) *
                              this.lever
                          );
                          newList.push(key);
                        }
                      }
                    }
                  }
                  if (this.selectAllShowList) {
                    this.selectAllShowList.forEach((e) => {
                      if (e.isCheck) {
                        newList.forEach((item) => {
                          if (e.apiId == item.apiId) {
                            item.isCheck = true;
                          }
                        });
                      }
                    });
                  }

                  this.selectAllShowList = JSON.parse(JSON.stringify(newList));
                } else {
                  this.selectAllShowList = null;
                }
              } else {
                if (res.data.ErrorMessageList.length !== 0) {
                  let ErrorMessageList = res.data.ErrorMessageList;
                  if (this.openList.length > 0) {
                    for (let i = this.openList.length - 1; i >= 0; i--) {
                      if (this.openList[i].show) {
                        ErrorMessageList.push(this.openList[i]);
                      }
                    }
                  }
                  ErrorMessageList = ErrorMessageList.slice(0, 3);
                  let ErrorMessage = [];
                  ErrorMessageList.forEach((e) => {
                    e.show = true;
                    ErrorMessage.unshift(e);
                  });
                  this.openList = ErrorMessage;
                }
                if (res.data.lastAadIncrease) {
                  let lastAadIncrease = JSON.parse(res.data.lastAadIncrease);
                  this.pair.price = lastAadIncrease.usdPrice;
                  this.pair.last = lastAadIncrease.last;
                  this.pair.increase = lastAadIncrease.increase;
                }
                if (res.data.operatorUserList) {
                  let list = res.data.operatorUserList;
                  if (list.length !== 0) {
                    let newList = [];
                    if (
                      list &&
                      list.length > 0 &&
                      this.transactionType == "spot"
                    ) {
                      for (let i = 0; i < list.length; i++) {
                        if (list.length == 1) {
                          let item = null;
                          item = list[i];
                          item.counterCoin = this.pair.counterCoin;
                          item.counterCoinNum = 0;
                          newList.push(item);
                        } else {
                          for (let j = i + 1; j < list.length; j++) {
                            if (list[i].apiId == list[j].apiId) {
                              let item = null;
                              if (list[i].coinType == this.pair.baseCoin) {
                                item = list[i];
                                item.counterCoin = list[j].coinType;
                                item.counterCoinNum = list[j].available;
                              } else {
                                item = list[j];
                                item.counterCoin = list[i].coinType;
                                item.counterCoinNum = list[i].available;
                              }
                              // list[i].counterCoin = list[j].coinType;
                              // list[i].counterCoinNum = list[j].available;
                              // list.splice(j);
                              newList.push(item);
                            } else if (i == list.length) {
                              let item = null;
                              item = list[i];
                              item.counterCoin = this.pair.counterCoin;
                              item.counterCoinNum = 0;
                              newList.push(item);
                            }
                          }
                        }
                      }
                    } else {
                      // 合约
                      let oldList = res.data.operatorUserList;
                      // 买入张数计算
                      let dataList = this.dataList;

                      for (let key of oldList) {
                        for (let i = 0; i < dataList.length; i++) {
                          if (
                            key.exchangeName.toLocaleLowerCase() ==
                            dataList[i].exchangeName.toLocaleLowerCase()
                          ) {
                            key.openSize = Math.floor(
                              (Number(key.available) /
                                Number(dataList[i].price)) *
                                this.lever
                            );
                            newList.push(key);
                          }
                        }
                      }
                    }

                    let nList = newList;
                    let checkAccList = JSON.parse(
                      sessionStorage.getItem("checkAccList")
                    );
                    let oList = checkAccList;
                    let number = 0;
                    if (oList && oList.length > 0) {
                      for (let i = 0; i < nList.length; i++) {
                        nList[i].isCheck = false;
                        for (let j = 0; j < oList.length; j++) {
                          if (nList[i].apiId == oList[j].apiId) {
                            nList[i].isCheck = true;
                            number++;
                          }
                        }
                      }
                    }
                    if (number == nList.length) {
                      this.accSelectAll = true;
                    } else {
                      this.accSelectAll = false;
                    }

                    if (
                      res.data.currentEntrustList &&
                      res.data.currentEntrustList.length !== 0
                    ) {
                      let currentList = res.data.currentEntrustList;
                      for (let item = 0; item < nList.length; item++) {
                        for (let i = 0; i < currentList.length; i++) {
                          if (
                            nList[item].exchangeName == "Binance" &&
                            nList[item].publicCode ==
                              currentList[i].publicPeopleName &&
                            (JSON.parse(currentList[i].executeConfig).actions[0]
                              .name !== "plan-order" ||
                              nList[item].states !== "WAIT_TRIGGER")
                          ) {
                            if (currentList[i].orderDirection == "flat_empty") {
                              nList[item].sell =
                                nList[item].sell -
                                Number(currentList[i].investNum);
                            } else if (
                              currentList[i].orderDirection == "flat_many"
                            ) {
                              nList[item].buy =
                                nList[item].buy -
                                Number(currentList[i].investNum);
                            }
                          }
                          nList[item].sell =
                            nList[item].sell >= 0 ? nList[item].sell : 0;
                          nList[item].buy =
                            nList[item].buy >= 0 ? nList[item].buy : 0;
                        }
                      }
                      if (this.activeName == "2") {
                        let list = res.data.currentEntrustList;

                        for (let key of list) {
                          key.investCoin = JSON.parse(
                            key.executeConfig
                          ).tradePair.investCoin;
                          let params = JSON.parse(key.executeConfig).actions[0]
                            .params;
                          let actions = JSON.parse(key.executeConfig)
                            .actions[0];
                          if (params && params.length == 1) {
                            if (actions.name == "limit-order") {
                              key.price = params[0].value;
                              key.triggerType = 3;
                              key.priceType = 1;
                            }
                            key.triggerPrice = "--";
                          } else if (params && params.length > 1) {
                            params.forEach((e) => {
                              if (e.name == "triggerPrice") {
                                key.triggerPrice = e.value;
                              }
                              if (e.name == "price") {
                                key.price = e.value ? e.value : "--";
                              }
                              if (e.name == "triggerType") {
                                key.triggerType = e.value;
                              }
                              if (e.name == "priceType") {
                                key.priceType = e.value;
                                key.priceTypeData = 3;
                              }
                              if (e.name == "type") {
                                key.type = e.value;
                              }
                            });
                          } else {
                            key.triggerPrice = "--";
                            key.price = "--";
                            key.priceType = 2;
                          }
                        }

                        let oldList = JSON.parse(
                          sessionStorage.getItem("multipleSelection")
                        );

                        if (oldList && oldList.length > 0) {
                          let checkedNumber = 0;
                          for (let i = 0; i < list.length; i++) {
                            list[i].checked = false;
                            for (let j = 0; j < oldList.length; j++) {
                              if (
                                this.transactionType !== "spot" &&
                                list[i].contractId == oldList[j].contractId &&
                                list[i].exchangeName ==
                                  oldList[j].exchangeName &&
                                list[i].direction == oldList[j].direction &&
                                list[i].availableNum ==
                                  oldList[j].availableNum &&
                                list[i].leverRate == oldList[j].leverRate &&
                                list[i].apiId == oldList[j].apiId &&
                                list[i].createTime == oldList[j].createTime &&
                                list[i].publicPeopleName ==
                                  oldList[j].publicPeopleName
                              ) {
                                list[i].checked = true;
                                checkedNumber++;
                                // setTimeout(() => {
                                //   this.$refs.multipleTable.toggleRowSelection(
                                //     list[i]
                                //   );
                                // }, 100);
                              } else if (
                                this.transactionType == "spot" &&
                                list[i].apiName == oldList[j].apiName &&
                                list[i].exchangeName ==
                                  oldList[j].exchangeName &&
                                list[i].apiId == oldList[j].apiId &&
                                list[i].createTime == oldList[j].createTime &&
                                list[i].publicPeopleName ==
                                  oldList[j].publicPeopleName
                              ) {
                                list[i].checked = true;
                                checkedNumber++;
                              }
                            }
                          }
                          if (checkedNumber == list.length) {
                            this.posistionCheckAllSelect = true;
                            this.posistionCheckAll = false;
                          } else if (checkedNumber == 0) {
                            this.posistionCheckAllSelect = false;
                            this.posistionCheckAll = false;
                          } else {
                            this.posistionCheckAll = true;
                            this.posistionCheckAllSelect = false;
                          }
                        } else {
                          list.forEach((e) => {
                            e.checked = false;
                          });
                        }

                        this.currentEntrustList = JSON.parse(
                          JSON.stringify(list)
                        );
                        this.backupsCurrentScreenList = JSON.parse(
                          JSON.stringify(list)
                        );
                        if (this.screenTime == 0) {
                          if (this.screenDirection !== 0) {
                            this.screenDirectionBtn();
                          } else if (this.screenMode !== 0) {
                            this.screenModeBtn();
                          } else if (
                            this.screenNumber !== 0 &&
                            this.transactionType !== "spot"
                          ) {
                            this.screenNumberBtn();
                          } else if (this.screenState !== 0) {
                            this.screenStateBtn();
                          }
                        } else {
                          this.screenTimeBtn();
                        }
                      }
                    } else {
                      this.currentEntrustList = null;
                    }
                    this.accList = JSON.parse(JSON.stringify(nList));
                    this.pointNumber = JSON.parse(JSON.stringify(nList));
                  } else {
                    this.accList = null;
                  }
                }
                if (res.data.lastAadIncrease) {
                  let lastAadIncrease = JSON.parse(res.data.lastAadIncrease);
                  this.pair.increase = lastAadIncrease.increase;
                  this.pair.last = lastAadIncrease.last;
                }

                if (this.transactionType == "spot" && this.activeName == "1") {
                  if (
                    res.data.spotApiList &&
                    res.data.spotApiList.length !== 0
                  ) {
                    let list = res.data.spotApiList;
                    let newList = [];
                    for (let key of list) {
                      if (key.coinType != "USDT") {
                        if (this.showOrderSmallCoin) {
                          if (key.coinValue < 10) {
                          }
                        } else {
                          newList.push(key);
                        }
                      }
                    }

                    let oldList = JSON.parse(
                      sessionStorage.getItem("multipleSelection")
                    );

                    if (oldList && oldList.length > 0) {
                      let checkedNumber = 0;
                      for (let i = 0; i < oldList.length; i++) {
                        for (let j = 0; j < newList.length; j++) {
                          if (
                            oldList[i].exchangeName ==
                              newList[j].exchangeName &&
                            oldList[i].coinType == newList[j].coinType &&
                            oldList[i].apiId == newList[j].apiId
                          ) {
                            newList[i].checked = true;
                            checkedNumber++;
                            // setTimeout(() => {
                            //   this.$refs.multipleTable2.toggleRowSelection(
                            //     this.positionList[j]
                            //   );
                            // }, 50);
                          }
                        }
                      }
                      if (checkedNumber == newList.length) {
                        this.posistionCheckAllSelect = true;
                        this.posistionCheckAll = false;
                      } else if (checkedNumber == 0) {
                        this.posistionCheckAll = false;
                        this.posistionCheckAllSelect = false;
                      } else {
                        this.posistionCheckAll = true;
                        this.posistionCheckAllSelect = false;
                      }
                    } else {
                      newList.forEach((e) => {
                        e.checked = false;
                      });
                    }

                    this.positionList = JSON.parse(JSON.stringify(newList));

                    if (!this.poAccList) {
                      let poAccList = [];
                      for (let i = 0; i < list.length; i++) {
                        for (let j = i + 1; j < list.length; j++) {
                          if (list[i].apiName == list[j].apiName) {
                            poAccList.push(list[i]);
                          }
                        }
                      }
                      let all = {
                        apiName: this.$t('m.全部'),
                      };
                      this.poAccList = poAccList;
                      this.poAccList.unshift(all);
                    }
                  } else {
                    this.positionList = null;
                  }
                } else if (
                  this.transactionType != "spot" &&
                  this.activeName == "1"
                ) {
                  if (
                    res.data.contractList &&
                    res.data.contractList.length !== 0
                  ) {
                    let list = [];
                    for (let key of res.data.contractList) {
                      if (
                        key.positions &&
                        JSON.parse(key.positions).length != 0
                      ) {
                        list.push(key);
                      }
                    }

                    let nlist = [];
                    for (let key of list) {
                      if (
                        JSON.parse(key.positions) &&
                        JSON.parse(key.positions).length != 0
                      ) {
                        let forList = JSON.parse(key.positions);
                        for (let i = 0; i < forList.length; i++) {
                          let contractSize;
                          for (
                            let item = 0;
                            item < this.dataList.length;
                            item++
                          ) {
                            if (
                              this.dataList[
                                item
                              ].exchangeName.toLocaleUpperCase() ==
                              key.exchangeName.toLocaleUpperCase()
                            ) {
                              contractSize = this.dataList[item].contractSize;
                            }
                          }
                          if (
                            this.showOrderSmallCoin &&
                            Number(forList[i].volumeNum) *
                              Number(contractSize) *
                              Number(forList[i].costOpen) <
                              10
                          ) {
                          } else {
                            let item = {};
                            item.contractId = forList[i].contractId;
                            item.volumeNum = forList[i].volumeNum;
                            item.availableNum = forList[i].availableNum;
                            item.costOpen = forList[i].costOpen;
                            item.riskRate = forList[i].riskRate;
                            item.liquidationPrice = forList[i].liquidationPrice;
                            item.profit = forList[i].profit;
                            item.profitRate = forList[i].profitRate;
                            item.direction = forList[i].direction;
                            item.leverRate = forList[i].leverRate;
                            item.apiName = key.apiName;
                            item.exchangeName = key.exchangeName;
                            item.investCoin = key.investCoin;
                            item.userId = key.userId;
                            item.apiId = key.apiId;
                            item.remarkCode = key.remarkCode;
                            nlist.push(item);
                          }
                        }
                      }
                    }
                    if (res.data.currentEntrustList.length !== 0) {
                      let currentList = res.data.currentEntrustList;
                      for (let item = 0; item < nlist.length; item++) {
                        for (let i = 0; i < currentList.length; i++) {
                          // flat_empty平空  flat_many平多
                          if (
                            nlist[item].exchangeName == "Binance" &&
                            currentList[i].publicPeopleName.indexOf(
                              nlist[item].apiName
                            ) > -1 &&
                            (JSON.parse(currentList[i].executeConfig).actions[0]
                              .name !== "plan-order" ||
                              nlist[item].states !== "WAIT_TRIGGER")
                          ) {
                            if (
                              (currentList[i].orderDirection == "flat_empty" &&
                                nlist[item].direction == "sell") ||
                              (currentList[i].orderDirection == "flat_many" &&
                                nlist[item].direction == "buy")
                            ) {
                              nlist[item].availableNum =
                                Number(nlist[item].availableNum) -
                                Number(currentList[i].investNum);
                            }
                          }
                          nlist[item].availableNum =
                            nlist[item].availableNum >= 0
                              ? nlist[item].availableNum
                              : 0;
                        }
                      }
                    }
                    if (this.screenTime == 0) {
                      if (this.screenDirection !== 0) {
                        this.screenDirectionBtn();
                      } else if (this.screenMode !== 0) {
                        this.screenModeBtn();
                      } else if (
                        this.screenNumber !== 0 &&
                        this.transactionType !== "spot"
                      ) {
                        this.screenNumberBtn();
                      } else if (this.screenState !== 0) {
                        this.screenStateBtn();
                      }
                    } else {
                      this.screenTimeBtn();
                    }
                    // qqq

                    let oldList = JSON.parse(
                      sessionStorage.getItem("multipleSelection")
                    );
                    if (oldList && oldList.length > 0) {
                      let checkedNumber = 0;
                      for (let i = 0; i < nlist.length; i++) {
                        for (let j = 0; j < oldList.length; j++) {
                          if (
                            nlist[i].contractId == oldList[j].contractId &&
                            nlist[i].exchangeName == oldList[j].exchangeName &&
                            nlist[i].direction == oldList[j].direction &&
                            nlist[i].availableNum == oldList[j].availableNum &&
                            nlist[i].leverRate == oldList[j].leverRate &&
                            nlist[i].apiId == oldList[j].apiId
                          ) {
                            nlist[i].checked = true;
                            checkedNumber++;
                            // setTimeout(() => {
                            //   this.$refs.multipleTable2.toggleRowSelection(
                            //     nlist[i]
                            //   );
                            // }, 50);
                          }
                        }
                      }
                      if (checkedNumber == nlist.length) {
                        this.posistionCheckAllSelect = true;
                        this.posistionCheckAll = false;
                      } else if (checkedNumber == 0) {
                        this.posistionCheckAllSelect = false;
                        this.posistionCheckAll = false;
                      } else {
                        this.posistionCheckAll = true;
                        this.posistionCheckAllSelect = false;
                      }
                    } else {
                      nlist.forEach((e) => {
                        e.checked = false;
                      });
                    }

                    this.positionList = JSON.parse(JSON.stringify(nlist));
                  } else {
                    this.positionList = null;
                  }
                }
              }

              this.$forceUpdate();
              setTimeout(() => {
                if (!sessionStorage.getItem("polling")) {
                  return;
                }
                this.quickUserAndData();
              }, 2000);
            } else {
              setTimeout(() => {
                if (!sessionStorage.getItem("polling")) {
                  return;
                }
                this.quickUserAndData();
              }, 2000);
            }
          })
          .catch(() => {
            setTimeout(() => {
              if (!sessionStorage.getItem("polling")) {
                return;
              }
              this.quickUserAndData();
            }, 2000);
          });
      } catch (error) {
        setTimeout(() => {
          if (!sessionStorage.getItem("polling")) {
            return;
          }
          this.quickUserAndData();
        }, 2000);
      }
    },

    // 恢复刷新前数据
    recovery() {
      let transactionType = sessionStorage.getItem("transactionType");
      let activeName = sessionStorage.getItem("activeName");
      let exchangeId = sessionStorage.getItem("exchangeId");
      let exchangeName = sessionStorage.getItem("exchangeName");
      let pair =
        sessionStorage.getItem("pair") !== "undefined"
          ? JSON.parse(sessionStorage.getItem("pair"))
          : null;
      let orderType = sessionStorage.getItem("orderType");
      let lever = sessionStorage.getItem("lever");
      let checkAccList = JSON.parse(sessionStorage.getItem("checkAccList"));
      let buyNum = sessionStorage.getItem("buyNum");
      let buyMin = sessionStorage.getItem("buyMin");
      let buyMax = sessionStorage.getItem("buyMax");
      let sellNum = sessionStorage.getItem("sellNum");
      let sellMax = sessionStorage.getItem("sellMax");
      let sellMin = sessionStorage.getItem("sellMin");
      let btmSelectInp = sessionStorage.getItem("btmSelectInp");
      let multipleSelection = JSON.parse(
        sessionStorage.getItem("multipleSelection")
      );
      let sellInvestmentType = sessionStorage.getItem("sellInvestmentType");
      let buyInvestmentType = sessionStorage.getItem("buyInvestmentType");
      let contractPositionTType = sessionStorage.getItem(
        "contractPositionTType"
      );
      let breachType = sessionStorage.getItem("breachType");
      let breachTypeTwo = sessionStorage.getItem("breachTypeTwo");

      let breach = sessionStorage.getItem("breach");
      let breachTwo = sessionStorage.getItem("breachTwo");
      let buyActionPurchasePriceTwo = sessionStorage.getItem(
        "buyActionPurchasePriceTwo"
      );
      let buyActionPurchasePrice = sessionStorage.getItem(
        "buyActionPurchasePrice"
      );
      let buyActionTriggerPriceTwo = sessionStorage.getItem(
        "buyActionTriggerPriceTwo"
      );
      let buyActionTriggerPrice = sessionStorage.getItem(
        "buyActionTriggerPrice"
      );

      if (contractPositionTType) {
        this.contractPositionTType = contractPositionTType;
      }

      if (transactionType) {
        this.transactionType = transactionType;
      }

      if (sellInvestmentType) {
        this.sellInvestmentType = sellInvestmentType;
      }

      if (buyInvestmentType) {
        this.buyInvestmentType = buyInvestmentType;
      }

      if (exchangeId) {
        this.exchangeName = exchangeName;
        this.exchangeId = exchangeId;
      }

      if (pair) {
        this.pair = pair;
      }

      if (orderType) {
        this.orderType = orderType;
      }

      if (lever) {
        this.lever = lever;
      }

      if (checkAccList) {
        this.checkAccList = checkAccList;
        this.pointNumber = checkAccList;
      }

      if (buyNum) {
        this.buyNum = buyNum;
      }

      if (buyMin) {
        this.buyMin = buyMin;
      }

      if (buyMax) {
        this.buyMax = buyMax;
      }

      if (sellNum) {
        this.sellNum = sellNum;
      }

      if (sellMax) {
        this.sellMax = sellMax;
      }

      if (sellMin) {
        this.sellMin = sellMin;
      }

      if (btmSelectInp) {
        this.btmSelectInp = btmSelectInp;
      }

      if (multipleSelection) {
        this.multipleSelection = multipleSelection;
      }
      if (breach) {
        this.breach = breach;
      }
      if (breachTwo) {
        this.breachTwo = breachTwo;
      }
      if (buyActionPurchasePriceTwo) {
        this.buyActionPurchasePriceTwo = buyActionPurchasePriceTwo;
      }
      if (buyActionPurchasePrice) {
        this.buyActionPurchasePrice = buyActionPurchasePrice;
      }
      if (buyActionTriggerPrice) {
        this.buyActionTriggerPrice = buyActionTriggerPrice;
      }
      if (buyActionTriggerPriceTwo) {
        this.buyActionTriggerPriceTwo = buyActionTriggerPriceTwo;
      }
      if (breachType) {
        this.breachType = breachType;
      }
      if (breachTypeTwo) {
        this.breachTypeTwo = breachTypeTwo;
      }
      if (activeName) {
        this.activeName = activeName;
        if (activeName == "3") {
          this.historyEntrust();
        }
      }
      // if (this.transactionType == "spot" && this.activeName == "1") {
      //   this.getSpotApiList();
      // } else if (this.transactionType != "spot" && this.activeName == "1") {
      //   this.batchGetContractApiList();
      // } else if (this.activeName == "2") {
      //   this.currentEntrust();
      // }
    },

    // 页面销毁清除本地数据
    remSessVal() {
      sessionStorage.removeItem("transactionType");
      sessionStorage.removeItem("exchangeId");
      sessionStorage.removeItem("exchangeName");
      sessionStorage.removeItem("pair");
      sessionStorage.removeItem("orderType");
      // sessionStorage.removeItem("lever");
      sessionStorage.removeItem("checkAccList");
      sessionStorage.removeItem("buyNum");
      sessionStorage.removeItem("buyMin");
      sessionStorage.removeItem("buyMax");
      sessionStorage.removeItem("sellNum");
      sessionStorage.removeItem("sellMax");
      sessionStorage.removeItem("sellMin");
      sessionStorage.removeItem("btmSelectInp");
      sessionStorage.removeItem("multipleSelection");
      sessionStorage.removeItem("buyInvestmentType");
      sessionStorage.removeItem("sellInvestmentType");
      sessionStorage.removeItem("contractPositionTType");
      sessionStorage.removeItem("breach");
      sessionStorage.removeItem("breachTwo");
      sessionStorage.removeItem("buyActionPurchasePriceTwo");
      sessionStorage.removeItem("buyActionPurchasePrice");
      sessionStorage.removeItem("buyActionTriggerPriceTwo");
      sessionStorage.removeItem("buyActionTriggerPrice");
      sessionStorage.removeItem("activeName");
    },
    currencyBtn(index) {
      this.currencyIndex = index;
    },
    //下单状态
    queryErrorMessage() {
      let payload = {
        token: sessionStorage.getItem("token"),
        teamProjectId: this.project.teamProjectId,
        operator: this.account.userId,
      };
      queryErrorMessage(payload).then((res) => {
        if (res.status == 200) {
          let ErrorMessageList = res.data.ErrorMessageList;
          if (this.openList.length > 0) {
            for (let i = this.openList.length - 1; i >= 0; i--) {
              if (this.openList[i].show) {
                ErrorMessageList.push(this.openList[i]);
              }
            }
          }
          ErrorMessageList = ErrorMessageList.slice(0, 3);
          let ErrorMessage = [];
          ErrorMessageList.forEach((e) => {
            e.show = true;
            ErrorMessage.unshift(e);
          });
          this.openList = ErrorMessage;
        }
      });
    },
    //关闭下单状态按钮
    openShow(index) {
      this.openList[index].show = false;
    },
    //止盈止损按钮
    scopeBtn(type, item) {
      this.scopeType = type;
      if (item.costOpen.toString().indexOf(".") > -1) {
        item.costOpen = item.costOpen
          .toString()
          .slice(0, item.costOpen.toString().indexOf(".") + 5);
      }

      this.scopedData = item;
      this.scopeShow = true;
      if (type == "surplus") {
        this.scopedHolder =
          "触发价格" +
          (item.direction == "sell" ? "小于" : "大于") +
          item.costOpen;
      } else {
        this.scopedHolder =
          "触发价格" +
          (item.direction == "sell" ? "大于" : "小于") +
          item.costOpen;
      }
      this.queryOrderActionDetails();
    },
    scopeTriggerPriceChange(e) {
      if (this.scopedData.direction == "sell") {
        if (this.scopeType == "surplus") {
          if (
            Number(this.scopeTriggerPrice) > Number(this.scopedData.costOpen)
          ) {
            this.scopedPriceShow = true;
            e.target.style.border = "1px solid red";
            this.$message.error(this.$t('m.做空止盈触发价格必须低于开仓均价'));
            return;
          }
        } else {
          if (
            Number(this.scopeTriggerPrice) < Number(this.scopedData.costOpen)
          ) {
            this.scopedPriceShow = true;
            e.target.style.border = "1px solid red";
            this.$message.error(this.$t('m.做空止损触发价格必须高于开仓均价'));
            return;
          }
        }
      } else {
        if (this.scopeType == "surplus") {
          if (
            Number(this.scopeTriggerPrice) < Number(this.scopedData.costOpen)
          ) {
            this.scopedPriceShow = true;
            e.target.style.border = "1px solid red";
            this.$message.error(this.$t('m.做多止盈触发价格必须高于开仓均价'));
            return;
          }
        } else {
          if (
            Number(this.scopeTriggerPrice) > Number(this.scopedData.costOpen)
          ) {
            this.scopedPriceShow = true;
            e.target.style.border = "1px solid red";
            this.$message.error(this.$t('m.做多止损触发价格必须低于开仓均价'));
            return;
          }
        }
      }

      e.target.style.border = "1px solid #DCDFE6";
      this.scopedPriceShow = false;
    },
    //历史委托
    historyEntrust() {
      if (!this.pair) {
        return;
      }
      let payload = {
        token: sessionStorage.getItem("token"),
        transactionType: this.transactionType,
        teamProjectId: this.project.teamProjectId,
        exchangeName: this.exchangeName == this.$t('m.全部') ? "" : this.exchangeName,
      };
      if (this.btmSelectInp) {
        payload.apiName = this.btmSelectInp;
      }

      if (this.transactionType == "spot") {
        payload.transactionPair =
          this.pair.counterCoin + "/" + this.pair.baseCoin;
      } else {
        payload.transactionPair = this.pair.counterCoin;
      }

      historyEntrust(payload).then((res) => {
        if (res.status == 200) {
          let historyEntrustList = res.data.historyEntrust;
          for (let key of historyEntrustList) {
            key.investCoin = JSON.parse(key.executeConfig).tradePair.investCoin;
            let params = JSON.parse(key.executeConfig).actions[0].params;
            let actions = JSON.parse(key.executeConfig).actions[0];
            if (params && params.length == 1) {
              if (actions.name == "limit-order") {
                key.price = params[0].value;
                key.triggerType = 3;
                key.priceType = 1;
              }
              key.triggerPrice = "--";
            } else if (params && params.length > 1) {
              params.forEach((e) => {
                if (e.name == "triggerPrice") {
                  key.triggerPrice = e.value;
                }
                if (e.name == "price") {
                  key.price = e.value ? e.value : "--";
                }
                if (e.name == "triggerType") {
                  key.triggerType = e.value;
                }
                if (e.name == "priceType") {
                  key.priceType = e.value;
                  key.priceTypeData = 3;
                }
                if (e.name == "type") {
                  key.type = e.value;
                }
              });
            } else {
              key.triggerPrice = "--";
              key.price = "--";
              key.priceType = 2;
            }
          }

          this.historyEntrustList = historyEntrustList;
        }
      });
    },

    // 盘口
    socketFn(val) {
      // var socket;
      if (typeof WebSocket == "undefined") {
        // console.log("遗憾：您的浏览器不支持WebSocket");
      } else {
        // console.log("恭喜：您的浏览器支持WebSocket");

        //实现化WebSocket对象
        //指定要连接的服务器地址与端口建立连接
        //注意ws、wss使用不同的端口。我使用自签名的证书测试，
        //无法使用wss，浏览器打开WebSocket时报错
        //ws对应http、wss对应https。
        // socket = new WebSocket("ws://localhost:8080/ws/asset");

        // socket = new WebSocket("ws://192.168.2.36:8088/WebSocketService/ws/asset");
        let transactionType = sessionStorage.getItem("transactionType");
        transactionType = transactionType ? transactionType : "spot";
        let pair = JSON.parse(sessionStorage.getItem("pair"));
        pair = pair
          ? pair
          : { counterCoin: "BTC", baseCoin: "USDT", exchangeName: "Huobi" };

        // this.socket = new WebSocket(
        //   "ws://159.138.87.203:8886/WebSocketServer-Test/ws/" +
        //     (exchangeName == "" ? "Huobi" : exchangeName) +
        //     "-" +
        //     transactionType +
        //     "-" +
        //     pair.coinType
        // );
        this.socket = new WebSocket(
          // "ws://159.138.87.203:8886/WebSocketServer-Test/ws/" +
          //  "wss://159.138.87.203:443/WebSocketServer-Test/ws/" +
          "wss://websocket.vtrading.com/ws/" +
            pair.exchangeName +
            "-" +
            transactionType +
            "-" +
            pair.counterCoin +
            "_" +
            pair.baseCoin
        );

        // 'http://192.168.2.36:8066/'
        //连接打开事件

        // this.socket.onopen = function () {
        //   socket.send("消息发送测试(From Client)");
        // };

        //收到消息事件
        this.socket.onmessage = (msg) => {
          let transactionType = sessionStorage.getItem("transactionType");
          let exchangeName = sessionStorage.getItem("exchangeName");
          pair = pair
            ? pair
            : { counterCoin: "BTC", baseCoin: "USDT", exchangeName: "Huobi" };
          if (msg) {
            if (msg.data.indexOf("error") > -1) {
              this.pairHandicap = [{ transactionTypeCopyShow: true }];
              this.socket.close();
              return;
            }
            if (msg.data == "连接成功") {
              return;
            }
            // if (JSON.parse(msg.data).event == "error") {
            //   alert("交易所不支持该币对");
            //   return;
            // }
            let dataA;
            let dataB;
            if (pair.exchangeName == "OkexV5") {
              dataA = JSON.parse(msg.data).asks;
              dataB = JSON.parse(msg.data).bids;
            } else if (
              pair.exchangeName == this.$t('m.全部') ||
              pair.exchangeName == "Huobi"
            ) {
              dataA = JSON.parse(msg.data).asks;
              dataB = JSON.parse(msg.data).bids;
            } else if (pair.exchangeName == "Binance") {
              dataA = JSON.parse(msg.data).a;
              dataB = JSON.parse(msg.data).b;
            }
            if (!dataA) {
              return;
            }

            let dataCopy = [];
            let counterCoinList = [];
            this.dataList.forEach((e) => {
              if (this.pointNumber.length == 0) {
                if (transactionType == "contract_usdt") {
                  e.counterCoinDecimal =
                    Number(e.contractSize).toString().length -
                    Number(e.contractSize).toString().indexOf(".") -
                    1;
                  counterCoinList.push(+e.counterCoinDecimal);
                } else {
                  counterCoinList.push(+e.counterCoinDecimal);
                }
              } else {
                this.pointNumber.forEach((item) => {
                  if (
                    exchangeName.toUpperCase() == exchangeName.toUpperCase()
                  ) {
                    if (transactionType == "contract_usdt") {
                      e.counterCoinDecimal =
                        Number(e.contractSize).toString().length -
                        Number(e.contractSize).toString().indexOf(".") -
                        1;
                      counterCoinList.push(+e.counterCoinDecimal);
                    } else {
                      counterCoinList.push(+e.counterCoinDecimal);
                    }
                  }
                });
              }
            });
            if (transactionType == "contract_usdt") {
              counterCoinList.sort((a, b) => {
                return b - a;
              });
            } else {
              counterCoinList.sort((a, b) => {
                return a - b;
              });
            }
            dataA.forEach((e, index) => {
              let dataSizeShow = false;
              if (transactionType == "contract_usdt" && dataCopy.length > 6) {
                return;
              } else if (
                transactionType !== "contract_usdt" &&
                dataCopy.length > 9
              ) {
                return;
              }
              let item = {};
              if (pair.exchangeName == "Binance") {
                if (Number(e.qty).toString().indexOf(".") > -1) {
                  e.qty = Number(
                    e.qty
                      .toString()
                      .slice(
                        0,
                        e.qty.toString().indexOf(".") + counterCoinList[0] + 1
                      )
                  );
                } else {
                  e.qty = Number(e.qty);
                }
                if (index == 0) {
                  e.cumulative = e.qty;
                } else {
                  e.cumulative = e.qty + dataA[index - 1].cumulative;
                }
                e.cumulative = Number(
                  e.cumulative.toFixed(counterCoinList[0] + 1)
                );
                item = e;
                item.priceShow = true;
              } else if (
                pair.exchangeName == "Huobi" ||
                pair.exchangeName == this.$t('m.全部')
              ) {
                if (transactionType == "contract_usdt") {
                  if (Number(e[1]).toString().indexOf(".") > -1) {
                    e[1] = Number(
                      e[1]
                        .toString()
                        .slice(
                          0,
                          e[1].toString().indexOf(".") + counterCoinList[0] + 1
                        )
                    );
                  } else {
                    e[1] = Number(e[1]);
                  }
                  if (index == 0) {
                    e[2] = e[1];
                  } else {
                    e[2] = e[1] + dataA[index - 1][2];
                  }
                  item.qty = e[1];
                  e[2] = Number(e[2].toFixed(counterCoinList[0] + 1));
                  item.cumulative = e[2];
                  item.price = e[0];
                  item.priceShow = true;
                } else {
                  if (Number(e.amount).toString().indexOf(".") > -1) {
                    e.amount = Number(
                      e.amount
                        .toString()
                        .slice(
                          0,
                          e.amount.toString().indexOf(".") +
                            counterCoinList[0] +
                            1
                        )
                    );
                  } else {
                    e.amount = Number(e.amount);
                  }
                  if (index == 0) {
                    e.cumulative = e.amount;
                  } else {
                    e.cumulative = e.amount + dataA[index - 1].cumulative;
                  }
                  e.qty = e.amount;
                  e.cumulative = Number(
                    e.cumulative.toFixed(counterCoinList[0] + 1)
                  );
                  item = e;
                  item.priceShow = true;
                }
              } else if (pair.exchangeName == "OkexV5") {
                if (e.size == 0) {
                  dataSizeShow = true;
                  return;
                }
                if (e.size.indexOf(".") > -1) {
                  e.size = Number(
                    e.size.slice(
                      0,
                      e.size.indexOf(".") + counterCoinList[0] + 1
                    )
                  );
                } else {
                  e.size = Number(e.size);
                }
                if (index == 0) {
                  e.cumulative = e.size;
                } else {
                  e.cumulative = Number(e.size + dataA[index - 1].cumulative);
                }
                item.cumulative = Number(
                  e.cumulative.toFixed(counterCoinList[0] + 1)
                );
                item.qty = e.size;
                item.price = e.price;
                item.priceShow = true;
              }
              if (dataSizeShow) {
                return;
              }
              dataCopy.unshift(item);
            });
            dataCopy.push({
              transactionTypeCopyShow: true,
            });
            dataB.forEach((e, index) => {
              let dataSizeShow = false;
              if (transactionType == "contract_usdt" && dataCopy.length > 14) {
                return;
              } else if (dataCopy.length > 20) {
                return;
              }
              let item = {};
              if (pair.exchangeName == "Binance") {
                if (Number(e.qty).toString().indexOf(".") > -1) {
                  e.qty = Number(
                    e.qty
                      .toString()
                      .slice(
                        0,
                        e.qty.toString().indexOf(".") + counterCoinList[0] + 1
                      )
                  );
                } else {
                  e.qty = Number(e.qty);
                }
                if (index == 0) {
                  e.cumulative = e.qty;
                } else {
                  e.cumulative = e.qty + dataB[index - 1].cumulative;
                }
                e.cumulative = Number(
                  e.cumulative.toFixed(counterCoinList[0] + 1)
                );
                item = e;
                item.priceShow = false;
              } else if (
                pair.exchangeName == "Huobi" ||
                pair.exchangeName == this.$t('m.全部')
              ) {
                if (transactionType == "contract_usdt") {
                  if (Number(e[1]).toString().indexOf(".") > -1) {
                    e[1] = Number(
                      e[1]
                        .toString()
                        .slice(
                          0,
                          e[1].toString().indexOf(".") + counterCoinList[0] + 1
                        )
                    );
                  } else {
                    e[1] = Number(e[1]);
                  }
                  if (index == 0) {
                    e[2] = e[1];
                  } else {
                    e[2] = e[1] + dataB[index - 1][2];
                  }
                  item.qty = e[1];
                  e[2] = Number(e[2].toFixed(counterCoinList[0] + 1));
                  item.cumulative = e[2];
                  item.price = e[0];
                  item.priceShow = false;
                } else {
                  if (Number(e.amount).toString().indexOf(".") > -1) {
                    e.amount = Number(
                      e.amount
                        .toString()
                        .slice(
                          0,
                          e.amount.toString().indexOf(".") +
                            counterCoinList[0] +
                            1
                        )
                    );
                  } else {
                    e.amount = Number(e.amount);
                  }
                  if (index == 0) {
                    e.cumulative = e.amount;
                  } else {
                    e.cumulative = e.amount + dataB[index - 1].cumulative;
                  }
                  e.qty = e.amount;
                  e.cumulative = Number(
                    e.cumulative.toFixed(counterCoinList[0] + 1)
                  );
                  item = e;
                  item.priceShow = false;
                }
              } else if (pair.exchangeName == "OkexV5") {
                if (e.size == 0) {
                  dataSizeShow = true;
                  return;
                }
                if (e.size.indexOf(".") > -1) {
                  e.size = Number(
                    e.size.slice(
                      0,
                      e.size.indexOf(".") + counterCoinList[0] + 1
                    )
                  );
                } else {
                  e.size = Number(e.size);
                }
                if (index == 0) {
                  e.cumulative = e.size;
                } else {
                  e.cumulative = Number(e.size + dataB[index - 1].cumulative);
                }
                item.cumulative = Number(
                  e.cumulative.toFixed(counterCoinList[0] + 1)
                );
                item.qty = e.size;
                item.price = e.price;
                item.priceShow = false;
              }
              if (dataSizeShow) {
                return;
              }
              dataCopy.push(item);
            });
            this.pairHandicap = JSON.parse(JSON.stringify(dataCopy));
          } else {
          }
        };
        //连接关闭事件
        this.socket.onclose = function () {
          // console.log("Socket已关闭");
        };
        //发生了错误事件
        this.socket.onerror = function () {
          // alert("Socket发生了错误");
        };

        //窗口关闭时，关闭连接
        window.unload = function () {
          socket.close();
        };
      }
    },
    socketClick() {
      if (!this.socketClickShow) {
        this.socketFn();
        this.socketClickShow = true;
        return;
      }
      this.socket.close();
      this.socketFn();
    },
  },

  created() {
    this.clientWidth = document.body.clientWidth;
    let project = sessionStorage.getItem("project");
    this.project = JSON.parse(project);
    let account = sessionStorage.getItem("account");
    this.account = JSON.parse(account);
    this.socketClickShow = false;
    this.recovery();
    let lever = sessionStorage.getItem("lever");
    if (lever) {
      this.lever =
        JSON.parse(
          JSON.parse(sessionStorage.getItem("role")).leverMultiple
        ).indexOf(Number(lever)) > -1
          ? lever
          : "3";
    }
    this.queryOrderLabel();
    sessionStorage.setItem("polling", true);
  },
  mounted() {
    this.queryProjectData();
    this.queryOrderActionDetails();

    setTimeout(() => {
      this.leverList = JSON.parse(
        JSON.stringify(
          JSON.parse(JSON.parse(sessionStorage.getItem("role")).leverMultiple)
        )
      );
    }, 500);
    let expressTransactionTips = localStorage.getItem("expressTransactionTips");
    if (!expressTransactionTips) {
      this.tipsShow = true;
    }
    // this.getCurrentPublicUser();
    // 轮询事件
    // this.setTime = setInterval(() => {
    //   this.queryErrorMessage();
    // }, 1000);

    // this.socketFn();
  },
  beforeCreate() {},
  beforeMount() {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {
    sessionStorage.removeItem("polling");
    clearInterval(this.setTime);
    this.remSessVal();
    this.socket.close();
  },
  activated() {},
  deactivated() {},
};
</script>








<style lang='scss' scoped>
//@import url(); 引入公共css类
.expressTransaction {
  height: 100%;

  .top-select {
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background: #fff;
    border-radius: 8px;

    .labelStyle {
      border: 1px solid red;
      border-radius: 5px;
    }
  }

  .content-warp {
    width: 100%;
    // height: calc(100% - 90px);

    display: flex;
    flex-direction: column;
    // 中间
    .content {
      margin: 10px 0;
      display: flex;
      height: 580px;
      .acc-warp {
        padding: 20px;
        background: #fff;
        flex: 1.3;

        border-radius: 8px;
        position: relative;
        .select-label,
        .select-exchange,
        .select-type {
          height: 40px;
          display: flex;
          align-items: center;
          span {
            width: 70px;
          }
          .el-input,
          .el-select {
            flex: 1;
          }
          .el-input {
            ::v-deep.el-input__suffix {
              top: 12px;
              right: 10px;
              cursor: pointer;
            }
          }
          .icon-suiji {
            width: 10px;
            height: 10px;
            cursor: pointer;
          }
        }
        .title {
          display: inline-block;
          color: #102859;
          font-size: 16px;
          margin-bottom: 10px;
        }
        .acc-warp-box {
          height: 370px;

          /deep/.el-checkbox__inner {
            border-radius: 50%;
          }
          .acc-warp-cont {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 20px;
            .acc {
              flex: 1;
              height: 100px;
              display: flex;
              cursor: pointer;
              justify-content: space-between;
              align-items: center;
              background: #f7f7f7;
              padding: 0 15px;
              border-radius: 4px;
              border: 1px solid #f7f7f7;
              .acc-box {
                flex: 1;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                margin-right: 3px;
                .acc-name {
                  color: #14263d;
                  margin-right: 10px;
                }
                .acc-ava {
                  display: flex;
                  flex-direction: column;
                  font-size: 12px;
                  color: #55698a;
                  line-height: 18px;
                  :first-child {
                    margin: 7px 0;
                  }
                }
              }
            }
            .transfer {
              display: inline-block;
              font-size: 12px;
              width: 30px;
              text-align: center;
              color: #2174ff;
              cursor: pointer;
            }
          }
          :last-child {
            margin: 0;
          }
        }
        .acc-warp-box::-webkit-scrollbar {
          /*滚动条整体样式*/
          width: 3px; /*高宽分别对应横竖滚动条的尺寸*/
          height: 3px;
        }
        .acc-warp-box::-webkit-scrollbar-thumb {
          /*滚动条里面小方块*/
          border-radius: 5px;
          -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
          background: rgba(0, 0, 0, 0.2);
        }
        .acc-warp-box::-webkit-scrollbar-track {
          /*滚动条里面轨道*/
          -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
          border-radius: 0;
          background: rgba(0, 0, 0, 0.1);
        }

        .default {
          display: flex;
          flex-direction: column;
          align-items: center;
          height: 370px;
          img {
            width: 160px;
            height: 160px;
            margin: 60px 0 20px 0;
          }
          span {
            color: #999;
          }
        }
        .check {
          border: 1px solid #2174ff !important;
          background: #f2f7ff !important;
          .acc-name {
            color: #2174ff !important;
          }
        }
        .acc-selectAll {
          padding-top: 5px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 14px;
          color: #102859;
          div {
            cursor: pointer;
            display: flex;
            align-items: center;
            /deep/.el-checkbox__inner {
              border-radius: 50%;
            }
            /deep/.el-checkbox__label {
              color: #102859;
            }
          }
        }

        // 参数提示框
        .params-tips {
          width: 91%;
          height: 210px;
          border-radius: 8px;
          position: absolute;
          top: 180px;
          left: 0px;
          background: rgba(28, 29, 40, 0.94);
          box-shadow: 0px 1px 4px 1px rgba(0, 0, 0, 0.23);
          // display: flex;
          // justify-content: space-between;
          padding: 20px;
          z-index: 8888;
          color: #f7f7f7;
          font-size: 14px;
          // 三角
          .triangle {
            position: absolute;
            top: 20px;
            right: -24px;
            border-width: 12px;
            border-style: dashed solid solid dashed;
            border-color: transparent transparent transparent
              rgba(28, 29, 40, 0.94);
            line-height: 0;
            font-size: 0;
            width: 0;
            height: 0;
          }
          .top {
            width: 100%;
            text-align: right;
            img {
              width: 14px;
              height: 14px;
              cursor: pointer;
            }
          }
          p {
            margin-top: 10px;
            letter-spacing: 1px;
          }
          .op-clk {
            margin-top: 60px;
            display: flex;
            .next {
              width: 90px;
              height: 32px;
              display: flex;
              justify-content: center;
              align-items: center;
              background: #2174ff;
              border-radius: 4px;
              cursor: pointer;
            }
            .previous-step {
              width: 90px;
              height: 32px;
              display: flex;
              justify-content: center;
              align-items: center;
              color: #2174ff;
              border-radius: 4px;
              margin-left: 10px;
              border-radius: 4px;
              border: 1px solid #2174ff;
              cursor: pointer;
            }
          }
        }
      }
      //   动作
      .action-warp {
        flex: 2.7;
        margin: 0 10px;
        background: #fff;
        color: #102859;
        font-size: 16px;
        padding: 0 20px 10px 20px;
        position: relative;

        border-radius: 8px 8px;

        .label {
          height: 60px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          // padding-bottom: 20px;
          border-bottom: 1px solid #e6e9ee;
          .contractPositionTType-warp {
            width: 100%;
            height: 60px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            /deep/.el-tabs__header {
              margin: 0;
              .el-tabs__nav {
                height: 60px;
                display: flex;
                .el-tabs__item {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  height: 60px;
                  width: 150px;
                  padding: 0;
                }
              }
            }
          }
        }
        .order-type {
          .title {
            display: inline-block;
            padding: 10px 0;
          }
          .type-content-warp {
            display: flex;
            justify-content: space-between;
            align-items: center;

            border-bottom: 1px solid #e6e9ee;
            .type-content {
              display: flex;
              justify-content: flex-start;
              padding: 20px 0;
              div {
                display: flex;
                .box {
                  width: 140px;
                  height: 40px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  background: #e6e9ee;
                  border-radius: 3px 0 0 3px;
                  cursor: pointer;
                }
                .boxOn {
                  color: #fff;
                  background: url("../../assets/icon/xieti-lan.png");
                }
                .boxTwo {
                  background: url("../../assets/icon/xieti-hui.png");
                }
              }
            }
            .conte-size-lever {
              display: flex;
              justify-content: flex-end;
              align-items: center;
              font-size: 12px;
              color: #55698a;
              .el-select {
                width: 30%;
              }
            }
          }
        }
        .price-investment-warp {
          display: flex;
          justify-content: space-between;
          width: 100%;
          height: -webkit-calc(100% - 144px);
          height: -moz-calc(100% - 144px);
          height: calc(100% - 144px);
          .left,
          .right {
            width: 46%;
            position: relative;
            ::v-deep.el-input__suffix {
              line-height: 40px;
            }
            .action-params {
              margin-bottom: 20px;
              .title {
                display: inline-block;
                padding: 10px 0;
              }
            }
            .action-investment,
            .u-l-limits {
              margin-bottom: 20px;
              .title {
                display: flex;
                align-items: center;
                margin-bottom: 10px;
                img {
                  margin-left: 4px;
                  cursor: pointer;
                }
              }
              .investment-content {
                display: flex;
                justify-content: space-between;
                align-items: center;
                .el-input {
                  margin-left: 20px;
                }
              }
            }
            .u-l-limits {
              margin-bottom: 20px;
              .investment-content {
                .first {
                  margin-right: 20px;
                }
                .el-input {
                  margin-left: 0;
                }
              }
            }
            .buyBtn,
            .sellBtn {
              width: 100%;
              height: 36px;
              color: #fff;
              background: #ff3a3a;
              border-radius: 3px;
              display: flex;
              justify-content: center;
              align-items: center;
              cursor: pointer;
              //   margin-top: 10px;
              position: absolute;
              left: 0;
              bottom: 20px;
            }
            .sellBtn {
              background: #26b34b;
            }
          }

          .center {
            margin: 14px 0 20px;
            border: 1px solid #e6e9ee;
          }
          .conditionBox {
            display: flex;
            flex-direction: column;
            flex: 1;
            margin-top: 10px;

            /* 整个滚动条 */
            // ::-webkit-scrollbar {
            //   width: 14px;
            //   background: #f7f7f7;
            // }
            /*滚动条样式*/
            ::-webkit-scrollbar {
              /*滚动条整体样式*/
              width: 3px; /*高宽分别对应横竖滚动条的尺寸*/
              height: 3px;
            }
            ::-webkit-scrollbar-thumb {
              /*滚动条里面小方块*/
              border-radius: 5px;
              -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
              background: rgba(0, 0, 0, 0.2);
            }
            ::-webkit-scrollbar-track {
              /*滚动条里面轨道*/
              -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
              border-radius: 0;
              background: rgba(0, 0, 0, 0.1);
            }
            .action-condition {
              display: flex;
              flex-direction: column;
              flex: 1;
              :first-child .title {
                padding: 0 0 8px;
              }
              .title {
                display: inline-block;
                padding: 10px 0 4px;
              }
              .condition-box {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 40px;
                width: 50%;
                border-radius: 4px;
                cursor: pointer;
              }
              .investment-content {
                display: flex;
                justify-content: space-between;
                align-items: center;
                .el-input {
                  margin-left: 20px;
                }
              }
              .lanBox {
                background: #2174ff;
                color: #fff;
              }
              .huiBox {
                background: #f7f7f7;
              }
              /deep/.el-input__suffix {
                line-height: 40px;
              }
            }
            .action-conditionBtn {
              color: #fff;
              margin-top: 8px;
              height: 36px;
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 3px;
              cursor: pointer;
            }
          }
          .conditionBox-center {
            border: 1px solid #e6e9ee;
            margin: 10px 25px 0;
          }
        }

        // 账户提示框
        .acc-tips {
          width: 60%;
          // height: 210px;
          border-radius: 8px;
          position: absolute;
          top: 20px;
          left: 0px;
          background: rgba(28, 29, 40, 0.94);
          box-shadow: 0px 1px 4px 1px rgba(0, 0, 0, 0.23);
          display: flex;
          justify-content: space-between;
          padding: 20px;
          z-index: 8888;
          // 三角
          .triangle {
            position: absolute;
            top: 20px;
            left: -24px;
            border-width: 12px;
            border-style: dashed solid solid dashed;
            border-color: transparent rgba(28, 29, 40, 0.94) transparent
              transparent;
            line-height: 0;
            font-size: 0;
            width: 0;
            height: 0;
          }
          .left {
            color: #f7f7f7;
            font-size: 14px;
            width: 50%;
            p {
              margin-top: 10px;
              line-height: 20px;
              letter-spacing: 1px;
            }
            .next {
              width: 90px;
              height: 32px;
              display: flex;
              justify-content: center;
              align-items: center;
              background: #2174ff;
              border-radius: 4px;
              margin-top: 40px;
              cursor: pointer;
            }
          }
          .center {
            width: 35%;
            // height: 100px;
          }
          .right {
            width: 14px;
            height: 14px;
            cursor: pointer;
          }
        }
        // 交易对提示框
        .pair-tips {
          width: 60%;
          height: 210px;
          border-radius: 8px;
          position: absolute;
          bottom: 20px;
          right: 0px;
          background: rgba(28, 29, 40, 0.94);
          box-shadow: 0px 1px 4px 1px rgba(0, 0, 0, 0.23);
          display: flex;
          justify-content: space-between;
          padding: 20px;
          z-index: 8888;
          // 三角
          .triangle {
            position: absolute;
            top: 20px;
            right: -24px;
            border-width: 12px;
            border-style: dashed solid solid dashed;
            border-color: transparent transparent transparent
              rgba(28, 29, 40, 0.94);
            line-height: 0;
            font-size: 0;
            width: 0;
            height: 0;
          }
          .left {
            color: #f7f7f7;
            font-size: 14px;
            width: 50%;
            p {
              margin-top: 10px;
              letter-spacing: 1px;
            }
            .op-clk {
              margin-top: 60px;
              display: flex;
              .next {
                width: 90px;
                height: 32px;
                display: flex;
                justify-content: center;
                align-items: center;
                background: #2174ff;
                border-radius: 4px;
                cursor: pointer;
              }
              .previous-step {
                width: 90px;
                height: 32px;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #2174ff;
                border-radius: 4px;
                margin-left: 10px;
                border-radius: 4px;
                border: 1px solid #2174ff;
                cursor: pointer;
              }
            }
          }
          .center {
            width: 35%;
          }
          .right {
            width: 14px;
            height: 14px;
            cursor: pointer;
          }
        }
      }
      //   交易对
      .pair-warp {
        flex: 1;
        // background: #fff;
        color: #102859;
        // padding: 20px;
        height: 580px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .pair {
          box-shadow: 0px 11px 22px 0px rgba(109, 147, 176, 0.08);
          border-radius: 8px;
          background: #fff;
          padding: 20px;
          flex: 1;
          .title {
            width: 100%;
            display: inline-block;
            font-size: 16px;
            padding-bottom: 20px;
            border-bottom: 1px solid #e6e9ee;
          }
          .currencyCoinBox {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 30px;
            margin-bottom: 1px;
            div {
              cursor: pointer;
              flex: 1;
              display: flex;
              justify-content: center;
              height: 100%;
              align-items: center;
            }
          }
          .pair-cont-warp {
            // height: 78%;
            height: 437px;
            overflow-y: auto;
            .pair-cont {
              height: 30px;
              font-size: 14px;
              display: flex;
              align-items: center;
              cursor: pointer;
            }
          }
          .pair-cont-warpShow {
            display: flex;
            justify-content: center;
            color: #909399;
            margin-top: 30px;
          }
          .pairOn {
            color: #2174ff;
            background: #f6f6f8;
          }
        }

        .size-warp {
          height: 145px;
          font-size: 12px;
          display: flex;
          flex-direction: column;
          line-height: 20px;
          color: #54698a;
          background: #ffffff;
          box-shadow: 0px 11px 22px 0px rgba(109, 147, 176, 0.08);
          border-radius: 8px;
          margin-top: 10px;
          padding: 10px 15px;
          .size-name {
            display: inline-block;
            width: 55px;
          }
        }
        .pair-warp-pair {
          background: #fff;
          display: flex;
          flex-direction: column;
          border-radius: 8px;
          .pair-warp-pairTitle {
            padding: 0 20px;
            height: 60px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid #e6e9ee;
            cursor: pointer;
            .pairTitle-box {
              display: flex;
              align-items: center;

              .pairTitle-center {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                margin-right: 8px;
              }
            }
          }
          .pairBox {
            display: flex;
            flex-direction: column;
            padding: 5px 20px;
            flex: 1;
            .pairItem {
              display: flex;
              justify-content: space-between;
              padding: 7px 0;
              cursor: pointer;
              :first-child {
                display: flex;
                width: 120px;
              }
              div {
                display: flex;
                justify-content: flex-start;
              }
              span {
                flex: 1;
              }
              :last-child {
                display: flex;
                justify-content: flex-end;
              }
            }
          }
          /*滚动条样式*/
          .pairBox::-webkit-scrollbar {
            /*滚动条整体样式*/
            width: 3px; /*高宽分别对应横竖滚动条的尺寸*/
            height: 3px;
          }
          .pairBox::-webkit-scrollbar-thumb {
            /*滚动条里面小方块*/
            border-radius: 5px;
            -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
            box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
            background: rgba(0, 0, 0, 0.2);
          }
          .pairBox::-webkit-scrollbar-track {
            /*滚动条里面轨道*/
            -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
            box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
            border-radius: 0;
            background: rgba(0, 0, 0, 0.1);
          }
          .pair-handicap {
            flex: 1;
            display: flex;
            flex-direction: column;
            padding: 0 20px;
            .pair-handicap-bigBox {
              display: flex;
              flex: 1;
              flex-direction: column;
            }
            .pair-handicap-title {
              font-size: 16px;
            }
            .pair-handicap-Box {
              font-size: 14px;
              display: flex;
              justify-content: space-between;
              padding: 3px 0;
              div {
                flex: 1;
                display: flex;
                justify-content: flex-start;
              }
              :last-child {
                justify-content: flex-end;
              }
            }
            .pair-handicap-item {
              flex: 1;
              display: flex;
              flex-direction: column;
              justify-content: center;
            }
          }
        }
        .pairStyle {
          height: 100%;
        }
      }
    }

    // 底部
    .bottom-select {
      background: #fff;
      padding: 0 20px 20px 20px;
      border-radius: 8px;
      position: relative;
      .select-warp {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .left {
          display: flex;
          align-items: center;
          .first,
          .last {
            display: flex;
            align-items: center;
            margin-right: 20px;
            .lable {
              width: 60px;
            }
            img {
              margin-right: 10px;
              cursor: pointer;
            }
            .last-tips {
              display: inline-block;
              margin-left: 20px;
              color: #999;
              font-size: 12px;
            }
            .el-input {
              height: 30px;
              width: 200px;
            }
            ::v-deep.el-input__inner {
              height: 30px;
              line-height: 30px;
            }
            ::v-deep.el-input__icon {
              height: 30px;
              line-height: 30px;
            }
          }

          .first-sub {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 72px;
            height: 32px;
            background: #2174ff;
            border-radius: 4px;
            color: #fff;
            font-size: 14px;
            margin-left: 10px;
            cursor: pointer;
          }
          .first-show {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 72px;
            height: 32px;
            background: #55698a;
            border-radius: 4px;
            color: #fff;
            font-size: 14px;
            margin-left: 10px;
            cursor: pointer;
          }
        }
        .right {
          color: #2174ff;
          cursor: pointer;
        }
      }
      .btn-color {
        color: #2174ff !important;
        cursor: pointer;
      }
      .bottom-explain {
        font-size: 14px;
        padding-top: 10px;
        color: #9faabd;
        display: flex;
        justify-content: center;
      }

      // 持仓提示
      .position-tips {
        width: 20%;
        height: 130px;
        border-radius: 8px;
        position: absolute;
        top: -130px;
        left: 180px;
        background: rgba(28, 29, 40, 0.94);
        box-shadow: 0px 1px 4px 1px rgba(0, 0, 0, 0.23);
        padding: 20px;
        z-index: 8888;
        color: #f7f7f7;
        font-size: 14px;
        z-index: 8888;
        // 三角
        .triangle {
          position: absolute;
          bottom: 20px;
          left: -20px;
          border-width: 10px;
          border-style: dashed solid solid dashed;
          border-color: transparent rgba(28, 29, 40, 0.94) transparent
            transparent;

          line-height: 0;
          font-size: 0;
          width: 0;
          height: 0;
        }
        .top {
          width: 100%;
          text-align: right;
          img {
            width: 14px;
            height: 14px;
            cursor: pointer;
          }
        }
        p {
          margin-top: 10px;
          letter-spacing: 1px;
        }
        .op-clk {
          margin-top: 30px;
          display: flex;
          .next {
            width: 90px;
            height: 32px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #2174ff;
            border-radius: 4px;
            cursor: pointer;
          }
          .previous-step {
            width: 90px;
            height: 32px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #2174ff;
            border-radius: 4px;
            margin-left: 10px;
            border-radius: 4px;
            border: 1px solid #2174ff;
            cursor: pointer;
          }
        }
      }

      //持仓操作
      .position-op-tips {
        width: 30%;
        height: 170px;
        border-radius: 8px;
        position: absolute;
        bottom: 204px;
        right: 140px;
        background: rgba(28, 29, 40, 0.94);
        box-shadow: 0px 1px 4px 1px rgba(0, 0, 0, 0.23);
        display: flex;
        justify-content: space-between;
        padding: 20px;
        z-index: 8888;
        // 三角
        .triangle {
          position: absolute;
          bottom: 15px;
          right: -20px;
          border-width: 10px;
          border-style: dashed solid solid dashed;
          border-color: transparent transparent transparent
            rgba(28, 29, 40, 0.94);
          line-height: 0;
          font-size: 0;
          width: 0;
          height: 0;
        }
        .left {
          color: #f7f7f7;
          font-size: 14px;
          width: 55%;
          p {
            margin-top: 10px;
            letter-spacing: 1px;
          }
          .op-clk {
            margin-top: 80px;
            display: flex;
            .next {
              width: 90px;
              height: 32px;
              display: flex;
              justify-content: center;
              align-items: center;
              background: #2174ff;
              border-radius: 4px;
              cursor: pointer;
            }
            .previous-step {
              width: 90px;
              height: 32px;
              display: flex;
              justify-content: center;
              align-items: center;
              color: #2174ff;
              border-radius: 4px;
              margin-left: 10px;
              border: 1px solid #2174ff;
              cursor: pointer;
            }
          }
        }
        .center {
          width: 35%;
        }
        .right {
          width: 14px;
          height: 14px;
          cursor: pointer;
        }
      }
    }
  }
  .chexiao {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  .green {
    color: #26b34b;
  }
  .red {
    color: #ff3a3a;
  }
  // 划转
  .transfer-box {
    width: 100%;
    .sel {
      display: flex;
      align-items: center;
      width: 100%;
      .left {
        width: -webkit-calc(100% - 30px);
        width: -moz-calc(100% - 30px);
        width: calc(100% - 30px);
        .left-box {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin: 10px 0;
          .title {
            margin-right: 20px;
          }
          img {
            margin: 0;
          }
        }
      }
      .right {
        img {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          cursor: pointer;
        }
      }
    }
    .curr,
    .tran-num {
      margin-top: 30px;
      .title {
        display: inline-block;
        margin-bottom: 10px;
      }
      .unit {
        display: flex;
        align-items: center;
        height: 40px;
        .cur {
          border-right: 1px solid #eee;
          margin-right: 6px;
          padding-right: 6px;
        }
        .all {
          display: flex;
          align-items: center;
          height: 20px;
          color: #2174ff;
          // margin-left: 6px;
          // padding-left: 6px;
          cursor: pointer;
        }
      }
      .delimitable {
        display: inline-block;
        font-size: 12px;
        margin-top: 8px;
        margin-left: 4px;
      }
      .available {
        margin: 8px 0 10px 0;
        color: #55698a;
        font-size: 12px;
      }
      .tips {
        color: #55698a;
        font-size: 12px;
        margin: 0;
      }
    }
  }
}
.currencyBrd {
  color: #2174ff;
  border-bottom: 1px solid #2174ff;
}
.dialog-nav {
  color: #55698a;
  padding: 20px 0 30px;
  font-size: 12px;
  .dialog-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .dialog-img {
      width: 36px;
      height: 36px;
      cursor: pointer;
      margin: 0 20px 20px 0;
    }
    .dialog-top {
      font-size: 16px;
      margin-bottom: 20px;
      color: #102859;
    }
  }
  .dialog-currency {
    display: flex;
    flex-direction: column;
    font-size: 16px;
    span {
      margin-bottom: 10px;
    }
  }
}
.dialog-footer {
  display: flex;
  justify-content: flex-end;
  div {
    width: 72px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    cursor: pointer;
  }
  .footerBack {
    border: 1px solid #c2d9ff;
    color: #2174ff;
  }
  .footerBtn {
    color: #ffffff;
    background: #2174ff;
    border: 1px solid #c2d9ff;
    margin-left: 12px;
  }
}

.contractShow {
  display: flex;

  .brdTipsStyle {
    border-bottom: 1px dashed #333;
    color: #55698a;
    margin-right: 10px;
  }
}
.open {
  position: absolute;
  bottom: 5px;
  right: 5px;
  z-index: 9999;
  border: 1px solid #e6e9ee;
  width: 280px;
  height: 130px;
  background: #fff;
  .open-title {
    display: flex;
    justify-content: flex-end;
    border-bottom: 1px solid #e6e9ee;
    span {
      margin: 5px 10px;
      cursor: pointer;
      color: #777;
    }
  }
  .open-nav {
    padding: 20px;
  }
}
.conditionBoxScoped {
  display: flex;
  flex-direction: column;
  .action-condition {
    display: flex;
    flex-direction: column;
    flex: 1;
    color: #102859;
    :first-child .title {
      padding: 0 0 8px;
    }
    .title {
      display: inline-block;
      padding: 10px 0 4px;
    }
    .condition-box {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 40px;
      width: 100%;
      border-radius: 4px;
      cursor: pointer;
    }
    .investment-content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      .investment-block {
        display: flex;
        width: 100%;
        /deep/.el-slider {
          width: 100%;
        }
      }
    }
    .lanBox {
      background: #2174ff;
      color: #fff;
    }
    .huiBox {
      background: #f7f7f7;
    }
    /deep/.el-input__suffix {
      line-height: 40px;
    }

    .action-btnList {
      display: flex;
      div {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 30px;
        background: #f7f7f7;
        color: #102859;
        cursor: pointer;
        border-radius: 4px;
        margin-right: 10px;
      }
      .action-item {
        background: #2174ff;
        color: #ffffff;
      }
    }
  }
  .action-conditionBtn {
    color: #fff;
    margin-top: 8px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
    cursor: pointer;
  }
}

/deep/.caret-wrapper {
  width: 0;
}
.screenBox {
  display: flex;
  align-items: center;
  cursor: pointer;
  .screenBox-img {
    display: flex;
    flex-direction: column;
    margin-left: 3px;
    :nth-child(2) {
      margin-top: 3px;
    }
    img {
      width: 8px;
    }
  }
}
.headerStyle {
  width: 100px;
  display: flex;
  align-items: center;
  cursor: pointer;
  .headerIcon {
    margin-left: 3px;
  }

  .brdTipsStyle {
    width: 70px;
    border-bottom: 1px dashed #333;
  }
}
/deep/.el-table .cell {
  padding: 0;
  .el-checkbox {
    display: flex;
    justify-content: flex-end;
  }
}
.scrollStyle {
  overflow-y: scroll;
}
.secondaryShowStyle {
  /deep/.el-input__suffix {
    display: flex;
    align-items: center;
  }
  .action-btnList {
    display: flex;
    div {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 30px;
      background: #f7f7f7;
      color: #102859;
      cursor: pointer;
      border-radius: 4px;
      margin-right: 10px;
    }
    .action-item {
      background: #2174ff;
      color: #ffffff;
    }
  }
}
/deep/.el-dialog__body {
  padding: 10px 20px;
}
.dialog-selectAll {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 20px;
  border-bottom: 1px solid #e6e9ee;
}
.dialog-boxShow {
  display: flex;
  flex-wrap: wrap;
  // height: 550px;
  .dialog-warp-cont {
    width: 32%;
    height: 89px;
    display: flex;
    margin-top: 20px;
    cursor: pointer;
    .dialog-warp-box {
      flex: 1;
      display: flex;
      height: 50px;
      align-items: center;
      padding: 20px 15px;
      background: #f7f7f7;
      border-radius: 4px;
      .warp-cont-price {
        height: 100%;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        color: #55698a;
      }
      .warp-cont-btn {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        /deep/.el-checkbox__inner {
          border-radius: 50%;
        }
      }
    }
    .transfer {
      height: 92px;
      line-height: 92px;
      color: #2174ff;
      margin-left: 5px;
    }
  }
  .check {
    border: 1px solid #2174ff !important;
    background: #f2f7ff !important;
    .dialog-name {
      color: #2174ff !important;
    }
  }
  .default {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    img {
      width: 160px;
      height: 160px;
      margin: 60px 0 20px 0;
    }
    span {
      color: #999;
    }
  }
}

.dialog-boxShow::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 3px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 3px;
}
.dialog-boxShow::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 5px;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: rgba(0, 0, 0, 0.2);
}
.dialog-boxShow::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 0;
  background: rgba(0, 0, 0, 0.1);
}
.dialog-boxShow .dialog-warp-cont:nth-child(3n + 2) {
  margin: 20px 20px 0;
}
</style>